import React, { Component } from 'react';
import { View, Linking, NativeModules, NativeEventEmitter } from 'react-native';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { v1 as uuidv1 } from 'uuid';
// import RNUxcam from 'react-native-ux-cam';
// import RNAdvertisingId from 'react-native-advertising-id';
import SplashScreen from 'react-native-splash-screen';
import _ from 'lodash';
import changeNavigationBarColor from 'react-native-navigation-bar-color';

import Utility from '../utils/Utility';
import {
  storeRemoteConfig,
  lastRemoteCacheRefreshedAt,
  calculateUACAppDeviceScore,
  updateUserData,
  getUserTodayDeals,
  getBoostedOffers,
  clearWishlistTooltip,
  saveBagModalOpenTimestampAndAppLaunchState,
  renderOnboarding,
} from '../actions/ActionTypes';
import {
  saveGuestAuthToken,
  saveUserState,
  changeLoginModalState,
  guestFirstAppOpenTime,
  // setForcedLogoutFlow,
  availableApps,
  registerGuestUser,
} from '../actions/LoginActions';
import {
  AVAILABLE_BUILD_TYPES,
  CURRENT_GUEST_STATE,
  APP_CONSTANTS,
  REMOTE_CONFIG_KEYS,
  REMOTE_CONFIG_DATA_REFRESH_AFTER,
  LOGIN_MODAL_STATE,
  NAVIGATION_BYPASS,
  APP_LAUNCH_STATES,
  TIME_RANGE_TO_REPLACE_GUEST_TOKEN,
  APP_LOAD_SOURCE,
  LINK_DOMAIN_SHORT,
} from '../config/Constants';
import RemoteConfig from '../utils/RemoteConfig';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
  AnalyticsUtilty,
} from '../analytics';
import NavigationService from './NavigationService';
import { fetch } from '@react-native-community/netinfo';
import NetworkStats from '../utils/NetworkStat';
import SelfieUtilities from '../utils/SelfieUtilities';
import AnalyticsUtility from '../analytics/AnalyticsUtility';
import { messaging, analytics } from '../libraries/Firebase';
import BGNetworkTaskManager from '../utils/BGNetworkTaskManager';
import { getLoyaltyPlans } from '../actions/FoxyEdgeAction';
import moment from 'moment';
import DynamicLinkManager from '../utils/DynamicLinkManager';
import Session from '../utils/Sessions';
import TimeUtility from '../utils/TimeUtility';
import { isNative, isWeb } from '../utils/BooleanUtility';
import DeviceInfoUtils from '../utils/DeviceInfoUtils';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class ScreenNavigator extends Component {
  constructor(props) {
    super(props);
    const {
      isUserLogoutOnce,
      guestProfile: { guestAuthToken },
    } = this.props;
    this.refershRemoteConfigAfterHours = 6;
    this.randomGuestToken = null;
    this.appJustLaunched = true;

    this.app_detected = false;

    if (Utility.isAndroid()) {
      this.appDetectEventListener = new NativeEventEmitter(
        NativeModules.AppDetect,
      );
    }
    this.skip_selfie = false;
    Utility.skipOnboardingSelfie =
      RemoteConfig.getValue('skip_selfie') === 'true' ||
      RemoteConfig.getValue('skip_selfie') === true;

    this.fcmToken = null;

    this.debouncedNavigate = _.debounce(this.handleRouteFromLink, 50, {
      leading: true,
      trailing: false,
    });
  }

  /**
   * This method generates a guest token if it doesn't exist. Subsequently,
   * it sends the following data to the server:
   *   1. updateUserData()
   *   2. getUserTodayDeals()
   *  None of these should get called before creating guest token
   */
  componentDidMount() {
    const {
      navigation,
      guestProfile,
      saveGuestAuthToken,
      authToken,
      lead,
      guestFirstAppOpenTime,
      registerGuestUser,
      wishlistTooltipResetAt,
    } = this.props;
    const { current_state, guestAuthToken } = guestProfile;

    if (Utility.isAndroid()) {
      this.changeNavigationBarWhite();
    }
    NetworkStats.getNetworkInformation();

    try {
      NativeModules.InstallSource.getAppLaunchTimeStamp((error, timeStamp) => {
        Utility.appLoadNative = timeStamp;
        if (!error) {
          AnalyticsManager.logEvent(EventType.appLifeCycleEvents.IMPRESSION, {
            [EventParameterKey.APP_STATE]: 'app_load_native',
            [EventParameterKey.TIME_STAMP]: Utility.appLoadNative,
          });
        }
      });
    } catch (e) {
      AnalyticsManager.logEvent(
        EventType.appLifeCycleEvents.EXCEPTION_CAPTURED,
        {
          [EventParameterKey.SOURCE]:
            'ScreenNavigator.js.js: componentDidMount',
        },
      );
    }

    Utility.isAppLaunchedFromOnboarding = false;
    this.checkDeepLinkAndNavigate();

    if (isNative()) {
      if (
        Utility.isBlank(authToken) &&
        (!guestAuthToken || Utility.isBlank(guestAuthToken))
      ) {
        this.randomGuestToken = uuidv1();
        saveGuestAuthToken(this.randomGuestToken);
        BGNetworkTaskManager.saveGuestAuthToken(this.randomGuestToken);
        registerGuestUser();
        guestFirstAppOpenTime(new Date());
        AnalyticsUtility.setGuestTokenAsProperties(this.randomGuestToken);
        this.calculateUacAndUpdateUserInfo();
      } else {
        BGNetworkTaskManager.saveGuestAuthToken(guestAuthToken);
      }
    }

    if (Utility.isAndroid()) {
      const eventEmitter = new NativeEventEmitter(NativeModules.Truecaller);
      eventEmitter.addListener('onNetworkInformation', (networkResponse) => {
        if (networkResponse.success) {
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.NETWORK_INFO,
            networkResponse,
          );
        }
      });
      NativeModules.NetworkInfo.getNetworkInformation();
      NativeModules.DeviceDetails.getDeviceMemoryInformation(
        (error, response) => {
          if (!error) {
            AnalyticsManager.logEvent(
              EventType.appLifeCycleEvents.INFO_DEVICE_MEMORY,
              {
                ...response,
              },
            );
          }
        },
      );

      this.handleFirebaseNotificationTap();
    }
    try {
      const buyNowConfig = Utility.jsonParser(
        RemoteConfig.getValue(REMOTE_CONFIG_KEYS.checkout_new),
      );
      AnalyticsManager.logEvent('user_config', {
        ...buyNowConfig,
        type: 'buy_now',
      });
      AnalyticsManager.logEvent('onboarding_config', { ...NAVIGATION_BYPASS });
      if (Utility.isAndroid()) {
        this.subscribeAppDetectEventListener();
      }
    } catch (e) {}

    if (isNative()) {
      this.getUserTodayDeals();
      const appFirstOpenedAt = moment(wishlistTooltipResetAt);
      const todayDate = moment(new Date());
  
      const { clearWishlistTooltip } = this.props;
  
      if (todayDate.diff(appFirstOpenedAt, 'days') > 5) {
        clearWishlistTooltip();
      }
    }

    if (Utility.isPresent(authToken)) {
      this.getLoyaltyPlansAndFireEvent();
    }
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      const eventEmitter = new NativeEventEmitter(NativeModules.Truecaller);
      eventEmitter.removeListener('onNetworkInformation');
      this.appDetectEventListener.removeListener('installedApps');
    }
  }

  replaceCurrentGuestToken = (newGuestToken) => {
    const { guestFirstAppOpenTime, saveGuestAuthToken, registerGuestUser } =
      this.props;
    if (
      TimeUtility.getDiffInMinsFromCurrentTime(guestFirstAppOpenTime) <
      TIME_RANGE_TO_REPLACE_GUEST_TOKEN
    ) {
      saveGuestAuthToken(newGuestToken);
      BGNetworkTaskManager.saveGuestAuthToken(newGuestToken);
      AnalyticsUtilty.setGuestTokenAsProperties(newGuestToken);
      registerGuestUser('deffered_link');
    }
  };

  handleRouteFromLink = (route, slug, path, extra = {}, params, url) => {
    const { saveBagModalOpenTimestampAndAppLaunchState } = this.props;

    const data = {
      appState: APP_LAUNCH_STATES.app_opened_with_deep_link,
    };
    saveBagModalOpenTimestampAndAppLaunchState(data);

    const { navigation, authToken, renderOnboarding } = this.props;
    const { gt = '' } = extra;
    if (Utility.isPresent(gt)) {
      this.replaceCurrentGuestToken(gt);
    }

    if (extra.externalActionRequired) {
      if (route === 'ContentModal' && path !== 'youtube_offer') {
        const { listId, videoId } = extra;
        this.getVideoListDetailById(listId, videoId);
      } else if (route === 'ContentModal' && path === 'youtube_offer') {
        const { couponCode, videoSlug } = extra;

        this.applyYoutubeOffer(couponCode, videoSlug);
      }
    } else if (Utility.isPresent(authToken)) {
      if (params?.notification_action === 'replace') {
        NavigationService.deeplinkHandledNavigation(route, {
          slug,
          extra,
          params,
          url,
        });
        console.tron.log('Auth TOke', authToken);
        return;
      }
      console.tron.log('Auth TOke2', authToken);
      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        extra,
        params,
        url,
        destination: url,
      });
    } else {
      this.dynamicLinkNavigator(route, slug, params, extra, url);
    }
    this.isOpenedFromNotificationTap = false;
  };

  dynamicLinkNavigator = (route, slug, params = {}, extra, url) => {
    console.tron.log('DynamicLinkNavigator', route);
    const {
      navigation,
      authToken,
      renderOnboarding,
      saveUserState,
      guestProfile,
      saveBagModalOpenTimestampAndAppLaunchState,
    } = this.props;

    const data = {
      appState: APP_LAUNCH_STATES.app_opened_with_deep_link,
    };
    saveBagModalOpenTimestampAndAppLaunchState(data);

    Utility.navigationParams = params;
    Utility.skipOnboardingSelfie = params.skip_selfie || params.skip_selfi;
    const { current_state } = guestProfile;

    if (
      (current_state !== CURRENT_GUEST_STATE.SKIPPED ||
        current_state === CURRENT_GUEST_STATE.INITIAL) &&
      !Utility.tabNavigatorReplaced
    ) {
      saveUserState(CURRENT_GUEST_STATE.SKIPPED);
      navigation.replace('TabNavigator');
      AnalyticsManager.logEvent(EventType.onboardingEvent.FIRST_SCREEN_LAUNCH, {
        [EventParameterKey.USER_STATE]: current_state,
        [EventParameterKey.SLUG]: slug,
      });

      Utility.isReplacedFromAppNavigator = true;
      Utility.tabNavigatorReplaced = true;
    }
    renderOnboarding(false);
    Utility.invokeSelfiePromptAfterLogin = false;
    if (params?.notification_action === 'replace') {
      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        params,
        extra,
        destination: url,
        from_deeplink: true,
      });
      return;
    }

    setTimeout(() => {
      NavigationService.deeplinkHandledNavigation(route, {
        slug,
        params,
        extra,
        destination: url,
        from_deeplink: true,
      });
    }, 30);
  };

  getLoyaltyPlansAndFireEvent = () => {
    const { getLoyaltyPlans, membership_cohort, membership_id } = this.props;
    getLoyaltyPlans((success, response) => {
      if (!success) {
        return;
      }
      new JSONAPIDeserializer({
        typeAsAttribute: false,
        pluralizeType: true,
        keyForAttribute: 'camelCase',
      })
        .deserialize(response)
        .then((data) => {
          const elligiblePlans = this.getElligiblePlansForUser(data);
          AnalyticsManager.logEvent('user_config', {
            type: 'eligible_plans',
            eligible: elligiblePlans,
            membership_cohort,
            membership_id,
          });
        });
    });
  };

  getElligiblePlansForUser = (loyaltyPlans) => {
    let eligible = '';
    loyaltyPlans.map((item) => {
      if (`${item?.ctaText}`.toLowerCase() === 'activate') {
        eligible += `${item?.id},`;
      }
    });
    return eligible.replace(/(^,)|(,$)/g, '');
  };

  calculateUacAndUpdateUserInfo = () => {
    const {
      hasUACDeviceEventTriggered,
      calculateUACAppDeviceScore,
      updateUserData,
    } = this.props;
    if (!hasUACDeviceEventTriggered) {
      if (Utility.isAndroid()) {
        calculateUACAppDeviceScore();
      }
      updateUserData();
    }
  };

  checkFcmMessagingPermission = async () => {
    if (isWeb()) return;
    const enabled = await messaging().hasPermission();
    if (!enabled) {
      this.requestFcmMessagingPermission();
    }
    const tokens = {
      fcm_token: (await messaging().getToken()) || '',
      apns_token: (await messaging().getAPNSToken()) || '',
      pseudo_user_id: (await analytics().getAppInstanceId()) || '',
    };
    return tokens;
  };

  handleFirebaseNotificationTap = () => {
    messaging()
      .getInitialNotification()
      .then((remoteMessage) => {
        // Handle notification tap when the app is not running
        const { data: { firebase_deeplink: firebaseDeeplink = '' } = {} } =
          remoteMessage;
        if (Utility.isBlank(firebaseDeeplink)) {
          return;
        }
        AnalyticsManager.logEvent(
          EventType.appLifeCycleEvents.NOTIFICATION_ACTION,
          {
            action: firebaseDeeplink,
            type: EventParameterKey.FIREBASE,
          },
        );
        this.handleDynamicLinkAction(firebaseDeeplink);
      });
  };

  handleDynamicLinkAction = (action) => {
    if (action.includes(LINK_DOMAIN_SHORT)) {
      DynamicLinkManager.resolveLink(action, this.debouncedNavigate, false);
      return;
    }
    const { appFirstOpened } = this.props;
    AnalyticsUtilty.fireAppLoadEvent(
      APP_LOAD_SOURCE.NOTIFICATION,
      appFirstOpened,
    );
    DynamicLinkManager.handleLinkWithoutInternalTrackingParams(
      action,
      this.debouncedNavigate,
    );
  };

  requestFcmMessagingPermission = async () => {
    try {
      await messaging().requestPermission();
      // User has authorised
    } catch (error) {
      // User has rejected permissions
    }
  };

  getUserTodayDeals = () => {
    const { getUserTodayDeals, selfie_image_url, getBoostedOffers } =
      this.props;

    getUserTodayDeals();
    getBoostedOffers();
  };

  postDeviceInfoCallback = (success) => {
    // this.checkDeepLinkAndNavigate();
  };

  changeNavigationBarWhite = async () => {
    try {
      const response = await changeNavigationBarColor('#ffffff', true);
      console.tron.log(response); // {success: true}
    } catch (e) {
      console.tron.log(e); // {success: false}
    }
  };

  checkDeepLinkAndNavigate = async () => {
    //As we changed homepage flow, i dnt think this flow is required now, Hence commenting this.
    // const link = await Linking.getInitialURL();
    // if (Utility.isPresent(link) && link.includes('onboarding_skip')) {
    //   Utility.isOpenedFromOnboardingDeeplink = true;
    //   await this.skipOnboarding();
    // }
    this.screenNavigator();
  };

  skipOnboarding = () => {
    const {
      changeLoginModalState,
      saveUserState,
      guestFirstAppOpenTime,
      // setForcedLogoutFlow,
    } = this.props;
    // setForcedLogoutFlow(false);
    changeLoginModalState(LOGIN_MODAL_STATE.USER_SKIPPED);
    saveUserState(CURRENT_GUEST_STATE.SKIPPED);
    guestFirstAppOpenTime(new Date());

    AnalyticsManager.logEvent(EventType.onboardingEvent.ONBOARDING_SKIP, {
      [EventParameterKey.SOURCE]:
        EventParameterValue.SOURCE.MOENGAGE_NOTIFICATION,
    });

    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_SELFIE_SCORE, {
      [EventParameterKey.SCORE]: jsonUacSelfie['yes'] || 0,
    });
  };

  screenNavigator = () => {
    const { navigation, guestProfile, appInstalledSource } = this.props;
    const { current_state } = guestProfile;

    if (Utility.getCurrentAppType() === AVAILABLE_BUILD_TYPES.foxy) {
      if (
        Utility.isBlank(current_state) ||
        current_state === CURRENT_GUEST_STATE.INITIAL
      ) {
        if (NAVIGATION_BYPASS.landingPage === 'TabNavigator') {
          Utility.tabNavigatorReplaced = true;
          Utility.isAppLaunchedFromOnboarding = true;
        }

        navigation.replace(NAVIGATION_BYPASS.landingPage);
      } else if (
        (current_state === CURRENT_GUEST_STATE.FULLY_REGISTERED ||
          current_state === CURRENT_GUEST_STATE.SKIPPED ||
          current_state === CURRENT_GUEST_STATE.SELFIE_CLICKED) &&
        !Utility.isReplacedFromAppNavigator
      ) {
        Utility.tabNavigatorReplaced = true;

        navigation.replace(NAVIGATION_BYPASS.landingPageForRegisteredUser);

        Utility.isAppLaunchedFromOnboarding = true;
      }

      AnalyticsManager.logEvent(EventType.onboardingEvent.FIRST_SCREEN_LAUNCH, {
        [EventParameterKey.USER_STATE]: current_state,
      });

      // This timeout is required to wait for getDynamicLink or getInitialLinks promise to resolve and provide the deep link.
      setTimeout(this.checkDeepLinkAndHideSplash, 500);
    }
  };

  checkDeepLinkAndHideSplash = () => {
    if (isWeb()) return;
    if (Utility.isBlank(Session.initialAppDeeplink)) {
      SplashScreen.hide();
    }
  };

  subscribeAppDetectEventListener = () => {
    try {
      this.appDetectEventListener.addListener('installedApps', (response) => {
        if (!this.app_detected) {
          if (!response) {
            AnalyticsManager.logEvent(
              EventType.appLifeCycleEvents.APP_DETECT_FAILURE,
            );
          } else {
            Utility.getInstalledApps(response);
          }
          this.app_detected = true;
          this.appDetectEventListener.removeListener();
        }
      });

      setTimeout(() => {
        NativeModules.AppDetect.getApps();
      }, 100);
    } catch (e) {}
  };

  render() {
    return <View />;
  }
}
const mapStateToProps = (state) => ({
  guestProfile: state.UserAccountInfo.guestProfile,
  authToken: state.UserAccountInfo.authToken,
  // force_logged_out_flow: state.UserAccountInfo.force_logged_out_flow,
  lead: state.UserAccountInfo.lead,
  appInstalledSource: state.UserAccountInfo.appInstalledSource,
  isUserLogoutOnce: state.UserAccountInfo.isUserLogoutOnce,
  selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
  name: state.UserAccountInfo.profile.name,
  facialProperties: state.UserAccountInfo?.facialAnalysis?.facialProperties,
  hasUACDeviceEventTriggered:
    state.UserAccountInfo.uac_events_status.has_device_event_triggered,
  membership_cohort: state.todayDeals.membership_cohort,
  membership_id: state.todayDeals?.membership_id,
  appFirstOpened: state.UserAccountInfo?.appFirstOpened,
  wishlistTooltipResetAt: state.userInteractions.wishlistTooltipResetAt,
  saveBagModalOpenTimestampAndAppLaunchState,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      saveGuestAuthToken,
      storeRemoteConfig,
      lastRemoteCacheRefreshedAt,
      saveUserState,
      changeLoginModalState,
      guestFirstAppOpenTime,
      // setForcedLogoutFlow,
      availableApps,
      calculateUACAppDeviceScore,
      updateUserData,
      getUserTodayDeals,
      registerGuestUser,
      getBoostedOffers,
      getLoyaltyPlans,
      clearWishlistTooltip,
      renderOnboarding,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ScreenNavigator);
