import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Linking, Clipboard, Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { WEB_URL, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import Config from '../../libraries/ReactNativeConfig';
import Share from '../../libraries/ReactNativeShare';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import images from '../../theme/Images';
import { isPresent, isWeb } from '../../utils/BooleanUtility';
import DermatProfileEditCard from '../pro/DermatProfileEditCard';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';

const TaskPromptCard = (props) => {
  const navigation = useNavigation();
  const handle = useSelector((state) => state.UserAccountInfo.profile.handle) || '';
  const shareTemplate = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_handle)) || {};
  const name = useSelector((state) => state.UserAccountInfo.profile.name) || '';
  useEffect(() => {
    const { previousScreen = '', itemData: { arguments: { type = '' } = {} } = {}, cardType = '' } = props;
    AnalyticsManager.logEvent(EventType.INFLUENCER.DERMAT_PROFILE, {
      [EventParameterKey.TYPE]: type || cardType,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
  }, []);

  const onTapInvite = () => {
    const { messageTemplate: { greetingText = '', closingText = '' } = {} } = shareTemplate;
    AnalyticsManager.logEvent(EventType.INFLUENCER.INFLUENCER_SHARE, {
      handle,
      screenName: SCREEN_CONSTANTS.INFLUENCER,
    });
    const title = Config.APP_NAME;
    const url = `${isWeb() ? Config.WEB_URL : Config.URI_HOST}/@${handle}`;
    const message = `${greetingText} ${name}. ${closingText}`;
    if (isWeb()) {
      if (navigator.share) {
        navigator
          .share({
            title,
            text: message,
            url,
          })
          .then(() => console.log('Successful share! 🎉'))
          .catch((err) => console.error(err));
      } else {
        Clipboard.setString(`${Config.URI_HOST}/@${handle}`);
      }
    }
    shareLink(title, message, url);
  };

  const shareLink = (title, message, url) => {
    Share.open({
      title,
      message: `${message} ${url}`,
    });
  };

  const buttonActions = {
    invite: onTapInvite,
  };
  const {
    itemData: {
      title = '',
      body = '',
      image_url = '',
      cta_text = '',
      destination = '',
      background_color: backgroundColor = colors.light_blue,
      arguments: { type = '', card_size: cardSize = '' } = {},
      external_link = '',
    } = {},
    showActionIcon = false,
    buttonAction,
    isInfluencerProfileCreated = false,
    previousScreen = '',
    isCenterAligned = false,
  } = props;

  if (isInfluencerProfileCreated) {
    <DermatProfileEditCard />;
  }

  const handleScreenNavigation = ({ route, slug }) => {
    navigation.navigate(route, { slug });
  };

  const onPressButton = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventType.INFLUENCER.DERMAT_PROFILE,
      [EventParameterKey.CTA]: cta_text,
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
    if (isPresent(destination)) {
      DynamicLinkManager.handleLinkWithoutInternalTrackingParams(destination, handleScreenNavigation);
      return;
    }
    if (isPresent(external_link)) {
      Linking.openURL(external_link).catch((err) => console.error('Error opening Google Maps', err));
      return;
    }
    if (typeof buttonActions[type] == 'function') {
      buttonActions[type]();
      return;
    }
    if (typeof buttonAction == 'function') {
      buttonAction();
    }
  };

  const BottomButton = () => {
    return (
      <FoxyShadowButton
        width={buttonWidth}
        underlayWidth={buttonWidth - 24}
        title={cta_text}
        onPress={onPressButton}
        style={styles.buttonContainer}
        textStyle={styles.buttonText}
        firstColor={colors.black}
        secondColor={colors.black}
        underlayColor={colors.black}
        actionIcon={{ uri: images.link_icon }}
        showActionIcon={showActionIcon}
      />
    );
  };

  const subContainerStyle = cardSize === 'large' ? styles.subColumnContainer : styles.subRowContainer;
  const imageStyle = cardSize === 'large' ? styles.largeImage : styles.imageContainer;
  const containerStyle =
    previousScreen === SCREEN_CONSTANTS.INFLUENCER || isCenterAligned ? styles.centerAlignContainer : styles.container;

  return (
    <View style={[containerStyle, { backgroundColor }]}>
      <View style={subContainerStyle}>
        <FastImageView resizeMode='contain' source={getMinifiedImage(image_url, imageStyle.width)} style={imageStyle} />
        <View style={styles.textContainer}>
          <Text ellipsizeMode='tail' numberOfLines={1} style={styles.title}>
            {title}
          </Text>
          <Text ellipsizeMode='tail' numberOfLines={2} style={styles.subtitle}>
            {body}
          </Text>
        </View>
      </View>
      <BottomButton />
    </View>
  );
};

export default TaskPromptCard;

const styles = StyleSheet.create({
  container: {
    padding: 16,
    width: getScreenWidth() - 24,
    backgroundColor: colors.light_blue,
    borderRadius: 12,
    alignContent: 'center',
    marginTop: 12,
  },
  centerAlignContainer: {
    padding: 16,
    width: getScreenWidth() - 24,
    backgroundColor: colors.light_blue,
    borderRadius: 12,
    alignContent: 'center',
    alignSelf: 'center',
    marginTop: 12,
  },
  subRowContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 12,
  },
  subColumnContainer: {
    alignContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 12,
  },
  imageContainer: {
    height: 60,
    width: 60,
    paddingBottom: 12,
    alignSelf: 'center',
  },
  largeImage: {
    height: 120,
    width: 176,
    paddingBottom: 12,
    alignSelf: 'center',
  },
  textContainer: {
    paddingVertical: 8,
    ...Platform.select({
      web: { width: getScreenWidth() / 1.3 },
    }),
  },
  title: {
    color: colors.foxyBlack,
    paddingHorizontal: 12,
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
    ...Platform.select({
      web: { width: getScreenWidth() / 1.3 },
    }),
  },
  subtitle: {
    paddingHorizontal: 12,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'flex-start',
    color: colors.foxyBlack,
  },
  buttonContainer: { marginTop: 12 },
  buttonText: { borderRadius: 8, paddingHorizontal: 8 },
});

const buttonWidth = getScreenWidth() - 32;
