import React, { useEffect, useRef, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  Image,
  FlatList,
  KeyboardAvoidingView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { OutlinedTextField } from 'react-native-material-textfield';
import images from '../../theme/Images';
import { ids, styles } from './styles';
import { isBlank, isDesktop, isIOS, isNative, isPresent, isWeb } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import GrayScaledImage from '../../containers/inviteCenter/GrayscaleImage';
import { getInfluencerDetails, updateInfluencerData } from '../../actions/InfluencerActions';
import { getProAccountObjectForId } from './OneLinkConfig';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import SaveProfileButton from './SaveProfileButton';
import FastImageView from '../FastImageView';
import { ScaleAnimate, StaticNavigationHeader } from '../shared';
import { fetchList } from '../../actions/ActionTypes';
import { LISTS_API_VERSION, REMOTE_CONFIG_KEYS, URL } from '../../config/Constants';
import FileUpload from './FileUpload';
import { validateYoutubeLink } from '../../utils/ValidationUtility';
import { usePostApi } from '../../lib/hooksUtil';
import FoxyAlert from '../camera/shared/FoxyAlert';
import Header from './Header';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';


const PlatformAwareKeyboardAvoidingView = withEither(
  isIOS,
  KeyboardAvoidingView,
)(View);

export default function SocialProfile() {
  const { id: currentUserId = '', profile = {} } = useSelector((state) => state.UserAccountInfo) || {};
  const [itemData, setItemData] = useState({});
  const { handle: instagramHandle = '', intro_video_present: isIntroVideoPresent = false } = profile;
  const [focusedTextField, setFocusedTextField] = useState('');
  const [instagramUsername, setInstagramUsername] = useState('');
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [youtubeChannel, setYoutubeChannel] = useState('');
  const [youtubeChannelError, setYoutubeChannelError] = useState('');
  const [proAccounts, setProAccounts] = useState({});
  const [introVideo, setIntroVideo] = useState({});
  const [testimonials, setTestimonials] = useState([]);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { postApi: updateIntroVideo, success: videoUploadSuccess, loading: videoLoading } = usePostApi();
  const { postApi: createTestimonial, success: testimonialSuccess, loading: testimonialLoading } = usePostApi();
  const [dermatSuccess, setDermatSuccess] = useState(false);
  const [dermatLoading, setDermatLoading] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [visible, setVisible] = useState(false);
  const instagramInputRef = useRef(null);
  const youtubeInputRef = useRef(null);
  const whatsappInputRef = useRef(null);
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['socialProfile']?.title;
  const headerSubtitle = headerData['socialProfile']?.subtitle;

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SOCIAL_PROFILE,
    });
  }, []);

  useEffect(() => {
    setFetchingDetails(true);
    fetchInfluencerDetails();
  }, []);

  useEffect(() => {
    if (dermatSuccess) {
      navigation.navigate('OnboardCustomers');
    }
  }, [dermatSuccess]);

  useEffect(() => {
    if (videoUploadSuccess || testimonialSuccess) setVisible(true);
  }, [videoUploadSuccess, testimonialSuccess]);

  const fetchVideos = (slug = '') => {
    dispatch(
      fetchList({ slug: `/api/${LISTS_API_VERSION}/lists/${slug}` }, 0, (success, response) => {
        if (success) {
          setItemData(response);
        }
      }),
    );
  };

  const fetchInfluencerDetails = () => {
    if (isPresent(instagramHandle)) {
      dispatch(
        getInfluencerDetails(instagramHandle, (success, response) => {
          if (success) {
            const { pro_accounts = [], testimonial_example_list = '' } = response;
            const defaultYoutubeChannel =
            getProAccountObjectForSlug(pro_accounts, 'youtube')?.user_input || '';
            const defaultInstagramHandle = getProAccountObjectForSlug(pro_accounts, 'instagram')?.user_input || '';
            const defaultWhatsappNumber = getProAccountObjectForId(pro_accounts, 36)?.user_input || ''; // change this id for production
            setYoutubeChannel(defaultYoutubeChannel);
            if (isPresent(defaultInstagramHandle)) {
              instagramInputRef.current.focus();
              setInstagramUsername(defaultInstagramHandle);
            }
            if (isPresent(defaultYoutubeChannel)) {
              youtubeInputRef.current.focus();
              setYoutubeChannel(defaultYoutubeChannel);
            }
            if (isPresent(defaultWhatsappNumber)) {
              whatsappInputRef.current.focus();
              setWhatsappNumber(defaultWhatsappNumber.replace('https://', ''));
            }
            if (isPresent(testimonial_example_list)) {
              fetchVideos(testimonial_example_list);
            }
            setProAccounts(pro_accounts);
            AnalyticsManager.logEvent(EventType.INFLUENCER.ADD_SOCIAL_MEDIA_ACCOUNTS_PAGE_VIEW, {
              current_user_id: currentUserId,
              edit: pro_accounts.length > 0,
            });
          }
          setFetchingDetails(false);
        }),
      );
    }
  };

  const PrefixIcons = ({ image, isFocused }) => {
    return (
      <GrayScaledImage
        source={{ uri: image }}
        style={{ height: 25, width: 40, resizeMode: 'contain' }}
        enableGrayscale={!isFocused}
      />
    );
  };

  const goBack = () => {
    navigation.goBack();
  };

  const updateInfluencer = () => {
    setDermatLoading(true);
    const youtubeChannelId =
      getProAccountObjectForId(proAccounts, 6)?.id || '';
    const instagramId = getProAccountObjectForId(proAccounts, 1)?.id || '';
    const whatsappId = getProAccountObjectForId(proAccounts, 36)?.id || '';
    const postData = {
      user: {
        pro_accounts_attributes: [
          {
            id: instagramId || '',
            social_media_platform_id: 1,
            user_input: instagramUsername,
          },
          {
            id: youtubeChannelId || '',
            social_media_platform_id: 6,
            user_input: youtubeChannel,
          },
          {
            id: whatsappId || '',
            social_media_platform_id: 36,
            user_input: whatsappNumber,
          },
        ],
      },
    };
    dispatch(
      updateInfluencerData(postData, (success, response) => {
        const { pro_accounts = [] } = response;
        setProAccounts(pro_accounts);
        if (success) {
          navigation.navigate('OnboardCustomers');
        }
        setDermatLoading(false);
      }),
    );
  };

  const onChangeYoutubeChannel = (value = '') => {
    setYoutubeChannel(value);
    setYoutubeChannelError('');
  };

  const handlePress = () => navigation.push('TabNavigator');

  const deleteTestimonial = (index) => {
    setTestimonials(testimonials.filter((_, currIndex) => currIndex !== index));
  };

  const addTestimonial = (testimonial) => {
    setTestimonials([...testimonials, testimonial]);
    createTestimonial(URL.DERMAT_TESTIMONIAL, {
      dermat_testimonial: { document: testimonial.data, name: testimonial.name.split('.')[0], document_type: testimonial.type },
    });
  };

  const onSubmit = () => {
    if (isPresent(youtubeChannel) && !validateYoutubeLink(youtubeChannel)) {
      setYoutubeChannelError('Please enter a valid YouTube Channel Link!');
      return;
    }
    updateInfluencer();
  };

  const uploadIntroVideo = ({ name, data, type }) => {
    setIntroVideo({ name, data, type });
    updateIntroVideo(URL.CREATE_INTRO_VIDEO, { video: data });
  };

  const closeModal = () => setVisible(false);
  const onBlurTextField = () => setFocusedTextField('');
  const onFocusInstagramTextField = () => setFocusedTextField('instagram');
  const onFocusWhatsappTextField = () => setFocusedTextField('whatsapp');
  const onFocusYoutubeTextField = () => setFocusedTextField('youtube');
  const labelOffset = { x1: -54, y1: -20, y0: -8 };
  const contentInset = { input: 8, left: 0 };

  return (
    <PlatformAwareKeyboardAvoidingView style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <ScrollView style={styles.scrollView}>
          <View dataSet={{ media: ids.formContainer }} style={styles.socialProfileContainer}>
            <View style={styles.socialProfileSubContainer}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.insta}
                    isFocused={focusedTextField === 'instagram' || isPresent(instagramUsername)}
                  />
                )}
                label='Instagram Username'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                value={instagramUsername}
                onChangeText={setInstagramUsername}
                labelOffset={labelOffset}
                contentInset={contentInset}
                onFocus={onFocusInstagramTextField}
                onBlur={onBlurTextField}
                ref={instagramInputRef}
              />
            </View>
            <View style={styles.socialProfileTextField}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.whatsapp}
                    isFocused={focusedTextField === 'whatsapp' || isPresent(whatsappNumber)}
                  />
                )}
                label='Whatsapp Number'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                value={whatsappNumber}
                onChangeText={setWhatsappNumber}
                labelOffset={labelOffset}
                contentInset={contentInset}
                onFocus={onFocusWhatsappTextField}
                onBlur={onBlurTextField}
                ref={whatsappInputRef}
                keyboardType='numeric'
                maxLength={10}
              />
            </View>
            <View style={styles.socialProfileTextField}>
              <OutlinedTextField
                renderLeftAccessory={() => (
                  <PrefixIcons
                    image={images.youtube}
                    isFocused={focusedTextField === 'youtube' || isPresent(youtubeChannel)}
                  />
                )}
                label='Youtube URL'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={labelOffset}
                contentInset={contentInset}
                onFocus={onFocusYoutubeTextField}
                onBlur={onBlurTextField}
                error={youtubeChannelError}
                value={youtubeChannel}
                onChangeText={onChangeYoutubeChannel}
                ref={youtubeInputRef}
              />
            </View>
            <View style={styles.socialProfileTextField}>
              <Text style={styles.label}>Your Introduction Video - Boost Your FoxyRx Profile</Text>
              {!introVideo.data && (
                <>
                  <Text style={styles.addIntroVideoSubheading} dataSet={{ media: ids.addIntroVideoSubheading }}>
                    Add a 15 sec video on your profile page. This will be played for the users landing on your page for
                    the first time
                  </Text>
                  <Text style={styles.addIntroVideoSubheading} dataSet={{ media: ids.addIntroVideoSubheading }}>
                    {`Here's how Dermats on FoxyRx do it! ⬇️`}
                  </Text>
                  <FlatList
                    data={itemData?.objects || []}
                    renderItem={({ item, index }) => <VideoComponent item={item} index={index} itemData={itemData} />}
                    horizontal
                    showsHorizontalScrollIndicator={false}
                    style={styles.addIntroVideoFlatlist}
                    contentContainerStyle={styles.addIntroVideoFlatlistContent}
                  />
                </>
              )}
              <FileUpload
                setFile={uploadIntroVideo}
                label='Choose File'
                name={introVideo.name}
                accept='video/*'
                fileType='video'
                sizeInMb={50}
                docType={introVideo.type}
                screenName={SCREEN_CONSTANTS.SOCIAL_PROFILE}
              />
            </View>
            <View style={styles.socialProfileTextField}>
              <Text style={styles.label}>Customer Testimonials (Upload images, videos)</Text>
              {testimonials.map(({ name, type }, index) => (
                <FileUpload
                  setFile={() => {}}
                  label='Choose File'
                  name={name}
                  accept='image/*,video/*'
                  sizeInMb={30}
                  key={name}
                  handleCancel={() => deleteTestimonial(index)}
                  docType={type}
                  screenName={SCREEN_CONSTANTS.SOCIAL_PROFILE}
                />
              ))}
              <FileUpload
                setFile={addTestimonial}
                label={testimonials.length ? 'Add File' : 'Choose File (Size limit: 30MB)'}
                name=''
                accept='image/*,video/*'
                sizeInMb={30}
                screenName={SCREEN_CONSTANTS.SOCIAL_PROFILE}
              />
            </View>
          </View>
        </ScrollView>
        <SaveProfileButton
          onPress={onSubmit}
          loading={dermatLoading || videoLoading || fetchingDetails || testimonialLoading}
          screenName={SCREEN_CONSTANTS.SOCIAL_PROFILE}
        />
        <FoxyAlert
          isVisible={visible}
          hideSecondButton
          alertBoxTitle='File upload successful'
          alertMessage="Your file is under review. We'll whatsapp you when it's approved."
          firstButtonTitle='Okay'
          firstButtonOnPress={closeModal}
          onTapOutside={closeModal}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.routines.checkedIcon}
          showImage
          isAbsolute
        />
      </View>
    </PlatformAwareKeyboardAvoidingView>
  );
}

const VideoComponent = ({ item, index, itemData }) => {
  const navigation = useNavigation();
  const openVideoPlaylist = (item, index) => {
    navigation.navigate('ContentModal', {
      listId: itemData.id,
      index,
      itemData: item,
      listData: itemData,
    });
  };
  const { image_url = '' } = item;
  if (isBlank(image_url)) return null;
  return (
    <ScaleAnimate onPress={() => openVideoPlaylist(item, index)}>
      <FastImageView source={image_url} style={styles.videoContainer} />
      <View style={styles.addIntroPlayIconContainer}>
        <FastImageView
          source={images.play_video}
          style={styles.addIntroPlayIcon}
        />
      </View>
    </ScaleAnimate>
  );
};

export const getProAccountObjectForSlug = (pro_accounts = {}, slug = '') => {
  return pro_accounts?.find((proAccount) => proAccount.social_media_platform?.slug === slug) || {};
};
