import colors from '../../../theme/Colors';
import Config from '../../../libraries/ReactNativeConfig';
import { PermissionsAndroid } from '../../../libraries/NativeLibraries';

export const API_DOMAIN = 'https://api.foxy.in';
export const HOST_NAME = 'foxy.in';
export const WEB_URL = 'https://foxy.in';
export const WWW_WEB_URL = 'https://www.foxy.in';
export const HTTP_WWW_WEB_URL = 'http://www.foxy.in';
export const SHORT_WEB_URL = 'foxy.in';
export const HTTP_WEB_URL = 'http://foxy.in';
export const TYPE_FORM_URL = 'foxyapp.typeform.com';
export const APP_INTENT_SCHEME = 'foxy://foxy.in';
export const LINK_DOMAIN_SHORT = 'links.foxy.in';
export const LINK_DOMAIN = `https://${LINK_DOMAIN_SHORT}`;
export const googleMapsApiKey = 'AIzaSyC7kQJGNf_JAwWxq_DEzfst0rVUUwP8zpY';
export const issuerServer = 'https://accounts.google.com';
export const IMAGES_S3_BUCKET = 'ekanek-foxy-assets';
export const CLOUDFRONT_URL = 'https://d1xkxb24r5qztq.cloudfront.net';
export const CDN_3 = 'https://cdn3.foxy.in';
export const EMAIL_ID = 'hello@foxy.in';
export const SUBJECT = '';
export const googleClientId =
  '467636570447-dp6mn1b4qa3tanithpcsj520k31ltgtv.apps.googleusercontent.com';
export const WHATS_APP_NUMBER = '+917428771000';
export const BASE_API = 'v1';
export const BASE_API_V2 = 'v2';
export const PRIVACY_POLICY_URL = 'https://foxy.in/privacy-policy';
export const TERM_OF_USAGE_URL = 'https://foxy.in/terms-of-use';
export const VIDEO_DISTRIBUTION_URL = 'https://d1zep23pc3t5l4.cloudfront.net';
export const VIDEO_CDN_URL = 'https://cdn1.foxy.in';
export const SELFIE_ANALYSIS_DELAYED_TIME = 7000;
export const BUILD_NO = 'android557';
export const GEOLOCATION_KEY = 'AIzaSyAp6po3qx-bFG8im7wnl6dyIt0bVUOWKyo';
export const CLOUDFRONT_IDENTIFIER = 'cloudfront.net';
export const VIDEO_UPLOAD_FORM_URL = `https://docs.google.com/forms/d/e/1FAIpQLScNpwjjpfB5cuorBW7a51SRd3KSqX4p9mAeTurYQH3UrCA-Tg/viewform`;
export const FOXY_LIVE_PACKAGE_NAME = 'in.foxy.live';
export const DEFAULT_IMAGE_FORMAT = 'webp';
export const APP_ASSET_URL = `${CDN_3}/media/app_asset/image`;
export const EMPTY_LIST_URL_FOR_SHARE = `${WEB_URL}/lists/`;
export const SALON_TYPE_FORM = `https://foxyapp.typeform.com/to/Nemi38GA#user_id=`;
export const INVITE_CENTRE_DEEPLINK = `${WWW_WEB_URL}/invite-center?group_deal_id=`;
export const SALON_QR_SCAN_SHORT = 'foxy.in/salons';
export const ONE_LINK_REGISTRATION = `${WWW_WEB_URL}/pro/sign-up`;
export const CART_ITEMS_URL = `${WWW_WEB_URL}/cart_items`;
export const PAYMENT_METHODS_URL = `${WWW_WEB_URL}/payment_methods?notification_action=replace`;
export const ORDER_PAGE_URL = `${WWW_WEB_URL}/orders`;
export const LISTS_API_VERSION = 'v7';

export const URL = {
  SEND_OTP: `${API_DOMAIN}/api/v2/users/send_otp`,
  SEND_OTP_ON_CALL: `${API_DOMAIN}/api/v2/users/make_call`,
  SEND_OTP_ON_WHATSAPP: `${API_DOMAIN}/api/v2/users/send_otp`,
  VERIFY_OTP: `${API_DOMAIN}/api/v2/users/verify.json`,
  VERIFY_HANDLE: `${API_DOMAIN}/api/v2/users/check_handle_availability?handle=`,
  ADD_PROFILE_DETAILS: `${API_DOMAIN}/api/v2/users/profile`,
  GET_LOCATION: `${API_DOMAIN}/api/v2/users/get_location?`,
  AUTOCOMPLETE_LOCALITY: `${API_DOMAIN}/api/v1/locations?q=`,
  GET_INTERESTS: `${API_DOMAIN}/api/v1/interests.json`,
  POST_SELECTED_INTERESTS: `${API_DOMAIN}/api/v2/users/interests.json`,
  POST_VIDEO_VIEW: `${API_DOMAIN}/api/v2/video_views`,
  SEARCH_PRODUCT: `${API_DOMAIN}/api/v1/products/search.json?q=`,
  AUTO_SUGGEST: `${API_DOMAIN}/api/v4/autosuggest?q=`,
  SEARCH_HASHTAG: `${API_DOMAIN}/api/v1/hash_tags?q=`,
  SEARCH_ARTIST: `${API_DOMAIN}/api/v1/artists/search_by_handle?q=`,
  MEDIA_CATEGORY: `${API_DOMAIN}/api/v1/media_categories/for_posts`,
  PUBLISH_POST: `${API_DOMAIN}/api/v1/media`,
  MEDIA_UPLOAD_SUCCESSFUL: `${API_DOMAIN}/api/v1/media/`,
  GET_INSTAGRAM_PROFILE: `${API_DOMAIN}/api/v1/instagram_profiles`,
  PROFILE_PIC_UPLOAD_URL: `${API_DOMAIN}/api/v2/users/profile_image_upload_url`,
  PROFILE_IMAGE_UPLOAD_SUCCESSFUL: `${API_DOMAIN}/api/v2/users/profile_image_upload_successful`,
  ANALYSE_FACE: `${API_DOMAIN}/api/v2/users/analyze_face`,
  GET_FACE_LANDMARKS_BY_ID: `${API_DOMAIN}/api/v2/users/face_analysis_data`,
  ARTIST_TYPE: `${API_DOMAIN}/api/v1/artist_types`,
  BRANDS: `${API_DOMAIN}/api/v1/brands?page=`,
  GET_SERVICES: `${API_DOMAIN}/api/v1/services`,
  GOOGLE_GEOCODE_API:
    'https://maps.googleapis.com/maps/api/geocode/json?latlng=',
  SEARCH_PLACE_BY_TEXT:
    'https://maps.googleapis.com/maps/api/place/autocomplete/json?components=country:ind&input=',
  GET_SALON_NEARBY: `${API_DOMAIN}/api/v1/salons`,
  youtube:
    'https://www.googleapis.com/youtube/v3/channels?part=snippet,contentDetails,statistics,brandingSettings&mine=true',
  googleProfile: 'https://www.googleapis.com/plus/v1/people/me?access_token=',
  NEAR_BY_ACADEMIES: `${API_DOMAIN}/api/v1/academies/search?q=`,
  NEAR_BY_STORE: `${API_DOMAIN}/api/v1/beauty_stores/search?q=`,
  SAVE_YOUTUBE_DATA: `${API_DOMAIN}/api/v1/google_profiles`,
  INSTAGRAM_PUBLIC_API: 'https://www.instagram.com/',
  UPLOAD_PHONE_BOOK: `${API_DOMAIN}/api/v2/contacts`,
  UPLOAD_PHONE_BOOK_V3: `${API_DOMAIN}/api/v3/contacts`,
  INSTAGRAM_PROFILE_CHECK: `${API_DOMAIN}/api/v1/instagram_profiles/check?handle=`,
  CAMPAIGN_URL: `${API_DOMAIN}/api/v2/campaigns?page=`,
  MY_CAMPAINGS_ONLY: `${API_DOMAIN}/api/v2/campaigns/mine`,
  MY_PROFILE: `${API_DOMAIN}/api/v2/users/my_profile`,
  GET_LATEST_MEDIA: `${API_DOMAIN}/api/v1/media/latest?page=`,
  GET_POPULAR_MEDIA: `${API_DOMAIN}/api/v1/media/popular?page=`,
  GET_SIMILAR_PRODUCT: `${API_DOMAIN}/api/v2/products/`,
  DELETE_MEDIA: `${API_DOMAIN}/api/v1/media/`,
  MY_REFERRALS: `${API_DOMAIN}/api/v2/users/my_referrals`,
  GET_USER_SUBMISSION_FOR_CAMPAIGN: `${API_DOMAIN}/api/v1/media/my_submission?campaign_id=`,
  SUBMIT_ARTIST_RESPONSE: `${API_DOMAIN}/api/v1/artists/`,
  SAVE_INSTAGRAM_FOLLOWERS_COUNT: `${API_DOMAIN}/api/v1/instagram_profiles/`,
  SAVE_YOUTUBE_SUBSCRIBERS_COUNT: `${API_DOMAIN}/api/v1/youtube_channels/`,
  YOUTUBE_ANALYZE: `${API_DOMAIN}/api/v1/youtube_channels/analyze`,
  UPDATE_POST: `${API_DOMAIN}/api/v1/media/`,
  REGISTER_CAMPAIGN: `${API_DOMAIN}/api/v1/campaign_registrations`,
  CANCEL_CAMPAIGN: `${API_DOMAIN}/api/v1/campaign_registrations/cancel_registration`,
  INSTAGRAM_VERIFICATION_POST: `${API_DOMAIN}/api/v2/users/insta_verification_post`,
  ARTIST_CONTENT: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/`,
  BULK_CART: `${API_DOMAIN}/api/v3/cart_items/bulk_create`,
  CART_COUPON: `${API_DOMAIN}/api/v1/cart_coupons`,
  ADD_FREE_ITEM: `${API_DOMAIN}/api/v3/cart_items/add_free_item`,
  SAVE_ARTIST_TYPE: `${API_DOMAIN}/api/v1/artists`,
  YOUTUBE_ANALYTICS:
    'https://content-youtubeanalytics.googleapis.com/v2/reports?dimensions=day&sort=day&metrics=views,subscribersGained,subscribersLost&ids=channel%3D%3DMINE&',

  GET_ARTIST_GIFT: `${API_DOMAIN}/api/v1/artists/my_gift`,
  SAVE_ARTIST_OFFERINGS: `${API_DOMAIN}/api/v1/offerings/`,
  SAVE_ARTIST_TRAININGS: `${API_DOMAIN}/api/v1/trainings/`,
  REQUEST_BOOST: `${API_DOMAIN}/api/v1/youtube_channels/request_boost`,
  REQUEST_EXPERT_REVIEW: `${API_DOMAIN}/api/v1/youtube_channels/request_expert_review`,
  YOUTUBE_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/youtube_recommendations`,
  SAVE_SALON: `${API_DOMAIN}/api/v1/artists`,
  SAVE_FREELANCER_PROFILE: `${API_DOMAIN}/api/v1/freelancer_profiles`,
  SAVE_BEAUTY_ADVISORY_PROFILE: `${API_DOMAIN}/api/v1/beauty_advisor_profiles`,
  SAVE_TRAINER_PROFILE: `${API_DOMAIN}/api/v1/trainer_profiles`,
  SAVE_STUDENT_PROFILE: `${API_DOMAIN}/api/v1/student_profiles`,
  VIDEOS_IN_REVIEW: `${API_DOMAIN}/api/v2/media/in_review`,
  INSTA_HANDLE_HINT: `${API_DOMAIN}/api/v1/instagram_profiles/probable`,
  COLLECT_CASH: `${API_DOMAIN}/api/v1/campaign_registrations/redeem_cash`,
  INSTAGRAM_PROFILE: `${API_DOMAIN}/api/v1/artists/instagram_profile`,
  STATUS_MESSAGE: `${API_DOMAIN}/api/v1/campaigns/status_messages`,
  UNLINK_GOOGLE_PROFILE: `${API_DOMAIN}/api/v1/google_profiles/my`,
  INVITE_CODE: `${API_DOMAIN}/api/v2/users/my_referrer`,
  PRODUCT: `${API_DOMAIN}/api/v1/products/`,
  USER_ATTRIBUTES: `${API_DOMAIN}/api/v1/user_attributes`,
  REQUEST_SKU: `${API_DOMAIN}/api/v1/skus/`,
  PIN_CODE: `${API_DOMAIN}/api/v1/my_pincode`,
  DELIVERY_TIME: `${API_DOMAIN}/api/v1/tats/`,
  CARD_DETAILS: `${API_DOMAIN}/api/v1/orders/card_details`,
  SHIPMENTS: `${API_DOMAIN}/api/v1/shipments`,
  UPDATE_USER_ATTRIBUTES: `${API_DOMAIN}/api/v1/user_attribute_values`,
  CART_PARAMS: `${API_DOMAIN}/api/v1/orders/card_details`,
  ARTIST_CTA: `${API_DOMAIN}/api/v1/call_to_action_options`,
  MY_COLLECTION: `${API_DOMAIN}/api/v2/my/kit`,
  ALL_ARTIST_VIDEOS: `${API_DOMAIN}/api/v1/media?artist_id=`,
  CREATE_BOOKING: `${API_DOMAIN}/api/${BASE_API}/bookings`,
  GET_EMOJI: `${API_DOMAIN}/api/${BASE_API}/emojis`,
  MY_ATTRIBUTE_VALUES: `${API_DOMAIN}/api/v2/users/my_attribute_values`,
  PRODUCT_SERVICEABILITY: `${API_DOMAIN}/api/${BASE_API}/pincodes/`,
  PRODUCT_V2: `${API_DOMAIN}/api/v2/products/`,
  OFFERS: `${API_DOMAIN}/api/v3/offers`,
  FREE_OFFER_ITEM: `${API_DOMAIN}/api/v3/cart_items/free_item`,
  DEVICES: `${API_DOMAIN}/api/v2/devices`,
  PINCODE_INFO: `${API_DOMAIN}/api/v1/pincodes/`,
  UPI_DATA: `${API_DOMAIN}/api/v1/upi_accounts`,
  NETBANKING_DETAILS: `${API_DOMAIN}/api/v1/netbanking_details`,
  CANCEL_ORDER: `${API_DOMAIN}/api/v1/orders/cancel_order`,
  UPLOAD_BASE_64_IMAGE: `${API_DOMAIN}/api/v2/users/upload_selfie_image`, //TODO:change name
  UPLOAD_PICTURE: `${API_DOMAIN}/api/v1/pictures`,
  OFFER_PROMPT: `${API_DOMAIN}/api/v1/`,
  TRUECALLER: `${API_DOMAIN}/api/v2/users/verify_truecaller`,
  GEOLOCATION_URL: `https://www.googleapis.com/geolocation/v1/geolocate?key=`,
  REVERSE_GEOLOCATION_URL: `https://maps.googleapis.com/maps/api/geocode/json?latlng=`,
  UPLOAD_SCANNED_PRODUCT_IMAGE: `${API_DOMAIN}/api/v1/product_pictures`,
  USER_ATTRIBUTE_LATEST_VIDEOS: `${API_DOMAIN}/api/v1/user_attribute_allowed_values/`,
  ATTRIBUTE_VIDEO_WATCHED: `${API_DOMAIN}/api/v1/media/`,
  STORY_URL: `${WEB_URL}/stories/player?stories=`,
  TUS_VIDEO_UPLOAD: `${API_DOMAIN}/files`,
  WEBVIEW_ORDER_LIST: `${WWW_WEB_URL}/orders`,
  RECYCLE_WITH_FOXY: `${WWW_WEB_URL}/recycle_with_foxy`,
  VALIDATE_UPI: `${API_DOMAIN}/api/v1/upi_accounts/validate_upi`,
  DELETE_CARD: `${API_DOMAIN}/api/v1/card_details`,
  GET_REWARDS: `${API_DOMAIN}/api/v1/offers/`,
  STORY_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/followings/recommend`,
  FOLLOW_RECOMMENDATIONS: `${API_DOMAIN}/api/v1/followings/`,
  ARTIST_STORY: `${API_DOMAIN}/api/v1/stories?story_rail[id]=`,
  STORY_VIEW: `${API_DOMAIN}/api/v1/combined_story_views`,
  SET_DEFAULT_ADDRESS: `${API_DOMAIN}/api/v1/addresses/`,
  OPT_FOR_PLASTIC_FREE_PACKAGING: `${API_DOMAIN}/api/v1/carts/set_plastic_free_packaging_charges`,
  UPDATE_USER_DATA: `${API_DOMAIN}/api/v2/users/update_infomation`,
  FETCH_TAG_DATA: `${API_DOMAIN}/api/v2/tags`,
  SEND_PAYTM_OTP: `${API_DOMAIN}/api/v1/paytm_accounts/send_otp`,
  VERIFY_PAYTM_OTP: `${API_DOMAIN}/api/v1/paytm_accounts/validate_otp`,
  CHECK_IF_PAYTM_ACCOUNT_EXIST: `${API_DOMAIN}/api/v1/paytm_accounts/account_exist`,
  ADD_PAYTM_MONEY: `${API_DOMAIN}/api/v1/paytm_accounts/add_money_hash?channel=WAP`,
  CHECK_BALANCE: `${API_DOMAIN}/api/v1/paytm_accounts/balance_check`,
  CHECK_UPI_TRANSACTION_STATUS: `${API_DOMAIN}/api/v1/orders/check_upi_transaction_status`,
  UNLINK_PAYTM: `${API_DOMAIN}/api/v1/paytm_accounts/revoke_access_token`,
  PRODUCT_PROMPTS: `${API_DOMAIN}/api/v1/products/`,
  OFFER_PROMPTS: `${API_DOMAIN}/api/v1/offers/`,
  CART_PROMPTS: `${API_DOMAIN}/api/v1/carts/prompts`,
  OFFERS_PROMPTS: `${API_DOMAIN}/api/v1/offers/all_prompts`,
  GET_CART_PRICING: `${API_DOMAIN}/api/v1/carts/pricing`,
  OFFER_DETAIL: `${API_DOMAIN}`,
  LIKE: `${API_DOMAIN}/api/v1/likes`,
  UNLIKE: `${API_DOMAIN}/api/v1/likes/mine`,
  RATE: `${API_DOMAIN}/api/v1/ratings`,
  GET_PAYMENT_STATUS: `${API_DOMAIN}/api/v2/payments`,
  PRICE_ALERT: `${API_DOMAIN}/api/v1/price_alerts`,
  REVIEWS: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/reviews?page=`,
  PAYOFF: `${API_DOMAIN}`,
  ALT_BRANDS: `${API_DOMAIN}`,
  FOXY_INSTAGRAM_PROFILE: 'https://www.instagram.com/foxy.famous/',
  FOLLOW_BRAND: `${API_DOMAIN}/api/v2/brands/id/follow.json`,
  UNFOLLOW_BRAND: `${API_DOMAIN}/api/v2/brands/id/unfollow.json`,
  GET_WARHOL_IMAGES: `${API_DOMAIN}/api/v2/users/my_andy_warhol_images`,
  DELETE_USER: `${API_DOMAIN}/api/v2/users/`,
  DELETE_SELFIE: `${API_DOMAIN}/api/v2/users/destroy_selfie`,
  UNLINK_DEVICE: `${API_DOMAIN}/api/v2/devices/unlink`,
  TODAY_DEALS: `${API_DOMAIN}/api/v1/flash-deals`,
  MY_LISTS: `${API_DOMAIN}/api/v2/my`,
  REGISTER_GUEST: `${API_DOMAIN}/api/v2/users/register_guest`,
  PRODUCT_FOR_REVIEW_URL: `${API_DOMAIN}/api/v1/skus/for_review`,
  GROUP_DEALS: `${API_DOMAIN}/api/v1/group_deals/`,
  GROUP_DEALS_V2: `${API_DOMAIN}/api/v2/group_deals/`,
  ACCEPT_GROUP_DEALS: `${API_DOMAIN}/api/v1/group_deal_invitations/`,
  BUILD_LIST: `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/build`,
  APPLY_FLASH_DEAL: `${API_DOMAIN}/api/v1/flash-deals`,
  GROUP_DEALS_V2: `${API_DOMAIN}/api/v2/group_deals/`,
  GOOGLE_PLACES_AUTOCOMPLETE: `${API_DOMAIN}/api/v1/places/autocomplete/?input=`,
  GOOGLE_PLACES_DETAILS: `${API_DOMAIN}/api/v1/places/details/?place_id=`,
  SETU_PAYMENTS: `${API_DOMAIN}/api/v1/setu_payments`,
  INFLUENCER_DETAILS: `${API_DOMAIN}/api/v2/profiles/handle/bio_page`,
  FOLLOW_INFLUENCER_SLUG: `api/v1/artists/id/follow.json`,
  UNFOLLOW_INFLUENCER_SLUG: `api/v1/artists/id/unfollow.json`,
  UPDATE_APP_RATING: `${API_DOMAIN}/api/v1/app_ratings`,
  ADD_DELIVERY_INSTRUCTION: `${API_DOMAIN}/api/v1/delivery_instructions`,
  HELD_ORDER: `${API_DOMAIN}/api/v1/orders/hold`,
};

export const PAYMENT_METHODS = {
  cod: 'Cash On Delivery',
};

export const PAYMENT_TYPES = {
  Cards: 'dc',
  NetBanking: 'nb',
  Cash: 'cod',
  GPay: 'gpay',
  UPI: 'upi',
};

export const ONBOARDING_STATUS = {
  OnProfileDetails: 'OnProfileDetails',
  OnInterestPage: 'OnInterestPage',
  Completed: 'Completed',
  OnLogout: 'OnLogout',
};

export const HOME_PAGE_URL = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/home-page.json`;
export const HOME_PAGE_SLUG = `/api/${LISTS_API_VERSION}/lists/home-page.json`;
export const STORE_PAGE_SLUG = `/api/${LISTS_API_VERSION}/lists/store-page.json`;
export const SEARCH_URL = `${API_DOMAIN}/api/v4/search.json`;
export const SEARCH_AUTOCOMPLETE = `${API_DOMAIN}/api/v0/autocomplete`;
export const SEARCH_AUTOSUGGEST = `${API_DOMAIN}/api/${LISTS_API_VERSION}/autosuggest`;
export const EMPTY_SEARCH_URL = `${API_DOMAIN}/api/${LISTS_API_VERSION}/empty-search`;
export const PRODUCT_DETAIL_URL = `${API_DOMAIN}`;
export const STORE_URL = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/store-page.json`;
export const ARTIST_STORY = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/story-rail?`;
export const CART_URL = `${API_DOMAIN}/api/v3/cart_items`;
export const CART_URL_NEW = `${API_DOMAIN}/api/v3/cart_items/increment`;
export const ADDRESS_URL = `${API_DOMAIN}/api/v1/addresses`;
export const ORDER_URL = `${API_DOMAIN}/api/v1/orders`;
export const ORDER_ITEM_URL = `${API_DOMAIN}/api/v1/order_items`;
export const SHIPMENT_URL = `${API_DOMAIN}/api/v1/shipments`;
export const CART_PRICING_URL = `${API_DOMAIN}/api/v3/cart_items/pricing`;
export const PRODUCT_NAME_LIMIT = 50;
export const CURRENCY_SYMBOL = '\u20B9';
export const VERIFIED_PURCHASE = 'VERIFIED PURCHASE';
export const INSTAGRAM_KEY = '64fe0a1670db46c6bc892036b454117a';
export const INSTAGRAM_REDIRECT_URL = 'https://api.foxy.in/instagram_redirect';
export const REMOTE_CONFIG_DATA_REFRESH_AFTER = 1;
export const EMPTY_CART_SLUG = `/api/${LISTS_API_VERSION}/lists/empty-cart-products.json`;
export const WISHLIST_DEALS_SLUG = `/api/v${LISTS_API_VERSION}7/lists/wishlist-deals`;
export const MY_LIKED_PRODUCTS = '/api/v2/my/liked-products';
export const EMPTY_CART_LIST_SLUG = `/api/${LISTS_API_VERSION}/lists/empty-cart-products.json`;
export const FEATURE_FLAGS = {
  // TODO: Use this flag to skip onboarding
  SKIP_ONBOARD: false,
};

export const LAYOUT = {
  RAIL: 'rail',
  GRIDRAIL: 'gridRail',
  LIST: 'list',
  GRID: 'grid',
  CARD: 'card',
  SCREEN: 'screen',
  CART: 'cart',
  PAGER: 'pager',
  VERTICALRAIL: 'verticalRail',
  PERSONALISEDRAIL: 'personalisedRail',
  STORYRAIL: 'storyRail',
  REVIEWGRID: 'reviewGrid',
  VERTICAL: 'vertical',
  HORIZONTAL_PLAYLIST: 'horizontalPlaylist',
  VERTICAL_PLAYLIST: 'verticalPlaylist',
  NAVIGATION_HEADER: 'navigationHeader',
  BANNER_WITH_RAIL: 'bannerWithRail',
  TIMER_BANNER_WITH_RAIL: 'timerBannerWithRail',
  MATCHED_PRODUCT: 'matchedProduct',
  PERSONALIZED_HORIZONTAL: 'personalizedHorizontal',
  HORIZONTALRAIL: 'horizontalRail',
};

export const CONTENT = {
  AD: 'ad',
};
export const ORIENTATION = {
  VERTICAL: 'vertical',
  HORIZONTAL: 'horizontal',
  LANDSCAPE: 'LANDSCAPE',
  PORTRAITUPSIDEDOWN: 'PORTRAITUPSIDEDOWN',
  PORTRAIT: 'PORTRAIT',
};

export const OFFER_APPLIED_BY = {
  USER_APPLIED: 'user_applied',
};

export const STORY_RAIL = {
  id: 'list-story-rail',
  slug: `/api/${LISTS_API_VERSION}/lists/story-rail.json`,
};

export const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
  GREY: 'grey',
};

export const SIZE = {
  small: 'small',
  large: 'large',
  larger: 'larger',
};

export const MAX_LINES = {
  single: 1,
  two: 2,
  three: 3,
  six: 6,
};

export const BANNER_TIMEOUT = 6;

export const SCREEN = {
  LOGIN_INFO: 'LoginInfo',
  OTP_VERIFICATION: 'OTPVerification',
  SELFIE_PROMPT: 'SelfiePrompt',
  TAKE_SELFIE: 'TakeSelfie',
  IMAGE_REVIEW: 'ImageReview',
  PROFILE_DETAILS: 'ProfileDetails',
  ATTRIBUTE_SELECTOR: 'AttributeSelector',
  SELECT_YOUR_INTEREST: 'SelectYourInterest',
  RETAIL_STORE: 'retailStore',
};

export const MORE = 'MorePage';

export const ContentPlatforms = {
  youtube: 'youtube',
  foxy: 'foxy',
};

export const ANDROID = {
  BUILD: {
    VERSIONS: {
      PIE: 28,
      OREO: 27,
      OREO_PRE: 26,
      NOUGAT: 25,
      MARSHMALLOW: 23,
      LOLLIPOP: 22,
      KITKAT: 19,
    },
  },
};

export const MORE_ITEMS = 'MoreItems';

export const DEFAULT_ANIMATION_TIME = 300;

export const MEDIA_DETAIL_INITIAL_OFFSET_X = 10.0;
export const MEDIA_DETAIL_HEADER_BUTTON_WIDTH = 160.0;

export const FOCUS_MODE = {
  SELFIE: {
    height: 250,
    width: 250,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
  PRODUCT_BURST: {
    height: 100,
    width: 100,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
  VIDEO: {
    height: 100,
    width: 100,
    tapLocationX: 0.1,
    tapLocationY: 0.1,
  },
};

export const APP_CONSTANTS = {
  IOS: {
    APP_STORE_ID: '1476031752',
    BUNDLE_ID: 'in.foxy',
  },
  ANDROID: {
    PACKAGE_NAME: 'in.foxy',
  },
};

export const PERMISSION_STORE = {
  camera: {
    android: PermissionsAndroid.PERMISSIONS.CAMERA,
    ios: 'camera',
  },
  accounts: {
    android: PermissionsAndroid.PERMISSIONS.GET_ACCOUNTS,
    ios: null,
  },
  phone_state: {
    android: PermissionsAndroid.PERMISSIONS.READ_PHONE_STATE,
    ios: null,
  },
  location: {
    android: PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
    ios: 'location',
  },
  read_contact: {
    android: PermissionsAndroid.PERMISSIONS.READ_CONTACTS,
    ios: 'contacts',
  },
  read_storage: {
    android: PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    ios: null,
  },
  write_storage: {
    android: PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
    ios: null,
  },
  record_audio: {
    android: PermissionsAndroid.PERMISSIONS.RECORD_AUDIO,
    ios: 'microphone',
  },
  photo: {
    android: PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,
    ios: 'photo',
  },
};

export const CART_CONSTANT = {
  PAYMENT_OPTION: {
    'CREDIT/DEBIT': 'Credit Card/Debit Card',
    NETBANKING: 'Netbanking',
    SAVED_CARD: 'Saved Card',
    UPI: 'UPI',
  },
};

export const TABBAR_CONSTANT = {
  feed: 'Home',
  store: 'Store',
  myCollection: 'My Kit',
  myProfile: 'Famous',
  category: 'Category',
  tag: 'Tag',
  scan: 'Scan',
};

export const GOOGLE_AUTH_CONFIG = {
  issuer: 'https://accounts.google.com',
  clientId: googleClientId,
  redirectUrl: 'in.foxy:/redirect',
  scopes: [
    'openid',
    'profile',
    'email',
    'https://www.googleapis.com/auth/youtube.readonly',
    'https://www.googleapis.com/auth/youtube',
    'https://www.googleapis.com/auth/youtube.upload',
    'https://www.googleapis.com/auth/yt-analytics.readonly',
    'https://www.googleapis.com/auth/youtube.force-ssl',
    'https://www.googleapis.com/auth/youtubepartner',
  ],
};

export const ARTIST_CONSTANTS = {
  artistContentSlug: `/api/${LISTS_API_VERSION}/lists/exclusive-for-artists.json`,
  artistList: `/api/${LISTS_API_VERSION}/lists/super-instagrammers.json`,
};

export const AVAILABLE_BUILD_TYPES = {
  foxy: 'foxy',
  artist_only: 'artist_only',
};

export const PREVIOUS_SCREEN_SOURCES = {
  artistOption: 'artistOptin',
  editProfessionalProfile: 'editProfessionalProfile',
  uncompletedOptin: 'uncompletedOptin',
};

export const OPTIN_PROCESS_STAGES = {
  INITIALLY_DISCARDED: 'INITIALLY_DISCARDED',
  COMPLETED: 'COMPLETED',
};

export const DYNAMIC_LINKS_CONFIG = {
  IOS_DOMAIN: LINK_DOMAIN,
  ANDROID_DOMAIN: LINK_DOMAIN,
  DEFAULT_IMAGE: 'https://foxy.in/foxy.png',
};

export const CURRENT_GUEST_STATE = {
  INITIAL: 'INITIAL',
  SELFIE_CLICKED: 'SELFIE_CLICKED',
  SELFIE_INTRO: 'SELFIE_INTRO',
  SELFIE_SKIPPED: 'SELFIE_SKIPPED',
  SKIPPED: 'SKIPPED',
  FULLY_REGISTERED: 'FULLY_REGISTERED',
};

export const SELFIE_STATE = {
  QUEUED: 'QUEUED',
  STARTING: 'STARTING',
  INITIATED: 'INITIATED',
  SELFIE_UPLOADED: 'SELFIE_UPLOADED',
  STARTED_ATTRIBUTES_MAPPING: 'STARTED_ATTRIBUTES_MAPPING',
  COMPLETED: 'COMPLETED',
  ERROR: 'ERROR',
  HAVE_BETAFACE_IMAGE_ID: 'HAVE_BETAFACE_IMAGE_ID',
  USER_ACCEPTED: 'USER_ACCEPTED',
};

export const APP_LOAD_SOURCE = {
  ORGANIC: 'organic',
  NOTIFICATION: 'notification',
  DYNAMIC_LINK: 'dynamic_link',
  DEEP_LINK: 'deep_link',
  PERSONALIZED_SHORTCUT: 'personalized_icon',
};

export const LOGIN_MODAL_STATE = {
  USER_SKIPPED: 'user_skipped',
};
export const REMOTE_CONFIG_KEYS = {
  min_allowed_ios_version_v2: 'min_allowed_ios_version_v2',
  min_allowed_android_version_v2: 'min_allowed_android_version_v2',
  whatsapp_orderDetails_invoice: 'whatsapp_orderDetails_invoice',
  whatsapp_orderDetail_cancel: 'whatsapp_orderDetail_cancel',
  chat_cancel_order: 'chat_cancel_order',
  whatsapp_orderDetails_needHelp: 'whatsapp_orderDetails_needHelp',
  product_returnPolicy: 'product_return_policy',
  whatsapp_brandCollab_talkToUs: 'whatsapp_brandCollab_talkToUs',
  whatsapp_myProfile_contactUs: 'whatsapp_myProfile_contactUs',
  whatsapp_artistDashboard_talkToUs: 'whatsapp_artistDashboard_talkToUs',
  available_payment_options: 'available_payment_options',
  product_authenticity: 'product_authenticity',
  whatsapp_order_details_return_order: 'whatsapp_order_details_return_order',
  artist_brand_collab_list_top_banner_url:
    'artist_brand_collab_list_top_banner_url',
  foxy_contact_info: 'foxy_contact_info',
  selfie_camera_text: 'selfie_camera_text',
  login_button_text: 'login_button_text',
  login_header_text: 'login_header_text',
  input_mobile_number_label_text: 'input_mobile_number_label_text',
  privacy_policy_tag_line: 'privacy_policy_tag_line',
  foxy_tag_line: 'foxy_tag_line',

  foreground_skip_visible_time: 'foreground_skip_visible_time',
  onboarding_background_notification_time:
    'onboarding_background_notification_time',
  phone_input_skip_countdown_timer: 'phone_input_skip_countdown_timer',
  uac_threshold: 'uac_threshold',
  uac_gender: 'uac_gender',
  uac_apps: 'uac_apps',
  uac_device: 'uac_device',
  uac_places: 'uac_places',
  video_card_subheading: 'video_card_subheading',
  video_card_heading: 'video_card_heading',
  video_card_image_url: 'video_card_image_url',
  ask_for_review_message: 'ask_for_review_message',
  brand_collab_details_connect_instagram_text:
    'brand_collab_details_connect_instagram_text',
  brand_collab_details_connect_youtube_instagram_text:
    'brand_collab_details_connect_youtube_instagram_text',
  brand_collab_add_address: 'brand_collab_add_address',
  brand_collab_request_submitted_text: 'brand_collab_request_submitted_text',
  brand_collab_connect_youtube_text: 'brand_collab_connect_youtube_text',
  text_modal_participate_in_collabs: 'text_modal_participate_in_collabs',
  covid_19_cod_message: 'covid_19_cod_message',
  contactless_checkbox_subheading: 'contactless_checkbox_subheading',
  contactless_checkbox_heading: 'contactless_checkbox_heading',
  allow_without_contactless: 'allow_without_contactless',
  show_contactless_checkbox: 'show_contactless_checkbox',
  switch_contact_less_modal_message: 'switch_contact_less_modal_message',
  is_cod_available: 'is_cod_available',
  ask_for_review_toast_message: 'ask_for_review_toast_message',
  outofstock_modal_message: 'outofstock_modal_message',
  discontinued_product_modal_message: 'discontinued_product_modal_message',
  unstocked_product_modal_message: 'unstocked_product_modal_message',
  lands_on_offers_page: 'lands_on_offers_page',

  show_artist_welcome_gift_banner: 'show_artist_welcome_gift_banner',

  out_of_stock_toast_message: 'out_of_stock_toast_message',
  special_offer_popover_text: 'special_offer_popover_text',
  non_dimensional_video: 'non_dimensional_video',
  show_square_product_card: 'show_square_product_card',
  ask_for_review_button_text: 'ask_for_review_button_text',
  UPI_APPS: 'upi_apps',
  upi_apps_ios: 'upi_apps_ios',
  PAYMENT_ENABLED: 'payment_enabled',
  plastic_free_checked_by_default: 'plastic_free_checked_by_default',
  plastic_free_packaging_charge: 'plastic_free_packaging_charge',
  show_plastic_free_packaging_checkbox: 'show_plastic_free_packaging_checkbox',
  go_green_heading: 'go_green_heading',
  go_green_subheading: 'go_green_subheading',
  go_green_subheading_selected: 'go_green_subheading_selected',
  uac_apps_commerce: 'uac_apps_commerce',
  uac_apps_beauty: 'uac_apps_beauty',
  uac_selfie: 'uac_selfie',
  uac_gender_threshold: 'uac_gender_threshold',
  uac_new_threshold: 'uac_new_threshold',
  active_offer: 'active_offer',
  android_notification_channels: 'android_notification_channels',
  new_uac_device: 'new_uac_device',
  better_selfie_text: 'better_selfie_text',
  uxcam_enabled: 'uxcam_enabled',
  default_ios_device_score: 'default_ios_device_score',
  default_ios_beauty_apps_score: 'default_ios_beauty_apps_score',
  default_ios_commerce_app_score: 'default_ios_commerce_app_score',
  plastic_free_checked_by_default: 'plastic_free_checked_by_default',
  show_plastic_free_packaging_checkbox: 'show_plastic_free_packaging_checkbox',
  go_green_heading: 'go_green_heading',
  go_green_subheading: 'go_green_subheading',
  UPI_APPS: 'upi_apps',
  recycle_with_foxy_message: 'recycle_with_foxy_message',
  prepaid_payment: 'prepaid_payment',
  hide_payment_options: 'hide_payment_options',
  hide_more_banks_nb: 'hide_more_banks_nb',
  ios_notification_request: 'ios_notification_request',
  notification_prompt_request: 'notification_prompt_request',
  foxy_promise: 'foxy_promise',
  foxy_promise_toast: 'foxy_promise_toast',
  foxy_promise_delivery: 'foxy_promise_delivery',
  community_guideline_cta: 'community_guideline_cta',
  community_guidelines_cta_url: 'community_guidelines_cta_url',
  community_guideline_heading: 'community_guideline_heading',
  delivery_info: 'delivery_info',
  app_landing: 'app_landing',
  local_notification: 'local_notification',
  user_attributes_column_display: 'user_attributes_column_display',
  number_of_attributes_to_select: 'number_of_attributes_to_select',
  new_payment_journey: 'new_payment_journey',
  cart_reminder_notification_data: 'cart_reminder_notification_data',
  customised_cart_reminder_notification_data:
    'customised_cart_reminder_notification_data',
  pagination_objects_count: 'pagination_objects_count',
  product_page: 'product_page',
  show_set_alert: 'show_set_alert',
  selfie_heading_subheading: 'selfie_heading_subheading',
  api_time_out_in_ms: 'api_time_out_in_ms',
  maxVideoDownloadLimit: 'maxVideoDownloadLimit',
  story_notification_data: 'story_notification_data',
  android_max_cache_limit: 'android_max_cache_limit',
  selfie_attribute_last_button_text: 'selfie_attribute_last_button_text',
  share_app_text: 'share_app_text',
  report_personalised_product: 'report_personalised_product',
  selfie_deletion_reasons: 'selfie_deletion_reasons',
  delete_account_reasons: 'delete_account_reasons',
  whatsapp_deleteSelfie_contactUs: 'whatsapp_deleteSelfie_contactUs',
  delete_selfie_reason_not_selected_error_message:
    'delete_selfie_reason_not_selected_error_message',
  delete_selfie_logout_toast_message: 'delete_selfie_logout_toast_message',
  connection_error: 'connection_error',
  checkout_new: 'checkout_new',
  redirect_text: 'redirect_text',
  wishlist_deals_feature_card: 'wishlist_deals_feature_card',
  foxy_promise_cart: 'foxy_promise_cart',
  daily_deals_notifications: 'daily_deals_notifications',
  disable_btl: 'disable_btl',
  versions_to_be_hard_updated_v2: 'versions_to_be_hard_updated_v2',
  review_reminder_notification_data: 'review_reminder_notification_data',
  show_cod_confirmation_modal: 'show_cod_confirmation_modal',
  versions_to_be_hard_updated_ios_v2: 'versions_to_be_hard_updated_ios_v2',
  btl_timer_text: 'btl_timer_text',
  upload_status_tray_strings: 'upload_status_tray_strings',
  product_review_page_strings: 'product_review_page_strings',
  cod_confirmation_modal_message: 'cod_confirmation_modal_message',
  review_modal_title: 'review_modal_title',
  hide_actions: 'hide_actions',
  today_deals_feature_card: 'today_deals_feature_card',
  group_deals_contacts_strings: 'group_deals_contacts_strings',
  update_shipment_status: 'update_shipment_status',
  last_wishlisted_item_time: 'last_wishlisted_item_time',
  wishlist_tooltip: 'wishlist_tooltip',
  like_gif: 'like_gif',
  checkout_wishlist_deals: 'checkout_wishlist_deals',
  wishlist_modal_data: 'wishlist_modal_data',
  pay_on_foxy_benefits: 'pay_on_foxy_benefits',
  salon_reward_modal: 'salon_reward_modal',
  influencer_page: 'influencer_page',
  mild_delay_offer_terms: 'mild_delay_offer_terms',
  notification_sounds: 'notification_sounds',
};

export const LOGIN_MODAL_MESSAGES = {
  DEFAULT: 'Login • Sign up',
  MY_KIT: 'Login to view your kit',
  ADD_TO_CART: 'Login to buy this product',
  LIKE_ARTIST: 'Login to like this artist',
  LIKE_VIDEO: 'Login to like this video',
  LIKE_COLLECTION: 'Login to like this collection',
  VIEW_PROFILE: 'Login to view your profile',
  VIEW_INVITES: 'Login to view your invite center',
  VIEW_CONTACTS: 'Login to invite your contacts',
  FOLLOW_ARTIST: 'Login to follow this artist',
  LOGIN_AFTER_ONE_DAY: 'Login to keep using the app',
  CHECKOUT: 'Login to checkout',
  RATE: 'Login to rate this product',
  PAY_WITH_FOXY: 'Login to pay with Foxy',
  WISHLIST_VIEW: 'Login to see your wishlist',
};

export const COMPETITOR_APP = {
  'com.amazon.avod.thirdpartyclient': 101,
  'in.startv.hotstar': 102,
  'com.netflix.mediaclient': 103,
  'com.graymatrix.did': 104,
  'com.tv.v18.viola': 105,
  'com.sonyliv': 106,
  'com.bsbportal.music': 107,
  'com.amazon.mp3': 108,
  'com.spotify.music': 109,
  'com.bigbasket.mobileapp': 110,
  'com.tul.tatacliq': 111,
  'com.grofers.customerapp': 112,
  'com.zeptoconsumerapp': 113,
  'app.trell': 114,
  'com.meesho.supply': 115,
  'net.one97.paytm': 116,
  'com.phonepe.app': 117,
  'com.google.android.apps.nbu.paisa.user': 118,
  'com.csam.icici.bank.imobile': 119,
  'com.dreamplug.androidapp': 120,
  'in.org.npci.upiapp': 121,
  'com.instagram.android': 122,
  'com.truecaller': 123,
  'com.facebook.katana': 124,
  'com.urbanclap.urbanclap': 125,
  'in.amazon.mShop.android.shopping': 126,
  'com.flipkart.android': 127,
  'com.myntra.android': 128,
  'com.fsn.nykaa': 129,
  'com.ril.ajio': 130,
  'com.manash.purplle': 131,
  'com.app.smytten': 132,
  'com.fsn.nds': 133,
  'com.fsn.nykaa.man': 134,
  'com.magicpin.local': 135,
  'com.licious': 136,
  'com.freshtohome': 137,
  'app.mycountrydelight.in.countrydelight': 138,
  'com.lenskart.app': 139,
  'in.swiggy.android': 140,
  'com.application.zomato': 141,
  'com.mamaearthapp': 142,
  'com.app.sugarcosmetics': 143,
  'com.myglamm.ecommerce': 144,
};

export const FACIAL_FEATURE_TAGS = [
  {
    title: 'Hair Type',
    type: 'hair_type',

    values: [],
    additionalValues: [],
  },
  {
    title: 'Hair Condition',
    type: 'hair_condition',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Hair Length',
    type: 'hair_length',
    values: [],

    additionalValues: [],
  },

  {
    title: 'Hair Colour',
    type: 'hair_colour',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Tone',
    type: 'skin_tone',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Type',
    type: 'skin_type',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Undertone',
    type: 'skin_undertone',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Skin Condition',
    type: 'skin_condition',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Face Shape',
    type: 'face_shape',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Beard Style',
    type: 'beard_style',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Beard Length',
    type: 'beard_length',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Moustache Type',
    type: 'mustache_length',
    values: [],

    additionalValues: [],
  },
  {
    title: 'Gender',
    type: 'gender',
    values: [],
    additionalValues: [],
  },
  {
    title: 'Age',
    type: 'age',
    values: [],
    additionalValues: [],
  },
];

export const BETAFACE_TAGS_MAPPING = {
  GENDER: { male: 'Male', female: 'Female' },
  MOUSTACHE_TYPE: { short: 'Thin' },
  BEARD_LENGTH: { thick: 'Full grown', Short: 'Stubble' },
  HAIR_COLOUR_MALE: {
    red: 'Coloured',
    'brown light': 'Coloured',
    'not natural light': 'Coloured',
    'not natural': 'Coloured',
    gray: 'Salt N Pepper',
  },
  HAIR_COLOUR_FEMALE: {
    red: 'Coloured',
    'brown light': 'Brown',
    'not natural light': 'Coloured',
    'not natural': 'Coloured',
  },

  HAIR_LENGTH_MALE: {
    none: 'Short',
    'very short': 'Short',
    short: 'Short',
    average: 'Medium',
    long: 'Medium',
    'very long': 'Long',
    bald: 'Shaved',
  },
  HAIR_LENGTH_FEMALE: {
    none: 'Short',
    'very short': 'Short',
    average: 'Shoulder to Mid',
    long: 'Long',
    'very long': 'Long',
  },
};

export const stepIndicatorConfig = {
  stepIndicatorSize: 12,
  currentStepIndicatorSize: 12,
  separatorStrokeWidth: 2,
  currentStepStrokeWidth: 2,
  stepStrokeCurrentColor: colors.green,
  stepStrokeWidth: 2,
  stepStrokeFinishedColor: colors.green,
  stepStrokeUnFinishedColor: '#aaaaaa',
  separatorFinishedColor: colors.green,
  separatorUnFinishedColor: '#aaaaaa',
  stepIndicatorFinishedColor: colors.green,
  stepIndicatorUnFinishedColor: '#aaaaaa',
  stepIndicatorCurrentColor: colors.green,
  stepIndicatorLabelFontSize: 12,
  currentStepIndicatorLabelFontSize: 12,
  stepIndicatorLabelCurrentColor: colors.green,
  stepIndicatorLabelFinishedColor: colors.green,
  stepIndicatorLabelUnFinishedColor: '#aaaaaa',
  labelColor: '#999999',
  labelSize: 10,
  labelfontFamily: 'Roboto-Regular',
  currentStepLabelColor: colors.green,
  finishedStepLabelColor: colors.green,
  unFinishedStepLabelColor: colors.green,
};

export const SELFIE_ANALYSIS_ERROR_CODE = {
  '001': 'initial error',
  '002': 's3 image upload error',
  '003': 'marking image successful in foxy',
  '004': 'while detecting facial features',
};

export const PERMISSIONS = {
  CAMERA: 'camera',
  RECORD_AUDIO: 'record_audio',
  READ_STORAGE: 'read_store',
  READ_PHONE_STATE: 'read_phone_state',
  LOCATION: 'location',
};

export const POPULAR_BANKS = [
  { bankCode: 'HDFB', title: 'HDFC' },
  { bankCode: 'SBIB', title: 'SBI' },
  { bankCode: 'ICIB', title: 'ICICI' },
  { bankCode: 'AXIB', title: 'AXIS' },
  { bankCode: '162B', title: 'KOTAK' },
];

export const AnalyticsScreenNames = {
  SELFIE_PROMPT: 'Selfie Prompt',
  SALON_SELECTION: 'Salon Selection',
  REFER_AND_EARN: 'Refer and Earn',
  MY_PROFILE: 'My Profile',
  MY_KIT: 'My Kit',
  FULL_SCREEN_VARIANT: 'Full Screen Variant',
  FULL_SCREEN_MODAL: 'Full screen modal',
  ARTIST: 'Artist',
  ARTIST_CARD: 'Artist Card',
  ARTIST_DETAIL: 'Artist Detail',
  ARTIST_STORY_CARD: 'Artist Story Card',
  BRAND: 'Brand',
  HOME_PAGE: 'Home Page',
  MORE_PAGE: 'More Page',
  PRODUCT_DETAIL: 'Product Detail',
  STORE: 'Store',
  OFFER_DETAIL: 'Offer Detail',
};

export const SELFIE_STATE_ON_DELAY = {
  IMAGE_UPLOAD_START: 'image_upload_start',
  IMAGE_UPLOAD_DONE: 'image_upload_done',
  WAITING_FOR_USER_ATTRIBUTES: 'waiting_for_user_attributes',
  STARTED_ATTRIBUTES_MAPPING: 'started_attributes_mapping',
  SELFIE_ANALYSIS_DONE: 'selfie_analysis_done',
};

export const DEFAULT_REMOTE_CONFIG_VALUES = {
  privacy_policy_tag_line: 'Review our',
  foxy_tag_line: 'Personalized Beauty & Grooming',

  video_card_subheading:
    'Foxy is a curated marketplace where only the top products reviewed by our experts are available. ',
  video_card_heading: 'What does this mean ?',
  video_card_image_url: '',
  ask_for_review_message: `FOXY is a curated marketplace where only the top products recommended by experts are available. We suggest you check out our expert recommended alternatives for this products.

  If you believe this product should be available on FOXY, ask for it to be reviewed.`,

  covid_19_cod_message:
    "For your and delivery partner's safety from COVID-19 we have temporarily suspended the option. Please pay online and be safe.",
  contactless_checkbox_subheading:
    'Delivery partner will leave your package outside your door and call to inform you.',
  contactless_checkbox_heading: 'Contactless Delivery',
  allow_without_contactless: 'yes',
  show_contactless_checkbox: 'yes',
  switch_contact_less_modal_message:
    "We are not accepting orders without no-contact delivery temporarily . For your and delivery partner's safety. Please switch to no-contact delivery.",
  is_cod_available: 'no',
  ask_for_review_toast_message: 'We will notify you once product is reviewed',
  outofstock_modal_message: 'This product has sold out!',
  discontinued_product_modal_message: 'This product is discontinued',
  unstocked_product_modal_message: 'This product is not reviewed',
  out_of_stock_toast_message: 'We will notifiy you',
  non_dimensional_video: `Video doesn't exist.`,
  show_square_product_card: 'yes',
  ask_for_review_button_text: 'Request taken',
  lands_on_offers_page: 'no',
  payment_enabled: 'UPI',
  uac_gender: '{"male":1,"female":3,"unknown":0}',
  uac_gender_score: 0,
  uac_new_threshold: 0,
  uac_gender_threshold: 0,
  active_offer: 0,
  better_selfie_text:
    'Make sure you are in a well lit space and hair are not tied',
  uxcam_enabled: 'no',

  default_ios_device_score: 1,
  default_ios_beauty_apps_score: 2,
  default_ios_commerce_app_score: 2,
  plastic_free_checked_by_default: 'yes',
  plastic_free_packaging_charge: 7,
  show_plastic_free_packaging_checkbox: 'yes',
  go_green_heading: 'Go Green',
  go_green_subheading: `Add plastic free packaging for ${CURRENCY_SYMBOL} 9`,
  recycle_with_foxy_message:
    'Check out this amazing initiative on Foxy, which not only makes recycling easy but also rewarding! All you need to do is collect your empty beauty & personal care products & fill in the details to claim your reward Team Foxy',
  prepaid_payment: 'digital payments',
  hide_payment_options: JSON.stringify({
    NetBanking: false,
    Cards: false,
    GPay: false,
    PayTmWallet: false,
    UPI: false,
    Cash: false,
  }),
  hide_more_banks_nb: 0,
  ios_notification_request: JSON.stringify({
    initialDisplayDuration: 0,
    displayDurationInMins: 1440,
    offers_tab_title: "You don't want to miss your awesome discounts!",
    offers_tab_subtitle: 'Allow Push notification to enjoy it!',
    payoff_page_title: 'Loving the personalised beauty suggestions?',
    payoff_page_subtitle:
      'Get notified whenever there is a price drop on your favs',
    order_success_title: 'Get the best offer updates',
    order_success_subtitle: 'The easy way.',
    product_not_reviewed_title: 'Enable Push Notifications!',
    product_not_reviewed_subtitle:
      'Let us notify you when the product is available',
    product_out_of_stock_title: 'Enable Push Notifications!',
    product_out_of_stock_subtitle:
      'Let us notify you when the products you love are available',
    recycling_page_title: 'Enable Push Notifications!',
    recycling_page_subtitle: 'Enable Push Notifications!',
    follow_artist_title: 'Never Miss Out',
    follow_artist_subtitle:
      'We will let you know as soon as your favourites post new',
    product_page_title: 'Never Miss Out',
    product_page_subtitle:
      'Get notified whenever there is a price drop on your favs',
    brand_page_title: 'Loving these Indie brands?',
    brand_page_subtitle: 'We will let you know when we have best deals for you',
    search_page_title: '',
    search_page_subtitle: '',
  }),
  notification_prompt_request: JSON.stringify({
    initialDisplayDuration: 0,
    displayDurationInMins: 4320,
    offers_tab_title: "You don't want to miss your awesome discounts!",
    offers_tab_subtitle: 'Allow Push notification to enjoy it!',
    payoff_page_title: 'Loving the personalised beauty suggestions?',
    payoff_page_subtitle:
      'Get notified whenever there is a price drop on your favs',
    order_success_title: 'Yayy! We have received your order!',
    order_success_subtitle:
      'Enable notifications to receive updates on the status of your order.',
    order_success_action_1: 'Keep me updated!',
    order_success_action_2: 'Remind me later',
    product_not_reviewed_title: 'Enable Push Notifications!',
    product_not_reviewed_subtitle:
      'Let us notify you when the product is available',
    product_out_of_stock_title: "It's not gone forever!",
    product_out_of_stock_subtitle:
      'Enable notifications to be the first to know when this product is back.',
    product_out_of_stock_action_1: 'Enable NOW',
    product_out_of_stock_action_2: 'Remind me later',
    recycling_page_title: 'Enable Push Notifications!',
    recycling_page_subtitle: 'Enable Push Notifications!',
    follow_artist_title: 'Never Miss Out',
    follow_artist_subtitle:
      'We will let you know as soon as your favourites post new',
    product_page_title: 'Never Miss Out',
    product_page_subtitle:
      'Get notified whenever there is a price drop on your favs',
    brand_page_title: 'Loving these Indie brands?',
    brand_page_subtitle: 'We will let you know when we have best deals for you',
    search_page_title: '',
    search_page_subtitle: '',
    wishlist_page_title: 'Foxy Fact: The more you wishlist the more you SAVE!',
    wishlist_page_subtitle:
      "Don't miss out on updates on your wishlist and Price Drops! P.S: We promise to keep the alerts relevant to you.",
    wishlist_page_action_1: 'Enable notifications',
    wishlist_page_action_2: 'Remind me later',
    me_page_title: 'Our experts have just the right product for you!',
    me_page_subtitle: 'Enable notifications and stay updated on latest trends!',
    me_page_action_1: "Let's do it",
    me_page_action_2: 'Another time maybe',
    order_history_title: "WAIT! You're missing out.",
    order_history_subtitle:
      'Stay up to date on the status of each order from the comfort of your home screen.',
    order_history_action_1: 'Enable notifications',
    order_history_action_2: 'Remind me later',
    feed_page_title: 'You snooze, you lose!',
    feed_page_subtitle:
      'Enable notifications to be the first to hear about exclusive offers on all your favorite brands.',
    feed_page_action_1: 'YES. Keep me updated!',
    feed_page_action_2: 'Remind me later',
  }),
  foxy_promise_toast: '{}',
  foxy_promise:
    'https://cdn2.foxy.in/eyJidWNrZXQiOiJla2FuZWstZm94eS1hc3NldHMiLCJrZXkiOiJtZWRpYS9iYW5uZXIvaW1hZ2UvNTU4L2ZveHlfcHJvbWlzZS5wbmciLCJlZGl0cyI6eyJyZXNpemUiOnsid2lkdGgiOjcyMCwiaGVpZ2h0IjowLCJmaXQiOiJjb3ZlciJ9fX0=',
  skip_selfie: false,
  foxy_promise_delivery: 'Free Delivery',
  uac_selfie: '{}',
  community_guideline_cta: 'check community guidelines',
  community_guidelines_cta_url: 'https://www.foxy.in/terms-of-use',
  community_guideline_heading:
    'Upload a short video reviewing the product and get a voucher of Rs.100 for your next order.',
  delivery_info: 'Free Delivery above Rs.399',
  local_notification: JSON.stringify({
    subject: 'Hey, Hurry!!',
    body: 'Your $(itemCount) cart items are selling fast.',
    sticky: 'true',
    expire_in: '60',
    primary_cta: 'Checkout Now',
    primary_destination: 'foxy://foxy.in/cart_items',
    secondary_cta: 'Dismiss',
    secondary_destination: 'null',
    channel: 'Offers',
  }),
  user_attributes_column_display: JSON.stringify({
    two_attributes: 2,
    three_attributes: 3,
    four_attributes: 2,
    five_or_more_attributes: 3,
  }),
  app_landing: JSON.stringify({
    s1c1: 'https://foxy.in/feed',
    s1c0: 'https://foxy.in/feed',
    s0c1: 'https://foxy.in/feed',
    s0c0: 'https://foxy.in/feed',
  }),
  number_of_attributes_to_select: 3,
  new_payment_journey: true,
  cart_reminder_notification_data: JSON.stringify({
    type: 'CART_REMINDER',
    behaviour: 'sticky',
    hours: '07',
    min: '00',
    last_order_ago: '48',
    frequency: '24',
    messages: {
      0: {
        heading: 'You have amazing taste!',
        subheading:
          'Your cart has some of our bestselling items. Complete your order before these items sell out.',
      },
      1: {
        heading: 'We’re still here to glam you up!',
        subheading:
          'Your cart wasn’t empty when you left. So, we have saved your items. Order them right away!',
      },
      2: {
        heading: 'Check out & checkout 😉',
        subheading:
          'You don’t want to miss out on the items lying in your cart. ',
      },
      3: {
        heading: 'We guarantee you’ll love us',
        subheading:
          'Complete your order and we guarantee 100% original items with a hassle free delivery. ',
      },
      4: {
        heading: 'Pssst, it’s your cart calling',
        subheading:
          'Items in your cart are waiting to be called yours. Place your order today!',
      },
      5: {
        heading: 'Come back, we’re already missing you!',
        subheading:
          'We don’t want you to lose out on the amazing offers applied on your cart. Place your order today',
      },
      6: {
        heading: 'Just checking up on you',
        subheading:
          'You dropped by but didn’t say stay ☹️Don’t miss out on the amazing offers applied on your cart. Place your order right away!',
      },
    },
  }),
  customised_cart_reminder_notification_data: JSON.stringify({
    type: 'CART_REMINDER',
    behaviour: 'sticky',
    hours: '07',
    min: '00',
    last_order_ago: '48',
    frequency: '24',
    messages: {
      1: {
        heading: '${name}, your have amazing taste!',
        subheading:
          '${count_cart} item(s) worth ₹ ${cart_value} & ${gwp_count} FREE gifts in your bag. Complete purchase? ',
        long_subheading:
          '${count_cart} item(s) worth ₹ ${cart_value}, with a discount of ${cart_discount} and ${gwp_count} FREE gift(s) in your bag. Continue to next step?',
      },
      2: {
        heading: '${step_count}/4 steps completed on your bag 🛍',
        subheading:
          '${count_cart} item(s) worth ₹ ${cart_value} & ${gwp_count} FREE gifts in your bag. Complete purchase?',
        long_subheading:
          '${count_cart} item(s) worth ₹ ${cart_value}, with a discount of ${cart_discount} and ${gwp_count} FREE gift(s) in your bag. Continue to next step?',
      },
    },
  }),
  pagination_objects_count: 10,
  product_page: JSON.stringify({
    offer: {
      heading: 'Pick your best offer',
      subheading: 'Free gift is always there. We promise.',
    },
    ingredients: {
      heading: 'What’s in it ?',
    },
    description: {
      heading: 'Everything you need to know ',
    },
    rating: {
      heading: 'Rating & Reviews',
    },
    address: {
      heading: 'Delivery Info',
    },
    promise: {
      heading: 'Foxy Promise',
    },
    foxy_live: {
      heading: 'Foxy Promise',
      subheading:
        'You’ll be taken to the Playstore to download the Foxy Live App. Try it ; It’s Free',
    },
    foxy_promise: {
      orignal: {
        heading: 'Orignal Products',
        subheading: 'Orignal Products',
      },
      delivery: {
        heading: 'Fast delivery',
        subheading: 'Fast delivery',
      },
      return: {
        heading: 'Easy Return',
        subheading: 'Easy Return',
      },
      payment: {
        heading: 'Secure Payment',
        subheading: 'Secure Payment',
      },
    },
  }),
  show_set_alert: 'no',
  selfie_heading_subheading: JSON.stringify({
    GenderAndAge: {
      heading: 'Let’s get to know about you',
      subheading: 'This helps us in suggesting a routine for you',
    },
    BeardAttributes: {
      heading: 'Let’s get to know your Beard',
      subheading: 'This helps us in suggesting a routine to you',
    },
    HairAttributes: {
      heading: 'Let’s get to know your Hair',
      subheading: 'This helps us in suggesting a routine to you',
    },
    HairGoals: {
      heading: 'What are your haircare goals ?',
      subheading: 'Pick top {number}',
    },
    SkinAttributes: {
      heading: 'Let’s get to know your Skin',
      subheading: 'This helps us in suggesting a routine to you',
    },
    SkinCareAttributes: {
      heading: 'What are your skincare goals ?',
      subheading: 'Pick top {number}',
    },
  }),
  api_time_out_in_ms: 15000,
  maxVideoDownloadLimit: 20,
  story_notification_data: JSON.stringify({
    array: [
      {
        title: 'Need Some attention?',
        description:
          'Our new personalised stories is all about YOUR needs. Check it out! 🌟',
      },
      {
        title: 'How Many Stories Do We Have Today? ',
        description: 'Guess there’s only one way to find out! 😏',
      },
      {
        title: 'A Story a Day Keeps the Bad Mood Away',
        description: 'In our case, more like infinite! 💫',
      },
      {
        title: 'Playing Hard To Get Huh?',
        description:
          'Would a new personalised stories feature help get you back? 😅',
      },
      {
        title: 'Ordered For Delicious Stories Content?',
        description: 'Coming right up! 🤤',
      },
      {
        title: 'Real People, Real Stories…',
        description:
          'And we have them specially curated just for you. Check it out now!',
      },
      {
        title: 'Confused About Your Beauty Purchase?',
        description: 'Let our personalised stories guide the way💄',
      },
      {
        title: 'Exclusive Content is Calling You!',
        description: 'Get the inside scoop you can’t find anywhere else! 🤫',
      },
      {
        title: 'Calling All Beauty Enthusiasts!',
        description:
          'Check out our latest stories and never miss a single trend! 👸🏻',
      },
      {
        title: 'The Clock is Ticking!',
        description: 'Check out our latest stories before they disappear! ⏰',
      },
    ],
  }),
  android_max_cache_limit: 20,
  android_notification_channels: JSON.stringify([
    {
      channelId: 'foxy_test',
      channelName: 'Foxy Test Channel',
      channelImportance: 'high',
      enableBadge: false,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: 'notification_sound_two',
    },
    {
      channelId: 'foxy_test_2',
      channelName: 'Foxy Test Channel 2',
      channelImportance: 'high',
      enableBadge: false,
      visibleOnLockScreen: true,
      enableVibration: true,
      enableSound: true,
      customNotificationSound: 'notification_sound_one',
    },
  ]),
  selfie_attribute_last_button_text: 'Show my personalized items',
  share_app_text:
    'Hi, check out this amazing app that talks all things beauty.  Find your perfect beauty match from a wide range of most interesting brands on Foxy where everyday is a sale-day. Download here :',
  report_personalised_product:
    'https://foxyapp.typeform.com/to/AhoRbLuW#user_name=${user_name}&user_id=${guest_token}&slug=${slug}',
  selfie_deletion_reasons: `[
    {
       "reason":"I have privacy concerns",
       "index":1
    },
    {
       "reason":"I don’t like my selfie",
       "index":2
    },
    {
       "reason":"I am facing technical issues",
       "index":3
    }
  ]`,
  delete_account_reasons: `I have privacy concerns,I don't want to use Foxy anymore,I'm getting too many messages from Foxy,I'm using another account`,
  whatsapp_deleteSelfie_contactUs:
    'Hi, I have a concern regarding my selfie on Foxy app',
  delete_selfie_reason_not_selected_error_message: 'Please select a reason',
  delete_selfie_logout_toast_message:
    'You are advised to log out from all other devices to remove your locally stored data',
  connection_error: `Something's not right. Please try checking both wifi/4G or try again later.`,
  checkout_new: JSON.stringify({
    change_add_to_cart_button: true,
    button_name: 'Add to cart',
    navigate_after_add_to_cart: true,
    address_before_cart: true,
    cart_priority: 1,
    payment_priority: 0,
    address_before_cart_for_added_products: false,
  }),
  redirect_text: 'Something went wrong, redirecting back in 3..',
  wishlist_deals_feature_card: JSON.stringify({
    title: 'Your wishlist Deals',
    subtitle: 'Insane prices on products for you today',
    guest_user_title: 'Your wishlist Deals',
    guest_user_subtitle: 'Login to unlock',
  }),
  go_green_subheading_selected: `Plastic free packaging added for ${CURRENCY_SYMBOL} 9`,
  foxy_promise_cart:
    'https://d1xkxb24r5qztq.cloudfront.net/eyJidWNrZXQiOiJla2FuZWstZm94eS1hc3NldHMiLCJrZXkiOiJtZWRpYS9iYW5uZXIvaW1hZ2UvNzUzL3BhZ2VfZW5kZXJfXzFfLnBuZyJ9',
  update_shipment_status: JSON.stringify([
    {
      reason: 'I have received my shipment already',
      index: 1,
    },
    {
      reason: 'I got shipment update directly from brand',
      index: 2,
    },
    {
      reason: 'I haven’t received my shipment',
      index: 3,
    },
  ]),
  daily_deals_notifications: JSON.stringify({
    0: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    1: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here ${product_name}",
    },
    2: {
      heading: 'Hurray',
      subheading: "Today's ${offer_discount} flash deals are here",
    },
    3: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    4: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    5: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
    6: {
      heading: 'Hurray',
      subheading: "Today's flash deals are here",
    },
  }),
  versions_to_be_hard_updated_v2: '{}',
  btl_timer_text: 'Flash deals in your bag expires in',
  review_reminder_notification_data: {
    heading: 'Review your order',
    subheading: 'Show off your new look and get 20% off on your next order.',
  },
  disable_btl: 'false',
  show_cod_confirmation_modal: 'true',
  versions_to_be_hard_updated_ios: '{}',
  cod_confirmation_modal_message: 'You are about to place your order of COD ',
  review_modal_title: 'How satisfied are you with our ordering experience?',
  versions_to_be_hard_updated_ios_v2: '{}',
  upload_status_tray_strings: JSON.stringify({
    in_progress: 'Uploading..',
    failed: 'Uploading Failed',
    success: 'Your video was successfully uploaded',
    paused: 'Paused. Waiting for better network connection',
    buttonText: {
      failed: 'RETRY',
      success: 'GOT IT',
      in_progress: 'CANCEL',
    },
  }),
  product_review_page_strings: JSON.stringify({
    placeholderBody: 'Start typing your review here',
    errorBody: 'Please enter a description',
    titlePlaceholder: 'Title',
    errorTitle: 'Please enter a title',
  }),
  cod_confirmation_modal_message:
    'You can pay via Cash/Card/UPI App at the time of delivery.',
  hide_actions: JSON.stringify({
    group_deal: false,
    wishlist_deal: false,
    invite_contacts: false,
  }),
  group_deals_contacts_strings: JSON.stringify({
    rich_message_referral:
      "Hi, I use the Foxy app for my beauty shopping and I think you're going to love it too! I am gifting you a personalised FOXY Kit worth $groupPrice that you can claim for FREE with your first order.\n \nStep 1. Install the Foxy App: $link\n \nStep 2. Claim your kit: $link\n \nDo check it out.",
    rich_message:
      'Hi, I want to buy this foxy product and found this amazing group deal which unlocks $groupPrice ($discount) savings.\n \nStep 1. Install the Foxy App: $link\n \nStep 2. Wishlist this product: $link\n \nYou will also get this deal or buy anything else with Rs 200 off  (code: ‘FOXYGROUP’).\n \nPlease confirm, so I can order.',
    remind_message_referral:
      'Hi, don’t miss out on your FREE Foxy Kit.\n \nHere’s the link if you need it again: $link',
    remind_message:
      'Hi, still waiting for you to accept my invitation.\nHere’s the link if you need it again: $link',
    popup_modal_title_subtitles: {
      referral: {
        heading: 'Gift this to your friend',
        subheading: '& have it for yourself',
      },
      group_deal: {
        heading: 'Claim the group deal',
        subheading: '',
      },
    },
    referral_popup_modal: [
      'Invite friend from your contacts to Foxy',
      'Friend install the app using your referral link',
      'Friend claim this kit with their first order',
      'You’ll get this kit abosolutely free after your friend’s order is delivered',
    ],
    group_deal_popup_modal: [
      'Invite $required_group_size friends from your contacts to Foxy',
      'They install the app and wishlist this product within 24 hours',
      'Everyone unlocks the group deal',
    ],
    today_deals_feature_card: JSON.stringify({
      with_selfie_title: 'Your Flash Deals',
      with_selfie_subtitle: 'Here Now, Gone Tomorrow!',
      without_selfie_title: 'Your Flash Deals',
      without_selfie_subtitle: 'Take your selfie to unlock daily deals',
    }),
    group_deal_card_product_page: {
      uninitialized: {
        heading: 'Get this group deal for $groupPrice',
        subheading:
          'Invite $required_group_size friends on foxy to claim the group deal',
        button_text: 'How this works?',
      },
      initialized: {
        heading: 'Get this for $groupPrice',
        subheading:
          'Waiting for $numPendingInvitesToUnlock more people to unlock',
        button_text: 'Go to Invite Center',
      },
      completed: {
        heading: 'Group deal for $groupPrice unlocked 🎉',
        subheading: 'Congrats! Unlocked successfully. Redeem now!',
      },
    },
    invite_center_empty: {
      heading: 'Share cray deals with your gang',
      subheading: 'and unlock amazing discounts together!',
      image_url: 'https://cdn3.foxy.in/media/app_asset/image/119/product.png',
      button_text: 'Find now!',
      navigateTo: 'TodayDeals',
    },
    contact_already_exists: 'Already on Foxy',
    share_link_group_deal_title: 'Claim this group deal on Foxy!',
    maxContactsVisibleInACard: 10,
    maxSmsAllowed: 15,
    maxSmsSelectedToast: 'Only $maxSmsAllowed sms can be sent at once!',
    referral_gift_data: {
      image:
        'https://cdn3.foxy.in/media/app_asset/image/139/beauty_starter_kit_by_foxy_1__1_.png',
      mrp: 1058,
      name: 'Personalised Foxy kit with a pouch and 5 minis',
      slug: `api/${LISTS_API_VERSION}/lists/free-foxy-kit`,
    },
  }),
  last_wishlisted_item_time: 0,
  wishlist_tooltip: JSON.stringify({
    no_of_time_show_hint: 2,
    no_of_time_show_tooltip: 4,
  }),
  like_gif:
    'https://cdn3.foxy.in/media/app_asset/image/125/item_like_for_autosearch_and_modal.gif',
  checkout_wishlist_deals: 'Checkout wishlist deals',
  wishlist_modal_data: JSON.stringify({
    heading: 'Yay! FLASHDEAL unlocked!',
    subheading:
      'You just unlocked ${wishlist_discount}% OFF on this product. Keep wishlisting to get more discounts!',
    heading_flashdeal_inactive: 'You have a chance to get ${this.wishlistDiscount}% OFF',
    subheading_flashdeal_inactive:
      'Products you wishlist have a higher chance of appearing on Flashdeals! Keep wishlistin’ 😉',
  }),
  pay_on_foxy_benefits: JSON.stringify({
    header_title: 'Why should I pay on Foxy?',
    heading_1: 'Pay the Salon on Foxy',
    sub_heading_1: 'and get assured rewards everytime',
    find_salon_heading: 'Find the salon you’re at',
    find_salon_sub_heading:
      'You can search it or scan the QR code present in the store',
    pay_on_foxy_heading: 'Pay the salon using Foxy',
    pay_on_foxy_sub_heading:
      'And get rewards! Everytime!! From a Beauty Kit worth INR 1500, to discounts upto 40% on beauty products. ',
    heading_2: 'Spin the wheel and get a reward everytime!',
    sub_heading_2: 'and get assured rewards everytime',
    heading_3: 'Add Important Info',
    recommend_foxy_heading: 'Recommend us a salon for Foxy',
    recommend_foxy_sub_heading:
      'Become a local guide for Foxy by providing us salon details of salons',
    verify_foxy_heading: 'Wait for us to verify the details',
    verify_foxy_sub_heading:
      'And get INR 1000 vouchers on Foxy once we sign up the salon for “Pay on Foxy”',
    button_text: 'Got it',
  }),
  salon_reward_modal: JSON.stringify({
    step_1: 'You can check out this offer on your Home Page & My Profile',
    step_2: 'Offer valid for the next 48 hours. Use it today!',
  }),
  influencer_page: JSON.stringify({
    horizontal_social_accounts_list: true,
  }),
  notification_sounds: JSON.stringify([
    'https://cdn3.foxy.in/generic_files/app_asset/generic_file/381/notification_sound_one.m4r',
    'https://cdn3.foxy.in/generic_files/app_asset/generic_file/381/notification_sound_one.m4r',
  ]),
  mild_delay_offer_terms: JSON.stringify({
    0: 'Foxy voucher for your next order',
    1: 'The voucher code will be sent to you via Whatsapp on the delivery of your current order ',
    2: 'You can also view your offers on the personalised page',
  }),
};

export const NOTIFICATION_REQUEST_MODAL_TYPE = {
  OFFERS_TAB: 'offers_tab',
  PAYOFF_PAGE: 'payoff_page',
  ORDER_SUCCESS: 'order_success',
  PRODUCT_NOT_REVIEWED: 'product_not_reviewed',
  PRODUCT_OUT_OF_STOCK: 'product_out_of_stock',
  RECYCLING_PAGE: 'recycling_page',
  FOLLOW_ARTIST: 'follow_artist',
  PRODUCT_PAGE: 'product_page',
  BRAND_PAGE: 'brand_page',
  SEARCH_PAGE: 'search_page',
  FEED_PAGE: 'feed_page',
  STORE_PAGE: 'store_page',
  WISHLIST_PAGE: 'wishlist_page',
  ME_PAGE: 'me_page',
  ORDER_HISTORY: 'order_history',
};

export const TRUECALLER_RAW_ERRORS = {
  1: 'Network Failure',
  2: 'User pressed back',
  3: 'Incorrect Partner Key',
  4: 'User Not Verified on Truecaller',
  5: 'Truecaller App Internal Error',
  13: 'User pressed back while verification in process',
  14: 'User pressed SKIP or USE ANOTHER NUMBER',
  15: 'Truecaller sdk not usable',
};

export const DEFAULT_DEVICE_INFORMATION_OBJECT = {
  deviceModel: null,
  deviceBrand: null,
  deviceName: null,
  deviceDisplayID: null,
  deviceHardware: null,
  deviceChangeListNumber: null,
  deviceManufacturer: null,
  deviceOverallProductName: null,
  deviceUser: null,
  deviceApiLevel: null,
  deviceImei: null,
  deviceImsi: null,
  connectionType: null,
  effectiveConnectionType: null,
  email: null,
  generalDeviceInfo: {},
};

export const UPLOAD_NOTIFICATION_TEXT = {
  IMAGE_UPLOAD_TITLE: 'Uploading your selfie',
  IMAGE_UPLOAD_DESCRIPTION: 'Please wait',
  ANALYSIS_START_TITLE: 'Analysing your selfie',
  ANALYSIS_END_DESCRIPTION: 'Personalized feed coming up shortly',
};

export const BLOCKED_UXCAM_EVENTS = {
  page_load: true,
  list_rendered: true,
  perfect: true,
  selfie_analysis_state: true,
  face_not_detected: true,
  attribute_update: true,
  modal_view: true,
  video_buffering_stop: true,
  app_background: true,
  list_item_click: true,
  prompt_visible: true,
  attribute_detect: true,
  page_load_delay: true,
  content_error: true,
  too_close: true,
  app_foreground: true,
  info_device_memory: true,
  android_sms_listener_unregister: true,
  selfie_prompt_view: true,
  delivery_info_visible: true,
  uac_selfie_score: true,
  zoom_modal_click: true,
  foxy_live_element: true,
  campaign_view: true,
  uac_new_score: true,
  selfie_analysis_retry: true,
  search_open: true,
  selfie_camera_open: true,
  screen_flash_on: true,
  otp_detect: true,
  app_detect: true,
};

//Routes defined in this should be equivalent to routes defined in your AppNavigator, because navigation will act upon these routes.
export const NAVIGATION_BYPASS = {
  enable: true,
  landingPage: 'TabNavigator',
  landingPageForRegisteredUser: 'TabNavigator',
  screenAfterPhoneNumberInput: 'SelfieIntro',
  screenAfterSelfiePrompt: 'TabNavigator',
};

// In secs
export const TIME_RANGE_TO_REPLACE_GUEST_TOKEN = 40;

export const APP_LAUNCH_STATES = {
  organic: 'organic',
  app_launched_with_cart_items: 'app_launched_with_cart_items',
  app_opened_with_deep_link: 'app_opened_with_deep_link',
};
export const APP_STORE_LINK =
  'https://apps.apple.com/in/app/apple-store/1476031752';
export const PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=in.foxy';
export const UPLOAD_STATUS_TRAY_CONSTANTS = {
  paused: 'paused',
  in_progress: 'in_progress',
  failed: 'failed',
  success: 'success',
};

export const IOS_FALLBACK_URL = `https://apps.apple.com/in/app/foxy-personalised-beauty-app/id${APP_CONSTANTS.IOS.APP_STORE_ID}`;
export const ANDROID_FALLBACK_URL = `https://play.google.com/store/apps/details?id=${APP_CONSTANTS.ANDROID.PACKAGE_NAME}`;
export const REGEX = {
  EMAIL: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/,
};
export const END_STATES = {
  add_delivery_instructions: {
    heading: 'Thank you ',
    subheading: 'We’ll deliver your order asap!',
  },

  update_shipment_status: {
    heading: 'Thank you!',
    subheading: 'Allow us some time to get back to you.',
  },
  upload_video: {
    heading: 'Your video uploaded successfully ✨',
    subheading: 'Once approved, we’ll send you your gift via Whatsapp. 😊',
  },
  get_detailed_status_from_brand: {
    heading: 'Thank you',
    subheading:
      'Our operations team will get in touch with you via email on priority',
  },
};
export const SALON_QR_SCAN_ALIGNMENT = {
  x: 233,
  y: 145,
};
export const SALON_QR_SCAN_OVERLAY = {
  x: 291,
  y: 444,
};
export const SALON_SCAN_OVERLAY_HEIGHT = 470;

export const STATUS_BAR_HEIGHT = {
  ANDROID: 56,
  IOS: 44,
};
export const SALON_IN_PROGRESS_TIME_LABELS = { m: null, s: null };
export const SALON_IN_PROGRESS_TIME_FORMAT = ['M', 'S'];
export const SALON_LOCATION_PERMISSION_MODAL_DATA = {
  heading: 'Permission Required',
  subheading:
    'Change access permission inside Settings to allow location access',
};
export const SALON_CAMERA_PERMISSION_MODAL_DATA = {
  heading: 'Permission Required',
  subheading: 'Change access permission inside Settings to allow camera access',
};
export const API_ERRORS = {
  SERVER_ERROR: 'server_error',
  CONNECTION_ERROR: 'connection_error',
};

export const GENDER_IDS = {
  male: 62,
  female: 63,
};

export const STATUSBAR_HEIGHT = {
  IOS: 44,
  Android: 56,
};

export const PAGINATION_API_LIMIT = 5;

export const NOTIFICATION_IDENTIFIERS = {
  CART_REMINDER: 'cartPaymentNotificationIdentifier',
  PAYMENT_SUCCESS: 'paymentSuccessNotificationIdentifier',
  PROFILE_PIC_UPLOAD: 'ProfilePicUploadIndentifier',
};
