import React from 'react';
import { View, StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';

const shimmerColors = ['#eeeeee', '#ddddddff', '#eeeeee'];

const TitleShimmer = () => (
  <View>
    <ShimmerPlaceHolder colorShimmer={shimmerColors} autoRun style={styles.titleShimmer} />
    <ShimmerPlaceHolder colorShimmer={shimmerColors} autoRun style={styles.subtitleShimmer} />
  </View>
);

const ContentShimmer = () => (
  <View>
    <ShimmerPlaceHolder colorShimmer={shimmerColors} autoRun style={styles.contentShimmer} />
  </View>
);

const ProductAplusShimmer = () => (
  <View style={styles.shimmerContainer}>
    <TitleShimmer />
    <ContentShimmer />
  </View>
);

export default ProductAplusShimmer;

const styles = StyleSheet.create({
  shimmerContainer: {
    paddingHorizontal: 12,
    paddingTop: 12,
  },
  webviewLoadContainer: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  titleShimmer: {
    height: 24,
    width: 240,
    borderRadius: 8,
  },
  subtitleShimmer: {
    height: 20,
    width: 200,
    borderRadius: 8,
    marginTop: 6,
    marginBottom: 16,
  },
  contentShimmer: {
    height: 150,
    width: getScreenWidth() - 24,
    borderRadius: 8,
    marginBottom: 4,
  },
  homeShimmerContainer: {
    flex: 1,
    height: getScreenHeight(),
    marginTop: -(getScreenHeight() / 1.3),
  },
});
