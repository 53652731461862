//TODO: Need to move this file to server

import React from 'react';
import { Linking, ScrollView, StyleSheet, Text, View } from 'react-native';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import { SHORT_WEB_URL, WEB_URL } from '../../config/Constants';
import Config from '../../libraries/ReactNativeConfig';

const PrivacyPolicy = () => (
  <ScrollView style={styles.container}>

    <Text style={styles.paragraph}>We welcome you to this digital platform accessible through the website and the mobile applications (“Platform”), and congratulate you for signing up on the Platform. The Platform and all its derivatives are owned and operated by EkAnek Networks Private Limited (the “Company” or “Foxy”). The terms “we”, “our”, “ours”, and “us” used below refers to the Company. You are on this page as you have chosen to sign up on the Platform as a user. The terms “you”, “your”, and “yours” refer to the user. This policy describes:</Text>
    <Text style={styles.paragraph}> {'        \u25CF  the types of information that Foxy may collect from you when you access or use its websites, mobile applications and other online services (collectively, referred as "Services"); and'}</Text>
    <Text style={styles.paragraph}> {'        \u25CF  its practices for collecting, using, maintaining, protecting and disclosing that information.'}</Text>
    <Text style={styles.paragraph}> {'Please read this policy carefully to understand Foxy’s  policies and practices regarding your information and how Foxy will treat it. By accessing or using its Services and/or registering for an account with Foxy, you agree to this privacy policy and you are consenting to Foxy’s collection, use, disclosure, retention, and protection of your personal information as described here. If you do not provide the information Foxy requires, Foxy may not be able to provide all of its Services to you.'}</Text>
    <Text style={styles.paragraph}> {'This policy may change from time to time, your continued use of Foxy’s Services after it makes any change is deemed to be acceptance of those changes, so please check the policy periodically for updates.'}</Text>
    <View style={styles.separator} />

    <Text style={styles.bold}>The information we collect and how we use it</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}> Foxy collects several types of information from and about users of our Services, including:</Text>
    <Text style={styles.paragraph}> {'        \u25CF  Your Personal Information - Personal Information is the information that can be associated with a specific person and could be used to identify that specific person whether from that data, or from the data and other information that we have, or is likely to have access to. We do not consider personal information to include information that has been made anonymous or aggregated so that it can no longer be used to identify a specific person, whether in combination with other information or otherwise.'}</Text>
    <Text style={styles.bold}>{'        \u25CF  Information about your internet connection, the equipment you use to access our Services and your usage details.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}> We collect this information:</Text>
    <Text style={styles.bold}>{'        \u25CF  directly from you when you provide it to us; and/or'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  automatically as you navigate through our Services (information collected automatically may include usage details, IP addresses and information collected through cookies, web beacons and other tracking technologies).'}</Text>
    <View style={styles.separator} />
    <View style={styles.separator} />
    <Text style={styles.bold}>{'Information you provide to us'}</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>The information we collect on or through our Services may include:</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your account information: Your full name, email address, postal code, password and other information you may provide with your account, such as your gender, mobile phone number and website. Your profile picture that will be publicly displayed as part of your account profile. You may optionally provide us with this information through third-party sign-in services such as YouTube, Instagram, Facebook, Google etc.. In such cases, we fetch and store whatever information is made available to us by you through these sign-in services.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your preferences: Your preferences and settings such as time zone and language.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your content: Information you provide through our Services, including your profile,  reviews, media, comments, updates, followers, ordering details and history, and other information you provide on our Services.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your searches and other activities: The search terms you have looked up and results you selected.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your browsing information: How long you used our Services and which features you used; the ads you clicked on.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your communications: Communications between you and other users, artists and sellers through our Services; your participation in a survey, poll, sweepstakes, contest or promotion scheme; your request for certain features (e.g., newsletters, updates or other products); your communication with us about employment opportunities posted to the services.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your transactional information: If you make a transaction through our Services, we may collect and store information about you to process your requests and automatically complete forms for future transactions, including (but not limited to) your phone number, address, email, billing information and credit or payment card information. This information may be shared with third-parties which assist in processing and fulfilling your requests, including PCI compliant payment gateway processors.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your Public Posts: You also may provide information (such as media, ratings, reviews, comments, likes, bookmarks, friends, etc.) to be published or displayed (hereinafter, "posted") on publicly accessible areas of our Services, or transmitted to other users of our Services or third-parties (collectively, "User Contributions"). Your User Contributions are posted on and transmitted to others at your own risk.  Please be aware that no security measures are perfect or impenetrable (see "Security" section below). Additionally, we cannot control the actions of other users of our Services with whom you may choose to share your User Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons. We may display this information on the Services, share it with businesses, and further distribute it to a wider audience through third party sites and services. You should be careful about revealing any sensitive details about yourself in such postings.'}</Text>
    <Text style={styles.paragraph}>{'We use the information you provide to us to enhance the functionality and improve the quality of our Services, and to personalize your experience while using our Services. We also use this information to display relevant advertising, provide support to you, communicate with you, and comply with our legal obligations.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>Selfie Analysis and Face Data Processing</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>Foxy features an advanced selfie analysis tool that estimates various facial attributes, including gender, age, facial features (shape, size, location), eyes, hair, skin, facial hair amount, and hairstyle. This data is utilized to recommend products tailored for you based on your face, skin, or hair attributes.</Text>
    <Text style={styles.paragraph}>The collected face data is exclusively used within our app to enhance your personal shopping experience by recommending relevant beauty and skincare products. It is not shared with any third parties, except for the purpose of defining customer segments for targeted advertising and communication using third-party tools.</Text>
    <Text style={styles.paragraph}>Your face data is securely stored on our Google cloud servers. We implement robust security measures to protect your data from unauthorized access or disclosure. We are committed to ensuring the confidentiality and integrity of your personal information.</Text>
    <Text style={[styles.paragraph, styles.row]}>We retain your face data as long as your profile exists in our app. This enables us to continually offer personalized product recommendations each time you return to the app. You have the right to delete your profile and, consequently, all associated face data at any time. To do so, please follow the steps given at <Text onPress={() => Linking.openURL('/privacy-concerns')} style={styles.link}>{`${WEB_URL}/privacy-concerns.`}</Text></Text>
    <Text style={styles.bold}>{'Information you choose to share from your social networks'}</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>{'We may collect, process and store your user ID associated with any social media account (e.g. YouTube, Instagram, Facebook, Twitter, or Google) that you use to sign into the Services or connect with or use with the Services. When you sign in to your account with your social media account information, or otherwise connect to your social media account with the Services, you consent to our collection, storage, and use, in accordance with this Privacy Policy, of the information that you make available to us through the social media interface. This could include, without limitation, any information that you have made public through your social media account, information that the social media service shares with us including your contact list, or information that is disclosed during the sign-in process. For further information as to how and for what purpose the social network provider processes your data, please see the relevant privacy policies of these social network providers.'}</Text>
    <Text style={styles.bold}>Information We Collect Through Automatic Data Collection Technologies</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We may automatically collect certain information about the computer or devices (including mobile devices) you use to access the Services, and about your use of the Services, even if you use the Services without registering or logging in.</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Usage information: Details of your use of our Services, including traffic data, location data, logs and other communication data and the resources that you access and use on or through our Services.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Computer and device information: Information about your computer, Internet connection and mobile device, including your IP address, operating systems, platforms, browser type, other browsing information (connection, speed, connection type etc.), device type, devices unique device identifier, mobile network information and the devices telephone number.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Stored information and files: Our applications also may access metadata and other information associated with other files stored on your mobile device. This may include, for example, photographs, audio and video clips, personal contacts and address book information.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Location information: Our applications collect real-time information about the location of your device, as permitted by you.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Last URL visited: The URL of the last web page you visited before visiting our websites.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Mobile device IDs: Unique mobile device identifier (e.g. IDFA or other device IDs on Apple devices like the iPhone and iPad), if you are using our Services on a mobile device, we may use mobile device IDs (the unique identifier assigned to a device by the manufacturer), instead of cookies, to recognize you. We may do this to store your preferences and track your use of our applications. Unlike cookies, mobile device IDs cannot be deleted. Advertising companies may use device IDs to track your use of our applications, track the number of advertisements displayed, measure advertising performance and display advertisements that are more relevant to you. Analytics companies may use mobile device IDs to track your usage of our applications.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your preferences: Your preferences and settings such as time zone and language.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Your activity on the Services: Information about your activity on the Services, such as your search queries, comments, domain names, search results selected, number of clicks, pages viewed and the order of those pages, how long you visited our Services, the date and time you used the Services, error logs, and other similar information.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Mobile status: For mobile application users, the online or offline status of your application.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Applications: If you use the Foxy application, Foxy may collect information about the presence and/ or absence and/ or details pertaining to other applications on your mobile phone. The applications we gather information for, may vary across categories including, without limitation, beauty, shopping, fashion and social media. This will help us understand you and your preferences better and enable Foxy to provide you with a personalized experience.'}</Text>

    <Text style={styles.bold}>Cookies and Other Electronic Tools</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We, and third parties with whom we partner, may use cookies, pixel tags, web beacons, mobile device IDs, "flash cookies" and similar files or technologies to collect and store information in respect to your use of the Services and third party websites. A cookie is a small text file that is stored on your computer that enables us to recognize you (for example, as a registered user) when you visit our website, store your preferences and settings, enhance your experience by delivering content and advertising specific to your interests, perform research and analytics, track your use of our Services, and assist with security and administrative functions. Cookies may be persistent or stored only during an individual session. To understand more about cookies, click here https://www.aboutcookies.org. A pixel tag (also called a web beacon or clear GIF) is a tiny graphic with a unique identifier, embedded invisibly on a webpage (or an online ad or email), and is used to count or track things like activity on a webpage or ad impressions or clicks, as well as to access cookies stored on users' computers. Foxy uses pixel tags to measure the popularity of our various pages, features and services. We also may include web beacons in e-mail messages or newsletters to determine whether the message has been opened and for other analytics.</Text>
    <Text style={styles.paragraph}>Most browsers are set to automatically allow cookies. Please note it may be possible to disable some (but not all) cookies through your device or browser settings, but doing so may interfere with certain functionality on the Services. Major browsers provide users with various options when it comes to cookies. Users can usually set their browsers to block all third-party cookies (which are those set by third-party companies collecting information on websites operated by other companies), block all cookies (including first-party cookies such as the ones Foxy uses to collect search activity information about its users), or block specific cookies. To modify your cookie settings, please visit your browser's help settings. You will need to opt out on each browser and each device you use to access the Services. Flash cookies operate differently than browser cookies and cannot be removed or blocked via web browser settings. By using our Services with your browser set to accept cookies you are consenting to our use of cookies in the manner described in this section. For more information please read our Cookie Policy.</Text>
    <Text style={styles.paragraph}>Third parties whose products or services are accessible or advertised through the Services, including social media services, may also use cookies or similar tools, and we advise you to check their privacy policies for information about their cookies and other practices. We do not control the practices of such partners and their privacy policies govern their interactions with you.</Text>

    <Text style={styles.bold}>Information from Third Parties</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We may also obtain information about you from third parties such as partners, marketers, third-party websites, and researchers, and combine that information with the information we collect from or about you.</Text>
    <View style={styles.separator} />
    <View style={styles.separator} />

    <Text style={styles.bold}>Anonymous or De-Identified Data</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We may anonymize and/or de-identify information collected from you through the Services or via other means, including via the use of third-party web analytic tools as described below. As a result, our use and disclosure of aggregated and/or de-identified information is not restricted by this Privacy Policy, and it may be used and disclosed to others without limitation.</Text>
    <Text style={styles.paragraph}>How we use the information we collect</Text>
    <Text style={styles.paragraph}>We use the information we collect from and about you for a variety of purposes, including to:</Text>
    <Text style={styles.bold}>{'        \u25CF  Process and respond to your queries'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Understand our users (what they do on our Services, what features they like, how they use them, etc.), improve the content and features of our Services (such as by personalizing content to your interests), process and complete your transactions, and make special offers.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Administer our Services and diagnose technical problems.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Send you communications that you have requested or that may be of interest to you by way of emails, or courier, or registered post, or telephone calls, or any other mode of communication. We may also share your preferences or the Services availed by you with your network followers on Foxy for marketing and other promotional activities of our Services.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Send you questions from other users that you may be able to answer if you have registered with Foxy.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Enable us to show you ads that are relevant to you.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Generate and review reports and data about, and to conduct research on, our user base and Service usage patterns.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Administer contests and sweepstakes.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Provide you with customer support.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Provide you with policies about your account.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Notify you about changes to our Services.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  Allow you to participate in interactive features offered through our Services.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  In any other way we may describe when you provide the information.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.bold}>{'        \u25CF  For any other purpose with your consent.'}</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We may use the information we have collected from you to enable us to display advertisements to our advertisers'/service providers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</Text>
    <View style={styles.separator} />

    <Text style={styles.bold}>How we share the information we collect</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We may disclose personal information that we collect or you provide, as described in this privacy policy, in the following ways:</Text>
    <Text style={styles.paragraph}>{'        \u25CF  To our subsidiaries and affiliates, which are entities under common ownership or control of our ultimate parent company EkAnek Networks Private Limited.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  To contractors, advertisers/service providers and other third-parties whom we use to support our business (e.g. sellers and artists  on the platform) and who are bound by contractual obligations to keep personal information confidential and use it only for the purposes for which we disclose it to them.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Foxy’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal information held by Foxy about the users of our Services are among the assets transferred.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  To third-parties to market their products or services to you if you have consented to receive the promotional updates. We contractually require these third-parties to keep personal information confidential and use it only for the purposes for which we disclose it to them.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  To fulfill the purpose for which you provide it.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  For any other purpose disclosed by us when you provide the information.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Service Providers. We may share your information with outside vendors that we use for a variety of purposes, such as to send you communications via emails, messages or tele-call to inform you about our products that may be of interest to you, push notifications to your mobile device on our behalf, provide voice recognition services to process your spoken queries and questions, help us analyze use of our Services, and process and collect payments. Some of our products, services and databases are hosted by third party hosting services providers. We also may use vendors for other projects, such as conducting surveys or organizing sweepstakes for us. We may share information about you with these vendors only to enable them to perform their services.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Legal Purposes. We may share your information when we believe in good faith that such sharing is reasonably necessary in order to investigate, prevent, or take action regarding possible illegal activities or to comply with legal process. We may also share your information to investigate and address threats or potential threats to the physical safety of any person, to investigate and address violations of this Privacy Policy or the Terms of Service, or to investigate and address violations of the rights of third parties and/or to protect the rights, property and safety of Foxy, our employees, users, or the public. This may involve the sharing of your information with law enforcement, government agencies, courts, and/or other organizations on account of legal request such as subpoena, court order or government demand to comply with the law.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Social Networks. If you interact with social media features on our Services, such as the Facebook Like button, or use your social media credentials to log-in or post content, these features may collect information about your use of the Services, as well as post information about your activities on the social media service. Your interactions with social media companies are governed by their privacy policies.'}</Text>
    <Text style={styles.paragraph}>{`        \u25CF  To enforce or apply our Terms of Use [www.${SHORT_WEB_URL}/terms] and other agreements, including for billing and collection purposes.`}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Foxy, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Consent. We may share your information in any other circumstances where we have your consent.'}</Text>

    <Text style={styles.bold}>Analytics and tailored advertising</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>To help us better understand your use of the Services, we may use third-party analytics on our Services, such as Google Analytics. These service providers use the sort of technology described in the "Automatically-Collected Information" Section above. The information collected by this technology will be disclosed to or collected directly by these service providers, who use the information to evaluate our users' use of the Services. We also use Google Analytics as described in the following section. To prevent Google Analytics from collecting or using your information, you may install the Google Analytics Opt-Out Browser add-on.</Text>
    <Text style={styles.bold}>Tailored Advertising</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>Third parties whose products or services are accessible or advertised via the Services may also use cookies or similar technologies to collect information about your use of the Services. This is done in order to help them-</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Inform, optimize, and serve ads based on past visits to our website and other sites and'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Report how our ad impressions, other uses of ad services, and interactions with these ad impressions and ad services are related to visits to our website. We also allow other third parties (e.g., ad networks and ad servers such as Google Analytics, OpenX, Pubmatic, DoubleClick and others) to serve tailored ads to you on the Services, and to access their own cookies or similar technologies on your computer, mobile phone, or other device you use to access the Services. We neither have access to, nor does this Privacy Policy govern, the use of cookies or other tracking technologies that may be placed by such third parties. These parties may permit you to opt out of ad targeting. If you are interested in more information about tailored browser advertising and how you can generally control cookies from being put on your computer to deliver tailored advertising (i.e., not just for the Services), you may visit the Network Advertising Initiative(s) Consumer Opt-Out Link, and/or the Digital Advertising Alliance(s) Consumer Opt-Out Link to opt-out of receiving tailored advertising from companies that participate in those programs. To opt out of Google Analytics for Display Advertising or customize Google Display Network ads, you can visit the Google Ads Settings page. Please note that to the extent advertising technology is integrated into the Services, you may still receive ads even if you opt-out of tailored advertising. In that case, the ads will just not be tailored to your interests. Also, we do not control any of the above opt-out links and are not responsible for any choices you make using these mechanisms or the continued availability or accuracy of these mechanisms.'}</Text>
    <Text style={styles.paragraph}>When accessing the Services from a mobile application you may also receive tailored in-application advertisements. Each operating system: iOS, Android and Windows Phone provides its own instructions on how to prevent the delivery of tailored in-application advertisements. You may review the support materials and/or the privacy settings for the respective operating systems in order to opt-out of tailored in-application advertisements. For any other devices and/or operating systems, please visit the privacy settings for the applicable device or operating system or contact the applicable platform operator.</Text>
    
    <Text style={styles.bold}>Choices about how we use and disclose your information</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We strive to provide you with choices regarding the personal information you provide to us. You can set your browser or mobile device to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of our Services may then be inaccessible or not function properly. We do not share your personal information with any advertising agency.</Text>
    
    <Text style={styles.bold}>Communication choices</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>When you sign up for an account, you are opting in to receive emails from other Foxy users, artists businesses, and Foxy itself. You can follow the "unsubscribe" instructions in commercial email messages, but note that you cannot opt out of receiving certain administrative policy, service policy, or legal policy from Foxy.</Text>

    <Text style={styles.bold}>Reviewing, changing or deleting information</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>If you would like to review, change or delete personal information we have collected from you, or permanently delete your account, please use the "Contact Us" section on the Platform or email us on {Config.CONTACT_MAIL}</Text>
    <Text style={styles.paragraph}>If you delete your User Contributions from our websites, copies of your User Contributions may remain viewable in cached and archived pages, or might have been copied or stored by other users of our websites. Proper access and use of information provided on our websites, including User Contributions, is governed by our Terms of Use [www.<Text>{SHORT_WEB_URL}</Text>/terms ]</Text>
    <View style={styles.separator} />


    <Text style={styles.bold}>Accessing & correcting your personal information</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We will take reasonable steps to accurately record the personal information that you provide to us and any subsequent updates.</Text>
    <Text style={styles.paragraph}>We encourage you to review, update, and correct the personal information that we maintain about you, and you may request that we delete personal information about you that is inaccurate, incomplete, or irrelevant for legitimate purposes, or are being processed in a way which infringes any applicable legal requirement.</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>Your right to review, update, correct, and delete your personal information may be limited, subject to the law of your jurisdiction:</Text>
    <Text style={styles.paragraph}>{'        \u25CF  If your requests are abusive or unreasonably excessive,'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Where the rights or safety of another person or persons would be encroached upon, or'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  If the information or material you request relates to existing or anticipated legal proceedings between you and us, or providing access to you would prejudice negotiations between us or an investigation of possible unlawful activity. Your right to review, update, correct, and delete your information is subject to our records retention policies and applicable law, including any statutory retention requirements.'}</Text>
    <View style={styles.separator} />


    <Text style={styles.bold}>Security: How we protect your information</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent unauthorized access to your information and to maintain data security. These safeguards take into account the sensitivity of the information that we collect, process and store and the current state of technology. We follow generally accepted industry standards to protect the personal information submitted to us, both during transmission and once we receive it. The third party service providers with respect to payment gateway and payment processing are all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service providers).</Text>
    <Text style={styles.paragraph}>We assume no liability or responsibility for disclosure of your information due to errors in transmission, unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your personal information secure. You should not share your user name, password, or other security information for your Foxy account with anyone. If we receive instructions using your user name and password, we will consider that you have authorized the instructions.</Text>

    <Text style={styles.bold}>Permissible Age</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>The Services are not intended for users under the age of 18, unless permitted under applicable local laws (Permissible Age). We do not knowingly collect any personal information from users or market to or solicit information from anyone under the Permissible Age. If we become aware that a person submitting personal information is under the Permissible Age, we will delete the account and any related information as soon as possible. If you believe we might have any information from or about a user under the Permissible Age, please contact us at privacy@{SHORT_WEB_URL}.</Text>

    
    <Text style={styles.bold}>Third party links and services</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>The Services may contain links to third-party websites. Your use of these features may result in the collection, processing or sharing of information about you, depending on the feature. Please be aware that we are not responsible for the content or privacy practices of other websites or services which may be linked on our services. We do not endorse or make any representations about third-party websites or services. Our Privacy Policy does not cover the information you choose to provide to or that is collected by these third parties. We strongly encourage you to read such third parties' privacy policies.</Text>


    <Text style={styles.bold}>EU privacy/data protection rights</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>EU General Data Protection Regulation (GDPR) has provided the below rights to the EU residents:</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to information - including contact details to the DPO, the purposes for processing Personal Information and the rights of the individual.'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to access the Personal Information that are processed'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to erasure (”Right to be forgotten”)'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to rectification'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to restriction of processing'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to data portability of the Personal Information supplied to Foxy by the EU resident'}</Text>
    <Text style={styles.paragraph}>{'        \u25CF  Right to object (opt-out) to processing (unless Foxy otherwise has compelling legitimate grounds)'}</Text>
    <Text style={styles.paragraph}>EU residents can exercise these rights by raising a request directly at privacy@{SHORT_WEB_URL} or contact us from the CONTACT US section in the platform.</Text>

    <Text style={styles.bold}>Changes to this privacy policy</Text>
    <View style={styles.separator} />
    <Text style={styles.paragraph}>We reserve the right to amend this Privacy Policy from time to time to reflect changes in the law, our data collection and use practices, the features of our services, or advances in technology. Please check this page periodically for changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is used. If we make any material changes to this Privacy Policy, we will post the changes here. Please review the changes carefully. Your continued use of the Services following the posting of changes to this Privacy Policy will constitute your consent and acceptance of those changes.</Text>

  </ScrollView>
);

const styles = StyleSheet.create({
  container: { flex: 1, height: '100%', width: '100%', padding: 12 },
  headingBold: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h24,
    color: colors.black,
    marginBottom: 40,
    marginTop: 60,
  },
  bold: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h2,
  },
  boldH0: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: size.h0,
    marginBottom: 20,
  },
  separator: {
    marginBottom: 20,
  },
  paragraph: { marginBottom: 20, fontFamily: 'Roboto', size: size.h2 },
  link: {
    color: 'blue',
    textDecorationStyle: 'underline',
    cursor: 'pointer',
  },

});

export default PrivacyPolicy;
