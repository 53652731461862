import React, { Component } from 'react';
import {
  View,
  Image,
  Platform,
  KeyboardAvoidingView,
  Text,
  Dimensions,
  TouchableOpacity,
  NativeModules,
  NativeEventEmitter,
  AppState,
  Keyboard,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { SafeAreaView } from 'react-native-safe-area-context';
import AccountManager from 'react-native-account-manager';
import { TextField } from 'react-native-material-textfield';
import images from '../../theme/Images';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import { ids, styles } from './Styles/LoginInfoStyles';
import colors from '../../theme/Colors';
import {
  SCREEN,
  ANDROID,
  LOGIN_MODAL_STATE,
  CURRENT_GUEST_STATE,
  TRUECALLER_RAW_ERRORS,
  DEFAULT_DEVICE_INFORMATION_OBJECT,
  LOGIN_MODAL_MESSAGES,
  APP_ASSET_URL,
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_ACTIONS,
  NOTIFICATION_TYPE,
  REMOTE_CONFIG_KEYS,
  COUNTRY_CODE,
  EnableMandatoryLogin,
} from '../../config/Constants';
import Utility from '../../utils/Utility';

import {
  AnalyticsManager,
  EventType,
  EventParameterValue,
  EventParameterKey,
} from '../../analytics';
import NotificationManager from '../../utils/NotificationsManager';
import _ from 'lodash';
import FastImageView from '../FastImageView';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import WithNavigation from '../../utils/WithNavigation';
import moment from 'moment';
import RemoteConfig from '../../utils/RemoteConfig';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import NavigationService from '../../navigator/NavigationService';
import MyCountryPicker from './CountryPickerModal';
import { isPhoneNumberValid } from '../../utils/AddressUtility';
import { isDesktop, isNative, isPresent, isIOS } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import StringUtils from '../../utils/StringUtils';
import ModalContainer from '../modals/ModalContainer';
import EkstoreCreditFooter from '../../containers/banner/EkstoreCreditFooter';

let SmsRetriever;
if (Utility.isAndroid()) {
  //&& Utility.isDeviceGreaterThanNougat()
  SmsRetriever = require('react-native-sms-retriever').default;
}

let { height, width } = Dimensions.get('window');
if (height < width) {
  const temp = height;
  height = width;
  width = temp;
}

const enableInternationalChoice = AppConfig.getBooleanValue(
  Config.ENABLE_INTERNATIONAL_CHOICE,
);

const errorStrings = {
  invalid: 'Please enter a valid phone number',
  invalid_length: 'Please enter a 10-digit phone number',
};
class LoginInfo extends Component {
  constructor(props) {
    super(props);
    const {
      screenProps: {
        userAccountInfo: {
          force_logged_out_flow,
          mobileNumber = '',
          countryCode,
          phoneNumberModalInfo: { title, subTitle, image } = {},
          callingCode,
        } = {},
        setForcedLogoutFlow,
      } = {},
    } = this.props;
    this.phoneNumberModalTitle = title || 'Login • Sign up';
    this.phoneNumberModalSubTitle = subTitle || 'and start shopping';
    this.modalImage = image || images.login_modal_image;
    this.enableInternationalChoice = AppConfig.getBooleanValue(
      Config.ENABLE_INTERNATIONAL_CHOICE,
    );
    const defaultMobileNumber = this.getFormattedMobileNumber(mobileNumber);
    this.state = {
      mobileNumber:
        Utility.isPresent(defaultMobileNumber) || enableInternationalChoice
          ? defaultMobileNumber
          : `${COUNTRY_CODE}-`,
      warning: false,
      email: null,
      errorText: 'Please enter a 10-digit mobile number.',
      showSkipButton: false,
    };
    this.debounceOnSubmitEditing = _.debounce(this.onSubmitEditing, 1000, {
      leading: true,
      trailing: false,
    });
    this.mobileNumberRegex = /^[+\d{12,15}$]/;
    this.extractMobileNumberRegex = /\d{10}$/;
    this.deviceInfo = DEFAULT_DEVICE_INFORMATION_OBJECT;
    this.lastEnteredPhoneNumber = '';
    this.lastProcessedNumber = ''; //BAD CHOICE AND UNWANTED STRING, BUT CAN"T THINK ANYTHING BETTER FOR NOW

    this.showingHintPrompt = false;
    this.eventTruecallerProfileSuccess = null;
    this.uploadManagerListener = null;
    this.eventTruecallerProfileFail = null;
    this.registerTruecallerEvents();
    this.skipCountDownTimer = null;
    this.userStartedPhonenumberTyping = false;
    this.isProgressLoaderVisible = false;
    // if (force_logged_out_flow === '' && Utility.isAndroid()) {
    //   setForcedLogoutFlow(true);
    // }
    if (Utility.isAndroid()) {
      this.uploadManager = new NativeEventEmitter(NativeModules.UploadManager);
    } else {
      this.uploadManager = new NativeEventEmitter(
        NativeModules.LocalNotificationHandler,
      );
    }

    this.isOnboardingSkipNotificationClicked = false;
    this.backgroundNotificationCountdownTimer = null;
    this.startTimeForBackgroundNotification();
    this.isBGOnboardingNotificationVisible = false;
    this.activityIndicatorDismissTimer = null;
    AppState.addEventListener('change', this.handleAppState);
    this.isAppInBackground = false;
    this.skipHintPrompts = false;
    this.initialNumberForPromptSequence = -1;
      (this.hintPromptSequence = [
        {
          name: 'hint_prompt',
          module: this.contactNumberHintPrompt,
          visibility: AppConfig.getBooleanValue(
            Config.LOGIN_HINT_PROMPT_VISIBILITY,
          ),
        },
        {
          name: 'truecaller',
          module: this.requestTrueCallerUserProfile,
          visibility: AppConfig.getBooleanValue(
            Config.TRUECALLER_LOGIN_VISIBILITY,
          ),
        },
      ]);
    this.modalImageWidth = Utility.getScreenWidth() - 24;
    this.onboardingNotificationDescription = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.onboarding_notification_desc,
    );
  }

  componentDidMount = () => {
    const {
      navigation,
      route,
      screenProps,
      screenProps: {
        registerSmsReceiver,
        userAccountInfo: {
          mobileNumber,
          guestProfile,
          force_logged_out_flow,
          remote_configs,
        },
        uacSelfieEventTriggered,
      },
    } = this.props;

    Utility.isAppLaunchedFromOnboarding = true;
    const promptSequence = this.hintPromptSequence
      .map((element) => element.name)
      .join(',');
    AnalyticsManager.logEvent('prompts_sequence', {
      sequence: promptSequence,
    });

    if (Utility.isAndroid()) {
      this.showProgressLoader();
    }

    const focusOnKeyBoard = route.params?.focusOnKeyboard ?? true;
    const changePhoneNumber = screenProps.changePhoneNumberTapped;

    const formattedMobileNumber = this.getFormattedMobileNumber(mobileNumber);

    if (
      !changePhoneNumber &&
      (Utility.isPresent(mobileNumber) || Utility.furtherAction.phone_number)
    ) {
      this.setState(
        {
          mobileNumber:
            formattedMobileNumber || Utility.furtherAction.phone_number,
        },
        () => {
          this.skipHintPrompts = true;
          this.onSubmitEditing('order_status');
          Utility.furtherAction = {
            action: Utility.furtherAction.action,
            params: [],
            phone_number: Utility.furtherAction.phone_number,
            isActionAsync: false,
          };
        },
      );

      if (Utility.furtherAction.fromConsumerProfile) {
        setTimeout(() => {
          Utility.furtherAction = {
            params: [],
            phone_number: '',
            isActionAsync: false,
          };
        }, 2000);
      }
    }

    let jsonUacSelfie = {};

    if (remote_configs.data && remote_configs.data.uac_selfie) {
      jsonUacSelfie = Utility.jsonParser(remote_configs.data.uac_selfie);
    }

    this.isBGOnboardingNotificationVisible = false;
    if (focusOnKeyBoard) {
      this.userStartedPhonenumberTyping = true;
      this.hideActivityIndicatorAndFocus();
    }

    // if (force_logged_out_flow && Utility.isAndroid()) {
    //   this.onPressSkip();
    //   AnalyticsManager.logEvent(
    //     EventType.onboardingEvent.FORCE_LOGGED_OUT_TRIGGER,
    //   );
    // }
    if (
      Utility.isAndroid() &&
      // Utility.isDeviceGreaterThanNougat() &&
      !Utility.isOpenedFromOnboardingDeeplink
    ) {
      setTimeout(() => {
        // this.contactNumberHintPrompt();
        this.invokeLoginPrompt();
      }, 100);

      this.retrieveAndroidDeviceInfo();
    }
    if (Utility.isIOS()) {
      this.setFocusToInputBox();
    }
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
      modal_name: EventParameterValue.MODAL_NAME.PHONE_NUMBER_INPUT,
    });

    this.retrieveDeviceInfo();
    setTimeout(() => {
      if (Utility.isBlank(mobileNumber) && !enableInternationalChoice) {
        this.setState({ mobileNumber: `${COUNTRY_CODE}-` });
      } else {
        this.setState({ mobileNumber: formattedMobileNumber });
      }
    }, 300);

    // if (Utility.isAndroid()) {
    this.uploadManagerListener = this.uploadManager.addListener(
      'onboarding_skip_nofication_clicked',
      ({ action }) => {
        this.isOnboardingSkipNotificationClicked = true;
        NotificationManager.cancelOnboardingNotification();
        if (action === 'skip') {
          this.onPressSkip(false);
          AnalyticsManager.logEvent(EventType.onboardingEvent.ONBOARDING_SKIP, {
            [EventParameterKey.SOURCE]: this.isBGOnboardingNotificationVisible
              ? EventParameterValue.SOURCE.BACKGROUND_NOTIFICATION
              : EventParameterValue.SOURCE.FOREGROUND_NOTIFICATION,
          });
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.UAC_SELFIE_SCORE,
            {
              [EventParameterKey.SCORE]:
                Utility.isPresent(jsonUacSelfie) &&
                Utility.isPresent(jsonUacSelfie.no)
                  ? jsonUacSelfie.no
                  : 0,
            },
          );
          screenProps.uacSelfieEventTriggered(
            Utility.isPresent(jsonUacSelfie) &&
              Utility.isPresent(jsonUacSelfie.no)
              ? jsonUacSelfie.no
              : 0,
          );
        } else if (action === 'cancel') {
          this.stopHeadless();
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.ONBOARDING_NOTIFICATION_LATER_PRESS,
            {
              [EventParameterKey.SOURCE]: this.isBGOnboardingNotificationVisible
                ? EventParameterValue.SOURCE.BACKGROUND_NOTIFICATION
                : EventParameterValue.SOURCE.FOREGROUND_NOTIFICATION,
            },
          );
        }
      },
    );
    // }
  };

  componentWillUnmount = () => {
    const {
      screenProps: {
        isOtpRequestedFromHint,
        userAccountInfo: { requestedFromHintPrompt = false } = {},
      } = {},
    } = this.props;
    if (requestedFromHintPrompt) {
      isOtpRequestedFromHint(false); // to close hint prompt loader, in case otp is not received since there is a limit of otp being sent to user.
    }
    if (Utility.isAndroid()) {
      try {
        this.eventTruecallerProfileSuccess.remove();
        this.eventTruecallerProfileFail.remove();
      } catch (error) {}
      AppState.removeEventListener('change', this.handleAppState);
      // this.cancelSkipCountdownTimer();
    }
    this.uploadManagerListener?.remove();
    Utility.hideLoginSkipButtonForSalon = false;
  };

  getFormattedMobileNumber = (mobileNumber) => {
    return mobileNumber?.startsWith(COUNTRY_CODE) &&
      mobileNumber?.indexOf('-') === -1
      ? Utility.insertCharAtPosition(3, mobileNumber, '-')
      : mobileNumber;
  };

  goBack = () => {
    const {
      screenProps,
      screenProps: {
        isOtpRequestedFromHint,
        userAccountInfo: { requestedFromHintPrompt = false } = {},
      } = {},
    } = this.props;
    if (requestedFromHintPrompt) {
      isOtpRequestedFromHint(false); // to close hint prompt loader
    }
    screenProps.goBack();
  };

  onSkip = () => {
    const { screenProps } = this.props;
    screenProps.onSkip();
  };

  onPressOutside = () => {
    if (EnableMandatoryLogin) {
      return null;
    }
    const { screenProps } = this.props;
    screenProps.onPressOutside();
  };

  invokeLoginPrompt = () => {
    if (this.skipHintPrompts) return;
    for (let i = 0; i < this.hintPromptSequence.length; i++) {
      if (this.hintPromptSequence[i].visibility) {
        this.initialNumberForPromptSequence = i;
        this.hintPromptSequence[i].module();
        break;
      }
    }

    if (this.initialNumberForPromptSequence === -1) {
      this.hideActivityIndicatorAndFocus();
    }
  };

  hideActivityIndicatorAndFocus = () => {
    this.hideProgressLoader();
    this.setFocusToInputBox();
  };

  registerTruecallerEvents = () => {
    if (Utility.isAndroid()) {
      const eventEmitter = new NativeEventEmitter(NativeModules.Truecaller);
      this.eventTruecallerProfileSuccess = eventEmitter.addListener(
        'didReceiveTrueProfileResponse',
        this.handleTruecallerProfileSuccess,
      );
      this.eventTruecallerProfileFail = eventEmitter.addListener(
        'didReceiveError',
        this.handleTruecallerProfileError,
      );
    }
  };

  startTimeForBackgroundNotification = () => {
    const {
      screenProps: {
        userAccountInfo: { remote_configs, authToken, guestProfile },
      },
    } = this.props;

    if (guestProfile.current_state === CURRENT_GUEST_STATE.SKIPPED) {
      return;
    }

    let defaultTimer = 7; //in minutes
    if (
      remote_configs.data &&
      remote_configs.data.onboarding_background_notification_time
    ) {
      defaultTimer =
        remote_configs.data.onboarding_background_notification_time;
    }

    const payload = {
      deepLink: Config.APP_INTENT_SCHEME,
      title: `Welcome to ${Config.APP_NAME}!`,
      description: this.onboardingNotificationDescription,
      primary_cta: NOTIFICATION_ACTIONS.CONTINUE.cta,
      primary_destination: `${Config.APP_INTENT_SCHEME}?utm_source=local_notification&utm_medium=local_onboarding_notification`,
      secondary_cta: NOTIFICATION_ACTIONS.LATER.cta,
      secondary_destination: NOTIFICATION_ACTIONS.LATER.action,
      type: NOTIFICATION_TYPE.ONBOARDING_NOTIFICATION,
      isLocal: true,
      display_time: moment(Date()).add(defaultTimer, 'm').toISOString(),
    };
    NotificationManager.createNotification(
      payload,
      NOTIFICATION_IDENTIFIERS.ONBOARDING_NOTIFICATION,
    );
  };

  removeTruecallerEvents = () => {};

  // TODO: check why Platform.select doesn't work here well
  retrieveDeviceInfo = () => {
    if (Utility.isAndroid()) {
      this.retrieveAndroidDeviceInfo();
    } else {
      this.retrieveIOSDeviceInfo();
    }
  };

  retrieveIOSDeviceInfo = () => {
    NetInfo.fetch().then((connection) => {
      this.deviceInfo.connectionType = connection.type;
    });
  };

  retrieveAndroidDeviceInfo = () => {
    try {
      NativeModules.DeviceDetails.getAllDeviceInfo((err, value) => {
        this.deviceInfo.generalDeviceInfo = value;
      });
      NetInfo.getConnectionInfo().then((connectionInfo) => {
        this.deviceInfo.connectionType = connectionInfo.type;
        this.deviceInfo.effectiveConnectionType = connectionInfo.effectiveType;
      });
    } catch (error) {
      // console.tron.log('Error arise in fetching device Info', error);
    }
  };

  onChangeText = (text) => {
    const { warning, showSkipButton } = this.state;

    const maskedText = text.replace(/[^0-9+-]/g, '');
    let phone = '';
    if (this.lastEnteredPhoneNumber.indexOf('-') !== text.length) {
      phone =
        maskedText.startsWith(COUNTRY_CODE) && maskedText.indexOf('-') === -1
          ? Utility.insertCharAtPosition(COUNTRY_CODE.length, text, '-')
          : text;
    } else {
      phone = text;
    }

    this.lastEnteredPhoneNumber = phone;
    const newState = { mobileNumber: phone };
    if (warning) {
      newState.warning = !this.mobileNumberRegex.test(maskedText);
    }

    const enteredPhoneNumber = Utility.removeDashFromText(phone);

    if (
      enteredPhoneNumber.length === 1 &&
      enteredPhoneNumber !== '+' &&
      Utility.isBlank(this.lastProcessedNumber)
    ) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.PHONE_NUMBER_INPUT_START,
        { [EventParameterKey.DIGIT]: enteredPhoneNumber },
      );
    } else if (enteredPhoneNumber.length === AppConfig.phoneNumberLimit) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.PHONE_NUMBER_INPUT_END,
        { [EventParameterKey.DIGIT]: enteredPhoneNumber },
      );
    }
    this.lastProcessedNumber = enteredPhoneNumber;
    this.setState(newState);

    if (!this.userStartedPhonenumberTyping) {
      this.cancelSkipCountdownTimer(true);
      this.userStartedPhonenumberTyping = true;
    }
  };

  startsWith = (string, char) => {
    const index = string.indexOf(char) === 0;
    return index;
  };

  onSubmitEditing = (source = 'onboarding') => {
    const {
      screenProps: {
        userAccountInfo: {
          countryCode = Config.COUNTRY_ISO_CODE,
          callingCode = AppConfig.callingCode,
        } = {},
        onAddMobileNumber,
        setInitialLoginScreenAsOTP,
      } = {},
    } = this.props;
    let { mobileNumber, email } = this.state;
    this.deviceInfo.email = email;
    this.stopHeadless();
    let errorText = '';

    if (enableInternationalChoice) {
      const isValidPhoneNumber =
        isPresent(mobileNumber) &&
        (isPhoneNumberValid(mobileNumber, callingCode, countryCode) ||
          mobileNumber?.startsWith('369900')); // added for testing purposes
      errorText = isValidPhoneNumber ? '' : errorStrings.invalid;
    } else {
      // Remove leading zero after Country Code
      mobileNumber = mobileNumber.replace('-', '');
      if (
        mobileNumber.startsWith(COUNTRY_CODE) &&
        mobileNumber.indexOf('0') === COUNTRY_CODE.length
      ) {
        mobileNumber = StringUtils.replaceAt(
          mobileNumber,
          COUNTRY_CODE.length + 1,
          '',
        );
      }

      if (this.mobileNumberRegex.test(mobileNumber)) {
        this.setState({ warning: false });
        const valid = Utility.phoneNumberFormatter(mobileNumber);
        mobileNumber = valid.phoneNumber;
        errorText = valid.error ? valid.message : '';
      } else {
        errorText = errorStrings.invalid_length;
      }
    }

    const phoneNumberForEvent = enableInternationalChoice ? `${callingCode}${mobileNumber}` : mobileNumber;
    if (Utility.isPresent(errorText)) {
      this.setState({
        warning: true,
        errorText,
      });
      const { REGEX_ERROR, PHONE_NUMBER_FORMATTING } = EventParameterValue.PHONE_NUMBER_ERROR;
      const errorType = errorText === errorStrings.invalid_length ? REGEX_ERROR : PHONE_NUMBER_FORMATTING;
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.PHONE_NUMBER_INPUT_ERROR,
        {
          [EventParameterKey.ERROR_TYPE]: errorType,
          [EventParameterKey.DIGIT]: phoneNumberForEvent,
        },
      );
    } else {
      this.setState({ warning: false });
      onAddMobileNumber(mobileNumber, this.deviceInfo, () => {});
      setInitialLoginScreenAsOTP(true, source !== 'order_status');
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.PHONE_NUMBER_INPUT,
        {
          [EventParameterKey.INPUT_METHOD]:
            EventParameterValue.INPUT_METHOD.MANUAL,
          [EventParameterKey.PHONE_NUMBER]: phoneNumberForEvent,
        },
      );
    }
  };

  // FIXME: Performance of navigation to Profile details is very slow in Android API >= ANDROID.BUILD.VERSIONS.OREO
  contactNumberHintPrompt = async () => {
    const { screenProps, navigation } = this.props;
    let isBlockExecuted = false;

    if (this.skipHintPrompts) {
      return;
    }
    // let timeoutCounter = null;
    try {
      AnalyticsManager.logEvent(EventType.onboardingEvent.HINT_REQUEST_VIEW, {
        [EventParameterKey.TYPE]: EventParameterValue.HINT_PROMPT_TYPES.MOBILE,
      });

      //TODO: Buggy Code, should not go in the build
      // timeoutCounter = setTimeout(() => {
      //   if (!isBlockExecuted) {
      //     clearTimeout(timeoutCounter);
      //     AnalyticsManager.logEvent(
      //       EventType.onboardingEvent.ACTIVITY_INDICATOR_DISMISS,
      //       {
      //         [EventParameterKey.STATE]: EventParameterValue.STATE.HINT_PROMPT,
      //       },
      //     );
      //     setTimeout(() => {
      //       this.requestTrueCallerUserProfile();
      //     }, 100);
      //   }
      // }, 15000);
      const countryPhoneNumber = await SmsRetriever.requestPhoneNumber();
      isBlockExecuted = true;
      const dialingNumber = countryPhoneNumber.match(
        this.extractMobileNumberRegex,
      );

      const { phoneNumber } = Utility.phoneNumberFormatter(dialingNumber[0]);
      Utility.furtherAction = {
        ...Utility.furtherAction,
        phone_number: phoneNumber,
        isActionAsync: false,
      };
      screenProps.isOtpRequestedFromHint(true);
      // screenProps.setForcedLogoutFlow(false);
      const formattedMobileNumber = this.getFormattedMobileNumber(phoneNumber);
      this.setState({
        mobileNumber: formattedMobileNumber,
      });
      this.showProgressLoader();
      screenProps.onAddMobileNumber(
        phoneNumber,
        this.deviceInfo,
        (response, success) => {
          if (!success) {
            this.goBack(); //in case request otp api returns 401 response, it should close the hint prompt loader.
          }
          setTimeout(this.goBack, 25000); // in case otp is sent to the user but sms retriever is not able to reads sms. This would close hint prompt loader after an timeout of 25 seconds.
        },
        true,
      );
      screenProps.onInsertMobileNumber(phoneNumber, this.deviceInfo);
      this.cancelSkipCountdownTimer();
      this.stopHeadless();
      AnalyticsManager.logEvent(EventType.onboardingEvent.PHONE_NUMBER_INPUT, {
        [EventParameterKey.INPUT_METHOD]:
          EventParameterValue.INPUT_METHOD.DEVICE_PROMPT,
        [EventParameterKey.PHONE_NUMBER]: phoneNumber,
      });
    } catch (error) {
      isBlockExecuted = true;
      // screenProps.setForcedLogoutFlow(false);
      AnalyticsManager.logEvent(EventType.onboardingEvent.HINT_REQUEST_SKIP, {
        [EventParameterKey.CODE]: error.code,
      });
      setTimeout(() => {
        this.initialNumberForPromptSequence += 1;
        if (
          this.hintPromptSequence[this.initialNumberForPromptSequence] &&
          this.hintPromptSequence[this.initialNumberForPromptSequence]
            .visibility
        ) {
          this.hintPromptSequence[this.initialNumberForPromptSequence].module();
        } else {
          this.hideActivityIndicatorAndFocus();
        }
        // this.initialNumberForPromptSequence += 1;
        // if (
        //   this.hintPromptSequence[this.initialNumberForPromptSequence] &&
        //   this.hintPromptSequence[this.initialNumberForPromptSequence]
        //     .visibility
        // ) {
        //   this.hintPromptSequence[this.initialNumberForPromptSequence].module();
        // } else {
        //   this.requestTrueCallerUserProfile();
        // }
      }, 100);
    }
  };

  showProgressLoader = () => {
    const { navigation } = this.props;
    this.isProgressLoaderVisible = true;
    Keyboard.dismiss();
    navigation.navigate('ModalProgressView');
  };

  hideProgressLoader = () => {
    const { navigation } = this.props;
    if (this.isProgressLoaderVisible) {
      this.isProgressLoaderVisible = false;
      navigation.pop();
    }
  };

  requestTrueCallerUserProfile = () => {
    if (Utility.isPresent(Utility.furtherAction.phone_number)) {
      return;
    }
    if (Utility.isAndroid()) {
      NativeModules.Truecaller.isUsable((error, isUsable) => {
        if (!isUsable) {
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.TRUECALLER_ERROR,
            {
              [EventParameterKey.RAW_ERROR]: TRUECALLER_RAW_ERRORS[15],
            },
          );
          this.showingHintPrompt = true;
          this.initialNumberForPromptSequence += 1;
          if (
            this.hintPromptSequence[this.initialNumberForPromptSequence] &&
            this.hintPromptSequence[this.initialNumberForPromptSequence]
              .visibility
          ) {
            this.hintPromptSequence[
              this.initialNumberForPromptSequence
            ].module();
          } else {
            this.hideActivityIndicatorAndFocus();
          }
          return;
        }

        NativeModules.Truecaller.requestUserProfile();
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.TRUECALLER_MODAL_VIEW,
        );

        this.activityIndicatorDismissTimer = setTimeout(() => {
          this.hideProgressLoader();
          AnalyticsManager.logEvent(
            EventType.onboardingEvent.ACTIVITY_INDICATOR_DISMISS,
            {
              [EventParameterKey.STATE]: EventParameterValue.STATE.TRUECALLER,
            },
          );

          this.setFocusToInputBox(!this.isAppInBackground);
        }, 3000);
      });
    }
  };

  cancelActivityIndicatorTimer = () => {
    try {
      clearTimeout(this.activityIndicatorDismissTimer);
    } catch (error) {}
  };

  setFocusToInputBox = (trackEvent = true) => {
    const {
      screenProps: { userAccountInfo },
    } = this.props;

    setTimeout(() => {
      try {
        if (this.input && trackEvent) {
          setTimeout(() => {
            try {
              if (this.input) {
                this.input.focus();
              }
            } catch (error) {}
          }, 100);
        }
      } catch (error) {}

      if (trackEvent) {
        AnalyticsManager.logEvent(
          EventType.onboardingEvent.PHONE_NUMBER_INPUT_FOCUSED,
        );
      }
    }, 50);
    if (!this.userStartedPhonenumberTyping && trackEvent) {
      this.startSkipCountdownTimer();
    }
  };

  handleAppState = () => {
    if (AppState.currentState === 'background') {
      this.isAppInBackground = true;
      // this.cancelSkipCountdownTimer();
    } else if (
      AppState.currentState === 'active'
      // !this.userStartedPhonenumberTyping
    ) {
      this.isAppInBackground = false;
      // console.tron.log('Handle App State');
      // this.startSkipCountdownTimer();
    }
  };

  handleTruecallerProfileError = ({ error }) => {
    const { screenProps } = this.props;
    this.cancelActivityIndicatorTimer();
    if (
      !this.showingHintPrompt &&
      Utility.isPresent(TRUECALLER_RAW_ERRORS[error])
    ) {
      this.hideProgressLoader();
      AnalyticsManager.logEvent(EventType.onboardingEvent.TRUECALLER_ERROR, {
        [EventParameterKey.RAW_ERROR]: TRUECALLER_RAW_ERRORS[error],
      });

      this.initialNumberForPromptSequence += 1;
      if (
        this.hintPromptSequence[this.initialNumberForPromptSequence] &&
        this.hintPromptSequence[this.initialNumberForPromptSequence].visibility
      ) {
        this.hintPromptSequence[this.initialNumberForPromptSequence].module();
      } else {
        this.setFocusToInputBox();
        this.showingHintPrompt = true;
      }
      // screenProps.setForcedLogoutFlow(false);
    }
  };

  handleTruecallerProfileSuccess = (event) => {
    const { screenProps, applyCartCoupon } = this.props;
    this.cancelActivityIndicatorTimer();
    NavigationService.unmountOnboarding();
    // screenProps.setForcedLogoutFlow(false);
    this.stopHeadless();
    this.cancelSkipCountdownTimer();
    AnalyticsManager.logEvent(EventType.onboardingEvent.PHONE_NUMBER_INPUT, {
      [EventParameterKey.INPUT_METHOD]:
        EventParameterValue.INPUT_METHOD.TRUECALLER,
    });
    screenProps.isOtpRequestedFromHint(true);
    screenProps.onInsertMobileNumber(event.phoneNumber, this.deviceInfo);
    screenProps.postTruecallerData(event, (response) => {
      this.hideProgressLoader();
      if (response) {
        const { params, isActionAsync } = Utility.furtherAction;

        if (isActionAsync) {
          screenProps.applyCartCoupon(...params);
        }

        AnalyticsManager.logEvent(EventType.onboardingEvent.ACCOUNT_LOGIN, {
          [EventParameterKey.INPUT_METHOD]:
            EventParameterValue.INPUT_METHOD.TRUECALLER,
        });
        AnalyticsManager.logFirebaseEvent(
          EventType.googleRemarketingEvents.LOGIN,
          {
            [EventParameterKey.METHOD]:
              EventParameterValue.INPUT_METHOD.TRUECALLER,
          },
        );

        const fbMeta = {
          [EventParameterKey.FB.EVENT_PARAM_REGISTRATION_METHOD]:
            EventParameterValue.INPUT_METHOD.TRUECALLER,
        };

        AnalyticsManager.logFBStandardEvent(
          EventType.FB.EVENT_NAME_COMPLETED_REGISTRATION,
          null,
          AnalyticsUtility.addCurrencyToFBEventMetaData(fbMeta),
        );
      }
    });
  };

  startSkipCountdownTimer = () => {
    const {
      screenProps: {
        userAccountInfo: { remote_configs, guestProfile },
      },
    } = this.props;
    //Dont start timers and always show skip button if user is already on Guest homepage
    if (guestProfile.current_state === CURRENT_GUEST_STATE.SKIPPED) {
      this.setState({ showSkipButton: true });
      return;
    }

    let time = 0.5 * 1000;
    if (
      Utility.isPresent(remote_configs) &&
      Utility.isPresent(remote_configs.data) &&
      Utility.isPresent(remote_configs.data.foreground_skip_visible_time)
    ) {
      time = remote_configs.data.foreground_skip_visible_time * 1000;
    }

    // Skip button should be always visible on login screen
    // this.skipCountDownTimer = setTimeout(() => {
    //   this.setState({ showSkipButton: true });
    //   AnalyticsManager.logEvent(EventType.onboardingEvent.SKIP_BUTTON_VISIBLE);
    //   AnalyticsManager.logEvent(EventType.onboardingEvent.SKIP_COUNTER_FINISH);
    //   if (!this.isOnboardingSkipNotificationClicked) {
    //     NotificationManager.showOnboardingNotification();
    //     AnalyticsManager.logEvent(
    //       EventType.onboardingEvent.ONBOARDING_NOTIFICATION_VISIBLE,
    //       {
    //         [EventParameterKey.TYPE]: 'foreground',
    //       },
    //     );
    //   }
    //   this.cancelSkipCountdownTimer();
    // }, time);

    // AnalyticsManager.logEvent(EventType.onboardingEvent.SKIP_COUNTER_START);
  };

  cancelSkipCountdownTimer = (hideSkipButton = false) => {
    const {
      screenProps: {
        userAccountInfo: { guestProfile },
      },
    } = this.props;
    //Dont start timers and always show skip button if user is already on Guest homepage
    if (guestProfile.current_state === CURRENT_GUEST_STATE.SKIPPED) {
      return;
    }

    clearTimeout(this.skipCountDownTimer);

    if (hideSkipButton) {
      NotificationManager.cancelOnboardingNotification();
      AnalyticsManager.logEvent(EventType.onboardingEvent.SKIP_COUNTER_RESET);
      this.setState({ showSkipButton: false });
      AnalyticsManager.logEvent(EventType.onboardingEvent.SKIP_BUTTON_HIDDEN);
    }
  };

  clearMobileNumber = () => {
    this.setState({
      mobileNumber: '',
      warning: false,
    });

    AnalyticsManager.logEvent(EventType.onboardingEvent.PHONE_NUMBER_CLEAR);
  };

  handleAccessory = () => {
    const { mobileNumber } = this.state;
    if (
      mobileNumber !== null &&
      mobileNumber !== '' &&
      mobileNumber !== undefined
    ) {
      return (
        <TouchableOpacity onPress={this.clearMobileNumber}>
          <Image
            source={images.failure}
            style={{ tintColor: colors.silver, height: 16, width: 16 }}
          />
        </TouchableOpacity>
      );
    }
  };

  renderLeftAccessory = () => {
    if (!enableInternationalChoice) return null;
    const {
      screenProps: {
        onChangeCountryCode,
        userAccountInfo: { countryCode = Config.COUNTRY_ISO_CODE } = {},
      } = {},
    } = this.props;
    return (
      <MyCountryPicker
        updateCountryCode={onChangeCountryCode}
        countryCode={countryCode}
      />
    );
  };

  mobileNumberInputBox = (props) => {
    const { mobileNumber = '', warning } = this.state;
    const borderBottomBorder = !warning
      ? { borderBottomColor: colors.subtitle }
      : { borderBottomColor: colors.foxyRed };

    const maxLengthWithoutCountryCode = parseInt(
      Config.CONTACT_NUMBER_LIMIT || '10',
    );
    const maxLengthWithCountryCode =
      COUNTRY_CODE.length + 1 + maxLengthWithoutCountryCode;
    let maxLength = `${mobileNumber}`.startsWith(COUNTRY_CODE)
      ? maxLengthWithCountryCode
      : maxLengthWithoutCountryCode;

    if (this.enableInternationalChoice) {
      maxLength = 15;
    }
    return (
      <View
        style={styles.mobileNumberInputBoxStyle}
        dataSet={{ media: ids.mobileNumberInputBoxStyle }}
      >
        <TextField
          testID='login-number'
          accessibilityLabel='login-number'
          ref={(ref) => {
            this.input = ref;
          }}
          label={enableInternationalChoice ? null : 'Mobile Number'}
          lineWidth={1}
          hideLabel={false}
          returnKeyType='done'
          renderAccessory={this.handleAccessory}
          renderLeftAccessory={this.renderLeftAccessory}
          value={mobileNumber}
          autoCapitalize='none'
          maxLength={maxLength}
          onChangeText={this.onChangeText}
          onSubmitEditing={this.onSubmitEditing}
          keyboardType='phone-pad'
          onBlur={this.showSafeAreaBottomInset}
          onFocus={this.removeSafeAreaBottomInset}
          blurOnSubmit={false}
          inputContainerStyle={borderBottomBorder}
          error={warning ? this.state.errorText : ''}
          errorColor={colors.foxyRed}
          editable={true}
          autoFocus={Utility.isIOS()}
          tintColor={colors.silver}
        />
      </View>
    );
  };

  onChangeAutofill = () => {
    const { screenProps } = this.props;
    screenProps.onTogglingAutofill();
    if (!screenProps.userAccountInfo.autofillMyProfileDetails) {
      AccountManager.getAccountsByType('com.google').then((accounts) => {
        this.setState({ email: accounts[0].name });
      });
    } else {
      this.setState({ email: null });
    }
  };

  onPressCross = (trackEvent = true) => {
    const {
      screenProps,

      screenProps: {
        uacSelfieEventTriggered,
        userAccountInfo: { remote_configs = {} } = {},
      },
    } = this.props;

    if (Utility.furtherAction.performSkipAction) {
      Utility.furtherAction.skipAction();
      Utility.furtherAction.skipAction = () => {};
      Utility.furtherAction.performSkipAction = false;
    }

    let jsonUacSelfie = {};

    if (
      Utility.isPresent(remote_configs.data) &&
      Utility.isPresent(remote_configs.data.uac_selfie)
    ) {
      jsonUacSelfie = Utility.jsonParser(remote_configs.data.uac_selfie);
    }

    this.onSkip();
    if (Utility.isBlank(screenProps.userAccountInfo.loginModalState)) {
      screenProps.loginModalState(LOGIN_MODAL_STATE.USER_SKIPPED);
    }
    if (trackEvent) {
      AnalyticsManager.logEvent(EventType.onboardingEvent.ONBOARDING_SKIP, {
        [EventParameterKey.SOURCE]:
          EventParameterValue.MODAL_NAME.PHONE_NUMBER_INPUT,
      });
    }
    AnalyticsManager.logEvent(EventType.onboardingEvent.UAC_SELFIE_SCORE, {
      [EventParameterKey.SCORE]:
        Utility.isPresent(jsonUacSelfie) && Utility.isPresent(jsonUacSelfie.no)
          ? jsonUacSelfie.no
          : 0,
    });
    screenProps.uacSelfieEventTriggered(
      Utility.isPresent(jsonUacSelfie) && Utility.isPresent(jsonUacSelfie.no)
        ? jsonUacSelfie.no
        : 0,
    );
    try {
      NotificationManager.cancelOnboardingNotification();
      this.stopHeadless();
      this.cancelSkipCountdownTimer();
    } catch (error) {}
  };

  hideSkipFromPayWithFoxy = () => Utility.hideLoginSkipButtonForSalon;

  stopHeadless = () => {
    clearTimeout(this.backgroundNotificationCountdownTimer);
    NotificationManager.stopHeadless();
  };

  crossButton = () => {
    const { screenProps = {} } = this.props;
    const { hideCrossButton = false } = screenProps;
    if (hideCrossButton) return null;
    if (EnableMandatoryLogin) {
      return null;
    }
    if (this.hideSkipFromPayWithFoxy()) {
      return null;
    }
    return (
      <TouchableOpacity
        hitSlop={Utility.getHitSlop()}
        onPress={this.onPressCross}
        style={styles.crossStyle}
      >
        <FastImageView source={images.black_cross} style={styles.crossStyle} />
      </TouchableOpacity>
    );
  };

  loginScreen = () => {
    const buttonWidth = isDesktop() ? '100%' : Utility.getScreenWidth() - 48;
    return (
      <ModalContainer onModalTapOutside={this.onPressOutside} showModalInCenter={isDesktop()}>
        <View style={styles.loginInfoContainer} dataSet={{ media: ids.loginInfoContainer }}>
          <FastImageView
            source={getMinifiedImage(
              this.modalImage,
              this.modalImageWidth,
              styles.loginModalImage.height,
            )}
            style={styles.loginModalImage}
            resizeMode='contain'
          />
          <View style={styles.titleContainer}>
            <Text style={styles.enterPhoneNumberTitle}>
              {this.phoneNumberModalTitle}
            </Text>
            <Text style={styles.enterPhoneNumberSubTitle}>
              {this.phoneNumberModalSubTitle}
            </Text>
          </View>
          <View style={styles.form} dataSet={{ media: ids.form }}>
            <this.mobileNumberInputBox {...this.props} />
            <FoxyShadowButton
              onPress={this.debounceOnSubmitEditing}
              title='Continue'
              width={buttonWidth}
              style={isDesktop() && { width: '100%' }}
            />
            <View style={styles.ekstoreCreditFooter}>
              <EkstoreCreditFooter />
            </View>
          </View>
          <this.crossButton />
        </View>
      </ModalContainer>
    );
  };

  render() {
    return (
      <KeyboardAvoidingView
        behavior={isIOS() ? 'position' : 'padding'}
        style={styles.keyboardAvoidingView}
        dataSet={{ media: ids.keyboardAvoidingView }}
      >
        <this.loginScreen />
      </KeyboardAvoidingView>
    );
  }
}

export default WithNavigation(LoginInfo);
