import React from 'react';
import { memoize } from 'lodash';
import FastImageView from '../../components/FastImageView';
import RemoteConfig from '../../utils/RemoteConfig';
import { jsonParser } from '../../utils/JsonUtility';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { getScreenWidth } from '../../utils/LayoutUtility';

const EkstoreCreditFooter = () => {
  const showPoweredByEkstore = jsonParser(
    RemoteConfig.getValue(REMOTE_CONFIG_KEYS.ekstore_credit_footer),
  );
  const {
    width = getScreenWidth(),
    height = 20,
    image,
    show_power_by_ekstore: hideCredits = true,
  } = showPoweredByEkstore;
  const imageStyle = {
    width,
    height,
  };
  if (!hideCredits) return null;
  return <FastImageView source={image} style={imageStyle} />;
};

export default EkstoreCreditFooter;
