import React from 'react';
import AccountOptionItem from './AccountOptionItem';

const DermaLoginOption = (props) => {
  const { navigation, toggleAccountModal } = props;

  const onPressButton = () => {
    toggleAccountModal();
    navigation.navigate('HandleSelection');
  };

  return <AccountOptionItem label={'Sign up as a Dermat'} handlePress={onPressButton} />;
};

export default DermaLoginOption;
