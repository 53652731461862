import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  View,
  StyleSheet,
  ScrollView,
  Animated,
  BackHandler,
  RefreshControl,
} from 'react-native';
import _, { debounce, transform } from 'lodash';
import { bindActionCreators } from 'redux';
import Toast from 'react-native-easy-toast';
import { SafeAreaView } from 'react-native-safe-area-context';
import { CustomNavigationHeader } from '../Product';
import DockedHeader from '../../utils/DockedHeader';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import OfferPagePrompts from './OfferPagePrompts';
import { List } from '../../containers/List';
import { CartTray } from '../../containers/feed';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  fetchOfferDetails,
  fetchList,
  fetchOfferCartItems,
} from '../../actions/ActionTypes';
import { ScreenPlaceholder } from '../shared';

import {
  EventParameterKey,
  AnalyticsManager,
  EventType,
} from '../../analytics';
import { FOXY_URLS, WEB_URL, LISTS_API_VERSION, EMPTY_OBJECT } from '../../config/Constants';
import ListsFilter from '../layout/ListsFilter';
import FilterUtils, { canOpenSortingModal } from '../sort-filter-options/FilterUtils';
import GridScreenPlaceHolder from '../shared/GridScreenPlaceHolder';
import FilterResultsEmptyState from '../sort-filter-options/FilterResultsEmptyState';
import ServerError from '../shared/ServerError';
import PageNotFound from '../shared/PageNotFound';
import ErrorBoundary from '../shared/ErrorBoundary';
import { Header } from '../header';
import OfferCartItems from '../cart/OfferCartItems';
import WithNavigation from '../../utils/WithNavigation';
import CartBuilderSummary from '../../containers/feed/CartBuilderSummary';
import NumberCounter from '../../helpers/NumberCounter';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import FoxyPersonalisedItems from '../../containers/FoxyPersonalisedItems';
import FastImageView from '../FastImageView';
import { goBackWithFallbackHome } from '../../utils/NavigationUtility';
import { getCompactSlug, getFullSlugFromName } from '../../utils/StringUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import AppInstallPrompts from '../shared/AppInstallPrompts';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isDesktop, isWeb, isBlank, isPresent } from '../../utils/BooleanUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';

class OfferDetail extends Component {
  scrollY = new Animated.Value(0);

  constructor(props) {
    super(props);
    const { navigation } = this.props;
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.OFFER_DETAIL);
    this.trace.start();

    this.extractedFiltersFromDeeplink = [];
    let existingSelectedFilters = {};

    const { route } = props;
    if (Utility.isPresent(route.params?.url)) {
      this.extractedFiltersFromDeeplink =
        Utility.extractSelectedFiltersFromDeeplink(route.params?.url, false);

      if (Utility.isPresent(this.extractedFiltersFromDeeplink)) {
        existingSelectedFilters = _.groupBy(
          this.extractedFiltersFromDeeplink,
          (element) => element.criteria_name,
        );
      }
    }

    this.state = {
      promptData: route.params?.promptData ?? {},
      offersData: {},
      itemData: {},
      forceRefreshOfferStrip: false,
      isCartTrayVisible: false,
      shouldShowChooseGiftScreen: false,
      loadMoreItems: false,
      stickyPosition: -1,
      selectedFilters: existingSelectedFilters,
      filters: [],
      quickFilters: [],
      showProductShimmer: false,
      showFiltersEmptyState: false,
      showInitialLoadingShimmer: true,
      isPageLoading: true,
      filtersFromDeepLink: this.extractedFiltersFromDeeplink,
      serverError: false,
      selectedList: '',
      refreshing: false,
      hideOosProduct: false,
      offerSkuIds: [],
    };
    this.listOpenedFromDynamicLink = false;
    this.ref = React.createRef();

    this.offerDetailSlug = '';
    this.pageNo = 0;
    this.paginate = true;
    this.quickFiltersRef = null;
    this.slug = '';
    this.offerSlug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.fnAutoListPagination = () => {};
    this.currentListId = null;
    this.productListLeft = new Animated.Value(1);
    this.productListStyle = { marginLeft: this.productListLeft };

    this.translateX = this.productListLeft.interpolate({
      inputRange: [-1, 0, 1, 2],
      outputRange: [0, 0, Utility.getScreenWidth(), Utility.getScreenWidth()],
    });

    this.animatedListStyle = { transform: [{ translateX: this.translateX }] };

    this.indexOfFoxyMatchFilterInUrl = _.findIndex(
      this.extractedFiltersFromDeeplink,
      (e) => e?.criteria_name === 'foxy_match',
    );

    this.isFoxyMatchFilterEnableInUrl = this.indexOfFoxyMatchFilterInUrl !== -1;

    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  onEndReached = () => {};

  componentDidMount() {
    const { promptData } = this.state;
    const { fetchOfferDetails, navigation, route } = this.props;
    // when coming from offer prompt tap
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.refreshOfferStrip();
    });
    let slug = '';
    if (Utility.isPresent(promptData) && Utility.isPresent(promptData.offer)) {
      slug = promptData.offer.slug || '';
      this.listOpenedFromDynamicLink = false;
    } else {
      slug = this.getRouteSlug();
      this.listOpenedFromDynamicLink = true;
      slug = slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    }
    this.offerDetailSlug = slug;

    slug = slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    this.slug = '';
    this.offerSlug = slug;
    this.hitApiCall(slug);
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      cartItems,
      fetchOfferCartItems,
      route: {
        params: {
          promptData: { offer: { slug: offerSlug } = {} } = {},
        } = {},
      } = {},
      route,
    } = this.props;
    const offerSlugFromRoute = this.getRouteSlug() || offerSlug;
    if (JSON.stringify(prevProps.cartItems) !== JSON.stringify(cartItems)) {
      fetchOfferCartItems(
        offerSlugFromRoute?.replace(/\/v\d+\//, '/v3/'),
        (success, data, status) => {
          if (success) {
            this.setState({ offerSkuIds: data?.list_to_sku_id || {} });
          }
        },
      );
    }
  }

  onRefresh = () => {
    const { promptData } = this.state;
    const { navigation, route } = this.props;
    // when coming from offer prompt tap
    this.pageNo = 0;
    this.unsubscribeFocus = navigation.addListener('focus', () => {
      this.refreshOfferStrip();
    });
    this.setState({
      refreshing: true,
      selectedList: '',
      showFiltersEmptyState: false,
      selectedFilters: {},
      filters: [],
      showProductShimmer: true,
    });
    this.selectedQuickFilters = [];
    this.filtersEnabled = false;
    let slug = '';
    if (Utility.isPresent(promptData) && Utility.isPresent(promptData.offer)) {
      slug = promptData.offer.slug || '';
      this.listOpenedFromDynamicLink = false;
    } else {
      slug = this.getRouteSlug();
      this.listOpenedFromDynamicLink = true;
      slug = slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    }
    this.offerDetailSlug = slug;

    slug = slug.replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
    this.slug = '';
    this.hitApiCall(slug);
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
    this.unsubscribeFocus();
  }

  hitApiCall = (slug) => {
    const { promptData } = this.state;
    const { fetchOfferDetails, fetchOfferCartItems } = this.props;
    // when coming from offer prompt tap

    fetchOfferCartItems(slug, (success, data, status) => {
      if (success) {
        this.setState({ offerSkuIds: data?.list_to_sku_id || {} });
      }
    });

    fetchOfferDetails(slug, this.pageNo, (success, data, status) => {
      this.setState({ refreshing: false, showProductShimmer: false });
      if (success) {
        const { serverError } = this.state;
        if (serverError) {
          this.setState({
            serverError: false,
          });
        }

        if (Utility.isPresent(data)) {
          AnalyticsManager.logFirebaseEvent(
            EventType.googleRemarketingEvents.VIEW_PROMOTION,
            {
              promotion_id: data.id,
              promotion_name: data.coupon_code,
            },
          );
        }

        this.slug = data.lists[0].slug;

        const dataObject =
          Utility.isPresent(data.objects) && data.objects.length > 0
            ? data.objects
            : data.lists[0].objects;
        const dataList =
          Utility.isPresent(data.objects) && data.objects.length > 0
            ? data
            : data.lists[0];

        if (Utility.isPresent(dataObject) && dataObject.length > 0) {
          this.setState(
            {
              itemData: dataList,
              offersData: data,
              filters: dataList.filters,
              quickFilters: dataList.quickFilters,
              showInitialLoadingShimmer: false,
            },
            this.autoApplyFoxyMatchFilters,
          );

          this.animateProductList();

          if (this.listOpenedFromDynamicLink) {
            this.setState({
              promptData: { id: data.id, type: dataList.type },
            });
          }
          if (Utility.isPresent(dataList)) {
            const analyticsMeta = {
              [EventParameterKey.SCREEN_NAME]: promptData.previousScreen || '',
              [EventParameterKey.LIST_ID]: dataList.id,
              [EventParameterKey.LIST_DISPLAY]: dataList.more_page_display,
              [EventParameterKey.LIST_NAME]: dataList.name,
              [EventParameterKey.LIST_CONTENT]: dataList.content,
              [EventParameterKey.LIST_POSITION]: promptData.index,
            };
            AnalyticsManager.logEvent(
              EventType.discoveryEvents.LIST_DETAIL_VIEW,
              analyticsMeta,
            );
            AnalyticsManager.logEvent('list_rendered', {
              id: dataList.id,
            });
          }

          this.trace.stop();
          AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
            [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.OFFER_DETAIL,
          });
          const isComboOffer = data.combo_offer ?? false;
          AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
            [EventParameterKey.SCREEN_NAME]:
              isComboOffer === true
                ? SCREEN_CONSTANTS.COMBO_OFFER_DETAIL
                : SCREEN_CONSTANTS.OFFER_DETAIL,
            [EventParameterKey.PREVIOUS_SCREEN]: promptData.previousScreen,
            [EventParameterKey.COUPON_CODE]: data.coupon_code,
            [EventParameterKey.OFFER_TYPE]: data.offer_type,
            [EventParameterKey.IS_COMBO_OFFER]: isComboOffer,
          });
        } else if (Utility.isPresent(dataObject) && dataObject.length > 0) {
          const { itemData } = this.state;
          const oldData = itemData;
          const { objects } = oldData;
          const newObjects = [...objects, ...dataObject];
          itemData.objects = newObjects;
          this.setState(
            {
              itemData,
              loadMoreItems: true,
            },
            () => {
              this.offerDetailSlug = dataList.slug;
              this.autoFetchOfferDetails();
              this.animateProductList();
            },
          );
        } else {
          this.setState({ loadMoreItems: false });
        }
      } else {
        Utility.clearPageLoadTimer();
        this.setState({
          loadMoreItems: false,
          isPageLoading: false,
          serverError: status !== 404,
        });
      }
    });
  };

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    if (navigation.getState().routes.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
    return true;
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
    this.refreshOfferStrip();
  };

  autoApplyFoxyMatchFilters = () => {
    const { filters } = this.state;
    const { facialProperties } = this.props;
    let foxyMatchAttributes = [];
    if (!this.isFoxyMatchFilterEnableInUrl) {
      return;
    }

    const indexOfFoxyMatch = _.findIndex(
      filters,
      (e) => e?.display === 'foxy_match',
    );

    if (indexOfFoxyMatch === -1) {
      return;
    }

    const foxyMatchFilter = filters[indexOfFoxyMatch];

    const commonUserAttribs = Utility.getCommonUserAttributes(
      facialProperties,
      foxyMatchFilter.criteria,
    );

    commonUserAttribs.forEach((element) => {
      element.values.forEach((elementValue) => {
        foxyMatchAttributes.push({
          attribute_id: element.id,
          value: elementValue.id,
        });
      });
    });

    const foxyMatchFilterObject = {
      criteria_name: 'user_attribute_allowed_value_id',
      value: foxyMatchAttributes,
    };
    setTimeout(() => {
      this.onFiltersPress([foxyMatchFilterObject], true, 0);
    }, 250);

    this.isFoxyMatchFilterEnableInUrl = false;
  };

  setForceRefreshToOfferStrip = (refresh) => {
    this.setState({ forceRefreshOfferStrip: refresh });
  };

  refreshOfferStrip = () => {
    this.setForceRefreshToOfferStrip(true);
    setTimeout(() => {
      this.setForceRefreshToOfferStrip(false);
    }, 500);
  };

  banner = (props) => (
    <FastImageView
      source={{
        uri: getMinifiedImage(
          props.image,
          Utility.getScreenWidth(),
          Utility.getScreenWidth(),
        ),
      }}
      style={style.banner}
      resizeMode="contain"
    />
  );

  showCartTray = () => {
    this.setState({
      isCartTrayVisible: true,
    });
  };

  hideCartTray = () => {
    this.setState({
      isCartTrayVisible: false,
    });
  };

  refreshOffersDetailsPageDiscountStrip = () => {
    this.refreshOfferStrip();
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  fetchFilteredList = (hideOosProduct) => {
    const { fetchList } = this.props;
    const {
      offersData: { combo_offer = false },
    } = this.state;
    fetchList(
      { slug: this.slug, hideOosProduct },
      0,
      (success, data) => {
        this.setState(
          {
            itemData: data,
            showProductShimmer: false,
            showFiltersEmptyState: data?.objects?.length === 0,
          },
          () => this.fnAutoListPagination(this.slug, -1, true),
        );
      },
      combo_offer,
    );
  };

  onFiltersPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation } = this.props;
    const {
      itemData,
      offersData: { id = '', name = '', slug: listSlug = '' },
      selectedFilters,
      filters,
      offersData = EMPTY_OBJECT,
    } = this.state;
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug,
        listData: offersData,
        screenName: SCREEN_CONSTANTS.OFFER_DETAIL,
        entityName: name,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        hideOosProduct,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        resetQuickFilter,
      });
      return;
    }

    // const { list } = FilterUtils.getList(tagData);
    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      itemData,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.OFFER_DETAIL,
      name,
      hideOosProduct,
    );

    const trimmerSlug = slug;
    // if (slug.indexOf('Nan') !== -1) {
    //   trimmerSlug = slug.substr(slug.indexOf('Nan') + 7, slug.length);
    // }

    this.slug = trimmerSlug;
    this.pageNo = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    this.setState({
      selectedFilters: groupedSelectedFilters,
      showProductShimmer: true,
    });

    this.fetchFilteredList(hideOosProduct);

    // this.loadFilteredData(this.listSlug);
  };

  getRouteSlug = () => {
    const { route = {} } = this.props;
    const { params = {} } = route;
    const { slug, shortSlug } = params;
    return slug || getFullSlugFromName(shortSlug, 'offers');
  }

  renderCounter = ({
    counter_max_value,
    counter_current_value,
    counter_suffix,
  }) => {
    return (
      <NumberCounter
        counter_max_value={counter_max_value}
        counter_current_value={counter_current_value}
        counter_suffix={counter_suffix}
      />
    );
  };

  quickFiltersRow = ({
    cartItems = [],
    offersData: {
      id: offerId,
      combo_offer_details: {
        quantity_required: quantities = {},
        max_items,
      } = {},
      show_personalised_details: showPersonalisedDetails = false,
      combo_offer = false,
    },
  }) => {
    const { stickyPosition, selectedFilters, itemData, offerSkuIds } =
      this.state;

    if (Utility.isBlank(itemData)) {
      return null;
    }

    let quantityRequired = 0;
    let totalQuantity = 0;
    Object.entries(quantities).map(([key, value]) => {
      if (key === itemData?.id) {
        quantityRequired = value;
      }
      totalQuantity += value;
    });
    let comboItemsInCartQty = 0;

    cartItems.reduce((filteredCartItems, cartItem) => {
      if (offerSkuIds[parseInt(itemData?.id)]?.includes(cartItem.sku_id)) {
        comboItemsInCartQty += cartItem.quantity;
      }
      return filteredCartItems;
    }, []);
    const quotient = Math.floor(comboItemsInCartQty / quantityRequired);
    const remainder = comboItemsInCartQty % quantityRequired;
    const allowedQuantityCondn =
      quotient * quantityRequired +
      (remainder > 0 || quotient === 0 ? quantityRequired : 0);
    const allowedQuantity = _.min([
      totalQuantity > allowedQuantityCondn
        ? allowedQuantityCondn
        : quantityRequired,
      max_items,
    ]);
    const screen = combo_offer
      ? SCREEN_CONSTANTS.COMBO_OFFER_DETAIL
      : SCREEN_CONSTANTS.OFFER_DETAIL;
    return (
      // <Animated.View style={this.animatedListStyle}>
      <ListsFilter
        scrollY={this.scrollY}
        getQuickFiltersListRef={this.getQuickFiltersListRef}
        stickyPosition={stickyPosition}
        selectedFilters={selectedFilters}
        itemData={itemData}
        onFiltersPress={this.onFiltersPress}
        index={1}
        counter_max_value={allowedQuantity}
        counter_current_value={comboItemsInCartQty}
        counter_suffix={'added'}
        combo_offer={combo_offer}
        previousScreen={screen}
        showPersonalisedDetails={showPersonalisedDetails}
      />
      // </Animated.View>
    );
  };

  filtersShimmer = () => {
    const { showProductShimmer } = this.state;
    if (!showProductShimmer) return null;

    return <GridScreenPlaceHolder />;
  };

  resetFilters = () => {
    const { navigation } = this.props;
    const { hideOosProduct } = this.state;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      showFiltersEmptyState: false,
      showProductShimmer: true,
    });
    this.selectedQuickFilters = [];

    this.fetchFilteredList(hideOosProduct);
  };

  filtersEmptyState = () => {
    const { showFiltersEmptyState } = this.state;
    if (!showFiltersEmptyState) return null;
    return <FilterResultsEmptyState onPressResetButton={this.resetFilters} />;
  };

  setListPaginatedCallApiRef = (fnAutoListPagination = () => {}) => {
    this.fnAutoListPagination = debounce(fnAutoListPagination, 1000, {
      leading: true,
      trailing: false,
    });
  };

  showEmptySearch = () => {
    const { navigation } = this.props;
    navigation.push('Search', {});
  };

  loadingShimmer = () => {
    const { showInitialLoadingShimmer } = this.state;
    if (!showInitialLoadingShimmer) return null;

    return <GridScreenPlaceHolder />;
  };

  setCurrentList = (listId) => {
    const { offersData } = this.state;
    this.pageNo = 0;
    const list = _.find(offersData.lists, (e) => e.id == listId);
    if (Utility.isBlank(list?.slug)) {
      return;
    }
    this.slug = list?.slug;
    this.setState({
      itemData: list,
    });

    if (this.currentListId !== listId) {
      this.productListLeft.setValue(1);
      this.animateProductList();
    }
  };

  animateProductList = () => {
    Animated.timing(this.productListLeft, {
      toValue: 0,
      duration: 300,
    }).start();
  };

  productList = () => {
    const {
      showProductShimmer,
      itemData = {},
      offersData: {
        coupon_code,
        combo_offer,
        id,
        initiate_edge_activation,
        show_invalidity_modal,
        invalid_modal_data = {},
        show_personalised_details: showPersonalisedDetails = false,
        app_only = false,
        short_description = '',
      } = {},
      hideOosProduct = false,
    } = this.state;
    const { objects = [] } = itemData;
    if (Utility.isBlank(this.currentListId)) {
      this.currentListId = itemData.id;
    }
    if (Utility.isBlank(itemData.objects) || showProductShimmer) return null;
    // if (showProductShimmer) return null;

    const screen = combo_offer
      ? SCREEN_CONSTANTS.COMBO_OFFER_DETAIL
      : SCREEN_CONSTANTS.OFFER_DETAIL;
    return (
      <Animated.View style={this.animatedListStyle}>
        <List
          itemData={itemData}
          id={itemData.id}
          listName={itemData.name}
          listContent={itemData.content}
          displayCount={objects.length}
          type={itemData.type}
          size={itemData.size}
          display={itemData.display}
          layout={itemData.display}
          refreshOfferStrip={this.refreshOfferStrip}
          toggleCartVisibility={this.toggleCartVisibility}
          previousScreen={screen}
          showToast={this.showToast}
          paginate={this.paginate}
          setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
          force_apply_coupon={coupon_code}
          offer_id={id}
          abc={() => {}} //TODO: Fix this
          use_modified_product_list={combo_offer}
          key={itemData.id}
          offersData={{
            initiate_edge_activation,
            show_invalidity_modal,
            invalid_modal_data,
            coupon_code,
            app_only,
            id,
            short_description,
          }}
          hideOosProduct={hideOosProduct}
          showPersonalisedDetails={showPersonalisedDetails}
        />
      </Animated.View>
    );
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  isCloseToBottom = ({
    nativeEvent: { layoutMeasurement, contentOffset, contentSize } = {},
  }) => {
    if (
      layoutMeasurement?.height + contentOffset?.y >=
      contentSize?.height - 32
    ) {
      this.fnAutoListPagination();
    }
  };

  scrollToList = () => {
    const { scrollY } = this.state;
    if (scrollY !== 0) {
      this.scrollRef?.scrollTo({ x: 0, y: 260, animated: true });
    }
  };

  scrollToTop = () => {
    const { scrollY } = this.state;
    if (scrollY !== 0) {
      this.scrollRef?.scrollTo({ x: 0, y: 0, animated: true });
    }
  };

  offerHeader = () => {
    const {
      promptData: { promptId = '' },
      forceRefreshOfferStrip,
      shouldShowChooseGiftScreen,
      offersData,
      offersData: { big_banner: bigBanner = '' } = {},
    } = this.state;
    if (Utility.isBlank(offersData)) return null;

    return (
      <View style={style.bannerContainer}>
        <this.banner image={bigBanner} />
        <OfferPagePrompts
          onScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          id={promptId}
          showToast={this.showToast}
          forceRefreshOfferStrip={forceRefreshOfferStrip}
          style={style.offerPromptContainer}
          shouldShowChooseGiftScreen={shouldShowChooseGiftScreen}
          showChooseGiftScreen={this.showChooseGiftScreen}
          offersData={offersData}
        />
      </View>
    );
  };

  foxyMatchSection = () => {
    const {
      offersData: {
        slug: offerSlug = '',
        name = '',
        show_personalised_details: showPersonalisedDetails = false,
      } = {},
      offersData,
    } = this.state;
    console.tron.log(
      showPersonalisedDetails,
      'checkong offer oage',
      offersData,
    );
    if (!showPersonalisedDetails || isBlank(offerSlug)) return null;
    return (
      <FoxyPersonalisedItems
        slug={offerSlug}
        subheading={`${name} products which you shouldn’t miss`}
      />
    );
  };

  render() {
    const {
      itemData = {},
      promptData,
      forceRefreshOfferStrip,
      isCartTrayVisible,
      shouldShowChooseGiftScreen,
      offersData,
      refreshing,
      filters,
      selectedFilters,
      isPageLoading,
      serverError,
      offersData: { coupon_code, deactivated_list } = {},
      offerSkuIds,
      hideOosProduct,
    } = this.state;
    const {
      previousScreen,
      navigation,
      route,
      cartItems,
      isOfferShimmerVisible,
    } = this.props;

    let shareUrl = Utility.isPresent(offersData) ? offersData?.slug : '';
    shareUrl = shareUrl?.replace('/api/v2', WEB_URL);
    shareUrl = shareUrl?.replace('/api/v1', WEB_URL);
    shareUrl = shareUrl?.replace('/api/v3', WEB_URL);
    shareUrl = shareUrl?.replace(`/api/${LISTS_API_VERSION}`, WEB_URL);
    shareUrl = shareUrl?.replace('.json', '');

    const stickyHeader =
      Utility.isPresent(itemData.quick_filters) || offersData?.combo_offer
        ? 2
        : -1;

    if (Utility.isPresent(deactivated_list)) {
      return (
        <>
          {!isDesktop() && (
            <CustomNavigationHeader
              scrollY={this.scrollY}
              goBack={this.onHardwareBackKeyPress}
              slug={itemData.slug}
              shareUrl={shareUrl}
              previousScreen={previousScreen}
              screenName={SCREEN_CONSTANTS.OFFER_DETAIL}
              name={itemData.name}
              image={itemData.image_url}
              showSearchButton
              showShareButton
              shareButtonRightSpace={80}
              onSearchIconTap={this.showEmptySearch}
              utmCampaign="offers"
            >
              <DockedHeader name={offersData.name} />
            </CustomNavigationHeader>
          )}
          <List
            itemData={deactivated_list}
            id={deactivated_list.id}
            previousScreen="offer_detail"
          />
          <AppInstallPrompts link={FOXY_URLS.appInstallPage.offers} />
        </>
      );
    }

    return (
      <ReactNavigationPerformanceView
        screenName='offer_detail'
        renderPassName={isPresent(itemData) ? 'interactive' : 'loading'}
        interactive={isPresent(itemData)}
      >
        <View style={style.container}>
          {!isDesktop() && (
            <CustomNavigationHeader
              scrollY={this.scrollY}
              goBack={this.onHardwareBackKeyPress}
              slug={itemData.slug}
              shareUrl={shareUrl}
              previousScreen={previousScreen}
              screenName={SCREEN_CONSTANTS.OFFER_DETAIL}
              name={itemData.name}
              image={itemData.image_url}
              showSearchButton
              showShareButton
              shareButtonRightSpace={80}
              onSearchIconTap={this.showEmptySearch}
              utmCampaign='offers'
            >
              <DockedHeader name={offersData.name} />
            </CustomNavigationHeader>
          )}
          <ErrorBoundary
            showServerError={serverError}
            pageNotFound={!isPageLoading && !serverError}
            slug={this.getRouteSlug()}
            hideHeader
            screen_name={'offer_Detail'}
          >
            <ScrollView
              ref={(ref) => {
                this.scrollRef = ref;
              }}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={this.onRefresh}
                />
              }
              scrollEventThrottle={16}
              onScroll={Animated.event(
                [{ nativeEvent: { contentOffset: { y: this.scrollY } } }],
                { listener: this.isCloseToBottom },
              )}
              style={style.background}
              stickyHeaderIndices={[stickyHeader]}
              removeClippedSubviews
              contentContainerStyle={style.scrollViewContentStyle}
            >
              <this.offerHeader />
              <this.foxyMatchSection />
              <View>
                {isOfferShimmerVisible ? (
                  <View>
                    <OfferCartItems
                      cartItems={cartItems}
                      navigation={navigation}
                      offersData={offersData}
                      itemData={itemData}
                      scrollY={this.scrollY}
                      scrollToTop={this.scrollToTop}
                      scrollToList={this.scrollToList}
                      setCurrentList={this.setCurrentList}
                      refreshing={refreshing}
                      force_apply_coupon={coupon_code}
                      slug={this.offerSlug}
                      list_to_sku_id={offerSkuIds}
                    />
                    <FullWidthShimmer style={style.fullWidthShimmer} />
                  </View>
                ) : (
                  <OfferCartItems
                    cartItems={cartItems}
                    navigation={navigation}
                    offersData={offersData}
                    itemData={itemData}
                    scrollY={this.scrollY}
                    scrollToTop={this.scrollToTop}
                    scrollToList={this.scrollToList}
                    setCurrentList={this.setCurrentList}
                    refreshing={refreshing}
                    force_apply_coupon={coupon_code}
                    slug={this.offerSlug}
                    list_to_sku_id={offerSkuIds}
                  />
                )}

                <this.quickFiltersRow
                  cartItems={cartItems}
                  offersData={offersData}
                />
              </View>

              <this.filtersShimmer />
              <this.filtersEmptyState />

              <this.productList />
              <this.loadingShimmer />
              <View style={style.verticalSeparator} />
            </ScrollView>
          </ErrorBoundary>

          <CartBuilderSummary
            offersData={offersData}
            itemData={itemData}
            source={SCREEN_CONSTANTS.COMBO_OFFER_DETAIL}
            showStrip
            offerSkuIds={offerSkuIds}
          />
          <CartBuilderSummary
            offersData={offersData}
            itemData={itemData}
            source={SCREEN_CONSTANTS.COMBO_OFFER_DETAIL}
            offerSkuIds={offerSkuIds}
          />

          <Toast
            style={style.toast}
            ref={(ref) => {
              this.toast = ref;
            }}
          />
        </View>
        <AppInstallPrompts link={FOXY_URLS.appInstallPage.offers} />
      </ReactNavigationPerformanceView>
    );
  }
}

const mapStateToProps = (store, ownProps) => ({
  facialProperties: store.UserAccountInfo.facialAnalysis.facialProperties,
  cartItems: store.bag.cartItems,
  isOfferShimmerVisible: store.bag.isOfferShimmerVisible,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { fetchOfferDetails, fetchList, fetchOfferCartItems },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OfferDetail),
);

const style = StyleSheet.create({
  screenContainer: { flex: 1, backgroundColor: 'white' },
  container: {
    flex: 1,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  scrollViewContentStyle: {
    paddingBottom: 44,
  },
  productListContainer: { zIndex: 1 },
  quickFiltersContainer: { marginTop: 10 },
  quickFiltersHeadingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 12,
    marginBottom: 8,
    marginTop: 16,
  },
  quickFiltersTitle: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
    marginLeft: 16,
  },
  background: {
    flex: 1,
    backgroundColor: colors.background,
  },
  wrapper: {
    backgroundColor: colors.background,
  },
  offerDiscountContainer: {
    height: 60,
    flexDirection: 'row',
    backgroundColor: colors.ultraLightFoxyPink,
  },
  bannerContainer: {
    paddingHorizontal: 12,
    marginBottom: 8,
  },
  offerPromptContainer: {
    marginBottom: 100,
    marginHorizontal: 12,
  },
  toast: { position: 'absolute', bottom: 20 },
  verticalSeparator: { height: 40 },

  offerPrompt: { marginBottom: 100, marginHorizontal: 12 },
  banner: {
    width: Utility.getScreenWidth() - 24,
    height: (1 / 3) * Utility.getScreenWidth(),
    marginTop: 12,
    paddingHorizontal: 12,
    alignSelf: 'center',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  fullWidthShimmer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    height: '99%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 8,
    paddingBottom: 6,
  },
});
