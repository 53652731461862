import React, { Component } from 'react';
import { View, StatusBar, BackHandler } from 'react-native';
import { connect } from 'react-redux';
import _ from 'lodash';
import { denormalize } from 'normalizr';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { brandSchema } from '../../config/Schema';
import { BrandCard, BrandDetail } from '../../components/brand';
import { FOXY_URLS, LAYOUT, LISTS_API_VERSION, EMPTY_ARRAY, EMPTY_OBJECT  } from '../../config/Constants';
import { withMaybe, withEither } from '../../lib/Monads';
import { fetchBrand, fetchList } from '../../actions/ActionTypes';
import Utility from '../../utils/Utility';
import { schemaBySlug } from '../../config/SchemaBySlug';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { AnalyticsManager } from '../../analytics';
import FilterUtils, { canOpenSortingModal } from '../../components/sort-filter-options/FilterUtils';
import { StaticNavigationHeader } from '../../components/shared';
import ScreenPlaceholder from '../../components/shared/ScreenPlaceholder';
import ServerError from '../../components/shared/ServerError';
import PageNotFound from '../../components/shared/PageNotFound';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { EventParameterKey } from '../retailStore/analytics';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { getFullSlugFromName, nestedParamToName } from '../../utils/StringUtility';
import AppInstallPrompts from '../../components/shared/AppInstallPrompts';
import { isNative, isPresent } from '../../utils/BooleanUtility';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import withProfiledNavigation from '../../utils/withProfiledNavigation';

class Brand extends Component {
  constructor(props) {
    super(props);
    Utility.setPageLoadStart('brand_detail');
    this.state = {
      brandData: {},
      showShimmer: true,
      stickyPosition: -1,
      selectedFilters: {},
      filters: [],
      quickFilters: [],
      filtersEnabled: false,
      filteredSlug: '',
      isPageLoading: true,
      serverError: false,
      hideOosProduct: false,
      filtersList: {},
    };

    this.slug = '';
    this.prevAppliedFiltersFromModal = [];
    this.selectedQuickFilters = [];
    this.quickFiltersRef = null;
    this.fnAutoListPagination = null;
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
    const { route: { params: { slug = '' } = {} } = {} } = this.props;
    this.brandSlug = this.getSlug().replace(/\/v\d+\//, `/${LISTS_API_VERSION}/`);
  }

  componentDidMount() {
    this.getSlugAndHitApi();
    setTimeout(this.fireListRenderedEvent, 3000);
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backHandler?.remove();
    }
  }

  getSlugAndHitApi = () => {
    const { layout } = this.props;
    if (layout === undefined) {
      this.hitApiCall(this.brandSlug);
    }
  };

  goBack = () => {
    const { navigation } = this.props;
    if (isNative() && navigation.getState().routes.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
    return true;
  };

  fireListRenderedEvent = () => {
    const { itemData } = this.props;
    Utility.fireListRenderedEvent(itemData, SCREEN_CONSTANTS.BRAND_DETAILS);
  };

  fetchBrandCallBack = (success, data, status) => {
    this.setState({
      showShimmer: false,
    });
    if (success) {
      this.addFiltersObjectIntoListData(data);
    } else {
      const { route: { params: { query = '' } = {} } = {} } = this.props;
      AnalyticsManager.logEvent('fetch_brand_fail', {
        [EventParameterKey.SLUG]: this.brandSlug?.slice(0, 98),
        query,
      });
      Utility.clearPageLoadTimer();
      this.setState({
        isPageLoading: false,
        serverError: status !== 404,
      });
    }
  };

  firePageLoadEnd = () => {
    Utility.setPageLoadEnd(SCREEN_CONSTANTS.BRAND_DETAILS);
  };

  hitApiCall = (slug) => {
    this.setState({
      showShimmer: true,
    });
    const { itemData, fetchBrand } = this.props;
    AnalyticsUtility.recordTime(
      {
        screen_name: SCREEN_CONSTANTS.BRAND_DETAILS,
        ...Utility.setRecordTimeEventMeta(itemData, 'brand'),
      },
      this.fetchBrandCallBack,
      fetchBrand,
      { slug },
    );
  };

  setListPaginatedCallApiRef = (fnAutoListPagination) => {
    this.fnAutoListPagination = fnAutoListPagination;
  };

  addFiltersObjectIntoListData = (apiResponse) => {
    const response = apiResponse;
    const {
      list: objects,
      position: stickyPosition,
      slug,
    } = Utility.createFilterObjectIfExists(
      apiResponse.list.objects,
      true,
      false,
      true,
    );
    this.slug = slug;
    response.list.objects = objects;
    const { filters, quickFilters } = FilterUtils.getFiltersFromList(
      response.list,
    );

    this.setState({
      brandData: response,
      filters,
      quickFilters,
      showShimmer: false,
      stickyPosition,
    });
  };

  onFiltersPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation } = this.props;
    const {
      brandData,
      brandData: { id = '', name = '', slug: brandSlug = '' },
      selectedFilters,
      filters,
    } = this.state;
    const { list } = FilterUtils.getList(brandData.list);
    this.setState({
      filtersList: list,
    });
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        selectedFilters,
        modalFilters: filters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug: brandSlug,
        screenName: SCREEN_CONSTANTS.BRAND,
        listData: brandData,
        entityName: name,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.BRAND_DETAILS,
      brandData?.name,
      hideOosProduct,
    );

    this.slug = slug;
    this.page = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    brandData.list.objects = Utility.addShimmerInListObject(brandData.list);
    this.filtersEnabled = this.slug.includes('&');
    this.setState({
      selectedFilters: groupedSelectedFilters,
      brandData,
      filteredSlug: this.slug,
    });

    this.fetchFilteredList(this.slug, hideOosProduct);
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  onRefresh = () => {
    isPresent(this.quickFiltersRef) && this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.filtersEnabled = false;
    this.selectedQuickFilters = [];
    this.getSlugAndHitApi(this.brandSlug);
  };

  resetFilters = () => {
    const { navigation } = this.props;
    const { hideOosProduct } = this.state;
    this.page = 0;
    this.filtersEnabled = false;
    this.slug = this.slug.substr(0, this.slug.indexOf('&'));
    Utility.isPresent(this.quickFiltersRef) &&
      this.quickFiltersRef.resetQuickFilters();
    this.setState({
      selectedFilters: {},
      filteredSlug: '',
    });
    this.selectedQuickFilters = [];

    this.selectedQuickFilters = [];
    this.fetchFilteredList(this.slug, hideOosProduct);
  };

  fetchFilteredList = (slug, hideOosProduct) => {
    const { fetchList } = this.props;
    this.page = 0;
    // FIXME: Buggy Code, need to fix
    fetchList({ slug, hideOosProduct }, this.page, (success, data) => {
      const { brandData } = this.state;
      const paginateLastItem = brandData.list.paginate_last_item;
      const listData = FilterUtils.appendFilteredListToMasterListObjects(
        slug,
        data,
        brandData.list,
      );
      brandData.list = listData;
      this.setState(
        {
          brandData: { ...brandData }, // spreading : this will create a new object ref and will re-render component. Otherwise we are just changing list of main object. Id of previous object will be same and component will not get re-render
        },
        () => {
          if (!paginateLastItem) {
            this.fnAutoListPagination(slug, -1, true);
          }
        },
      );
    });
  };

  itemDataNullFn = (props) => !props.itemData;

  isLayoutScreenFn = (props) => props.layout === 'screen';

  getSlug() {
    const { route = {} } = this.props;
    const { params = {} } = route;
    const {
      slug,
      shortSlug,
      isCategory,
      first,
      second,
      third,
    } = params;
    if (slug) return slug;
    if (!shortSlug) return '';
    return getFullSlugFromName(
      nestedParamToName([shortSlug, isCategory, first, second, third]),
      'brand',
    );
  }

  render() {
    const {
      navigation,
      route,
      itemData,
      isLoading,
      toggleCartVisibility,
      onPressResetButton,
    } = this.props;
    const { params = {} } = route;
    const { shortSlug } = params;
    const {
      brandData,
      showShimmer,
      filters,
      selectedFilters,
      isPageLoading,
      serverError,
      hideOosProduct,
      filtersList = {},
    } = this.state;
    let { layout } = this.props;
    layout = layout || (route.params?.display ?? LAYOUT.SCREEN);

    if (layout === 'screen') {
      return (
        <ReactNavigationPerformanceView
          screenName='brand_detail'
          interactive={isPresent(brandData)}
        >
          {showShimmer && isPageLoading && (
            <ScreenPlaceholder
              fullscreen
              firePageLoadEnd={this.firePageLoadEnd}
            />
          )}
          {!showShimmer && (
            <>
              {isPresent(brandData) && (
                <BrandDetail
                  {...this.props}
                  brandData={brandData}
                  id={brandData.id}
                  layout={layout}
                  isLoading={showShimmer} // for placeholder
                  toggleCartVisibility={toggleCartVisibility}
                  stickyPosition={this.state.stickyPosition}
                  selectedFilters={this.state.selectedFilters}
                  onFiltersPress={this.onFiltersPress}
                  getQuickFiltersListRef={this.getQuickFiltersListRef}
                  onPressResetButton={this.resetFilters}
                  // abc={() => {}}
                  setListPaginatedCallApiRef={this.setListPaginatedCallApiRef}
                  slug={this.slug}
                  filteredSlug={this.state.filteredSlug}
                  resetStatusBar={this.resetStatusBar}
                  filtersEnabled={this.filtersEnabled}
                  isPageLoading={isPageLoading}
                  onRefresh={this.onRefresh}
                  serverError={serverError}
                  hideOosProduct={hideOosProduct}
                  goBack={this.goBack}
                  filtersList={filtersList}
                />
              )}
            </>
          )}
          <AppInstallPrompts link={FOXY_URLS.appInstallPage.brand} />
        </ReactNavigationPerformanceView>
      );
    }
    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchBrand, fetchList }, dispatch),
});

export default withProfiledNavigation(connect(null, mapDispatchToProps)(Brand));
