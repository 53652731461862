import React from 'react';
import { StyleSheet, Text, View, Image } from 'react-native';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 6,
    paddingHorizontal: 12,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  ratingsAndReviewsCount: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
  },
  ratingsAndReviewsCountContainer: { flexDirection: 'row' },
  ratingStar: { width: 14, height: 14, marginLeft: 4 },
  ratingBase: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginTop: -2,
  },
  ratingContainer: { marginBottom: 2, flexDirection: 'row' },
  rating: {
    fontSize: 24,
    fontWeight: '500',
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
});

const ProductRatingsHeader = ({
  reviewData,
  reviewsCount,
  ratingsCount,
  rating,
}) => {
  // if (Utility.isBlank(reviewData)) {
  //   return null;
  // }
  const ratingsText = ratingsCount > 1 ? 'ratings' : 'rating';
  if ((ratingsCount < 1 && reviewsCount < 1) || Utility.isBlank(rating)) {
    return null;
  }
  return (
    <View style={styles.container}>
      {(reviewsCount > 0 || ratingsCount > 0) && Utility.isPresent(rating) && (
        <View style={styles.innerContainer}>
          <Text style={styles.rating}>{rating}</Text>
          <View style={styles.ratingContainer}>
            <Text style={styles.ratingBase}>{` / 5`}</Text>
            <Image source={images.ratingStar} style={styles.ratingStar} />
          </View>
        </View>
      )}
      <View style={styles.ratingsAndReviewsCountContainer}>
        {(ratingsCount > 0 || reviewsCount > 0) && (
          <Text style={styles.ratingsAndReviewsCount}>{`(`}</Text>
        )}
        {ratingsCount > 0 && (
          <Text
            style={styles.ratingsAndReviewsCount}
          >{`${ratingsCount} ${ratingsText}`}</Text>
        )}
        {ratingsCount > 0 && reviewsCount > 0 && (
          <Text style={styles.ratingsAndReviewsCount}>{` | `}</Text>
        )}
        {reviewsCount > 0 && (
          <Text
            style={styles.ratingsAndReviewsCount}
          >{`${reviewsCount} reviews`}</Text>
        )}
        {(ratingsCount > 0 || reviewsCount > 0) && (
          <Text style={styles.ratingsAndReviewsCount}>{`)`}</Text>
        )}
      </View>
    </View>
  );
};

export default ProductRatingsHeader;
