import React from 'react';
import { AnalyticsUtilty } from '../../analytics';
import { View } from 'react-native';
import FastImageView from '../FastImageView';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { StyleSheet } from 'react-native';
import RatingContainer from '../productRatings/RatingContainer';
import { ProductCardStyles } from '../Product/styles';
import AppConfig from '../../config/AppConfig';
import Config from 'react-native-config';
import { getMinifiedImage } from '../../utils/ImageUtility';

const ShopifyProductImageAndRating = (props) => {
  const {
    imageUrl,
    imageId,
    imageName,
    size,
    rating,
    ratingsPosition,
    showWishlistIcon,
    height,
    width,
  } = props;

  const imageError = () => {
    AnalyticsUtilty.fireImageErrorAnalytics(
      imageId,
      imageUrl,
      imageName,
      'product',
    );
  };

  const RatingComponent = () => {
    if (
      isBlank(rating) ||
      AppConfig.getBooleanValue(Config.HIDE_RATING) ||
      ratingsPosition === 'bottom' ||
      ratingsPosition === 'hide' ||
      showWishlistIcon === '1'
    ) {
      return null;
    }

    return (
      <RatingContainer
        rating={rating}
        styles={ProductCardStyles.ratingRectangle}
      />
    );
  };

  const minifiedImageUrl = getMinifiedImage(
    imageUrl,
    width,
    height,
  );

  return (
    <FastImageView
      style={styles.imageStyle}
      source={minifiedImageUrl}
      resizeMode={'contain'}
      onError={imageError}
    >
      <View style={styles.ratingContainer}>
        <RatingComponent />
      </View>
    </FastImageView>
  );
};

export default ShopifyProductImageAndRating;

const styles = StyleSheet.create({
  imageStyle: {
    height: '100%',
  },
  ratingContainer: {
    marginTop: 12,
  },
});
