import React, { useRef } from 'react';
import { TouchableWithoutFeedback, Keyboard, View } from 'react-native';
import { OutlinedTextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { styles } from './styles';

export default function OutlinedTextFieldNumber({
  label,
  value,
  setValue,
  prefix,
  error,
  phoneNumberInputRef,
  maxLength,
}) {

  const handleFocus = () => {
    if (phoneNumberInputRef.current) {
      phoneNumberInputRef.current.focus(); // Focus the input field
    }
  };

  const onChangeText = (text) => {
    if (!text.length) setValue(text);
    if (+text) setValue(+text);
  };

  const labelOffset = { x1: -17, y1: -20, y0: -8 };
  const contentInset = { input: 8 };

  return (
    <View style={styles.wrapper}>
      <TouchableWithoutFeedback onPress={handleFocus}>
        <View>
          <OutlinedTextField
            prefix={prefix}
            label={label}
            tintColor={colors.subtitle}
            inputContainerStyle={styles.textInputContainerStyle}
            lineWidth={0}
            activeLineWidth={0}
            labelOffset={labelOffset}
            contentInset={contentInset}
            keyboardType='numeric'
            value={value}
            onChangeText={onChangeText}
            error={error}
            affixTextStyle={styles.prefix}
            ref={phoneNumberInputRef}
            maxLength={maxLength}
          />
        </View>
      </TouchableWithoutFeedback>
    </View>
  );
}
