import React, { PureComponent } from 'react';
import {
  View,
  TouchableOpacity,
  Image,
  Text,
  Vibration,
  ActivityIndicator,
  Animated,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import _ from 'lodash';
import { withEither } from '../../lib/Monads';
import {
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
  renderOfferShimmers,
  like,
  getCartItems,
} from '../../actions/ActionTypes';
import ProductCardStyles from './styles/styles';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { ProductDetailStyles, IncrementDecrementStyles } from './styles';
import colors from '../../theme/Colors';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import FullWidthShimmer from '../../lib/FullWidthShimmer';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { isWeb } from '../../utils/BooleanUtility';

class IncrementDecrement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
    };
    this.debouncedAddToCart = _.debounce(
      this.incrementProductInCartAndFireAnalytics,
      500,
      {
        leading: true,
        trailing: false,
      },
    );
    this.debouncedRemoveFromCart = _.debounce(
      this.decrementProductInCartAndFireAnalytics,
      500,
      {
        leading: true,
        trailing: false,
      },
    );
    this.debouncedUpdateCart = _.debounce(this.updateCart, 500, {
      leading: true,
      trailing: false,
    });

    const { scrollY } = props;
    if (Utility.isPresent(scrollY)) {
      this.incrementDecrementStyle = [
        IncrementDecrementStyles.incrementDecrementSwitch,
        {
          height: scrollY.interpolate({
            inputRange: [0, 100, 200, 300, 400],
            outputRange: [32, 30, 26, 24, 22],
            extrapolate: 'clamp',
          }),
        },
      ];
    } else {
      this.incrementDecrementStyle =
        IncrementDecrementStyles.incrementDecrementSwitch;
    }
    if (this.props.small) {
      this.incrementDecrementStyle =
        IncrementDecrementStyles.incrementDecrementSwitchSmall;
    }

    if (this.props.variantModal) {
      this.incrementDecrementStyle =
        IncrementDecrementStyles.incrementDecrementSwitchModal;
    }
  }

  loadAddToCartAnimation = (isLoading) => {
    this.setState({
      isAnimating: isLoading,
    });
  };

  changeItemInCart = (data, hideAnimation = false) => {
    const {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      renderOfferShimmers = () => {},
      toTop = () => {},
      itemData,
      selectedVariant,
      quantityAddedToCart,
      productsInCart,
    } = this.props;
    const updatedData = { ...data };

    if (
      !!itemData?.has_variants &&
      quantityAddedToCart == 1 &&
      data.quantity == -1
    ) {
      updatedData['sku_id'] = productsInCart?.[0].sku_id;
    } else if (!!itemData?.has_variants && Utility.isBlank(selectedVariant)) {
      this.showVariantModal();
      return;
    }

    if (!hideAnimation) {
      this.loadAddToCartAnimation(true);
      renderOfferShimmers(true);

      setTimeout(() => {
        renderOfferShimmers(false);
        toTop();
      }, 4000);
    }

    incrementDecrementCartBeforeCall(updatedData);

    incrementDecrementCart(data, (response) => {
      this.loadAddToCartAnimation(false);
      setTimeout(() => {
        renderOfferShimmers(false);
      }, 0);

      this.addToCartCallback(response, data);
    });
  };

  addToCartCallback = (response, data) => {
    const { showToast } = this.props;
    const {
      variant,
      cartItem = {},
      skuId,
      listId,
      force_apply_coupon,
      getCartItems,
      previousScreen = 'combo_offer',
    } = this.props;
    if (
      Utility.isPresent(response.errors) &&
      Utility.isPresent(response.errors[0])
    ) {
      getCartItems(() => {}, true);
      if (showToast) {
        showToast(response.errors[0]);
      }
    } else {
      const productMeta = {
        [EventParameterKey.SCREEN_NAME]: previousScreen,
        [EventParameterKey.SKU_ID]: skuId,
        [EventParameterKey.PRODUCT_ID]: cartItem.id,
        [EventParameterKey.BRAND_ID]: cartItem?.brand?.id || '',
        [EventParameterKey.QUANTITY]: data.quantity,
        [EventParameterKey.ADDRESS_PRESENT]: 'NA',
        [EventParameterKey.LIST_ID]: listId,
        [EventParameterKey.COUPON_CODE]: force_apply_coupon,
      };
      if (Utility.isPresent(variant)) {
        productMeta[[EventParameterKey.VARIANT_ID]] = variant.id;
      }
      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
        productMeta,
      );
    }
  };

  showVariantModal = () => {
    const {
      itemData,
      listId,
      force_apply_coupon,
      campaignId,
      offer_id,
      navigation,
    } = this.props;
    navigation.navigate('VariantModal', {
      itemData,
      listId,
      comboOffer: true,
      offer_id,
      campaignId,
      force_apply_coupon,
    });
  };

  updateCart = (cartItemData) => {
    const {
      listId = '',
      force_apply_coupon,
      itemData,
      quantityAddedToCart,
      productsInCart,
      selectedVariant,
    } = this.props;
    const { skuId: sku_id = '' } = cartItemData;
    const data = {
      sku_id,
      quantity: 1,
      listId,
      force_apply_coupon,
    };

    if (!!itemData?.has_variants && Utility.isBlank(selectedVariant)) {
      this.showVariantModal();
      return;
    }
    this.changeItemInCart(data); // Adding/Removing item from cart
  };

  incrementProductInCartAndFireAnalytics = (cartItemData) => {
    const { listId = '', force_apply_coupon } = this.props;

    const data = {
      sku_id: cartItemData.sku_id,
      quantity: +1,
      source: 'cart',
      listId,
      force_apply_coupon,
    };

    this.changeItemInCart(data, true); // Adding/Removing item from cart
  };

  decrementProductInCartAndFireAnalytics = () => {
    const {
      isFreeItem = false,
      skuId,
      listId,
      force_apply_coupon,
    } = this.props;

    const data = {
      sku_id: skuId,
      quantity: -1,
      is_free: isFreeItem,
      source: 'cart',
      listId,
      force_apply_coupon,
    };

    this.changeItemInCart(data, true); // Adding/Removing item from cart
  };

  checkToAddProductOrShowCart = (cartItemData, showAddedToCart) => {
    const {
      offersData: {
        initiate_edge_activation = false,
        show_invalidity_modal = false,
        invalidModalData = {},
        app_only = false,
        short_description = '',
        coupon_code,
        id = '',
      } = {},
      navigation,
      previousScreen,
    } = this.props;

    if (app_only && isWeb()) {
      navigation.navigate('ApplyOnAppOfferModal', {
        couponCode: coupon_code,
        message: short_description,
        offerId: id,
      });
      return;
    }

    if (initiate_edge_activation) {
      navigation.push(SCREEN_CONSTANTS.BECOME_MEMBER_MODAL, {
        previousScreen,
      });
      return;
    }
    if (show_invalidity_modal) {
      navigation.push(SCREEN_CONSTANTS.UPGRADE_EDGE_MODAL, {
        previousScreen,
        invalidModalData,
      });
      return;
    }

    Vibration.vibrate(200);
    this.debouncedUpdateCart(cartItemData, showAddedToCart);
  };

  onRemoveFromCartPressFromCart = () => {
    const { disableQuantityChange = false } = this.props;
    const { isAnimating, isRemoveProductConfirmationAlertVisible } = this.state;
    if (disableQuantityChange || isAnimating) return;
    if (isRemoveProductConfirmationAlertVisible) {
      this.closeRemoveProductConfirmationAlert();
    }
    this.debouncedRemoveFromCart();
  };

  onAddToCartPressFromCart = (cartItemData) => {
    const {
      isFreeItem = false,
      offersData: {
        initiate_edge_activation = false,
        show_invalidity_modal = false,
        invalidModalData = {},
      } = {},
      navigation,
      previousScreen,
    } = this.props;

    const { isAnimating } = this.state;
    if (initiate_edge_activation) {
      navigation.push(SCREEN_CONSTANTS.BECOME_MEMBER_MODAL, {
        previousScreen,
      });
      return;
    }
    if (show_invalidity_modal) {
      navigation.push(SCREEN_CONSTANTS.UPGRADE_EDGE_MODAL, {
        previousScreen,
        invalidModalData,
      });
      return;
    }
    if (isFreeItem || isAnimating) return;
    this.debouncedAddToCart(cartItemData);
  };

  addToCartButton = (props) => {
    const { showAddedToCart, skuId } = props;
    const {
      itemData: { outOfStock = false, gwp = false } = {},
      variantModal,
      selectedVariant,
    } = this.props;
    const styles = ProductDetailStyles;
    const { isAnimating } = this.state;
    const cartItemData = {
      skuId,
    };

    let addToBagText = outOfStock && !gwp ? 'Out of Stock' : 'Add';

    let buttonStyle =
      outOfStock && !gwp
        ? styles.incrementButtonContainerOos
        : styles.incrementButtonContainer;

    if (variantModal) {
      buttonStyle = styles.incrementButtonContainerVariantModal;
    }

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={() => {
          this.checkToAddProductOrShowCart(cartItemData, showAddedToCart);
        }}
        hitSlop={Utility.getHitSlop()}
        disabled={outOfStock && !gwp}
      >
        <View style={buttonStyle}>
          {isAnimating ? (
            <ActivityIndicator color={colors.white} size="small" />
          ) : (
            <View style={styles.addToBagViewVariantModal}>
              <Text style={styles.addToBagText}>{addToBagText}</Text>
            </View>
          )}
        </View>
      </DebouncedTouchableOpacity>
    );
  };

  handleAddToCartPress = (cartItemData) => {
    return () => {
      this.onAddToCartPressFromCart(cartItemData);
    };
  };

  cartIncrementDecrementButton = (props) => {
    const { skuId, campaignId } = props;
    const { quantity, small, listId, force_apply_coupon, variantModal } =
      this.props;

    const { isAnimating } = this.state;
    const cartItemData = {
      sku_id: skuId,
      quantity,
      campaignId,
      listId,
      force_apply_coupon,
    };
    const styles = IncrementDecrementStyles;
    let plusButtonStyle = styles.plus;

    if (isAnimating) {
      plusButtonStyle = styles.plusWhileCartChanging;
    }

    const handleAddToCartPress = _.memoize(
      () => this.handleAddToCartPress(cartItemData),
      () => [cartItemData.id],
    )();

    if (small) {
      return (
        <Animated.View style={styles.incrementDecrementSwitchSmall}>
          <TouchableOpacity
            onPress={this.onRemoveFromCartPressFromCart}
            style={styles.decrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
          >
            <this.DecrementButtonImage />
          </TouchableOpacity>
          {isAnimating ? (
            <View style={styles.activityIndicator}>
              <ActivityIndicator size="small" color={colors.subtitle} />
            </View>
          ) : (
            <View style={styles.quantityContainer}>
              <Text
                allowFontScaling={false}
                style={styles.productQuantAndVariantText}
              >
                {this.getQuantity()}
              </Text>
            </View>
          )}
          <TouchableOpacity
            onPress={handleAddToCartPress}
            style={styles.incrementButtonContainer}
            hitSlop={Utility.getHitSlop('cross')}
          >
            <Image source={images.plus_thin} style={plusButtonStyle} />
          </TouchableOpacity>
        </Animated.View>
      );
    }

    return (
      <View
        style={
          variantModal
            ? styles.incrementDecrementSwitchModal
            : styles.incrementDecrementSwitch
        }
      >
        <TouchableOpacity
          onPress={this.onRemoveFromCartPressFromCart}
          style={styles.decrementButtonContainer}
          hitSlop={Utility.getHitSlop('cross')}
        >
          <this.WhiteDecrementButtonImage />
        </TouchableOpacity>
        {isAnimating ? (
          <View style={styles.activityIndicator}>
            <ActivityIndicator size="small" color={colors.white} />
          </View>
        ) : (
          <View style={styles.quantityContainer}>
            <Text
              allowFontScaling={false}
              style={styles.productQuantAndVariantTextWhite}
            >
              {this.getQuantity()}
            </Text>
          </View>
        )}
        <TouchableOpacity
          onPress={handleAddToCartPress}
          style={styles.incrementButtonContainer}
          hitSlop={Utility.getHitSlop('cross')}
        >
          <Image source={images.plus_thin} style={styles.plusWhite} />
        </TouchableOpacity>
      </View>
    );
  };

  getQuantity = () => {
    const { quantity, showCartItemQuantity, quantityAddedToCart } = this.props;
    return showCartItemQuantity ? quantity : quantityAddedToCart;
  };

  WhiteDecrementButtonImage = () => {
    const { disableQuantityChange = false } = this.props;
    const { isAnimating } = this.state;
    const styles = IncrementDecrementStyles;
    if (this.getQuantity() > 1) {
      const style = disableQuantityChange
        ? styles.minusDisabled
        : isAnimating
        ? styles.minusWhiteWhileCartChanging
        : styles.minusWhite;

      return <View style={style} />;
    }
    return (
      <Image
        source={images.previewScreenIcons.delete}
        style={styles.deleteWhite}
      />
    );
  };

  DecrementButtonImage = () => {
    const { disableQuantityChange = false } = this.props;
    const { isAnimating } = this.state;
    const styles = IncrementDecrementStyles;
    if (this.getQuantity() > 1) {
      const minusButtonStyle = isAnimating
        ? styles.minusWhileCartChanging
        : styles.minus;

      return (
        <View
          style={
            disableQuantityChange ? styles.minusDisabled : minusButtonStyle
          }
        />
      );
    }
    return (
      <Image source={images.previewScreenIcons.delete} style={styles.delete} />
    );
  };

  isSkuPresentInCart = () => {
    const { quantity, showCartItemQuantity, quantityAddedToCart } = this.props;
    return showCartItemQuantity ? !quantity : !quantityAddedToCart;
  };

  addToCartButton = compose(
    withEither(this.isSkuPresentInCart, this.addToCartButton),
  )(this.cartIncrementDecrementButton);

  render() {
    const {
      layout,
      skuId,
      theme,
      addToCartLayout,
      quantity,
      campaignId,
      isBuyNow,
      isOfferShimmerVisible,
      isFancyCard,
      itemData,
      listId = '',
      force_apply_coupon,
      offer_id,
      selectedVariant,
      variantModal,
    } = this.props;
    return (
      <>
        <this.addToCartButton
          {...this.props}
          theme={theme}
          skuId={skuId}
          layout={layout}
          addToCartLayout={addToCartLayout}
          quantity={quantity}
          campaignId={campaignId}
          isBuyNow={isBuyNow}
        />

        {isOfferShimmerVisible && !isFancyCard && (
          <FullWidthShimmer style={ProductCardStyles.fullWidthShimmer} />
        )}
      </>
    );
  }
}

const mapStateToProps = (store, ownProps) => {
  const { bag: { cartItems = [] } = {} } = store;
  const { skuId, itemData, variantModal, selectedVariant } = ownProps;

  const skuInCart =
    store.bag && _.find(store.bag?.cartItems, (item) => item.sku_id == skuId);

  let productInCart =
    store.bag &&
    _.filter(store.bag?.cartItems, (item) => item.product?.id === itemData?.id);

  if (variantModal && Utility.isPresent(selectedVariant)) {
    productInCart =
      store.bag &&
      _.filter(store.bag?.cartItems, (item = {}) => {
        return item.variant?.id === selectedVariant?.id || item.variant?.shopify_variant_id ==  skuId;
      });
  }

  const productInCartQuantity = _.reduce(
    productInCart,
    (sum, item) => {
      return sum + item.quantity;
    },
    0,
  );

  return {
    isOfferShimmerVisible: store.bag.isOfferShimmerVisible,
    quantityAddedToCart: skuInCart?.quantity || productInCartQuantity,
    productsInCart: productInCart,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      incrementDecrementCart,
      incrementDecrementCartBeforeCall,
      renderOfferShimmers,
      like,
      getCartItems,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
  })(IncrementDecrement),
);
