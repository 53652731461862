import { takeEvery, select, call, put, delay } from 'redux-saga/effects';
import { Platform } from 'react-native';
import {
  ADD_MOBILE_NUMBER,
  SEND_OTP,
  REQUEST_OTP,
  userRegistered,
  fetchInterests,
  newUser,
  hasSelfie,
  VERIFY_TRUECALLER,
  verifyOtpSuccess,
  isOtpRequestedFromHint,
  insertMobileNumber,
  userAuthenticated,
  showProfileShimmer,
  isRequestedFromHintPrompt,
} from '../actions/LoginActions';
import { AnalyticsManager, AnalyticsUtilty } from '../analytics';
import { URL } from '../config/Constants';
import Utility from '../utils/Utility';
import {
  getBoostedOffers,
  getCartItems,
  getUserTodayDeals,
  saveBagItems,
  setInfluencerProfileStatus,
} from '../actions/ActionTypes';
import { getMyAttributes } from './ProfilePicUploadUrlSaga';
import BGNetworkTaskManager from '../utils/BGNetworkTaskManager';
import { postDeviceInformationAfterLogin } from './VerifyOtpSaga';
import {
  getApiHeaders,
  convertJsonFromResponse,
  hasPublicId,
} from './GeneratorUtil';
import AppConfig from '../config/AppConfig';
import { isNative, isPresent } from '../utils/BooleanUtility';
import Config from '../libraries/ReactNativeConfig';

function* setUserIdOrAlias(id) {
  if (!AppConfig.getBooleanValue(Config.SEND_USER_ID_FOR_GUEST_USER)) {
    return;
  }
  if (yield hasPublicId()) {
    AnalyticsManager.setUserAlias(`${id}`);
  }
}

const fetchOTP = (
  mobileNumber,
  callingCode,
  deviceInfo,
  inviteCode,
  guest_token,
  headers,
  fromHintPrompt,
) => {
  const enableInternationalChoice = AppConfig.getBooleanValue(
    Config.ENABLE_INTERNATIONAL_CHOICE,
  );
  const userObject = fromHintPrompt
    ? {
      ...(enableInternationalChoice && { country_code: `${callingCode}` }),
      phone_number: `${mobileNumber}`,
      ALwT4fM2yyy7gbQAFWwyESQZPUcTAmxj: Utility.generateRandomString(
        32,
        'aA#!',
      ),
    }
    : {
      phone_number: `${mobileNumber}`,
      ...(enableInternationalChoice && { country_code: `${callingCode}` }),
    };

    const response = fetch(URL.SEND_OTP, {
    method: 'POST',
    headers,
    body: JSON.stringify({
      guest_token,
      user: { ...userObject },
      device: isNative() ? deviceInfo : null,
      invite_code: inviteCode,
    }),
  });
  return response;
};

function* requestOtp(action) {
  const { callback = () => {}, fromHintPrompt } = action;
  try {
    // This chunk will execute only if render onboarding is invoked from consumer profile screen.

    if (Utility.openOtpModalFromProfile) {
      yield put(showProfileShimmer(true));
      // yield put(isRequestedFromHintPrompt(false));
    }
    for (let i = 0; i < 3; i += 1) {
      // console.tron.log(action.deviceInfo);

      const mobileNumber = yield select(
        (state) => state.UserAccountInfo.mobileNumber,
      );
      const callingCode = yield select(
        (state) => state.UserAccountInfo.callingCode,
      );
      const deviceInfo = yield select(
        (state) => state.UserAccountInfo.deviceInfo,
      );
      const inviteCode = yield select(
        (state) => state.UserAccountInfo.invitationCode,
      );
      const guest_token = yield select(
        (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
      );

      let headers = yield getApiHeaders();
      const response = yield call(
        fetchOTP,
        mobileNumber,
        callingCode,
        action.deviceInfo,
        inviteCode,
        guest_token,
        headers,
        fromHintPrompt,
      );
      if (response.status >= 200 && response.status < 300) {
        const json = yield convertJsonFromResponse(response);

        if (Utility.isBlank(json)) {
          callback({}, false);
          return;
        }
        if (Utility.isAndroid() && Platform.Version > 27) {
          if (callback) {
            callback(json, true);
          }
        }

        if (fromHintPrompt) {
          if (Utility.furtherAction.fromConsumerProfile) {
            return;
          }
          yield put(newUser(json.user.new_user, json.user.has_selfie));
          yield getMyAttributes('', true, false, true);
        }
        // }
        break;
      } else if (response.status < 500) {
        callback({}, false);
        break;
      }
      callback({}, false);
      console.tron.log('Request Otp failure retry ');
    }
  } catch (error) {
    callback({}, false);
    console.tron.log(`Error in sending otp ${error}`);
  }
}

function* verifyTruecaller(action) {
  const { data, callback } = action;
  const { signature, payload } = data;
  for (let i = 0; i < 3; i++) {
    try {
      const postObject = { user: { payload, signed_string: signature } };
      const response = yield fetch(URL.TRUECALLER, {
        headers: yield getApiHeaders(),
        method: 'POST',
        body: JSON.stringify(postObject),
      });

      if (response.status >= 200 && response.status < 300) {
        const json = yield convertJsonFromResponse(response);
        if (Utility.isBlank(json)) {
          return;
        }
        // yield put(
        //   newUser(json.user.new_user, json.user.has_selfie),
        // );
        yield getMyAttributes('', true, false);
        yield put(verifyOtpSuccess(json.user));
        yield put(setInfluencerProfileStatus(isPresent(json?.user?.profile?.id) && isPresent(json?.user?.profile?.handle)));
        yield put(userAuthenticated(true));
        yield put(isRequestedFromHintPrompt(false));
        yield put(getCartItems());
        yield put(getUserTodayDeals());
        yield put(getBoostedOffers());
        AnalyticsManager.setUserProperty('authenticated', 'true');
        if (Utility.isPresent(json?.user?.cart)) {
          yield put(saveBagItems({ items: json.user.cart }));
        }
        if (Utility.isPresent(json?.user?.guest_token)) {
          AnalyticsUtilty.setGuestTokenAsProperties(json?.user?.guest_token);
        }
        // if (Utility.fetchUserAttributesForGuestUserFlowFromHintOrTruecaller) {
        //   Utility.fetchUserAttributesForGuestUserFlowFromHintOrTruecaller = false;

        // }
        if (!Utility.isBlank(json.user.auth_token)) {
          BGNetworkTaskManager.saveAuthToken(json.user.auth_token);
        }
        callback(json.user);
        yield postDeviceInformationAfterLogin();
        //TODO:add callback here
      }
    } catch (error) {
      AnalyticsManager.setUserProperty('authenticated', 'false');
      console.tron.log('Going in to error block');
    }

    yield delay(500);
  }
  callback(false);
}

export default function* watchRequestOtpSaga() {
  yield takeEvery(ADD_MOBILE_NUMBER, requestOtp);
  yield takeEvery(SEND_OTP, requestOtp);
  yield takeEvery(REQUEST_OTP, requestOtp);
  yield takeEvery(VERIFY_TRUECALLER, verifyTruecaller);
}
