import React from 'react';
import { View } from 'react-native';
import Card from '../../lib/Card';
import { ScaleAnimate } from '../shared';
import { StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import {
  getDynamicWidthForGrid,
  getScreenWidth,
} from '../../utils/LayoutUtility';
import { isPresent } from '../../utils/BooleanUtility';
import Config from 'react-native-config';
import ShopifyProductDescription from './ShopifyProductDescription';
import { LAYOUT } from '../../config/Constants';
import ShopifyProductImageAndRating from './ShopifyProductImageAndRating';
import AddToCart from '../Product/AddToCart';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import { useNavigation } from '@react-navigation/native';
import LinearGradient from 'react-native-linear-gradient';
import Utility from '../../utils/Utility';

const x = { x: 0, y: 0 };
const y = { x: 0, y: 1 };

const ShopifyVariantGrid = (props) => {
  const {
    listData: {
      size = 'defaultSize',
      options = {},
      id: listId = '',
      name: listName = '',
      content: listContent = '',
    } = {},
    listData,
    index = '',
    itemData: contentData,
  } = props;
  const itemData = {
    ...contentData,
    ...contentData?.shopify_variant?.data,
    ...contentData?.shopify_variant?.data?.attributes?.data,
  };
  const navigation = useNavigation();
  const {
    name = '',
    vendor: brandName = Config.APP_NAME,
    id,
    handle,
    image_url: imageUrl,
    sku_id: skuId,
    data: variantDetails = {},
    transparent_image_url = '',
    transparent_image_vibrant_colors: {
      vibrant_color: backgroundColor = colors.white,
    } = {},
  } = itemData;
  const { price, compare_at_price: compareAtPrice } = variantDetails || {};
  const {
    title_line_count: titleLineCount,
    hide_brand_name: hideBrandName,
    cta_position_bottom: ctaPositionBottom = '0',
    show_borders_for_list_items = 0,
    disable_card_background_color = '1',
  } = options || {};
  let cardContainerStyle = styles[`${size}CardContainer`];
  const imageContainerStyle = styles[`${size}ImageContainer`];

  const calculateDiscount = (originalPrice = 0, discountedPrice) => {
    const discount =
      ((parseFloat(originalPrice) -
        parseFloat(discountedPrice || originalPrice)) /
        parseFloat(originalPrice)) *
      100;
    return discount === 0 ? null : Math.round(discount);
  };

  const navigateToShopifyProductDetail = () => {
    navigation.push('Product', {
      id,
      slug: handle,
      itemData,
    });
  };

  let height = cardContainerStyle.height;
  if (hideBrandName === '1') {
    height -= 22;
  }
  if (parseInt(titleLineCount) === 1) {
    height -= 25;
  }
  if (ctaPositionBottom === '1') {
    height += 30;
  }
  cardContainerStyle = [cardContainerStyle, { height }];

  if (isPresent(transparent_image_url) || show_borders_for_list_items) {
    cardContainerStyle = [cardContainerStyle, { borderColor: colors.border }];
  }

  const mrp = compareAtPrice || price;
  const sp = price;

  const cartContainerStyle =
    ctaPositionBottom === '0'
      ? styles.cartImageContainer
      : styles.cartImageContainerForBottomCta;

  const finalBackgroundColor =
    disable_card_background_color === '1' || isBlank(transparent_image_url)
      ? colors.white
      : backgroundColor || colors.white;
  const gradientArray = [
    Utility.addAlpha(finalBackgroundColor, 0.15),
    Utility.addAlpha(finalBackgroundColor, 0.25),
  ];
  const transparentImageUrl =
    disable_card_background_color !== '1' ? transparent_image_url : '';
  return (
    <ScaleAnimate {...props} onPress={navigateToShopifyProductDetail}>
      <Card style={cardContainerStyle}>
        <View style={imageContainerStyle}>
          <ShopifyProductImageAndRating
            imageUrl={imageUrl}
            size={size}
            height={imageContainerStyle?.height}
            width={cardContainerStyle?.width}
            transparentImageUrl={transparentImageUrl}
          />
          <LinearGradient
            colors={gradientArray}
            style={styles.gradient}
            start={x}
            end={y}
          />
        </View>
        <View style={styles.fullCardDivider} />
        <ShopifyProductDescription
          itemData={itemData}
          name={name}
          brandName={brandName}
          layout={LAYOUT.GRID}
          titleLineCount={parseInt(titleLineCount)}
          hideBrandName={hideBrandName === '1'}
          mrp={mrp}
          sp={sp}
          discount={calculateDiscount(mrp, sp)}
        />
        <View style={cartContainerStyle}>
          <AddToCart
            skuId={skuId}
            layout={LAYOUT.GRID}
            addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
            id={id}
            itemData={itemData}
            listId={listId}
            listName={listName}
            listContent={listContent}
            index={index}
            listData={listData}
            cta_position_bottom={ctaPositionBottom}
            hasVariants={false}
          />
        </View>
      </Card>
    </ScaleAnimate>
  );
};

ShopifyVariantGrid.getComponentHeight = (size) =>
  styles[`${size}CardContainer`]?.height || 200;

export default ShopifyVariantGrid;

const styles = StyleSheet.create({
  defaultSizeCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  dynamicCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  smallCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: getDynamicWidthForGrid(2, 16),
    height: getDynamicWidthForGrid(2, 16) + 116,
    alignSelf: 'center',
  },
  largeCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    width: getScreenWidth() / 2.15,
    height: ((4 / 3) * getScreenWidth()) / 2.15 + 116,
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingBottom: 4,
    marginBottom: 6,
    borderRadius: isPresent(Config.LARGE_PRODUCT_CARD_CORNER)
      ? parseInt(Config.LARGE_PRODUCT_CARD_CORNER)
      : 0,
  },
  slimCardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    margin: 4,
    flexDirection: 'column',
    borderRadius: 4,
    width: (getScreenWidth() * 0.67) / 2,
    height: (getScreenWidth() * 0.67) / 2 + 116,
    alignSelf: 'center',
  },
  slimImageContainer: {
    height: (getScreenWidth() * 0.67) / 2,
    resizeMode: 'contain',
    overflow: 'hidden',
  },
  cartImageContainerForBottomCta: {
    width: '100%',
    marginTop: 8,
  },
  dynamicImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  gradient: {
    height: '100%',
    width: '100%',
    borderRadius: 4,
    top: 0,
    position: 'absolute',
    zIndex: -1,
  },
  defaultSizeImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  smallImageContainer: {
    height: getDynamicWidthForGrid(2, 16),
    width: '100%',
  },
  largeImageContainer: {
    height: ((4 / 3) * getScreenWidth()) / 2.15,
    width: '100%',
  },
  fullCardDivider: {
    height: 1,
    width: '100%',
    backgroundColor: colors.borderColor,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 94,
    right: 10,
  },
  variantContainerStyle: {
    position: 'absolute',
    left: 12,
    top: 16,
    borderWidth: 1,
    borderColor: colors.border,
    borderRadius: 15,
    maxWidth: 84,
    padding: 2,
    backgroundColor: '#ffffff',
  },
});
