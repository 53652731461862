import { Platform } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import { getScreenWidth } from '../../../utils/LayoutUtility';
import { isDesktop } from '../../../utils/BooleanUtility';

export const { ids: promoCodeIds, styles: PromoCodeStyles } = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  promoCodeContainer: {
    flex: 1,
    backgroundColor: colors.background,
    borderRadius: 6,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  activityIndicatorContainer: {
    height: Utility.getScreenHeight() / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    position: 'absolute',
    top: 0,
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 6,
    borderBottomWidth: 2,
    borderBottomColor: colors.border,
    zIndex: 1,
  },
  cartPageHeader: {
    position: 'absolute',
    top: 0,
    height: 50,
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderRadius: 6,
    borderBottomWidth: 1,
    borderBottomColor: colors.border,
    zIndex: 1,
  },

  backArrow: {
    width: 20,
    height: 17.5,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
    marginLeft: 16,
  },
  headerText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 18,

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 12,
    color: colors.foxyBlack,
  },
  applyButton: {
    position: 'absolute',
    right: 12,
    left: 12,
    height: 44,
    bottom: 4,
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  subHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    marginLeft: 12,
    marginTop: 16,
    marginBottom: 8,
  },
  promocodeBox: {
    marginTop: 8,
    backgroundColor: colors.white,
    padding: 12,
  },
  promoIDContainer: {
    height: 27,
    backgroundColor: '#facfd1',
    paddingLeft: 8,
    paddingRight: 8,
    borderWidth: 1,
    borderColor: colors.foxyPink,
    borderStyle: 'dashed',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  promoIdText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.ultraDarkBlue,
  },
  promoTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginBottom: 4,
  },
  promoDescription: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
  },
  promoDetailButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyPink,
  },
  rightArrow: {
    tintColor: colors.foxyPink,
  },
  viewDetailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 12,
  },
  radioButtonContainer: {
    alignSelf: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 8,
  },
  divider: {
    height: 2,
    width: '100%',
    backgroundColor: colors.border,
    marginTop: 12,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    flexDirection: 'column',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    backgroundColor: 'rgba(255,255,255,0.8)',
  },
  promoCodeScrollView: {
    marginTop: 0,
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
    ...Platform.select({
      web: { marginBottom: 0 },
      default: { marginBottom: 70 + Utility.bottomInset },
    }),
  },
  spacer: {
    width: '100%',
    ...Platform.select({
      web: { height: 100 },
      default: { height: 300 },
    }),
  },
  bannerImage: {
    height: isDesktop() ? 350 : (1 / 3) * (Utility.getScreenWidth() - 24),
    borderRadius: 4,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: colors.border,
    marginTop: 16,
    width: getScreenWidth() - 24,
  },
  promoActionText: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h4,
    color: colors.foxyPink,
  },
  offer_discount_coupon_container: {
    height: 45,
    paddingLeft: 8,
    paddingRight: 8,
    justifyContent: 'center',
    borderRadius: 4,
  },
  couponInput: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.foxyPink,
    cursor: 'pointer',
    ':hover': {
      opacity: 0.7,
    },
  },
  ctaTextContainer: { position: 'absolute', top: 16, right: 16 },
  ctaText: {
    color: colors.cta.lightBlue,
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
  },
  keyboardAvoidingView: {
    borderRadius: 6,
    flex: 1,
  },
});
