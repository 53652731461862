import { View, Text, TouchableOpacity, Image } from 'react-native';
import React, { useEffect, useRef, useState } from 'react';
import { parseInt } from 'lodash';
import StyleSheet from 'react-native-media-query';
import { OutlinedTextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { ids as formIds, styles as formStyles } from './styles';
import DegreeTitlePicker from './DegreeTitlePicker';
import FileUpload from './FileUpload';
import images from '../../theme/Images';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';


export default function Certificate({
  index,
  certificate = {},
  editCertificate = () => {},
  deleteCertificate = () => {},
  certificate: { specialization = '', institute = '', graduation = '' } = {},
  uploadedCertificates = [],
  addCertificate = () => {},
}) {

  const [file, setFile] = useState({});
  const specializationInputRef = useRef(null);
  const instituteInputRef = useRef(null);
  const graduationInputRef = useRef(null);
  useEffect(() => {
    focusInputIfPresent(specialization, specializationInputRef);
  }, [specialization]);

  useEffect(() => {
    focusInputIfPresent(institute, instituteInputRef);
  }, [institute]);

  useEffect(() => {
    focusInputIfPresent(graduation, graduationInputRef);
  }, [graduation]);

  const focusInputIfPresent = (value, ref) => {
    if (isPresent(value)) {
      ref?.current?.focus();
    }
  };

  const handleSetSpecialization = (text) => {
    editCertificate({ ...certificate, specialization: text }, index);
  };

  const handleSetInstitute = (text) => {
    editCertificate({ ...certificate, institute: text }, index);
  };

  const handleSetGraduationYear = (text) => {
    editCertificate({ ...certificate, graduation: text }, index);
  };

  const handleSetFile = (fi = {}) => {
    setFile(fi);
    editCertificate({ ...certificate, certificate: fi.data }, index);
  };

  const handleDeleteCertificate = () => deleteCertificate(index);
  const labelOffset = { x1: -17, y1: -20, y0: -8 };
  const contentInset = { input: 8 };
  return (
    <View style={styles.container}>
      {index !== 0 && (
        <TouchableOpacity style={styles.crossStyle} onPress={handleDeleteCertificate}>
          <Image source={images.cross} style={{ tintColor: colors.black }} />
        </TouchableOpacity>
      )}
      <DegreeTitlePicker index={index} editCertificate={editCertificate} certificate={certificate} />
      <View style={styles.specializationContainer}>
        <OutlinedTextField
          label='Institute'
          tintColor={colors.subtitle}
          inputContainerStyle={formStyles.textInputContainerStyle}
          lineWidth={0}
          activeLineWidth={0}
          labelOffset={labelOffset}
          contentInset={contentInset}
          value={certificate?.institute}
          onChangeText={handleSetInstitute}
          ref={instituteInputRef}
        />
      </View>
      <View style={styles.specializationContainer}>
        <OutlinedTextField
          label='Year of Graduation'
          tintColor={colors.subtitle}
          inputContainerStyle={formStyles.textInputContainerStyle}
          lineWidth={0}
          activeLineWidth={0}
          labelOffset={labelOffset}
          contentInset={contentInset}
          value={certificate?.graduation}
          onChangeText={handleSetGraduationYear}
          ref={graduationInputRef}
          keyboardType='numeric'
          error={certificate?.graduationError}
        />
      </View>
      <View style={styles.specializationContainer}>
        <OutlinedTextField
          label='Specialization'
          tintColor={colors.subtitle}
          inputContainerStyle={formStyles.textInputContainerStyle}
          lineWidth={0}
          activeLineWidth={0}
          labelOffset={labelOffset}
          contentInset={contentInset}
          value={certificate?.specialization}
          onChangeText={handleSetSpecialization}
          ref={specializationInputRef}
        />
      </View>
      <View style={styles.fileContainer}>
        <View style={styles.alignCenterContainer}>
          <FastImageView source={getMinifiedImage(images.verified_tick)} style={styles.verifiedTick} />
          <Text style={formStyles.label}>Upload Certificates for Blue Tick Verification (Optional)</Text>
        </View>
        <FileUpload
          setFile={handleSetFile}
          label='Choose File'
          name={file.name}
          accept='image/*,application/pdf'
          sizeInMb={10}
          handleCancel={handleSetFile}
          docType={file.type}
          certificate={certificate}
          uploadedCertificates={uploadedCertificates}
          screenName={SCREEN_CONSTANTS.ABOUT_YOU}
        />
      </View>
      <View style={styles.addMoreClinics}>
        <TouchableOpacity onPress={addCertificate} style={styles.addMoreClinics}>
          <FastImageView
            source={getMinifiedImage(images.plus_button_circle, styles.plusIcon.width)}
            style={styles.plusIcon}
            tintColor={colors.foxyBlue}
            resizeMode='cover'
          />
          <Text style={styles.textStyle}>Add more degrees</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    marginVertical: 10,
    paddingTop: 10,
    width: '100%',
    paddingHorizontal: 10,
    marginBottom: 20,
  },
  titleContainer: {},
  specializationContainer: {
    marginTop: 20,
  },
  fileContainer: {
    marginTop: 10,
  },
  crossStyle: {
    position: 'absolute',
    right: 0,
    height: 22,
    width: 22,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
    borderRadius: 50,
    boxShadow: '0px 2px 10px 1px rgba(229,229,229,0.8)',

    '@media (min-width: 768px)': {
      bottom: 3,
      right: -6,
    },
  },
  plusIcon: {
    width: 20,
    height: 20,
    tintColor: colors.foxyBlue,
    alignSelf: 'center',
    marginBottom: 2,
  },
  textStyle: {
    color: colors.foxyBlue,
    fontSize: 14,
    marginBottom: 4,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
    padding: 4,
  },
  addMoreClinics: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  verifiedTick: {
    height: 19,
    width: 19,
    marginHorizontal: 4,
  },
  alignCenterContainer: { flexDirection: 'row', alignContent: 'center' },
});
