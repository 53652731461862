import React, { Component } from 'react';
import { View, Image, Text, TouchableOpacity, Dimensions } from 'react-native';
import _ from 'lodash';
// Styles Imports
import styles from './Styles';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { LAYOUT } from '../../config/Constants';
import ScaleAnimate from '../shared/ScaleAnimate';
import CrossButton from '../progressBarView/CrossButton';
import { AnalyticsManager } from '../../analytics';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class StoryHeader extends Component {
  static crossButtonWidth = 12.0;

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 500, {
      leading: true,
      trailing: false,
    });

    this.navigationSource = getNavigationSource(this, this.props);
  }

  ratioMultiplier = () => Dimensions.get('window').width / 360;

  handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, route, { slug, extra });
  };

  navigate = () => {
    const {
      publisherName,
      navigation,
      publisherId,
      publisherSlug,
      publisherType,
      goBack,
      destination,
      sortStories,
      index,
    } = this.props;
    AnalyticsManager.logEvent('story_header_clicked', {
      publisher_type: publisherType,
      publisher_name: publisherName,
      publisher_id: Utility.isPresent(publisherId) ? publisherId : '',
      index,
      destination_page_url: Utility.isPresent(destination) ? destination : '',
    });
    if (!Utility.isBlank(destination)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        destination,
        this.handleRouteFromLink,
      );
    } else if (publisherName === 'Offer') {
      navigation.navigate('Store');
      sortStories();
    } else if (publisherName === 'Foxy') {
      sortStories();
      navigation.navigate('Feed');
    } else if (publisherType === 'artist') {
      navigation.push(this.navigationSource, 'Artist', {
        id: publisherId,
        slug: publisherSlug,
        display: LAYOUT.SCREEN,
      });
    } else if (publisherType === 'brand') {
      navigateToScreen({
        navigation,
        type: 'push',
        screen: 'Brand',
        navigationSource: this.navigationSource,
        params: {
          id: publisherId,
          slug: publisherSlug,
          display: LAYOUT.SCREEN,
        },
      });
    }
  };

  PublisherAvatar = () => {
    const { publisherThumbnail, imageUrl, personalised_story } = this.props;
    const artistImage =
      Utility.isPresent(imageUrl) && personalised_story
        ? imageUrl
        : publisherThumbnail;
    const avatarWidth = styles.avatarImage.width;
    const avatarHeight = styles.avatarImage.height;
    return (
      <View style={styles.avatarImageContainer}>
        <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
          <FastImageView
            style={styles.avatarImage}
            source={{
              uri: Utility.isBlank(artistImage)
                ? 'https://apan.net/people/avatar.png'
                : getMinifiedImage(
                  artistImage,
                  avatarWidth,
                  avatarHeight,
                ),
            }}
          />
        </ScaleAnimate>
      </View>
    );
  };

  PublisherTitle = () => {
    const { publisherName, displayName } = this.props;

    return (
      <View style={styles.textContainer}>
        <TouchableOpacity
          onPress={this.debouncedNavigate}
          hitSlop={Utility.getHitSlop()}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text
              numberOfLines={1}
              style={{
                fontSize: 14,
                fontWeight: '800',
                fontStyle: 'normal',
                color: '#ffffff',
                zIndex: 10000,
              }}
            >
              {Utility.isBlank(displayName) ? publisherName : displayName}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  FollowButton = () => {
    const { onFollowTapped, storyHeader } = this.props;
    let icon = images.mediaDetailPage.followingButton;

    if (storyHeader) {
      icon = images.mediaDetailPage.followingButton;
    }
    return (
      <TouchableOpacity
        onPress={onFollowTapped}
        style={{
          width: 41.0,
          height: 25.0,
          marginTop: 5.5,
          marginBottom: 5.5,
          position: 'absolute',
          right: 12,
          zIndex: 10000,
        }}
      >
        <Image source={icon} />
      </TouchableOpacity>
    );
  };

  render() {
    const { availableWidth, headerContainerStyle, showElements } = this.props;
    return (
      <View style={headerContainerStyle}>
        <View
          style={{
            height: 36,
            width: availableWidth,
            flexDirection: 'row',
            top: 5,
          }}
          viewName="description"
        >
          <View
            style={{
              height: 36,
              width: 296 * this.ratioMultiplier(),
              flexDirection: 'row',
              marginLeft: 16,
            }}
          >
            <this.PublisherAvatar />
            <this.PublisherTitle />
          </View>
          <View>{showElements && <this.FollowButton />}</View>
        </View>
      </View>
    );
  }
}

export default withProfiledNavigation(StoryHeader);
