//TODO: Need to move this file to server

import React from 'react';
import {
  View,
  Text,
  Image,
  ScrollView,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import { getScreenWidth } from '../../utils/LayoutUtility';
import images from '../../theme/Images';

export default function PrivacyConcerns() {
  return (
    <ScrollView style={styles.mainBody}>
      <Text style={styles.text}>{privacyConcerns.title}</Text>
      {privacyConcerns.sections.map((section, index) => (
        <Section {...section} index={index} />
      ))}
    </ScrollView>
  );
}

const Section = ({ title, steps, index }) => (
  <View style={styles.section} key={title}>
    <Text style={styles.sectionTitle}>{`${index + 1}. ${title}`}</Text>
    <View style={styles.stepContainer}>
      {steps.map((step, stepIndex) => <Step {...step} index={stepIndex} />)}
    </View>
  </View>
);

const Step = ({
  text,
  boldText,
  image,
  index,
}) => (
  <View style={styles.step} key={boldText} dataSet={{ media: ids.step }}>
    <Text style={styles.stepTextContainer} dataSet={{ media: ids.stepTextContainer }}>
      <Text style={styles.boldText}>{`Step ${index + 1}: `}</Text>
      <Text style={styles.text}>{`${text} `}</Text>
      <Text style={styles.boldText}>{boldText}</Text>
    </Text>
    <Image
      source={{ uri: images.privacyConcerns[image] }}
      style={styles.stepImage}
      dataSet={{ media: ids.stepImage }}
      resizeMode="cover"
    />
  </View>
);

const { ids, styles } = StyleSheet.create({
  mainBody: {
    backgroundColor: '#f7f7f7',
    margin: 0,
    padding: 20,
  },
  text: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
  },
  section: {
    marginVertical: 20,
  },
  sectionTitle: {
    fontSize: 24,
    fontFamily: 'Robot-Regular',
    fontWeight: '700',
    marginBottom: 20,
  },
  stepContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  step: {
    borderWidth: 1,
    borderColor: '#ddd',
    borderRadius: 5,
    backgroundColor: '#fff',
    maxWidth: 400,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: 40,
    '@media (min-width: 768px)': {
      marginRight: 20,
    },
  },
  stepImage: {
    width: getScreenWidth() - 40,
    height: 720,
    borderRadius: 5,
    marginTop: 20,
    '@media (min-width: 768px)': {
      marginTop: 10,
      width: 330,
    },
  },
  boldText: {
    fontWeight: '700',
  },
  stepTextContainer: {
    padding: 20,
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    color: '#555',
    width: getScreenWidth() - 40,
    borderBottom: '2px solid #0000001f',
    paddingBottom: 10,
    '@media (min-width: 768px)': {
      width: 332,
    },
  },
});

const privacyConcerns = {
  title: 'Foxy values your privacy. Please follow the instructions below to delete your private data.',
  sections: [
    {
      title: 'How to Delete your Selfie',
      steps: [
        {
          text: 'Go to My Profile and click on',
          boldText: 'Account Settings',
          image: 'account',
        },
        {
          text: 'Click on',
          boldText: 'Delete Selfie',
          image: 'accountSettings',
        },
        {
          text: 'Again click on',
          boldText: 'Delete Selfie',
          image: 'deleteSelfie',
        },
        {
          text: 'Select a reason and click on',
          boldText: 'Delete Selfie',
          image: 'deleteSelfieReasons',
        },
      ],
    },
    {
      title: 'How to Delete your Account',
      steps: [
        {
          text: 'Go to My Profile and click on',
          boldText: 'Account Settings',
          image: 'account',
        },
        {
          text: 'Click on',
          boldText: 'Delete Account',
          image: 'accountSettings',
        },
        {
          text: 'Click on',
          boldText: 'I am sure I want to Delete My Account',
          image: 'deleteAccount',
        },
        {
          text: 'Select a reason and click on',
          boldText: 'Delete Account',
          image: 'deleteAccountReasons',
        },
      ],
    },
  ],
};
