import { getStoreRef } from "../../store/StoreUtility";
import { isBlank } from "../../utils/BooleanUtility";

export default class RoutinesUtility {
  static getLocalTimeFromUTC = (datetime) => {
    const date = new Date(datetime);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const day = date.getUTCDate();
    const hour = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    return new Date(year, month, day, hour, minutes, seconds);
  };

  // static isStartTimeIn24Hours(startDateStr) {
  //   const currentDate = new Date();
  //   const startDate = new Date(startDateStr);
  //   if (currentDate > startDate) {
  //     return false;
  //   }
  //   const timeDiff = startDate.getTime() - currentDate.getTime();
  //   const timeDiffInHours = timeDiff / (1000 * 60 * 60);
  //   if (timeDiffInHours < 24) {
  //     return true;
  //   }
  //   return false;
  // }

  static addXDaysToDate = (date, dayNum) => {
    const selectedDayInMil = 60 * 60 * 24 * 1000 * dayNum;
    return new Date(new Date(date).getTime() + selectedDayInMil);
  };

  static isLive = (startDateStr, endDateStr) => {
    const currentDate = new Date();
    const startDate = RoutinesUtility.getLocalTimeFromUTC(startDateStr);
    const endDate = RoutinesUtility.getLocalTimeFromUTC(endDateStr);
    return currentDate >= startDate && currentDate <= endDate;
  };

  static isEnded = (endDateStr) => {
    const currentDate = new Date();
    const endDate = RoutinesUtility.getLocalTimeFromUTC(endDateStr);

    return currentDate > endDate;
  };

  static isToday = (someDateStr) => {
    const today = new Date();
    const someDate = RoutinesUtility.getLocalTimeFromUTC(someDateStr);
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  static isDateInFuture = (someDateStr) => {
    const currentDate = new Date();
    const someDate = RoutinesUtility.getLocalTimeFromUTC(someDateStr);
    if (currentDate.getDate() >= someDate.getDate()) {
      if (currentDate.getMonth() >= someDate.getMonth()) {
        return currentDate.getFullYear() < someDate.getFullYear();
      }
      return currentDate.getMonth() < someDate.getMonth();
    }
    return (
      currentDate.getDate() < someDate.getDate() &&
      currentDate.getMonth() <= someDate.getMonth() &&
      currentDate.getFullYear() <= someDate.getFullYear()
    );
  };

  static isDateInPast = (someDateStr) => {
    const currentDate = new Date();
    const someDate = RoutinesUtility.getLocalTimeFromUTC(someDateStr);
    if (currentDate.getDate() <= someDate.getDate()) {
      if (currentDate.getMonth() <= someDate.getMonth()) {
        return currentDate.getFullYear() > someDate.getFullYear();
      }
      return currentDate.getMonth() > someDate.getMonth();
    }
    return (
      currentDate.getDate() > someDate.getDate() &&
      currentDate.getMonth() >= someDate.getMonth() &&
      currentDate.getFullYear() >= someDate.getFullYear()
    );
  };

  static getMonthString(month) {
    let monthStr = 'December';
    switch (month) {
      case 0:
        monthStr = 'January';
        break;
      case 1:
        monthStr = 'February';
        break;
      case 2:
        monthStr = 'March';
        break;
      case 3:
        monthStr = 'April';
        break;
      case 4:
        monthStr = 'May';
        break;
      case 5:
        monthStr = 'June';
        break;
      case 6:
        monthStr = 'July';
        break;
      case 7:
        monthStr = 'August';
        break;
      case 8:
        monthStr = 'September';
        break;
      case 9:
        monthStr = 'October';
        break;
      case 10:
        monthStr = 'November';
        break;
      default:
        break;
    }
    return monthStr;
  }

  static getLocalDateString() {
    const someDate = new Date();
    const hours =
      someDate.getHours() > 12 ? someDate.getHours() - 12 : someDate.getHours();
    const minutes = someDate.getMinutes();
    const amOrPm = someDate.getHours() > 12 ? 'PM' : 'AM';
    let date = `${RoutinesUtility.getMonthString(
      someDate.getMonth(),
    )} ${someDate.getDate()}, ${hours}:${minutes}${amOrPm}`;
    return date;
  }

  static getDateString(someDateStr) {
    const someDate = RoutinesUtility.getLocalTimeFromUTC(someDateStr);
    const hours =
      someDate.getHours() > 12 ? someDate.getHours() - 12 : someDate.getHours();
    const minutes = someDate.getMinutes();
    const amOrPm = someDate.getHours() > 12 ? 'PM' : 'AM';
    let date = `${RoutinesUtility.getMonthString(
      someDate.getMonth(),
    )} ${someDate.getDate()}, ${hours}:${minutes}${amOrPm}`;
    return date;
  }

  static getStartDateString(
    startDateStr,
    endDateStr,
    isLive = false,
    showLessThan24hrsTimeDiff,
  ) {
    const currentDate = new Date();
    const startDate = RoutinesUtility.getLocalTimeFromUTC(startDateStr);
    const endDate = RoutinesUtility.getLocalTimeFromUTC(endDateStr);
    const startMonthString = RoutinesUtility.getMonthString(
      startDate.getMonth(),
    );
    if (isLive) {
      return `${startMonthString} ${startDate.getDate()}`;
    }
    if (currentDate < startDate) {
      const timeDiff = startDate.getTime() - currentDate.getTime();
      const timeDiffInMinutes = parseInt(timeDiff / (1000 * 60));
      const timeDiffInHours = parseInt(timeDiffInMinutes / 60);
      const timeDiffInDays = parseInt(timeDiffInHours / 24);
      if (timeDiffInMinutes <= 60 && timeDiffInMinutes !== 0) {
        const minuteStr = timeDiffInMinutes <= 1 ? 'minute' : 'minutes';
        return `Starts in ${timeDiffInMinutes} ${minuteStr}`;
      }
      if (timeDiffInHours <= 24 && timeDiffInHours !== 0) {
        const hourStr = timeDiffInHours <= 1 ? 'hour' : 'hours';
        return showLessThan24hrsTimeDiff
          ? `Starts in ${parseInt(timeDiffInHours)} ${hourStr}`
          : '';
      }
      if (timeDiffInDays <= 15 && timeDiffInDays !== 0) {
        const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
        return `Starts in ${timeDiffInDays} ${daysStr}`;
      }
      return `Starts on ${startMonthString} ${startDate.getDate()}`;
    }

    if (currentDate > endDate) {
      const timeDiff = currentDate.getTime() - endDate.getTime();
      const timeDiffInMinutes = parseInt(timeDiff / (1000 * 60));
      const timeDiffInHours = parseInt(timeDiffInMinutes / 60);
      const timeDiffInDays = parseInt(timeDiffInHours / 24);
      if (timeDiffInMinutes <= 60 && timeDiffInMinutes !== 0) {
        const minuteStr = timeDiffInMinutes <= 1 ? 'minute' : 'minutes';
        return `Ended ${timeDiffInMinutes} ${minuteStr} ago`;
      }
      if (timeDiffInHours <= 24 && timeDiffInHours !== 0) {
        const hourStr = timeDiffInHours <= 1 ? 'hour' : 'hours';
        return `Ended ${timeDiffInHours} ${hourStr} ago`;
      }
      if (timeDiffInDays <= 15 && timeDiffInDays !== 0) {
        const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
        return `Ended ${timeDiffInDays} ${daysStr} ago`;
      }
      const endMonthString = RoutinesUtility.getMonthString(endDate.getMonth());
      return `Ended on ${endMonthString} ${endDate.getDate()}`;
    }
    return `${RoutinesUtility.getMonthString(
      startDate.getMonth(),
    )} ${startDate.getDate()}`;
  }

  static getEndsAtString(endDateStr) {
    const currentDate = new Date();
    const timeDiff = endDateStr.getTime() - currentDate.getTime();

    const timeDiffInMinutes = parseInt(timeDiff / (1000 * 60));
    const timeDiffInHours = parseInt(timeDiffInMinutes / 60);
    const timeDiffInDays = parseInt(timeDiffInHours / 24);

    if (timeDiffInDays !== 0) {
      const daysStr = timeDiffInDays <= 1 ? 'day' : 'days';
      return `Expiring in ${timeDiffInDays} ${daysStr}`;
    }
    return '';
  }
}

export const isRoutineLive = (slug) => {
  const store = getStoreRef().getState();
  const activeRoutineSlugs = store?.UserAccountInfo?.activeRoutineSlugs;
  if (isBlank(activeRoutineSlugs)) return null;
  return activeRoutineSlugs?.includes(slug);
};
