import React from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import Modal from 'react-native-modal';
import Map from './Map';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default function MapModal({
  isVisible,
  onLocationSelect,
  index,
}) {
  return (
    <Modal isVisible={isVisible} style={styles.modal}>
      <View style={styles.modalContent} dataSet={{ media: ids.modalContent }}>
        <Map index={index} handleLocationSelect={onLocationSelect} />
      </View>
    </Modal>
  );
}

const { ids, styles } = StyleSheet.create({
  modal: {
    margin: 0,
  },
  modalContent: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    height: '100%',
    backgroundColor: 'white',
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
    '@media (min-width: 768px)': {
      top: '50%',
      left: '50%',
      bottom: 'auto',
      right: 'auto',
      height: '90%',
      width: getScreenWidth() / 1.6,
      transform: 'translate(-50%, -50%)',
      borderRadius: 16,
    },
  },
});
