import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PaymentsForCartItems from './PaymentsForCartItems';
import {
  createNewOrder,
  createNewOrderV2,
  getCardDetails,
  getSavedUpiFromServer,
  saveCardsInRedux,
  saveLocalNotificationData,
  setCartNotification,
  setUpgradeToPrepaid,
} from '../../actions/ActionTypes';
import { checkPaytmBalance } from '../../actions/PaytmActions';
import { setCurrentPaymentFlow } from '../../actions/PaymentActions';
import { availableApps } from '../../actions/LoginActions';
import { setCongratsModalForEdge } from '../../actions/FoxyEdgeAction';
import { getStoreRef } from '../../store/StoreUtility';
import { isPresent } from '../../utils/BooleanUtility';

class Payments extends Component {
  constructor(props) {
    super(props);
  }

  getCartItemsForPartialCart = () => {
    const { route: { params: { cartItems = [] } = {} } = {} } = this.props;
    const partialCartDetails = getStoreRef().getState()?.UserAccountInfo?.partialCartDetails;
    const { items = [] } = partialCartDetails || {};
    return isPresent(items) ? items : cartItems;
  };

  getCartPricingForPartialCart = () => {
    const { route: { params: { cartPricing = {} } = {} } = {} } = this.props;
    const partialCartDetails = getStoreRef().getState()?.UserAccountInfo?.partialCartDetails;
    const { pricing = {} } = partialCartDetails || {};
    return isPresent(pricing) ? pricing : cartPricing;
  };

  render() {
    const {
      route: {
        params: {
          showPaymentsForPartialCart = false,
          skuId = '',
          slug = '',
          previousScreen = '',
          checkPlasticFreePackaging = false,
          extra: { retry_payment_order_id = '' } = {},
        } = {},
      } = {},
    } = this.props;

    const cartItemsForPartialCart = this.getCartItemsForPartialCart();
    const cartPricingForPartialCart = this.getCartPricingForPartialCart();
    if (showPaymentsForPartialCart) {
      return (
        <PaymentsForCartItems
          {...this.props}
          cartItems={cartItemsForPartialCart}
          cartPricing={cartPricingForPartialCart}
          showPaymentsForPartialCart={showPaymentsForPartialCart}
          skuId={skuId}
          slug={slug}
          previousScreen={previousScreen}
        />
      );
    }
    return (
      <PaymentsForCartItems
        {...this.props}
        showPaymentsForPartialCart={false}
        retry_payment_order_id={retry_payment_order_id}
        previousScreen={previousScreen}
        checkPlasticFreePackaging={checkPlasticFreePackaging}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartPricing: state.bag.cartPricing,
  address: state.UserAccountInfo.addresses,
  authToken: state.UserAccountInfo.authToken,
  isCod: state.bag.cod,
  isNewPaymentJourney: state.bag.isNewPaymentJourney,
  UtoP: state.bag.UtoP,
  isContactLess: state.bag.isContactLess,
  optForPlasticFreePackaging: state.UserAccountInfo.optForPlasticFreePackaging,
  addedToCartProduct: state.bag.addedToCartProduct,
  membership_cohort: state.todayDeals.membership_cohort,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      createNewOrder,
      checkPaytmBalance,
      getCardDetails,
      setUpgradeToPrepaid,
      setCurrentPaymentFlow,
      createNewOrderV2,
      saveCardsInRedux,
      getSavedUpiFromServer,
      availableApps,
      setCongratsModalForEdge,
      saveLocalNotificationData,
      setCartNotification,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payments);
