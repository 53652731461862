import React from 'react';
import { View, StyleSheet } from 'react-native';
// import ShimmerPlaceHolder from 'react-native-shimmer-placeholder';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';

const shimmerColor = ['#ffffff1A', '#ffffff33', '#ffffff1A'];

const PlaceHolder = () => {
  return (
    <View style={styles.placeHolderContainer}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.image}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.title}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.subTitle}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.buttonHolder}
        colorShimmer={shimmerColor}
      />
    </View>
  );
};

const SmallCardPlaceHolder = () => {
  return (
    <View style={styles.smallCardPlaceHolderContainer}>
      <View style={styles.smallCardPlaceHolder}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.smallCardImage}
          colorShimmer={shimmerColor}
        />
        <View>
          <ShimmerPlaceHolder
            autoRun
            style={styles.title}
            colorShimmer={shimmerColor}
          />
          <ShimmerPlaceHolder
            autoRun
            style={styles.smallCardSubtitle}
            colorShimmer={shimmerColor}
          />
        </View>
      </View>
      <ShimmerPlaceHolder
        autoRun
        style={styles.buttonHolder}
        colorShimmer={shimmerColor}
      />
    </View>
  );
};

const DashboardPlaceHolder = () => {
  return (
    <View style={styles.container}>
      <SmallCardPlaceHolder />
      <SmallCardPlaceHolder />
      <PlaceHolder />
    </View>
  );
};

export default DashboardPlaceHolder;

const styles = StyleSheet.create({
  container: {
    width: getScreenWidth(),
    // justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    // height: getScreenHeight(),
  },
  smallCardPlaceHolderContainer: {
    width: getScreenWidth() - 24,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 12,
    backgroundColor: colors.white,
    borderRadius: 20,
    marginTop: 18,
  },
  profilePlaceHolderContainer: {
    width: getScreenWidth(),
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 12,
  },
  smallCardPlaceHolder: {
    width: getScreenWidth(),
    alignItems: 'center',
    paddingHorizontal: 12,
    flexDirection: 'row',
  },
  profileImageContainer: {
    width: 120,
    height: 120,
    borderRadius: 75,
    marginVertical: 12,
  },
  title: {
    height: 12,
    width: getScreenWidth() / 4,
    borderRadius: 10,
    marginTop: 12,
    marginLeft: 4,
    backgroundColor: '#EAEAEA',
  },
  smallCardSubtitle: {
    height: 16,
    width: getScreenWidth() / 3,
    borderRadius: 10,
    marginTop: 12,
    marginLeft: 4,
    backgroundColor: '#EAEAEA',
  },
  subTitle: {
    height: 16,
    width: getScreenWidth() / 2,
    borderRadius: 10,
    marginTop: 12,
    marginLeft: 4,
    backgroundColor: '#EAEAEA',
  },
  placeHolderHeader: {
    height: 12,
    width: getScreenWidth() / 2,
    borderRadius: 10,
    marginTop: 8,
  },
  placeHolderContainer: {
    width: getScreenWidth() - 20,
    marginTop: 20,
    height: 240,
    padding: 10,
    backgroundColor: colors.white,
    borderRadius: 20,
  },
  placeHolder: {
    height: 40,
    width: getScreenWidth() - 40,
    borderRadius: 6,
    marginTop: 12,
  },

  buttonHolder: {
    height: 40,
    borderRadius: 6,
    width: getScreenWidth() - 50,
    marginTop: 12,
    alignSelf: 'center',
    backgroundColor: '#EAEAEA',
  },
  image: {
    height: 100,
    borderRadius: 6,
    width: getScreenWidth() / 2,
    marginTop: 12,
    alignSelf: 'center',
    backgroundColor: '#EAEAEA',
  },
  smallCardImage: {
    height: 70,
    borderRadius: 12,
    width: getScreenWidth() / 3,
    marginTop: 12,
    alignSelf: 'center',
    backgroundColor: '#EAEAEA',
    paddingHorizontal: 12,
  },
});
