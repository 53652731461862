import React from 'react';
import { Platform } from 'react-native';
//FIXME: Test this change
import { v7 as uuidv7, v5 as uuidv5 } from 'uuid';
import Utility from './Utility';
import ImagePicker from '../libraries/ImagePicker';

export default class UploadVideoManager {
  static createVideoFile = (
    videoUrl,
    mediaType = 'video',
    vidoDataCallback = () => {}
  ) => {
    const postId = uuidv7();
    const videoTitle = '';
    const postData = {
      id: postId,
      localId: postId,
      category: 'video',
      postCacheDirectoryPath: '',
      uploadType: 'FoxyVideo',
      items: [
        {
          mimeType: 'video/mp4',
          uuid: uuidv7(videoUrl, uuidv5.URL),
          uri: videoUrl,
          mediaUriAbsolutePath: Utility.isAndroid()
            ? `file://${videoUrl}`
            : videoUrl,
          mediaUriExternalDirectoryPath: '',
          mediaBase64Uri: '',
          thumbnail: '',
          height: Utility.getScreenHeight(),
          width: Utility.getScreenWidth(),
          videoLength: 0,
          mediaType,
          caption: videoTitle,
          recentProductSearchID: '',
          products: [],
          videoFrames: [],
          productsTag: [],
        },
      ],
      postTitle: videoTitle,
      postDescription: '',
      postThumbnail: '',
      postCategory: mediaType,
      hashTags: [],
      mentions: [],
      editingMode: false,
    };
    vidoDataCallback(postData);
  };

  static openImagePicker = (
    mediaType = 'video',
    successCallback = () => {},
    failureCallback = () => {}
  ) => {
    const options = {
      title: 'Select Media',
      mediaType,
      allowsEditing: true,
      storageOptions: {
        skipBackup: true,
        path: `${mediaType}s`,
      },
    };
    ImagePicker.launchCamera(options, (response) => {
      if (response.error) {
        failureCallback(response);
      }
      if (!response.didCancel) {
        successCallback(response.path || response.uri);
      } else {
        failureCallback(response);
      }
    });
  };
}
