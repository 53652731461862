import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import _ from 'lodash';
import FastImageView from '../../components/FastImageView';
import { withMaybe } from '../../lib/Monads';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import HTML from 'react-native-render-html';
import LinearGradient from 'react-native-linear-gradient';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../utils/NavigationUtility';

class CartItemsComboHeader extends PureComponent {
  constructor(props) {
    super(props);
    const { item: { offer_id = '' } = {} } = props;
    this.comboColor = Utility.usedComboColors[offer_id] ?? '#2397F1';
    this.nameStyle = [styles.name, { color: this.comboColor }];
    this.imageTintColor = this.comboColor.concat('70');
    this.dotStyle = [styles.dot, { backgroundColor: this.comboColor }];

    this.x = { x: 0, y: 0 };
    this.y = { x: 0, y: 1 };
  }

  onCTAPress = () => {
    const {
      item: { url = '', coupon_code: couponCode = '', status = '' },
      navigation,
    } = this.props;
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      ({ route = '', slug = '', extra = {} }) => {
        navigateToScreen({
          navigation,
          type: 'push',
          screen: route,
          params: { slug, extra },
        });
      },
    );

    const ctaText = status === 'redeemed' ? 'Edit' : 'Add';
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.ACTION]: ctaText,
      [EventParameterKey.SCREEN_NAME]: 'cart',
      [EventParameterKey.COUPON_CODE]: couponCode,
      [EventParameterKey.ENTITY]: 'combo_offer',
    });
  };

  discountConditionFn = (props) =>
    parseFloat(props.sp) === parseFloat(props.mrp);

  conditionalPriceText = withMaybe(this.discountConditionFn)(Text);

  showDiscountRateConditionFn = (props) =>
    props.discount === null || props.discount === '';

  conditionalDiscountText = withMaybe(this.showDiscountRateConditionFn)(Text);

  render() {
    const {
      item: {
        coupon_code: couponCode = '',
        status = '',
        combo_offer_details: comboOfferDetail,
        combo_offer_details: {
          quantity_required: quantities = {},
          max_items: maxItems = 0,
          combo_offer_heading = '',
          combo_offer_subheading = '',
        } = {},
      } = {},
    } = this.props;

    if (Utility.isBlank(comboOfferDetail)) {
      return null;
    }

    const ctaText = status === 'redeemed' ? 'Edit' : 'Add';
    const ctaIcon =
      status === 'redeemed' ? images.edit_white : images.plus_white;

    const gradientArray = [
      Utility.addAlpha(this.comboColor, 0.15),
      Utility.addAlpha(this.comboColor, 0.25),
    ];

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.onCTAPress}
        style={styles.container}
      >
        <LinearGradient
          colors={gradientArray}
          style={styles.gradientContainer}
          start={this.x}
          end={this.y}
        />
        <View style={styles.contentContainer}>
          <FastImageView
            style={styles.image}
            tintColor={this.imageTintColor}
            source={images.inverted_percent_ribbon}
          />
          <View style={styles.textContainer}>
            <View style={styles.headingContainer}>
              <Text style={this.nameStyle}>{couponCode}</Text>
              <View style={this.dotStyle} />
              <Text style={this.nameStyle}>{combo_offer_heading}</Text>
            </View>
            <View style={styles.priceContainer}>
              <View style={styles.markdown}>
                <HTML html={combo_offer_subheading} />
              </View>
            </View>
          </View>
          <View style={styles.ctaContainer}>
            <DebouncedTouchableOpacity
              {...this.props}
              style={styles.touchableOpacity}
              onPress={this.onCTAPress}
            >
              <FastImageView
                style={styles.ctaImage}
                tintColor={colors.white}
                source={ctaIcon}
              />
              <Text style={styles.ctaText}>{ctaText}</Text>
            </DebouncedTouchableOpacity>
          </View>
        </View>
      </DebouncedTouchableOpacity>
    );
  }
}

export default withNavigation(CartItemsComboHeader);

const styles = StyleSheet.create({
  container: {
    width: Utility.getScreenWidth(),
    height: 64,
  },
  contentContainer: {
    height: '100%',
    width: '100%',
    flexDirection: 'row',
  },
  gradientContainer: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
  },
  image: {
    marginTop: 0,
    marginLeft: 8,
    height: 48,
    width: 31,
  },
  textContainer: {
    flexDirection: 'column',
    marginLeft: 8,
    marginTop: 8,
  },
  name: {
    fontFamily: 'Roboto-Bold',
    fontWeight: '700',
    fontSize: size.h3,
  },
  priceContainer: {
    bottom: 4,
    flexDirection: 'row',
    alignItems: 'center',
  },
  markdown: {
    bottom: 4,
    width: Utility.getScreenWidth() - 136,
  },
  headingContainer: {
    flexDirection: 'row',
  },
  dot: {
    marginLeft: 8,
    marginRight: 8,
    height: 4,
    width: 4,
    borderRadius: 2,
    alignSelf: 'center',
  },
  ctaContainer: {
    // width: 68,
    height: 28,
    borderRadius: 16,
    position: 'absolute',
    right: 12,
    alignSelf: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: colors.foxyBlack,
  },
  touchableOpacity: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  ctaImage: {
    height: 14,
    width: 14,
    marginLeft: 12,
  },
  ctaText: {
    marginRight: 12,
    marginLeft: 6,
    fontFamily: 'Roboto-Bold',
    fontWeight: '500',
    color: colors.white,
    fontSize: size.h3,
  },
});
