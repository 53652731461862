import { takeLatest, put, select } from 'redux-saga/effects';
import _ from 'lodash';
import {
  addLastLike,
  addTodayDealsToRedux,
  APPLY_FLASH_DEAL,
  LIKE,
  UNLIKE,
} from '../actions/ActionTypes';
import { FOLLOW_BRAND, UNFOLLOW_BRAND } from '../actions/ActionTypes';
import { URL } from '../config/Constants';
import { logoutUser } from '../actions/LoginActions';
import { getApiHeaders } from './GeneratorUtil';
import { addMyBrowsing } from '../actions/UserInteractionsActions';
import Utility from '../utils/Utility';
import { AnalyticsManager, EventParameterKey, EventType } from '../analytics';
import { convertJsonFromResponse } from '../sagas/GeneratorUtil';

function* likeAsyc(action) {
  const {
    data: { id, type = '', sku_id, shopify_type },
    callback,
  } = action;

  let myBrowsing = yield select((state) => state.userInteractions.myBrowsing);
  if (Utility.isBlank(myBrowsing)) {
    myBrowsing = [];
  }

  let currentIndex = _.findIndex(myBrowsing, (myBrowsingItem) => {
    return (
      myBrowsingItem.id === id &&
      myBrowsingItem?.type?.toLowerCase() === type?.toLowerCase()
    );
  });

  if (currentIndex === -1) {
    currentIndex = myBrowsing.length;
  }

  const url = `${URL.LIKE}?item_index=${currentIndex}`;

  const postData = {
    like: {
      item_type: shopify_type || _.startCase(_.capitalize(type)).replace(' ', ''),
      item_id: id,
    },
  };
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      console.tron.log('error in like');
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(addTodayDealsToRedux(json));
      yield put(addLastLike());
      callback(json?.skus);
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `FavouriteSaga.js L-65 ${error}`,
    });
  }
}

function* applyFlashDealAsync(action) {
  const {
    data: { id = '', type = '', sku_id = '' },
  } = action;

  let myBrowsing = yield select((state) => state.userInteractions.myBrowsing);
  if (Utility.isBlank(myBrowsing)) {
    myBrowsing = [];
  }

  let currentIndex = _.findIndex(myBrowsing, (myBrowsingItem) => {
    return (
      myBrowsingItem.id === id &&
      myBrowsingItem?.type?.toLowerCase() === type?.toLowerCase()
    );
  });

  if (currentIndex === -1) {
    currentIndex = myBrowsing.length;
  }

  const postData = {
    flash_deals: [
      {
        item_type: _.startCase(_.capitalize(type)).replace(' ', ''),
        item_id: id,
      },
    ],
  };

  const url = `${URL.APPLY_FLASH_DEAL}?item_index=${currentIndex}`;

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      yield put(addTodayDealsToRedux(json));
      if (Utility.isPresent(action?.data)) {
        yield put(addMyBrowsing(action?.data));
      }
    }
  } catch (error) {
    AnalyticsManager.logEvent(EventType.appLifeCycleEvents.EXCEPTION_CAPTURED, {
      [EventParameterKey.SOURCE]: `FavouriteSaga.js L-120 ${error}`,
    });
  }
}

function* unLikeAsyc(action) {
  const {
    data: { id, type, shopify_type },
  } = action;
  const url = URL.UNLIKE;

  const postData = {
    like: {
      item_type: shopify_type || _.startCase(_.capitalize(type)).replace(' ', ''),
      item_id: id,
    },
  };
  try {
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      console.tron.log('error in like');
    } else {
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* followBrandAsync(action) {
  const {
    data: { id, type = '' },
  } = action;
  const url = URL.FOLLOW_BRAND.replace('id', id);
  console.tron.log('FOLLOW_BRAND_URL', url);

  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      console.tron.log('error in following brand');
    } else {
      console.tron.log('status code', statusCode);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

export default function* watchFetchFavourite() {
  yield takeLatest(LIKE, likeAsyc);
  yield takeLatest(UNLIKE, unLikeAsyc);
  yield takeLatest(FOLLOW_BRAND, followBrandAsync);
  yield takeLatest(APPLY_FLASH_DEAL, applyFlashDealAsync);
}
