import { TouchableOpacity, Text, Image, View } from 'react-native';
import React, { useRef, useState, useEffect } from 'react';
import DocumentPicker, {
  pickSingle,
} from '../../libraries/ReactNativeDocumentPicker';
import StyleSheet from 'react-native-media-query';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import { isBlank, isDesktop, isPresent, isWeb } from '../../utils/BooleanUtility';
import { getStoreRef } from '../../store/StoreUtility';
import CameraUtils from '../../utils/CameraUtils';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { AnalyticsManager, EventType } from '../../analytics';

export default function FileUpload({
  setFile,
  label,
  name,
  accept,
  sizeInMb = 10,
  handleCancel,
  docType,
  capture,
  fileType = 'all',
  screenName = '',
  certificate: { certificateUrl: docUrl = '' } = {},
}) {
  const filename = docUrl?.split('/')?.pop();
  // Split the filename by the dot to get the parts
  const splitFileName = filename?.split('.');
  const docUrlName = isPresent(splitFileName) ? filename : '';
  const docUrlType = isPresent(splitFileName) ? splitFileName[1] : '';
  const [error, setError] = useState('');
  const fileInputRef = useRef(null);
  const [permissionStatus, setPermissionStatus] = useState();
  useEffect(() => {
    const store = getStoreRef().getState();
    setPermissionStatus(store?.permissionStatus);
  }, [permissionStatus]);

  const convertImageToBase64AndUpload = (file) => {
    const { size, name, type, uri } = file;
    if (size > sizeInMb * 1000000) {
      setError(`Size cannot exceed ${sizeInMb} MB`);
      return;
    }
    setError('');
    const documentType = getMediaType(type);
    CameraUtils.convertImageToBase64(uri)
      .then((response) => {
        const postData = {
          data: `data:${type};base64,${response}`,
          name,
          type: documentType,
        };
        setFile(postData);
      })
      .catch((error) => {});
  };

  function getMediaType(type) {
    let documentType = '';
    if (type.includes('image')) {
      documentType = 'image';
    } else if (type.includes('video')) {
      documentType = 'video';
    } else if (type.includes('pdf')) {
      documentType = 'pdf';
    }
    return documentType;
  }

  const handleUploadClick = () => {
    if (isWeb()) {
      if (name && handleCancel) return;
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
      return;
    }
    pickDocument();
  };

  const pickDocument = async () => {
    try {
      let types;

      switch (fileType) {
        case 'video':
          types = [DocumentPicker.types.video];
          break;
        case 'image':
          types = [DocumentPicker.types.images];
          break;
        default:
          types = [DocumentPicker.types.allFiles];
      }

      const results = await DocumentPicker.pickSingle({
        type: types, // Use the determined types here
      });

      convertImageToBase64AndUpload(results); // Your existing function to handle the file
    } catch (err) {
      if (DocumentPicker.isCancel(err)) {
        // User cancelled the picker, handle if necessary
      } else {
        AnalyticsManager.logEvent(EventType.errorEvents.FILE_UPLOAD_ERROR, {
          screenName,
          fileType,
        });
      }
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const size = file.size || file.fileSize;
    const type = file.type;
    if (size > sizeInMb * 1000000) {
      setError(`Size cannot exceed ${sizeInMb} MB`);
      return;
    }
    setError('');

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result;
        const documentType = getMediaType(type);
        setFile({ data: base64String, name: file.name, type: documentType });
      };
      reader.readAsDataURL(file);
    }
  };

  const documentType = isBlank(docType) ? docUrlType : docType;
  const docName = isBlank(name) ? docUrlName : name;
  const imageUri = docTypeToIcon[documentType] || images.video_icon;
  return (
    <View>
      <TouchableOpacity style={styles.fileUpload} onPress={handleUploadClick}>
        <FastImageView
          source={getMinifiedImage(imageUri, styles.image.width)}
          style={[styles.image, !docName && { tintColor: colors.subtitle }]}
        />
        <Text style={styles.fileUploadLabel}>{docName || label}</Text>
      </TouchableOpacity>
      <Text style={styles.error}>{error}</Text>
      {!!docName && handleCancel && !docUrl && (
        <TouchableOpacity style={styles.crossStyle} onPress={handleCancel}>
          <Image source={images.cross} style={{ tintColor: colors.black }} />
        </TouchableOpacity>
      )}
      {isWeb() && (
        <input
          ref={fileInputRef}
          type='file'
          accept={accept}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          capture={capture}
        />
      )}
    </View>
  );
}

const { ids, styles } = StyleSheet.create({
  fileUpload: {
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    backgroundColor: '#f3f6f8',
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 12,
  },
  fileUploadLabel: {
    fontFamily: 'Roboto-Regular',
    marginLeft: 10,
    color: colors.subtitle,
  },
  image: {
    width: 20,
    height: 20,
  },
  error: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    lineHeight: 16,
    paddingTop: 2,
    paddingBottom: 2,
    textAlign: 'left',
    opacity: 1,
    color: 'rgb(213, 0, 0)',
  },
  crossStyle: {
    position: 'absolute',
    top: 11,
    right: 0,
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const docTypeToIcon = {
  image: images.imageIcon,
  video: images.videoFileIcon,
  pdf: images.pdfIcon,
  jpeg: images.imageIcon,
};
