import { Platform } from 'react-native';
import { select } from 'redux-saga/effects';
import Utility from '../utils/Utility';
import { getGuestToken, setGuestToken } from '../utils/TokenUtility';
import { isNative, isWeb } from '../utils/BooleanUtility';
import { EnableShopifyDirect } from '../config/Constants';

export function* convertJsonFromResponse(response) {
  try {
    const jsonResponse = yield response.json();
    return jsonResponse;
  } catch (e) {
    return false;
  }
}

export function* getApiHeaders() {
  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = yield select((state) => state.UserAccountInfo);
  if (!getGuestToken() || getGuestToken() !== guestAuthToken) {
    setGuestToken(guestAuthToken);
  }
  let headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  if (isNative()) {
    headers = {...headers, ...{
      'x-build-version': Utility.getAppVersion(),
      'x-app-platform': Platform.OS,
    }}
  }

  if (EnableShopifyDirect) {
    headers['x-build-type'] = 'shopify_direct';
  }

  if (Utility.isPresent(authToken)) {
    headers['x-auth-token'] = authToken;
  }

  if (Utility.isPresent(guestAuthToken)) {
    headers['x-guest-token'] = guestAuthToken;
  }

  if (isWeb()) {
    headers.Platform = 'web';
  }

  return headers;
}

export function* hasPublicId() {
  const publicId = yield select((state) => state.UserAccountInfo.publicId);
  return Utility.isPresent(publicId);
}

export function* getAddressTypeForId(addressId) {
  const allAddresses = yield select(
    (state) => state.UserAccountInfo.addresses,
  ) || [];
  const shopifyAddresses = yield select(
    (state) => state.UserAccountInfo?.shopifyAddresses,
  ) || [];

  return (
    allAddresses[addressId]?.address_type ??
    shopifyAddresses[addressId]?.address_type ??
    'EkanekAddress'
  );
}

export function* getDynamicListParams({ value = '', param = ' ' }) {
  if (!value) {
    return '';
  }
  const [...ids] = yield select((state) => state.UserAccountInfo[value]);

  if (ids?.length === 0 || Utility.isBlank(param)) return '';

  if (value === 'recentlyViewedProductIds') {
    const currentProductId = yield select(
      (state) => state.UserAccountInfo.lastVisitedProductId,
    );
    const index = ids.indexOf(currentProductId);
    if (index > -1) {
      ids.splice(index, 1);
    }
  }
  return ids.reduce((acc, id) => `${acc}&${param}[]=${id}`, '');
}
