import {
  put,
  takeLatest,
  select,
  takeEvery,
  call,
  delay,
} from 'redux-saga/effects';
import {
  FETCH_PRODUCT,
  FETCH_PRODUCT_FOR_INVITE_CENTER,
  UPDATE_PRODUCT_DATA,
  UPDATE_PRODUCT_DATA_BY_SLUG,
  RATE_PRODUCT,
  FETCH_PRODUCT_BY_SLUG,
  DELETE_MEDIA,
  REQUEST_PRODUCT,
  GET_PRODUCT_RECOMMENDATION,
  GET_RECOMMENDED_VARIANTS,
  GET_CURRENT_PINCODE,
  SAVE_CURRENT_PINCODE,
  GET_DELIVERY_TIME,
  GET_VARIANTS_FOR_PRODUCT,
  FETCH_PRODUCT_CAMERA,
  GET_PRODUCT_SERVICEABILITY,
  SIMILAR_PRODUCTS,
  SIMILAR_PRODUCTS_LIST_RECEIVED,
  FETCH_PRODUCT_REVIEWS,
  SEND_SKU_PRICE_ALERT,
} from '../actions/ActionTypes';
import { GET_PRODUCT_PERSONALIZED_ELEMENTS } from '../actions/ProductPageElementsActions';
import { logoutUser } from '../actions/LoginActions';
import { API_DOMAIN, PRODUCT_DETAIL_URL, URL } from '../config/Constants';
import Utility from '../utils/Utility';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import { isPresent } from '../utils/BooleanUtility';

export function* fetchProductAsync(action) {
  const { payload } = action;
  if (Utility.isBlank(payload)) {
    return;
  }
  const slug = action.payload.replace('/api/v1', '/api/v2');
  const { callback } = action;
  const url = `${PRODUCT_DETAIL_URL}${slug}?show_special_offer=true`;
  try {
    for (let i = 0; i < 5; i += 1) {
      const response = yield fetch(url, {
        method: 'GET',
        headers: yield getApiHeaders(),
      });
      if (response.status >= 200 && response.status < 300) {
        if (
          slug.includes('/api/v1/product_pictures') &&
          response.status === 202
        ) {
          if (i === 4) {
            callback(false, {});
          }
        } else {
          const json = yield convertJsonFromResponse(response);
          if (Utility.isBlank(json)) {
            callback(false);
            return;
          }
          callback(true, json, response.status);
          break;
        }
      } else {
        callback(false, {}, response.status);
        break;
      }
      yield delay(1500);
    }
    // yield put({ type: UPDATE_PRODUCT_DATA, data: json });
    // yield put({ type: UPDATE_PRODUCT_DATA_BY_SLUG, data: json });
  } catch (error) {
    console.tron.log('error in fetch product', error);
    callback(false, {});
  }
}

export function* fetchProductReviewsAsync(action) {
  const slug = action.payload;
  const { callback } = action;
  const url = `${URL.PRODUCT}${slug}/all_reviews`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in fetch product', error);
    callback(false, {});
  }
}

export function* fetchProductCameraAsync(action) {
  const slug = action.payload;
  const url = `${PRODUCT_DETAIL_URL}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isBlank(json)) {
      return;
    }
    if (Utility.isPresent(json.variant_attributes)) {
      if (json.variant_attributes[0]?.allowed_values?.length !== 0) {
        action.callback1();
      } else {
        action.callback2();
      }
    } else {
      action.callback2();
    }
    yield put({ type: UPDATE_PRODUCT_DATA, data: json });
    yield put({ type: UPDATE_PRODUCT_DATA_BY_SLUG, data: json });
  } catch (error) {
    console.tron.log('error in fetch product', error);
  }
}

function* rateProductAsync(action) {
  const {
    data: { url: slug, rating, id, order_id, artistHandle },
    callback = () => {},
  } = action;
  let url = `${URL.RATE}`;
  if (isPresent(artistHandle)) {
    url = `${URL.RATE}/create_profile_rating`;
  }
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({
        auth_token: authToken,
        rating,
        sku_id: id,
        order_id,
        handle: artistHandle,
      }),
    });

    const statusCode = response.status;
    console.tron.log('status code in rating', statusCode);
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        callback(true, json);
      }
    } else {
      console.tron.log('error in rating');
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* fetchProductBySlug(action) {
  const { slug, callback } = action;
  let sendCallbackData = true;

  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback({ errorType: 'api_failure', error: 'Network error. Please try again!' });
  }, 7000);
  try {
    const url = `${URL.PRODUCT_V2}${slug}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json) && sendCallbackData) {
      callback(json);
    }
  } catch (err) {
    console.tron.log('Erropr', err);
  } finally {
    clearTimeout(timer);
  }
}

function* deleteMedia(action) {
  const { postId, callback } = action;
  try {
    const url = `${URL.DELETE_MEDIA}${postId}`;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'DELETE',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      callback('Success');
    } else {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        return;
      }
      callback(json.errors[0]);
    }
  } catch (error) {}
}

function* requestProductAsync(action) {
  const { skuId, callback } = action;
  const url = `${URL.REQUEST_SKU}${skuId}/ask`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    const statusCode = response.status;
    console.tron.log('status code in request product', statusCode);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* sendSkuPriceAlertAsync(action) {
  const { id, callback } = action;
  const url = `${URL.PRICE_ALERT}`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  const data = {
    price_alert: {
      sku_id: id,
    },
  };
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(data),
    });
    const statusCode = response.status;
    console.tron.log('status code in request product', statusCode);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} url is ${url}`);
  }
}

function* getRecommendedVariantsAsync(action) {
  const { id, callback } = action;
  try {
    const url = `${URL.PRODUCT}${id}/recommended_variants`;
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, []);
        return;
      }
      callback(true, json.recommended_variants);
    } else {
      callback(false, []);
    }
  } catch (error) {
    console.tron.log('error in get recommendation is ', error);
  }
}

function* getCurrentPincodeAsync(action) {
  const { callback } = action;
  try {
    const url = URL.PIN_CODE;
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      yield put({ type: SAVE_CURRENT_PINCODE, data: json });
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in get pincode', error);
    callback(false, {});
  }
}

function* getDeliveryTimeAsync(action) {
  const { pincode, skuId, callback } = action;
  try {
    const url = `${URL.DELIVERY_TIME}${pincode}?sku_id=${skuId}`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in get pincode', error);
    callback(false, {});
  }
}

function* getVariantForproductAsync(action) {
  const { slug, callback, offer_id } = action;
  try {
    let url = `${API_DOMAIN}${slug}/variants?offer_id=${offer_id}`;
    url = url.replace(/\/v\d+\//, '/v2/');
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in get variant', error);
  }
}

function* similarProductAsync(action) {
  const { id, callback } = action;
  try {
    const url = `${URL.GET_SIMILAR_PRODUCT}${id}/similar_products`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in get similar products', error);
    callback(false, {});
  }
}

function* getProductServiceabilityAsync(action) {
  const { pincode, callback } = action;
  try {
    const url = `${URL.PRODUCT_SERVICEABILITY}${pincode}/serviceability`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    console.tron.log('error in get serviceability', error);
    callback(false, {});
  }
}

function* getProductPersonalizedElementsAsync(action) {
  const { slug, callback } = action;
  try {
    const url = `${API_DOMAIN}${slug}/personalized_details`;
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
  }
}

// Our watcher Saga:
export default function* watchFetchProduct() {
  yield takeLatest(FETCH_PRODUCT, fetchProductAsync);
  yield takeLatest(FETCH_PRODUCT_REVIEWS, fetchProductReviewsAsync);
  yield takeLatest(RATE_PRODUCT, rateProductAsync);
  yield takeLatest(SIMILAR_PRODUCTS, similarProductAsync);
  yield takeLatest(FETCH_PRODUCT_BY_SLUG, fetchProductBySlug);
  yield takeLatest(DELETE_MEDIA, deleteMedia);
  yield takeLatest(REQUEST_PRODUCT, requestProductAsync);
  yield takeLatest(GET_RECOMMENDED_VARIANTS, getRecommendedVariantsAsync);
  yield takeLatest(GET_CURRENT_PINCODE, getCurrentPincodeAsync);
  yield takeLatest(GET_DELIVERY_TIME, getDeliveryTimeAsync);
  yield takeLatest(GET_VARIANTS_FOR_PRODUCT, getVariantForproductAsync);
  yield takeLatest(FETCH_PRODUCT_CAMERA, fetchProductCameraAsync);
  yield takeLatest(GET_PRODUCT_SERVICEABILITY, getProductServiceabilityAsync);
  yield takeLatest(
    GET_PRODUCT_PERSONALIZED_ELEMENTS,
    getProductPersonalizedElementsAsync,
  );

  yield takeLatest(SEND_SKU_PRICE_ALERT, sendSkuPriceAlertAsync);
}
