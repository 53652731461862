import React, { Component } from 'react';
import { View, StyleSheet, Text, FlatList } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import { FlatListPerformanceView } from '../../../libraries/ReactNativePerformanceShopify';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import {
  getOfferPrompt,
  addToCart,
  applyCartCoupon,
  removeOffer,
  getCartPricing,
  renderOfferShimmers,
  fetchData,
} from '../../../actions/ActionTypes';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import CouponPrompt from '../../prompts/CouponPrompt';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../../analytics';
import OfferShimmer from '../../shared/OfferShimmer';
import FullWidthShimmer from '../../../lib/FullWidthShimmer';
import { getPersonalisedLists } from '../../../actions/PersonalisedActions';
import FoxyAlert from '../../camera/shared/FoxyAlert';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import { TouchableOpacity } from 'react-native-gesture-handler';
import withNavigation from '../../../utils/WithNavigation';
import ErrorBoundaryComponent from '../../shared/ErrorBoundaryComponent';
import { isDesktop, isBlank, isWeb } from '../../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../../config/ScreenConstants';
import { getListSource, getNavigationSource } from '../../../utils/PerfUtility';
import withProfiledNavigation from '../../../utils/withProfiledNavigation';

class CouponRail extends Component {
  static getComponentHeight(item) {
    return 241.2;
  }

  constructor(props) {
    super(props);
    const { navigation, route = {}, horizontal } = this.props;

    this.horizontal = route.params?.horizontal ?? horizontal ?? true; //horizontal || navigation.getParam('horizontal');

    this.enablePagination = route.params?.enablePagination ?? false;
    this.page = 0;

    this.state = {
      loading: false,
      errors: false,
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
      horizontal: this.horizontal,
    };

    this.componentVisible = false;
    this.minEspOfferId = '';
    this.overlappingOfferIds = [];
    this.order = [];
    this.chooseGiftIdentifier = 'choose_free_gift';
    this.promptStyle = !this.horizontal
      ? styles.promptFullWidth
      : styles.prompt;
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToClubbableModal = () => {
    const { navigation } = this.props;
    const { notClubbableCouponCode, notClubbableOfferId, notClubbableWith } =
      this.state;
    navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
      notClubbableCouponCode,
      navigation,
      notClubbableOfferId,
      notClubbableWith,
      applyOffer: this.forceApplyOffer,
    });
  };

  offerNotClubbable = (
    notClubbableCouponCode,
    notClubbableOfferId,
    notClubbableWith,
  ) => {
    this.setState(
      {
        notClubbableCouponCode,
        notClubbableOfferId,
        notClubbableWith,
      },
      this.navigateToClubbableModal,
    );
  };

  navigateToUnfulfilledModal = () => {
    const { navigation } = this.props;
    const { unfulfilledCoupon } = this.state;
    if (isBlank(unfulfilledCoupon)) return;
    navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
      navigation,
      itemData: unfulfilledCoupon,
      navigateToOfferDetail: this.onViewProductsTap,
    });
  };

  setUnfulfilledCoupon = (data) => {
    this.setState(
      {
        unfulfilledCoupon: data,
      },
      this.navigateToUnfulfilledModal,
    );
  };

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  navigateToOfferDetail = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }

    if (url.includes('choose_free_gift')) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      if (url === 'https://www.foxy.in/') {
        navigation.navigate('Feed');
      }

      DynamicLinkManager.handleDynamicLink(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          navigation.push(route, { slug, extra });
        },
      );
    }
  };

  forceApplyOffer = (code) => {
    const { applyCartCoupon, hitApis } = this.props;
    renderOfferShimmers(true);
    applyCartCoupon(
      code,
      (success, callbackData) => {
        const appliedCoupon = callbackData?.prompts?.find(
          (coupon) => coupon.coupon_code === code,
        );
        if (success) {
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        }

        this.flatListRef?.scrollToOffset({ animated: true, y: 0 });
      },
      false,
      true,
    );
  };

  applyOffer = (code, id) => {
    const { applyCartCoupon, renderOfferShimmers, showToast } = this.props;

    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
    }, 5000);

    applyCartCoupon(
      code,
      (success, callbackData) => {
        renderOfferShimmers(false);

        if (Utility.isBlank(callbackData)) {
          this.setState({
            errors: true,
          });
          return;
        }
        if (success) {
          this.setSuccessfullyAppliedCoupon(callbackData);
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.MODAL_NAME]:
              EventParameterValue.MODAL_NAME.SUCCESS_OFFER,
            [EventParameterKey.offer_id]: callbackData?.offer_id,
          });
        } else if (callbackData.clubbable === false) {
          const offerPrompts = [];
          _.forEach(callbackData.offers_prompts, (e) => {
            offerPrompts.push(e.offer_id);
          });

          this.offerNotClubbable(
            callbackData.coupon_code,
            callbackData.offer_id,
            callbackData.offers_prompts,
          );
        } else {
          this.setState({
            errorMessage: `${callbackData.errors[0] || ''} `,
          });
        }
      },
      false,
      false,
    );
  };

  changeCouponState = (coupon, applied, promptData = {}) => {
    const {
      applyCartCoupon,
      showToast,
      removeOffer,
      skuId,

      getCartPricing,
      renderOfferShimmers = () => {},
      navigation,
    } = this.props;

    renderOfferShimmers(true);

    setTimeout(() => {
      renderOfferShimmers(false);
    }, 5000);

    if (!applied) {
      applyCartCoupon(coupon, (response, json) => {
        renderOfferShimmers(false);
        if (Utility.isBlank(json)) {
          this.setState({
            errors: true,
          });
          return;
        }
        if (isWeb() && json.app_only) {
          navigation.navigate('ApplyOnAppOfferModal', {
            couponCode: coupon,
            message: promptData.short_description,
            offerId: promptData.offer_id,
          });
          return;
        }
        if (response) {
          const appliedCoupon = json?.prompts?.find(
            (couponCode) => couponCode.coupon_code === coupon,
          );

          this.setSuccessfullyAppliedCoupon(appliedCoupon);
          AnalyticsManager.logEvent('offer_applied', {
            source: 'product_detail',
            coupon_code: coupon,
            offer_id: promptData.offer_id,
            offer_type: 'offer_coupon',
            offer_applied_by: 'user_applied',
          });
          AnalyticsManager.logFirebaseEvent(
            EventType.googleRemarketingEvents.SELECT_PROMOTION,
            {
              promotion_name: coupon,
              promotion_id: promptData.offer_id,
              items: [{ id: skuId }],
            },
          );
          this.flatListRef?.scrollToOffset({ animated: true, y: 0 });
        } else if (!!json.condition_unfullfilled) {
          this.setUnfulfilledCoupon(json);
          this.setState({
            errors: true,
          });
          AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
            [EventParameterKey.modal_name]:
              EventParameterValue.MODAL_NAME.UNFULLFILLED_OFFER,
            [EventParameterKey.offer_id]: json?.offer_id,
          });
        } else {
          this.setState({
            errors: true,
          });
          if (json.clubbable === false) {
            const offerPrompts = [];
            _.forEach(json.offers_prompts, (e) => {
              offerPrompts.push(e.offer_id);
            });
            AnalyticsManager.logEvent('modal_view', {
              modal_name: 'replace_offer',
              new_offer_id: json.offer_id,
              old_offer_ids: offerPrompts.toString(),
            });
            this.offerNotClubbable(
              json.coupon_code,
              json.offer_id,
              json.offers_prompts,
            );
            return;
          }
          if (Utility.isPresent(json.errors)) {
            this.setState({
              errorMessage: `${json.errors[0]}`,
            });
          }
        }
        getCartPricing();
      });
    }
    if (applied) {
      removeOffer(coupon, (response, json) => {
        if (response) {
          AnalyticsManager.logEvent('offer_remove', {
            source: 'product_detail',
            coupon_code: coupon,
            offer_id: promptData.offer_id,
            offer_type: 'offer_coupon',
            offer_applied_by: 'user_applied',
          });
        } else if (Utility.isPresent(json.errors)) {
          showToast(json.errors[0]);
        }
        getCartPricing();
      });
    }
  };

  setError = () => {
    this.setState({
      errors: false,
    });
  };

  getComponent = ({ item = [], index }) => {
    const {
      showChooseGiftScreen,
      isPromptLoadingShimmer,
      selectedVariantSku,
      showToast,
      previousScreen = '',
      item: promptData = [],
      listData = {},
    } = this.props;

    if (promptData?.length === 1) {
      this.promptStyle = styles.promptFullWidth;
    }

    const { valid_on_skus = [] } = item;

    const { errors } = this.state;

    return (
      <ErrorBoundaryComponent
        itemData={item}
        listData={listData}
        screenName={previousScreen}
      >
        <CouponPrompt
          promptData={item}
          changeCouponState={this.changeCouponState}
          removeCoupon={this.changeCouponState}
          navigateToOfferDetail={this.navigateToOfferDetail}
          showChooseGiftScreen={showChooseGiftScreen}
          loading={isPromptLoadingShimmer}
          style={this.promptStyle}
          errors={errors}
          setError={this.setError}
          previousScreen={previousScreen}
          isValidOnSku={
            Utility.isPresent(selectedVariantSku) &&
            Utility.isPresent(valid_on_skus)
              ? valid_on_skus.includes(selectedVariantSku)
              : true
          }
          componentWidth={this.promptStyle.width}
          showToast={showToast}
        />
      </ErrorBoundaryComponent>
    );
  };

  handleRouteFromLink = ({ route = '', slug = '', path = '', extra = {} }) => {
    const { navigation } = this.props;
    navigation.push(route, { slug, extra });
  };

  onViewProductsTap = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }
    if (url.includes(this.chooseGiftIdentifier)) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      DynamicLinkManager.handleDynamicLink(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          navigation.push(this.navigationSource, route, { slug, extra });
        },
      );
    }
  };

  navigateToOfferDetail = (item) => {
    const { url = '' } = item;
    const { navigation, previousScreen } = this.props;

    if (Utility.isBlank(item)) {
      return;
    }

    AnalyticsManager.logEvent('prompt_action_click', {
      offer_id: item.offer_id,
      coupon_code: item.coupon_code,
      prompt_location: previousScreen,
      cta_text: Utility.isPresent(item.cta) ? item.cta : 'VIEW PRODUCTS',
      prompt_status: item.status,
    });

    if (Utility.isPresent(url)) {
      if (url === 'https://www.foxy.in/') {
        navigation.navigate('Feed');
      }

      const promptData = {
        type: 'list',
        previousScreen: 'product_Detail',
        id: item.offer_id,
      };

      DynamicLinkManager.handleDynamicLink(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          navigation.push(this.navigationSource, route, {
            slug,
            extra,
            promptData,
          });
        },
      );
    }
  };

  onScrollBeginDrag = () => {
    AnalyticsManager.logEvent('offer_scroll', {
      type: 'personalised',
    });
  };

  onFirstButtonPressForErrorMessage = () => {
    this.setState({
      errorMessage: '',
    });
  };

  closeSuccessOfferAppliedModal = () => {
    this.setState({
      successfullyAppliedCoupon: '',
    });
  };

  onFirstButtonPressForSuccessfulCoupon = () => {
    const { successfullyAppliedCoupon } = this.state;
    this.setState({
      successfullyAppliedCoupon: '',
    });
    this.onViewProductsTap(
      successfullyAppliedCoupon?.cta_url,
      successfullyAppliedCoupon?.offer_id,
    );
  };

  onPressEmptyStateButton = () => {
    const { navigation } = this.props;
    navigation.navigate('Feed');
  };

  emptyState = () => {
    if (this.horizontal) return null;
    const { halfHeightEmptyState } = this.props;
    const height = !halfHeightEmptyState
      ? Utility.getScreenHeight() - 100
      : 200;
    return (
      <View style={[styles.emptyStateContainer, { height }]}>
        <FastImageView source={images.discountCoupon} height={96} width={96} />
        <Text style={styles.emptyStateTitle}>No active offers found</Text>

        <TouchableOpacity
          style={styles.emptyStateActionButton}
          onPress={this.onPressEmptyStateButton}
        >
          <Text style={styles.emptyStateButtonText}>{'Start Shopping'}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  listNameContainer = () => {
    const { listData } = this.props;
    let headerName = listData?.name;
    let subtitle = listData?.subtitle;

    if (!this.horizontal) {
      headerName = 'Your personalised offers 💯';
      subtitle = ' Limited time offers available only to you';
    }

    if (Utility.isBlank(headerName)) return null;
    return (
      <View style={styles.listNameContainer}>
        <Text style={styles.title} ellipsizeMode='tail' numberOfLines={1}>
          {headerName || ''}
        </Text>
        <Text
          style={styles.subtitle}
          allowFontScaling={false}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {subtitle || ''}
        </Text>
      </View>
    );
  };

  flatListRef = (ref) => {
    this.flatListRef = ref;
  };

  keyExtractor = (item, index) => `${index}_grid`;

  render() {
    const {
      loading,
      errorMessage,
      successfullyAppliedCoupon,
      unfulfilledCoupon,
      horizontal,
    } = this.state;
    const {
      isOfferShimmerVisible,
      listData: { background_color = '', prompts: promptData = [] } = {},
      navigation,
      item,
    } = this.props;

    const offersData = Utility.isPresent(promptData) ? promptData : item;

    if (Utility.isBlank(offersData)) {
      if (loading) {
        return <OfferShimmer horizontal={horizontal} />;
      }
      if (!this.horizontal) {
        return <this.emptyState />;
      }
      return null;
    }

    return (
      <View>
        {/* <this.listNameContainer /> */}
        <FlatListPerformanceView listName={`${getListSource(this, this.props)}`}>
          <FlatList
            ref={this.flatListRef}
            data={offersData}
            extraData={{ ...this.props, ...this.state }}
            scrollEnabled
            onScrollBeginDrag={this.onScrollBeginDrag}
            horizontal={horizontal}
            showsHorizontalScrollIndicator={false}
            keyExtractor={this.keyExtractor}
            renderItem={this.getComponent}
            contentContainerStyle={[
              styles.contentContainerStyle,
              { backgroundColor: background_color },
            ]}
          />
        </FlatListPerformanceView>
        {isOfferShimmerVisible && <FullWidthShimmer style={styles.shimmer} />}

        <FoxyAlert
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
          alertMessage={successfullyAppliedCoupon?.message}
          firstButtonTitle={
            Utility.isPresent(successfullyAppliedCoupon?.cta_text)
              ? successfullyAppliedCoupon?.cta_text
              : 'YAY !'
          }
          firstButtonOnPress={this.onFirstButtonPressForSuccessfulCoupon}
          onTapOutside={this.removeSuccessfullyAppliedCoupon}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showJson
        />

        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle='Oops'
          alertMessage={errorMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.onFirstButtonPressForErrorMessage}
          onTapOutside={this.onFirstButtonPressForErrorMessage}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
          isError
          previousScreen={"coupon_rail"}
        />
      </View>
    );
  }
}

const dividingFactor = isDesktop() ? 2 : 1;
const styles = StyleSheet.create({
  title: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    marginHorizontal: 12,
    marginTop: 24,
    marginBottom: 4,
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    marginBottom: 12,
    marginLeft: 12,
  },
  shimmer: {
    width: Utility.getScreenWidth(),
    flex: 1,
    height: '99%',
    position: 'absolute',
    alignSelf: 'center',
    alignItems: 'center',
    marginTop: 8,
    paddingBottom: 6,
  },

  emptyStateContainer: {
    height: Utility.getScreenHeight() - 100,
    justifyContent: 'center',
    alignItems: 'center',
  },

  emptyStateTitle: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h3,
    color: colors.black,
    marginTop: 15,
  },

  emptyStateActionButton: {
    height: 28,
    width: 180,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: colors.black,
    borderRadius: 16,
    marginTop: 20,
  },

  emptyStateButtonText: {
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    fontSize: size.h4,
  },
  prompt: {
    width: (Utility.getScreenWidth() / dividingFactor - 50) * 0.95,
    height: 130,
    marginLeft: 8,
    marginRight: 12,
    elevation: 5,
    marginTop: 8,
  },
  promptFullWidth: {
    width: Utility.getScreenWidth() / dividingFactor - 36,
    height: 130,
    marginLeft: 12,
    elevation: 5,
    marginTop: 8,
  },
  contentContainerStyle: {
    paddingHorizontal: 6,
    backgroundColor: colors.white,
  },
  listNameContainer: {
    backgroundColor: colors.white,
  },
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getOfferPrompt,
      addToCart,
      applyCartCoupon,
      removeOffer,
      getCartPricing,
      renderOfferShimmers,
      getPersonalisedLists,
      fetchData,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  isOfferShimmerVisible: state.bag.isOfferShimmerVisible,
  authToken: state.UserAccountInfo.authToken,
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(CouponRail),
);
