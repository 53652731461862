import React, { Component } from 'react';
import { ImageBackground } from 'react-native';
import PropTypes from 'prop-types';
import styles from './styles';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import Utility from '../../utils/Utility';
import withNavigation from '../../utils/WithNavigation';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class OfferCard extends Component {
  constructor(props) {
    super(props);
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigation.navigate(this.navigationSource, route, {
      destination: slug, // webview reads data from destination
      slug,
      extra,
    });
  };

  onActionPressed = () => {
    const {
      navigation,
      itemData,
      previousScreen,
      listIndex = 0,
      itemData: { destination, dynamic_destination } = {},
      allVideosId = '',
    } = this.props;
    const url = destination;
    // TODO: Navigate to Offer Details

    // if (Utility.isBlank(dynamic_destination)) {
    //   DynamicLinkManager.handleDynamicLink(
    //     url,
    //     this.navigateToScreen,
    //     destination,
    //   );
    // } else {
    //   navigation.navigate('PaginatedList', {
    //     id: allVideosId,
    //     dynamicDestinationSlug: dynamic_destination,
    //   });
    // }
  };

  render() {
    const { itemData } = this.props;
    if (itemData === undefined) {
      return null;
    }
    const newImageUrl = getMinifiedImage(
      itemData.image_url,
      Utility.getScreenWidth(),
      Utility.getScreenWidth(),
    );
    return (
      <ScaleAnimate {...this.props} onPress={this.onActionPressed}>
        <ImageBackground
          resizeMode='stretch'
          source={{ uri: newImageUrl }}
          style={styles.bannerImage}
        />
      </ScaleAnimate>
    );
  }
}

OfferCard.propTypes = {
  itemData: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.string,
    image_url: PropTypes.string,
  }),
};

export default withProfiledNavigation(OfferCard);
