import React from 'react';
import { Image } from 'react-native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { NavigationContainer, DefaultTheme } from '@react-navigation/native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { Payoff } from '../containers/payoff';
import Reviews from '../containers/Reviews/Reviews';
import AllReviews from '../containers/productRatings/AllReviews';
import PostReview from '../containers/productRatings/PostReview';
import PaymentsWaiting from '../components/payment/paymentStatus/PaymentsWaiting';
import MediaPlaylist from '../containers/media/MediaPlaylist';
import Cart, { CartStackNavigationOptions } from '../containers/cart/Cart';
import CheckoutReminder from '../containers/cart/CheckoutReminder';
import CartOffers from '../containers/cart/Offers';
import RecycleWithFoxyWebView from '../components/recycleWithFoxy/RecycleWithFoxyWebView';
import ChooseFreeGiftScreen from '../components/cart/ChooseFreeGiftScreen';
import FreeProductScreen from '../components/cart/FreeProductScreen';
import OrderStatus from '../components/cart/OrderStatus';
import Payments from '../containers/payment/Payments';
import GooglePayProcessing from '../components/cart/PaymentProcessing';
import PaytmVerify from '../components/cart/PaytmVerification';
import PaytmWebView from '../components/cart/PaytmWebView';
import SavedCardScreen from '../components/cart/SavedCard';
import StoryDetails from '../components/StoryDetails/StoryDetails';
import RecommendationPrompt from '../components/StoryDetails/RecommendationPrompt';
import FollowRecommendations from '../components/StoryDetails/FollowRecommendations';
import OrdersWebView from '../components/orders/OrdersWebView';
import ConsumerProfile, { ProfileNavigationOptions } from '../containers/consumerProfile/ConsumerProfile';
import AboutUs from '../containers/consumerProfile/AboutUs';
import AccountSettings from '../containers/consumerProfile/AccountSettings';
import ScreenNavigator from './ScreenNavigator';
import SelfieIntro from '../components/onboarding/SelfieIntro';
import { ScanPlaceholder } from '../containers/camera/addProducts';
import { OfferDetail } from '../components/offer';
import PayoffTabIcon from '../components/shared/PayoffTabIcon';
import FullPageProductDescription from '../components/Product/FullPageProductDescription';
import { MoreItemsListHeaderOptions } from '../containers/feed/MoreItemsList';
import TodayDeals from '../containers/todayDeals/TodayDeals';
import SwipableCards from '../containers/todayDeals/SwipableCards';
import { StoreNavigationOptions, StoreHeaderOptions } from '../containers/store/Store';
import {
  SelfiePrompt,
  ImageReview,
  LoginInfo,
  ProfileDetails,
  OTPVerification,
} from '../components/onboarding';
import MyProfile from '../containers/myProfile/MyProfile';
import { ProfileEdit, MyUploads } from '../containers/myProfile';
import PostDetail from '../components/profile/PostDetail';
import { Category, CategoryDetail } from '../containers/category';
import WebUrlView from '../components/shared/WebUrlView';
import Address from '../containers/cart/Address';
import NotificationHistory from '../components/feature/NotificationHistory';
import { Feed, PaginatedList } from '../containers/feed';
import { FeedNavigationOptions } from '../containers/feed/Feed';
import { MyCollection, ContentPageCarousel, Brand } from '../containers';
import Store from '../containers/store/Store';
import Search from '../containers/search/Search';
import Tag from '../containers/tag/Tag';
import {
  AddProducts,
  UploadVideo,
  ScanProduct,
} from '../containers/camera/index';
import colors from '../theme/Colors';
import images from '../theme/Images';
import { navigationRef } from './NavigationService';
import { SearchHeaderOptions } from '../containers/search/Search';
import {
  TagHeaderOptions,
  TagHeaderOptionsWithNavigationHeader,
} from '../containers/tag/Tag';
import { PayoffHeaderOptions } from '../containers/payoff/Payoff';
import Onboarding from '../containers/onboarding/Onboarding';
import PersonalisedOffers from '../components/layout/personalisedOffers/PersonalisedOffers';
import MediaComponentWithoutControls from '../components/media/MediaComponentWithoutControls';
import Influencer from '../containers/artist/Influencer';
import OrdersHistory from '../components/orders/OrdersHistory';
import OrderDetails from '../components/orders/OrderDetails';
import UploadUnboxingVideo from '../containers/media/UploadUnboxingVideo';
import CallApi from '../components/shared/CallApi';
import FaqSection from '../containers/faqSection/FaqSection';
import FaqQuestionSection from '../containers/faqSection/FaqQuestionSection';
import FaqAnswerSection from '../containers/faqSection/FaqAnswerSection';
import Routines, { RoutinesNavigationOptions } from '../components/Routines/Routines';
import PreRoutineDetails from '../containers/Routines/PreRoutineDetails';
import DuringRoutineDetails from '../containers/Routines/DuringRoutineDetails';
import RoutineGroups from '../containers/Routines/RoutineGroups';
import RoutineCheckList from '../components/Routines/RoutineCheckList';
import RoutineLivestream from '../containers/Routines/RoutineLivestream';
import CancelOrderReasonPage from '../containers/faqSection/contactUs/CancelOrderReasonPage';
import CancelOrder from '../containers/faqSection/cancelOrder/CancelOrder';
import CustomerOrderStatus from '../containers/customerSupport/CustomerOrderStatus';
import ConfirmRequestPage from '../containers/faqSection/ConfirmRequestPage';
import RequestCallBackPage from '../containers/faqSection/faqRequestCallBack/RequestCallBackPage';
import ContactUsReasonPage from '../containers/faqSection/contactUs/ContactUsReasonPage';
import ReturnReplaceReasonPage from '../containers/faqSection/contactUs/ReturnReplaceReasonPage';
import PersonalisedPage from '../containers/feed/PersonalisedPage';
import Config from '../libraries/ReactNativeConfig';
import AppConfig from '../config/AppConfig';
import ListTabPage from '../containers/listTab/ListTabPage';
import OfferCapModal from '../containers/cart/OfferCapModal';
import ProductDetailModal from '../containers/product/ProductDetailModal';
import SizeChart from '../components/Product/SizeChart';
import ShopifyOrderHistory from '../components/shopifyOrders/ShopifyOrderHistory';
import ShopifyOrderDetails from '../components/shopifyOrders/ShopifyOrderDetails';
import { DESKTOP_HIDDEN_TABS, ORDER_PAGE_TYPE } from '../config/Constants';
import ContactUsModal from '../components/modals/ContactUsModal';
import PopUpAlert from '../components/modals/PopUpAlert';
import { AddEmailModal, FullScreenVariant } from '../components/Product';
import DebouncedTouchableOpacity from '../components/shared/DebouncedTouchableOpacity';
import ScaleAnimate from '../components/shared/ScaleAnimate';
import { VariantModal } from '../components/Product';
import NavigationStacksUtility from '../utils/NavigationStacksUtility';
import SortingFilteringModal from '../components/sort-filter-options/SortingFilteringModal';
import AlertModal from '../components/camera/shared/AlertModal';
import MoreBanks from '../components/cart/MoreBanks';
import UnavailableProductsModal from '../components/Product/UnavailableProductsModal';
import linking from './LinkingConfig';
import { isDesktop } from '../utils/BooleanUtility';
import CustomTabHeader from '../containers/customTopBar/CustomTabHeader';
import CategoryBrowsing, {
  CategoryHeaderOptions,
  CategoryNavigationOptions,
} from '../containers/categoryBrowsing/CategoryBrowsing';
import GenericOffer from '../components/offer/GenericOffer';
import MultiLevelTabs from '../containers/tabs/MultiLevelTabs';
import ProductDetail from '../components/Product/ProductDetail';
import CertificateModal from '../components/Product/CertificateModal';
import { isPresent } from '../utils/BooleanUtility';
import ForYou, { ForYouOptions } from '../containers/forYou/ForYou';
import Brands, {
  BrandsStackNavigationOptions,
  brandsHeaderOption,
} from '../containers/brand/Brands';
import ReplacementProductModal from '../components/Product/ReplacementProductModal';
import TermsOfUse from '../containers/policies/TermsOfUse';
import PrivacyPolicy from '../containers/policies/PrivacyPolicy';
import ShippingPolicy from '../containers/policies/ShippingPolicy';
import TheEdge from '../containers/FoxyEdge/TheEdge';
import Ingredients from '../containers/Ingredients';
import ArtistLists from '../containers/artist/ArtistLists';
import AppInstallPrompt from '../components/shared/AppInstallPrompt';
import PrivacyConcerns from '../containers/policies/PrivacyConcerns';
import ProductLists from '../components/Product/ProductLists';
import ApplyOnAppOfferModal from '../components/shared/ApplyOnAppOfferModal';
import MyCartModal from '../components/modals/MyCartModal';
import NotificationModal from '../components/modals/NotificationModal';
import JoinProModal from '../containers/FoxyEdge/JoinProModal';
import ClubbableModal from '../components/Product/ClubbableModal';
import UnfulfilledOfferModal from '../components/Product/UnfulfilledOfferModal';
import DateSelectionModal from '../components/modals/DateSelectionModal';
import HandleSelection from '../components/pro/HandleSelection';
import FullScreenProductDescription from '../components/Product/FullScreenProductDescription';
import { getAllTabsRoutes } from '../utils/NavigationUtility';
import ShopOnlineList from '../containers/List/ShopOnlineList';
import ShopOnlineBrand from '../components/brand/ShopOnlineBrand';
import ShopOnlineCategory from '../containers/category/ShopOnlineCategory';
import ShopOnlineBrandCategory from '../components/brand/ShopOnlineBrandCategory';
import SelectCallBackTimeSlot from '../containers/faqSection/faqRequestCallBack/SelectCallBackTimeSlot';
import TheEdgeTabIcon from '../components/shared/TheEdgeTabIcon';
import SocialProfile from '../components/pro/SocialProfile';
import AddLinks from '../components/pro/AddLinks';
import AddLinksModal from '../components/pro/AddLinkModal';
import AddIntroVideo from '../components/pro/AddIntroVideo';
import RegistrationCompleted from '../components/pro/RegistrationCompleted';
import VerificationModal from '../components/pro/VerificationModal';
import Dashboard from '../components/pro/Dashboard';
import OneLinkPage from '../components/pro/OneLinkPage';
import { AnalyticsManager, EventType } from '../analytics';
import ListPage from '../containers/List/ListPage';
import SubscribeRoutineModal from '../components/modals/SubscribeRoutineModal';
import AboutYou from '../components/pro/AboutYou';
import ConsultationDetails from '../components/pro/ConsultationDetails';
import OnboardCustomers from '../components/pro/OnboardCustomers';
import SaveProgress from '../components/pro/SaveProgress';
import NonMemberLandingPage from '../containers/FoxyEdge/NonMemberLandingPage';
import FoxyBottomModal from '../components/shared/FoxyBottomModal';
import AwaitingPaymentOrchestrator from '../components/payment/AwaitingPaymentOrchestrator';
import LoyaltyPlans from '../containers/FoxyEdge/LoyaltyPlans';
import AddedToWishlistDeals from '../components/Product/AddedToWishlistDeals';
import BecomeMemberModal from '../containers/FoxyEdge/BecomeMemberModal';
import FullScreenImageModal from '../utils/FullScreenImageModal';
import RequestInviteModal from '../components/modals/RequestInviteModal';
import MyWallet from '../containers/offer/MyWallet';
import CancellationReasonsModal from '../components/modals/CancellationReasonsModal';
import AddDeliveryInstructionsModal from '../components/modals/AddDeliveryInstructionsModal';

const OrderHistoryComponent =
  ORDER_PAGE_TYPE === 'shopify' ? ShopifyOrderHistory : OrdersHistory;

const navigationOptions = {
  headerStyle: {
    backgroundColor: colors.background,
  },
  headerTintColor: colors.foxyBlack,
  tabBarStyle: { borderTopWidth: 0 },
  headerTitleStyle: {
    fontFamily: 'Roboto-Bold',
  },
  mode: 'card',
};

let PayoffStackNavigationOptions = isDesktop()
  ? {
      headerShown: false,
      tabBarLabel: 'For You',
    }
  : {
      navigationOptions,
      tabBarLabel: Config.PAY_OFF_TAB_LABEL || (() => null),
      tabBarActiveTintColor: isPresent(Config.PAY_OFF_TAB_ACTIVE_COLOR)
        ? Config.PAY_OFF_TAB_ACTIVE_COLOR
        : () => null,
      tabBarInactiveTintColor: '#979BAA',
      tabBarStyle: { borderTopWidth: 0, elevation: 0 },
      tabBarOptions: {
        // showIcon: true,
        // showLabel: true,
        style: { borderTopWidth: 0 },
        labelStyle: {
          fontSize: 11,
          fontFamily: 'Roboto-Regular',
          // marginBottom: 4,
        },
      },
      tabBarOnPress: ({ navigation, defaultHandler }) => {
        defaultHandler();

        const { state: { routes = [] } = {} } = navigation;
        console.tron.log('IsFocused', navigation.isFocused());
        if (!navigation.isFocused()) return;

        if (isPresent(routes)) {
          const route = routes[0];
          const { params: { scrollToTop = () => {} } = {} } = route;
          scrollToTop();
        }
      },
      tabBarIcon: ({ focused }) => {
        return <PayoffTabIcon focused={focused} />;
      },
    };

const ReviewStackNavigationOptions = {
  // navigationOptions,
  tabBarLabel: Config.REVIEWS_TAB_LABEL || 'Reviews',
  tabBarActiveTintColor:
    Config.REVIEWS_TAB_TEXT_ACTIVE_COLOR ||
    Config.REVIEWS_TAB_ACTIVE_COLOR ||
    '#EC564F',
  tabBarInactiveTintColor: '#979BAA',
  tabBarStyle: {
    borderTopWidth: 0,
    elevation: 0,
  },
  tabBarOptions: {
    showIcon: true,
    showLabel: true,
    labelStyle: {
      fontSize: 11,
      fontFamily: 'Roboto-Regular',
      // marginBottom: 4,
    },
    iconStyle: {
      flexGrow: 0,
      // marginTop: 4,
    },
  },
  tabBarIcon: ({ focused }) => {
    let image = focused
      ? images.tab.review_selected
      : images.tab.review_unselected;
    if (isPresent(Config.REVIEWS_TAB_ICON_URL) && !focused) {
      image = { uri: Config.REVIEWS_TAB_ICON_URL };
    }
    if (isPresent(Config.REVIEWS_TAB_ACTIVE_ICON_URL) && focused) {
      image = { uri: Config.REVIEWS_TAB_ACTIVE_ICON_URL };
    }
    const activeColor = Config.REVIEWS_TAB_ACTIVE_COLOR ?? '#EC564F';
    const tintColor = focused ? activeColor : '#979BAA';
    return (
      <Image
        testID='review-tab-icon'
        accessibilityLabel='review-tab-icon'
        source={image}
        style={{
          height: 24,
          width: 24,
          resizeMode: 'contain',
          tintColor,
        }}
      />
    );
  },
};

const updateSelfieUrl = () => {
  if (isDesktop()) return;
  PayoffStackNavigationOptions = {
    navigationOptions,
    tabBarLabel: AppConfig.getBooleanValue(Config.HIDE_TAB_LABEL_PAYOFF)
      ? Config.PAY_OFF_TAB_LABEL
      : () => null,
    tabBarInactiveTintColor: '#979BAA',
    tabBarOptions: {
      // showIcon: true,
      // showLabel: true,
      style: { borderTopWidth: 0 },
      activeTintColor: isPresent(Config.PAY_OFF_TAB_ACTIVE_COLOR)
        ? Config.PAY_OFF_TAB_ACTIVE_COLOR
        : () => null,
      labelStyle: {
        fontSize: 11,
        fontFamily: 'Roboto-Regular',
        // marginBottom: 4,
      },
    },
    tabBarOnPress: ({ navigation, defaultHandler }) => {
      defaultHandler();

      const { state: { routes = [] } = {} } = navigation;
      console.tron.log('IsFocused', navigation.isFocused());
      if (!navigation.isFocused()) return;

      if (isPresent(routes)) {
        const route = routes[0];
        const { params: { scrollToTop = () => {} } = {} } = route;
        scrollToTop();
      }
    },
    tabBarIcon: ({ focused }) => {
      return <PayoffTabIcon focused={focused} />;
    },
  };
};

const DetailStackNavigator = createNativeStackNavigator();
const MyProfileContainerStackNavigator = createNativeStackNavigator();
const WishlistStackNavigator = createNativeStackNavigator();
const ListTabStackNavigator = createNativeStackNavigator();
const TabContainer = isDesktop()
  ? createMaterialTopTabNavigator()
  : createBottomTabNavigator();
const ProfileStackNavigator = createNativeStackNavigator();

const LightTheme = {
  ...DefaultTheme,
  dark: false,
};

const MyProfileStack = () => (
  <MyProfileContainerStackNavigator.Navigator
    options={{
      tabBarLabel: 'Famous',
      tabBarIcon: ({ focused }) => {
        const image = focused
          ? images.tabbar.profile.selected
          : images.tabbar.profile.normal;
        return <Image source={image} />;
      },
    }}
    screenOptions={{ headerShown: false, gestureEnabled: false }}
  >
    <MyProfileContainerStackNavigator.Screen
      name='MyProfile'
      component={MyProfile}
    />
    <MyProfileContainerStackNavigator.Screen
      name='ScanPlaceholder'
      component={ScanPlaceholder}
    />
  </MyProfileContainerStackNavigator.Navigator>
);

const wishlistTabHeaderOptions =
  NavigationStacksUtility.getListTabHeaderOptions({
    title: 'Wishlist Deals',
  });

const getListTabStack =
  ({ title, tabHeaderOptions, listSlug, tabStackNavigator }) =>
  () => {
    return (
      <ListTabStackNavigator.Navigator
        screenOptions={{ gestureEnabled: false }}
      >
        <ListTabStackNavigator.Screen
          name={`${title}_ListTab`}
          options={tabHeaderOptions}
        >
          {() => <ListTabPage listSlug={listSlug} />}
        </ListTabStackNavigator.Screen>
      </ListTabStackNavigator.Navigator>
    );
  };

const HomeTab = () => (
  <TabContainer.Screen name={'Feed'} component={Feed} options={FeedNavigationOptions} />
);

const StoreTab = () => (
  <TabContainer.Screen name='Store' component={Store} options={StoreNavigationOptions} />
);

const PayoffTab = () => (
  <TabContainer.Screen name='Payoff' component={Payoff} options={PayoffStackNavigationOptions} />
);

const EdgeStackNavigationOptions = isDesktop() ? {
  headerShown: false,
  tabBarLabel: 'The Edge',
} : {
  headerShadowVisible: false,
  tabBarLabel: 'e . d . g . e',
  tabBarActiveTintColor: Config.ACTIVE_TAB_TINT || '#202023',
  tabBarInactiveTintColor: '#979BAA',
  tabBarStyle: { borderTopWidth: 0, elevation: 0 },
  tabBarOptions: {
    style: { borderTopWidth: 0 },
    labelStyle: {
      fontSize: 11,
      fontFamily: 'Roboto-Regular',
      // marginBottom: 4,
    },
  },
  tabBarOnPress: ({ navigation, defaultHandler }) => {
    defaultHandler();
    const { state: { routes = [] } = {} } = navigation;
    if (!navigation.isFocused()) return;
    if (isPresent(routes)) {
      const route = routes[0];
      const { params: { scrollToTop = () => {} } = {} } = route;
      scrollToTop();
    }
  },
  tabBarIcon: ({ focused }) => {
    const image = focused ? images.edge_selected : images.edge_unselected;
    const tintColor = focused ? '' : '#989AA0';

    return <TheEdgeTabIcon image={image} tintColor={tintColor} focused={focused} />;
  },
};

const WishlistTab = () => (
  <TabContainer.Screen name='Wishlist' component={TodayDeals} options={wishlistOptions} />
);

const ProfileTab = () => (
  <TabContainer.Screen name='Profile' component={ConsumerProfile} options={ProfileNavigationOptions} />
);

const TheEdgeTab = () => (
  <TabContainer.Screen name='TheEdge' component={TheEdge} options={EdgeStackNavigationOptions} />
);

const CategoryBrowsingTab = () => (
  <TabContainer.Screen name='Categories' component={CategoryBrowsing} options={CategoryNavigationOptions} />
  );

const SearchTab = () => (
  <TabContainer.Screen name='Search' component={Search} options={SearchHeaderOptions} />
  );

const ForYouTab = () => (
  <TabContainer.Screen
    name='ForYou'
    component={ForYou}
    options={ForYouOptions}
  />
);

const BrandsTab = (props) => {
  return (
    <TabContainer.Screen
      name='Brands'
      component={Brands}
      options={BrandsStackNavigationOptions}
    />
  );
};

const ProductsTab = () => (
  <TabContainer.Screen name='Product' component={ProductDetail} />
);

const OfferDetailTab = () => (
  <TabContainer.Screen name='OfferDetail' component={OfferDetail} />
);

const MoreItemsTab = () => (
  <TabContainer.Screen name='MoreItems' component={ListPage} />
);

const CategoryTab = () => (
  <TabContainer.Screen name='Category' component={Category} />
);

const BrandTab = () => <TabContainer.Screen name='Brand' component={Brand} />;

const FullScreenProductDescriptionTab = () => (
  <TabContainer.Screen
    name='FullScreenProductDescription'
    component={FullScreenProductDescription}
  />
);

const IngredientsTab = () => (
  <TabContainer.Screen name='Ingredients' component={Ingredients} />
);

const PostReviewTab = () => (
  <TabContainer.Screen name='PostReview' component={PostReview} />
);

const AllReviewsTab = () => (
  <TabContainer.Screen name='AllReviews' component={AllReviews} />
);

const TodayDealsTab = () => (
  <TabContainer.Screen name='TodayDeals' component={TodayDeals} />
);

const ArtistListsTab = () => (
  <TabContainer.Screen name='ArtistLists' component={ArtistLists} />
);

const ProductListsTab = () => (
  <TabContainer.Screen name='ProductLists' component={ProductLists} />
);

const ShopOnlineListTab = () => (
  <TabContainer.Screen name='ShopOnlineList' component={ShopOnlineList} />
);

const ShopOnlineBrandTab = () => (
  <TabContainer.Screen name='ShopOnlineBrand' component={ShopOnlineBrand} />
);

const ShopOnlineCategoryTab = () => (
  <TabContainer.Screen
    name='ShopOnlineCategory'
    component={ShopOnlineCategory}
  />
);

const ShopOnlineBrandCategoryTab = () => (
  <TabContainer.Screen
    name='ShopOnlineBrandCategory'
    component={ShopOnlineBrandCategory}
  />
);

const PreRoutineDetailsTab = () => (
  <TabContainer.Screen name='PreRoutineDetails' component={PreRoutineDetails} />
);

const DuringRoutineDetailsTab = () => (
  <TabContainer.Screen
    name='DuringRoutineDetails'
    component={DuringRoutineDetails}
  />
);

const RoutineLiveStreamTab = () => (
  <TabContainer.Screen name='RoutineLiveStream' component={RoutineLivestream} />
);

const RoutinesTab = () => (
  <TabContainer.Screen name='Routines' component={Routines} options={RoutinesNavigationOptions} />
);

const listTabStackNavigationOptions =
  NavigationStacksUtility.getListTabStackNavigationOptions({
    label: Config.LIST_TAB_LABEL,
    activeImageTintColor: Config.LIST_TAB_ACTIVE_COLOR,
    inactiveImageTintColor: Config.LIST_TAB_INACTIVE_COLOR,
    activeIconUrl: Config.LIST_TAB_ACTIVE_ICON_URL,
    inactiveIconUrl: Config.LIST_TAB_ICON_URL,
    activeTextColor: Config.LIST_TAB_ACTIVE_TEXT_COLOR,
    inactiveTextColor: Config.LIST_TAB_INACTIVE_TEXT_COLOR,
  });

const listTabHeaderOptions = NavigationStacksUtility.getListTabHeaderOptions({
  title: Config.LIST_TAB_LABEL,
});

const ListTabStack = getListTabStack({
  title: 'ListTab',
  tabHeaderOptions: listTabHeaderOptions,
  listSlug: Config.LIST_TAB_SLUG,
});

const ListTab = () => {
  return (
    <TabContainer.Screen
      name='ListTab'
      component={ListTabStack}
      options={listTabStackNavigationOptions}
    />
  );
};

const listTab2StackNavigationOptions =
  NavigationStacksUtility.getListTabStackNavigationOptions({
    label: Config.LIST_TAB_2_LABEL,
    activeImageTintColor: Config.LIST_TAB_2_ACTIVE_COLOR,
    inactiveImageTintColor: Config.LIST_TAB_2_INACTIVE_COLOR,
    activeIconUrl: Config.LIST_TAB_2_ACTIVE_ICON_URL,
    inactiveIconUrl: Config.LIST_TAB_2_ICON_URL,
    activeTextColor: Config.LIST_TAB_2_ACTIVE_TEXT_COLOR,
    inactiveTextColor: Config.LIST_TAB_2_INACTIVE_TEXT_COLOR,
  });

const listTab2HeaderOptions = NavigationStacksUtility.getListTabHeaderOptions({
  title: Config.LIST_TAB_2_LABEL,
});

const ListTab2Stack = getListTabStack({
  title: 'ListTab2',
  tabHeaderOptions: listTab2HeaderOptions,
  listSlug: Config.LIST_TAB_2_SLUG,
});

const ListTab2 = () => {
  return (
    <TabContainer.Screen
      name='ListTab2'
      component={ListTab2Stack}
      options={listTab2StackNavigationOptions}
    />
  );
};

const CartTab = () => (
  <TabContainer.Screen
    name='Cart'
    component={CartStack}
    options={CartStackNavigationOptions}
  />
);

const ReviewsTab = (props) => {
  const { route: { params = {} } } = props;
  return (
    <TabContainer.Screen         
      name='Reviews'
      component={Reviews}
      initialParams={params} 
      options={ReviewStackNavigationOptions} 
    />
  );
};

const TabStackEntries = {
  Home: HomeTab,
  Store: StoreTab,
  Payoff: PayoffTab,
  Reviews: ReviewsTab,
  Profile: ProfileTab,
  Cart: CartTab,
  Wishlist: WishlistTab,
  ListTab: ListTab,
  ListTab2: ListTab2,
  Categories: CategoryBrowsingTab,
  ForYou: ForYouTab,
  Brands: BrandsTab,
  Product: ProductsTab,
  OfferDetail: OfferDetailTab,
  MoreItems: MoreItemsTab,
  TheEdge: TheEdgeTab,
  Category: CategoryTab,
  Brand: BrandTab,
  FullScreenProductDescription: FullScreenProductDescriptionTab,
  Ingredients: IngredientsTab,
  PostReview: PostReviewTab,
  AllReviews: AllReviewsTab,
  TodayDeals: TodayDealsTab,
  ArtistLists: ArtistListsTab,
  ProductLists: ProductListsTab,
  ShopOnlineList: ShopOnlineListTab,
  ShopOnlineBrand: ShopOnlineBrandTab,
  ShopOnlineCategory: ShopOnlineCategoryTab,
  ShopOnlineBrandCategory: ShopOnlineBrandCategoryTab,
  PreRoutineDetails: PreRoutineDetailsTab,
  DuringRoutineDetails: DuringRoutineDetailsTab,
  RoutineLiveStream: RoutineLiveStreamTab,
  Routines: RoutinesTab,
};

const tabBar = isDesktop()
  ? (props) => <CustomTabHeader {...props} />
  : undefined;
const TABS = getAllTabsRoutes();

const TabStack = (props) => {
  return (
    <TabContainer.Navigator
      options={{ style: { borderTopWidth: 0, elevation: 0 } }}
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
        gestureEnabled: false,
        lazy: true,
      }}
      tabBar={tabBar}
      swipeEnabled={false}
      backBehavior='history'
      screenListeners={({route}) => ({
        tabPress: () => {
          if (isDesktop()) {
            AnalyticsManager.logEvent(EventType.MISC_WEB_EVENTS.HEADER_CLICK, { 'header-name': route.name });
          } else {
            AnalyticsManager.logEvent(EventType.discoveryEvents.TAB_CLICK, { tab_name: route.name });
          }
        },
      })}
    >
      {TABS.map((tabName) => TabStackEntries?.[`${tabName}`]?.(props) || null)}
      {isDesktop() && SearchTab()}
    </TabContainer.Navigator>
  );
};

const splashScreenHandler = (e) => {
  return;
};

const detailStackScreenListener = {
  state: splashScreenHandler,
};

const DetailStack = () => (
  <DetailStackNavigator.Navigator
    screenOptions={{
      headerShown: false,
      animation: 'slide_from_right',
      gestureEnabled: false,
    }}
    screenListeners={detailStackScreenListener}
  >
    <DetailStackNavigator.Screen
      name='ScreenNavigator'
      component={ScreenNavigator}
      options={{ headerShown: false }}
    />
    <DetailStackNavigator.Screen
      name='AppIntro'
      component={SelfiePrompt}
      options={{
        animation: 'none',
      }}
    />
    <DetailStackNavigator.Screen name='SelfieIntro' component={SelfieIntro} />
    <DetailStackNavigator.Screen
      name='TabNavigator'
      component={TabStack}
      options={{
        animation: 'none',
      }}
    />
    <DetailStackNavigator.Screen name='MyProfile' component={MyProfileStack} />
    <DetailStackNavigator.Screen name='Artist' component={Influencer} />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='Product' component={ProductDetail} />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='Brand' component={Brand} />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='Search'
        component={Search}
        options={SearchHeaderOptions}
      />
    )}
    <DetailStackNavigator.Screen
      name={'Tag'}
      component={Tag}
      options={TagHeaderOptionsWithNavigationHeader}
    />
    <DetailStackNavigator.Screen
      name={'CategoryDetail'}
      component={CategoryDetail}
    />
    <DetailStackNavigator.Screen
      name='ContentModal'
      component={ContentPageCarousel}
    />
    {/* <DetailStackNavigator.Screen name='GetBooking' component={GetBooking} /> */}
    <DetailStackNavigator.Screen
      name='ScanPlaceholder'
      component={ScanPlaceholder}
    />
    <DetailStackNavigator.Screen name='AddProducts' component={AddProducts} />
    <DetailStackNavigator.Screen name='PostDetail' component={PostDetail} />
    <DetailStackNavigator.Screen name='UploadVideo' component={UploadVideo} />
    <DetailStackNavigator.Screen
      name='NotificationHistory'
      component={NotificationHistory}
    />
    <DetailStackNavigator.Screen name='MyUploads' component={MyUploads} />
    <DetailStackNavigator.Screen
      name='PaginatedList'
      component={PaginatedList}
    />
    <DetailStackNavigator.Screen name='ProfileEdit' component={ProfileEdit} />
    <DetailStackNavigator.Screen name='Address' component={Address} />
    <DetailStackNavigator.Screen
      name='ConsumerProfile'
      component={ConsumerProfile}
    />
    <DetailStackNavigator.Screen name='ImageReview' component={ImageReview} />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='AllReviews' component={AllReviews} />
    )}
    <DetailStackNavigator.Screen name='StoryDetails' component={StoryDetails} />
    <DetailStackNavigator.Screen
      name='ScanProduct'
      component={ScanProduct}
      options={{
        gesturesEnabled: false,
      }}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='MoreItems'
        component={ListPage}
        options={MoreItemsListHeaderOptions}
      />
    )}
    <DetailStackNavigator.Screen name='OfferDetail' component={OfferDetail} />
    <DetailStackNavigator.Screen
      name='RecommendationPrompt'
      component={RecommendationPrompt}
    />
    <DetailStackNavigator.Screen
      name='FollowRecommendations'
      component={FollowRecommendations}
    />
    <DetailStackNavigator.Screen
      name='OrdersWebView'
      component={OrdersWebView}
      options={{
        gesturesEnabled: false,
      }}
    />
    <DetailStackNavigator.Screen
      name='RecycleWithFoxyWebView'
      component={RecycleWithFoxyWebView}
      options={{
        gesturesEnabled: false,
      }}
    />
    <DetailStackNavigator.Screen
      name='ChooseFreeGiftScreen'
      component={ChooseFreeGiftScreen}
    />
    <DetailStackNavigator.Screen
      name='FreeProductScreen'
      component={FreeProductScreen}
    />
    <DetailStackNavigator.Screen name='Cart' component={Cart} />
    <DetailStackNavigator.Screen
      name='CheckoutReminder'
      component={CheckoutReminder}
    />
    <DetailStackNavigator.Screen
      name='MediaPlaylist'
      component={MediaPlaylist}
    />
    <DetailStackNavigator.Screen name='CartOffers' component={CartOffers} />
    <DetailStackNavigator.Screen name='MyCollection' component={MyCollection} />
    <DetailStackNavigator.Screen
      name='OrderStatus'
      component={OrderStatus}
      options={{
        presentation: 'fullScreenModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen name='Payments' component={Payments} />
    <DetailStackNavigator.Screen
      name='GooglePayProcessing'
      component={GooglePayProcessing}
    />
    <DetailStackNavigator.Screen name='PaytmVerify' component={PaytmVerify} />
    <DetailStackNavigator.Screen
      name='PaytmWebView'
      component={PaytmWebView}
      options={{
        gesturesEnabled: false,
      }}
    />
    <DetailStackNavigator.Screen
      name='SavedCardScreen'
      component={SavedCardScreen}
    />
    <DetailStackNavigator.Screen name='FaqSection' component={FaqSection} />
    <DetailStackNavigator.Screen
      name='FaqQuestionSection'
      component={FaqQuestionSection}
    />
    <DetailStackNavigator.Screen
      name='FaqAnswerSection'
      component={FaqAnswerSection}
    />
    <DetailStackNavigator.Screen
      name='SwipableCards'
      component={SwipableCards}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='PostReview' component={PostReview} />
    )}
    <DetailStackNavigator.Screen
      name='FullPageProductDescription'
      component={FullPageProductDescription}
    />
    <DetailStackNavigator.Screen
      name='PaymentsWaiting'
      component={PaymentsWaiting}
    />
    <DetailStackNavigator.Screen
      name='AccountSettings'
      component={AccountSettings}
    />
    <DetailStackNavigator.Screen name='AboutUs' component={AboutUs} />
    <DetailStackNavigator.Screen
      name='SelectCallBackTimeSlot'
      component={SelectCallBackTimeSlot}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='TodayDeals' component={TodayDeals} />
    )}
    <DetailStackNavigator.Screen name='LoyaltyPlans' component={LoyaltyPlans} />
    {/* todo_vishwender: headerMode in WebUrlView */}
    <DetailStackNavigator.Screen
      name='WebUrlView'
      component={WebUrlView}
      options={{
        gesturesEnabled: false,
      }}
    />
    <DetailStackNavigator.Screen
      name='CallApi'
      component={CallApi}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
        cardStyle: {
          backgroundColor: 'rgba(0,0,0,0.4)',
        },
      }}
    />
    <DetailStackNavigator.Screen
      name='Onboarding'
      component={Onboarding}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />

    <DetailStackNavigator.Screen
      name='LoginInfo'
      component={LoginInfo}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='OTPVerification'
      component={OTPVerification}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />

    <DetailStackNavigator.Screen
      name='ProfileDetails'
      component={ProfileDetails}
    />
    <DetailStackNavigator.Screen
      name='PersonalisedOffers'
      component={PersonalisedOffers}
    />
    <DetailStackNavigator.Screen name='Influencer' component={Influencer} />
    <DetailStackNavigator.Screen
      name='MediaComponentWithoutControls'
      component={MediaComponentWithoutControls}
    />
    <DetailStackNavigator.Screen
      name='OrdersHistory'
      component={OrderHistoryComponent}
    />
    <DetailStackNavigator.Screen name='OrderDetails' component={OrderDetails} />
    <DetailStackNavigator.Screen
      name='UploadUnboxingVideo'
      component={UploadUnboxingVideo}
    />
    <DetailStackNavigator.Screen
      name='RoutineCheckList'
      component={RoutineCheckList}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='PreRoutineDetails'
        component={PreRoutineDetails}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='DuringRoutineDetails'
        component={DuringRoutineDetails}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='RoutineLiveStream'
        component={RoutineLivestream}
      />
    )}
    <DetailStackNavigator.Screen
      name='RoutineGroups'
      component={RoutineGroups}
    />
    <DetailStackNavigator.Screen
      name='OfferCapModal'
      component={OfferCapModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='SortingFilteringModal'
      component={SortingFilteringModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='BecomeMemberModal'
      component={BecomeMemberModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='VariantModal'
      component={VariantModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='DateSelectionModal'
      component={DateSelectionModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />

    <DetailStackNavigator.Screen
      name='JoinProModal'
      component={JoinProModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />

    <DetailStackNavigator.Screen
      name='UnavailableProductsModal'
      component={UnavailableProductsModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='CancelOrderReasonPage'
      component={CancelOrderReasonPage}
    />
    <DetailStackNavigator.Screen name='CancelOrder' component={CancelOrder} />
    <DetailStackNavigator.Screen
      name='CustomerOrderStatus'
      component={CustomerOrderStatus}
    />
    <DetailStackNavigator.Screen
      name='ConfirmRequestPage'
      component={ConfirmRequestPage}
    />
    <DetailStackNavigator.Screen
      name='RequestCallBackPage'
      component={RequestCallBackPage}
    />
    <DetailStackNavigator.Screen
      name='ContactUsReasonPage'
      component={ContactUsReasonPage}
    />
    <DetailStackNavigator.Screen
      name='ReturnReplaceReasonPage'
      component={ReturnReplaceReasonPage}
    />
    <DetailStackNavigator.Screen
      name='PersonalisedPage'
      component={PersonalisedPage}
    />
    <DetailStackNavigator.Screen name='SizeChart' component={SizeChart} />
    <DetailStackNavigator.Screen
      name='ProductDetailModal'
      component={ProductDetailModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='ShopifyOrderDetails'
      component={ShopifyOrderDetails}
    />
    <DetailStackNavigator.Screen name='EdgeInfo' component={NonMemberLandingPage} />
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='FullScreenProductDescription'
        component={FullScreenProductDescription}
      />
    )}
    <DetailStackNavigator.Screen
      name='ContactUsModal'
      component={ContactUsModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='PopUpAlert'
      component={PopUpAlert}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='FullScreenVariant'
      component={FullScreenVariant}
    />
    <DetailStackNavigator.Screen
      name='GenericOffer'
      component={GenericOffer}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='ReplacementProductModal'
      component={ReplacementProductModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='AppInstallPrompt'
      component={AppInstallPrompt}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='ApplyOnAppOfferModal'
      component={ApplyOnAppOfferModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen name='MoreBanks' component={MoreBanks} />

    {/* DebouncedTouchableOpacity and ScaleAnimate are just here so that they can get navigation object from the stack navigator */}
    <DetailStackNavigator.Screen
      name='DebouncedTouchableOpacity'
      component={DebouncedTouchableOpacity}
    />
    <DetailStackNavigator.Screen name='ScaleAnimate' component={ScaleAnimate} />
    <DetailStackNavigator.Screen
      name='AlertModal'
      component={AlertModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='Category' component={Category} />
    )}
    <DetailStackNavigator.Screen name='ReviewsPage' component={Reviews} />
    <DetailStackNavigator.Screen name='TermsOfUse' component={TermsOfUse} />
    <DetailStackNavigator.Screen
      name='PrivacyPolicy'
      component={PrivacyPolicy}
    />
    <DetailStackNavigator.Screen
      name='ShippingPolicy'
      component={ShippingPolicy}
    />
    <DetailStackNavigator.Screen
      name='PrivacyConcerns'
      component={PrivacyConcerns}
    />
    <DetailStackNavigator.Screen
      name='CertificateModal'
      component={CertificateModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='MyCartModal'
      component={MyCartModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='ClubbableModal'
      component={ClubbableModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='MultiLevelTabs'
      component={MultiLevelTabs}
    />
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='Ingredients' component={Ingredients} />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen name='ArtistLists' component={ArtistLists} />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='ProductLists'
        component={ProductLists}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='ShopOnlineList'
        component={ShopOnlineList}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='ShopOnlineBrand'
        component={ShopOnlineBrand}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='ShopOnlineCategory'
        component={ShopOnlineCategory}
      />
    )}
    {!isDesktop() && (
      <DetailStackNavigator.Screen
        name='ShopOnlineBrandCategory'
        component={ShopOnlineBrandCategory}
      />
    )}
    <DetailStackNavigator.Screen
      name='UnfulfilledOfferModal'
      component={UnfulfilledOfferModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='HandleSelection'
      component={HandleSelection}
    />
    <DetailStackNavigator.Screen
      name='AboutYou'
      component={AboutYou}
    />
    <DetailStackNavigator.Screen
      name='SaveProgress'
      component={SaveProgress}
    />
    <DetailStackNavigator.Screen
      name='ConsultationDetails'
      component={ConsultationDetails}
    />
    <DetailStackNavigator.Screen
      name='SocialProfile'
      component={SocialProfile}
    />
    <DetailStackNavigator.Screen
      name='OnboardCustomers'
      component={OnboardCustomers}
    />
    <DetailStackNavigator.Screen name='AddLinks' component={AddLinks} />
    <DetailStackNavigator.Screen
      name='AddLinksModal'
      component={AddLinksModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='AddIntroVideo'
      component={AddIntroVideo}
    />
    <DetailStackNavigator.Screen
      name='RegistrationCompleted'
      component={RegistrationCompleted}
    />
    <DetailStackNavigator.Screen
      name='VerificationModal'
      component={VerificationModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen name='Dashboard' component={Dashboard} />
    <DetailStackNavigator.Screen name='OneLinkPage' component={OneLinkPage} />
    <DetailStackNavigator.Screen
      name='NotificationModal'
      component={NotificationModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='AddedToWishlistDeals'
      component={AddedToWishlistDeals}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
        cardStyle: {
          backgroundColor: 'rgba(0,0,0,0.4)',
        },
      }}
    />
    <DetailStackNavigator.Screen
      name='AddEmailModal'
      component={AddEmailModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />

    <DetailStackNavigator.Screen
      name='SubscribeRoutineModal'
      component={SubscribeRoutineModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='FoxyBottomModal'
      component={FoxyBottomModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='FullScreenImageModal'
      component={FullScreenImageModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='AwaitingPaymentOrchestrator'
      component={AwaitingPaymentOrchestrator}
    />
    <DetailStackNavigator.Screen
      name='RequestInviteModal'
      component={RequestInviteModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen name='MyWallet' component={MyWallet} />
    <DetailStackNavigator.Screen
      name='CancellationReasonsModal'
      component={CancellationReasonsModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
    <DetailStackNavigator.Screen
      name='AddDeliveryInstructionsModal'
      component={AddDeliveryInstructionsModal}
      options={{
        presentation: 'transparentModal',
        animation: 'slide_from_bottom',
      }}
    />
  </DetailStackNavigator.Navigator>
);

const AppStack = () => (
  <NavigationContainer
    ref={navigationRef}
    theme={LightTheme}
    linking={linking}
    documentTitle={{
      formatter: (options, route) => Config.TITLE,
    }}
  >
    {DetailStack()}
  </NavigationContainer>
);

export { AppStack, updateSelfieUrl };
