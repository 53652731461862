import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableWithoutFeedback,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import RoundedButton from '../layout/buttons/RoundedButton';
import CouponcodePill from '../cart/CouponCodePill';
import {
  getOfferPrompt,
  addToCart,
  applyCartCoupon,
  removeOffer,
  getCartPricing,
} from '../../actions/ActionTypes';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import _ from 'lodash';
import { RGBA } from '../../libraries/ReactNativeColorMatrixImageFilters';
import FoxyAlert from '../camera/shared/FoxyAlert';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { isBlank } from '../../utils/BooleanUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class OfferCouponLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      applied: false,
      errors: false,
      notClubbableCouponCode: '',
      notClubbableOfferId: '',
      notClubbableWith: {},
      errorMessage: '',
      unfulfilledCoupon: {},
      successfullyAppliedCoupon: {},
      nonClubbableOfferMeta: {},
    };

    this.navigationSource = getNavigationSource(this, this.props);
  }

  offerNotClubbable = (
    notClubbableCouponCode,
    notClubbableOfferId,
    notClubbableWith,
    nonClubbableOfferMeta,
  ) => {
    this.setState(
      {
        notClubbableCouponCode,
        notClubbableOfferId,
        notClubbableWith,
        nonClubbableOfferMeta,
      },
      this.navigateToClubbableModal,
    );
  };

  navigateToUnfulfilledModal = () => {
    const { navigation } = this.props;
    const { unfulfilledCoupon } = this.state;
    if (isBlank(unfulfilledCoupon)) return;
    navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
      navigation,
      itemData: unfulfilledCoupon,
      navigateToOfferDetail: this.navigateToOfferDetail,
    });
  };

  setUnfulfilledCoupon = (data) => {
    this.setState(
      {
        unfulfilledCoupon: data,
      },
      this.navigateToUnfulfilledModal,
    );
  };

  navigateToClubbableModal = () => {
    const { navigation } = this.props;
    const {
      notClubbableCouponCode,
      notClubbableOfferId,
      notClubbableWith,
      nonClubbableOfferMeta,
    } = this.state;
    navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
      notClubbableCouponCode,
      navigation,
      notClubbableOfferId,
      notClubbableWith,
      applyOffer: this.applyOffer,
      nonClubbableOfferMeta,
    });
  };

  setSuccessfullyAppliedCoupon = (data) => {
    this.setState({
      successfullyAppliedCoupon: data,
    });
  };

  removeSuccessfullyAppliedCoupon = () => {
    this.setState({
      successfullyAppliedCoupon: {},
    });
  };

  navigateToOfferDetail = (url, id) => {
    const { navigation } = this.props;

    if (Utility.isBlank(url)) {
      return;
    }

    if (url.includes('choose_free_gift')) {
      navigation.push('ChooseFreeGiftScreen', {
        id,
      });
      return;
    }

    if (Utility.isPresent(url)) {
      if (url === Config.HTTPS_DOMAIN_NAME) {
        navigation.navigate('Feed');
      }

      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          navigateToScreen({
            navigation,
            type: 'push',
            screen: route,
            params: { slug, extra },
          });
        },
      );
    }
  };

  applyOffer = (code) => {
    const { applyCartCoupon, hitApis } = this.props;
    applyCartCoupon(
      code,
      (success, callbackData) => {
        const appliedCoupon = callbackData?.prompts?.find(
          (coupon) => coupon.coupon_code === code,
        );
        if (success) {
          this.setSuccessfullyAppliedCoupon(appliedCoupon);
        }
      },
      false,
      true,
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { coupon_codes, data: { coupon_code } = {} } = nextProps;
    // do things with nextProps.someProp and prevState.cachedSomeProp
    const isApplied = Utility.isPresent(
      _.find(coupon_codes, (code) => {
        return code?.coupon_code === coupon_code;
      }),
    );
    return {
      applied: isApplied,
      // ... other derived state properties
    };
  }

  applyCouponCode = (code) => {
    const {
      applyCartCoupon,
      removeOffer,
      updateOfferPrompt,
      skuId,
      offerNotClubbable,
      getCartPricing,
      navigation,
    } = this.props;
    const { applied } = this.state;

    if (!applied) {
      this.setState({
        applied: true,
      });
      applyCartCoupon(code, (response, json) => {
        if (response) {
          getCartPricing();
        } else {
          this.setState({
            errors: true,
            applied: false,
          });
          if (json.clubbable === false) {
            const offerPrompts = [];
            _.forEach(json.offers_prompts, (e) => {
              offerPrompts.push(e.offer_id);
            });

            this.offerNotClubbable(
              json.coupon_code,
              json.offer_id,
              json.offers_prompts,
              {
                old_cart_value: json.old_cart_value,
                new_cart_value: json.new_cart_value,
              },
            );
            return;
          }
          if (Utility.isPresent(json.errors)) {
            // showToast(json.errors[0]);
            this.setState({
              errorMessage: json.errors[0],
            });
          }
        }
        getCartPricing();
      });
    }
    if (applied) {
      navigation.push(
        this.navigationSource, 'Cart', {
          source: 'tabbed_coupon',
        },
      );
    }
  };

  onFirstButtonPressForErrorMessage = () => {
    this.setState({
      errorMessage: '',
    });
  };

  closeSuccessOfferAppliedModal = () => {
    this.setState({
      successfullyAppliedCoupon: '',
    });
  };

  onFirstButtonPressForSuccessfulCoupon = () => {
    const { successfullyAppliedCoupon } = this.state;
    this.setState({
      successfullyAppliedCoupon: '',
    });
    this.navigateToOfferDetail(
      successfullyAppliedCoupon?.cta_url,
      successfullyAppliedCoupon?.offer_id,
    );
  };

  render() {
    const { data, navigation, selfie_image_url } = this.props;
    const {
      applied,
      errorMessage,
      successfullyAppliedCoupon,
      unfulfilledCoupon,
    } = this.state;
    let image = {
      uri: getMinifiedImage(
        selfie_image_url,
        styles.selfieImageStyle.width,
        styles.selfieImageStyle.height,
      ),
    };
    if (Utility.isBlank(selfie_image_url)) {
      image = images.for_you_placeholder;
    }
    const background = '#14272E';

    return (
      <View>
        <View
          style={[
            styles.descriptionContainer,
            {
              marginHorizontal: 12,
              borderRadius: 4,
              overflow: 'hidden',
            },
          ]}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <View
              style={{
                width: '25%',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#3C5B69',
                height: '100%',
                paddingHorizontal: 12,
                overflow: 'hidden',
              }}
            >
              <RGBA red={0.2} green={0.37} blue={0.47} alpha={2.5}>
                <FastImageView
                  source={image}
                  style={styles.selfieImageStyle}
                />
              </RGBA>
            </View>

            <View
              style={{
                flexDirection: 'column',
                width: '75%',
                paddingVertical: 18,
                backgroundColor: '#234351',
                paddingHorizontal: 12,
                overflow: 'hidden',
                height: '100%',
              }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: '#fff',
                  fontFamily: 'Roboto-Bold',
                }}
              >
                {data?.name}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: '#fff',
                  fontFamily: 'Roboto-Regular',
                  marginTop: 8,
                }}
              >
                {data?.short_description}
              </Text>
            </View>
          </View>
          <View
            style={{
              height: 20,
              width: 20,
              position: 'absolute',
              backgroundColor: colors.background,
              bottom: 42,
              left: -10,
              borderRadius: 10,
              overflow: 'hidden',
              zIndex: 10,
              borderWidth: 4,
              borderColor: colors.background,
            }}
          />
          <View
            style={{
              height: 20,
              width: 20,
              position: 'absolute',
              backgroundColor: colors.background,
              bottom: 42,
              right: -10,
              borderRadius: 10,
              overflow: 'hidden',
              zIndex: 10,
              borderWidth: 4,
              borderColor: colors.background,
            }}
          />
          <View
            style={{
              height: 20,
              width: 20,
              position: 'absolute',
              backgroundColor: colors.background,
              bottom: 42,
              left: -24,
              zIndex: 1000,
              borderColor: colors.background,
            }}
          />

          <View
            style={{
              height: 20,
              width: 20,
              position: 'absolute',
              backgroundColor: colors.background,
              bottom: 42,
              right: -24,
              zIndex: 1000,
              borderColor: colors.background,
            }}
          />
          <RoundedButton
            buttonText={applied ? 'APPLIED' : 'APPLY'}
            buttonTextColor={'#000'}
            buttonColor={'#fff'}
            buttonAction={() => {
              // this.onNavigationStateChange(value);
              // this.props.navigation.navigate('TakeSelfie');
              this.applyCouponCode(data?.coupon_code);
            }}
            style={{
              width: applied ? 78 : 64,
              height: 19,
              position: 'absolute',
              bottom: 12,
              right: 12,
            }}
            buttonTextStyle={{
              fontSize: 10,
              fontFamily: 'Roboto-Medium',
              marginHorizontal: 8,
            }}
          />
        </View>
        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle="Oops"
          alertMessage={errorMessage}
          firstButtonTitle="Okay"
          firstButtonOnPress={this.onFirstButtonPressForErrorMessage}
          onTapOutside={this.onFirstButtonPressForErrorMessage}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
          isError
          source={"offer_coupon"}
        />

        <FoxyAlert
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
          alertMessage={successfullyAppliedCoupon?.message}
          firstButtonTitle={
            Utility.isPresent(successfullyAppliedCoupon?.cta_text)
              ? successfullyAppliedCoupon?.cta_text
              : 'YAY !'
          }
          firstButtonOnPress={this.onFirstButtonPressForSuccessfulCoupon}
          onTapOutside={this.removeSuccessfullyAppliedCoupon}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showJson
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  descriptionContainer: {
    backgroundColor: colors.white,
    borderRadius: 8,
    zIndex: 10,
    height: 100,
    marginTop: 12,
  },
  headingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  subHeadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginTop: 6,
  },
  couponText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  offerPromptCouponContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  selfieImageStyle: {
    height: 56,
    width: 56,
    borderRadius: 28,
  },
});

const mapStateToProps = function (state) {
  return {
    imageUrl: state.UserAccountInfo.profile.selfie_image_url,
    coupon_codes: state.bag?.cartPricing?.coupon_codes,
    cartItems: state.bag.cartItems,
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      addToCart,
      applyCartCoupon,
      getCartPricing,
    },
    dispatch,
  ),
});

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(OfferCouponLayout),
);
