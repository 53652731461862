import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableWithoutFeedback,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import Video from '../../libraries/ReactNativeVideo';
import FastImageView from '../FastImageView';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import foxyImages from '../../theme/Images';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import { isPresent, isWeb } from '../../utils/BooleanUtility';
import { withEither } from '../../lib/Monads';
import { useRef } from 'react';
import UploadButtonWeb from './UploadButtonWeb';

const styles = StyleSheet.create({
  uploadButtonText: {
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    fontWeight: '500',
    fontSize: 14,
  },
  cameraIcon: { width: 24, height: 18, marginRight: 10 },

  squareCameraPlaceholder: {
    height: 54,
    width: 54,
    alignSelf: 'center',
  },

  postReviewButtonContainer: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderWidth: 1,
    borderColor: colors.border,
    alignItems: 'center',
    justifyContent: 'center',
    height: 50,
    flexDirection: 'row',
    marginTop: 12,
    borderRadius: 4,
    cursor: 'pointer',
  },
  communityGuideCTA: {
    fontFamily: 'Roboto',
    color: '#4285F4',
    fontWeight: '400',
    fontSize: 14,
  },
  communityGuidelineHeading: {
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    fontWeight: '400',
    fontSize: 14,
  },
  communityGuideRow: { flexDirection: 'column' },
  communityGuidelinesTitle: {
    fontFamily: 'Roboto',
    color: colors.foxyBlack,
    fontWeight: '500',
    fontSize: 14,
    marginBottom: 12,
  },
  uploadMediaContainer: { paddingHorizontal: 16 },
  emptyPlaceHolder: { height: 72, width: 72, marginRight: 32 },
  addMediaButtonContainer: {
    height: 72,
    width: 72,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E4E4E4',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 32,
  },
  addMediaButtonIcon: { fontSize: 30, fontWeight: '400', color: '#E4E4E4' },
  crossIcon: { height: 20, width: 20, borderRadius: 15 },
  crossIconContainer: {
    position: 'absolute',
    top: -10,
    right: -10,
    height: 20,
    width: 20,
    overflow: 'visible',
    backgroundColor: '#fff',
    borderRadius: 15,
  },
  selectedMedia: {
    height: 72,
    width: 72,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E4E4E4',
  },
  selectedMediaContainer: { marginRight: 16 },
  selectedMediaRailContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    overflow: 'visible',
  },
  scrollViewContainer: { height: 100 },
  squareContainerForImageUpload: {
    width: 112,
    height: 160,
    backgroundColor: colors.cardBackGroundColor,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.silverLit,
    borderStyle: 'dashed',
    marginTop: 12,
    justifyContent: 'center',
    marginRight: 8,
    alignItems: 'center',
    marginLeft: 0,
  },

  cameraPlaceHolder: {
    height: 54,
    width: 54,
  },
});

const UploadMedia = ({
  onUploadMediaPress,
  onUploadMediaPressAppend,
  deleteImage,
  navigation,
  media = [],
  showSquareCameraButton,
  hideCommunityGuidelines,
  communityGuideline = '',
}) => {
  const SelectedImages = () => {
    const renderEachImage = (image) => {
      const onPress = () => {
        deleteImage(media.indexOf(image));
      };

      const Thumbnail = () => {
        if (String(image?.mime).includes('image')) {
          return (
            <FastImageView source={image.path} style={styles.selectedMedia} />
          );
        }

        let player;
        const assignRef = (ref) => {
          player = ref;
        };
        const seekTo0 = () => {
          player.seek(0);
        };
        return (
          <Video
            style={styles.selectedMedia}
            source={{ uri: image.path }}
            paused
            resizeMode='cover'
            ref={assignRef}
            onLoad={seekTo0}
          />
        );
      };

      const ThumbnailWeb = () => {
        const { type = '', data = {} } = image;
        if (type.includes('image')) {
          return (
            <FastImageView
              source={{ uri: data }}
              style={styles.selectedMedia}
            />
          );
        } else if (type.includes('video')) {
          return (
            <Video
              style={styles.selectedMedia}
              source={{ uri: data }}
              paused
              resizeMode='cover'
            />
          );
        }
        return null;
      };

      return (
        <View style={styles.selectedMediaContainer}>
          {isWeb() ? <ThumbnailWeb /> : <Thumbnail />}
          <TouchableOpacity onPress={onPress} style={styles.crossIconContainer}>
            <Image source={foxyImages.greyCross} style={styles.crossIcon} />
          </TouchableOpacity>
        </View>
      );
    };

    const fileInputAppendButtonRef = useRef(null);

    const handleAppendButtonFilePicker = () => {
      if (fileInputAppendButtonRef.current) {
        fileInputAppendButtonRef.current.click();
      }
    };

    const onAppendButtonPressWeb = (event) => {
      handleFileChange(event, onUploadMediaPressAppend);
    };

    const RenderAppendButton = () => {
      if (media.length > 7) {
        return <View style={styles.emptyPlaceHolder} />;
      }
      return (
        <View>
          <TouchableWithoutFeedback
            onPress={
              isWeb() ? handleAppendButtonFilePicker : onUploadMediaPressAppend
            }
          >
            <View style={styles.addMediaButtonContainer}>
              <Text style={styles.addMediaButtonIcon}>+</Text>
            </View>
          </TouchableWithoutFeedback>
          {isWeb() && (
            <UploadButtonWeb
              fileInputRef={fileInputAppendButtonRef}
              handleFileChange={onAppendButtonPressWeb}
            />
          )}
        </View>
      );
    };

    return (
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        style={styles.scrollViewContainer}
      >
        <View style={styles.selectedMediaRailContainer}>
          {media.map(renderEachImage)}
          <RenderAppendButton />
        </View>
      </ScrollView>
    );
  };

  const showCommunityGuidelines = () => {
    const communityGuidelinesCtaUrl = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.community_guidelines_cta_url,
    );
    navigation.navigate('WebUrlView', {
      destination: communityGuidelinesCtaUrl,
      title: 'Community Guidelines',
      showBottomButton: true,
      fromOnboarding: true,
    });
  };

  const CommunityGuideLine = () => {
    if (
      !AppConfig.getBooleanValue(Config.SHOW_COMMUNITY_GUIDELINES_FOR_REVIEW)
    ) {
      return null;
    }
    const communityGuidelineCta = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.community_guideline_cta,
    );
    const communityGuidelineHeading = isPresent(communityGuideline)
      ? communityGuideline
      : RemoteConfig.getValue(REMOTE_CONFIG_KEYS.community_guideline_heading);
    return (
      <>
        <View style={styles.communityGuideRow}>
          <Text style={styles.communityGuidelineHeading}>
            {communityGuidelineHeading}
          </Text>
        </View>
        <TouchableWithoutFeedback onPress={showCommunityGuidelines}>
          <Text style={styles.communityGuideCTA}>{communityGuidelineCta}</Text>
        </TouchableWithoutFeedback>
      </>
    );
  };

  const handleFileChange = async (event, onUploadMediaPress) => {
    const files = event.target.files;
    const fileListArray = Array.from(files);

    const imageArray = await Promise.all(
      fileListArray.map(async (file) => {
        return new Promise((resolve) => {
          if (isWeb()) {
            const reader = new FileReader();
            reader.onload = (e) => {
              resolve(e.target.result);
            };
            reader.readAsDataURL(file);
          } else {
            resolve(null);
          }
        });
      }),
    );
    const imageDataArray = fileListArray.map((file, index) => ({
      type: file.type,
      data: imageArray[index],
    }));

    onUploadMediaPress(imageDataArray);
  };

  const onUploadButtonPressWeb = async (event) => {
    handleFileChange(event, onUploadMediaPress);
  };

  const fileInputRef = useRef(null);

  const handleFilePicker = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const UploadButtonContainer = () => {
    return (
      <View style={styles.postReviewButtonContainer}>
        <Image source={foxyImages.review_camera} style={styles.cameraIcon} />
        <Text style={styles.uploadButtonText}>Upload media</Text>
      </View>
    );
  };

  const SquareCameraButtonContainer = () => {
    return (
      <View style={styles.squareContainerForImageUpload}>
        <Image
          source={foxyImages.upload_image_camera_icon}
          style={styles.cameraPlaceHolder}
        />
      </View>
    );
  };

  const UploadMediaButton = () => {
    return (
      <View style={styles.uploadMediaContainer}>
        {!hideCommunityGuidelines && (
          <Text style={styles.communityGuidelinesTitle}>Add videos/photos</Text>
        )}

        {!hideCommunityGuidelines && <CommunityGuideLine />}
        {Utility.isBlank(media) ? (
          <View>
            <TouchableWithoutFeedback
              onPress={isWeb() ? handleFilePicker : onUploadMediaPress}
            >
              <View>
                {showSquareCameraButton && <SquareCameraButtonContainer />}
                {!showSquareCameraButton && <UploadButtonContainer />}
              </View>
            </TouchableWithoutFeedback>
            {isWeb() && (
              <UploadButtonWeb
                fileInputRef={fileInputRef}
                handleFileChange={onUploadButtonPressWeb}
              />
            )}
          </View>
        ) : (
          <SelectedImages />
        )}
      </View>
    );
  };

  return <UploadMediaButton />;
};

export default UploadMedia;
