import StyleSheet from 'react-native-media-query';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import { CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP } from '../../../utils/LayoutUtility';
import { isAndroid, isIOS } from '../../../utils/BooleanUtility';
import { getScreenWidth } from '../../../utils/LayoutUtility';

export const { ids: cartIds, styles: CartStyles } = StyleSheet.create({
  container: {
    flex: 1,
    width: Utility.getScreenWidth(),
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    overflow: 'hidden',
  },
  scrollView: {
    backgroundColor: colors.background,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
    marginBottom: 4,
    '@media (min-width: 768px)': {
      backgroundColor: colors.body,
    },
  },
  header: {
    position: 'absolute',
    top: 40,
    left: 0,
    right: 10,
    zIndex: 1,
    height: 52,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  crossButtonContainer: {
    height: 16,
    width: 16,
    marginRight: 16,
  },
  listContainer: {
    width: '100%',
    paddingTop: 12,
    marginTop: 12,
    paddingBottom: 4,
  },
  listHeaderContainer: {
    marginHorizontal: 12,
  },
  listHeaderTitle: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '500',
    color: '#173143',
  },
  listHeaderSubtitle: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '500',
    color: '#979BAA',
    marginTop: 4,
  },
  orderStatusCross: {
    height: 16,
    width: 16,
    position: 'absolute',
    top: 50,
    right: 24,
    zIndex: 1,
  },
  crossImage: {
    height: 16,
    width: 16,
    marginTop: 4,
    tintColor: colors.foxyBlack,
  },
  addressContainer: {
    marginTop: 4,
    marginBottom: 12 + Utility.bottomInset,
    backgroundColor: colors.white,
  },
  addressContainerForCartPage: {
    marginTop: 12,
    marginBottom: 4,
    backgroundColor: colors.white,
    '@media (min-width: 768px)': {
      marginTop: 0,
    },
  },
  addressContainerProduct: {
    backgroundColor: colors.white,
  },
  addressTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  addAddressContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 40,
    marginRight: 20,
  },
  addressHeaderText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    paddingLeft: 12,
    marginTop: 8,
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: 600,
    },
  },
  cartHeaderText: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 18,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    left: 16,
  },
  plus: {
    height: 10,
    width: 10,
    tintColor: colors.cta.lightBlue,
    marginLeft: Utility.padding,
  },
  addAddressText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.cta.lightBlue,
    marginLeft: 4,
  },
  rightArrow: {
    position: 'absolute',
    right: 0,
    marginRight: Utility.largePadding,
    tintColor: colors.foxyBlack,
  },
  rightArrowAddress: {
    position: 'absolute',
    right: 0,
    marginRight: Utility.largePadding,
    tintColor: colors.foxyBlack,
    top: 12,
  },
  checkoutInfoDockContainer: {
    position: 'absolute',
    bottom: 0,
    width: Utility.getScreenWidth(),
    backgroundColor: 'white',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      position: 'static',
      width: '100%',
    },
  },
  activityIndicatorInCheckout: {
    position: 'absolute',
    alignSelf: 'center',
    left: 48,
  },
  paymentDock: {
    height: 56,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    borderTopWidth: 1,
    borderTopColor: colors.border,
  },
  priceInfoDockContainer: {
    height: 70,
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  checkoutButton: {
    position: 'absolute',
    top: 12,
    right: Utility.largePadding,
    flexDirection: 'row',
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkoutText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  totalPriceContainer: {
    flexDirection: 'column',
    marginTop: 12,
    marginLeft: Utility.largePadding,
    height: 40,
    alignItems: 'flex-start',
  },
  totalText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.disabled,
  },
  totalPrice: {
    fontFamily: 'Roboto-Medium',
    fontSize: 18,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.foxyBlack,
  },
  individualAddressContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
  },
  userAddressTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 12,
    marginRight: 12,
    marginTop: 12,
    marginBottom: 12,
    alignItems: 'flex-start',
    width: Utility.getScreenWidth() - 72,
  },
  cartAddressTextContainer: {
    flexDirection: 'row',
    width: Utility.getScreenWidth() - 72,
    '@media (min-width: 768px)': {
      width: 359,
    },
  },
  contactNameText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,
    flex: 1,
    '@media (min-width: 768px)': {
      fontSize: size.h3,
    },
  },
  addressTextMedium: {
    fontFamily: 'Roboto-Medium',
    fontSize: 12,
    color: colors.foxyBlack,
  },
  addressTextName: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    color: colors.foxyBlack,
    flexWrap: 'wrap',
    flex: 1,
    '@media (min-width: 768px)': {
      fontSize: size.h3,
    },
  },
  radioButtonContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: 40,
    alignSelf: 'center',
  },
  promoButtonContainer: {
    height: 62,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginTop: 12,
    backgroundColor: colors.white,
    paddingLeft: 12,
  },
  promoCodeSubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 4,
  },
  promoCodeImage: {
    height: 24,
    width: 24,
    marginLeft: Utility.padding,
  },
  promoCodeText: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    '@media (min-width: 768px)': {
      fontWeight: '600',
      fontSize: 16,
    },
  },
  promoCodeSubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginBottom: 8,
    marginTop: 4,
  },
  selectedPaymentLogo: {
    marginLeft: Utility.largePadding,
    width: 40,
    height: 24,
    resizeMode: 'contain',
    borderRadius: 2,
  },
  animatedScreenInModalStyle: {
    position: 'absolute',
    backgroundColor: 'white',
    height: '100%',
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  pricingDetailsContainer: {
    alignItems: 'flex-start',
    width: Utility.getScreenWidth(),
    marginTop: 12,
    backgroundColor: colors.white,
    paddingTop: 4,
    paddingBottom: 4,
    '@media (min-width: 768px)': {
      width: '100%',
      borderRadius: 8,
    },
  },
  itemTotaltext: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    marginLeft: 12,
    marginTop: 4,
    marginBottom: 4,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  grandTotaltext: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 12,
    marginTop: 4,
    marginBottom: 8,
    '@media (min-width: 768px)': {
      fontSize: 16,
    },
  },
  price: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',

    color: '#173143',
    right: 12,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  priceStyleAddWidth: {
    position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    width: 54,
    fontStyle: 'normal',
    color: '#173143',
    right: 12,
    alignSelf: 'center',
  },
  unlockFreeDelivery: {
    fontFamily: 'Roboto-Regular',
    fontSize: 11,
    color: '#4285F4',
    marginRight: 8,
  },
  deliveryFee: {
    // position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',

    color: '#173143',
    // right: 12,
    // alignSelf: 'center',
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  deliveryFeeOrignal: {
    // position: 'absolute',
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',

    color: '#173143',
    marginLeft: 8,
    textDecorationLine: 'line-through',
    // right: 12,
    // alignSelf: 'center',
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  priceHeader: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    paddingLeft: 12,
    paddingBottom: 8,
    paddingTop: 4,
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: 800,
    },
  },
  pricingTextContainer: {
    flexDirection: 'row',
    width: '100%',
    marginTop: 4,
    '@media (min-width: 768px)': {
      marginTop: 8,
    },
  },
  grandTotalPrice: {
    position: 'absolute',
    fontFamily: 'Roboto-Medium',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    right: 12,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  verticalSpacing: {
    height: 100,
  },
  verticalSpacingCart: {
    height: 120,
  },
  selectedPaymentText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    marginLeft: 6,
    maxWidth: '45%',
    color: colors.silver,
  },
  changePaymentButtonText: {
    color: '#449386',
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
  },
  emptyStateContainer: {
    flex: 1,
    backgroundColor: colors.background,
    alignSelf: 'center',
  },
  emptyBagImageContainer: {
    height: 220,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,

    fontStyle: 'normal',
    color: colors.foxyBlack,
    margin: 4,
  },
  subtitleText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,

    fontStyle: 'normal',
    color: colors.subtitle,
    margin: 4,
    maxWidth: 150,
    textAlign: 'center',
  },
  emptyBagImage: {
    marginTop: 48,
    height: 54,
    width: 58,
  },
  headingContainer: {
    flexDirection: 'row',
    marginLeft: 12,
    marginRight: 12,
    marginBottom: 8,
    marginTop: 12,
  },
  subHeading: {
    fontSize: 16,
    lineHeight: 16,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    flex: 1,
    alignContent: 'flex-start',
  },
  priceShimmer: {
    height: 16,
    width: 54,
    marginTop: 4,
  },
  actionBottonContainer: {
    position: 'absolute',
    height: 70,
    bottom: 0,
    width: Utility.getScreenWidth(),
    flexDirection: 'column',
    justifyContent: 'flex-start',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  giftImageInStrip: {
    height: 48,
    width: 48,
    marginLeft: 24,
    marginTop: 8,
    marginBottom: 8,
  },
  giftStripContainer: {
    height: 64,
    width: '100%',
    backgroundColor: colors.white,
    flexDirection: 'row',
    alignItems: 'center',
  },
  foxySurpriseHeader: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h4,

    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginTop: 4,
    lineHeight: 14,
    marginLeft: 24,
  },

  moreButton: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
    // tintColor: colors.foxyBlack,
    marginRight: 14,
    backgroundColor: 'red',
  },
  menuOptionStyle: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,

    fontStyle: 'normal',
    letterSpacing: 0,
    margin: 6,
  },
  contactLessDeliveryContainer: {
    flex: 1,
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 1,
    backgroundColor: 'white',
    paddingHorizontal: 20,
    paddingVertical: 5,
  },
  checkBoxContainer: {
    height: 14,
    borderColor: colors.silver,
    borderWidth: 0.4,
    borderRadius: 3,
    width: 14,
    marginTop: 8,
  },
  checkBox: {
    height: 14,
    width: 14,
    borderRadius: 2,
  },
  checkBoxTextContainer: { width: '90%', flexDirection: 'column' },

  checkBoxHeading: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 16,
    marginTop: 4,
  },
  checkBoxSubHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,

    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.silver,
    marginLeft: 16,
    marginBottom: 4,
  },
  removeOfferButton: { position: 'absolute', right: 24, alignSelf: 'center' },
  removeOfferText: {
    fontFamily: 'Roboto-Medium',
    color: colors.foxyPink,
    fontSize: size.h4,
    letterSpacing: 0,
  },
  cartLoadingOverlay: {
    flex: 1,
    position: 'absolute',
    height: Utility.getScreenHeight(),
    width: Utility.getScreenWidth(),
    backgroundColor: '#000000',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    opacity: 0.5,
    zIndex: 1,
  },
  toast: {
    position: 'absolute',
    bottom: 60,
    maxWidth: Utility.getScreenWidth() - 32,
  },
  delayedDeliveryText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: '#979BAA',
    paddingBottom: 8,
  },
  promiseDeliverySubText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    color: colors.silver,
  },
  promiseDeliveryTitle: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  promiseDeliveryContainer: { marginTop: 8 },
  addressDeliveryHeading: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.foxyBlack,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  truckImage: {
    height: 20,
    width: 20,
    marginRight: 6,
    resizeMode: 'contain',
    '@media (min-width: 768px)': {
      height: 24,
      width: 24,
    },
  },
  addressDeliveryContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  promiseDeliveryBox: {
    backgroundColor: '#FFEEF2',
    padding: 12,
    borderRadius: 8,
    marginBottom: 8,
  },
  verticalPadding: {
    paddingBottom: 8,
  },
  emptyAddressContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  emptyAddressText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    color: colors.foxyBlack,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  chooseFreeGiftConfirmButtonText: {
    fontFamily: 'Roboto',
    fontSize: 16,
    fontWeight: '500',
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'center',
    color: colors.white,
  },
  chooseFreeGiftButtonContainer: {
    marginTop: 8,
    height: 44,
    backgroundColor: colors.green,
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    width: '85%',
    alignSelf: 'center',
    flexDirection: 'row',
    marginBottom: Utility.bottomInset,
  },
  offerPromptShimmerContainer: {
    backgroundColor: colors.white,
    padding: 16,
  },
  offerPromptShimmer: {
    width: 200,
    marginHorizontal: 12,
    borderRadius: 4,
    opacity: 0.5,
  },
  loadingContainer: {
    height: Utility.getWindowHeight(),
    width: Utility.getScreenWidth(),
    alignItems: 'center',
    justifyContent: 'center',
  },
  spShimmer: { width: 54, marginRight: 4, borderRadius: 2 },
  mrpShimmer: { width: 54, borderRadius: 2 },
  discountShimmer: { width: 54, borderRadius: 2, marginLeft: 8 },

  shimmerContainer: { flexDirection: 'row' },
  loaderContainer: {
    height: Utility.getScreenHeight() / 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: { height: 120, backgroundColor: colors.background },
  actionButton: {
    position: 'absolute',
    width: Utility.getScreenWidth(),
    flexDirection: 'column',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    height: isIOS ? 50 + Utility.bottomInset : 20 + Utility.bottomInset,
    bottom: 0,
    backgroundColor: colors.background,
    alignSelf: 'center',
  },
  indicatorContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    height: Utility.getScreenHeight() / 2,
  },
  cartTabImageStyle: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  unlockFreeDeliveryText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: '#01B460',
  },
  unlockFreeDeliveryContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItem: 'space-between',
    paddingHorizontal: 10,
    width: '100%',
    marginBottom: 18,
    marginTop: 6,
  },
  claimButton: {
    backgroundColor: colors.green,
    borderWidth: 1,
    borderRadius: 16,
    borderStyle: 'solid',
    borderColor: '#01B460',
    width: 74,
    height: 20,
    justifyContent: 'center',
  },
  claimButtonText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.white,
    alignItems: 'center',
    textAlign: 'center',
  },
  deliveryFeeOriginalText: {
    fontSize: 12,
    color: '#01B460',
    fontFamily: 'Roboto-Regular',
    marginLeft: 4,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  deliveryFeeOriginalView: {
    position: 'absolute',
    right: 12,
    alignSelf: 'center',
    flexDirection: 'row',
  },
  centerFlexContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  goGreenContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 12,
  },
  goGreenHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    paddingBottom: 12,
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  contactLessContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 8,
  },
  contactLessHeading: {
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
    paddingBottom: 8,
    '@media (min-width: 768px)': {
      fontSize: 16,
      fontWeight: 700,
    },
  },
  alignCenterRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  additionalItemImage: {
    height: 14,
    borderColor: colors.silver,
    borderWidth: 0.4,
    borderRadius: 3,
    width: 14,
  },
  additionalItemText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.foxyBlack,
    marginLeft: 8,
    paddingRight: 12,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  cartLeftContainer: {
    width: CART_LEFT_CONTAINER_WIDTH_FOR_DESKTOP,
    alignSelf: 'flex-start',
  },
  cartRightContainer: {
    width: 432,
  },
  cartContainer: {
    flexDirection: 'row',
    width: Utility.getScreenWidth(),
    justifyContent: 'space-between',
  },
  locationIcon: {
    width: 18,
    height: 16,
    resizeMode: 'contain',
    tintColor: '#000',
    marginRight: 8,
    '@media (min-width: 768px)': {
      width: 20,
      height: 20,
    },
  },
  preOrderText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    color: colors.foxyBlack,
  },
  preOrderDate: {
    fontFamily: 'Roboto-Bold',
    fontSize: 14,
    color: colors.foxyBlack,
    marginTop: Utility.isIOS() ? 2 : 0,
  },
  desktopEmptySpace: {
    height: 30,
    width: '100%',
  },
  emptyCartFooterImage: {
    position: 'relative',
    bottom: isIOS() ? 90 : 60 + Utility.bottomNavBarHeightAndroid,
  },
  selectAddressContainer: {
    flex: 1,
    flexDirection: 'row',
    width: getScreenWidth(),
  },
  selectAddressList: {
    height: '100%',
    alignSelf: 'center',
  },
  addressCartPricingContainer: {
    width: 320,
  },
});
