import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import Config from 'react-native-config';
import AutoHeightWebView from 'react-native-autoheight-webview';
import { debounce, memoize } from 'lodash';
import { getScreenHeight } from '../../utils/LayoutUtility';
import { jsonParser } from '../../utils/JsonUtility';
import { getAutoHeightScript } from '../../injected/APlusCss';
import { isBlank, isIOS } from '../../utils/BooleanUtility';
import HandleNavigationFromWebview from '../../utils/WebViewUtility';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import Utility from '../../utils/Utility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

const decelerationRate = 'normal';

const urlParams = (() => {
  const AplusContentData =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
    {};
  const {
    product_page_a_plus_url_params = Config.PRODUCT_PAGE_A_PLUS_URL_PARAMS || '',
  } = AplusContentData;

  return product_page_a_plus_url_params;
})();


const APlusContentWebView = (props) => {
  const {
    renderLoading,
    source: { uri },
    superOnMessage,
    enableAutoHeight = false,
    previousScreen,
    injectedJavaScript = '',
    listData: { objects: listObjects = [] } = {},
    itemData: {
      data: { attributes: { reject_selectors: itemDataRejectedSelectors = '' } = {} } = {},
    } = {},
  } = props;
  let {
    itemData: {
      data: { attributes: { url = uri } = {} } = {},
    } = {},
  } = props;

  if (isBlank(uri)) return null;
  url = url.includes('?') ? `${url}&${urlParams}` : `${url}?${urlParams}`;
  const [isLoading, setIsLoading] = useState(true);
  const [isTimeout, setIsTimeout] = useState(false);
  const [height, setHeight] = useState(0);
  const [fetchError, setFetchError] = useState(false);
  const webviewRef = useRef(null);
  const intervalRef = useRef(null);
  let loadStartTime = null;
  let injectedJS = injectedJavaScript;
  if (enableAutoHeight) {
    injectedJS = getAutoHeightScript(injectedJS);
  }

  const fireEvent = (eventType) => {
    if (isBlank(eventType)) {
      throw new Error(`Event Type cannot be blank! Failed to log event on URL: ${url}`);
    }

    AnalyticsManager.logEvent(eventType, {
      [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
      [EventParameterKey.LOAD_TIME]: Utility.getTimeDiff(new Date(loadStartTime), new Date()),
      [EventParameterKey.URL]: url,
    });
  };
  const debouncedFireEvent = debounce(fireEvent, 100, { trailing: false, leading: true });

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (height === 0 && enableAutoHeight) {
        debouncedFireEvent(EventType.pageLoad.WEBVIEW_LOAD_TIMEOUT);
        setIsTimeout(true);
        clearInterval(intervalRef.current);
      }
    }, 15000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const onMessage = (event) => {
    if (fetchError || isBlank(event.nativeEvent.data)) return;

    superOnMessage && superOnMessage(event, webviewRef);

    const { height: htmlHeight = 0 } = jsonParser(event.nativeEvent.data) || {};

    if (enableAutoHeight) {
      setIsLoading(false);
      setHeight(htmlHeight + 30);
    }
  };

  const onLoadProgress = ({ nativeEvent }) => {
    if (nativeEvent.progress > 0.5) {
      webviewRef.current.injectJavaScript(injectedJS);
    }
    if (nativeEvent.progress > 0.9 && !enableAutoHeight) {
      setIsLoading(false);
    }
  };

  const onLoadError = () => {
    setFetchError(true);
    debouncedFireEvent(EventType.pageLoad.WEBVIEW_LOAD_ERROR);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setIsLoading(false);
  };

  const onLoad = () => {
    clearInterval(intervalRef.current);
    debouncedFireEvent(EventType.pageLoad.WEBVIEW_LOAD);
  };

  const onLoadStart = () => {
    loadStartTime = new Date();
  };

  if (isTimeout) return null;
  let style = styles.container;
  if (enableAutoHeight) {
    style = memoize(
      () => ({ height }),
      () => [height],
    )();
  }

  return (
    <>
      <AutoHeightWebView
        ref={webviewRef}
        scrollEnabled={!enableAutoHeight}
        source={{ uri: url }}
        style={style}
        onHttpError={onLoadError}
        onMessage={onMessage}
        customScript={injectedJS}
        onLoadProgress={onLoadProgress}
        allowsInlineMediaPlayback
        onShouldStartLoadWithRequest={(clickParams) => HandleNavigationFromWebview(clickParams, url)}
        onLoadStart={onLoadStart}
        onLoad={onLoad}
        cacheEnabled
        onError={onLoadError}
        setBuiltInZoomControls={false}
        androidLayerType="hardware"
        domStorageEnabled
        scalesPageToFit
        cacheMode="LOAD_DEFAULT"
        viewportContent="width=device-width, user-scalable=no, initial-scale=1.0"
        decelerationRate={decelerationRate}
      />
      {isLoading && renderLoading && renderLoading()}
    </>
  );
};

export default APlusContentWebView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    height: getScreenHeight(),
  },
});
