import React, { useEffect, useState } from 'react';
import { Image, View, Text, StyleSheet, Linking } from 'react-native';
import { min } from 'lodash';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import FastImageView from '../FastImageView';
import ScaleAnimate from '../shared/ScaleAnimate';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import NavigationService from '../../navigator/NavigationService';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getScreenWidth } from '../../utils/LayoutUtility';

const ImageHeightDivisor = { 2: 1.97, 3: 1.0, 4: 1 };
const maxHeight = Utility.getScreenHeight() * 0.6;

const generateRandomIntegerInRange = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const LinkCard = ({
  item = {},
  columnWidth,
  handleDeeplink = () => {},
  columnCount,
  styles = {},
  fireItemClickEvent,
}) => {
  const {
    url = '',
    image_url: imageUrl = '',
    name = '',
    url_domain: domain = '',
  } = item;

  if (Utility.isBlank(imageUrl) || Utility.isBlank(url)) return null;

  const [height, setHeight] = useState(200);

  const onPress = () => {
    if (Utility.isBlank(url)) return;
    if (isWeb()) {
      Linking.openURL(url);
      return;
    }
    fireItemClickEvent(item);
    handleDeeplink(url);
  };

  useEffect(() => {
    Image.getSize(
      getMinifiedImage(imageUrl, columnWidth),
      (_, height) => {
        let newHeight = height / ImageHeightDivisor[columnCount];
        newHeight = min([maxHeight, newHeight]);
        if (isDesktop()) {
          newHeight *= 0.5;
        }
        setHeight((generateRandomIntegerInRange(7, 10) / 10) * newHeight);
      },
    );
  }, []);

  return (
    <View style={styles.container}>
      <ScaleAnimate onPress={onPress}>
        <FastImageView
          source={imageUrl}
          style={[styles.imageContainer, { height }]}
          resizeMode="cover"
        />
        <View style={styles.description}>
          {Utility.isPresent(name) && (
            <Text style={styles.cardDescription} numberOfLines={2}>
              {name}
            </Text>
          )}
          {Utility.isPresent(domain) && (
            <View style={styles.link}>
              <FastImageView source={images.altLink} style={styles.icon} />
              <Text style={styles.linkText} numberOfLines={1}>
                {domain}
              </Text>
            </View>
          )}
        </View>
      </ScaleAnimate>
    </View>
  );
};

const handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
  NavigationService.navigate(route, { slug, extra });
};

const handleDeeplink = (url) => {
  DynamicLinkManager.handleDynamicLink(url, handleRouteFromLink);
};

export const LinkCardRail = ({
  itemData = {},
  navigation,
  onItemPress = () => {},
  routineActivityId = '',
  listData: { columns = '' } = {},
  previousScreen,
}) => {
  const {
    url = '',
    image_url: imageUrl = '',
    name = '',
    url_domain: domain = '',
  } = itemData;
  if (Utility.isBlank(imageUrl) || Utility.isBlank(url)) return null;

  const onPress = () => {
    if (Utility.isBlank(url)) return;
    onItemPress(routineActivityId);
    handleDeeplink(url, navigation);
  };

  let railDescriptionStyle = railStyles.description;
  let railContainer = railStyles.container;
  let railImageContainer = railStyles.imageContainer;

  if (previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE && columns === 1) {
    railContainer = railStyles.singleLinkContainer;
    railDescriptionStyle = railStyles.singleDescription;
    railImageContainer = railStyles.singleImageContainer;
  }

  if (previousScreen === SCREEN_CONSTANTS.DURING_ROUTINE && columns === 2) {
    railContainer = railStyles.doubleLinkContainer;
    railDescriptionStyle = railStyles.doubleDescription;
    railImageContainer = railStyles.doubleImageContainer;
  }

  return (
    <View style={railContainer}>
      <ScaleAnimate onPress={onPress}>
        <FastImageView
          source={imageUrl}
          style={railImageContainer}
        />
        <View style={railDescriptionStyle}>
          {Utility.isPresent(name) && (
            <Text
              style={railStyles.cardDescription}
              numberOfLines={2}
              ellipsizeMode="tail"
            >
              {name}
            </Text>
          )}
          {Utility.isPresent(domain) && (
            <View style={railStyles.link}>
              <FastImageView source={images.altLink} style={railStyles.icon} />
              <Text style={railStyles.linkText} numberOfLines={1}>
                {domain}
              </Text>
            </View>
          )}
        </View>
      </ScaleAnimate>
    </View>
  );
};

export default LinkCard;

const railStyles = StyleSheet.create({
  container: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
    marginRight: 8,
    marginLeft: 4,
  },
  singleLinkContainer: {
    width: getScreenWidth() - 110,
    marginHorizontal: 8,
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  doubleLinkContainer: {
    shadowColor: colors.black,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
    marginHorizontal: 2,
    width: (getScreenWidth() - 102) / 2,
  },
  singleImageContainer: {
    width: '100%',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: colors.white,
    height: 144,
    resizeMode: 'cover',
  },
  doubleImageContainer: {
    width: (getScreenWidth() - 102) / 2,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: colors.white,
    height: 144,
    resizeMode: 'cover',
  },
  imageContainer: {
    width: 146,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: colors.white,
    height: 144,
  },
  cardDescription: {
    paddingHorizontal: 8,
    fontFamily: 'Roboto',
    fontSize: size.h3_5,
    color: colors.black,
    width: 146,
  },
  link: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flex: 1,
    paddingHorizontal: 6,
  },
  linkText: { fontFamily: 'Roboto', fontSize: size.h3, color: colors.grey },
  icon: {
    height: 13,
    width: 13,
    marginRight: 4,
  },
  description: {
    flex: 1,
    backgroundColor: colors.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: 2,
    height: 84,
  },
  singleDescription: {
    flex: 1,
    backgroundColor: colors.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: 2,
    height: 84,
  },
  doubleDescription: {
    flex: 1,
    backgroundColor: colors.white,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    marginTop: 2,
    height: 84,
    paddingHorizontal: 2,
  },
});
