// Dependencies
import React, { PureComponent } from 'react';
import { View, Text, Animated, StyleSheet } from 'react-native';
import Toast from 'react-native-easy-toast';
import { REMOTE_CONFIG_KEYS, CURRENCY_SYMBOL } from '../../config/Constants';
import { ProductDetailStyles } from './styles';
import ProductPagePlaceHolder from '../shared/ProductPagePlaceHolder';
import { ProductImages } from '.';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { PRODUCT, PRODUCT_RIGHT_CONTAINER_WIDTH } from '../../config/LayoutConstants/ProductConfig';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import ProductRatingAndMoreInfo from './ProductRatingAndMoreInfo';
import ProductImageWithDescriptionCard from './ProductImageWithDescriptionCard';
import IncrementDecrement from './IncrementDecrement';
import { AnalyticsManager, EventType } from '../../analytics';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import ProductImagesDesktop from './ProductImagesDesktop';
import ProductDetailTopSectionSummary from './ProductDetailTopSectionSummary';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { memoize } from 'lodash';
import { compose } from 'recompose';
import { withEither } from '../../lib/Monads';

class ProductSummary extends PureComponent {
  constructor(props) {
    super(props);
    this.bottomButtonOffset = Utility.isAndroid() ? 125 : 40;
    this.productPageConfig = memoize(
      () => {
        return jsonParser(
          RemoteConfig.getValue(REMOTE_CONFIG_KEYS.product_page),
        );
      },
      () => [REMOTE_CONFIG_KEYS.product_page],
    )();
  }

  componentDidMount() {
    const { slug } = this.props;
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
      modal_name: SCREEN_CONSTANTS.PRODUCT_SUMMARY,
      slug,
    });
  }

  goBack = () => {
    const { navigation, slug } = this.props;
    navigation.goBack();
    AnalyticsManager.logEvent(EventType.artistEvents.MODAL_CLOSE, {
      modal_name: SCREEN_CONSTANTS.PRODUCT_SUMMARY,
      slug,
    });
    return true;
  };

  ProductDescription = (props) => {
    const { name: productName = '' } = props;
    return (
      <View style={ProductDetailStyles.productDescriptionContainerStyle}>
        <Text style={ProductDetailStyles.productNameStyle}>{productName}</Text>
      </View>
    );
  };

  AddToCartButton = (props) => {
    const {
      addedToCart,
      itemData,
      showCart,
      addToCartLayout,
      inModal,
      dismissModal,
      previousScreen,
      itemData: { sku_id },
    } = props;

    const { force_apply_coupon, offer_id, listId } = this.props;

    return (
      <IncrementDecrement
        skuId={sku_id}
        addedToCart={addedToCart}
        addToCartLayout={addToCartLayout}
        showCart={showCart}
        previousScreen={SCREEN_CONSTANTS.PRODUCT_SUMMARY}
        itemData={itemData}
        navigation={this.props.navigation}
        inModal={inModal}
        dismissModal={dismissModal}
        multiVariantAttributes={itemData?.multi_level_variant_attributes}
        variantModal
        force_apply_coupon={force_apply_coupon}
        offer_id={offer_id}
        listId={listId}
      />
    );
  };

  isProductInStock = (stockedStatus) => {
    return !(
      stockedStatus === 'stocked_out' ||
      stockedStatus === 'unstocked' ||
      stockedStatus === 'discontinued'
    );
  };

  isUnStocked = ({ itemData }) => itemData.stocked_status === 'unstocked';

  ProductPricingData = (props) => {
    let { mrp } = props;

    return (
      <View style={{ flexDirection: 'row' }}>
        <Text style={ProductDetailStyles.spStyle}>
          {`${CURRENCY_SYMBOL}${mrp} `}
        </Text>
      </View>
    );
  };

  PricingInfo = (props) => {
    const { itemData, selectedVariant, addedToCart } = props;

    let { mrp } = props;
    const {
      previousScreen,
      itemData: { stocked_status },
      force_apply_coupon,
      offer_id,
      listId,
    } = this.props;

    if (itemData?.gwp) {
      return null;
    }

    if (['unstocked', 'discontinued'].includes(stocked_status)) {
      return null;
    }

    return (
      <View style={ProductDetailStyles.productSummaryPricingContainer}>
        <this.ProductPricingData mrp={mrp} />
        <this.AddToCartButton
          addedToCart={addedToCart}
          addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_DETAIL}
          showCart={this.showCart}
          previousScreen={previousScreen}
          itemData={itemData}
          isBuyNow
          force_apply_coupon={force_apply_coupon}
          offer_id={offer_id}
          listId={listId}
        />
      </View>
    );
  };

  ProductInStock = () => {
    const { itemData } = this.props;
    let stockedStatus = '';

    if (this.isProductInStock(itemData?.stocked_status)) {
      return null;
    }

    if (itemData?.stocked_status === 'unstocked') {
      stockedStatus = 'This item is not in stock';
    }

    if (itemData?.stocked_status === 'discontinued') {
      stockedStatus = 'This item is discontinued by brand';
    }

    if (itemData?.stocked_status === 'stocked_out') {
      stockedStatus = 'Will be back in stock soon';
    }

    if (itemData?.gwp) {
      stockedStatus = 'This item is not for sale';
    }

    return (
      <View style={ProductDetailStyles.stockedStatusContainer}>
        <Text style={ProductDetailStyles.stockedStatusText}>
          {stockedStatus}
        </Text>
      </View>
    );
  };

  ProductHeroDescription = (props) => {
    const { heroDescription } = props;
    if (Utility.isBlank(heroDescription)) return null;

    return (
      <View style={ProductDetailStyles.heroDescriptionBoxContainer}>
        <Text
          style={ProductDetailStyles.productDescriptionHeroStyle}
          numberOfLines={3}
        >
          {heroDescription}
        </Text>
      </View>
    );
  };

  toastRef = (ref) => {
    this.toast = ref;
  };

  desktopSummary = () => {
    const { itemData, offer_id } = this.props;
    let { images, image_url } = itemData;
    if (Utility.isBlank(images) || images.length === 0) {
      images = [image_url];
    }

    return (
      <View style={styles.desktopSummaryContainer}>
        <View style={styles.productImageContainer}>
          <ProductImagesDesktop images={images} />
        </View>
        <View style={styles.desktopProductInfoContainer}>
          <ProductDetailTopSectionSummary
            data={itemData}
            currentSku={itemData?.sku_id}
            isLoading={false}
            offer_id={offer_id}
            productPage={this.productPageConfig}
          />
          <View style={styles.desktopProductInStock}>
            <this.ProductInStock itemData={itemData} />
          </View>
        </View>
      </View>
    );
  };

  productInStockSummary = () => {
    const { itemData } = this.props;
    let { images, image_url, type, name, product_name } = itemData;
    if (Utility.isBlank(images) || images.length === 0 || isWeb()) {
      images = [image_url];
    }

    const productName = type === 'product' ? name : product_name;
    const { product_image_aspect_shape = 'square' } = itemData;
    const tempItemData = {
      ...itemData,
      product_image_aspect_shape: `${product_image_aspect_shape}small`,
    };
    return (
      <>
        <ProductImages
          images={images}
          tag={itemData.tag}
          previousScreen={SCREEN_CONSTANTS.PRODUCT_DETAIL}
          id={itemData.id}
          name={itemData.name}
          hero_video={itemData.hero_video}
          navigation={this.props.navigation}
          itemData={tempItemData}
        />

        <this.ProductDescription
          brand={itemData.brand}
          name={productName}
          rating={itemData.rating}
        />
        <this.ProductHeroDescription
          heroDescription={itemData.hero_description}
        />
        <ProductRatingAndMoreInfo
          rating={itemData?.rating}
          id={itemData?.id}
          sku_id={itemData?.sku_id}
        />
      </>
    );
  };

  productOutOfStockSummary = () => {
    const { itemData, navigation } = this.props;
    return (
      <>
        <ProductImageWithDescriptionCard
          itemData={itemData}
          navigation={navigation}
        />
        {itemData.stocked_status === 'stocked_out' && (
          <ProductRatingAndMoreInfo
            rating={itemData?.rating}
            id={itemData?.id}
            sku_id={itemData?.sku_id}
          />
        )}
        <this.ProductInStock itemData={itemData} />
      </>
    );
  };

  isDesktop = () => isDesktop();

  isInStock = (props) => ['in_stock'].includes(props.stockedStatus);

  conditionalProductSummary = compose(
    withEither(this.isDesktop, this.desktopSummary),
    withEither(this.isInStock, this.productInStockSummary),
  )(this.productOutOfStockSummary);

  render() {
    const { itemData, addedToCart } = this.props;

    if (Utility.isBlank(itemData)) {
      return (
        <View>
          <ProductPagePlaceHolder hideHeader />
        </View>
      );
    }
    let { images, image_url } = itemData;

    if (Utility.isBlank(images) || images.length === 0) {
      images = [image_url];
    }

    return (
      <View>
        <Animated.ScrollView
          ref={this.scrollRef}
          style={{ marginBottom: 72 }}
          scrollEventThrottle={1}
          removeClippedSubviews
        >
          <this.conditionalProductSummary
            stockedStatus={itemData.stocked_status}
          />
        </Animated.ScrollView>

        <this.PricingInfo
          mrp={itemData.mrp}
          itemData={itemData}
          addedToCart={addedToCart}
          priority={itemData.priority}
        />

        <Toast style={ProductDetailStyles.toast} ref={this.toastRef} />
      </View>
    );
  }
}

export default withNavigation(ProductSummary);

const styles = StyleSheet.create({
  container: { flex: 1, alignItems: 'center' },
  verticalSpacer: {
    backgroundColor: colors.background,
    height: 200,
  },
  productDetailContainer: {
    '@media (min-width: 800px)': {
      flex: 1,
      width: getScreenWidth(),
      flexDirection: 'row',
      backgroundColor: colors.background,
      marginBottom: 20,
      alignSelf: 'center',
    },
  },
  productImageContainer: {
    width: 484,
    zIndex: 3,
    position: 'sticky',
    top: 0,
    height: 490,
  },
  desktopSummaryContainer: { flexDirection: 'row' },
  desktopProductInfoContainer: {
    marginLeft: 8,
    flexDirection: 'column',
    width: PRODUCT_RIGHT_CONTAINER_WIDTH,
  },
  desktopProductInStock: {
    marginTop: 48,
  },
  productDetailScrollView: {
    '@media (min-width: 800px)': {
      height: 'auto',
      marginBottom: 0,
    },
  },
});
