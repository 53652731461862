import React from 'react';
import { useDispatch } from 'react-redux';
import { v7 as uuidv7 } from 'uuid';
import AccountOptionItem from './AccountOptionItem';
import colors from '../../theme/Colors';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  isUserLoggedOutOnce,
  logoutUser,
  registerGuestUser,
  saveGuestAuthToken,
  saveUserState,
  smsRetrieverStatus,
  unlinkDevice,
} from '../../actions/LoginActions';
import { CURRENT_GUEST_STATE } from '../../config/Constants';
import BGNetworkTaskManager from '../../utils/BGNetworkTaskManager';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

export default function LogoutOption({
  navigation,
  toggleAccountModal = () => {},
}) {
  const dispatch = useDispatch();
  const closeModal = () => {
    navigation.pop();
  };
  const toggleModal = () => {
    toggleAccountModal();
    navigation.push(SCREEN_CONSTANTS.ALERT_MODAL, {
      alertBoxTitle: 'Logout?',
      alertMessage: 'Are you sure you want to logout.',
      textAlignment: 'center',
      firstButtonTitle: 'Yes',
      firstButtonTextColor: colors.dodgerBluePressed,
      secondButtonTitle: 'Cancel',
      firstButtonOnPress: logout,
      secondButtonOnPress: closeModal,
      onTapOutside: closeModal,
    });
  };
  const logout = () => {
    closeModal();
    dispatch(saveUserState(CURRENT_GUEST_STATE.INITIAL));
    dispatch(unlinkDevice());
    dispatch(isUserLoggedOutOnce(true));
    dispatch(logoutUser());
    dispatch(smsRetrieverStatus('initiate'));
    generateGuestTokenBeforeLogout();
    navigation.navigate(SCREEN_CONSTANTS.FEED);
  };

  const generateGuestTokenBeforeLogout = () => {
    const guestToken = uuidv7();
    dispatch(saveGuestAuthToken(guestToken));
    BGNetworkTaskManager.saveGuestAuthToken(guestToken);
    dispatch(registerGuestUser());
    AnalyticsUtility.setGuestTokenAsProperties(guestToken);
  };

  return <AccountOptionItem label='Logout' handlePress={toggleModal} />;
}
