import React from 'react';
import { View, Text, StyleSheet, Linking, Platform } from 'react-native';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import images from '../../theme/Images';
import { isBlank } from '../../utils/BooleanUtility';
import InfluencerRating from '../../containers/artist/InfluencerRating';

const DirectionButton = (props) => {
  const { buttonText = 'Get directions', buttonIcon = '', lat = '', long = '' } = props;
  const openGoogleMaps = () => {
    const scheme = Platform.select({ ios: 'maps://0,0?q=', android: 'geo:0,0?q=' });
    const latLng = `${lat},${long}`;
    const label = 'Custom Label';
    const url = Platform.select({
      ios: `${scheme}${label}@${latLng}`,
      android: `${scheme}${latLng}(${label})`,
      web: `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
    });
    Linking.openURL(url);
  };
  return (
    <FoxyShadowButton
      width={(getScreenWidth() - 24 - 12) / 3}
      height={34}
      title={buttonText}
      onPress={openGoogleMaps}
      style={styles.buttonContainer}
      textStyle={styles.buttonText}
      firstColor={colors.black}
      secondColor={colors.black}
      underlayColor={colors.black}
      showActionIcon={!!buttonIcon}
      actionIcon={{ uri: buttonIcon }}
    />
  );
};

const BottomContainer = ({ address = '', rating = '', lat = '', long = '', cta_text = '', ratingsCount = '' }) => {
  return (
    <View style={styles.bottomContainer}>
      <View style={styles.bottomLeftContainer}>
        <View style={styles.ratingAndDistanceContainer}>
          <View style={styles.ratingContainer}>
            <Text style={styles.subtitle}>{rating}</Text>
            <InfluencerRating
              selectedIconImage={{ uri: images.star_icon }}
              ratingSize={16}
              containerStyle={styles.smallRatingIconContainer}
              ratingsCount={ratingsCount}
            />
          </View>
        </View>
        <Text ellipsizeMode='tail' numberOfLines={1} style={styles.address}>
          {address}
        </Text>
      </View>
      <View style={styles.bottomRightContainer}>
        <DirectionButton lat={lat} long={long} buttonText={cta_text} />
      </View>
    </View>
  );
};

const GetDirectionDetails = (props) => {
  const {
    itemData: {
      arguments: {
        address = '',
        rating = '',
        lat = '',
        long = '',
        user_ratings_total: ratingsCount = '',
        picture_url: pictureUrl = '',
      } = {},
      cta_text = '',
    } = {},
  } = props;
  if (isBlank(lat) && isBlank(long)) return null;
  const locationImageUrl = isBlank(pictureUrl) ? images.skin_clinic_sample_image : pictureUrl;
  return (
    <View style={styles.container}>
      <FastImageView
        resizeMode='cover'
        source={getMinifiedImage(locationImageUrl, styles.imageContainer.width)}
        style={styles.imageContainer}
      />
      <BottomContainer
        address={address}
        rating={rating}
        lat={lat}
        long={long}
        cta_text={cta_text}
        ratingsCount={ratingsCount}
      />
    </View>
  );
};

export default GetDirectionDetails;

const styles = StyleSheet.create({
  container: {
    width: getScreenWidth() - 24,
    backgroundColor: colors.white,
    height: 172,
    borderRadius: 12,
    alignContent: 'center',
    marginVertical: 12,
    alignSelf: 'center',
  },
  imageContainer: {
    height: 114,
    width: getScreenWidth() - 28,
    borderRadius: 12,
    paddingBottom: 12,
    alignSelf: 'center',
  },
  textContainer: {
    paddingVertical: 8,
  },
  title: {
    color: colors.foxyBlack,
    paddingHorizontal: 4,
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
    color: colors.foxyBlack,
    textAlign: 'center',
    paddingHorizontal: 4,
  },
  address: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'flex-start',
    color: colors.foxyBlack,
    textAlign: 'left',
    paddingHorizontal: 4,
    width: 200,
  },
  buttonContainer: {},
  buttonText: { borderRadius: 8 },
  bottomContainer: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 10,
    paddingHorizontal: 6,
  },
  bottomLeftContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    paddingHorizontal: 6,
  },
  bottomRightContainer: {},
  ratingAndDistanceContainer: {
    flexDirection: 'row',
  },
  addressContainer: {},
  imageStyle: {
    height: 12,
    width: 12,
    alignSelf: 'center',
  },
  ratingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  smallRatingIconContainer: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
