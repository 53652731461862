import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import HTML from 'react-native-render-html';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import FastImageView from '../../FastImageView';
import { isWeb } from '../../../utils/BooleanUtility';
import { getMinifiedImage } from '../../../utils/ImageUtility';

class RoutineArtistDetailsCard extends PureComponent {
  render() {
    const {
      artist: {
        name = '',
        bio = '',
        imageUrl = '',
      } = {},
    } = this.props;
    const influencerTags = this.props?.user?.profile?.influencerTags || [];
    if (Utility.isBlank(imageUrl)) return null;
    const data = bio?.replace(/(\r\n|\n|\r)/gm, '');
    return (
      <View style={styles.container}>
        <Text style={styles.title}>About {name}</Text>
        <View style={styles.artistInfoContainer}>
          <FastImageView
            source={getMinifiedImage(imageUrl, styles.artistImage.width, styles.artistImage.height)}
            style={styles.artistImage}
          />
          <View style={styles.artistInfoTextContainer}>
            {influencerTags.map((tag) => {
              return (
                <View style={styles.tagsContainer}>
                  <FastImageView
                    source={getMinifiedImage(tag?.imageUrl, styles.tagImage.width, styles.tagImage.height)}
                    style={styles.tagImage}
                  />
                  <Text style={styles.tagsText}>{tag?.name}</Text>
                </View>
              );
            })}
          </View>
        </View>
        {Utility.isPresent(data) && (
          <HTML
            html={`${data}`}
            tagsStyles={{
              p: styles.body,
            }}
          />
        )}
      </View>
    );
  }
}

export default RoutineArtistDetailsCard;

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingHorizontal: 12,
    paddingVertical: 20,
    alignItems: 'flex-start',
    borderRadius: 8,
    backgroundColor: colors.white,
  },
  title: {
    color: colors.foxyBlack,
    fontSize: size.h1,
    fontFamily: 'Roboto-Bold',
  },
  body: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
  },
  artistImage: {
    width: 70,
    height: 70,
    borderRadius: 35,
    resizeMode: isWeb() ? 'cover' : 'contain',
  },
  artistInfoTextContainer: {
    marginLeft: 22,
  },
  artistInfoContainer: {
    marginVertical: 12,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  tagsText: {
    fontSize: size.h3,
    marginBottom: 6,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  tagImage: {
    width: 16,
    height: 16,
    resizeMode: 'contain',
    marginRight: 8,
  },
  tagsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
});
