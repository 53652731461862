import {
  Image,
  LayoutAnimation,
  Platform,
  StyleSheet,
  Text,
  UIManager,
  View,
  TouchableOpacity,
} from 'react-native';
import React, { Component } from 'react';
import _ from 'lodash';
import Config from '../../../libraries/ReactNativeConfig';
import colors from '../../../theme/Colors';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import images from '../../../theme/Images';
import Utility from '../../../utils/Utility';
import CouponCodePill from '../../cart/CouponCodePill';
import { AnalyticsManager } from '../../../analytics';
import AppConfig from '../../../config/AppConfig';
import Alert from '../../Alert/Alert';
import OrderItemsAccordion from './OrderItemsAccordion';
import { isBlank } from '../../../utils/BooleanUtility';

export default class PaymentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };

    if (
      Platform.OS === 'android' &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }

    this.getItemData = _.memoize((alert) => ({
      ...alert,
      ctaText: '',
      ctaUrl: '',
    }));
  }

  toggleShowDetails = () => {
    const { showDetails } = this.state;
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    AnalyticsManager.logEvent('details_view', {
      type: 'payment_details',
      action: !showDetails ? 'expand' : 'collapse',
    });
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
    }));
  };

  paymentDetailsHeader = () => {
    const {
      metaData: { id, totalAmount = '' },
      orderId,
      deliveryDate,
      merchantOrderName,
    } = this.props;
    const { showDetails } = this.state;
    const merchentId = Utility.isBlank(merchantOrderName)
      ? '#'
      : merchantOrderName;
    const idTobeShown = AppConfig.getBooleanValue(
      Config.SHOW_ORDER_PAGE_MERCHENT_ID,
    )
      ? merchentId
      : orderId;
    return (
      <View style={styles.paymentDetailsHeaderContainer}>
        <View style={styles.paymentDetailsHeaderInnerContainer}>
          <Text style={styles.orderId}>{`Order #${idTobeShown}`}</Text>
          <Text style={styles.deliveryDate}>{deliveryDate}</Text>
        </View>
        <View style={styles.bottomContainer}>
          <Text style={styles.amount}>
            {`${CURRENCY_SYMBOL}${totalAmount}`}
          </Text>
          <TouchableOpacity
            onPress={this.toggleShowDetails}
            style={styles.viewDetailsContainer}
            hitSlop={Utility.getHitSlop('likeButton')}
          >
            <Text style={styles.viewDetails}>View Details</Text>
            <Image
              source={showDetails ? images.chevronUp : images.chevronDown}
              style={styles.chevron}
            />
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  orderItem = (props) => {
    const { orderItem } = props;
    return (
      <View style={styles.orderItemContainer}>
        <Text style={styles.orderItem} numberOfLines={1}>
          {`${orderItem.quantity} x ${orderItem.name}`}
        </Text>
        <Text style={styles.orderItemPrice}>
          {orderItem.esp === 'FREE'
            ? orderItem.esp
            : `${CURRENCY_SYMBOL}${
                orderItem.esp * (orderItem.quantity || 1) || 0
              }`}
        </Text>
      </View>
    );
  };

  paymentDetails = () => {
    const {
      metaData: {
        orderItems = [],
        discount = '',
        deliveryFee = '',
        plasticFreePackagingCharges,
        codCharges,
      } = {},
    } = this.props;

    return (
      <View style={styles.paymentDetailsContainer}>
        <View>
          {_.map(orderItems, (orderItem) => {
            return <this.orderItem orderItem={orderItem} key={orderItem.id} />;
          })}
        </View>

        <View style={styles.discountContainer}>
          <Text style={styles.discountText} numberOfLines={1}>
            Delivery Fee
          </Text>
          <Text style={styles.discount}>
            {parseInt(deliveryFee) === 0
              ? 'FREE'
              : `${CURRENCY_SYMBOL}${deliveryFee}`}
          </Text>
        </View>

        {parseInt(codCharges) > 0 && (
          <View style={styles.discountContainer}>
            <Text style={styles.discountText} numberOfLines={1}>
              COD charges
            </Text>
            <Text style={styles.discount}>
              {parseInt(codCharges) === 0
                ? 'FREE'
                : `${CURRENCY_SYMBOL}${codCharges || 0}`}
            </Text>
          </View>
        )}

        <View style={styles.discountContainer}>
          <Text style={styles.discountText} numberOfLines={1}>
            Packaging charges
          </Text>
          <Text style={styles.discount}>
            {parseInt(plasticFreePackagingCharges) === 0
              ? 'FREE'
              : `${CURRENCY_SYMBOL}${plasticFreePackagingCharges || 0}`}
          </Text>
        </View>
      </View>
    );
  };

  divider = () => {
    return <View style={styles.divider} />;
  };

  grandTotal = () => {
    const { metaData: { totalAmount = '' } = {} } = this.props;
    return (
      <View style={styles.grandTotalContainer}>
        <Text style={styles.grandtotalText} numberOfLines={1}>
          Grand Total
        </Text>
        <Text style={styles.totalAmount}>
          {`${CURRENCY_SYMBOL}${totalAmount}`}
        </Text>
      </View>
    );
  };

  coupons = () => {
    const { metaData: { coupons = [] } = {} } = this.props;
    return (
      <View style={styles.couponsContainer}>
        <Text style={styles.couponAppliedText}>{`Coupon(s) applied: `}</Text>
        {coupons.map((coupon) => {
          return (
            <CouponCodePill
              couponCode={coupon.couponCode}
              style={styles.couponCodePill}
            />
          );
        })}
      </View>
    );
  };

  paidBy = () => {
    const { metaData: { paymentMethod = '' } = {} } = this.props;
    return (
      <View style={styles.paidByContainer}>
        <Image source={images.success} style={styles.paidByImage} />
        <Text style={styles.paidByText}>{`${paymentMethod || 'COD'}`}</Text>
      </View>
    );
  };

  savings = () => {
    const { metaData: { discount = '' } = {} } = this.props;
    if (parseInt(discount) <= 0) {
      return null;
    }
    return (
      <View style={styles.savingsContainer}>
        <Text style={styles.discountText} numberOfLines={1}>
          Total Savings
        </Text>
        <Text style={styles.discount}>{`${CURRENCY_SYMBOL}${discount}`}</Text>
      </View>
    );
  };

  renderRefundRequests = () => {
    const { refundRequests } = this.props;

    return refundRequests.map((refundRequest) => {
      if (isBlank(refundRequest.alert)) {
        return null;
      }
      return (
        <View key={refundRequest.id}>
          <this.divider />
          <Alert itemData={this.getItemData(refundRequest.alert)} fullWidth />
          <Text style={styles.refundReason}>
            {refundRequest.presentableText}
          </Text>
          <OrderItemsAccordion skus={refundRequest.skuItems} />
        </View>
      );
    });
  };

  render() {
    const { showDetails } = this.state;

    return (
      <View style={styles.container}>
        <this.paymentDetailsHeader />
        {showDetails && (
          <>
            <this.paymentDetails />
            <this.divider />
            <this.grandTotal />
            <this.divider />
            <this.savings />
            <this.coupons />
            <this.paidBy />
          </>
        )}

        <this.renderRefundRequests />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingBottom: 3,
  },
  paidByContainer: {
    backgroundColor: colors.background,
    width: Utility.getScreenWidth() - 24,
    height: 36,
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    paddingLeft: 20,
    marginTop: 12,
  },
  paidByImage: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    marginRight: 12,
  },
  paidByText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  couponCodePill: {
    fontFamily: 'Roboto-Medium',
    fontSize: 10,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.ultraDarkBlue,
    marginHorizontal: 2,
    marginTop: 6,
    borderColor: '#01B460',
    backgroundColor: '#F2FBF7',
    paddingHorizontal: 6,
    paddingVertical: 2,
    borderRadius: 2,
  },
  couponAppliedText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  couponsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: 12,
  },
  grandTotalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  grandtotalText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  totalAmount: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  divider: {
    width: '100%',
    height: 1,
    backgroundColor: '#EFEFEF',
    marginVertical: 12,
  },
  paymentDetailsHeaderContainer: {
    flexDirection: 'column',
    backgroundColor: colors.white,
    paddingVertical: 12,
  },
  paymentDetailsHeaderInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  orderId: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  deliveryDate: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  bottomContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 4,
  },
  amount: { fontSize: 12, fontFamily: 'Roboto-Medium', color: '#979BAA' },
  viewDetailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  viewDetails: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  chevron: {
    height: 12,
    width: 12,
    resizeMode: 'contain',
    tintColor: colors.cta.lightBlue,
    marginLeft: 8,
  },
  orderItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 14,
  },
  orderItem: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  orderItemPrice: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  paymentDetailsContainer: { backgroundColor: colors.white },
  discountContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 14,
  },
  discountText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    width: '80%',
  },
  discount: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.foxyBlack,
  },
  savingsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.ultraLightGreen,
    marginHorizontal: -8,
    marginTop: -4,
    marginBottom: -8,
    paddingVertical: 8,
    paddingHorizontal: 8,
    borderRadius: 4,
  },
  refundReason: {
    color: colors.subtitle,
    fontFamily: 'Roboto-Medium',
    fontSize: 14,
    marginBottom: 8,
  },
});
