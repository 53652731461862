import { Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import Config from '../../libraries/ReactNativeConfig';
import { useDispatch } from 'react-redux';
import { TextField } from 'react-native-material-textfield';
import { AddressStyles } from '../../components/cart/styles';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import colors from '../../theme/Colors';
import { useAuthToken } from '../../lib/hooksUtil';
import NavigationService from '../../navigator/NavigationService';
import { insertMobileNumber, requestOTP } from '../../actions/LoginActions';
import { AddressBeforeLoginAppConfig, DEFAULT_DEVICE_INFORMATION_OBJECT, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { isShopifyAddressExist } from '../../actions/ActionTypes';
import RemoteConfig from '../../utils/RemoteConfig';
import AppConfig from '../../config/AppConfig';
import MyCountryPicker from '../../components/onboarding/CountryPickerModal';
import Utility from '../../utils/Utility';

const isInternationalChoiceEnabled = AppConfig.getBooleanValue(Config.ENABLE_INTERNATIONAL_CHOICE);

export default function PhoneNumberInput({
  label,
  phoneNumber,
  phoneInputCount,
  handleChangeText,
  phoneRef,
  isMobileNoEmpty,
  phoneNoError,
  moveToField,
  onFocus,
  proceedWithAddressAdd,
  addressBeforeLogin,
  setInitialLoginScreenAsOTP,
  changeCountryCode,
  countryCode,
}) {
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [verifyPhoneDisclaimer, setVerifyPhoneDisclaimer] = useState('');

  const dispatch = useDispatch();
  const authToken = useAuthToken();

  useEffect(() => {
    if (!AppConfig.getBooleanValue(Config.SHOW_VERIFY_PHONE_ON_ADDRESS_PAGE)) return;
    if (authToken) return;
    isShopifyAddressExistForPhoneNumber(phoneNumber);
  }, [phoneNumber, authToken]);

  useEffect(() => {
    if (showVerifyPhone && authToken) {
      proceedWithAddressAdd();
    }
  }, [showVerifyPhone, authToken]);

  useEffect(() => {
    const disclaimer = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.verify_phone_disclaimer_on_address_page,
    );
    setVerifyPhoneDisclaimer(disclaimer);
  }, []);

  const isShopifyAddressExistForPhoneNumber = (phone) => {
    if (phone.length === 10) {
      dispatch(isShopifyAddressExist(phone, (success, response) => {
        if (success) {
          const { exists = false } = response;
          setShowVerifyPhone(Boolean(exists));
        }
      }));
    } else {
      setShowVerifyPhone(false);
    }
  };

  const navigateToDestination = () => {
    if (!AddressBeforeLoginAppConfig) {
      return;
    }
    NavigationService.navigate(Config.POST_LOGIN_NAVIGATION_ROUTE);
  };

  const onboardingParams = addressBeforeLogin
    ? { furtherAction: navigateToDestination, addressBeforeLogin }
    : {};

  const handleVerifyMobile = () => {
    if (authToken) return;
    if (!showVerifyPhone) return;
    const { phoneNumber: formattedPhoneNumber } = Utility.phoneNumberFormatter(phoneNumber);

    dispatch(insertMobileNumber(formattedPhoneNumber, DEFAULT_DEVICE_INFORMATION_OBJECT));
    if (setInitialLoginScreenAsOTP && addressBeforeLogin) {
      setInitialLoginScreenAsOTP(true, true);
    } else {
      NavigationService.renderOnboarding(onboardingParams);
    }
  };

  const message = addressBeforeLogin
    ? 'You are a registered user. Login now to see your rewards!'
    : verifyPhoneDisclaimer;
  const buttonCta = addressBeforeLogin ? 'Login' : 'Verify';

  const verifyMobile = () => {
    if (!showVerifyPhone) return null;
    return (
      <RoundedButton
        buttonText={buttonCta}
        buttonTextColor={colors.white}
        buttonColor={colors.primaryActionBackgroundColor}
        buttonTextStyle={AddressStyles.verifyPhoneButtonText}
        buttonAction={handleVerifyMobile}
      />
    );
  };

  const renderLeftAccessory = () => {
    if (!AppConfig.getBooleanValue(Config.ENABLE_INTERNATIONAL_CHOICE))
      return null;
    return (
      <MyCountryPicker
        updateCountryCode={changeCountryCode}
        countryCode={countryCode}
      />
    );
  };

  const handleFocus = () => onFocus(label, 'autofill');
  const handleSubmitEditing = () => moveToField('userName');

  return (
    <>
      <TextField
        testID="phone-number-input"
        accessibilityLabel="phone-number-input"
        label={isInternationalChoiceEnabled ? undefined : label}
        lineWidth={1}
        activeLineWidth={1}
        blurOnSubmit={false}
        value={phoneNumber}
        maxLength={isInternationalChoiceEnabled ? undefined : phoneInputCount}
        onChangeText={handleChangeText}
        error={isMobileNoEmpty ? phoneNoError : null}
        renderLeftAccessory={renderLeftAccessory}
        ref={phoneRef}
        renderLeftAccessory={renderLeftAccessory}
        onSubmitEditing={handleSubmitEditing}
        hideLabel={false}
        inputContainerStyle={AddressStyles.phoneFieldInputContainer}
        textContentType="telephoneNumber"
        returnKeyType="done"
        keyboardType="phone-pad"
        onFocus={handleFocus}
        enablesReturnKeyAutomatically
        renderRightAccessory={verifyMobile}
        tintColor={colors.silver}
      />
      { showVerifyPhone && (
        <Text style={AddressStyles.verifyPhoneDisclaimer}>
          {message}
        </Text>
      )}
    </>
  );
}
