import { Image, Text, TouchableOpacity, StyleSheet } from 'react-native';
import React from 'react';
import { isDesktop } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

export default function InfluencerTag({
  value,
  label,
  image,
  handleChipPress,
  influencerTagIds = [],
  disabled = false,
  backgroundColor,
}) {
  const handlePress = () => handleChipPress(value);
  const tagStyle = isDesktop() ? styles.chip : styles.chipStyle;
  return (
    <TouchableOpacity
      key={value}
      style={[
        tagStyle,
        backgroundColor && { backgroundColor },
        influencerTagIds.includes(value) && { backgroundColor: '#f9dabe59' },
      ]}
      onPress={handlePress}
      disabled={disabled}
    >
      <FastImageView
        source={getMinifiedImage(image, styles.chipImage.width)}
        style={styles.chipImage}
      />
      <Text style={styles.chipText}>{label}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  chip: {
    flexDirection: 'row',
    border: '1px solid #dadada',
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
  },
  chipStyle: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#dadada',
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 12,
    marginRight: 8,
    marginBottom: 8,
  },
  chipImage: {
    width: 16,
    height: 16,
    marginRight: 6,
  },
  chipText: {
    fontSize: 12,
    color: colors.foxyBlack,
  },
});
