import React, { useEffect, useRef } from 'react';
import {
  ScrollView,
  View,
  TouchableOpacity,
  Image,
  Text,
  Clipboard,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';
import StyleSheet from 'react-native-media-query';
import Toast from 'react-native-easy-toast';
import { ids, styles } from './styles';
import { isBlank, isDesktop, isNative, isWeb } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { StaticNavigationHeader } from '../shared';
import Header from './Header';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';

export default function SaveProgress(props) {
  const { profile = {} } = useSelector((state) => state.UserAccountInfo);
  const { route: { params: { handle: handleName = '' } = {} } = {} } = props;
  const toastRef = useRef(null);
  const { handle, name } = profile;
  const navigation = useNavigation();

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SAVE_PROGRESS,
    });
  }, []);

  const profileHandle = isBlank(handle) ? handleName : handle;
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const {
    textList = {},
    primary_cta_text = '',
    secondary_cta_text = '',
  } = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_save_progress));
  const headerTitle = headerData['saveProgress']?.title;
  const headerSubtitle = headerData['saveProgress']?.subtitle;
  const handlePress = () => navigation.push('TabNavigator');
  const showCopiedToClipboard = () => {
    toastRef.current.show('Copied to clipboard.', 2000);
  };

  const copyOneLink = () => {
    Clipboard.setString(
      `${isWeb() ? Config.WEB_URL : Config.URI_HOST}/artist/@${profileHandle}`,
    );
    showCopiedToClipboard();
  };
  const onPressPrimary = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventType.INFLUENCER.DERMAT_PROFILE,
      [EventParameterKey.CTA]: primary_cta_text,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SAVE_PROGRESS,
    });
    navigation.push(SCREEN_CONSTANTS.ABOUT_YOU);
  };
  const onPressSecondary = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventType.INFLUENCER.DERMAT_PROFILE,
      [EventParameterKey.CTA]: secondary_cta_text,
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SAVE_PROGRESS,
    });
    navigation.push('Influencer', {
      bioHandle: profileHandle,
    });
  };
  const handleContainer = isNative() ? styles.copyHandleContainer : innerStyles.copyHandlerContainer;
  const trimmedHandle = `${handle}${handle.length > 20 ? '...' : ''}`;
  return (
    <View style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
          <View style={styles.fullWidthContainer}>
            <View style={styles.mediaContainer}>
              <FastImageView
                source={getMinifiedImage(images.dermat_placeholder, innerStyles.image.width)}
                style={innerStyles.image}
              />
              <Text style={innerStyles.title}>Your FoxyRx Page is Ready</Text>
              <View style={handleContainer}>
                <Text
                  style={styles.registrationCompletedSubheading}
                  numberOfLines={1}
                >{` ${Config.URI_HOST}/@${trimmedHandle}`}</Text>
                <TouchableOpacity onPress={copyOneLink} style={styles.copyTextContainer}>
                  <FastImageView
                    source={getMinifiedImage(images.copy_blue, styles.copyImage.width)}
                    style={styles.copyImage}
                  />
                  <Text style={styles.copyText}>Copy</Text>
                </TouchableOpacity>
              </View>
              <Text style={innerStyles.list}>{textList?.header}</Text>
              <Text style={innerStyles.listItem}>{textList?.title}</Text>
              <Text style={innerStyles.listItem}>{textList?.subtitle}</Text>
            </View>
          </View>
        </ScrollView>
        <View style={innerStyles.buttonContainer} dataSet={{ media: innerIds.buttonContainer }}>
          <TouchableOpacity style={styles.button} onPress={onPressPrimary}>
            <Text style={styles.buttonText}>{primary_cta_text}</Text>
          </TouchableOpacity>
          <TouchableOpacity style={innerStyles.button} onPress={onPressSecondary}>
            <Text style={innerStyles.buttonText}>{secondary_cta_text}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Toast style={innerStyles.toast} ref={toastRef} />
    </View>
  );
}

const { ids: innerIds, styles: innerStyles } = StyleSheet.create({
  image: {
    height: 375,
    width: 188,
    alignSelf: 'center',
  },
  title: {
    fontFamily: 'Roboto-Bold',
    fontSize: 24,
    color: colors.black,
    marginTop: 20,
    alignSelf: 'center',
  },
  text: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: colors.black,
    alignSelf: 'center',
  },
  list: {
    marginVertical: 16,
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: colors.black,
    paddingHorizontal: 8,
    // alignSelf: 'center',
  },
  listItem: {
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    color: colors.black,
    marginHorizontal: 10,
  },
  button: {
    backgroundColor: '#C4C6C7',
    borderRadius: 4,
    alignItems: 'center',
    marginTop: 10,
  },
  buttonText: {
    color: colors.black,
    fontSize: 18,
    marginVertical: 12,
  },
  buttonContainer: {
    paddingBottom: 20,
    paddingTop: 12,
    paddingHorizontal: 12,
    zIndex: 5,
    '@media (min-width: 768px)': {
      width: 480,
      borderTop: '0.5px solid #dfe0e1',
    },
  },
  toast: { position: 'absolute', bottom: 20 },
  copyHandlerContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10,
  },
});
