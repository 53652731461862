import { useNavigation, useRoute } from '@react-navigation/native';
import React, { useEffect, useRef, useState } from 'react';
import {
  View,
  Text,
  ScrollView,
  Linking,
  FlatList,
  TouchableOpacity,
} from 'react-native';
import { ids, styles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { memoize } from 'lodash';
import {
  getInfluencerDetails,
  getProAccountLinks,
} from '../../actions/InfluencerActions';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import FastImageView from '../FastImageView';
import InfluencerTag from './InfluencerTag';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import { SocialMediaOptions, getProAccountObjectForId } from './OneLinkConfig';
import { ScaleAnimate } from '../shared';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import AddLinkCard from './AddLinkCard';
import ErrorBoundary from '../shared/ErrorBoundary';
import { getScreenWidth } from '../../utils/LayoutUtility';
import Video from '../../libraries/ReactNativeVideo';
import { AnalyticsManager, EventType } from '../../analytics';

export default function OneLinkPage() {
  const navigation = useNavigation();
  const { params: { handle: instagramHandle = '' } = {} } = useRoute();
  const assignRef = useRef(null);
  const dispatch = useDispatch();
  const handle = instagramHandle.replace('@', '') || '';
  const { id: currentUserId = '', profile = {} } = useSelector((state) => state.UserAccountInfo);
  const { pro_referral_url: referralUrl = '' } = profile;
  const [influencerData, setInfluencerData] = useState({});
  const [pageNotFound, setPageNotFound] = useState(false);
  const [proAccountLinks, setProAccountLinks] = useState({});
  const [showIntroVideo, setShowIntroVideo] = useState(false);

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.BIO_PAGE_VIEW, {
      foxy_handle: handle,
      id_of_user: currentUserId,
    });
    fetchInfluencerDetails();
    fetchProAccountLinks();
  }, []);

  const fetchInfluencerDetails = () => {
    if (isPresent(handle)) {
      dispatch(
        getInfluencerDetails(handle, (success, response) => {
          if (success) {
            setInfluencerData(response);
            return;
          }
          setPageNotFound(true);
        }),
      );
    }
  };

  const fetchProAccountLinks = () => {
    dispatch(
      getProAccountLinks((success, response) => {
        if (success) {
          setProAccountLinks(response);
        }
      }),
    );
  };

  const handleSocialMediaUrl = (url) => {
    if (Linking.canOpenURL(url)) {
      Linking.openURL(url);
    }
  };

  const SocialMediaOptionTile = ({ item }) => {
    const {
      image = '',
      title = '',
      backgroundColor = '',
      social_media_platform_id = '',
      identifier = '',
      cta_url = '',
    } = item;
    const { pro_accounts = [] } = influencerData;
    const proAccount = getProAccountObjectForId(
      pro_accounts,
      social_media_platform_id,
    );
    if (isBlank(proAccount) && identifier !== 'foxy') return null;
    const { final_url = '' } = proAccount;
    const tileTitle = proAccount?.button_text || title;
    return (
      <ScaleAnimate onPress={() => handleSocialMediaUrl(final_url || cta_url)}>
        <View style={[styles.oneLinkPageTileContainer, { backgroundColor }]}>
          <FastImageView source={image} style={styles.oneLinkPageTileImage} />
          <Text
            numberOfLines={1}
            ellipseMode='tail'
            style={styles.oneLinkPageTileText}
          >
            {tileTitle}
          </Text>
        </View>
      </ScaleAnimate>
    );
  };

  const ProfileIcon = () => {
    const { image_url = '' } = influencerData;
    if (isBlank(image_url)) return null;
    const { intro_video: { metadata: { videoUrl = '' } = {} } = {} } =
      influencerData;
    return (
      <ScaleAnimate onPress={() => setShowIntroVideo(true)}>
        {isPresent(videoUrl) && (
          <>
            <FastImageView
              source={images.video_play}
              style={styles.videoPlay}
            />
            <FastImageView
              source={images.video_play_container}
              style={styles.profileImageContainer}
            />
          </>
        )}
        <FastImageView source={image_url} style={styles.profileImage} />
      </ScaleAnimate>
    );
  };

  const handleRouteFromLink = (route, slug, path, extra) => {
    navigation.navigate(route, { slug, extra });
  };

  const handleDeeplink = (url) => {
    DynamicLinkManager.handleDynamicLink(url, handleRouteFromLink);
  };

  const IntroVideoComponent = () => {
    if (!showIntroVideo) return null;
    const { intro_video: { metadata: { videoUrl = '' } = {} } = {} } =
      influencerData;
    return (
      <>
        <View
          style={styles.introVideoContainer}
        >
          <Video
            style={{ height: '100%', width: 400 }}
            source={{ uri: videoUrl }}
            resizeMode={'cover'}
            onEnd={() => setShowIntroVideo(false)}
            // ref={assignRef}
          />
        </View>
        <TouchableOpacity
          style={styles.pop_up_close_container}
          onPress={() => setShowIntroVideo(false)}
        >
          <FastImageView
            source={images.pop_up_close}
            style={styles.pop_up_close}
          />
        </TouchableOpacity>
      </>
    );
  };

  if (isBlank(influencerData)) return null;

  const {
    name = '',
    bio = '',
    influencer_tags = [],
    influencer_tag_colors = [],
  } = influencerData;
  const {
    content_links_list: { objects: contentLinks = [] } = {},
    product_links_list: { objects: productLinks } = {},
  } = proAccountLinks;
  return (
    <ErrorBoundary pageNotFound={pageNotFound} hideHeader>
      <ScrollView style={styles.oneLinkScrollView}>
        <View
          style={[styles.oneLinkContainer, styles.oneLink]}
          dataSet={{ media: ids.oneLinkContainer }}
        >
          <FastImageView
            source={images.share_black}
            style={styles.oneLinkPageShareIcon}
          />
          <ProfileIcon />
          <Text style={styles.registrationCompletedHeading}>{name}</Text>
          <View style={styles.chipContainer}>
            {influencer_tags.map((chipObject, index) => (
              <InfluencerTag
                label={chipObject.name}
                image={chipObject.image_url}
                disabled
                backgroundColor={influencer_tag_colors[index]}
              />
            ))}
          </View>
          <Text style={styles.registrationCompletedSubheading}>{bio}</Text>
          <View style={[styles.socialMediaTileContainer, styles.curveDesignImageContainer]}>
            <FastImageView
              source={images.curve_design}
              style={styles.curveDesignImage}
            />
            {SocialMediaOptions.map((item) => {
              return <SocialMediaOptionTile item={item} />;
            })}
            {isPresent(contentLinks) && (
              <>
                <Text style={styles.registrationCompletedHeading}>
                  Fav Content
                </Text>
                <Text
                  style={[
                    styles.registrationCompletedSubheading,
                    styles.contactUsSubheading,
                  ]}
                >
                  By {name}
                </Text>
                <FlatList
                  data={contentLinks}
                  style={styles.productLinksFlatlist}
                  renderItem={({ item }) => (
                    <AddLinkCard
                      image={item.image}
                      title={item.label}
                      url_domain={item.url_domain}
                      onPress={memoize(
                        () => handleDeeplink(item.url),
                        () => [item.url],
                      )}
                    />
                  )}
                  showsHorizontalScrollIndicator={false}
                  horizontal
                />
              </>
            )}
            {isPresent(productLinks) && (
              <>
                <Text style={styles.registrationCompletedHeading}>
                  Fav Products
                </Text>
                <Text
                  style={[
                    styles.registrationCompletedSubheading,
                    styles.contactUsSubheading,
                  ]}
                >
                  By {name}
                </Text>
                <FlatList
                  data={productLinks}
                  style={styles.productLinksFlatlist}
                  renderItem={({ item }) => (
                    <AddLinkCard
                      image={item.image}
                      title={item.label}
                      url_domain={item.url_domain}
                      onPress={memoize(
                        () => handleDeeplink(item.url),
                        () => [item.url],
                      )}
                    />
                  )}
                  showsHorizontalScrollIndicator={false}
                  horizontal
                />
              </>
            )}
            <ScaleAnimate onPress={memoize(() => handleDeeplink(item.url), () => [item.url])}>
              <FastImageView
                source={images.one_link_footer}
                style={styles.oneLinkFooter}
              />
            </ScaleAnimate>
          </View>
        </View>
      </ScrollView>
      <IntroVideoComponent />
    </ErrorBoundary>
  );
}
