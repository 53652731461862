import React, { PureComponent } from 'react';
import { Image, StyleSheet, View, BackHandler } from 'react-native';
import Video from '../../libraries/ReactNativeVideo';
import Utility from '../../utils/Utility';
import images from '../../theme/Images';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import WithNavigation from '../../utils/WithNavigation';

class MediaComponentWithoutControl extends PureComponent {
  constructor(props) {
    super(props);
    const { route } = props;
    this.source = { uri: route.params?.uri };
    this.playerRef = undefined;
  }

  componentDidMount() {
    // Add BackHandler listener
    this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.goBack);
  }

  componentWillUnmount() {
    // Remove BackHandler listener
    if (this.backHandler) {
      this.backHandler.remove();
    }
  }

  saveVideoPlayerRef = (ref) => {
    this.playerRef = ref;
  };

  goBack = () => {
    this.props.navigation?.goBack();
    return true; // Return true to indicate that we've handled the back action
  };

  render() {
    return (
      <View style={styles.container}>
        <Video
          ref={this.saveVideoPlayerRef}
          source={this.source}
          style={styles.video}
          onEnd={this.goBack}
          resizeMode='contain'
        />
        <DebouncedTouchableOpacity
          {...this.props}
          onPress={this.goBack}
          style={styles.buttonContainer}
        >
          <Image source={images.cross} style={styles.cancel} />
        </DebouncedTouchableOpacity>
      </View>
    );
  }
}

export default WithNavigation(MediaComponentWithoutControl);

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
  },
  video: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: colors.black,
  },
  cancel: {
    height: 24,
    width: 24,
    zIndex: 2,
    borderRadius: 16,
    tintColor: colors.black,
    opacity: 1,
  },
  buttonContainer: {
    height: 36,
    width: 36,
    backgroundColor: colors.white,
    opacity: 0.7,
    marginBottom: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 18,
  },
});
