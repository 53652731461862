import {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react';
import { BackHandler } from 'react-native';
import { useSelector } from 'react-redux';
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native';
import AnalyticsManager from '../analytics/AnalyticsManager';
import { isAndroid, isIOS, isPresent, isWeb } from '../utils/BooleanUtility';
import { getCompactSlug, getFullSlugFromName } from '../utils/StringUtility';
import { URL } from '../config/Constants';
import { EnableShopifyDirect } from '../config/Constants';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

export const useHardwareBackKeyPress = (onBackPress) => {
  const navigation = useNavigation();
  useEffect(() => {
    let backHandler;
    if (isAndroid()) {
      backHandler = BackHandler.addEventListener('hardwareBackPress', onHardwareBackKeyPress);
    }
    return () => {
      if (isAndroid()) {
        backHandler.remove();
      }
    };
  }, []);
  const onHardwareBackKeyPress = () => {
    if (typeof onBackPress === 'function') {
      onBackPress();
    } else if (navigation.getState().routes.length < 2) {
      navigation.replace('TabNavigator');
    } else {
      navigation.goBack();
    }
    return true;
  };
};

export const useApiCall = (url, itemData = {}, refetch, analyticsData) => {
  const [data, setData] = useState(itemData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = useSelector((state) => state.UserAccountInfo);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': authToken,
        'x-guest-token': guestAuthToken,
      };

      if (EnableShopifyDirect) {
        headers['x-build-type'] = 'shopify_direct';
      }
      if (isWeb()) {
        headers.Platform = 'web';
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers,
        });
        const statusCode = response.status;

        const jsonData = await response.json();
        if (statusCode >= 200 && statusCode < 300) {
          if (isPresent(analyticsData)) {
            AnalyticsManager.logEvent('item_view', {
              ...analyticsData,
            });
          }

          setData(jsonData);
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (e) {
        setError(`${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url, refetch]);

  return { data, isLoading, error };
};

export const useAuthToken = () => {
  const authToken = useSelector((state) => state.UserAccountInfo.authToken);
  return authToken;
};

export const useEmail = () => {
  const profileEmail = useSelector((state) => state.UserAccountInfo.profile.email);
  const emailVerified = useSelector((state) => state.UserAccountInfo.profile.email_verified);
  const [email, setEmail] = useState(profileEmail || '');

  useEffect(() => {
    if (profileEmail) {
      setEmail(profileEmail);
    }
  }, [profileEmail]);

  return { email, setEmail, emailVerified };
};

export const useIsComponentMounted = () => {
  const isComponentMounted = useRef(false);
  useEffect(() => {
    isComponentMounted.current = true;
    return () => {
      isComponentMounted.current = false;
    };
  }, []);
  return isComponentMounted;
};

export const useShortSlug = (type) => {
  const route = useRoute();
  const navigation = useNavigation();
  useEffect(() => {
    if (isWeb()) {
      const { params = {} } = route;
      const { shortSlug, slug } = params;
      let slugString = slug;
      if (!shortSlug && slug) {
        navigation.setParams({ shortSlug: getCompactSlug(slug) });
      }
      if (!slug && shortSlug) {
        slugString = getFullSlugFromName(shortSlug, type);
        navigation.setParams({ slug: slugString });
      }
    }
  });
};

export const useRemoveParams = (params = []) => {
  const navigation = useNavigation();
  useFocusEffect(
    useCallback(() => {
      navigation.setParams(params.reduce((acc, param) => ({ ...acc, [param]: undefined }), {}));
    }, []),
  );
};

export const usePaginatedListCall = ({ url, hideOos = false, maxPageCount = Number.MAX_SAFE_INTEGER }) => {
  const [page, setPage] = useState(0);
  const [listData, setListData] = useState({});
  const slug = `${url}?page=${page}&hide_oos=${hideOos}`;
  const { data, isLoading, error } = useApiCall(slug);

  useEffect(() => {
    if (page < maxPageCount && isPresent(data) && data.objects.length > 0) {
      const { objects = [] } = listData;
      setListData({ ...data, objects: [...objects, ...data.objects] });
      setPage(page => page + 1);
    }
  }, [data]);
  return { data: listData, isLoading, error };
};

export const useApiCallDeserializer = ({ url, itemData = {} }) => {
  const [data, setData] = useState(itemData);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = useSelector((state) => state.UserAccountInfo);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);

      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-auth-token': authToken,
        'x-guest-token': guestAuthToken,
      };

      if (isWeb()) {
        headers.Platform = 'web';
      }

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers,
        });
        const statusCode = response.status;

        const jsonData = await response.json();
        if (statusCode >= 200 && statusCode < 300) {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          }).deserialize(jsonData)
            .then((data) => setData(data));
        } else {
          throw new Error('Network response was not ok');
        }
      } catch (e) {
        setError(`${e}`);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [url]);

  return { data, isLoading, error };
};

export const usePostApi = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState();
  const [error, setError] = useState(null);
  const [response, setResponse] = useState();

  const {
    authToken,
    guestProfile: { guestAuthToken },
  } = useSelector(state => state.UserAccountInfo);
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'x-auth-token': authToken,
    'x-guest-token': guestAuthToken,
  };

  if (isWeb()) {
    headers.Platform = 'web';
  }

  const postApi = async (url, data) => {
    setLoading(true);
    setError(null);
    setSuccess(false);

    try {
      const result = await fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data),
      });
      const statusCode = result.status;

      const jsonData = await result.json();
      if (statusCode >= 200 && statusCode < 300) {
        setSuccess(true);
        setResponse(jsonData);
      } else {
        throw new Error('Network response was not ok');
      }
    } catch (e) {
      setSuccess(false);
      setError(`${e}`);
    } finally {
      setLoading(false);
    }
  };

  return {
    postApi,
    loading,
    error,
    response,
    success,
  };
};

export const useApiResponseDeserializer = (data) => {
  const [response, setResponse] = useState();
  const [error, setError] = useState(null); // State to handle errors

  useEffect(() => {
    if (!data) return; // Exit if no data is provided

    const deserializer = new JSONAPIDeserializer({
      typeAsAttribute: false,
      pluralizeType: true,
      keyForAttribute: 'camelCase',
    });

    // Function to handle async deserialization
    const deserializeData = async () => {
      try {
        const deserializedData = await deserializer.deserialize(data);
        setResponse(deserializedData);
      } catch (e) {
        setError(e); // Set error state if deserialization fails
        console.error('Error during deserialization:', e);
      }
    };

    deserializeData();
  }, [data]); // Dependency array to re-run effect when `data` changes

  return {
    response,
    error, // Return error for better error handling
  };
};
