import React, { PureComponent } from 'react';
import {
  View,
  Text,
  FlatList,
  StyleSheet,
  TouchableWithoutFeedback,
} from 'react-native';
import _ from 'lodash';
import Config from '../../../libraries/ReactNativeConfig';
import RadioButton from 'react-native-radio-button';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import Utility from '../../../utils/Utility';
import FilterAnalyticsEvents from '../FiltersAnalyticsEvents';
import { TouchableOpacity } from 'react-native';
import images from '../../../theme/Images';
import FastImageView from '../../FastImageView';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../../analytics';

export default class ContentCheckBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      options: this.props.options,
      hideOosProduct: this.props.isHideOosProduct,
    };
  }

  componentDidMount = () => {
    const { options } = this.state;
    const { selectedFilters = [] } = this.props;
    this.setState({
      options: options.map((element) => {
        const isSelected = Utility.isBlank(selectedFilters)
          ? element.name === 'Popularity'
          : Utility.isPresent(
              selectedFilters.filter(
                (filter) => element.value === filter.value,
              ),
            );
        return {
          ...element,
          selected: isSelected,
        };
      }),
    });

    this.props.applyResetFunction(this.onItemReset);
  };

  onItemReset = () => {
    const { options } = this.state;
    this.setState({
      options: options.map((element) => ({ ...element, selected: false })),
    });
  };

  onPressCheckBox = (item, index) => {
    const {
      onFilterSelect,
      criteria_name,
      listId,
      listName,
      entityName = '',
      screenName = '',
      listSlug = '',
    } = this.props;
    const { options } = this.state;
    const { selected } = item; // returns previous state
    const nextItemSelectedState = !selected;
    const listObject = { id: listId, name: listName, slug: listSlug };
    if (nextItemSelectedState) {
      FilterAnalyticsEvents.logFilterValueSelectEvent(
        item,
        criteria_name,
        index,
        listObject,
        entityName,
        screenName,
      );
    } else {
      FilterAnalyticsEvents.logFilterValueUnselectEvent(
        item,
        criteria_name,
        index,
        listObject,
        entityName,
        screenName,
      );
    }
    const toggleOptions = options.map((element) =>
      element.name === item.name
        ? { ...element, selected: true }
        : { ...element, selected: false },
    );
    this.setState({
      options: toggleOptions,
    });
    onFilterSelect(item);
  };

  rowCheckBox = (props) => {
    const {
      item: { name, selected, id },
      item,
      index,
    } = props;
    return (
      <TouchableWithoutFeedback
        onPress={_.memoize(
          () => this.onPressCheckBox(item, index),
          () => [item, index],
        )}
      >
        <View
          style={selected ? style.selectedRowContainer : style.rowContainer}
        >
          <Text
            style={selected ? style.checkboxLabelSelected : style.checkboxLabel}
          >{`${name}`}</Text>
          <RadioButton
            outerColor={
              selected
                ? Config.FILTER_MODAL_RADIO_BUTTON_SELECTED_COLOR
                : colors.subtitle
            }
            innerColor={Config.FILTER_MODAL_RADIO_BUTTON_SELECTED_COLOR}
            isSelected={selected}
            size={12}
            onPress={() => this.onPressCheckBox(item)}
          />
        </View>
      </TouchableWithoutFeedback>
    );
  };

  renderHideOosProductCheckBox = () => {
    const { hideOosProduct } = this.state;
    const saveCardColor = hideOosProduct ? colors.foxyBlack : colors.foxyGreen;
    const checkboxImage = hideOosProduct ? images.black_check_box : '';

    return (
      <TouchableOpacity
        onPress={this.onCheckboxPressed}
        style={style.checkboxView}
      >
        <View style={style.checkbox}>
          <FastImageView
            resizeMode="contain"
            source={checkboxImage}
            height={16}
            width={16}
            style={style.checkboxTick}
            tintColor={Config.FILTER_MODAL_INDICATOR_COLOR}
          />
        </View>
        <Text style={style.checkboxText}>Hide out of stock products</Text>
      </TouchableOpacity>
    );
  };

  fireCheckBoxEvent = () => {
    const { hideOosProduct } = this.state;
    const {
      entityName,
      screenName,
      criteria_name,
      listId,
      listName,
      listSlug,
      onPressHideOosCheckBox,
    } = this.props;
    onPressHideOosCheckBox(hideOosProduct);
    AnalyticsManager.logEvent(EventType.discoveryEvents.FILTER_ACTION, {
      [EventParameterKey.FILTER_TYPE]: criteria_name,
      [EventParameterKey.LIST_ID]: listId,
      [EventParameterKey.LIST_NAME]: listName,
      [EventParameterKey.LIST_SLUG]: listSlug,
      [EventParameterKey.ENTITY_NAME]: entityName,
      [EventParameterKey.SCREEN_NAME]: screenName,
      [EventParameterKey.FILTER_HIDE_OOS]: hideOosProduct,
    });
  };

  onCheckboxPressed = () => {
    const { hideOosProduct } = this.state;
    this.setState({ hideOosProduct: !hideOosProduct }, this.fireCheckBoxEvent);
  };

  renderListViewItems = ({ item, index }) => (
    <this.rowCheckBox item={item} index={index} />
  );

  render() {
    const { options } = this.state;
    return (
      <View style={style.container}>
        <FlatList data={options} renderItem={this.renderListViewItems} />
        <this.renderHideOosProductCheckBox />
      </View>
    );
  }
}

const style = StyleSheet.create({
  container: {
    height: '100%',
  },
  rowContainer: {
    flexDirection: 'row',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 18,
    paddingBottom: 18,
    justifyContent: 'space-between',
  },
  checkboxLabel: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h3,
    color: colors.darkBorder,
  },
  checkboxLabelSelected: {
    fontFamily: 'Roboto-Bold',
    fontSize: size.h3,
    color: Config.FILTER_MODAL_CHECKBOX_LABEL_COLOR,
  },
  selectedRowContainer: {
    flexDirection: 'row',
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: 18,
    paddingBottom: 18,
    justifyContent: 'space-between',
    backgroundColor: colors.lightestGrey,
  },
  checkboxView: {
    height: 20,
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 12,
  },
  checkbox: {
    height: 16,
    width: 16,
    borderRadius: 2,
    borderWidth: 1,
    borderColor: colors.foxyBlack,
    marginRight: 8,
    justifyContent: 'center',
    alignItems: 'center',
  },
  checkboxText: {
    fontFamily: 'Roboto-Regular',
    fontSize: 14,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.black,
  },
  checkboxTick: {
    height: 16,
    width: 16,
  },
});
