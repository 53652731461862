import React, { PureComponent } from 'react';
import {
  View,
  Image,
  TouchableOpacity,
  TextInput,
  Text,
  FlatList,
  ActivityIndicator,
  SafeAreaView,
  Switch,
  Keyboard,
  StyleSheet,
  BackHandler,
  StatusBar,
  KeyboardAvoidingView,
  Platform,
} from 'react-native';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReactNavigationPerformanceView } from '@shopify/react-native-performance-navigation';
import { FlatListPerformanceView } from '../../libraries/ReactNativePerformanceShopify';

import { withEither, withMaybe } from '../../lib/Monads';
import styles from './styles';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import {
  searchItem,
  getEmptySearchData,
  updateSearchHistory,
  getSearchAutoSuggest,
  fetchProduct,
  setSearchResultClicks,
} from '../../actions/ActionTypes';
import EmptySearch from './EmptySearch';
import { addProducts } from '../../actions/UploadPostActions';
import { List } from '../List';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { CartModal } from '../../components/cart';
import FilterUtils, {
  canOpenSortingModal,
} from '../../components/sort-filter-options/FilterUtils';
import {
  NOTIFICATION_REQUEST_MODAL_TYPE,
  REMOTE_CONFIG_KEYS,
  PERMISSION_STORE,
  STATUS_BAR_HEIGHT,
  SEARCH_TAG_SUPPORT,
  OFFERS_URL,
  WWW_WEB_URL,
  LISTS_API_VERSION,
  AnalyticsScreenNames,
  SEP,
} from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import { applyFlashDeal } from '../../actions/ActionTypes';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import FastImageView from '../../components/FastImageView';
import AppConfig from '../../config/AppConfig';
import Config from '../../libraries/ReactNativeConfig';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { goBackWithFallbackHome, navigateToScreen } from '../../utils/NavigationUtility';
import { isDesktop, isWeb, isWebIOS } from '../../utils/BooleanUtility';
import { getCompactSlug } from '../../utils/StringUtility';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import DebouncedPressable from '../../components/shared/DebouncedPressable';
import { getStoreRef } from '../../store/StoreUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import SearchSuggestTile from './SearchSuggestTile';
import RoutinesUtility, { isRoutineLive } from '../../components/Routines/RoutinesUtility';

class Search extends PureComponent {
  searchConstants = {
    switchText: 'Products and Brands only',
  };

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      focus: false,
      isCartVisible: false,
      showFreeProductScreen: false,
      loadMore: false,
      addedProducts: [],
      searchResults: {},
      searchSuggestResults: {},
      showLoader: false,
      showNoResultScreen: false,
      paginationResult: [],
      text: '',
      focus: false,
      isCartVisible: false,
      productAndBrandsOnlySwitchValue: true,
      topSearches: [],
      emptySearchProducts: [],
      emptySearchFeature: {},
      selectedFilters: {},
      text: '',
      focus: false,
      isCartVisible: false,
      showFreeProductScreen: false,
      stickyPosition: -1,
      showFiltersModal: false,
      filters: [],
      quickFilters: [],
      keyboardHeight: 0,
      emptySearchError: false,
      showServerError: false,
      refreshing: false,
      loyaltyPlanEntryPoint: {},
      hideOosProduct: false,
      screenInteractive: false,
      filtersList: {},
    };
    this.onSubmitQueryCache = {};
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.SEARCH);
    this.trace.start();

    this.debouncedSearch = _.debounce(props.searchItem, 500);
    this.debounceHandleLoadMore = _.debounce(this.handleLoadMore, 100);
    this.debouncedSearchSuggest = _.debounce(props.getSearchAutoSuggest, 500);
    const { route } = props;
    this.isPreviousScreenScanProduct =
      route.params?.isPreviousScreenScanProduct ?? false;
    this.isSearch = route.params?.isSearch;
    this.currentContentItemIndex = route.params?.currentContentItemIndex;
    this.searchResults = {};

    this.itemDisplayValueCount = [];
    this.previousScreen = route.params?.previousScreen ?? '';
    this.customGoBack = route.params?.customGoBack;
    this.firstTime = true;
    this.allowLoadMore = true;
    this.componentActive = true;
    this.quickFiltersRef = null;

    this.slug = '';
    this.pageNo = 0;
    this.allowNextPageLoad = true;
    this.selectedQuickFilters = [];
    this.filtersEnabled = false;
    this.prevAppliedFiltersFromModal = [];
    this.listRef = null;
    this.listProductIndex = -1;
    this.isCurrentScreen = false;

    this.navigationHeaderHeight = Utility.isIOS()
      ? STATUS_BAR_HEIGHT.IOS + Utility.topInset
      : STATUS_BAR_HEIGHT.ANDROID;
    this.searchHeaderStyle = [
      styles.navigationHeaderContainer,
      { height: this.navigationHeaderHeight },
    ];
    this.containerStyle = styles.container;
    this.routes = props.navigation.getState().routes;
  }

  componentDidMount() {
    AnalyticsManager.logEvent(EventType.search.SEARCH_OPEN);
    const { getEmptySearchData, navigation } = this.props;
    this.unsubscribeFocus = navigation.addListener(
      'focus',
      this.showScannedStringResult,
    );
    this.unsubscribeBlur = navigation.addListener('blur', this.onDidBlur);
    const { route = {} } = this.props;
    const { params = {} } = route;
    const { q } = params;
    if (q) {
      this.updateQuery(q);
    }
    if (!this.isPreviousScreenScanProduct) {
      getEmptySearchData(this.emptySearchApiCallback);
    }
    this.tabPressListener = navigation.addListener('tabPress', () => {
      if (this.isCurrentScreen) {
        this.scrollPageToTop();
      }
    });
    this.trace.stop();
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.SEARCH,
    });

    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.handleSearchBack,
      );
    }
    this.keyboardDidShowListener = Keyboard.addListener(
      'keyboardDidShow',
      this.keyboardDidShow,
    );
    this.keyboardDidHideListener = Keyboard.addListener(
      'keyboardDidHide',
      this.keyboardDidHide,
    );

    const remoteRequestData = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.ios_notification_request),
    );

    if (Utility.isPresent(remoteRequestData['search_page_title'])) {
      this.displayNotificationRequestModal();
    }
  }

  componentWillUnmount = () => {
    if (Utility.isAndroid()) {
      this.backHandler.remove();
    }
    this.unsubscribeFocus();
    this.unsubscribeBlur();
    this.tabPressListener();
    this.keyboardDidShowListener?.remove();
    this.keyboardDidHideListener?.remove();
  };

  keyboardDidShow = (e) => {
    const { endCoordinates: { height = 0 } = {} } = e;
    this.setState({
      keyboardHeight: height,
    });
  };

  keyboardDidHide = () => {
    this.setState({
      keyboardHeight: 0,
    });
  };

  displayNotificationRequestModal = () => {
    Utility.canDisplayNotificationRequestModal(
      this.props.lastNotificationModalDisplayTime,
      'Store',
      (canDisplay, showNotificationPrompt) => {
        if (canDisplay) {
          this.showNotificationModal(showNotificationPrompt);
        }
      },
    );
  };

  showNotificationModal = (showNotificationPrompt) => {
    const { navigation } = this.props;
    navigation.push('NotificationModal', {
      showNotificationPrompt,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.SEARCH_PAGE,
    });
  };

  emptySearchApiCallback = (success, response) => {
    this.setState({
      emptySearchError: !success,
    });
    if (success) {
      this.setState({
        topSearches: response.top_searches,
        emptySearchProducts: response.most_searched_products,
        emptySearchFeature: response.feature_card,
        loyaltyPlanEntryPoint: response?.loyalty_plan_entry_point,
        screenInteractive: true,
      });
    }
  };

  showFreeProductScreen = () => {
    AnalyticsManager.logEvent('VIEW_PRODUCTS_BANNER_CLICKED', {
      screen: 'empty_search',
    });
    this.props.navigation.navigate('FreeGiftScreen');
  };

  showScannedStringResult = () => {
    const { navigation, route } = this.props;
    this.onDidFocus();
    let isPreviousScreenScanProduct = route.params?.isPreviousScreenScanProduct;
    if (isPreviousScreenScanProduct !== true) {
      isPreviousScreenScanProduct = false;
    }
    const searchString = route.params?.searchString;
    const barcode = route.params?.barcode;
    if (isPreviousScreenScanProduct) {
      this.updateQuery(searchString, barcode, isPreviousScreenScanProduct);
    }
  };

  goBack = () => {
    const { navigation, route } = this.props;
    this.dismissKeyboard();
    if (
      this.isPreviousScreenScanProduct &&
      this.previousScreen === SCREEN_CONSTANTS.COLLECTION
    ) {
      navigation.navigate('ScanProduct');
      return;
    }

    AnalyticsManager.logEvent(EventType.search.SEARCH_ABANDON);
    if (this.routes?.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
  };

  onNavigationBlur = (payload) => {
    const { updateSearchHistory } = this.props;
    const { text } = this.state;
    if (
      !Utility.isBlank(text) &&
      !Utility.isBlank(payload.action) &&
      payload.action.type !== 'Navigation/BACK'
    ) {
      updateSearchHistory(text);
    }
  };

  resetSearchFilters = () => {
    this.slug = '';
    this.pageNo = 0;
    this.filtersEnabled = false;
    this.allowNextPageLoad = true;
    this.selectedQuickFilters = [];
    this.prevAppliedFiltersFromModal = [];
    this.setState({
      selectedFilters: {},
      filters: [],
    });
  };

  searchResultApiCallback = (
    callbackData,
    isPaginatedResult,
    searchQuery,
    fromFilters,
  ) => {
    const { paginationResult, productAndBrandsOnlySwitchValue } = this.state;

    this.setState({
      showLoader: false,
      refreshing: false,
      showServerError: Utility.isBlank(callbackData),
    });

    if (!isPaginatedResult) {
      this.checkEmptySearchObject(callbackData);
    }

    if (isPaginatedResult) {
      this.setState({
        paginationResult: this.state.paginationResult.concat(callbackData),
        showLoader: false,
      });
    } else {
      const listObject = { ...callbackData };
      let { list: objects, position: stickyPosition } =
        Utility.createFilterObjectIfExists(
          listObject.objects,
          true,
          this.filtersEnabled,
          productAndBrandsOnlySwitchValue,
        );
      const listWithFilterIndex = _.findIndex(
        objects,
        (item) =>
          isPresent(item.quick_filters) &&
          (item.content === 'product' || item.content === 'sku'),
      );
      if (
        listWithFilterIndex !== -1 &&
        isBlank(objects[listWithFilterIndex].objects)
      ) {
        objects = Utility.addFiltersEmptyStateInListObject(objects);
      }
      listObject.objects = objects;
      this.listProductIndex = stickyPosition;

      const { filters, quickFilters } =
        FilterUtils.getFiltersFromList(listObject);
      this.setState(
        {
          searchResults: listObject,
          searchSuggestResults: '',
          showLoader: false,
          paginationResult: [],
          stickyPosition,
          filters,
          quickFilters,
        },
        () => {
          if (fromFilters && this.listProductIndex !== -1) {
            try {
              setTimeout(() => {
                if (this.listRef) {
                  this.listRef.scrollToIndex({
                    animated: true,
                    index: this.listProductIndex - 1,
                  });
                }
              }, 200);
            } catch (error) {}
          }
        },
      );
    }

    let itemCount = 0;
    let productItemCount = 0;
    let mediaItemCount = 0;
    let brandItemCount = 0;
    let listItemCount = 0;
    let artistItemCount = 0;

    callbackData.objects.forEach((element, index) => {
      itemCount += element.display_count;
    });

    callbackData.objects.forEach((element, index) => {
      if (element.content === 'media') {
        mediaItemCount += element.display_count;
      }
      if (element.content === 'product') {
        productItemCount += element.display_count;
      }
      if (element.content === 'brand') {
        brandItemCount += element.display_count;
      }
      if (element.content === 'list') {
        listItemCount += element.display_count;
      }
      if (element.content === 'artist') {
        artistItemCount += element.display_count;
      }
      if (isPaginatedResult) {
        this.itemDisplayValueCount.push(element.display_count);
      }
    });

    const meta = {
      [EventParameterKey.SEARCH_QUERY]: searchQuery,
      [EventParameterKey.SEARCH_FILTER]: '',
      [EventParameterKey.RESULT_COUNT]: itemCount,
      [EventParameterKey.PRODUCTS_RESULT_COUNT]: productItemCount,
      [EventParameterKey.LISTS_RESULT_COUNT]: listItemCount,
      [EventParameterKey.BRANDS_RESULT_COUNT]: brandItemCount,
      [EventParameterKey.ARTISTS_RESULT_COUNT]: artistItemCount,
      [EventParameterKey.MEDIA_RESULT_COUNT]: mediaItemCount,
    };
    AnalyticsManager.logEvent(EventType.search.SEARCH_COMPLETE, meta);
    AnalyticsManager.logEvent(
      EventType.googleRemarketingEvents.VIEW_SEARCH_RESULT,
      { [EventParameterKey.SEARCH_QUERY]: searchQuery },
    );
    if (isWeb()) {
      AnalyticsManager.logPixelStandardEvent(EventType.FB_PIXEL.Search, { search_string: searchQuery });
    }
    if (!isPaginatedResult && Utility.isPresent(searchQuery)) {
      updateSearchHistory(searchQuery);
    }
  };

  memoizedOnSubmit = () => {
    return (slug = '', fromFilters = false) => {
      if (!this.onSubmitQueryCache[slug]) {
        this.onSubmitQueryCache[slug] = () => this.onSubmit(slug, fromFilters);
      }
      return this.onSubmitQueryCache[slug];
    };
  };

  onSubmit = (
    slug = undefined,
    fromFilters = false,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation, route, updateSearchHistory } = this.props;
    const {
      productAndBrandsOnlySwitchValue,
      text = '',
      searchSuggestResults: { suggestions = [] } = {},
    } = this.state;
    const searchQuery = this.state.text;
    this.setState({ showLoader: true, paginationResult: [] });

    if (
      searchQuery?.toLowerCase().trim() ===
      suggestions[0]?.name?.toLowerCase().trim()
    ) {
      AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.SEARCH_FILTER]: '',
        [EventParameterKey.QUERY_SOURCE]: 'exact_match',
      });
      this.updateQuerySelect(searchQuery, suggestions[0], 0, false);
      this.setState({ showLoader: false, paginationResult: [] });
      return;
    }

    const isPreviousScreenScanProduct =
      route.params?.isPreviousScreenScanProduct;

    const data = {
      query: searchQuery,
      isPreviousScreenScanProduct,
      filterParams: this.getSelectedFilterParams(),
    };

    if (Utility.isPresent(text)) {
      updateSearchHistory(text);
    }

    AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, {
      [EventParameterKey.SEARCH_QUERY]: text,
      [EventParameterKey.SEARCH_FILTER]: '',
      [EventParameterKey.QUERY_SOURCE]: EventParameterValue.MEDIUM.MANUAL,
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SEARCH,
      {
        [EventParameterKey.SEARCH_TERM]: searchQuery,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_SEARCHED,
      null,
      {
        [EventParameterKey.FB.EVENT_PARAM_SEARCH_STRING]: searchQuery,
      },
    );

    if (Utility.isPresent(this.state.searchResults)) {
      this.setState({
        searchItem: Utility.addShimmerInListObject(this.state.searchResults),
      });
    }

    this.debouncedSearch(
      data,
      isPreviousScreenScanProduct,
      productAndBrandsOnlySwitchValue,
      hideOosProduct,
      (callbackData) =>
        this.searchResultApiCallback(
          callbackData,
          false,
          searchQuery,
          fromFilters,
        ),
    );
  };

  getSelectedFilterParams = () => {
    const { selectedFilters } = this.state;
    const selectedFilterObj = {};
    if (isPresent(selectedFilters)) {
      const selectedFilterArray =
        Utility.convertSelectedFiltersObjectIntoArray(selectedFilters);
      selectedFilterArray.forEach((element) => {
        selectedFilterObj[`${element.criteria_name}[]`] = selectedFilterObj[
          `${element.criteria_name}[]`
        ]
          ? [...selectedFilterObj[`${element.criteria_name}[]`], element.value]
          : [element.value];
      });
    }
    return selectedFilterObj;
  };

  emptyScreenCondition = () =>
    this.state.text === '' ||
    Utility.isBlank(this.state.text) ||
    this.state.text.length <= 1;

  productSearchEmptyState = () => <View />;

  searchScreen = withEither(this.emptyScreenCondition, EmptySearch)(List);

  showScanButtonCondition = () =>
    Utility.isPresent(this.state.text) ||
    AppConfig.getBooleanValue(Config.DISABLE_SCAN_FOR_SEARCH);

  clearTextInputButtonCondition = () => Utility.isBlank(this.state.text);

  clearTextInputButton = withMaybe(this.clearTextInputButtonCondition)(
    TouchableOpacity,
  );

  scanButton = withMaybe(this.showScanButtonCondition)(TouchableOpacity);

  textChanged = (text = '') => {
    const { searchSuggestResults } = this.state;
    if (!isDesktop()) {
      this.textInputRef.focus();
    }
    this.setState({ text, showNoResultScreen: false, searchResults: {} });
    this.resetSearchFilters();
    if (text.length > 1) {
      const searchQuery = text;
      this.pageNo = 0;
      this.allowNextPageLoad = true;
      this.allowLoadMore = true;
      this.setState({ showLoader: Utility.isBlank(searchSuggestResults) });
      this.debouncedSearchSuggest(
        encodeURIComponent(searchQuery),
        (callbackData) => {
          this.setState({
            searchSuggestResults: callbackData,
            showLoader: false,
          });
        },
      );
    } else {
      this.setState({ searchSuggestResults: '' });
    }
  };

  onFocus = () => {
    // this shows auto suggestion ui
    this.setState({
      focus: true,
    });
  };

  onFocusRemoved = () => {
    // this removes auto suggestion/search ui
    this.setState({
      focus: false,
    });
  };

  checkEmptySearchObject = (callbackData = {}) => {
    let noObjectCount = 0;
    callbackData.objects.map((item) => {
      if (item.objects.length === 0) {
        noObjectCount++;
      }
    });
    if (noObjectCount === callbackData.objects.length) {
      const { id, type, slug } = callbackData;
      const { text } = this.state;
      AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_EMPTY, {
        [EventParameterKey.ID]: id,
        [EventParameterKey.TYPE]: type,
        [EventParameterKey.SEARCH_QUERY]: text,
        [EventParameterKey.SLUG]: slug,
      });
      this.setState({ showNoResultScreen: true });
    } else {
      this.setState({ showNoResultScreen: false });
    }
  };

  searchQuery = (
    query,
    barcode,
    isPreviousScreenScanProduct,
    itemFilter,
    type,
  ) => {
    const { productAndBrandsOnlySwitchValue } = this.state;
    const data = {
      query: query,
      barcode,
      type,
      filterUrls: this.slug,
    };
    this.setState({ showLoader: true });
    this.allowLoadMore = true;
    if (!productAndBrandsOnlySwitchValue) {
      this.resetSearchFilters();
    }

    this.debouncedSearch(
      data,
      isPreviousScreenScanProduct,
      productAndBrandsOnlySwitchValue,
      this.state.hideOosProduct,
      (callbackData) =>
        this.searchResultApiCallback(
          callbackData,
          false,
          query,
          this.filtersEnabled,
        ),
    );
  };

  updateQuery = (query = '', barcode = '', isPreviousScreenScanProduct = false) => {
    Keyboard.dismiss();
    AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, {
      [EventParameterKey.SEARCH_QUERY]: query,
      [EventParameterKey.SEARCH_FILTER]: '',
      [EventParameterKey.QUERY_SOURCE]: EventParameterValue.MEDIUM.OPTION_PILL,
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SEARCH,
      {
        [EventParameterKey.SEARCH_TERM]: query,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_SEARCHED,
      null,
      {
        [EventParameterKey.FB.EVENT_PARAM_SEARCH_STRING]: query,
      },
    );
    this.setState(
      {
        text: isPreviousScreenScanProduct ? query[0] : query,
      },
      this.searchQuery(query, barcode, isPreviousScreenScanProduct),
    );
  };

  updateQuerySelect = (
    query = '',
    item,
    itemIndex,
    isFlashDealAvailable = false,
  ) => {
    const {
      updateSearchHistory,
      navigation,
      applyFlashDeal,
      setSearchResultClicks,
    } = this.props;
    const { text } = this.state;
    const { type = '', id = '' } = item;
    Keyboard.dismiss();
    const data = {
      query: query,
      type: type,
      id: id,
    };
    setSearchResultClicks(data);
    if (item.type === 'product') {
      applyFlashDeal({
        type: 'product',
        id: item?.id,
      });
    }

    const navigationSource = { source: `${query}${SEP}search` };

    if (
      (Utility.isPresent(item.id) || Utility.isPresent(item.slug)) &&
      Utility.isPresent(item.type)
    ) {
      AnalyticsManager.logEvent(EventType.search.SEARCH_AUTOCOMPLETE_CLICK, {
        [EventParameterKey.SEARCH_QUERY]: text,
        [EventParameterKey.AUTOCOMPLETE_RESULT]: query,
        [EventParameterKey.ID]: item.id,
        [EventParameterKey.PRODUCT_ID]: item.id,
        [EventParameterKey.TYPE]: item.type,
        isFlashDealAvailable,
        [EventParameterKey.ITEM_POSITION]: itemIndex,
      });
      navigateOnSearchQuerySelect({
        navigation,
        item,
        previousScreen: this.props?.previousScreen,
        query,
        navigationSource,
      });
      if (item.type === 'foxy_video') {
        this.setState({ text: query, searchSuggestResults: '' }); // TODO: have to remove this and navigate to media page
      }
    } else {
      this.setState({ text: query, searchSuggestResults: '' });
      this.searchQuery(query, null, null, null, item.type);
    }
    AnalyticsManager.logEvent(EventType.search.SEARCH_INITIATE, {
      [EventParameterKey.SEARCH_QUERY]: query,
      [EventParameterKey.SEARCH_FILTER]: item.type,
      [EventParameterKey.QUERY_SOURCE]: EventParameterValue.MEDIUM.AUTOCOMPLETE,
    });
    AnalyticsManager.logFirebaseEvent(
      EventType.googleRemarketingEvents.SEARCH,
      {
        [EventParameterKey.SEARCH_TERM]: query,
      },
    );
    AnalyticsManager.logFBStandardEvent(
      EventType.FB.EVENT_NAME_SEARCHED,
      null,
      {
        [EventParameterKey.FB.EVENT_PARAM_SEARCH_STRING]: query,
      },
    );
    if (Utility.isPresent(text)) {
      updateSearchHistory(text);
    }
  };

  openCamera = () => {
    const { navigation } = this.props;
    const { currentContentItemIndex } = this;

    navigation.navigate('AddProducts', {
      currentContentItemIndex,
      isPreviousScreenSearch: true,
    });
  };

  toggleCartVisibility = () => {
    this.props.navigation.push({ source: 'search' }, 'Cart', {
      source: 'search',
    });
  };

  onCartDismiss = () => {
    this.setState({ isCartVisible: false, showFreeProductScreen: false });
  };

  handleSearchBack = () => {
    if (!this.componentActive) {
      return true;
    }
    const { text } = this.state;
    if (text !== '') {
      this.textChanged('');
      this.resetSearchFilters();
      return true;
    }
    this.goBack();
    return true;
  };

  saveTextInputRef = (ref) => {
    this.textInputRef = ref;
  };

  onClearTextPress = () => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_ABANDON, {
      search_query: this.state.text,
    });
    this.textChanged('');
  };

  onScanHeaderIconPress = () => {
    const { navigation } = this.props;
    navigation.navigate('ScanUSN');
  };

  searchHeader = () => {
    const onSubmitEditing = this.memoizedOnSubmit()(this.slug, false);

    const placeholderText =
      Config.APP_NAME !== 'Foxy'
        ? 'Search products, categories, etc'
        : 'Search products, videos, artists etc';
    return (
      <View style={this.searchHeaderStyle}>
        <DebouncedPressable
          onPress={this.handleSearchBack}
          style={styles.navigationBackButtonContainer}
          hitSlop={Utility.getHitSlop('backButton')}
        >
          <Image
            testID='search-back-btn'
            accessibilityLabel='search-back-btn'
            source={images.navigationWhiteBackIcon}
            style={styles.searchHeaderBackButton}
          />
        </DebouncedPressable>
        <TextInput
          testID='search-input-field'
          accessibilityLabel='search-input-field'
          style={styles.textInputStyle}
          allowFontScaling={false}
          placeholder={placeholderText}
          placeholderTextColor={colors.silver}
          onChangeText={this.textChanged}
          value={this.state.text}
          onSubmitEditing={onSubmitEditing}
          autoFocus
          returnKeyType='search'
          enablesReturnKeyAutomatically
          ref={this.saveTextInputRef}
        />

        <this.clearTextInputButton
          onPress={this.onClearTextPress}
          hitSlop={Utility.getHitSlop()}
          style={styles.clearTextContainer}
        >
          <Image source={images.cross} style={styles.clearText} />
        </this.clearTextInputButton>
        {!isWeb() && (
          <this.scanButton
            onPress={this.onScanHeaderIconPress}
            hitSlop={Utility.getHitSlop()}
            style={styles.clearTextContainer}
          >
            <FastImageView
              source={images.salon.search_qr_scan}
              style={styles.scanQr}
            />
          </this.scanButton>
        )}
      </View>
    );
  };

  searchInProductAndBrandsToggler = () => {
    const {
      productAndBrandsOnlySwitchValue,
      searchResults,
      searchSuggestResults,
    } = this.state;
    const background = '#ffffff';
    if (
      Utility.isPresent(searchResults) ||
      AppConfig.getBooleanValue(Config.DISABLE_PRODUCTS_AND_BRANDS_ONLY_SEARCH)
    ) {
      return null;
    }
    if (Utility.isBlank(searchSuggestResults)) {
      this.firstTime = false;
      return null;
    }
    return (
      <View
        style={[styles.storeSwitchContainer, { backgroundColor: background }]}
      >
        <Text style={styles.searchInStoreText}>
          {this.searchConstants.switchText}
        </Text>
        <Switch
          value={productAndBrandsOnlySwitchValue}
          style={[
            styles.storeSwitch,
            { transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }] },
          ]}
          thumbTintColor={Utility.isIOS() ? null : 'rgba(145, 211, 69)'}
          trackColor={
            Utility.isIOS()
              ? null
              : { true: 'rgba(145, 211, 69)', false: 'rgba(0, 0, 0, 0.2)' }
          }
          onValueChange={this.toggleSwitch}
          tintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
          onTintColor={Utility.isIOS() ? 'rgba(145, 211, 69)' : null}
        />
      </View>
    );
  };

  toggleSwitch = (value) => {
    this.setState({
      productAndBrandsOnlySwitchValue: value,
    });
  };

  toggleSearchResultSwitch = (value) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_TOGGLE_BUTTON_CLICK, { is_checked: value });
    this.pageNo = 0;
    this.setState(
      {
        productAndBrandsOnlySwitchValue: value,
        loadMore: false,
      },
      () => {
        this.searchQuery(this.state.text, null, null, null, null);
      },
    );
  };

  searchSuggestFooterComponent = () => {
    const { keyboardHeight, searchSuggestResults } = this.state;
    if (Utility.isBlank(searchSuggestResults)) {
      return null;
    }
    const footerStyle = { height: keyboardHeight };
    return <View style={footerStyle} />;
  };

  searchSuggest = () => {
    const {
      searchSuggestResults = {},
      text,
      searchResults,
      productAndBrandsOnlySwitchValue,
      keyboardHeight,
    } = this.state;
    const { suggestions = [] } = searchSuggestResults;
    if (Utility.isBlank(text) || text.length <= 1) {
      return null;
    }
    if (Utility.isPresent(searchResults)) {
      return null;
    }

    let searchSuggestArray = [];

    searchSuggestArray = searchSuggestResults.suggestions;

    const searchSuggestContainerStyle =
      Utility.isBlank(searchSuggestResults) || !keyboardHeight
        ? styles.searchSuggestContainer
        : styles.searchSuggestContainerWithKeyboard;

    if (isWebIOS()) {
      return (
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={{ flex: 1 }}
        >
          <FlatList
            data={suggestions}
            renderItem={this.renderSearchSuggest}
            initialNumToRender={20}
            keyboardShouldPersistTaps
            extraData={productAndBrandsOnlySwitchValue}
            contentContainerStyle={styles.autosuggestContainer}
          />

        </KeyboardAvoidingView>
      );
    }
    return (
      <FlatListPerformanceView listName={`${text}_autosuggest_search`}>
        <FlatList
          data={suggestions}
          renderItem={this.renderSearchSuggest}
          ListFooterComponent={this.searchSuggestFooterComponent}
          initialNumToRender={20}
          keyboardShouldPersistTaps
          extraData={productAndBrandsOnlySwitchValue}
          contentContainerStyle={styles.autosuggestContainer}
        />
      </FlatListPerformanceView>
    );
  };

  renderSearchSuggest = ({ item, index }) => {
    const { todayDeals: { skus = {} } = {} } = getStoreRef().getState();
    return <SearchSuggestTile itemData={item} index={index} skus={skus} updateQuerySelect={this.updateQuerySelect} />;
  };

  NoResultScreen = () => {
    const { showLoader, text } = this.state;
    const displayText =
      'Please check you have the right spelling, or try different keywords.';
    if (!showLoader && text.length > 2) {
      return (
        <View style={styles.NoResultContainer}>
          <Image
            source={images.no_search_result}
            style={{ marginBottom: 10 }}
          />
          <Text
            style={{
              marginBottom: 10,
              fontSize: 16,
              fontFamily: 'Roboto',
              color: colors.foxyBlack,
            }}
          >
            No results found for "{text}"
          </Text>
          <Text
            style={{
              textAlign: 'center',
              fontSize: 12,
              fontFamily: 'Roboto',
              color: colors.foxyBlack,
            }}
          >
            {displayText}
          </Text>
        </View>
      );
    }
    return null;
  };

  dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  onProductTapped = (productID) => {
    const { navigation } = this.props;
    if (this.previousScreen !== SCREEN_CONSTANTS.COLLECTION) {
      this.toggleTappedProducts(productID);
    }
    navigation.navigate('Collection', { scanProductId: productID });
  };

  toggleTappedProducts = (productID) => {
    this.setState({ addedProducts: [productID] }, () => this.goBackOnPreview());
  };

  goBackOnPreview = () => {
    const { navigation } = this.props;
    this.addTaggedProductsOnGlobalState();
    navigation.navigate('Preview', {
      currentContentItemIndex: this.currentContentItemIndex,
    });
  };

  addTaggedProductsOnGlobalState = () => {};

  handleLoadMore = () => {
    if (!this.allowNextPageLoad) {
      this.setState({ loadMore: false });
      return;
    }

    this.pageNo += 1;
    this.setState({
      loadMore: true,
    });
    this.paginateSearchQuery(this.state.text);
  };

  paginateSearchQuery = (query) => {
    const { productAndBrandsOnlySwitchValue, paginationResult } = this.state;
    const { searchItem = () => {} } = this.props;
    const data = {
      query,
      page: this.pageNo,
      filterParams: this.getSelectedFilterParams(),
    };
    searchItem(
      data,
      false,
      productAndBrandsOnlySwitchValue,
      this.state.hideOosProduct,
      (callbackData) => {
        this.allowNextPageLoad = callbackData?.objects?.length >= 20;
        if (Utility.isPresent(callbackData?.objects)) {
          this.setState({
            paginationResult: this.state.paginationResult.concat(
              callbackData.objects,
            ),
            showLoader: false,
            loadMore: this.allowNextPageLoad,
          });
        } else {
          this.setState({
            showLoader: false,
            loadMore: false,
          });
        }

      const productItemCount = 0;
      const mediaItemCount = 0;
      const brandItemCount = 0;
      const listItemCount = 0;
      const artistItemCount = 0;

      const itemCount = 0;

      const meta = {
        [EventParameterKey.SEARCH_QUERY]: query,
        [EventParameterKey.SEARCH_FILTER]: '',
        [EventParameterKey.RESULT_COUNT]: itemCount,
        [EventParameterKey.PRODUCTS_RESULT_COUNT]: productItemCount,
        [EventParameterKey.LISTS_RESULT_COUNT]: listItemCount,
        [EventParameterKey.BRANDS_RESULT_COUNT]: brandItemCount,
        [EventParameterKey.ARTISTS_RESULT_COUNT]: artistItemCount,
        [EventParameterKey.MEDIA_RESULT_COUNT]: mediaItemCount,
      };
      if (this.pageNo === 0) {
        AnalyticsManager.logEvent(EventType.search.SEARCH_COMPLETE, meta);
        AnalyticsManager.logEvent(EventType.googleRemarketingEvents.VIEW_SEARCH_RESULT, {
          [EventParameterKey.SEARCH_QUERY]: query,
        });
        const productResults = (callbackData?.objects || []).filter(({ type }) => type === 'product');
        AnalyticsManager.logGTMTag({
          event: EventType.GTM.VIEW_SEARCH_RESULT,
          value: productResults.reduce((acc, { mrp }) => acc + +mrp, 0),
          items: productResults.map(({ sku_id }) => ({ id: sku_id, google_business_vertical: 'retail' })),
        });
      }
    });
  };

  onDidBlur = () => {
    this.componentActive = false;
    this.isCurrentScreen = false;
  };

  onDidFocus = () => {
    this.componentActive = true;
    this.isCurrentScreen = true;
    // Utility.setStatusBarWhite();
  };

  onPressHideOosCheckBox = (isHideOos) => {
    this.setState({
      hideOosProduct: isHideOos,
    });
  };

  onFiltersPress = (
    item,
    added,
    index,
    resetQuickFilter,
    hideOosProduct = this.state.hideOosProduct,
  ) => {
    const { navigation } = this.props;
    const { searchResults, selectedFilters, filters, text } =
      this.state;
    const { list, list: { id = '', name = '', slug: listSlug = '' } = {} } =
      FilterUtils.getList(searchResults);
    this.setState({
      filtersList: list,
    });
    const shouldOpenSortingModal = canOpenSortingModal(item, selectedFilters);
    if (shouldOpenSortingModal) {
      navigation.navigate('SortingFilteringModal', {
        modalFilters: filters,
        selectedFilters,
        onFilterApplyPress: this.onFiltersPress,
        listName: name,
        listId: id,
        listSlug,
        screenName: SCREEN_CONSTANTS.SEARCH,
        entityName: text,
        listData: searchResults,
        onPressHideOosCheckBox: this.onPressHideOosCheckBox,
        hideOosProduct,
        showFoxyMatchModal: item.criteria_name === 'quick_filter_foxy_match',
        resetQuickFilter,
      });
      return;
    }

    const {
      slug,
      pageNo,
      allowNextPageLoad,
      filtersEnabled,
      groupedSelectedFilters,
      selectedQuickFilters,
      prevAppliedFiltersFromModal,
    } = FilterUtils.onFilterApplied(
      item,
      added,
      index,
      list,
      selectedFilters,
      this.quickFiltersRef,
      this.slug,
      this.prevAppliedFiltersFromModal,
      this.selectedQuickFilters,
      SCREEN_CONSTANTS.SEARCH,
      text,
      hideOosProduct,
    );

    let trimmerSlug = slug;

    if (slug.indexOf('Nan') !== -1) {
      trimmerSlug = slug.substr(slug.indexOf('Nan') + 7, slug.length);
    }

    this.slug = trimmerSlug;
    this.pageNo = pageNo;
    this.allowNextPageLoad = allowNextPageLoad;
    this.selectedQuickFilters = selectedQuickFilters;
    this.filtersEnabled = filtersEnabled;
    this.prevAppliedFiltersFromModal = prevAppliedFiltersFromModal;
    this.setState(
      {
        selectedFilters: groupedSelectedFilters,
      },
      () => {
        this.onSubmit(this.slug, true, hideOosProduct);
      },
    );
  };

  getQuickFiltersListRef = (ref) => {
    this.quickFiltersRef = ref;
  };

  setListRef = (ref) => {
    this.listRef = ref;
  };

  onScrollToIndexFailed = () => {
    setTimeout(() => {
      try {
        if (this.listRef) {
          this.listRef.scrollToIndex({
            animated: true,
            index: 0,
          });
        }
      } catch (error) {}
    }, 200);
  };

  onRefreshSearchResult = () => {
    this.setState({ refreshing: true });
    this.resetSearchFilters();
    setTimeout(this.onSubmit, 0);
  };

  searchResult = (props) => {
    const {
      searchResults,
      text,
      paginationResult,
      productAndBrandsOnlySwitchValue,
      topSearches = [],
      emptySearchProducts = [],
      emptySearchFeature = {},
      stickyPosition,
      selectedFilters,
      showFooterShimmer,
      loadMore,
      emptySearchError,
      refreshing,
      loyaltyPlanEntryPoint = {},
      filtersList = {},
    } = this.state;
    const {
      searchHistory = [],
      emptySearchVideos,
      navigation,
      route,
      onProductPress,
    } = props;
    const isPreviousScreenScanProduct =
      route.params?.isPreviousScreenScanProduct;
    const previousScreen = SCREEN_CONSTANTS.SEARCH;
    return (
      <View removeClippedSubviews={true} style={styles.searchScreen}>
        <this.searchScreen
          search
          searchQuery={text}
          elementItemCounts={this.itemDisplayValueCount}
          itemData={searchResults}
          onPillPress={this.updateQuery}
          searchHistory={searchHistory}
          topSearches={topSearches}
          emptySearchVideos={emptySearchVideos}
          emptySearchProducts={emptySearchProducts}
          emptySearchFeature={emptySearchFeature}
          isPreviousScreenScanProduct={isPreviousScreenScanProduct}
          shuffle={false}
          previousScreen={previousScreen}
          preventReduxFetch
          showFreeProductScreen={this.showFreeProductScreen}
          {...onProductPress}
          paginationResult={paginationResult}
          toggleCartVisibility={this.toggleCartVisibility}
          handleLoadMore={this.debounceHandleLoadMore}
          isSearchResultScreen={!Utility.isBlank(searchResults)}
          productAndBrandsOnlySwitchValue={productAndBrandsOnlySwitchValue}
          toggleSwitch={this.toggleSearchResultSwitch}
          navigation={navigation}
          stickyPosition={stickyPosition}
          selectedFilters={selectedFilters}
          onFiltersPress={this.onFiltersPress}
          getQuickFiltersListRef={this.getQuickFiltersListRef}
          setListRef={this.setListRef}
          filtersEnabled={this.filtersEnabled}
          loadMore={loadMore}
          dismissKeyboard={this.dismissKeyboard}
          onScrollToIndexFailed={this.onScrollToIndexFailed}
          onRefreshSearchResult={this.onRefreshSearchResult}
          refreshing={refreshing}
          loyaltyPlanEntryPoint={loyaltyPlanEntryPoint}
          onPressResetButton={this.onRefreshSearchResult}
          filtersList={filtersList}
        />
      </View>
    );
  };

  noResultCondition = (props) => {
    return props.showNoResultScreen;
  };

  conditionalSearchRendering = withEither(
    this.noResultCondition,
    this.NoResultScreen,
  )(this.searchResult);

  render() {
    const {
      searchResults,
      addedProducts,
      showLoader,
      text,
      showNoResultScreen,
      paginationResult,
      productAndBrandsOnlySwitchValue,
      topSearches = [],
      emptySearchProducts = [],
      emptySearchFeature = {},
      stickyPosition,
      selectedFilters,
      loadMore,
      emptySearchError,
      showServerError,
      screenInteractive,
    } = this.state;
    const {
      searchHistory = [],
      emptySearchVideos,
      navigation,
      route,
    } = this.props;
    const isPreviousScreenScanProduct =
      route.params?.isPreviousScreenScanProduct;
    const isSearch = route.params?.isSearch;
    const previousScreen = SCREEN_CONSTANTS.SEARCH;

    let onProductPress = {};
    if (isPreviousScreenScanProduct) {
      if (isSearch) {
        onProductPress = {
          addedProducts,
          showCustomIcon: false,
          iconName: images,
          onPress: this.onProductTapped,
        };
      } else {
        onProductPress = {
          addedProducts,
          showCustomIcon: true,
          iconName: images,
          onPress: this.onProductTapped,
        };
      }
    }

    return (
      <ReactNavigationPerformanceView
        screenName={AnalyticsScreenNames.SEARCH}
        interactive={screenInteractive}
      >
        {!isDesktop() && <this.searchHeader />}

        <ErrorBoundary
          showServerError={showServerError || emptySearchError}
          hideHeader
          screen_name={AnalyticsScreenNames.SEARCH}
        >
          <SafeAreaView style={styles.container}>
            {!isDesktop() && <this.searchSuggest />}
            {showLoader && (
              <View style={styles.loaderContainer}>
                <ActivityIndicator
                  animating
                  size='large'
                  style={styles.indicator}
                  color={colors.foxyPink}
                />
              </View>
            )}
            <this.conditionalSearchRendering
              {...this.props}
              onProductPress={onProductPress}
              showNoResultScreen={showNoResultScreen}
            />
          </SafeAreaView>
        </ErrorBoundary>
      </ReactNavigationPerformanceView>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchHistory: state.UserAccountInfo.searchHistory,
    lastNotificationModalDisplayTime:
      state.UserAccountInfo.lastNotificationModalDisplayTime,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        searchItem,
        getEmptySearchData,
        updateSearchHistory,
        addProducts,
        getSearchAutoSuggest,
        fetchProduct,
        applyFlashDeal,
        setSearchResultClicks,
      },
      dispatch,
    ),
  };
}

export default withProfiledNavigation(
  connect(mapStateToProps, mapDispatchToProps)(Search),
);

export const SearchHeaderOptions = {
  headerShadowVisible: false,
};

export const navigateOnSearchQuerySelect = ({
  navigation,
  item = {},
  previousScreen,
  query,
  navigationSource,
}) => {
  const {
    slug = '',
    id,
    type,
    bio_handle: bioHandle,
    endsAt = '',
    skuId,
    personalizedSlug = '',
  } = item;
  const slugOrId = slug || id;
  if (type === 'routine') {
    const isLive = isRoutineLive(slug);
    const isExpired = new Date() > RoutinesUtility.getLocalTimeFromUTC(endsAt);
    navigateToScreen({
      navigation,
      type: 'push',
      screen: isLive || isExpired ? 'DuringRoutineDetails' : 'PreRoutineDetails',
      navigationSource,
      params: {
        slug,
        display: 'screen',
        previousScreen,
        isRoutineActive: isLive,
        sku_id: skuId,
        personalizedSlug,
      },
    });
  } else if (type === 'product') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource,
      params: {
        slug: `/api/v2/products/${slugOrId}.json`,
        display: 'screen',
        previousScreen,
      },
    });
  } else if (type === 'brand') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      navigationSource,
      params: {
        slug: `/api/v7/brands/${slug}.json`,
        display: 'screen',
        query,
      },
    });
  } else if (type === 'artist') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Artist',
      navigationSource,
      params: {
        bioHandle,
        display: 'screen',
      },
    });
  } else if (type === 'list') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'MoreItems',
      navigationSource,
      params: {
        slug: `/api/${LISTS_API_VERSION}/lists/${slug}.json`,
        display: 'screen',
      },
    });
  } else if (type === 'categories') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: SCREEN_CONSTANTS.CATEGORY,
      navigationSource,
      params: {
        slug: `${slug}.json`,
        display: 'screen',
        query,
      },
    });
  } else if (type === 'tags') {
    navigation.navigate(navigationSource, 'Tag', {
      slug: `${SEARCH_TAG_SUPPORT}/${slug}.json`,
      display: 'screen',
    });
  } else if (type === 'foxy_store') {
    navigation.push('RetailStore', {
      slug: `/store_api/v1/foxy_stores/${slug}`,
    });
  } else if (type === 'offers') {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: SCREEN_CONSTANTS.OFFER_DETAIL,
      navigationSource,
      params: {
        slug: `${OFFERS_URL}/${slug}`,
      },
    });
  } else if (slug.includes('/products/')) {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      navigationSource,
      params: {
        shortSlug: getCompactSlug(slug),
      },
    });
  } else if (slug.includes('/brands/')) {
    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      navigationSource,
      params: {
        shortSlug: getCompactSlug(slug),
      },
    });
  } else {
    return true;
  }
};
