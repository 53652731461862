import { View, Text } from 'react-native';
import React from 'react';
import { ProductDetailStyles } from './styles';
import { getPriceText } from '../../utils/NumberUtility';
import { isPresent } from '../../utils/BooleanUtility';

const ProductPrice = ({ sp, mrp, discount, removePadding = false }) => {
  const discountToShow = discount?.toString()?.includes('off') ? discount : `${discount}% off`;
  return (
    <View style={[ProductDetailStyles.pricingContainerTop, removePadding && { paddingLeft: 0 }]}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={ProductDetailStyles.spStyle}>{`${getPriceText(parseFloat(sp))} `}</Text>
        {parseFloat(mrp) !== parseFloat(sp) && (
          <Text style={ProductDetailStyles.mrpStyle}>{`${getPriceText(mrp)} `}</Text>
        )}
        {parseFloat(mrp) !== parseFloat(sp) && isPresent(discount) && (
          <View>
            <Text style={ProductDetailStyles.discountRateStyle}>{discountToShow}</Text>
          </View>
        )}
      </View>
    </View>
  );
};

export default React.memo(ProductPrice);
