import React, { useEffect, useState } from 'react';
import { useApiCall } from '../../lib/hooksUtil';
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  ScrollView,
  BackHandler,
} from 'react-native';
import _ from 'lodash';
import applyOffer from '../../hoc/applyOffer';
import {
  API_DOMAIN,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  CURRENCY_SYMBOL,
  LISTS_API_VERSION,
} from '../../config/Constants';
import Utility from '../../utils/Utility';
import size from '../../theme/Fonts';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import MyWalletShimmer from './MyWalletShimmer';
import { StaticNavigationHeader } from '../../components/shared';
import { useNavigation } from '@react-navigation/native';
import { DefaultSizeOfferPromptListing } from '../../components/layout/offerPrompt';
import { getStoreRef } from '../../store/StoreUtility';
import FastImageView from '../../components/FastImageView';
import { useDispatch, useSelector } from 'react-redux';
import PermissionsUtility from '../../utils/PermissionsUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const MyWallet = (props) => {
  const { applyCartCoupon, buttonAction } = props;
  const navigation = useNavigation();
  const state = getStoreRef().getState();
  const { UserAccountInfo: { authToken = '' } = {} } = state;

  const {
    data: { my_wallet_offers_list: myWalletOffersList = {} } = {},
    isLoading,
  } = useApiCall(
    `${API_DOMAIN}/api/${LISTS_API_VERSION}/offers/my_wallet`,
    {},
    authToken,
  );
  const dispatch = useDispatch();

  const lastNotificationModalDisplayTime = useSelector(
    (state) => state.UserAccountInfo.lastNotificationModalDisplayTime,
  );
  const initial_app_opened_at = useSelector(
    (state) => state.UserAccountInfo.initial_app_opened_at,
  );

  useEffect(() => {
    setTimeout(() => {
      displayNotificationRequestModal();
    }, 800);
    // Add an event listener for the hardware back button press
    BackHandler.addEventListener('hardwareBackPress', onHardwareBackKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      BackHandler.removeEventListener(
        'hardwareBackPress',
        onHardwareBackKeyPress,
      );
    };
  }, []);

  const onHardwareBackKeyPress = () => {
    goBack();
    return true;
  };

  const goBack = () => {
    if (navigation.getState().routes.length < 2) {
      navigation.replace('TabNavigator');
      return;
    }
    navigation.goBack();
  };

  const showNotificationModal = (show, showNotification) => {
    navigation.push('NotificationModal', {
      showNotificationPrompt: showNotification,
      type: NOTIFICATION_REQUEST_MODAL_TYPE.WALLET,
    });
  };

  const displayNotificationRequestModal = () => {
    if (
      !PermissionsUtility.shouldShowNotificationModalForCurrentSession(
        SCREEN_CONSTANTS.WALLET_PAGE,
      )
    ) {
      return;
    }
    Utility.canDisplayNotificationRequestModal(
      lastNotificationModalDisplayTime,
      SCREEN_CONSTANTS.WALLET_PAGE,
      (canDisplay, showPrompt) => {
        if (canDisplay) {
          showNotificationModal(true, showPrompt);
          PermissionsUtility.setNotificationModalVisibilityForCurrentSession(
            'wallet_page',
            false,
          );
        }
      },
      initial_app_opened_at,
      true,
    );
  };

  if (isLoading) {
    return <MyWalletShimmer />;
  }

  return (
    <View style={styles.outerContainer}>
      <StaticNavigationHeader
        title=''
        bgColor={colors.teal}
        skipDivider
        overrideBackButtonBgColor
      />

      <ScrollView style={styles.container}>
        <View style={styles.headerContainer}>
          <View style={styles.heading}>
            <FastImageView source={images.wallet} style={styles.walletImage} />
            <Text style={styles.headingText}>My Wallet</Text>
          </View>
          <Text style={styles.subheadingText}>
            Shop, collect, save and repeat!
          </Text>
          <Text style={styles.totalBalanceText}>Total balance</Text>
          <Text style={styles.balance}>{`${CURRENCY_SYMBOL} ${
            myWalletOffersList?.additional_data?.total_balance || 0
          }`}</Text>
        </View>
        <View style={{ alignSelf: 'center' }}>
          <FlatList
            data={myWalletOffersList?.objects}
            renderItem={({ item, index }) => (
              <DefaultSizeOfferPromptListing
                item={item}
                index={index}
                applyCartCoupon={applyCartCoupon}
                isLoading={isLoading}
                buttonAction={buttonAction}
                navigation={navigation}
                inheritStyles={styles}
                showSubHeading
                previousScreen={'my_wallet'}
              />
            )}
            contentContainerStyle={styles.contentContainer}
            showsHorizontalScrollIndicator={false}
            numColumns={isDesktop() ? 2 : 0}
          />
        </View>
      </ScrollView>
    </View>
  );
};

export default applyOffer(MyWallet);

export const styles = StyleSheet.create({
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: isDesktop() ? getScreenWidth() / 2 : getScreenWidth() - 60,
    height: 94,
    borderRadius: 16,
    paddingVertical: 8,
    justifyContent: 'space-between',
    paddingHorizontal: 12,
    alignSelf: 'center',
    marginHorizontal: 12,
    paddingLeft: 52,
    marginLeft: 35,
    marginTop: 12,
  },
  outerContainer: {
    backgroundColor: colors.white,
    flex: 1,
  },
  cardIcon: { height: 42, width: 42 },
  container: { backgroundColor: 'white', flex: 1 },
  contentContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
    marginTop: 28,
    paddingBottom: 32,
  },
  circle: {
    height: 72,
    width: 72,
    borderRadius: 36,
    position: 'absolute',
    top: 10,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
  },
  maxDiscount: { fontSize: 20, fontFamily: 'Roboto-Bold', color: '#173143' },
  headingStyle: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  subheadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  timerText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginVertical: 4,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 10,
  },
  separatorStyle: {
    color: colors.black,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  bottomContainer: { marginBottom: 4 },
  validOnProduct: {
    fontSize: 10,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
  },
  buttonContainer: { width: isDesktop() ? 94 : 91, height: 22 },
  headerContainer: {
    height: 152,
    width: isDesktop() ? '100%' : getScreenWidth(),
    backgroundColor: colors.teal,
    borderBottomRightRadius: 32,
  },
  heading: { flexDirection: 'row', alignSelf: 'center' },
  walletImage: { height: 24, width: 24 },
  headingText: {
    fontSize: 18,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlack,
  },
  subheadingText: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    color: '#151553',
    marginTop: 4,
    alignSelf: 'center',
  },
  totalBalanceText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
    marginTop: 12,
    alignSelf: 'center',
  },
  balance: {
    fontSize: 32,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    alignSelf: 'center',
  },
  bottomButtonContainer: { alignItems: 'flex-end', flex: 1, marginBottom: -2 },
});
