import { PixelRatio } from 'react-native';
import Config from 'react-native-config';
import { takeLatest } from 'redux-saga/effects';
import {
  FETCH_SHOPIFY_PRODUCT_BY_HANDLE,
  FETCH_SHOPIFY_PRODUCT_RECOMMENDATIONS,
  FETCH_SHOPIFY_COLLECTION_BY_HANDLE,
  FETCH_SHOPIFY_SEARCH,
  FETCH_SHOPIFY_SEARCH_SUGGESTIONS,
  FETCH_SHOPIFY_PRODUCT_COLORS,
} from '../actions/ActionTypes';
import { API_DOMAIN } from '../config/Constants';
import Utility from '../utils/Utility';
import { convertJsonFromResponse, getApiHeaders } from './GeneratorUtil';
import { isBlank, isPresent } from '../utils/BooleanUtility';
import Session from '../utils/Sessions';

function* fetchShopifyProductByHandle(action) {
  const { handle, callback } = action;
  const devicePixelRatio = PixelRatio.get();
  const pixelatedWidth = Math.round(devicePixelRatio * 360);

  const url = `${Config.SHOPIFY_STOREFRONT_URL}?`;
  let _params = new URLSearchParams();
  const query = `{
    "query":
      "query getProductIdFromHandle($handle: String!) { productByHandle(handle: $handle) { id images(first: 200) { nodes { id, url(transform: { maxWidth: ${pixelatedWidth}, preferredContentType: WEBP }), altText } } title vendor handle productType description descriptionHtml tags featuredImage { url } options { name values } variants(first: 250) { nodes { title selectedOptions { name value } availableForSale compareAtPrice { amount currencyCode } price { amount currencyCode } sku id image { id, url, altText } } } } }",
    "variables": {
      "handle": "${handle}"
    }
  }`;
  _params.append('query', query);
  try {
    const response = yield fetch(url + _params, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (
      (response.status >= 200 && response.status < 300) ||
      response.status === 401
    ) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    callback(false, {});
  }
}

function* fetchShopifyCollectionByHandle(action) {
  const {
    handle,
    callback,
    endCursor = '',
    sortFilter,
    hideOosProduct = false,
  } = action;
  let { filters } = action;
  const devicePixelRatio = PixelRatio.get();
  const pixelatedWidth = Math.round(devicePixelRatio * 360);
  if (hideOosProduct) {
    filters.push({ available: true });
  }
  const url = `${Config.SHOPIFY_STOREFRONT_URL}?`;
  let _params = new URLSearchParams();
  const query = `{
    "query": "query getCollectionByHandle($handle: String!${
      endCursor ? ', $after: String' : ''
    }${isPresent(filters) ? ', $filters: [ProductFilter!]' : ''}${
    isPresent(sortFilter) ? ', $sortKey: ProductCollectionSortKeys!' : ''
  }${
    isPresent(sortFilter) ? ', $reverse: Boolean!' : ''
  }) { collection(handle: $handle) { title products(first: 20${
    endCursor ? ', after: $after' : ''
  }${isPresent(filters) ? ', filters: $filters' : ''}${
    isPresent(sortFilter) ? ', sortKey: $sortKey' : ''
  }${
    isPresent(sortFilter) ? ', reverse: $reverse' : ''
  }) { filters { label, presentation, type, values { count id label input } } nodes { images(first: 200) { nodes { id, altText, url(transform:{preferredContentType: WEBP}) } } options { name values } variants(first: 250) { nodes { title id image { id, url, altText } availableForSale price { amount } selectedOptions { name value } compareAtPrice { amount } } } featuredImage { id url(transform:{maxWidth: ${pixelatedWidth}, preferredContentType: WEBP}) } title vendor handle productType description descriptionHtml tags id } pageInfo {  hasNextPage endCursor} } } }",
    "variables": {
      "handle": "${handle}",
      "after": "${endCursor}",
      "filters": ${JSON.stringify(filters)},
      "sortKey": ${JSON.stringify(sortFilter.input || '')},
      "reverse": ${sortFilter.reverse || false}
    }
  }`;
  _params.append('query', query);

  try {
    const response = yield fetch(url + _params, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    if (
      (response.status >= 200 && response.status < 300) ||
      response.status === 401
    ) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
      return;
    }
    callback(false, {});
  } catch (error) {
    callback(false, {});
  }
}

function fetchShopifySearch(action) {
  const {
    query = '',
    endCursor = '',
    productFilters = [],
    callback,
    sortFilter,
  } = action;
  const devicePixelRatio = PixelRatio.get();
  const pixelatedWidth = Math.round(devicePixelRatio * 360);
  const variables = {
    query,
    first: 10,
    productFilters,
    reverse: sortFilter?.reverse || '',
    sortKey: sortFilter?.input || '',
  };

  if (isPresent(endCursor)) {
    variables['after'] = endCursor;
  }

  const searchVariables = JSON.stringify(variables);
  const searchQuery = `{
    "query": "query searchProducts($query: String!, $first: Int, $after: String, $productFilters: [ProductFilter!]) { search(query: $query, first: $first, types: [PRODUCT], after: $after, productFilters: $productFilters) { productFilters { label, type, values { count id label input } } edges { node { ... on Product { images(first: 50) { nodes { id, altText, url(transform:{preferredContentType: WEBP}) } } options { name values } variants(first: 250) { nodes { title id image { id, url, altText } availableForSale price { amount } selectedOptions { name value } compareAtPrice { amount } } } featuredImage { id url(transform:{maxWidth: ${pixelatedWidth}, preferredContentType: WEBP}) } title vendor handle productType description descriptionHtml tags id } } } pageInfo { endCursor hasNextPage } totalCount } }",
    "variables": ${searchVariables}
  }`;
  let _params = new URLSearchParams();
  _params.append('query', searchQuery);
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  fetch(`${Config.SHOPIFY_STOREFRONT_URL}?` + _params, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      const {
        data: {
          search: {
            edges = [],
            productFilters = [],
            pageInfo = {},
            totalCount,
          },
        } = {},
      } = result;
      const nodes = [];
      edges.forEach(({ node }) => {
        nodes.push(node);
      });
      const data = {
        productFilters,
        productData: { nodes },
        pageInfo,
        totalCount,
      };
      callback(true, data);
    })
    .catch((error) => {
      console.tron.log('SEARCH RESULT ERROR', `${error}`);
      callback(false, {});
    });
}

function fetchShopifyPredictiveSearch(action) {
  const { query, callback } = action;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  let _params = new URLSearchParams();

  const searchQuery = `{
    "query": "query suggestions($query: String!) { predictiveSearch(query: $query) { queries { text } collections { id title handle image { url } } products { id handle title productType images(first: 1) { nodes { url(transform: { maxWidth: 100, preferredContentType: WEBP }) } } } } }",
    "variables": { "query": "${query}" }
  }`;

  _params.append('query', searchQuery);

  fetch(`${Config.SHOPIFY_STOREFRONT_URL}?` + _params, requestOptions)
    .then((response) => response.json())
    .then((result = {}) => {
      const {
        data: { predictiveSearch: { queries = [], products = [], collections = [] } = {} } = {},
      } = result;
      callback(true, { suggestions: [...queries, ...collections, ...products] });
    })
    .catch((error) => {
      console.tron.log('PREDICTIVE RESULT ERROR', `${error}`);
      callback(false, {});
    });
}

function fetchShopifyRecommendedProducts(action) {
  const { productId, callback } = action;
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const devicePixelRatio = PixelRatio.get();
  const pixelatedWidth = Math.round(devicePixelRatio * 360);

  const params = new URLSearchParams();

  const query = `{
    "query": "query productRecommendations($productId: ID!) { productRecommendations(productId: $productId) { id images(first: 50) { nodes { id, url(transform: { maxWidth: ${pixelatedWidth}, preferredContentType: WEBP }), altText } } title vendor handle productType description descriptionHtml tags featuredImage { url } options { name values } variants(first: 250) { nodes { title selectedOptions { name value } availableForSale compareAtPrice { amount currencyCode } price { amount currencyCode } sku id image { id, url, altText } } } } }",
    "variables": { "productId": "${productId}" }
    }`;
  params.append('query', query);

  fetch(
    `${Config.SHOPIFY_STOREFRONT_URL}?${params}`,
    requestOptions,
  )
    .then(response => response.json())
    .then((result = {}) => {
      callback(true, result);
    })
    .catch((error) => {
      console.tron.log('recommended products ERROR', `${error}`);
      callback(false, {});
    });
}


export function* fetchShopifyProductColorMapping(action) {
  const { slug, callback = () => {} } = action;
  
  const url = `${API_DOMAIN}/api/v1/colour_families`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    let sendCallbackData = true;
    const timer = setTimeout(() => {
      sendCallbackData = false;
      callback(false, { errorType: 'api_failure' });
    }, parseInt(Utility.getTimeOutValue()));

    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      clearTimeout(timer);
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      Session.colorMappingShopify = json;
      callback(true, json);
    } else {
      callback(false, {});
      console.tron.log('error in color mapping', `${slug}`);
    }
  } catch (error) {
    console.tron.log('error in color mapping', `${error}`);
    callback(false, {});
  }
}

export default function* watchShopify() {
  yield takeLatest(
    FETCH_SHOPIFY_PRODUCT_BY_HANDLE,
    fetchShopifyProductByHandle,
  );
  yield takeLatest(
    FETCH_SHOPIFY_COLLECTION_BY_HANDLE,
    fetchShopifyCollectionByHandle,
  );
  yield takeLatest(
    FETCH_SHOPIFY_SEARCH_SUGGESTIONS,
    fetchShopifyPredictiveSearch,
  );
  yield takeLatest(FETCH_SHOPIFY_SEARCH, fetchShopifySearch);
  yield takeLatest(
    FETCH_SHOPIFY_PRODUCT_RECOMMENDATIONS,
    fetchShopifyRecommendedProducts,
  );
  yield takeLatest(
    FETCH_SHOPIFY_PRODUCT_COLORS,
    fetchShopifyProductColorMapping,
  );
}
