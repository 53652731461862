import React, { useEffect, useRef, useState } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  KeyboardAvoidingView,
  Platform,
  Image,
  FlatList,
} from 'react-native';
import { ids, styles } from './styles';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { fetchList } from '../../actions/ActionTypes';
import FastImageView from '../FastImageView';
import { ScaleAnimate } from '../shared';
import { createIntroVideo } from '../../actions/InfluencerActions';
import FoxyAlert from '../camera/shared/FoxyAlert';
import colors from '../../theme/Colors';
import { AnalyticsManager, EventType } from '../../analytics';
import { LISTS_API_VERSION } from '../../config/Constants';

export default function AddIntroVideo() {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [successModalVisibility, setSuccessModalVisibility] = useState(false);
  const [itemData, setItemData] = useState({});
  const { id: currentUserId = '' } = useSelector((state) => state.UserAccountInfo);

  const goBack = () => {
    navigation.goBack();
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result;
        dispatch(
          createIntroVideo(base64String, (success, response) => {
            if (success) {
              toggleSuccessModal(true);
            }
          }),
        );
      };

      reader.readAsDataURL(file);
      AnalyticsManager.logEvent(EventType.INFLUENCER.INTRO_VIDEO_UPLOAD, {
        current_user_id: currentUserId,
      });
    }
  };

  useEffect(() => {
    fetchVideos();
    AnalyticsManager.logEvent(EventType.INFLUENCER.UPLOAD_INTRO_VIDEO_PAGE_VIEW, {
      current_user_id: currentUserId,
    });
  }, []);

  const fetchVideos = () => {
    dispatch(
      //FIXME: Need to get this list from api with url sample_intro_videos 
      fetchList({ slug: `/api/${LISTS_API_VERSION}/lists/3478290` }, 0, (success, response) => {
        if (success) {
          setItemData(response);
        }
      }),
    );
  };

  const openVideoPlaylist = (item, index) => {
    navigation.navigate('ContentModal', {
      listId: itemData.id,
      index,
      itemData: item,
      listData: itemData,
    });
  };

  const renderVideoComponent = ({ item, index }) => {
    const { image_url = '' } = item;
    if (isBlank(image_url)) return null;
    return (
      <ScaleAnimate onPress={() => openVideoPlaylist(item, index)}>
        <FastImageView source={image_url} style={styles.videoContainer} />
        <View style={styles.addIntroPlayIconContainer}>
          <FastImageView
            source={images.play_video}
            style={styles.addIntroPlayIcon}
          />
        </View>
      </ScaleAnimate>
    );
  };

  const toggleSuccessModal = (show) => {
    setSuccessModalVisibility(show);
  };

  const navigateToRegistrationCompletion = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.SKIP_INTRO_VIDEO, {
      current_user_id: currentUserId,
    });
    toggleSuccessModal(false);
    navigation.replace('Dashboard');
  };

  const handlePress = () => navigation.push('TabNavigator');

  return (
    <View style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <Image source={{ uri: images.appHeaderLogo }} style={styles.logo} />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        <ScrollView stickyHeaderIndices={[0]} style={styles.scrollView}>
          <View style={styles.header}>
            <TouchableOpacity onPress={goBack}>
              <Image source={images.ic_back_product} style={styles.back} />
            </TouchableOpacity>
            <View
              dataSet={{ media: ids.headingTextContainer }}
              style={styles.flex1Container}
            >
              <Text
                style={styles.headerTitle}
                dataSet={{ media: ids.headerTitle }}
              >
                Add your intro video
              </Text>
            </View>
            <TouchableOpacity onPress={navigateToRegistrationCompletion}>
              <Text style={styles.skipText} dataSet={{ media: ids.skipText }}>
                Skip
              </Text>
            </TouchableOpacity>
          </View>
          <View style={styles.fullWidth}>
            <View
              dataSet={{ media: ids.formContainer }}
              styles={styles.formContainer}
            >
              <Text
                dataSet={{ media: ids.addIntroVideoHeading }}
                style={styles.addIntroVideoHeading}
              >
                Tell your followers about you
              </Text>
              <Text
                style={styles.addIntroVideoSubheading}
                dataSet={{ media: ids.addIntroVideoSubheading }}
              >
                Add a 15 sec video on your profile page. This will be played for
                the users landing on your page for the first time
              </Text>
              <Text
                style={styles.addIntroVideoSubheading}
                dataSet={{ media: ids.addIntroVideoSubheading }}
              >
                {`Here's how our top creators do it! ⬇️`}
              </Text>
              <FlatList
                data={itemData?.objects || []}
                renderItem={renderVideoComponent}
                horizontal
                showsHorizontalScrollIndicator={false}
                style={styles.addIntroVideoFlatlist}
                contentContainerStyle={styles.addIntroVideoFlatlistContent}
              />
            </View>
          </View>
        </ScrollView>
        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
          style={styles.buttonContainer}
          dataSet={{ media: ids.buttonContainer }}
        >
          <TouchableOpacity style={styles.button} onPress={handleUploadClick}>
            <Text style={styles.buttonText}>Upload your intro video</Text>
          </TouchableOpacity>
          <input
            ref={fileInputRef}
            type='file'
            accept='video/*'
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
        </KeyboardAvoidingView>
        <FoxyAlert
          isVisible={successModalVisibility}
          hideSecondButton
          alertBoxTitle="File upload successful"
          alertMessage="Your image/video is under review. It'll appear on your profile once approved."
          firstButtonTitle='Okay'
          firstButtonOnPress={navigateToRegistrationCompletion}
          onTapOutside={navigateToRegistrationCompletion}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.routines.checkedIcon}
          showImage
          isAbsolute
        />
      </View>
    </View>
  );
}
