import {
  Text,
  TouchableOpacity,
  Image,
  StyleSheet,
} from 'react-native';
import React from 'react';
import { getHiddenTabRoutes } from '../../utils/NavigationUtility';
import images from '../../theme/Images';

export default function CustomTabRoute({
  navigation,
  descriptors,
  state,
  route,
  index,
  position,
}) {
  const { key, name, params } = route;
  if (getHiddenTabRoutes().includes(name)) return null;
  const { options } = descriptors[key];
  const { tabBarLabel, title } = options;
  const label = tabBarLabel || title || name;
  const isFocused = state.index === index;

  const onPress = () => {
    const event = navigation.emit({
      type: 'tabPress',
      target: key,
      canPreventDefault: true,
    });

    if (!isFocused && !event.defaultPrevented) {
      navigation.navigate(name, params);
    }
  };

  const inputRange = state.routes.map((_, i) => i);
  const opacity = position.interpolate({
    inputRange,
    outputRange: inputRange.map(i => (i === index ? 1 : 0)),
  });

  return (
    <TouchableOpacity
      key={key}
      accessibilityRole="button"
      accessibilityState={isFocused ? { selected: true } : {}}
      accessibilityLabel={options.tabBarAccessibilityLabel}
      onPress={onPress}
      style={styles.button}
    >
      <Text
        style={[
          { opacity },
          isFocused ? styles.focusedLabel : styles.namedLabel,
          styles.label,
        ]}
      >
        {label}
      </Text>
      { isFocused && (
        <Image
          source={{ uri: images.desktop.accountOptionDivider }}
          style={styles.image}
        />
      )}
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  button: {
    width: 78,
    height: '100%',
    position: 'relative',
    fontSize: 16,
    textAlign: 'center',
    lineHeight: 1.5,
  },
  label: {
    color: '#173143',
    top: '40%',
    position: 'absolute',
    fontSize: 16,
    whiteSpace: 'nowrap',
    alignSelf: 'center',
  },
  image: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: '100%',
    height: 3.46,
    width: 78,
  },
  namedLabel: {
    fontWeight: 'normal',
  },
  focusedLabel: {
    fontWeight: 'bold',
  },
});
