import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import { ScrollView } from 'react-native';
import colors from '../../theme/Colors';

const shimmerColor = [
  colors.shimmer.lightGrey,
  colors.shimmer.extraLightGrey,
  colors.shimmer.lightGrey,
];

const ProfilePlaceHolder = () => {
  return (
    <View style={styles.profilePlaceHolderContainer}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.profileImageContainer}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.profileTitle}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.profileSubTitle}
        colorShimmer={shimmerColor}
      />
    </View>
  );
};

const PlaceHolder = () => {
  return (
    <View style={styles.placeHolderContainer}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.placeHolderHeader}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.placeHolder}
        colorShimmer={shimmerColor}
      />
    </View>
  );
};

const PillsShimmer = () => {
  return (
    <View style={styles.placeHolderContainer}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.placeHolderHeader}
        colorShimmer={shimmerColor}
      />
      <View style={styles.pillsContainer}>
        {[...Array(8)].map((_, index) => (
          <ShimmerPlaceHolder
            autoRun
            style={styles.pills}
            colorShimmer={shimmerColor}
          />
        ))}
      </View>
    </View>
  );
};

const DescriptionContainer = () => {
  return (
    <View style={styles.placeHolderContainer}>
      <ShimmerPlaceHolder
        autoRun
        style={styles.placeHolderHeader}
        colorShimmer={shimmerColor}
      />
      <ShimmerPlaceHolder
        autoRun
        style={styles.descriptionPlaceHolder}
        colorShimmer={shimmerColor}
      />
    </View>
  );
};

const HandleSelectionShimmer = () => {
  return (
    <View style={styles.container}>
      <ProfilePlaceHolder />
      <PlaceHolder />
      <PlaceHolder />
      <PillsShimmer />
      <DescriptionContainer />
    </View>
  );
};

export default HandleSelectionShimmer;

const styles = StyleSheet.create({
  container: {
    width: getScreenWidth() - 12,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },
  profilePlaceHolderContainer: {
    width: getScreenWidth() - 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profileImageContainer: {
    width: 120,
    height: 120,
    borderRadius: 75,
    marginVertical: 12,
  },
  profileTitle: {
    height: 12,
    width: 80,
    borderRadius: 10,
  },
  profileSubTitle: {
    height: 16,
    width: 120,
    borderRadius: 10,
    marginTop: 8,
  },
  placeHolderHeader: {
    height: 12,
    width: getScreenWidth() / 2,
    borderRadius: 10,
    marginTop: 8,
  },
  placeHolderContainer: {
    justifyContent: 'center',
    width: getScreenWidth() - 60,
    marginTop: 20,
  },
  placeHolder: {
    height: 40,
    width: getScreenWidth() - 40,
    borderRadius: 6,
    marginTop: 12,
  },
  pills: {
    height: 24,
    width: getScreenWidth() / 5,
    borderRadius: 30,
    marginVertical: 6,
    marginHorizontal: 2,
  },
  pillsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 8,
  },
  descriptionPlaceHolder: {
    height: 130,
    width: getScreenWidth() - 40,
    borderRadius: 6,
    marginTop: 12,
  },
});
