import React from 'react';
import { View, Text, TouchableOpacity, Image } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { ids, styles } from './styles';
import images from '../../theme/Images';

const Header = ({ title = '', subTitle = '' }) => {
  const navigation = useNavigation();
  const goBack = () => {
    navigation.goBack();
  };

  return (
    <View style={styles.header}>
      <TouchableOpacity onPress={goBack}>
        <Image source={images.ic_back_product} style={styles.back} />
      </TouchableOpacity>

      <View dataSet={{ media: ids.headingTextContainer }}>
        <Text style={styles.headerTitle} dataSet={{ media: ids.headerTitle }}>
          {title}
        </Text>
        <Text style={styles.headerSubtitle} dataSet={{ media: ids.headerSubtitle }}>
          {subTitle}
        </Text>
      </View>
    </View>
  );
};

export default Header;
