import { put, takeLatest, select } from 'redux-saga/effects';
import {
  ARTIST_DATA_RECEIVED,
  FETCH_ARTIST,
  UPDATE_ARTIST_DATA,
  UPDATE_ARTIST_DATA_BY_SLUG,
  VIEW_ARTIST_STORY,
  FOLLOW_ARTIST,
  UNFOLLOW_ARTIST,
  followArtistFailed,
  unfollowArtistFailed,
  UPDATE_ARTIST_STORY_LIST,
  POST_ARTIST_TYPE,
  saveArtistTypeLocal,
  SAVE_ARTIST_TYPE_LOCAL,
  REQUEST_BOOST,
  REQUEST_EXPERT_REVIEW,
  requestExpertReview,
  GET_YOUTUBE_RECOMMENDATIONS,
  SAVE_ALL_ARTIST_TYPES,
  GET_ALL_ARTIST_TYPES,
  GET_ARTIST_CTA_OPTIONS,
  SAVE_ARTIST_CTA_OPTIONS,
  GET_ALL_VIDEOS,
  CREATE_BOOKING,
  GET_ALL_EMOJIS,
  SAVE_ALL_EMOJIS,
  GET_ARTIST_STORY_RECOMMENDATION,
  FOLLOW_RECOMMENDATIONS,
  setStoryItemRecommendation,
  followStories,
  COMBINED_STORY_VIEW,
  followRecommendations,
  GET_FOXY_LIVE_ARTISTS,
  GET_PROFILE_COMPLETION_CARDS,
} from '../actions/ActionTypes';
import {
  getUserFacialAttributes,
  getMyAttributes,
} from './ProfilePicUploadUrlSaga';
import { logoutUser } from '../actions/LoginActions';
import {
  API_DOMAIN,
  ARTIST_STORY,
  URL,
  LISTS_API_VERSION,
} from '../config/Constants';
import { getApiHeaders, convertJsonFromResponse } from './GeneratorUtil';
import Utility from '../utils/Utility';
import {
  ADD_PROLINK,
  CHECK_HANDLE_AVAILABILITY,
  CREATE_INTRO_VIDEO,
  FETCH_OG_DETAILS,
  GET_INFLUENCER_DETAILS,
  GET_INFLUENCER_TAGS,
  GET_PRO_ACCOUNT_LINKS,
  INSTA_PRO_VERIFICATION_STATUS,
  UPDATE_INFLUENCER,
  VERIFY_ONELINK,
} from '../actions/InfluencerActions';
import { API_ERRORS } from '../config/Constants';
import { appendAttributesWithHomePageUrl } from '../utils/UriUtility';

export function* fetchArtistAsync(action) {
  const slug = action.payload;
  const { callback } = action;
  const url = `${API_DOMAIN}${slug}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      callback(true, json);
    } else {
      callback(false, {}, response.status);
    }
  } catch (error) {}
}

export function* followArtistAsync(action) {
  const {
    data: { url: slug },
  } = action;
  const url = `${API_DOMAIN}${slug}`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      yield put(followArtistFailed(action.data));
    }
  } catch (error) {
    yield put(followArtistFailed(action.data));
    console.tron.log(` error ${JSON.stringify(error)} auth token : ${authToken}`);
  }
}
export function* unfollowArtistAsync(action) {
  const {
    data: { url: slug },
  } = action;
  const url = `${API_DOMAIN}${slug}`;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken }),
    });
    const statusCode = response.status;
    if (statusCode === 401) {
      yield put(logoutUser());
    } else if (statusCode !== 200) {
      yield put(unfollowArtistFailed(action.data));
    }
  } catch (error) {
    yield put(unfollowArtistFailed(action.data));
    console.tron.log(` error ${JSON.stringify(error)} auth token : ${authToken}`);
  }
}

export function* getArtistStoryAsync() {
  const viewedArtist = yield select(
    (state) => state.UserAccountInfo.viewedArtistStory,
  );
  const url = Object.keys(viewedArtist).reduce((url, key, index, _arr) => {
    if (viewedArtist[key].last_seen !== undefined) {
      return `${url}artists[${index}][id]=${key}&artists[${index}][last_seen]=${viewedArtist[key].last_seen}&`;
    }
    return url;
  }, ARTIST_STORY);
  console.tron.log('artist story url is ', url);
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const json = yield convertJsonFromResponse(response);
    if (Utility.isPresent(json)) {
      yield put({ type: UPDATE_ARTIST_STORY_LIST, data: json });
    }
  } catch (error) {
    console.tron.log('error in artist story');
  }
}

export function* saveArtistTypes(action) {
  const { artistTypes } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const payload = {
      auth_token: authToken,
      artist: {
        artist_type: artistTypes,
      },
    };

    console.tron.log('Sending Data ', payload);
    const response = yield fetch(URL.SAVE_ARTIST_TYPE, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });
    if (response.status >= 200 && response.status < 300) {
      // success
    } else {
      // failed
    }

    yield put(saveArtistTypeLocal(artistTypes));
  } catch (error) {}
}

export function* requestBoost(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const payload = { auth_token: authToken };
    const response = yield fetch(URL.REQUEST_BOOST, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });

    console.tron.log('Boost Request response ', response.status);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('Error', error);
    callback(false);
  }
}

export function* requestExpertReviews(action) {
  const { callback } = action;
  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const payload = { auth_token: authToken };
    const response = yield fetch(URL.REQUEST_EXPERT_REVIEW, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(payload),
    });

    console.tron.log('Request Expert Review api status ', response.status);
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getYoutubeRecommendations(action) {
  const { callback } = action;

  try {
    const response = yield fetch(URL.YOUTUBE_RECOMMENDATIONS, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });

    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      callback(json);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log('Error mode');
    callback(false);
  }
}

export function* getArtistCta(action) {
  const url = URL.ARTIST_CTA;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put({ type: SAVE_ARTIST_CTA_OPTIONS, data: json });
      }
    }
  } catch (error) {
    console.tron.log('artist CTA error', error);
  }
}

export function* getAllVideos(action) {
  const { callback, page = 0, id, dynamicDestinationSlug } = action;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  const path = dynamicDestinationSlug.replace(
    '${allVideosId}',
    allVideosId.toString(),
  );
  const url = `${API_DOMAIN}${path}&page=${page}`;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, []);
        return;
      }
      callback(true, response);
    } else {
      callback(false, []);
    }
  } catch (error) {
    console.tron.log('Error mode');
    callback(false, []);
  }
}

export function* createBooking(action) {
  const { booking, callback } = action;
  const url = URL.CREATE_BOOKING;
  const authToken = yield select((state) => state.UserAccountInfo.authToken);
  try {
    const response = yield fetch(url, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify({ auth_token: authToken, booking }),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    console.tron.log(` error ${JSON.stringify(error)} auth token : ${authToken}`);
    callback(false);
  }
}

export function* getAllEmojis() {
  const url = URL.GET_EMOJI;
  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    const statusCode = response.status;
    if (statusCode >= 200 && statusCode < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isPresent(json)) {
        yield put({ type: SAVE_ALL_EMOJIS, data: json });
      }
    }
  } catch (error) {
    console.tron.log('emoji get error', error);
  }
}

function* fetchSelfieData() {
  const has_selfie = yield select((state) => state.UserAccountInfo.has_selfie);
  const facialAnalysis = yield select(
    (state) => state.UserAccountInfo.facialAnalysis,
  );
  const { my_attributes = [] } = facialAnalysis;
  if (has_selfie && Utility.isBlank(my_attributes)) {
    yield getMyAttributes('', true);
  }
}

export function* followerRecommendations(action) {
  const { callback, data } = action;
  try {
    const postData = { followings: { items: data } };

    const response = yield fetch(URL.FOLLOW_RECOMMENDATIONS, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export function* getArtistStoryRecommendation(action) {
  const { callback, saveToRedux } = action;
  let url = `${API_DOMAIN}/api/${LISTS_API_VERSION}/lists/story-recommendations`;
  url = `${url}?Nan=Nan`;
  yield fetchSelfieData();
  const facialAnalysis = yield select(
    (state) => state.UserAccountInfo.facialAnalysis,
  );

  if (
    Utility.isPresent(facialAnalysis) &&
    Utility.isPresent(facialAnalysis.facialProperties)
  ) {
    const { facialProperties } = facialAnalysis;
    url = appendAttributesWithHomePageUrl(url, facialProperties, false);
  } else if (Utility.isPresent(facialAnalysis.my_attributes_values)) {
    url = appendAttributesWithHomePageUrl(
      url,
      facialAnalysis.my_attributes_values,
      true,
    );
  }

  try {
    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, {});
        return;
      }
      if (saveToRedux) {
        const autoSelectRecommendation = [];
        const autoSelectRecommendationObject = {};
        const stories = [];
        json.objects.map((element) => {
          if (element.type !== 'feature') {
            element.objects.slice(0, element.display_count).map((e) => {
              if (
                !Utility.isBlank(e.stories) &&
                !Utility.isBlank(e.stories.first_story_page_id)
              ) {
                stories.push(e);
              }
              autoSelectRecommendationObject[`${e.type}${e?.id}`] = {
                item_type: e.type,
                item_id: e?.id,
              };
              autoSelectRecommendation.push({
                item_type: e.type,
                item_id: e?.id,
              });
            });
          }
        });
        yield put(setStoryItemRecommendation(autoSelectRecommendationObject));
        yield put(followStories(stories));
        yield put(
          followRecommendations(autoSelectRecommendation, () => {
            console.tron.log('Success');
          }),
        );
      }
      callback(true, json);
    } else {
      callback(false, {});
    }
  } catch (error) {
    callback(false, {});
    console.tron.log('artist recommendation', error);
  }
}

export function* setArtistViewStoryAsync(action) {
  const { data, callback } = action;
  try {
    const postData = { combined_story_view: { combined_story_id: data } };

    const response = yield fetch(URL.STORY_VIEW, {
      method: 'POST',
      headers: yield getApiHeaders(),
      body: JSON.stringify(postData),
    });
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* getFoxyLiveArtists(action) {
  const { callback, api = '' } = action;
  const userAgentPrefix = Utility.isAndroid() ? 'Android' : 'ios';
  try {
    const response = yield fetch(api, {
      method: 'GET',
      headers: {
        ...(yield getApiHeaders()),
        'User-Agent': `FoxyLive/1.0 ${userAgentPrefix} 15`,
      },
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false, null);
        return;
      }
      const artistInfo = json.data.map((element) => {
        const {
          id,
          attributes: {
            name = '',
            imageUrl = '',
            artistTypes = [],
            liveRating,
          } = {},
        } = element;
        return {
          id,
          name,
          imageUrl,
          profession: artistTypes.length > 0 ? artistTypes[0] : '',
          rating: liveRating,
        };
      });
      callback(true, artistInfo);
    } else {
      callback(false, null);
    }
  } catch (error) {
    callback(false, null);
  }
}

function* getInfluencerDetails(action) {
  const { SERVER_ERROR, CONNECTION_ERROR } = API_ERRORS;
  const { callback, handle = '' } = action;
  const url = `${URL.INFLUENCER_DETAILS}`.replace('handle', handle);
  const timer = setTimeout(() => {
    callback(false, { error: CONNECTION_ERROR });
  }, 20000);
  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  clearTimeout(timer);
  try {
    const details = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, details);
    } else {
      callback(false, { error: SERVER_ERROR, list: details, statusCode: response.status });
    }
  } catch (error) {
    callback(false, { error: SERVER_ERROR });
  }
}

function* checkHandleAvailability(action) {
  const { callback, handle = '' } = action;
  const url = `${URL.CHECK_HANDLE_AVAILABILITY}?handle=${handle}`;
  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* updateInfluencerData(action) {
  const { callback, data = '' } = action;
  const url = `${URL.UPDATE_INFLUENCER}`;
  const response = yield fetch(url, {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify(data),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* addProLink(action) {
  const { callback, link_type = '', url = '' } = action;
  const response = yield fetch(`${URL.ADD_PROLINK}`, {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      pro_link: {
        link_type,
        url,
      },
    }),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* fetchOGDetails(action) {
  const { callback, id = '' } = action;
  const response = yield fetch(`${URL.ADD_PROLINK}/${id}/fetch_og_details`, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* createIntroVideo(action) {
  const { callback, video = '' } = action;
  const url = `${URL.CREATE_INTRO_VIDEO}`;
  const response = yield fetch(url, {
    method: 'POST',
    headers: yield getApiHeaders(),
    body: JSON.stringify({
      video,
    }),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* getVerificationStatus(action) {
  const { callback } = action;
  const url = `${URL.INSTA_PRO_VERIFICATION_STATUS}`;
  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* getInfluencerTags(action) {
  const { callback } = action;
  const url = `${URL.INFLUENCER_TAGS}`;
  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}

function* getProAccountLinks(action) {
  const { callback } = action;
  const url = `${URL.PRO_ACCOUNT_LINKS}`;
  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}


function* verifyOneLink(action) {
  const { callback } = action;
  const url = `${URL.VERIFY_ONELINK}`;
  const response = yield fetch(url, {
    method: 'POST',
    headers: yield getApiHeaders(),
  });
  try {
    if (response.status >= 200 && response.status < 300) {
      callback(true);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* fetchProfileCompletionList(action) {
  const { callback } = action;
  const url = `${URL.DERMAT_PROFILE}/profile_completion_cards?platform_view=desktop`;

  const response = yield fetch(url, {
    method: 'GET',
    headers: yield getApiHeaders(),
  });
  try {
    const jsonResponse = yield response.json();
    if (response.status >= 200 && response.status < 300) {
      callback(true, jsonResponse);
    } else {
      callback(false, jsonResponse);
    }
  } catch (error) {
    callback(false, {});
  }
}


// Our watcher Saga:
export default function* watchFetchArtist() {
  yield takeLatest(FETCH_ARTIST, fetchArtistAsync);
  yield takeLatest(FOLLOW_ARTIST, followArtistAsync);
  yield takeLatest(COMBINED_STORY_VIEW, setArtistViewStoryAsync);
  yield takeLatest(UNFOLLOW_ARTIST, unfollowArtistAsync);
  yield takeLatest(POST_ARTIST_TYPE, saveArtistTypes);
  yield takeLatest(REQUEST_BOOST, requestBoost);
  yield takeLatest(REQUEST_EXPERT_REVIEW, requestExpertReviews);
  yield takeLatest(GET_YOUTUBE_RECOMMENDATIONS, getYoutubeRecommendations);
  yield takeLatest(GET_ARTIST_CTA_OPTIONS, getArtistCta);
  yield takeLatest(GET_ALL_VIDEOS, getAllVideos);
  yield takeLatest(CREATE_BOOKING, createBooking);
  yield takeLatest(GET_ALL_EMOJIS, getAllEmojis);
  yield takeLatest(
    GET_ARTIST_STORY_RECOMMENDATION,
    getArtistStoryRecommendation,
  );
  yield takeLatest(CREATE_INTRO_VIDEO, createIntroVideo);
  yield takeLatest(FOLLOW_RECOMMENDATIONS, followerRecommendations);
  yield takeLatest(GET_FOXY_LIVE_ARTISTS, getFoxyLiveArtists);
  yield takeLatest(GET_INFLUENCER_DETAILS, getInfluencerDetails);
  yield takeLatest(CHECK_HANDLE_AVAILABILITY, checkHandleAvailability);
  yield takeLatest(UPDATE_INFLUENCER, updateInfluencerData);
  yield takeLatest(ADD_PROLINK, addProLink);
  yield takeLatest(FETCH_OG_DETAILS, fetchOGDetails);
  yield takeLatest(INSTA_PRO_VERIFICATION_STATUS, getVerificationStatus);
  yield takeLatest(GET_INFLUENCER_TAGS, getInfluencerTags);
  yield takeLatest(GET_PRO_ACCOUNT_LINKS, getProAccountLinks);
  yield takeLatest(VERIFY_ONELINK, verifyOneLink);
  yield takeLatest(GET_PROFILE_COMPLETION_CARDS, fetchProfileCompletionList)
}
