import { StyleSheet } from 'react-native';
import colors from '../../../theme/Colors';
import Utility from '../../../utils/Utility';
import { getScreenWidth } from '../../../utils/LayoutUtility';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.background,
    overflow: 'visible',
  },
  sub_heading: {
    fontSize: 16,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: '#173143',
  },

  morebuttonstyle: {
    fontSize: 14,
    color: '#fd5c63',
    fontFamily: 'Roboto-Bold',
  },
  footerMoreViewContainer: {
    flexDirection: 'column',
    marginLeft: 12,
    marginRight: 12,
  },
  footerMoreViewDivider: {
    backgroundColor: colors.white,
    width: '100%',
    height: 1,
  },
  footerMoreViewContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    // height: 38,
    width: '100%',
    paddingBottom: 12,
  },
  footerMoreViewCTAText: {
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
    color: colors.cta.lightBlue,
  },
  footerMoreViewChevron: {
    width: 8,
    height: 12,
    marginRight: 4,
  },
  searchHeaderContainer: {
    height: 35,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: Utility.getScreenWidth(),
    backgroundColor: colors.background,
    marginTop: 8,
  },
  searchHeaderSwitchText: {
    color: colors.silver,
    fontSize: 14,
    fontFamily: 'Roboto-Regular',

    fontStyle: 'normal',
    letterSpacing: 0,
    marginLeft: 16,
  },
  searchHeaderSwitch: {
    position: 'absolute',
    right: 8,
    bottom: 10,
    height: 24,
    transform: [{ scaleX: 0.8 }, { scaleY: 0.8 }],
  },
  feedFooterContainer: {
    width: Utility.getScreenWidth(),
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 40,
  },
  footerButtonView: {
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    borderRadius: 16,
    borderColor: colors.foxyPink,
    borderWidth: 1,
  },
  footerTextHeading: {
    textAlign: 'center',
    color: '#979BAA',
    fontSize: 12,
    fontWeight: '400',
  },
  footerButtonText: {
    textAlign: 'center',
    color: colors.cta.lightBlue,
    fontSize: 14,
    marginTop: 8,
    fontWeight: '500',
    marginRight: 4,
  },
  footerChevronImage: {
    tintColor: '#4285F4',
    marginTop: 6,
  },
  footerSearchImage: {
    tintColor: '#4285F4',
    marginTop: 4,
  },
  footerTextAndImageContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  footerBottom: {
    width: Utility.getScreenWidth(),
    height: 72,
  },
  listContainer: { width: Utility.getScreenWidth(), height: '100%' },
});

export default styles;
