import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import _ from 'lodash';
import { ORIENTATION, LAYOUT } from '../../config/Constants';
import ScaleAnimate from '../shared/ScaleAnimate';
import Card from '../../lib/Card';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import { BRAND } from '../../config/LayoutConstants/BrandConfig';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  AnalyticsUtilty,
} from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FastImageView from '../FastImageView';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class DefaultSizeBrandRail extends PureComponent {
  static brandLayoutStyle = {
    vertical: {
      cardElevation: 2,
      cardMaxElevation: 2,
      cornerRadius: 4,
    },
    horizontal: {
      cardElevation: 0,
      cardMaxElevation: 0,
      cornerRadius: 0,
    },
  };

  constructor(props) {
    super(props);
    this.debouncedNavigate = _.debounce(this.navigate, 1000, {
      leading: true,
      trailing: false,
    });
    this.debounceBrandCardClick = _.debounce(
      this.fireSearchResultClickEvent,
      2000,
      {
        leading: true,
        trailing: false,
      },
    );
    this.navigationSource = getNavigationSource(this, this.props);
  }

  navigate = () => {
    const {
      navigation,
      id,
      itemData,
      listIndex,
      index,
      search,
      searchQuery,
      elementItemCounts,
      toggleCartVisibility,
      layout,
      previousScreen,
      listData,
      listId,
      listName,
      listContent,
    } = this.props;

    if (previousScreen === 'recommendation') {
      this.followBrand && this.followBrand();
      return null;
    }

    if (previousScreen === SCREEN_CONSTANTS.SEARCH) {
      let clickedIndex = Utility.addPreviousIndexValues(
        elementItemCounts,
        listIndex,
      );
      clickedIndex += index + 1;
      const meta = {
        [EventParameterKey.SEARCH_QUERY]: searchQuery,
        [EventParameterKey.ITEM_TYPE]: itemData.type,
        [EventParameterKey.ITEM_NAME]: itemData.name,
        [EventParameterKey.ITEM_ID]: itemData.id,
        [EventParameterKey.ITEM_POSITION]: clickedIndex,
      };

      this.debounceBrandCardClick(meta);
    }

    if (Utility.isPresent(itemData)) {
      AnalyticsUtilty.fireItemClickEvent(
        previousScreen,
        itemData.id,
        'brand',
        itemData.name,
        index,
        listId,
        layout,
        listName,
        listIndex,
        '',
        '',
        listContent,
        itemData.id,
        itemData?.slug,
        listData?.slug,
      );
    }

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Brand',
      navigationSource: this.navigationSource,
      params: {
        id,
        itemData,
        slug: itemData.slug,
        display: LAYOUT.SCREEN,
        toggleCartVisibility,
      },
    });
  };

  fireSearchResultClickEvent = (meta) => {
    AnalyticsManager.logEvent(EventType.search.SEARCH_RESULT_CLICK, meta);
  };

  brandImage = (props) => {
    const { imageUrl, name, webpImageUrl } = props;

    const newUrl =
      Utility.isAndroid() && Utility.isPresent(webpImageUrl)
        ? webpImageUrl
        : imageUrl;

    return (
      <View style={styles.brandImageContainer}>
        <FastImageView
          source={{
            uri: newUrl,
          }}
          resizeMode='cover'
          style={styles.brandImage}
          dataSet={{ media: ids.brandImage }}
          onError={this.imageError}
        >
          {Utility.isBlank(imageUrl) ? (
            <Text style={styles.placeholderText} numberOfLines={2}>
              {name}
            </Text>
          ) : null}
        </FastImageView>
      </View>
    );
  };

  render() {
    const {
      orientation = ORIENTATION.HORIZONTAL,
      itemData: { image_url = '', name = '', image_webp_url = '' },
      itemData,
    } = this.props;

    if (itemData === undefined) {
      return null;
    }
    const imageUrl = getMinifiedImage(image_url, styles.brandImage.width, styles.brandImage.height);
    return (
      <ScaleAnimate {...this.props} onPress={this.debouncedNavigate}>
        <Card
          {...DefaultSizeBrandRail.brandLayoutStyle[orientation]}
          style={styles.verticalCardStyle}
          dataSet={{ media: ids.verticalCardStyle }}
        >
          <this.brandImage
            imageUrl={imageUrl}
            webpImageUrl={image_webp_url}
            name={name}
          />
        </Card>
      </ScaleAnimate>
    );
  }
}

export default withProfiledNavigation(DefaultSizeBrandRail);

const { ids, styles } = StyleSheet.create({
  verticalCardStyle: {
    backgroundColor: 'white',
    borderColor: '#efefef',
    margin: 4,
    elevation: 3,
    shadowRadius: 1,
    shadowOpacity: 0.24,
    shadowColor: colors.black,
    shadowOffset: { width: 1, height: 2 },
    overflow: 'visible',
    borderRadius: 4,
    height: 120,
    width: 120,
    '@media (min-width: 768px)': {
      height: 120 * 2,
      width: 120 * 2,
      margin: 8,
    },
  },
  placeholderText: {
    position: 'absolute',
    color: 'white',
    bottom: 8,
    left: 8,
    right: 8,
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Roboto',
    fontSize: 12,
  },
  brandImageContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  railImageBackgroundStyle: {
    borderRadius: 4,
    overflow: 'hidden',
    backgroundColor: 'black',
    height: 120,
    width: 120,
  },
  brandImage: {
    height: 120,
    width: 120,
    '@media (min-width: 768px)': {
      height: 120 * 2,
      width: 120 * 2,
    },
  },
});
