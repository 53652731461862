import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  FlatList,
  Vibration,
  TouchableOpacity,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../../theme/Colors';
import size from '../../../../theme/Fonts';
import {
  APP_ASSET_URL,
  ROUTINE_ACTIVITY_TYPES,
} from '../../../../config/Constants';
import FastImageView from '../../../FastImageView';
import images from '../../../../theme/Images';
import FoxyButton from '../../../shared/FoxyButton';
import _ from 'lodash';
import Utility from '../../../../utils/Utility';
import HTML from 'react-native-render-html';
import RoutinesUtility from '../../RoutinesUtility';
import {
  getPollsData,
  selectPollOption,
} from '../../../../actions/RoutineActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PollActionCardContent from './PollActionCardContent';
import DynamicLinkManager from '../../../../utils/DynamicLinkManager';
import withNavigation from '../../../../utils/WithNavigation';
import NavigationService from '../../../../navigator/NavigationService';
import DebouncedTouchableOpacity from '../../../shared/DebouncedTouchableOpacity';
import { navigateToScreen } from '../../../../utils/NavigationUtility';
import { getMinifiedImage } from '../../../../utils/ImageUtility';
import { isNative, isBlank, isPresent } from '../../../../utils/BooleanUtility';
var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class RoutineActionCard extends Component {
  LAYOUT_COMPONENT = {
    feature: this.featureTypeContent,
    upload: this.featureTypeContent,
    poll: this.pollTypeContent,
  };

  constructor(props) {
    super(props);
    this.headerIcon = `${APP_ASSET_URL}/441/sample_action_icon.png`;
    const {
      itemData: {
        activity: { backgroundColor = '#F9DDFF' } = {},
        markedDone = false,
      } = {},
    } = this.props;
    this.state = {
      markAsDone: markedDone,
      isLoading: false,
      initialImageNumToRender: 0,
      pollQuestionsData: [],
    };
    const gradientColor = Utility.isBlank(backgroundColor)
      ? '#F9DDFF'
      : backgroundColor;
    this.gradientColors = [`${gradientColor}66`, gradientColor];
    this.fetchPollData();
  }

  fetchPollData = () => {
    const { itemData: { activity: { id = '' } = {}, activityType = '' } = {} } =
      this.props;
    if (activityType === 'Poll') {
      const { getPollsData } = this.props;
      getPollsData(id, (success, response) => {
        if (!success) return;
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              console.tron.log(data, 'poll data');
              const { pollQuestions = [] } = data;
              this.setState({
                pollQuestionsData: pollQuestions,
              });
            });
        } catch (e) {}
      });
    }
  };

  featureTypeContent({ itemData }) {
    const { activity: { body = '' } = {}, activityType = '' } = itemData;
    const data = body?.replace(/(\r\n|\n|\r)/gm, '');
    if (Utility.isBlank(data)) return null;
    return (
      <HTML
        html={`${data}`}
        tagsStyles={{
          p: styles.htmlText,
          ol: styles.htmlText,
          ul: styles.htmlText,
          div: styles.htmlText,
          span: styles.htmlText,
        }}
      />
    );
  }

  pollTypeContent({
    pollsData,
    selectedPollOptionIds,
    onPollOptionPress,
    buttonVisibility,
    inactivePollTapped = () => {},
  }) {
    if (Utility.isBlank(pollsData)) return null;
    return (
      <View>
        <PollActionCardContent
          pollsData={pollsData}
          onPollOptionPress={onPollOptionPress}
          buttonVisibility={buttonVisibility}
          inactivePollTapped={inactivePollTapped}
        />
      </View>
    );
  }

  onPollOptionPress = (item) => {
    const { id: pollOptionId, pollQuestionId = '' } = item;
    const {
      selectPollOption,
      onActionPress = () => {},
      itemData: { id: activityId, activity: { id: pollId = '' } } = {},
    } = this.props;
    selectPollOption(
      pollId,
      pollQuestionId,
      [pollOptionId],
      (success, response) => {
        if (!success) return;
        try {
          new JSONAPIDeserializer({
            typeAsAttribute: false,
            pluralizeType: true,
            keyForAttribute: 'camelCase',
          })
            .deserialize(response)
            .then((data) => {
              const { pollQuestions = [] } = data;
              this.setState({
                pollQuestionsData: pollQuestions,
              });
              onActionPress(activityId, ROUTINE_ACTIVITY_TYPES.POLL);
            });
        } catch (e) {}
      },
    );
  };

  navigateToScreen = (route, slug, path, extra = {}, params, url) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra, params, url });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  navigateToScreen = ({ route, slug }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      type: 'push',
      screen: route,
      params: {
        newDestination: slug, // webview reads data from destination
        slug,
      },
    });
  };

  setMarkAsDone = (markDone) => {
    this.setState({
      markAsDone: markDone,
    });
    this.setLoading(false);
  };

  onCardPress = () => {
    const { itemData: { activity: { externalLink } = {} } = {} } = this.props;
    DynamicLinkManager.handleDynamicLink(externalLink, this.navigateToScreen);
  };

  actionContent = () => {
    const {
      itemData: {
        activityType = '',
        activity: { pictureUrls = [], externalLink } = {},
      } = {},
      itemData = {},
      buttonVisibility = '',
      inactivePollTapped = () => {},
    } = this.props;
    const cardImage = pictureUrls[0];
    const { pollQuestionsData = [] } = this.state;
    const LayoutComponent =
      this.LAYOUT_COMPONENT[`${activityType}`.toLowerCase()];
    if (LayoutComponent === undefined || LayoutComponent === null) return null;
    return (
      <View>
        <LayoutComponent
          itemData={itemData}
          pollsData={pollQuestionsData}
          onPollOptionPress={this.onPollOptionPress}
          buttonVisibility={buttonVisibility}
          inactivePollTapped={inactivePollTapped}
        />
        {isPresent(cardImage) && (
          <TouchableOpacity
            disabled={isBlank(externalLink)}
            onPress={this.onCardPress}
          >
            <FastImageView
              source={cardImage}
              style={styles.activityCardImage}
              resizeMode='cover'
            />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  imageRailFooterComponent = () => {
    const { initialImageNumToRender } = this.state;
    const { dailyPhotos } = this.props;
    return (
      <View style={[styles.dailyImage]}>
        {dailyPhotos.length > initialImageNumToRender && (
          <Text style={styles.moreImagesText}>
            +{dailyPhotos.length - initialImageNumToRender}
          </Text>
        )}
      </View>
    );
  };

  uploadedImages = () => {
    const {
      dailyPhotos = [],
      itemData: {
        myPhotos = [],
        activity: { arguments: extraData = {} } = {},
      } = {},
    } = this.props;
    const { initialImageNumToRender } = this.state;
    if (Utility.isBlank(myPhotos) || extraData?.action !== 'upload')
      return null;
    return (
      <FlatList
        data={myPhotos}
        horizontal
        showsHorizontalScrollIndicator={false}
        renderItem={this.renderDailyImage}
        keyExtractor={(item) => `day_${item?.day}`}
        onLayout={this.onFlatListLayout}
        ListFooterComponent={this.imageRailFooterComponent}
        initialNumToRender={initialImageNumToRender}
      />
    );
  };

  onFlatListLayout = (event) => {
    const { width } = event.nativeEvent.layout;
    this.setState({
      initialImageNumToRender: (width / 30).toFixed(0) - 1,
    });
  };

  renderDailyImage = ({ item }) => {
    return (
      <FastImageView
        source={getMinifiedImage(
          item,
          styles.dailyImage.width,
          styles.dailyImage.height,
        )}
        style={styles.dailyImage}
      />
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <this.actionContent />
        <this.uploadedImages />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPollsData,
      selectPollOption,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(RoutineActionCard),
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    paddingHorizontal: 12,
    paddingBottom: 12,
    flex: 1,
    justifyContent: 'flex-start',
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  headerContainer: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  point: {
    color: colors.subtitle,
    width: 4,
    height: 4,
  },
  htmlText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginVertical: 4,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  headerIcon: {
    width: 32,
    height: 32,
    marginRight: 12,
    resizeMode: 'contain',
  },
  playIcon: {
    width: 24,
    height: 24,
    resizeMode: 'contain',
  },
  actionButtonContainer: {
    height: 40,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent',
    marginTop: 12,
  },
  actionButton: {
    left: 0,
    right: 0,
    height: 40,
    borderRadius: 8,
  },
  actionButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  actionButtonIcon: {
    width: 18,
    height: 18,
    tintColor: colors.white,
    resizeMode: 'contain',
    marginRight: 4,
  },
  divider: {
    backgroundColor: colors.white,
    width: '100%',
    height: 1,
    marginVertical: 12,
  },
  dailyImage: {
    height: 40,
    width: 22,
    borderRadius: 4,
    resizeMode: 'cover',
    marginRight: 8,
    marginBottom: 12,
    justifyContent: 'center',
  },
  moreImagesText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
  },
  activityCardImage: {
    width: '100%',
    height: 200,
    marginTop: 12,
  },
});
