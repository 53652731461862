import { StyleSheet, Text, View } from 'react-native';
import React, { Component } from 'react';
import Shipment from './Shipment';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import Alert from '../Alert/Alert';
import { isBlank } from '../../utils/BooleanUtility';

// eslint-disable-next-line react/prefer-stateless-function
export default class OrderItem extends Component {
  renderOrderItemCard = (props) => {
    return (
      <View style={styles.orderItem}>
        <this.renderShipmentOrOrderItems {...props} />
      </View>
    );
  };

  renderShipmentOrOrderItems = (props) => {
    let {
      navigation,
      onlyOneOrder,
      orderHistoryItem: {
        id,
        shipments = {},
        orderItems,
        aasmState,
        platform,
        merchantOrderName,
      },
      isSelectOrderView,
    } = this.props;

    const { fromOrderDetails, shipment_id } = props;

    if (aasmState === 'delivered' || aasmState === 'cancelled') {
      shipments = {};
    }

    if (Utility.isBlank(shipments)) {
      return (
        <Shipment
          navigation={navigation}
          orderItems={orderItems}
          id={`order-${id}`}
          showOrder
          platform={platform}
          renderProp={this.renderOrderItemCard}
          fromOrderDetails={fromOrderDetails}
          onlyOneOrder={onlyOneOrder}
          merchantOrderName={merchantOrderName}
          isSelectOrderView={isSelectOrderView}
          order_id={id}
        />
      );
    }

    const shipmentSkus = shipments.reduce(
      (acc, s) => [
        ...acc,
        ...s.shipmentItems.map((si) => si?.orderItem?.sku?.id),
      ],
      [],
    );

    const orderWithoutShipments = orderItems.filter(
      (oi) => !shipmentSkus.includes(oi?.sku?.id),
    );

    return (
      <>
        {shipments.map((shipment, index) => {
          if (fromOrderDetails && shipment.id === shipment_id) return null;
          return (
            <>
              <Shipment
                navigation={navigation}
                shipment={shipment}
                index={index}
                totalShipments={shipments.length}
                id={
                  Utility.isPresent(shipment)
                    ? `shipment-${shipment?.id}`
                    : `order-${id}`
                }
                renderProp={this.renderOrderItemCard}
                fromOrderDetails={fromOrderDetails}
                onlyOneOrder={onlyOneOrder}
                platform={platform}
                merchantOrderName={merchantOrderName}
                isSelectOrderView={isSelectOrderView}
                order_id={Utility.isBlank(shipment) && id}
              />
              <Shipment
                navigation={navigation}
                orderItems={orderWithoutShipments}
                id={`order-${id}`}
                showOrder
                renderProp={this.renderOrderItemCard}
                platform={platform}
                fromOrderDetails={fromOrderDetails}
                merchantOrderName={merchantOrderName}
                isSelectOrderView={isSelectOrderView}
                order_id={id}
              />
            </>
          );
        })}
      </>
    );
  };

  renderAlerts = () => {
    const { orderHistoryItem: { refundRequests = [] } = {} } = this.props;

    return refundRequests.map((refundRequest) => {
      if (isBlank(refundRequest?.alert)) {
        return null;
      }

      return (
        <Alert
          key={refundRequest.id}
          itemData={refundRequest?.alert}
          fullWidth
        />
      );
    });
  };

  render() {
    const { orderHistoryItem: { id, merchantOrderName = '' } = {} } =
      this.props;

    const merchentId = Utility.isBlank(merchantOrderName)
      ? '#'
      : merchantOrderName;
    const idTobeShown = AppConfig.getBooleanValue(
      Config.SHOW_ORDER_PAGE_MERCHENT_ID,
    )
      ? merchentId
      : id;
    return (
      <>
        <View style={styles.container}>
          <View style={styles.innerContainer}>
            <Text style={styles.orderId}>{`ORDER #${idTobeShown}`}</Text>
          </View>

          <this.renderShipmentOrOrderItems />
        </View>
        <View>
          <this.renderAlerts />
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingLeft: 12,
    marginTop: 12,
    backgroundColor: 'white',
  },
  innerContainer: {
    height: 40,
    justifyContent: 'center',
  },
  orderId: {
    color: '#979BAA',
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
  },
  orderItem: {
    backgroundColor: colors.white,
  },
});
