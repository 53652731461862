import { StyleSheet } from 'react-native';
import React from 'react';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import Utility from '../../../utils/Utility';
import colors from '../../../theme/Colors';

const CheckoutCard = ({ navigation, heading }) => {
  const checkoutNow = () => {
    navigation.push('Cart');
  };

  return (
    <FoxyShadowButton
      title={heading}
      onPress={checkoutNow}
      backgroundColor={colors.black}
      style={styles.buttonContainer}
      firstColor={colors.black}
      secondColor={colors.black}
      underlayColor={colors.black}
    />
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    marginTop: 3,
    width: Utility.getScreenWidth() - 24,
    alignSelf: 'center',
    alignItems: 'center',
    backgroundColor: 'red',
  },
});

export default CheckoutCard;
