import { View, StyleSheet } from 'react-native';
import React from 'react';
import Modal from 'react-native-modal';
import Config from '../../libraries/ReactNativeConfig';
import { useApiCall, useAuthToken } from '../../lib/hooksUtil';
import { URL } from '../../config/Constants';
import UserTitleAccountOption from './UserTitleAccountOption';
import AccountOptionDivider from './AccountOptionDivider';
import LogoutOption from './LogoutOption';
import GetAppOption from './GetAppOption';
import { isPresent } from '../../utils/BooleanUtility';
import LoginOption from './LoginOption';
import DermatOption from './DermatOption';
import DermaLoginOption from './DermatLoginOption';
import { getStoreRef } from '../../store/StoreUtility';

export default function AccountModal({ showModal = false, toggleModal = () => {}, navigation, dermatOption = [] }) {

  const authToken = useAuthToken();
  const accountOptions = isPresent(authToken)
    ? Config.LOGGED_IN_ACCOUNT_OPTIONS
    : Config.LOGGED_OUT_ACCOUNT_OPTIONS;

  const options = accountOptions.split(',') || [];
  const [titleOption, ...restOptions] = options;
  const optionContainerStyle = isPresent(dermatOption)
    ? styles.optionsContainer
    : styles.optionsContainerWithPaddingBottom;
  return (
    <Modal
      onBackdropPress={toggleModal}
      isVisible={showModal}
      backdropColor='transparent'
      styles={styles.modal}
      animationInTiming={1}
      animationOutTiming={1}
    >
      <View style={styles.modalContainer}>
        <Option option={titleOption} navigation={navigation} toggleModal={toggleModal} />
        <AccountOptionDivider />
        <View style={optionContainerStyle}>
          {restOptions.map(option => (
            <Option
              key={option}
              option={option}
              navigation={navigation}
              toggleModal={toggleModal}
            />
            ))}
        </View>
        {isPresent(dermatOption) && (
          <View style={styles.dermatOptionsContainer}>
            {dermatOption.map((option) => (
              <GetDermatOption key={option} option={option} navigation={navigation} toggleModal={toggleModal} />
            ))}
          </View>
        )}
      </View>
    </Modal>
  );
}

const optionMappings = {
  login: LoginOption,
  logout: LogoutOption,
  userTitle: UserTitleAccountOption,
  getApp: GetAppOption,
  dermatSignUp: DermaLoginOption,
};

const Option = ({ option, navigation, toggleModal }) => {
  const isInfluencerProfileCreated = getStoreRef()?.getState()?.UserAccountInfo?.isInfluencerProfileCreated;
  if (isInfluencerProfileCreated && option === 'dermatSignUp') return null;
  const OptionComponent = optionMappings[option];
  if (!OptionComponent) return null;
  return <OptionComponent navigation={navigation} toggleAccountModal={toggleModal} option={option} />;
};

const GetDermatOption = ({ option, navigation, toggleModal }) => {
  return <DermatOption navigation={navigation} toggleAccountModal={toggleModal} option={option} />;
};

const styles = StyleSheet.create({
  modal: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  modalContainer: {
    borderRadius: 4,
    backgroundColor: 'white',
    position: 'absolute',
    top: 5,
    right: -30,
    boxShadow: '10px 0px 10px rgba(0,0,0,0.1), 0px 10px 10px 1px rgba(0,0,0,0.1)',
  },
  optionsContainer: {
    paddingTop: 12,
    paddingHorizontal: 14,
  },
  dermatOptionsContainer: {
    paddingHorizontal: 14,
    paddingBottom: 8,
  },
  optionsContainerWithPaddingBottom: {
    paddingTop: 12,
    paddingHorizontal: 14,
    paddingBottom: 8,
  },
});
