import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import colors from '../../theme/Colors';
import { isPresent } from '../../utils/BooleanUtility';

const VariantCount = (props) => {
  const { variantsCount, variantText } = props;
  let property = variantsCount > 1 ? 'Variants' : 'Variant';
  if (isPresent(variantText)) {
    property = variantText;
  }
  return (
    <View style={styles.variantSizeTextContainer}>
      <Text style={styles.variantCount}>{variantsCount}</Text>
      <Text
        style={styles.variantCountText}
        numberOfLines={1}
        ellipsizeMode="tail"
      >
        {property}
      </Text>
    </View>
  );
};
export default function ProductOtherVariants(props) {
  const { variantsCount, variantText, containerStyle } = props;
  if (
    variantsCount <= 0 ||
    AppConfig.getBooleanValue(Config.SHOW_ONLY_COLOR_VARIANTS_ON_PRODUCT_CARDS)
  ) {
    return null;
  }
  return (
    <View style={[styles.variantShadeContainer, containerStyle]}>
      <VariantCount variantsCount={variantsCount} variantText={variantText} />
    </View>
  );
}

const styles = StyleSheet.create({
  variantCountText: {
    fontSize: 10,
    marginLeft: 2,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  variantCount: {
    fontSize: 10,
    marginLeft: 0,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
  },
  variantSizesContainer: {
    flexDirection: 'row',
    padding: 1,
    paddingHorizontal: 6,
    marginBottom: 4,
    // justifyContent: 'center',
    alignItems: 'center',
  },
  variantSizeTextContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 2,
    paddingVertical: 0,
  },
});
