import React, { useEffect, useRef } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import Toast from 'react-native-easy-toast';
import { isBlank } from '../../utils/BooleanUtility';
import { ids, styles } from './styles';
import { AnalyticsManager, EventType } from '../../analytics';
import DashboardPlaceHolder from './DashboardPlaceHolder';
import { URL } from '../../config/Constants';
import { useApiCall } from '../../lib/hooksUtil';
import { List } from '../../containers/List';

export default function Dashboard(props) {
  const { previousScreen = '' } = props;
  const toastRef = useRef(null);
  const handle = useSelector((state) => state.UserAccountInfo.profile.handle) || '';

  const { data, isLoading } = useApiCall(`${URL.DERMAT_PROFILE}/profile_completion_cards`, []);
  const authToken = useSelector((state) => state?.UserAccountInfo?.authToken);
  useEffect(() => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.INFLUENCER_DASHBOARD_VIEW, {
      foxy_handle: handle,
    });
  }, []);

  if (isBlank(authToken)) return null;

  if (isLoading) {
    return <DashboardPlaceHolder />;
  }
  if (isBlank(data)) return null;
  return (
    <>
      <View style={styles.dashboardContainer} dataSet={{ media: ids.dashboardContainer }}>
        <List itemData={data} previousScreen={previousScreen} />
      </View>
      <Toast style={{ position: 'absolute', bottom: 20 }} ref={toastRef} />
    </>
  );
}
