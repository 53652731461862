import Config from 'react-native-config';
import { ReactMoE } from '../../libraries/ReactMoe';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import Utility from '../Utility';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import DynamicLinkManager from '../DynamicLinkManager';
import NavigationService from '../../navigator/NavigationService';

const fireInAppEvents = (Event, inAppInfo, extraData = {}) => {
  AnalyticsManager.logEvent(Event, {
    [EventParameterKey.CAMPAIGN_ID]: inAppInfo?.campaignData?.campaignId,
    [EventParameterKey.CAMPAIGN_NAME]: inAppInfo?.campaignData?.campaignName,
    ...extraData,
  });
};

export default class MoeEngageHandler {
  static setEventListeners = (onClickNotification) => {
    if (Utility.isWeb()) return;
    if (Utility.isAndroid()) {
      // In iOS push notifications are being handled natively
      ReactMoE.setEventListener('pushClicked', onClickNotification);
    }
    ReactMoE.setEventListener('inAppCampaignShown', (inAppInfo) => {
      fireInAppEvents(EventType.forceUpdate.IN_APP_CAMPAIGN_SHOWN, inAppInfo);
    });

    ReactMoE.setEventListener('inAppCampaignClicked', (inAppInfo) => {
      const {
        action: { navigationType, navigationUrl },
      } = inAppInfo;
      DynamicLinkManager.handleDynamicLink(
        navigationUrl,
        ({ route = '', slug = '', extra = {}, params = {}, url = '' }) => {
          NavigationService.push(route, {
            slug,
            extra,
            params,
            url,
          });
        },
      );
      fireInAppEvents(
        EventType.forceUpdate.IN_APP_CAMPAIGN_CLICKED,
        inAppInfo,
        { navigationUrl },
      );
    });

    ReactMoE.setEventListener('inAppCampaignDismissed', (inAppInfo) => {
      fireInAppEvents(EventType.forceUpdate.IN_APP_CAMPAIGN_DISMISS, inAppInfo);
    });

    ReactMoE.setEventListener('inAppCampaignCustomAction', (inAppInfo) => {
      fireInAppEvents(
        EventType.forceUpdate.IN_APP_CAMPAIGN_CUSTOM_ACTION,
        inAppInfo,
      );
    });
  };

  static removeEventListeners = () => {
    if (Utility.isWeb()) return;
    ReactMoE.removeEventListener('inAppCampaignShown');
    ReactMoE.removeEventListener('inAppCampaignClicked');
    ReactMoE.removeEventListener('inAppCampaignDismissed');
    ReactMoE.removeEventListener('inAppCampaignCustomAction');
  };

  static initialize = () => {
    if (Utility.isWeb()) return;
    const appId = __DEV__
      ? Config.NOTIFICATIONS_HANDLER_APP_ID_DEBUG
      : Config.NOTIFICATIONS_HANDLER_APP_ID;
    console.tron.log('APP ID', appId);
    ReactMoE.initialize(appId);
    MoEReactInbox.initialize(appId);
    ReactMoE.showInApp();
  };
}
