import React, { Component } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native';
import PropTypes from 'prop-types';
import { TapGestureHandler, State } from 'react-native-gesture-handler';

import Utility from '../../../utils/Utility';
import FoxyShadowButton from '../../../lib/FoxyShadowButton';
import colors from '../../../theme/Colors';
import styles from './styles/AlertBoxStyles';

class AlertBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  openWhatsApp = () => {
    Utility.openWhatsApp('Hi, I have a question about sign up.');
  };

  hyperLink = (props) => (
    <TouchableWithoutFeedback onPress={this.openWhatsApp}>
      <Text style={styles.hyperLink}>{props.children}</Text>
    </TouchableWithoutFeedback>
  );

  alertMessageContainer = (props) => {
    const messageBoxHeight = props.hideSecondButton
      ? props.alertBoxHeight - 46 * 1
      : props.alertBoxHeight - 46 * 2;
    return (
      <View
        style={[styles.alertMessageContainer, { height: messageBoxHeight }]}
      >
        <Text style={styles.alertBoxTitle}>{props.alertBoxTitle}</Text>
        {props.showWhatsappHyperlink && (
          <>
            <Text style={styles.alertMessage}>{props.alertMessage}</Text>
            <Text style={styles.alertMessage}>
              <this.hyperLink>Chat with us on Whatsapp</this.hyperLink> for any
              question
            </Text>
          </>
        )}
        {!props.showWhatsappHyperlink && (
          <Text style={styles.alertMessage}>{props.alertMessage}</Text>
        )}
        <props.ChildrenComponent />
      </View>
    );
  };

  actionButton = (props) => {
    const { hideSecondButton } = props;
    if (hideSecondButton) {
      return null;
    }
    return (
      <TouchableOpacity
        style={[
          styles.actionButtonsContainer,
          { borderBottomWidth: props.borderBottomWidth },
        ]}
        onPress={props.onPress}
      >
        <Text style={[styles.actionButtonText, { color: props.textColor }]}>
          {props.title}
        </Text>
      </TouchableOpacity>
    );
  };

  actionButtonsContainer = (props) => (
    <View style={styles.actionButtonsContainer}>
      <TouchableOpacity
        style={styles.firstActionButtonContainer}
        onPress={props.firstButtonOnPress}
      >
        <Text
          style={[
            styles.firstActionButtonTitle,
            { color: props.firstButtonTextColor },
          ]}
        >
          {props.firstButtonTitle}
        </Text>
      </TouchableOpacity>
      <FoxyShadowButton
        width={165}
        title={props.secondButtonTitle}
        onPress={props.secondButtonOnPress}
        backgroundColor={props.secondButtonBackgroundColor}
      />
    </View>
  );

  onTapHandlerStateChange = (event) => {
    const { onTapOutside } = this.props;
    if (event.nativeEvent.state === State.ACTIVE) {
      onTapOutside();
    }
  };

  render() {
    const {
      height,
      alertBoxTitle,
      alertMessage,
      firstButtonTitle,
      firstButtonTextColor,
      secondButtonTitle,
      secondButtonTextColor,
      firstButtonOnPress,
      secondButtonOnPress,
      isVisible,
      Children,
      onTapOutside,
      hideSecondButton = false,
      showWhatsappHyperlink,
    } = this.props;
    if (isVisible === false) {
      return null;
    }
    const ChildrenComponent = Children;
    return (
      <TouchableWithoutFeedback onPress={onTapOutside}>
        <View style={styles.alertBoxBehindArea}>
          <TapGestureHandler
            onHandlerStateChange={this.onTapHandlerStateChange}
          >
            <View
              style={[
                styles.alertBoxBehindArea,
                { backgroundColor: 'rgba(0,0,0,0.3)' },
              ]}
            />
          </TapGestureHandler>
          <View
            style={[
              styles.alertBoxContainer,
              { top: (Utility.screenHeight - height) / 2, height },
            ]}
          >
            <this.alertMessageContainer
              alertMessage={alertMessage}
              alertBoxTitle={alertBoxTitle}
              alertBoxHeight={height}
              ChildrenComponent={ChildrenComponent}
              hideSecondButton={hideSecondButton}
              showWhatsappHyperlink={showWhatsappHyperlink}
            />
            <this.actionButton
              borderBottomWidth={1}
              textColor={firstButtonTextColor}
              onPress={firstButtonOnPress}
              title={firstButtonTitle}
              hideSecondButton={false}
            />
            <this.actionButton
              borderBottomWidth={0}
              textColor={secondButtonTextColor}
              onPress={secondButtonOnPress}
              title={secondButtonTitle}
              hideSecondButton={hideSecondButton}
            />
          </View>
        </View>
      </TouchableWithoutFeedback>
    );
  }
}

AlertBox.propTypes = {
  isVisible: PropTypes.bool,
  height: PropTypes.number,
  alertBoxTitle: PropTypes.string,
  alertMessage: PropTypes.string,
  firstButtonTitle: PropTypes.string,
  firstButtonTextColor: PropTypes.string,
  secondButtonTitle: PropTypes.string,
  secondButtonTextColor: PropTypes.string,
  firstButtonOnPress: PropTypes.func,
  secondButtonOnPress: PropTypes.func,
  Children: PropTypes.func,
  onTapOutside: PropTypes.func,
  showWhatsappHyperlink: PropTypes.bool,
};

AlertBox.defaultProps = {
  isVisible: false,
  height: 206,
  alertBoxTitle: 'Save as Draft?',
  alertMessage:
    'Drafts let you save your edits to that you can come back later',
  firstButtonTitle: 'Save draft',
  firstButtonTextColor: colors.enabled,
  secondButtonTextColor: colors.foxyBlack,
  secondButtonTitle: 'Discard',
  secondButtonBackgroundColor: colors.enabled,
  firstButtonOnPress: () => {},
  secondButtonOnPress: () => {},
  Children: () => <View />,
  onTapOutside: () => {},
  showWhatsappHyperlink: false,
};

export default AlertBox;
