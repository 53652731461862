import React, { useEffect, useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity, StyleSheet, KeyboardAvoidingView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { parseInt } from 'lodash';
import DropDownPicker from 'react-native-dropdown-picker';
import { ids, styles } from './styles';
import { isDesktop, isIOS, isPresent } from '../../utils/BooleanUtility';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import SaveProfileButton from './SaveProfileButton';
import { useApiCallDeserializer, useApiResponseDeserializer, usePostApi } from '../../lib/hooksUtil';
import { REMOTE_CONFIG_KEYS, URL } from '../../config/Constants';
import Certificate from './Certificate';
import { StaticNavigationHeader } from '../shared';
import colors from '../../theme/Colors';
import Header from './Header';
import { removeEmptyObjects } from '../../utils/UriUtility';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';


const PlatformAwareKeyboardAvoidingView = withEither(
  isIOS,
  KeyboardAvoidingView,
)(View);

export default function AboutYou() {
  const navigation = useNavigation();
  const [experience, setExperience] = useState('');
  const [certificates, setCertificates] = useState([{}]);
  const [uploadedCertificates, setUploadedCertificates] = useState([{}]);
  const [open, setOpen] = useState(false);
  const [deletedCertificate, setDeletedCertificate] = useState([]);
  const { data } = useApiCallDeserializer({ url: URL.GET_DERMAT_PROFILE });
  const [uploadedDetails, setUploadedDetails] = useState(data);
  const { postApi, success, loading: isLoading, response } = usePostApi();
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['aboutYou']?.title;
  const headerSubtitle = headerData['aboutYou']?.subtitle;

  useEffect(() => {
    if (isPresent(data)) {
      setUploadedDetails(data);
    }
  }, [data]);

  useEffect(() => {
    if (certificates.length === 0) {
      setCertificates([{}]);
    }
  }, [certificates]);
 
  const { response: uploadedData } = useApiResponseDeserializer(response);

  useEffect(() => {
    if (isPresent(uploadedData)) {
      setUploadedDetails(uploadedData);
    }
  }, [uploadedData]);

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.ABOUT_YOU,
    });
  }, []);


  useEffect(() => {
    if (isPresent(uploadedDetails)) {
      const { experience: exp, degrees = {} } = uploadedDetails;
      if (exp) {
        setExperience(exp);
      }
      if (isPresent(degrees)) {
        setCertificates(degrees);
        setUploadedCertificates(degrees);
      }
    }
  }, [uploadedDetails]);

  useEffect(() => {
    if (success) {
      navigation.navigate('ConsultationDetails');
    }
  }, [success]);

  const goBack = () => {
    navigation.goBack();
  };

  const handlePress = () => navigation.push('TabNavigator');

  // Usage in your onSubmit function
  const onSubmit = () => {
    let certificateData = certificates;
    if (isPresent(deletedCertificate) && isPresent(certificates)) {
      certificateData = [...certificates, ...deletedCertificate];
    }

    // Remove empty objects from the array
    certificateData = removeEmptyObjects(certificateData);
    const { isError, certificatesWithErrors } = checkCertificateErrors(certificateData);
    setCertificates(certificatesWithErrors);

    if (isError) {
      return;
    }
    const degreeParams = certificateData.map(
      ({ id, title, specialization, certificate, institute, graduation, _destroy }, index) => ({
        id,
        title,
        specialization,
        certificate,
        institute,
        graduation,
        display_order: index,
        _destroy,
      }),
    );

    const requestParams = {
      dermat_profile: {
        experience,
        degrees_attributes: degreeParams,
      },
    };
    postApi(URL.DERMAT_PROFILE, requestParams);
  };

  const addCertificate = () => {
    setCertificates([...certificates, {}]);
  };

  const deleteCertificate = (index) => {
    //store deleted certificate in different state
    const deletedCertificateData = certificates
      .filter((_, currIndex) => currIndex === index)
      .map((item) => ({ ...item, _destroy: 1 }));
    setDeletedCertificate([...deletedCertificate, ...deletedCertificateData]);
    //remove deleted certificate from original data to remove it from Ui
    setCertificates(certificates.filter((_, currIndex) => currIndex !== index));
  };

  const editCertificate = (certificate, index) => {
    setCertificates(certificates.map((cert, currIndex) => (currIndex === index ? certificate : cert)));
  };

  const dropdownData = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '10+'];
  const dropdownItems = dropdownData.map((item) => ({
    label: item === '0' ? 'Choose' : `${item} years`, 
    value: item,
  }));

  return (
    <PlatformAwareKeyboardAvoidingView style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <ScrollView style={styles.scrollView}>
          <View style={aboutYouStyles.aboutYouStyles}>
            <View dataSet={{ media: ids.formContainer }} style={aboutYouStyles.subContainer}>
              <View style={aboutYouStyles.dropDownContainer}>
                <Text style={styles.label}>Years of experience (if any)</Text>
                <DropDownPicker
                  open={open}
                  value={experience}
                  items={dropdownItems}
                  setOpen={setOpen}
                  setValue={setExperience}
                  placeholder='Year of experience (if any)'
                  placeholderStyle={aboutYouStyles.placeholder}
                  style={aboutYouStyles.picker} // Add zIndex here
                  dropDownContainerStyle={aboutYouStyles.dropdownContainer} // Add zIndex here
                  arrowIconStyle={aboutYouStyles.arrowIcon}
                  textStyle={aboutYouStyles.text}
                  listMode='SCROLLVIEW' // Use SCROLLVIEW for a smoother experience
                />
              </View>
              <View style={aboutYouStyles.paddingContainer}>
                <Text style={aboutYouStyles.qualificationText}>Qualifications</Text>
                {certificates?.map((certificate, index) => (
                  <Certificate
                    certificate={certificate}
                    editCertificate={editCertificate}
                    index={index}
                    deleteCertificate={deleteCertificate}
                    uploadedCertificates={uploadedCertificates}
                    addCertificate={addCertificate}
                  />
                ))}
              </View>
            </View>
          </View>
        </ScrollView>
        <SaveProfileButton onPress={onSubmit} loading={isLoading} screenName={SCREEN_CONSTANTS.ABOUT_YOU} />
      </View>
    </PlatformAwareKeyboardAvoidingView>
  );
}

const aboutYouStyles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  subContainer: {
    paddingTop: 24,
    paddingHorizontal: 12,
  },
  paddingContainer: {
    padding: 16,
  },
  dropDownContainer: {
    padding: 16,
    zIndex: 1000,
  },
  qualificationText: {
    color: colors.grey,
    fontFamily: 'Roboto-Medium',
  },
  picker: {
    borderWidth: 1,
    borderColor: '#d3d3d3',
    borderRadius: 5,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    // zIndex: 1000,
  },
  dropdownContainer: {
    borderWidth: 1,
    borderColor: '#d3d3d3',
    borderRadius: 5,
    maxHeight: 600,
  },
  placeholder: {
    color: '#6e6e6e', // Replace with your desired color
    fontSize: 16,
  },
  arrowIcon: {
    tintColor: '#6e6e6e', // Color for the dropdown arrow icon
  },
  text: {
    fontSize: 16,
    color: colors.foxyBlack,
  },
});

const checkCertificateErrors = (certificates = []) => {
  let isError = false;
  const certificatesWithErrors = certificates.map((certificate) => {
    const { graduation } = certificate;
    const currentYear = new Date().getFullYear();

    if (parseInt(graduation) > currentYear) {
      isError = true;
      return { ...certificate, graduationError: 'Please enter a year less than or equal to the current year.' };
    }

    // Clear any previous errors if the graduation year is valid
    return { ...certificate, graduationError: null };
  });

  return { isError, certificatesWithErrors };
};

