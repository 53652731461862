import { isPresent, isWeb } from './BooleanUtility';
import { isInStock } from './ProductUtility';
import Utility from './Utility';

export default class VariantUtility {
  static checkIfMultiVariant(multiVariantAttributes) {
    return (
      Utility.isPresent(multiVariantAttributes) &&
      Utility.isPresent(
        multiVariantAttributes[0]?.multi_level_variant_attributes,
      )
    );
  }

  static getParentVariantForChildVariantId(variantId, multiVariantAttributes) {
    return (
      multiVariantAttributes.filter(
        VariantUtility.filterParentAttributes(variantId),
      )?.[0] || {}
    );
  }

  static filterParentAttributes = (variantId) => (attribute) => {
    const { multi_level_variant_attributes: multiVariantAttributes = [] } =
      attribute || {};
    return Utility.isPresent(multiVariantAttributes)
      ? Utility.isPresent(
          multiVariantAttributes.filter(
            VariantUtility.filterParentAttributes(variantId),
          )?.[0],
        ) || false
      : VariantUtility.checkIfAttributeIsPresent(variantId)(attribute) || false;
  };

  static checkIfAttributeIsPresent = (variantId) => (attribute) => {
    return attribute.variant?.sku_id === variantId;
  };

}

export const findSkuIdByVariantId = (attributes = [], variantId) => {
  for (const attr of attributes) {
    if (attr.variant && attr.variant.id === +variantId) {
      return attr.variant.sku_id;
    }
    if (isPresent(attr.multi_level_variant_attributes)) {
      const result = findSkuIdByVariantId(attr.multi_level_variant_attributes, +variantId);
      if (result) {
        return result;
      }
    }
  }
  return null;
};

export const getMultiVariantOosConfig = (multiVariantAttrs = []) => {
  const result = { oos: true };
  multiVariantAttrs.forEach(({ value, multi_level_variant_attributes = [], variant }) => {
    if (isPresent(multi_level_variant_attributes)) {
      result[value] = getMultiVariantOosConfig(multi_level_variant_attributes);
      if (!result[value].oos) {
        result.oos = false;
      }
    } else {
      const isOutOfStock = isWeb() ? variant.outOfStock && !isInStock(variant.web_stocked_status) : variant.outOfStock;
      result[value] = {
        skuId: variant.sku_id,
        oos: isOutOfStock,
      };
      if (!isOutOfStock) {
        result.oos = false;
      }
    }
  });
  return result;
};

const createNestedMap = (arrays) => {
  const oos = { oos: true };

  const buildMap = (arr = []) => {
    if (arr.length === 0) {
      return { ...oos };
    }

    const [first, ...rest] = arr;
    const map = {};

    first.forEach((key) => {
      map[key] = buildMap(rest);
    });

    return { ...oos, ...map };
  };

  return buildMap(arrays);
};

const updateOosStatus = (obj) => {
  // Get all keys of the object
  const keys = Object.keys(obj);

  if (keys.length === 1) {
    return;
  }
  // Iterate through each key
  keys.forEach((key) => {
    const value = obj[key];

    // If the value is an object, recursively update its children
    if (typeof value === "object" && value !== null) {
      updateOosStatus(value);
    }
  });

  // Now determine the `oos` status for the current object
  // Check if all children's `oos` values are true
  const allTrue = keys.every((key) => key === 'oos' || (typeof obj[key] === "object" && obj[key].oos === true));

  // If all children's `oos` values are true, set the parent's `oos` to true
  // Otherwise, set it to false
  obj.oos = allTrue;
};

export const getShopifyOosVariantsConfig = (nodes = [], options = []) => {
  const optionValues = [];
  options.forEach(({ values }) => { optionValues.push(values); });
  const oosConfig = createNestedMap(optionValues);

  nodes.forEach(({ selectedOptions = [], availableForSale }) => {
    let temp = oosConfig;
    selectedOptions.forEach(({ value }) => {
      temp = temp[value];
    });
    temp.oos = !availableForSale;
  });

  updateOosStatus(oosConfig);
  return oosConfig;
};
