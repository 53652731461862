import {
  KeyboardAvoidingView,
  Text,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
  View,
} from 'react-native';
import React from 'react';
import { useRoute } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { isIOS } from '../../utils/BooleanUtility';

export default function SaveProfileButton({ onPress = () => {}, loading = false, buttonText = 'Continue', screenName = '' }) {
  const { params = {} } = useRoute();
  const { source = '' } = params;
  const fromDashboard = source === 'dashboard';
  const buttonStyle = loading ? styles.buttonInLoadingState : styles.button;
  const onPressCta = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.TYPE]: EventType.INFLUENCER.DERMAT_PROFILE,
      [EventParameterKey.CTA]: buttonText,
      [EventParameterKey.SCREEN_NAME]: screenName,
    });
    onPress();
  };
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={styles.buttonContainer}
      dataSet={{ media: ids.buttonContainer }}
    >
      <TouchableOpacity style={buttonStyle} onPress={onPressCta} disabled={loading}>
        {loading && (
          <View style={styles.loader}>
            <ActivityIndicator color={colors.white} size='small' />
          </View>
        )}
        <Text style={styles.buttonText}>{fromDashboard ? 'Save' : buttonText}</Text>
      </TouchableOpacity>
    </KeyboardAvoidingView>
  );
}

const { ids, styles } = StyleSheet.create({
  buttonContainer: {
    marginBottom: isIOS() ? 30 : 10,
    paddingTop: 12,
    borderTop: '0.5px solid #dfe0e1',
    paddingHorizontal: 12,
    zIndex: 5,
    '@media (min-width: 768px)': {
      width: 480,
    },
  },
  button: {
    backgroundColor: colors.black,
    borderRadius: 4,
    alignItems: 'center',
  },
  buttonInLoadingState: {
    backgroundColor: colors.black,
    borderRadius: 4,
    alignItems: 'center',
    opacity: 0.6,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    marginVertical: 12,
  },
  loader: {
    position: 'absolute',
    top: 12,
    left: 20,
  },
});
