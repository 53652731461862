import React, { Component, PureComponent } from 'react';
import {
  StyleSheet, View, Text, Image, TouchableWithoutFeedback,
} from 'react-native';

import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

const styles = StyleSheet.create({
  cardContainerSelected: {
    height: 80,
    borderColor: colors.green,
    borderWidth: 1,
    padding: 8,
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 4,
    marginTop: 10,
    borderRadius: 4,
    flexDirection: 'row',
  },
  cardContainer: {
    height: 80,
    borderColor: colors.border,
    borderWidth: 1,
    padding: 8,
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 4,
    marginTop: 10,
    borderRadius: 4,
    flexDirection: 'row',
  },
  cardIcon: {
    height: 48,
    width: 48,

    alignSelf: 'center',
    borderRadius: 2,
  },
  cardContent: {
    flex: 0.9,
    paddingLeft: 10,
    paddingRight: 4,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  cardRightArrow: {
    flex: 0.1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    color: colors.foxyBlack,
    fontSize: size.h3,
  },
  description: {
    color: colors.silver,
    fontSize: size.h4,
  },
  circularCheckLayout: {
    position: 'absolute',
    right: 15,
    top: 15,
  },
});

export default class OnboardingSalonCard extends PureComponent {
  cardClicked = () => {
    const { cardClick, itemData } = this.props;
    cardClick(itemData.id);
  };

  _checkMark = () => (
    <View style={styles.circularCheckLayout}>
      <Image source={images.greenCircularTick} />
    </View>
  );

  render() {
    const {
      id, image_url, name, short_address, cardClick, selectedId,
    } = this.props;

    const image = image_url !== ''
      ? image_url
      : 'https://cdn.iconscout.com/icon/free/png-256/beauty-care-cosmetics-makeup-lipstick-lip-color-fashion-7-774.png';

    return (
      <TouchableWithoutFeedback onPress={cardClick}>
        <View style={id !== selectedId ? styles.cardContainer : styles.cardContainerSelected}>
          <FastImageView
            style={styles.cardIcon}
            source={getMinifiedImage(image, styles.cardIcon.width, styles.cardIcon.height)}
            resizeMode="cover"
          />
          <View style={styles.cardContent}>
            <Text style={styles.heading}>{name}</Text>
            <Text style={styles.description}>{short_address}</Text>
          </View>
          {/* <View style={styles.cardRightArrow}>
            <Image source={images.chevronRight} style={{ tintColor: colors.foxyBlack }} />
          </View> */}
          {id === selectedId && <this._checkMark />}
        </View>
      </TouchableWithoutFeedback>
    );
  }
}
