import React, { useEffect, useRef, useState } from 'react';
import { View, Text, TextInput, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import { memoize } from 'lodash';
import colors from '../../theme/Colors';
import { styles as formStyles } from './styles';
import { isBlank, isDesktop } from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';

const DegreeTitlePicker = ({ index, editCertificate, certificate = {}, certificate: { title = '' } }) => {
  const { degrees: degreeOptions = [] } =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_degrees_data)) || [];
  const titleRef = useRef(null);
  const [showSearchSuggest, setShowSearchSuggest] = useState(false);
  const [suggestions, setSuggestions] = useState(degreeOptions);
  const [textValue, setTextValue] = useState(title);

  useEffect(() => {
    const handleClick = () => {
      if (isDesktop()) {
        hideSearchSuggest();
      }
    };

    if (isDesktop()) {
      document.addEventListener('click', handleClick);
    }

    return () => {
      if (isDesktop()) {
        document.removeEventListener('click', handleClick);
      }
    };
  }, []);


  const showsSearchSuggest = () => {
    if (textValue) {
      setShowSearchSuggest(true);
    }
  };

  const hideSearchSuggest = () => setShowSearchSuggest(false);

  const handleChangeText = (text) => {
    setTextValue(text);
    editCertificate({ ...certificate, title: text.toUpperCase() }, index);
    if (text) {
      setShowSearchSuggest(true);
      setSuggestions(
        degreeOptions.filter((suggestion) =>
          suggestion?.toLowerCase().includes(text.toLowerCase())
        )
      );
    } else {
      setShowSearchSuggest(false);
    }
  };

  const onOptionsClick = (option) => {
    hideSearchSuggest();
    titleRef.current.setNativeProps({ selection: { start: 0, end: 0 } });
    setTextValue(option);
    editCertificate({ ...certificate, title: option }, index);
  };

  const renderSuggestionItem = ({ item, index }) => (
    <TouchableOpacity
      onPress={memoize(
        () => onOptionsClick(item),
        () => [index],
      )}
      style={styles.searchSuggestItem}
    >
      <View style={styles.searchSuggestTextBox}>
        <Text numberOfLines={1} style={styles.searchSuggestText}>
          {item}
        </Text>
      </View>
    </TouchableOpacity>
  );

  const degreeTitle = isBlank(textValue) ? title : textValue;

  return (
    <View style={styles.container}>
      <Text style={formStyles.label}>Degree</Text>
      <TextInput
        ref={titleRef}
        label="Degree*"
        placeholder="Enter degree title"
        placeholderTextColor={colors.subtitle}
        value={degreeTitle}
        onChangeText={handleChangeText}
        style={styles.textInput}
        scrollEnabled={false}
      />
      {showSearchSuggest && Boolean(suggestions.length) && (
        <View style={styles.optionsContainer}>
          <FlatList
            data={suggestions}
            renderItem={renderSuggestionItem}
            keyExtractor={(item) => item}
            style={styles.flatListContainer}
            keyboardShouldPersistTaps="handled" // To handle taps on suggestions while keyboard is up
          />
        </View>
      )}
    </View>
  );
};

export default DegreeTitlePicker;

const styles = StyleSheet.create({
  container: {
    zIndex: 1,
    marginBottom: 10,
  },
  optionsContainer: {
    backgroundColor: colors.white,
    width: '100%',
    elevation: 5, // Android shadow effect
    shadowColor: '#000', // iOS shadow effect
    shadowOpacity: 0.3,
    shadowRadius: 4,
    shadowOffset: { width: 0, height: 2 },
    maxHeight: 400, // Restrict max height so it scrolls when items exceed
    borderRadius: 4,
    borderWidth: 1,
    borderColor: colors.grey,
    zIndex: 1000, // Ensure it stays on top
  },
  flatListContainer: {
    flexGrow: 0, // Prevents ScrollView from taking up all available space
  },
  textInput: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
    height: 40,
    paddingHorizontal: 10,
    width: '100%',
    color: colors.black,
  },
  searchSuggestItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 8,
    borderBottomWidth: 0.7,
    borderBottomColor: colors.background,
    paddingHorizontal: 10,
  },
  searchSuggestText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    flex: 1,
  },
  searchSuggestTextBox: {
    flex: 1,
  },
});
