import _, { memoize } from 'lodash';
import React, { PureComponent } from 'react';
import { View, Text } from 'react-native';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import styles from './styles';
import FastImageView from '../../components/FastImageView';
import { isPresent } from '../../utils/BooleanUtility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import { isRoutineLive } from '../../components/Routines/RoutinesUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class SearchSuggestTile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      itemData: props.itemData,
    };
    const {
      itemData: { routine = {} },
    } = props;
    if (isPresent(routine)) {
      try {
        new JSONAPIDeserializer({
          typeAsAttribute: false,
          pluralizeType: true,
          keyForAttribute: 'snake_case',
        })
          .deserialize(routine)
          .then((data) => {
            console.tron.log('routines data thin', data);
            this.setState({ itemData: { ...data, type: 'routine' } });
          });
      } catch (e) {}
    }
  }

  render() {
    const { itemData: item } = this.state;
    const { skus, itemData, updateQuerySelect, index } = this.props;

    const isFlashDealAvailable = _.some(item.sku_ids, (itemObject) => skus[`${itemObject}`]);

    let searchSuggestImageStyle = isFlashDealAvailable ? styles.searchSuggestAnimation : styles.searchSuggestIcon;

    let searchAutosuggestImage = isFlashDealAvailable ? images.itemLikeAnimation : images.search_autosuggest_icon;

    if (Utility.isPresent(item?.image_url)) {
      searchAutosuggestImage = item.image_url;
      searchSuggestImageStyle = styles.searchSuggestImage;
    }
    const { name: artistName, imageUrl } = item?.artist || {};
    const artistImage = Utility.isBlank(imageUrl) ? images.routines.artist_placeholder : imageUrl;
    const isLive = isRoutineLive(item.slug);

    return (
      <DebouncedTouchableOpacity
        onPress={memoize(
          () => updateQuerySelect(item.name, item, index, isFlashDealAvailable),
          () => [item.name, item.id, index],
        )}
        style={styles.searchSuggestItem}
        hitSlop={Utility.getHitSlop()}
      >
        <FastImageView
          source={getMinifiedImage(
            searchAutosuggestImage,
            searchSuggestImageStyle.width,
            searchSuggestImageStyle.height,
          )}
          style={searchSuggestImageStyle}
        />

        <View style={styles.searchSuggestTextBox}>
          <View
            style={item.type == 'product' ? styles.autoSuggestProductNameContainer : styles.autoSuggestNameContainer}
          >
            <Text numberOfLines={item.type == 'product' ? 2 : 1} style={styles.searchSuggestText}>
              {item.name}
            </Text>
          </View>
          {Utility.isPresent(item.subtext || item?.artist?.name) && (
            <View style={styles.flexDirectionRow}>
              {isPresent(item?.artist) && <FastImageView source={artistImage} style={styles.artistImage} />}
              <Text numberOfLines={1} style={styles.searchSuggestSubText}>
                {item.subtext || artistName}
              </Text>
              {isPresent(itemData.routine) && isLive && (
                  <View style={styles.liveContainer}>
                    <Text style={styles.liveText}>LIVE</Text>
                  </View>
                )}
            </View>
          )}
        </View>
      </DebouncedTouchableOpacity>
    );
  }
}

export default SearchSuggestTile;
