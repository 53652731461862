import React, { PureComponent } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Svg, Circle, Mask, Rect, Defs } from 'react-native-svg';
import ScaleAnimate from '../../components/shared/ScaleAnimate';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import RemoteConfig from '../../utils/RemoteConfig';
import moment from 'moment';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import NavigationService from '../../navigator/NavigationService';

class FoxyEdgeCouponCard extends PureComponent {
  constructor(props) {
    super(props);
    const { componentWidth } = this.props;
    this.cardWidth = componentWidth + 12;
    const { promptData: { reward_type = '' } = {} } = this.props;
    const couponColors = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.edge_coupon_colors),
    );
    this.couponColor = couponColors[`${reward_type}`];
  }

  maskedCard = ({ fillColor }) => {
    return (
      <Svg height={'100%'} width={'100%'}>
        <Defs>
          <Mask id={`clip`}>
            <Rect fill={colors.white} width="100%" height="100%" />
            <Circle
              fill={colors.black}
              r={'12'}
              cx={`80%`}
              cy={'0'}
              fill={colors.black}
            />
            <Circle
              fill={colors.black}
              r={'12'}
              cx={'80%'}
              cy={'120'}
              fill={colors.black}
            />
          </Mask>
        </Defs>
        <Rect width="100%" height="100%" fill={fillColor} mask={`url(#clip)`} />
      </Svg>
    );
  };

  expiryDateText = () => {
    const { promptData: { ends_at = '' } = {} } = this.props;
    const dateString = moment(ends_at).format('Do MMM, YYYY');
    return (
      <View style={styles.verticalRightText}>
        <Text style={styles.expireTitle}>Expires on</Text>
        <Text style={styles.expireDate}>{dateString}</Text>
      </View>
    );
  };

  verticalRightContainer = () => {
    return (
      <View style={styles.verticalRightContainer}>
        <Svg height={'100%'} width={'100%'}>
          <Defs>
            <Mask id={`clip1`}>
              <Rect fill={colors.white} width="100%" height="100%" />
              <Circle
                fill={colors.black}
                r={'12'}
                cx={'0'}
                cy={'0'}
                fill={colors.black}
              />
              <Circle
                fill={colors.black}
                r={'12'}
                cx={'0'}
                cy={'120'}
                fill={colors.black}
              />
            </Mask>
          </Defs>
          <Rect
            width="100%"
            height="100%"
            fill={this.couponColor}
            mask={`url(#clip1)`}
          />
        </Svg>
        <this.expiryDateText />
      </View>
    );
  };

  onNavigateToOfferDetail = () => {
    const {
      promptData: {
        url = '',
        name = '',
        coupon_code = '',
        offer_id = '',
        cta_text = '',
      } = {},
    } = this.props;

    console.tron.log('item data here', this.props);

    AnalyticsManager.logEvent(EventType.routine.ITEM_CLICK, {
      [EventParameterKey.TYPE]: 'offer_card',
      [EventParameterKey.NAME]: name,
      [EventParameterKey.COUPON_CODE]: coupon_code,
      [EventParameterKey.OFFER_ID]: offer_id,
      [EventParameterKey.CTA]: cta_text,
    });

    DynamicLinkManager.handleDynamicLink(
      url,
      ({ route = '', slug = '', extra = {} }) => {
        NavigationService.push(route, { slug, extra });
      },
    );
  };

  buttonContainer = () => {
    const {
      promptData: { coupon_code = '', status = '', cta_text = '' } = {},
      onPress = () => {},
    } = this.props;

    const firstButtonStyle = [
      styles.firstButton,
      {
        borderColor: this.couponColor,
        backgroundColor: `${this.couponColor}33`,
      },
    ];
    return (
      <View style={styles.buttonContainer}>
        <View style={firstButtonStyle}>
          <Text style={styles.firstButtonText}>{coupon_code}</Text>
        </View>
        <TouchableOpacity
          onPress={this.onNavigateToOfferDetail}
          style={styles.secondButton}
          hitSlop={Utility.getHitSlop('cross')}
        >
          <Text style={styles.secondButtonText}>{cta_text}</Text>
        </TouchableOpacity>
      </View>
    );
  };

  offerCardContainer = () => {
    const {
      promptData: { offer_display_name = '', name = '', message = '' } = {},
    } = this.props;
    return (
      <View style={styles.cardContainer}>
        <this.maskedCard fillColor={`${this.couponColor}33`} />
        <this.verticalRightContainer />
        <View style={styles.offerCardContentContainer}>
          <View style={styles.leftCardTitleContainer}>
            <Text
              style={styles.cardTitle}
              numberOfLines={1}
              ellipsizeMode="tail"
            >
              {offer_display_name || name}
            </Text>
            <Text
              style={styles.firstTitle}
              numberOfLines={2}
              ellipsizeMode={'tail'}
            >
              {message}
            </Text>
          </View>
          <this.buttonContainer />
        </View>
      </View>
    );
  };

  render() {
    const { onPress = () => {} } = this.props;
    return (
      <ScaleAnimate {...this.props} onPress={onPress}>
        <View style={styles.container}>
          <this.offerCardContainer />
        </View>
      </ScaleAnimate>
    );
  }
}
export default withNavigation(FoxyEdgeCouponCard);

const styles = StyleSheet.create({
  container: {
    alignSelf: 'center',
    justifyContent: 'center',
    height: 150,
    width: '100%',
  },
  cardContainer: {
    height: 120,
    alignSelf: 'center',
    backgroundColor: colors.transparent,
    borderRadius: 16,
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    width: '100%',
  },
  verticalTextContainer: {
    position: 'absolute',
    width: 120,
    height: 54,
    justifyContent: 'center',
    alignItems: 'center',
    right: -33,
    backgroundColor: '#96B5F8',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    transform: [{ rotate: '-90deg' }],
    overflow: 'hidden',
  },
  verticalContainerCornerCircle: {
    height: 24,
    width: 24,
    borderRadius: 12,
    position: 'absolute',
    top: -12,
    right: -8,
    backgroundColor: '#5B6986',
  },
  leftCardTitleContainer: {
    marginLeft: 14,
    marginTop: 12,
    height: 56,
  },
  firstTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h4,
    marginTop: 4,
  },
  cardTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
    // letterSpacing: 4,
  },
  verticalRightContainer: {
    height: 120,
    width: '20%',
    borderBottomRightRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
  },
  offerCardContentContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    flex: 1,
    backgroundColor: colors.transparent,
    width: '80%',
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 18,
  },
  firstButton: {
    maxWidth: 100,
    height: 20,
    borderStyle: 'dashed',
    borderRadius: 2,
    borderWidth: 1,
    marginLeft: 14,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 4,
  },
  firstButtonText: {
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    alignSelf: 'center',
    color: colors.foxyBlack,
  },
  secondButton: {
    width: 86,
    height: 19,
    backgroundColor: colors.white,
    borderRadius: 16,
    marginRight: 21,
  },
  secondButtonText: {
    fontFamily: 'Roboto-Medium',
    fontSize: size.h5,
    lineHeight: 12,
    alignSelf: 'center',
    color: colors.foxyBlack,
    padding: 4,
  },
  verticalRightText: {
    position: 'absolute',
    transform: [{ rotate: '-90deg' }],
    width: '100%',
  },
  expireTitle: {
    color: colors.white,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h5,
    alignSelf: 'center',
  },
  expireDate: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    fontSize: size.h5,
    alignSelf: 'center',
    marginTop: 4,
    textAlign: 'center',
  },
});
