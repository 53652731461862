import React from 'react';
import { View, Text } from 'react-native';
import { isPresent } from '../../utils/BooleanUtility';
import styles from './styles';
import FastImageView from '../../components/FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

const TagComponent = ({ imageUrl, name, backgroundColor }) => (
  <View style={[styles.tag, { backgroundColor }]}>
    <FastImageView
      source={getMinifiedImage(
        imageUrl,
        styles.tagIcon.width,
        styles.tagIcon.height,
      )}
      style={styles.tagIcon}
    />
    <Text style={styles.tagText}>{name}</Text>
  </View>
);

const Tags = (props) => {
  const {
    tags,
    influencer_tag_colors: tagColors = [],
    TagContainerStyle = {},
  } = props;
  const containerStyle = isPresent(TagContainerStyle)
    ? TagContainerStyle
    : styles.tagsContainer;
  return (
    <View style={containerStyle}>
      {tags.map((tag, index) => (
        <TagComponent
          key={index}
          imageUrl={tag.image_url}
          name={tag.name}
          backgroundColor={tagColors[index]}
        />
      ))}
    </View>
  );
};

export default Tags;
