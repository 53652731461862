import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  Vibration,
  Platform,
} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';
import colors from '../../../../theme/Colors';
import size from '../../../../theme/Fonts';
import {
  APP_ASSET_URL,
  ROUTINE_ACTIVITY_TYPES,
} from '../../../../config/Constants';
import images from '../../../../theme/Images';
import FoxyButton from '../../../shared/FoxyButton';
import _ from 'lodash';
import Utility from '../../../../utils/Utility';
import {
  getPollsData,
  selectPollOption,
} from '../../../../actions/RoutineActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DynamicLinkManager from '../../../../utils/DynamicLinkManager';
import withNavigation from '../../../../utils/WithNavigation';
import NavigationService from '../../../../navigator/NavigationService';
import { isBlank, isNative } from '../../../../utils/BooleanUtility';
var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class RoutineActionButton extends Component {
  LAYOUT_COMPONENT = {
    feature: this.featureTypeContent,
    upload: this.featureTypeContent,
    poll: this.pollTypeContent,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
    };
  }

  navigateToScreen = ({
    route = '',
    slug = '',
    extra = {},
    params = {},
    url = '',
  }) => {
    const { navigation } = this.props;
    navigation.navigate(route, { slug, extra, params, url });
  };

  setLoading = (isLoading) => {
    this.setState({
      isLoading: isLoading,
    });
  };

  navigateToScreen = ({ route = '', slug = '' }) => {
    NavigationService.push(route, {
      newDestination: slug, // webview reads data from destination
      slug,
    });
  };

  onActionButtonPress = () => {
    const {
      itemData: { activityItems = [], participationId = '', id = '' },
      onActionPress,
    } = this.props;
    const {
      activity: {
        arguments: extraData = {},
        destination = '',
        externalLink = '',
      } = {},
    } = activityItems[activityItems.length - 1];
    const navigateTo = destination || externalLink;
    this.setLoading(true);
    if (isNative()) {
      Vibration.vibrate(200);
    }

    if (Utility.isPresent(navigateTo)) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        navigateTo,
        this.navigateToScreen,
      );
    }

    const isUpload = extraData?.action === ROUTINE_ACTIVITY_TYPES.UPLOAD;
    const actionId = isUpload ? participationId : id;
    const activityType = isUpload
      ? ROUTINE_ACTIVITY_TYPES.UPLOAD
      : ROUTINE_ACTIVITY_TYPES.ACTION;
    onActionPress(id, activityType, this.setMarkAsDone, participationId);
  };

  setMarkAsDone = (markDone) => {
    const { setMarkAsDone = () => {} } = this.props;
    setMarkAsDone(markDone);
    this.setLoading(false);
  };

  getActionButtonColor = (isButtonDisabled) => {
    const { isLoading } = this.state;
    const { markAsDone } = this.props;
    let buttonColor = colors.subtitle;
    if (markAsDone) {
      buttonColor = colors.disabled_green;
    } else if (!isButtonDisabled && !isLoading) {
      buttonColor = colors.black;
    }
    return buttonColor;
  };

  getActionButtonImage = () => {
    const { isLoading } = this.state;
    const {
      itemData: { activity: { arguments: extraData = {} } = {} } = {},
      markAsDone,
    } = this.props;
    let buttonIcon = null;
    if (markAsDone) {
      buttonIcon = images.whiteTick;
    } else if (extraData?.action === 'upload') {
      buttonIcon = images.routines.upload_icon;
    }
    return buttonIcon;
  };

  actionButton = () => {
    const { buttonVisibility = '' } = this.props;
    const {
      itemData: { activityItems = [], ctaOptions = [] },
    } = this.props;
    const { activityType } = activityItems[activityItems.length - 1];
    const { text = '', arguments: extraData = {} } = ctaOptions;
    if (activityType === 'Poll' || isBlank(text)) {
      return null;
    }
    const { isLoading } = this.state;
    const { markAsDone } = this.props;
    if (buttonVisibility === 'hidden') return null;
    const title = markAsDone ? 'Done' : text;
    const isButtonDisabled = buttonVisibility === 'disabled';
    const buttonColor = this.getActionButtonColor(isButtonDisabled);
    const buttonIcon = this.getActionButtonImage();
    return (
      <View style={styles.actionButtonContainer}>
        <FoxyButton
          title={title}
          show_button_icon={markAsDone || extraData?.action === 'upload'}
          actionButtonColor={buttonColor}
          onActionPress={this.onActionButtonPress}
          layoutStyle={styles.actionButton}
          textStyle={styles.actionButtonText}
          disabled={isButtonDisabled || markAsDone || isLoading}
          icon_type={buttonIcon}
          iconStyle={styles.actionButtonIcon}
          showLoader={isLoading}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <this.actionButton />
      </View>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getPollsData,
      selectPollOption,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(null, mapDispatchToProps)(RoutineActionButton),
);

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 12,
    flex: 1,
    justifyContent: 'flex-start',
  },
  title: {
    color: colors.black,
    fontFamily: 'Roboto-Bold',
    fontSize: size.h1,
  },
  htmlText: {
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontFamily: 'Roboto-Regular',
    marginVertical: 4,
  },
  subtitle: {
    color: colors.foxyBlack,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  actionButtonContainer: {
    height: 40,
    width: '100%',
    alignSelf: 'center',
    backgroundColor: 'transparent',
  },
  actionButton: {
    left: 0,
    right: 0,
    height: 40,
    borderRadius: 8,
    ...Platform.select({
      web: { top: -20 },
      default: {},
    }),
  },
  actionButtonText: {
    color: colors.white,
    fontSize: size.h3,
    fontFamily: 'Roboto-Medium',
  },
  actionButtonIcon: {
    width: 18,
    height: 18,
    tintColor: colors.white,
    resizeMode: 'contain',
    marginRight: 4,
  },
  moreImagesText: {
    color: colors.black,
    fontSize: size.h4,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
  },
});
