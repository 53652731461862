// Dependencies
import React, { Component } from 'react';
import {
  View,
  Text,
  Image,
  TouchableOpacity,
  StyleSheet,
  Keyboard,
  TouchableWithoutFeedback,
  NativeModules,
  Platform,
} from 'react-native';
import { bindActionCreators } from 'redux';
import images from '../../theme/Images';
import Utility from '../../utils/Utility';
import colors from '../../theme/Colors';
import ActionButton from '../shared/ActionButton';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
  EventParameterValue,
} from '../../analytics';
import RemoteConfig from '../../utils/RemoteConfig';
import {
  APP_ASSET_URL,
  NOTIFICATION_REQUEST_MODAL_TYPE,
  PERMISSION_CONSTANTS,
  PERMISSION_STORE,
  REMOTE_CONFIG_KEYS,
} from '../../config/Constants';
import NotificationManager from '../../utils/NotificationsManager';
import animations from '../../theme/Animations';
import withNavigation from '../../utils/WithNavigation';
import { ReactMoE } from '../../libraries/ReactMoe';
import RoundedButton from '../../components/layout/buttons/RoundedButton';
import NotificationUtils, {
  checkAndroidNotificationStatusFromNativeLayer,
} from '../../utils/NotificationUtils';
import FastImageView from '../FastImageView';
import { BlurView } from '../../libraries/ReactNativeCommunityBlur';

import { connect } from 'react-redux';
import {
  incrementFoxyPermissionPrompt,
  setNotificationModalDisplayTime,
} from '../../actions/ActionTypes';
import { savePermissionStatus } from '../../utils/PermissionsUtility';
import { triggerPermissionsEvent } from '../../analytics/PermissionsEvents';
import { createAndroidNotificationChannels } from '../../utils/AndroidNotificationsUtils';
import { CommonActions } from '@react-navigation/native';

class NotificationModal extends Component {
  constructor(props) {
    super(props);
    this.bottomDockHeight = 70;
    this.state = {
      promptData: {},
      animationJson: null,
    };
    this.getRemoteConfigData();
    this.notificationModalAssets = {};
  }

  componentDidMount() {
    const { setNotificationModalDisplayTime, incrementFoxyPermissionPrompt } =
      this.props;

    this.fireEvent(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.MODAL_SHOWN,
    );

    const currentDate = new Date();
    setNotificationModalDisplayTime(currentDate.getTime());
    incrementFoxyPermissionPrompt();
  }

  getRemoteConfigData = async () => {
    const { promptData } = this.state;
    this.fetchAnimationJson();
    const remoteRequestData = await JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );
    if (Utility.isBlank(promptData) && Utility.isPresent(remoteRequestData)) {
      this.setState({
        promptData: remoteRequestData,
      });
    }

    this.notificationModalAssets = Utility.jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_modal_assets),
    );
  };

  fetchAnimationJson = () => {
    const {
      route: { params: { type } = {} },
    } = this.props;
    if (typeof animations.notificationPrompt[type] !== 'string') return;
    try {
      fetch(animations.notificationPrompt[type], {
        method: 'GET',
      })
        .then((response) => response?.json())
        .then((responseData) => {
          this.setState({
            animationJson: responseData,
          });
        });
    } catch (e) {
      console.tron.log(e);
    }
  };

  closeModal = (action) => {
    const { navigation } = this.props;
    navigation.dispatch((state) => {
      const routes = state.routes.filter(
        (r) => r.name !== 'NotificationModal',
      );
      return CommonActions.reset({
        ...state,
        routes,
        index: routes.length - 1,
      });
    });

    setTimeout(() => {
      this.fireEvent(action);
    }, 10);
  };

  fireEvent = (action) => {
    const {
      increment_system_prompt = 0,
      increment_foxy_notification_prompt = 0,
      incrementFoxyPermissionPrompt,
      route: { params: { type = '' } = {} },
    } = this.props;

    const shownModalCount = {
      viewed_system_prompt: 'NA',
      viewed_custom_prompt: 'NA',
    };

    if (action == 'modal_shown') {
      shownModalCount.viewed_system_prompt = increment_system_prompt;
      shownModalCount.viewed_custom_prompt =
        increment_foxy_notification_prompt + 1;

      incrementFoxyPermissionPrompt();
    } else {
      shownModalCount.viewed_system_prompt = increment_system_prompt;
      shownModalCount.viewed_custom_prompt = increment_foxy_notification_prompt;
    }
    AnalyticsManager.logEvent(
      EventType.notificationModal.NOTIFICATION_PERMISSION_MODAL,

      {
        [EventParameterKey.ACTION]: action,
        [EventParameterKey.SOURCE]: type,
        ...shownModalCount,
      },
    );
  };

  acceptButtonDock = (props) => {
    const {
      route: { params: { type } = {} },
    } = this.props;
    const { promptData } = this.state;
    const allowButtonText = promptData[type + '_action_1'] || 'Yes Definitely!';
    return (
      <ActionButton
        onActionPress={this.acceptAction}
        title={allowButtonText}
        actionButtonColor={colors.green}
      />
    );
  };

  onPressNoButton = () => {
    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_NO,
    );
  };

  cancelButtonDock = (props) => {
    const {
      route: { params: { type } = {} },
    } = this.props;
    const { promptData } = this.state;
    const cancelButtonText =
      promptData[type + '_action_2'] || 'Remind Me Later';
    return (
      <View style={styles.cancelButtonContainer}>
        <ActionButton
          onActionPress={this.onPressNoButton}
          title={cancelButtonText}
          actionButtonColor={colors.white}
          textColor={colors.black}
        />
      </View>
    );
  };

  triggerAndroidNotificationSettings = (currentPermissionState) => {
    const { notificationPermission = '' } = this.props;
    if (Platform.Version < 33 && currentPermissionState === 'never_ask_again') {
      return true;
    }

    //FIXME: Once app-start notification permission code merged change this static variable to redux
    return (
      currentPermissionState === 'never_ask_again' &&
      notificationPermission === 'never_ask_again'
    );
  };

  acceptAction = () => {
    const {
      route: { params: { showNotificationPrompt, type: source } = {} },
      notificationPermission = '',
    } = this.props;

    if (Utility.isAndroid()) {
      /**
       * @param channelId: if not found, open app level notification settings
       */

      this.closeModal(
        EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_YES,
      );
      checkAndroidNotificationStatusFromNativeLayer(
        notificationPermission,
        (status) => {
          if (this.triggerAndroidNotificationSettings(status)) {
            triggerPermissionsEvent(
              EventType.miscAppEvents.SETTINGS_PROMPT,
              EventParameterValue.PERMISSIONS.notifications,
              source,
              'custom_modal',
            );
            NativeModules.NotificationsChannelModule.openNotificationChannelSettings(
              null,
            );
            return;
          }

          // Show os prompt
          triggerPermissionsEvent(
            EventType.miscAppEvents.PERMISSION_PROMPT,
            EventParameterValue.PERMISSIONS.notifications,
            source,
            'custom_modal',
          );

          Utility.checkPermission(
            PERMISSION_STORE.notification,
            false,
            this.permissionRequestCallback,
            {},
            false,
          );
        },
      );
      return;
    }

    if (showNotificationPrompt) {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_PROMPT,
        EventParameterValue.PERMISSIONS.notifications,
        source,
        'custom_modal',
      );

      NotificationManager.getNotificationPermission(source);
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.SETTINGS_PROMPT,
        EventParameterValue.PERMISSIONS.notifications,
        source,
        'custom_modal',
      );
      Utility.openSettings();
    }

    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CLICK_YES,
    );
  };

  permissionRequestCallback = (granted, data) => {
    // Internally creates a General Notification channel if user has granted notification permission.
    ReactMoE.pushPermissionResponseAndroid(granted);
    // CleverTapAnalytics.subscribeToNotifications(granted);

    if (granted) {
      createAndroidNotificationChannels();
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_ALLOW,
        EventParameterValue.PERMISSIONS.notifications,
        'app_launch',
        'custom_modal',
      );
    } else {
      triggerPermissionsEvent(
        EventType.miscAppEvents.PERMISSION_DENY,
        EventParameterValue.PERMISSIONS.notifications,
        'app_launch',
        'custom_modal',
      );
    }

    NotificationUtils.previousNotificationPermissionState = data;
    savePermissionStatus(PERMISSION_CONSTANTS.notification, data);
  };

  notificationTitleText = (type) => {
    const remoteRequestData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );
    return remoteRequestData[type + '_title'] || 'Enable Notifications!';
  };

  notificationSubtitleText = (type) => {
    const remoteRequestData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );
    return (
      remoteRequestData[type + '_subtitle'] || 'Get the best offer updates'
    );
  };

  notificationImage = (type) => {
    const remoteRequestData = JSON.parse(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_prompt_request_v2),
    );
    return (
      remoteRequestData[type + '_image'] || images.notificationDefaultImage
    );
  };

  dismissKeyboard = () => {
    Keyboard.dismiss();
  };

  onPressCancel = () => {
    this.closeModal(
      EventParameterValue.NOTIFICATION_REQUEST_MODAL.ACTION.CROSS,
    );
  };

  onModalTapOutside = () => {
    return;
  };

  showPlatformSpecificPermissionPath = () => {
    if (!this.isNotificationPermissionRestricted()) {
      return null;
    }

    const image = this.notificationModalAssets[Platform.OS];

    return (
      <FastImageView
        style={styles.permissionPathImage}
        source={image}
        resizeMode={'contain'}
      />
    );
  };

  showImageOrAnimation = () => {
    const bannerImage = this.notificationImage();

    return (
      <View style={styles.notificationModalImageView}>
        <FastImageView
          style={styles.modalImageContainer}
          resizeMode={'contain'}
          source={bannerImage}
        />

        <this.showPlatformSpecificPermissionPath />
      </View>
    );
  };

  isNotificationPermissionRestricted = () => {
    const {
      route: { params: { showNotificationPrompt } = {} },
      notificationPermission = '',
    } = this.props;

    //FIXME: Once app start code is merged replace this static with actual redux value
    if (Utility.isAndroid() && notificationPermission === 'never_ask_again') {
      return true;
    }

    if (Utility.isIOS() && !showNotificationPrompt) {
      return true;
    }

    return false;
  };

  render() {
    const {
      route: { params: { type } = {} },
      notificationPermission = '',
    } = this.props;

    const hideStatusBar =
      type === NOTIFICATION_REQUEST_MODAL_TYPE.WISHLIST_PAGE;

    return (
      <>
        <BlurView
          style={styles.blurView}
          blurRadius={1}
          blurType={'light'}
          blurAmount={0.5}
        />
        <View
          style={styles.notificationModal}
          statusBarTranslucent={hideStatusBar}
        >
          <View style={styles.modalStyle}>
            <View style={styles.contentView}>
              <Text style={styles.titleText}>
                {this.notificationTitleText(type)}
              </Text>
              <Text style={styles.subtitleText}>
                {this.notificationSubtitleText(type)}
              </Text>

              <Text style={styles.subtitleText}>
                <Text style={styles.subtitleTextBold}> 'Allow'</Text> to enable
                these cool features.
              </Text>

              {notificationPermission === 'never_ask_again' && (
                <FastImageView
                  style={styles.modalImageContainer}
                  resizeMode={'contain'}
                  source={this.notificationImage(type)}
                />
              )}

              <View style={styles.buttonContainer}>
                <RoundedButton
                  buttonText={'Deny'}
                  buttonTextColor={'#008000'}
                  buttonColor={colors.white}
                  buttonAction={this.onPressNoButton}
                  style={styles.roundedButton}
                  buttonTextStyle={styles.buttonStyle}
                />
                <RoundedButton
                  buttonText={'Allow'}
                  buttonTextColor={colors.white}
                  buttonColor={'#008000'}
                  buttonAction={this.acceptAction}
                  style={styles.roundedButtonSecondary}
                  buttonTextStyle={styles.buttonStyle}
                />
              </View>
            </View>
          </View>
        </View>
      </>
    );
  }
}

const styles = StyleSheet.create({
  roundedButton: {
    width: 96,
    alignSelf: 'flex-end',
    height: 36,
    borderColor: colors.subText,
    borderWidth: 1,
  },
  roundedButtonSecondary: {
    width: 96,
    alignSelf: 'flex-end',
    height: 36,
    marginRight: 16,
    marginLeft: 22,
  },
  notificationModal: {
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    height: Utility.getScreenHeight(),
    backgroundColor: colors.translucent,
  },
  buttonsContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cancelButtonContainer: {
    position: 'absolute',
    bottom: 2,
    left: 0,
    right: 0,
    height: 52,
    borderRadius: 6,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 12,
    left: 12,
    right: 12,
    elevation: 2,
    shadowRadius: 4,
    height: 46,
    borderRadius: 4,
    backgroundColor: colors.black,
    shadowColor: colors.black,
    shadowOpacity: 0.2,
    shadowOffset: { width: 1, height: 1 },
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cancelButtonView: {
    padding: 4,
    position: 'absolute',
    top: 12,
    right: 12,
    shadowRadius: 4,
    height: 32,
    width: 32,
    borderRadius: 16,
    overflow: 'visible',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalStyle: {
    width: Utility.getScreenWidth() - 70,
    borderRadius: 12,
  },
  modalStyleWithoutSecondaryImage: {
    width: Utility.getScreenWidth() - 24,
    minHeight: 400,
    borderRadius: 12,
    backgroundColor: 'white',
  },
  titleText: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: colors.foxyBlack,
    textAlign: 'center',
    marginLeft: 16,
    marginRight: 32,
    marginTop: 32,
    lineHeight: 34,
    alignSelf: 'center',
  },
  subtitleText: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    letterSpacing: 0,
    color: colors.darkGrey,
    marginLeft: 16,
    marginTop: 22,
    lineHeight: 22,
  },
  subtitleTextBottom: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: colors.darkGrey,
    marginLeft: 16,
    marginTop: 22,
    lineHeight: 22,
  },
  subtitleTextBold: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    letterSpacing: 0,
    color: colors.darkGrey,
    marginLeft: 16,
    marginTop: 22,
    lineHeight: 22,
  },
  contentView: {
    backgroundColor: 'white',
    borderRadius: 12,
    marginBottom: 16,
    paddingHorizontal: 8,
  },
  crossButtonContainer: { height: 24, width: 24 },
  modalImageContainer: {
    height: 80,
    width: Utility.getScreenWidth() - 148,
    alignSelf: 'center',
    marginVertical: 12,
  },

  permissionPathImage: {
    height: 20,
    width: Utility.getScreenWidth() - 40,
  },
  blurView: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: Utility.getScreenHeight(),
  },

  notificationModalImageView: {
    justifyContent: 'center',
    alignItems: 'center',
  },

  buttonStyle: { fontSize: 14 },
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    marginBottom: 24,
    marginTop: 18,
  },
});

const mapStateToProps = (state) => ({
  notificationPermission: state.permissionStatus?.notification,
  increment_system_prompt: state.permissionStatus?.increment_system_prompt || 0,
  increment_foxy_notification_prompt:
    state.permissionStatus?.increment_foxy_notification_prompt || 0,
});

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      incrementFoxyPermissionPrompt,
      setNotificationModalDisplayTime,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(NotificationModal),
);
