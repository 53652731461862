import { StyleSheet, Text, View } from 'react-native';
import React from 'react';
import _ from 'lodash';
import applyOffer from '../../../hoc/applyOffer';
import Utility from '../../../utils/Utility';
import DynamicLinkManager from '../../../utils/DynamicLinkManager';
import colors from '../../../theme/Colors';
import size from '../../../theme/Fonts';
import NavigationService from '../../../navigator/NavigationService';
import { useSelector } from 'react-redux';
import DebouncedTouchableOpacity from '../../shared/DebouncedTouchableOpacity';
import OfferCounter from '../../../containers/offer/OfferCounter';
import RoundedButton from '../buttons/RoundedButton';
import FastImageView from '../../FastImageView';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
import { AnalyticsManager, EventParameterKey } from '../../../analytics';
import AppConfig from '../../../config/AppConfig';
import { isBlank } from '../../../utils/BooleanUtility';

const isInviteCenterEnabled = AppConfig.getProfileMenuItems().includes('invite_center');

function DefaultSizeOfferPromptFancyRail(props) {
  const {
    item,
    applyCartCoupon,
    index,
    previousScreen,
    navigation,
    showSubHeading = false,
    inheritStyles,
    isLoading,
    fromGenericOfferModal,
    isDisabled = false,
    onPress,
  } = props;

  const authToken = useSelector((state) => state.UserAccountInfo.authToken) || '';

  const styles = Utility.isPresent(inheritStyles)
    ? inheritStyles
    : OfferPromptFancyRailStyles;

  const {
    name,
    maximum_discount = '',
    ends_at,
    message,
    is_generic,
    image_url,
    cta_text,
    applied,
    cta_action,
    url,
    coupon_code,
    store_offer: isStoreOffer = false,
    max_discount_value,
  } = item;

  if (!isInviteCenterEnabled && cta_action === 'invite') {
    return null;
  }


  let buttonText = 'View Products';

  const navigateToOfferDetail = (url, is_generic) => {
    if (Utility.isBlank(url)) {
      return;
    }
    AnalyticsManager.logEvent('item_action_2', {
      type: 'wallet',
      coupon: coupon_code,
      [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
      action: 'view products',
      is_generic,
    });
    if (Utility.isPresent(url)) {
      DynamicLinkManager.handleDynamicLink(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          if (is_generic) {
            navigation.push('GenericOffer', {
              slug,
              isStoreOffer,
              coupon_code,
            });
            return;
          }
          NavigationService.push(route, { slug, extra });
        },
      );
    }
  };

  const showReferralModal = () => {
    navigation.navigate('InviteToClaimModal');
  };

  const openLoginOrInviteFriends = () => {
    AnalyticsManager.logEvent('item_action_2', {
      type: 'wallet',
      coupon: coupon_code,
      [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
      source: cta_action === 'login' ? 'login' : 'invite',
    });
    if (cta_action === 'login' && isBlank(authToken)) {
      NavigationService.renderOnboarding();
    } else if (cta_action === 'invite') {
      showReferralModal();
    }
  };

  const onPressCard = () => {
    if (onPress) {
      onPress();
      return;
    }
    if (fromGenericOfferModal) {
      return;
    }
    if (applied && previousScreen === 'cart') {
      return;
    }

    if (Utility.isPresent(cta_action)) {
      openLoginOrInviteFriends();
      return;
    }
    if (['cart', 'product_detail'].includes(previousScreen)) {
      AnalyticsManager.logEvent('item_action_2', {
        type: 'wallet',
        coupon: coupon_code,
        [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
        action: 'apply',
        is_generic,
      });
      applyCartCoupon(coupon_code);
    } else {
      navigateToOfferDetail(url, is_generic);
    }
  };

  const onPressCta = () => {
    if (fromGenericOfferModal) {
      return;
    }
    if (applied && previousScreen === 'cart') {
      return;
    }
    if (Utility.isPresent(cta_action)) {
      openLoginOrInviteFriends();
      return;
    }
    if (buttonText === 'Apply') {
      AnalyticsManager.logEvent('item_action_2', {
        type: 'wallet',
        coupon: coupon_code,
        [EventParameterKey.PREVIOUS_SCREEN]: previousScreen,
        action: 'apply',
        is_generic,
      });
      applyCartCoupon(coupon_code);
    } else {
      navigateToOfferDetail(url, is_generic);
    }
  };

  if (Utility.isPresent(cta_text)) {
    buttonText = cta_text;
  } else if (previousScreen === 'cart') {
    if (applied) {
      buttonText = 'Applied';
    } else {
      buttonText = 'Apply';
    }
  }

  const randomColor = isDisabled
    ? {
        bgColor: colors.grayscale.background,
        textColor: colors.grayscale.borderColor,
      }
    : colors.contactsInitialsColor[index % 18];
  const descriptionBackgroundColor = randomColor?.bgColor;
  const circleBGColor = randomColor?.textColor;

  return (
    <DebouncedTouchableOpacity
      {...props}
      onPress={onPressCard}
      style={_.memoize(
        () => {
          return [
            styles.couponContainer,
            {
              backgroundColor: descriptionBackgroundColor,
              borderWidth: applied ? 2 : 0,
              borderColor: circleBGColor,
            },
          ];
        },
        () => [index % 18],
      )()}
    >
      <View>
        <Text
          style={styles.headingStyle}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {name}
        </Text>
        {!!showSubHeading && (
          <Text
            style={styles.subheadingStyle}
            numberOfLines={1}
            ellipsizeMode='tail'
          >
            {message}
          </Text>
        )}
      </View>

      <View style={styles.innerContainer}>
        <OfferCounter
          endsAt={ends_at}
          digitStyle={styles.digitStyle}
          digitTxtStyle={styles.digitTxtStyle}
          timeLabelStyle={styles.timeLabelStyle}
          separatorStyle={styles.separatorStyle}
          countdownText={styles.countdownText}
          timerText={styles.timerText}
        />
        <View style={styles.bottomButtonContainer}>
          {isLoading ? (
            <Text>Applying...</Text>
          ) : (
            <>
              {is_generic && buttonText !== 'Apply' && previousScreen !== 'cart' ? (
                <Text style={styles.validOnProduct}>Valid on all products</Text>
              ) : (
                <RoundedButton
                  buttonText={buttonText}
                  buttonColor={colors.black}
                  buttonTextColor={colors.white}
                  style={styles.buttonContainer}
                  buttonAction={onPressCta}
                />
              )}
            </>
          )}
        </View>
      </View>
      <View
        style={_.memoize(
          () => [styles.circle, { backgroundColor: circleBGColor }],
          () => [index % 18],
        )()}
      >
        {Utility.isPresent(image_url) ? (
          <FastImageView source={image_url} style={styles.cardIcon} />
        ) : (
          <Text
            style={styles.maxDiscount}
          >{`${CURRENCY_SYMBOL}${maximum_discount || max_discount_value}`}</Text>
        )}
      </View>
    </DebouncedTouchableOpacity>
  );
}

const OfferPromptFancyRailStyles = StyleSheet.create({
  cardIcon: { height: 32, width: 32 },
  couponContainer: {
    backgroundColor: '#FFE4F4',
    width: 241,
    height: 73,
    borderRadius: 16,
    marginLeft: 32,
    paddingVertical: 8,
    justifyContent: 'space-between',
    paddingHorizontal: 8,
    paddingLeft: 32,
    marginTop: 8,
  },
  container: {},
  contentContainer: {
    backgroundColor: colors.white,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  circle: {
    height: 56,
    width: 56,
    borderRadius: 28,
    position: 'absolute',
    top: 8,
    left: -28,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'red',
  },
  maxDiscount: { fontSize: 16, fontFamily: 'Roboto-Bold', color: '#173143' },
  headingStyle: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
    color: colors.foxyBlack,
  },
  subheadingStyle: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.subtitle,
  },
  timerText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    marginBottom: 6,
  },
  digitStyle: {
    backgroundColor: colors.black,
    borderRadius: 2,
  },
  digitTxtStyle: {
    color: colors.white,
    fontSize: size.h5,
    fontFamily: 'Roboto-Regular',
  },
  timeLabelStyle: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h5,
    marginTop: 0,
    marginLeft: 10,
  },
  separatorStyle: {
    color: colors.black,
  },
  countdownText: {
    color: colors.black,
    fontFamily: 'Roboto-Regular',
    fontSize: size.h6,
  },
  innerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  validProductText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    marginBottom: -4,
  },
  ctaContainer: {
    width: 91,
    height: 22,
  },
  footerContainer: { flexDirection: 'row' },
  amountContainer: {
    position: 'absolute',
    top: -36,
    right: 16,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  amount: {
    fontSize: 24,
    fontFamily: 'Roboto-Bold',
    color: colors.green,
    lineHeight: 26,
  },
  chevron: { height: 16, width: 16, marginLeft: 4 },
  bottomButtonContainer: { alignItems: 'flex-end', flex: 1, marginBottom: 2 },
  validOnProduct: {
    fontSize: 10,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    marginBottom: 6,
  },
});

export default applyOffer(DefaultSizeOfferPromptFancyRail);
