import React, { PureComponent } from 'react';
import {
  Text,
  View,
  FlatList,
  UIManager,
  LayoutAnimation,
  Image,
} from 'react-native';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import FoxyAlert from '../../components/camera/shared/FoxyAlert';
import FastImageView from '../../components/FastImageView';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import colors from '../../theme/Colors';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import styles from './styles';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isBlank } from '../../utils/BooleanUtility';

export default class ProAccountsList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertMessage: '',
      horizontal: true,
    };
    this.screenWidth = Utility.getScreenWidth();
    if (
      Utility.isAndroid() &&
      UIManager.setLayoutAnimationEnabledExperimental
    ) {
      UIManager.setLayoutAnimationEnabledExperimental(true);
    }
    this.openUrlHandlersCache = {};
    this.rowBgStylesCache = {};
    this.tagBgStylesCache = {};
  }

  changeListOrientation = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    this.setState({ horizontal: !this.state.horizontal });
  };

  openUrl = (item) => {
    const { final_url: url } = item;
    openUrlInBrowser(url, this.openUrlCallback);
    this.logLinkClickEvent(item);
  };

  memoizedOpenUrl = (item) => {
    const { final_url: url } = item;
    if (!this.openUrlHandlersCache[url]) {
      this.openUrlHandlersCache[url] = () => {
        this.openUrl(item);
      };
    }
    return this.openUrlHandlersCache[url];
  };

  openUrlCallback = (wasSuccess) => {
    if (!wasSuccess) {
      this.showError();
    }
  };

  ConditionalFastImage = ({ style, source }) => {
    if (Utility.isBlank(source)) return null;
    return <FastImageView source={source} style={style} />;
  };

  getMemoizedRowBgStyle = (bgColor) => {
    if (!this.rowBgStylesCache[bgColor]) {
      this.rowBgStylesCache[bgColor] = [
        styles.socialAccountRow,
        { backgroundColor: bgColor },
      ];
    }
    return this.rowBgStylesCache[bgColor];
  };

  getMemoizedTagBgStyle = (bgColor) => {
    if (!this.tagBgStylesCache[bgColor]) {
      this.tagBgStylesCache[bgColor] = [
        styles.socialAccountTag,
        { backgroundColor: bgColor },
      ];
    }
    return this.tagBgStylesCache[bgColor];
  };

  SocialAccountRow = ({ item }) => {
    const {
      social_media_platform: {
        background_color = '',
        icon_url: icon = '',
      } = {},
      final_url: url = '',
      image_url: image = '',
      button_text: buttonText = '',
    } = item;
    if (Utility.isBlank(url)) return null;
    const bgColor = Utility.isBlank(background_color)
      ? colors.influencerSocialMediaAccountBg
      : background_color;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.memoizedOpenUrl(item)}
        style={this.getMemoizedRowBgStyle(bgColor)}
      >
        <FastImageView
          source={getMinifiedImage(icon, styles.rowIcon.width, styles.rowIcon.height)}
          style={styles.rowIcon}
        />
        <Text style={styles.socialHandle} numberOfLines={1}>
          {buttonText}
        </Text>
        <this.ConditionalFastImage
          source={getMinifiedImage(image, styles.rowDP.width, styles.rowDP.height)}
          style={styles.rowDP}
        />
      </DebouncedTouchableOpacity>
    );
  };

  SocialAccountTag = ({ item }) => {
    const {
      social_media_platform: {
        background_color = '',
        icon_url: icon = '',
      } = {},
      final_url: url = '',
    } = item;
    if (Utility.isBlank(url)) return null;
    const bgColor = Utility.isBlank(background_color)
      ? colors.influencerSocialMediaAccountBg
      : background_color;
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={this.getMemoizedTagBgStyle(bgColor)}
        onPress={this.memoizedOpenUrl(item)}
      >
        <this.ConditionalFastImage
          source={getMinifiedImage(
            icon,
            styles.socialAccountIcon.width,
            styles.socialAccountIcon.height,
          )}
          style={styles.socialAccountIcon}
        />
      </DebouncedTouchableOpacity>
    );
  };

  logLinkClickEvent = (item = {}) => {
    const { getInfluencerHandle = () => {} } = this.props;
    const {
      social_media_platform: { name: platformName = '' },
      final_url = '',
    } = item;
    AnalyticsManager.logEvent(EventType.influencer.INFLUENCER_LINK_CLICK, {
      [EventParameterKey.FOXY_HANDLE]: getInfluencerHandle(),
      [EventParameterKey.TYPE]: platformName,
      [EventParameterKey.URL]: final_url,
    });
  };

  showError = () => {
    this.setState({
      alertMessage: 'It looks like you have encountered a broken link!!',
    });
  };

  closeErrorModal = () => {
    this.setState({
      alertMessage: '',
    });
  };

  changeListOrientationButton = () => {
    const { horizontal } = this.state;
    const { accounts = [] } = this.props;
    if (isBlank(accounts)) return null;
    const containerStyle = horizontal
      ? styles.disableHorizontalOrientationButton
      : styles.disableVerticalOrientationButton;
    const icon = horizontal ? images.chevronDown : images.chevronUp;
    const iconStyle = horizontal
      ? styles.disableHorizontalOrientationButtonIcon
      : styles.disableVerticalOrientationButtonIcon;

    return (
      <DebouncedTouchableOpacity
        {...this.props}
        style={containerStyle}
        onPress={this.changeListOrientation}
      >
        {!horizontal && (
          <Text style={styles.disableVerticalOrientationButtonCta}>
            Show Less
          </Text>
        )}
        <Image source={icon} style={iconStyle} />
      </DebouncedTouchableOpacity>
    );
  };

  render() {
    const { accounts = [] } = this.props;
    const { alertMessage, horizontal } = this.state;
    const listContainer = horizontal
      ? styles.iconListContainer
      : styles.rowListContainer;
    const renderItem = horizontal
      ? this.SocialAccountTag
      : this.SocialAccountRow;
    const data = horizontal ? accounts.slice(0, 5) : accounts;
    return (
      <View style={styles.accountListContainer} activeOpacity={0.6}>
        <FlatList
          horizontal={horizontal}
          data={data}
          renderItem={renderItem}
          style={listContainer}
          showsHorizontalScrollIndicator={false}
          scrollEnabled={false}
          ListFooterComponent={this.changeListOrientationButton}
        />
        <FoxyAlert
          isVisible={Utility.isPresent(alertMessage)}
          hideSecondButton
          alertBoxTitle='Sorry..'
          alertMessage={alertMessage}
          firstButtonTitle='Okay'
          firstButtonOnPress={this.closeErrorModal}
          onTapOutside={this.closeErrorModal}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
        />
      </View>
    );
  }
}
