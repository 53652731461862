import React from 'react';
import { View, Text, FlatList, StyleSheet } from 'react-native';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import FastImageView from '../../components/FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { isBlank, isWeb } from '../../utils/BooleanUtility';

const Degree = ({ item = {} }) => {
  const {
    attributes: {
      title = '',
      specialization = '',
      certificate_url = '',
      certificate_thumbnail_url,
      institute = '',
    } = {},
  } = item;

  if (isBlank(certificate_thumbnail_url)) return null;

  return (
    <View style={styles.degreeContainer}>
      <FastImageView
        resizeMode='cover'  // Changed to 'contain' to show the whole image
        source={getMinifiedImage(certificate_thumbnail_url, styles.certificate.width)}
        style={styles.certificate}
      />
      <View style={styles.infoContainer}>
        <Text numberOfLines={1} style={styles.title}>{title}</Text>
        <Text ellipsizeMode='tail' numberOfLines={3} style={styles.specialization}>
          {`from ${institute}`}
        </Text>
      </View>
    </View>
  );
};


const Qualifications = (props) => {
  const {
    qualifications: { data = [] },
  } = props;
  if (isBlank(data)) return null;
  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Text style={styles.headerTitle}>Qualifications</Text>
        <Text style={styles.headerSubtitle}>
          Check out the achievements by your Dr.
        </Text>
      </View>
      <View style={styles.qualificationContainer}>
        <FlatList
          data={data}
          renderItem={({ item }) => <Degree item={item} />}
          keyExtractor={(item, index) => index.toString()}
          horizontal
          showsHorizontalScrollIndicator={false}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 18,
    width: getScreenWidth(),
    backgroundColor: colors.white,
    marginTop: 32,
  },
  qualificationContainer: {
    paddingLeft: 6,
  },
  text: {
    fontSize: 14,
    color: colors.foxyBlack,
  },
  headerTitle: {
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
  },
  headerSubtitle: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
  },
  headerContainer: {
    padding: 12,
  },
  certificate: {
    height: 140, // You can adjust this to be higher if needed
    width: getScreenWidth() / 1.7 - 30,
  },
  degreeContainer: {
    alignItems: 'center',
    width: getScreenWidth() / 1.7 - 20,
    marginHorizontal: 6,
  },
  infoContainer: {
    alignItems: 'center',
    backgroundColor: colors.skyBlue,
    paddingVertical: 4,
    borderBottomLeftRadius: 25,
    borderBottomRightRadius: 25,
    alignSelf: 'center',
    width: getScreenWidth() / 1.7 - 15,
  },
  title: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.white,
    paddingHorizontal: isWeb() ? 10 : 2,
  },
  specialization: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.white,
    textAlign: 'center',
    height: 30,
    width: getScreenWidth() / 1.7 - 40,
    paddingHorizontal: 2,
    paddingBottom: 2,
  },
});

export default Qualifications;
