import React, { Component } from 'react';
import { View, TouchableOpacity, Text, Animated, Image } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { MAX_LINES, CURRENCY_SYMBOL, ShowProductVariantLabelOnCartItem } from '../../config/Constants';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import OfferProductInCartStyles from './styles/OfferProductInCartStyles';
import {
  removeFromCart,
  cancelOrderItemById,
  isSpecialOfferApplied,
} from '../../actions/ActionTypes';
import IncrementDecrement from './IncrementDecrement';
import Config from '../../libraries/ReactNativeConfig';
import AppConfig from '../../config/AppConfig';
import { ProductInCartStyles } from './styles';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

class CartItemCardForOffer extends Component {
  constructor(props) {
    super(props);
    const { scrollY } = props;
    this.animatedStyle = [
      OfferProductInCartStyles.brandName,
      {
        height: scrollY.interpolate({
          inputRange: [0, 100, 200, 300, 400],
          outputRange: [14, 8, 0, 0, 0],
          extrapolate: 'clamp',
        }),
      },
    ];
    this.imageDividerStyle = [
      OfferProductInCartStyles.divider,
      {
        height: scrollY.interpolate({
          inputRange: [0, 100, 200, 300],
          outputRange: [1, 0.75, 0.35, 0],
          extrapolate: 'clamp',
        }),
      },
    ];
  }

  productImageAndRating = (props) => {
    const { imageUrl, variantImageUrl, variantWebpImageUrl } =
      props;
    const styles = OfferProductInCartStyles;
    let minifiedImageUrl = getMinifiedImage(
      imageUrl,
      styles.productImage.width,
      styles.productImage.width,
    );
    if (Utility.isPresent(variantImageUrl)) {
      minifiedImageUrl = getMinifiedImage(
        variantImageUrl,
        styles.productImage.width,
        styles.productImage.width,
      );
    }
    return (
      <FastImageView
        resizeMode='contain'
        source={minifiedImageUrl}
        style={styles.productImage}
      />
    );
  };

  productDescription = (props) => {
    const styles = OfferProductInCartStyles;

    const { mrp = '', brandName = 'Foxy', name = '', isFree } = props;

    const productName = name.replace(`${brandName} `, '');

    return (
      <View style={styles.descriptionContainerStyleWithAddToCart}>
        <Animated.Text
          numberOfLines={MAX_LINES.single}
          style={this.animatedStyle}
        >
          {brandName?.toUpperCase()}
        </Animated.Text>
        <Text numberOfLines={MAX_LINES.two} style={styles.productName}>
          {productName}
        </Text>

        <Animated.View style={this.animatedStyle}>
          {isFree ? (
            <Text style={styles.spStyle}>FREE</Text>
          ) : (
            <Text style={styles.mrpStyle}>{`${CURRENCY_SYMBOL}${mrp}`}</Text>
          )}
        </Animated.View>
      </View>
    );
  };

  variantImage = (props) => {
    const { variantImage } = props;
    if (Utility.isBlank(variantImage) || variantImage === '') {
      return null;
    }
    return (
      <Image
        style={ProductInCartStyles.variantImage}
        source={{
          uri: getMinifiedImage(
            variantImage,
            ProductInCartStyles.variantImage.width,
            ProductInCartStyles.variantImage.height,
          ),
        }}
      />
    );
  };

  ProductVariant = () => {
    const {
      cartItem: { variant = [] },
      cartProduct: {
        variants_details: { variants_property_name = '' } = {},
      } = {},
    } = this.props;
    if (Utility.isBlank(variant.display_name)) {
      return null;
    }
    const styles = ProductInCartStyles;

    let variantLabel = variant.display_name;
    if (ShowProductVariantLabelOnCartItem) {
      variantLabel = `${variants_property_name ?? 'Size'}: ` + variantLabel;
    }
    if (variantLabel === 'Default Title') return null;
    return (
      <View style={styles.variantPillContainer}>
        <this.variantImage variantImage={variant.image_url} />
        <Text
          allowFontScaling={false}
          style={styles.productQuantityVariantTextSmall}
          numberOfLines={1}
          ellipsizeMode='tail'
        >
          {variantLabel}
        </Text>
      </View>
    );
  };

  render() {
    const {
      cartItem,
      skuId,
      quantity,
      addToCart,
      showToast,
      cartProduct: { type = '' } = {},
      listData = {},
      toTop,
      list_id,
      force_apply_coupon,
      scrollY,
          } = this.props;

    if (Utility.isBlank(skuId) && addToCart && Utility.isBlank(cartItem)) {
      return null;
    }

    return (
      <TouchableOpacity onPress={toTop} style={OfferProductInCartStyles.container}>
        <View style={OfferProductInCartStyles.cardStyleForCart}>
          {addToCart && (
            <this.productImageAndRating
              imageUrl={cartItem.product?.image_url}
              variantImageUrl={cartItem.variant?.images?.[0] || ''}
              variantWebpImageUrl={cartItem.variant?.webp_images?.[0] || ''}
            />
          )}

          <Animated.View style={this.imageDividerStyle} />

          <this.productDescription
            mrp={cartItem.final_mrp}
            name={cartItem.product?.name}
            brandName={cartItem.product?.brand && cartItem.product.brand?.name}
            isFree={cartItem.is_free}
          />

          <IncrementDecrement
            skuId={skuId}
            quantity={quantity}
            showCartItemQuantity
            cartItem={cartItem.product}
            showToast={showToast}
            type={type}
            listData={listData}
            small
            toTop={toTop}
            listId={list_id}
            force_apply_coupon={force_apply_coupon}
            scrollY={scrollY}
          />
        </View>
        <this.ProductVariant />
      </TouchableOpacity>
    );
  }
}

CartItemCardForOffer.defaultProps = {
  addToCart: true,
  fromOrderDetail: false,
  inOrderDetail: false,
};

const mapStateToProps = (store, ownProps) => {
  const { id } = ownProps;
  return {
    addedToCart: !!(store.bag && store.bag.cartItems[id]),
    configs: store.UserAccountInfo.remote_configs,
    userCreatedAt: store.UserAccountInfo.app_installed_at,
    todayDealss: store.todayDeals,
    couponCodes:
      store.bag.cartPricing && store.bag.cartPricing.coupon_codes
        ? store.bag.cartPricing.coupon_codes
        : [],
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    { removeFromCart, cancelOrderItemById, isSpecialOfferApplied },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
    CartItemCardForOffer,
  ),
);
