import React, { Component } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { connect } from 'react-redux';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import RoundedButton from '../layout/buttons/RoundedButton';
import colors from '../../theme/Colors';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { AnalyticsManager, EventType } from '../../analytics';
import images from '../../theme/Images';
import NotificationCount from '../shared/NotificationCount';
import { MoEReactInbox } from '../../libraries/ReactMoe';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';

export class Greetings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationCount: 0,
    };
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  onFocus = () => {
    this.setNotificationCount();
  };

  setNotificationCount = async () => {
    if (Utility.isWeb()) return;
    const { notificationCount } = this.state;
    const count = (await MoEReactInbox.getUnClickedCount()) || 0;
    if (notificationCount !== count) {
      this.setState({
        notificationCount: count,
      });
    }
  };

  handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    AnalyticsManager.logEvent(EventType.discoveryEvents.PAYOFF_ACTION, {
      route_to: route,
    });
    navigation.navigate(route, { slug, extra });
  };

  onNavigationStateChange = (url) => {
    DynamicLinkManager.handleLinkWithInternalTrackingParams(
      url,
      this.handleRouteFromLink,
    );
  };

  navigateToConsumerProfile = () => {
    const { navigation } = this.props;
    navigation.navigate('ConsumerProfile', {});
  };

  profileImage = ({ image }) => {
    const { notificationCount = 0 } = this.state;
    return (
      <View>
        <TouchableOpacity onPress={this.navigateToConsumerProfile}>
          <FastImageView source={image} style={styles.selfieImage} />
        </TouchableOpacity>
        <View style={styles.notificationCount}>
          <NotificationCount count={notificationCount} />
        </View>
      </View>
    );
  };

  render() {
    const {
      selfie_image_url,
      name,
      itemData: { arguments: ctaArguments = {} } = {},
      facialAnalysis,
      navigation,
    } = this.props;
    let image = getMinifiedImage(selfie_image_url);
    if (Utility.isBlank(image)) {
      image = images.for_you_placeholder.uri;
    }
    const today = new Date();
    const curHr = today.getHours();
    let msg = 'Hi';
    if (curHr < 12) {
      msg = 'Good morning, ';
    } else if (curHr < 18) {
      msg = 'Good afternoon, ';
    } else {
      msg = 'Good evening, ';
    }
    const genderFromSelfie = Utility.getGender(facialAnalysis) || [];

    let gender = 'Buddy';
    if (genderFromSelfie[0] === 62) {
      gender = 'Handsome';
    } else if (genderFromSelfie[0] === 63) {
      gender = 'Beautiful';
    }

    if (Utility.isPresent(name)) {
      gender = name;
    }

    const [userName, ...rest] = gender.split(' ');

    return (
      <>
        <View style={styles.container}>
          <View style={styles.greetingContainer}>
            <View>
              <Text style={styles.greetingMessage}>{msg}</Text>
              <Text style={styles.userName}>{userName}</Text>
            </View>
            <this.profileImage image={image} />
          </View>
          <View style={styles.actionButtonContainer}>
            {Object.entries(ctaArguments).map(([key, value]) => {
              if (Utility.isBlank(key)) {
                return null;
              }
              return (
                <RoundedButton
                  buttonText={key}
                  buttonTextColor={'#fff'}
                  buttonColor={'#000'}
                  buttonAction={() => {
                    this.onNavigationStateChange(value);
                  }}
                  style={styles.actionButton}
                  buttonTextStyle={styles.actionButtonText}
                  showChevron
                />
              );
            })}
          </View>
        </View>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
    name: state.UserAccountInfo.profile.name,
    facialAnalysis: state.UserAccountInfo?.facialAnalysis?.my_attributes_values,
  };
};

const styles = StyleSheet.create({
  actionButton: { width: 88, height: 24, borderRadius: 8, marginRight: 8 },
  actionButtonText: {
    fontSize: 12,
    fontFamily: 'Roboto-Medium',
  },
  actionButtonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: 12,
  },
  selfieImage: {
    height: 56,
    width: 56,
    borderRadius: 28,
  },
  userName: {
    fontSize: 24,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    lineHeight: 28,
  },
  greetingMessage: {
    fontSize: 18,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
    lineHeight: 24,
  },
  greetingContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 24,
  },
  container: { paddingHorizontal: 12, paddingBottom: 8 },
  notificationCount: {
    position: 'absolute',
    bottom: -2,
    right: 0,
  },
});

export default withNavigation(connect(mapStateToProps, null)(Greetings));
