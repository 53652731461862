import React, { PureComponent } from 'react';
import {
  TouchableOpacity,
  Image,
  View,
  Platform,
  Animated,
  Easing,
} from 'react-native';
import PropTypes from 'prop-types';
// Style import
import {
  videoControlsViewStyles as styles,
  playerMiniControlsStyles,
} from './Style';
import images from '../../theme/Images';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import Utility from '../../utils/Utility';
import { ContentPlatforms } from '../../config/Constants';
import BagIcon from '../header/BagIcon';
import LottieView from 'lottie-react-native';
import animations from '../../theme/Animations';
import AnimatedLikeButton from '../shared/AnimatedLikeButton';
import colors from '../../theme/Colors';
import { isWeb } from '../../utils/BooleanUtility';

class PlayerMiniControls extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      progress: new Animated.Value(0),
    };
  }

  componentDidMount = () => {
    if (this.props.isFavorite) {
      this.likeAnimation();
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.isFavorite === true && prevProps.isFavorite !== true) {
      this.likeAnimation();
    }
  };

  fullscreenButton = () => {
    return null;
    // const { toggleFullscreen, isFullscreen, contentPlatform } = this.props;
    // if (Utility.isAndroid() && contentPlatform === ContentPlatforms.youtube) {
    //   return null;
    // }
    // const icon = isFullscreen
    //   ? images.videoControls.exitFullscreen
    //   : images.videoControls.fullscreen;
    // return (
    //   <TouchableOpacity
    //     style={playerMiniControlsStyles.fullscreenButtonIcon}
    //     onPress={toggleFullscreen}
    //   >
    //     <Image source={icon} />
    //   </TouchableOpacity>
    // );
  };

  shareButton = () => {
    const { onShareTapped } = this.props;
    const style = playerMiniControlsStyles.shareButtonIcon;
    return (
      <TouchableOpacity
        hitSlop={Utility.getHitSlop()}
        onPress={onShareTapped}
      >
        <Image source={images.videoControls.share} style={style} />
      </TouchableOpacity>
    );
  };

  favoriteButton = () => {
    const { isFavorite, onFavoriteTapped } = this.props;
    const style = playerMiniControlsStyles.favoriteButtonIcon;
    return (
      <TouchableOpacity style={style} onPress={onFavoriteTapped}>
        <Image
          source={images.unlikeImage}
          style={playerMiniControlsStyles.favoriteButtonImage}
        />
      </TouchableOpacity>
    );
  };

  cartButton = () => {
    const style = playerMiniControlsStyles.addToCartButtonIcon;
    const { fireVideoCtaClickEvent } = this.props;
    return (
      <View style={style}>
        <BagIcon
          handleTap={this.handleTap}
          showCart={true}
          darkMode={true}
          source="video"
          fireVideoCtaClickEvent={fireVideoCtaClickEvent}
        />
      </View>
    );
  };

  likeAnimation = () => {
    const { progress } = this.state;
    Animated.timing(progress, {
      toValue: 1,
      duration: 1000,
      easing: Easing.linear,
    }).start();
  };

  likedAnimatedView = () => {
    const { onFavoriteTapped } = this.props;
    const style = playerMiniControlsStyles.favoriteButtonIcon;
    return (
      <TouchableOpacity style={style} onPress={onFavoriteTapped}>
        {/* <Image source={images.videoControls.favoriteAnimation} /> */}
        <LottieView
          source={animations.like}
          style={playerMiniControlsStyles.favoriteButtonIconAnimation}
          resizeMode="cover"
          progress={this.state.progress}
          resizeMode="cover"
        />
      </TouchableOpacity>
    );
  };

  fireVideoCtaClickEvent = (action) => {
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_CTA_CLICK, {
      [EventParameterKey.ACTION]: action,
      ...this.videoMetadata,
    });
  };

  render() {
    const { isFavorite } = this.props;
    // this difference is because of android's status bar, safe area insets only work in ios.
    return (
      <View style={styles.playerMiniControls}>
        {!isWeb() && <this.shareButton />}
        {isFavorite ? <this.likedAnimatedView /> : <this.favoriteButton /> }
        <this.fullscreenButton />
        <this.cartButton />
      </View>
    );
  }
}

PlayerMiniControls.propTypes = {
  isFavorite: PropTypes.bool,
  onShareTapped: PropTypes.func,
  toggleFullscreen: PropTypes.func,
  onFavoriteTapped: PropTypes.func,
  contentPlatform: PropTypes.string,
  shouldShowList: PropTypes.bool,
  isFullscreen: PropTypes.bool,
};

PlayerMiniControls.defaultProps = {
  isFavorite: false,
  toggleFullscreen: () => {},
  onShareTapped: () => {},
  onFavoriteTapped: () => {},
  contentPlatform: '',
  shouldShowList: false,
  isFullscreen: false,
};

export default PlayerMiniControls;
