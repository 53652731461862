// Dependencies
import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import Orientation from 'react-native-orientation';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
// import StaticNavigationHeader from './StaticNavigationHeader';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { isNative } from '../../utils/BooleanUtility';

class PaymentPagePlaceHolder extends Component {
  componentDidMount() {
    if (isNative()) {
      Orientation.lockToPortrait();
    }
  }

  stripPlaceHolder = () => {
    return (
      <>
        <View style={styles.railPlaceholderShimmerContainer}>
          <ShimmerPlaceHolder
            autoRun
            style={styles.imagePlaceHolder}
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          />
          <ShimmerPlaceHolder
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            autoRun
            style={styles.titlePlaceHolder}
          />
        </View>
        <ShimmerPlaceHolder
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          autoRun
          style={styles.iconPlaceHolder}
        />
      </>
    );
  };

  headerPlaceholder = () => {
    return (
      <ShimmerPlaceHolder
        colorShimmer={['#fafafa', '#cccccc', '#fafafa']}
        autoRun
        style={styles.headerTextShimmerStyle}
      />
    );
  };

  render() {
    return (
      <>
        <this.headerPlaceholder />
        <View style={styles.itemPlaceholder}>
          <this.stripPlaceHolder />
        </View>
        <View style={styles.itemPlaceholder}>
          <this.stripPlaceHolder />
        </View>
      </>
    );
  }
}

const iconsBottomSpace = Utility.isIOS() ? 8 : 12;
const styles = StyleSheet.create({
  shimmerContainer: {
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
    marginTop: 12,
  },
  itemPlaceholder: {
    flexDirection: 'row',
    alignSelf: 'center',
    width: Utility.getScreenWidth(),
    height: 52,
    backgroundColor: colors.white,
    paddingLeft: 20,
    paddingRight: 20,
    marginVertical: 2,
  },

  railPlaceholderShimmerContainer: {
    borderRadius: 4,
    backgroundColor: colors.white,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    alignItems: 'center',
  },

  imagePlaceHolder: {
    height: 24,
    width: 24,
    borderRadius: 2,
  },
  titlePlaceHolder: {
    width: Utility.getScreenWidth() / 2,
    height: 18,
    borderRadius: 2,
    marginLeft: 12,
  },
  iconPlaceHolder: {
    height: 16,
    width: 24,
    position: 'absolute',
    right: 20,
    bottom: 18,
    borderRadius: 4,
  },

  headerTextShimmerStyle: {
    height: 14,
    width: 80,
    borderRadius: 4,
    marginVertical: 14,
    marginLeft: 16,
  },
});

export default PaymentPagePlaceHolder;
