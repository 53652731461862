import React, { useState, useEffect, useRef } from 'react';
import {
  View,
  ActivityIndicator,
  StyleSheet,
  TouchableOpacity,
  Text,
  TextInput,
  PermissionsAndroid,
} from 'react-native';
import Geolocation from 'react-native-geolocation-service';
import Permissions from '../../libraries/ReactNativePermissions';
import MapView, { Marker } from '../../libraries/ReactNativeMap';
import colors from '../../theme/Colors';
import { REMOTE_CONFIG_KEYS, URL } from '../../config/Constants';
// import { isAndroid, isIOS, isWeb } from '../../../utils/BooleanUtility';
import { isAndroid, isDesktop, isIOS, isWeb } from '../../utils/BooleanUtility';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import Header from './Header';
import { StaticNavigationHeader } from '../shared';

// Disable yellow box warning messages
console.disableYellowBox = true;


const onSelectSuggestion = (
  placeId,
  setRegionData,
  setMarkerCoords,
  setSearch,
  setSuggestions,
  region,
  setLocationInfo,
) => {
  fetch(`${URL.GOOGLE_PLACES_DETAILS}${placeId}`)
    .then((res) => res.json())
    .then((response) => {
      const { result = {} } = response;
      const {
        geometry = {},
        rating = '',
        user_ratings_total = '',
        photos = [],
        address_components = [],
        formatted_address,
      } = result;
      const { location } = geometry;
      const locationImage = photos[0]?.photo_reference;
      setRegionData({
        ...region,
        latitude: location.lat,
        longitude: location.lng,
      });

      setLocationInfo({ rating, user_ratings_total, locationImage, address_components });
      setMarkerCoords({ latitude: location.lat, longitude: location.lng });
      setSearch(formatted_address);
      setSuggestions([]);
    });
};


export default function Map({ index, handleLocationSelect }) {
  const { locationModalInfo = {}, defaultRegion = {} } =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.location_modal)) || {};
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['map']?.title;
  const headerSubtitle = headerData['map']?.subtitle;
  const [loading, setLoading] = useState(true);
  const [region, setRegion] = useState(defaultRegion) || {};
  const [permissionStatus, setPermissionStatus] = useState(null);
  const mapRef = useRef(null);
  const markerRef = useRef(null);
  const [isMapReady, setIsMapReady] = useState(false);
  const [marginTop, setMarginTop] = useState(1);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [markerCoords, setMarkerCoords] = useState({
    latitude: region.latitude,
    longitude: region.longitude,
  });
  const [locationInfo, setLocationInfo] = useState({});


  useEffect(() => {
    if (isWeb()) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setRegion({
            latitude,
            longitude,
            latitudeDelta: 0.001,
            longitudeDelta: 0.001,
          });
          setMarkerCoords({ latitude, longitude });
          setLoading(false);
        },
        (error) => {
          setError(error.message);
          setLoading(false);
        },
        { enableHighAccuracy: false, timeout: 200000, maximumAge: 5000 },
      );
    } else {
      requestLocationPermission();
    }
  }, []);

  const requestLocationPermission = async () => {
    if (isAndroid()) {
      const granted = await PermissionsAndroid.request(
        PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,
        locationModalInfo,
      );
      if (granted === PermissionsAndroid.RESULTS.GRANTED) {
        setLoading(false);
        getLocation();
      } else {
        console.tron.log('Location permission denied');
      }
    } else if (isIOS()) {
      const result = await Permissions.request('location');
      if (result === 'authorized') {
        setLoading(false);
        getLocation();
      } else {
        console.tron.log('Location permission denied');
      }
      setPermissionStatus(result);
    }
  };

  const getLocation = () => {
    Geolocation.getCurrentPosition(
      (position) => {
        const {
          coords: { longitude = '', latitude = '' },
        } = position;
        setRegion({
          latitude,
          longitude,
          latitudeDelta: 0.001,
          longitudeDelta: 0.001,
        });
        setMarkerCoords({ latitude, longitude });
      },
      { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 },
    );
  };

  const onMapReady = () => {
    setIsMapReady(true);
    setMarginTop(0);
  };

  const onLocationSelect = async () => {
    setButtonLoading(true);
    const lat = markerCoords.latitude || region.latitude;
    const long = markerCoords.longitude || region.longitude;
    const response = await fetch(
      `${URL.GEOCODE_ADDRESS}?lat=${lat}&long=${long}`,
    );
    const responseJson = await response.json();
    const address = responseJson.results[0].formatted_address;
    const {
      rating = '',
      user_ratings_total: ratingsCount = '',
      locationImage = '',
      address_components = [],
    } = locationInfo;
    handleLocationSelect({
      index,
      lat,
      long,
      address,
      rating,
      ratingsCount,
      locationImage,
      address_components,
    });
  };

  const onChangeText = (text) => {
    setSearch(text);
    fetch(`${URL.GOOGLE_PLACES_AUTOCOMPLETE}${text}`)
      .then((res) => res.json())
      .then((result) => {
        const { predictions = [] } = result;
        setSuggestions(predictions);
      });
  };

  const onDragEnd = (e) => {
    let markerLat = '';
    let markerLng = '';
    if (isWeb()) {
      markerLat = e.latLng.lat();
      markerLng = e.latLng.lng();
    } else {
      const { coordinate } = e.nativeEvent;
      markerLat = coordinate.latitude;
      markerLng = coordinate.longitude;
    }

    setMarkerCoords({ latitude: markerLat, longitude: markerLng });
  };

  if (loading) {
    return (
      <View style={styles.spinnerView}>
        <ActivityIndicator size='large' color={colors.foxyBlack} />
      </View>
    );
  }

  const setRegionData = (data) => {
    setRegion(data);
  };

  return (
    <View style={styles.container}>
     {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
     {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
      <View style={{ flex: 1 }}>
        <View style={styles.searchContainer}>
          <TextInput
            label='Search'
            tintColor={colors.subtitle}
            value={search}
            onChangeText={onChangeText}
            style={styles.textInput}
            placeholder='Search'
            placeholderTextColor={colors.disabled}
          />
          {Boolean(suggestions.length) && (
            <View style={styles.optionsContainer}>
              {suggestions.map(({ description, place_id: placeId }) => (
                <TouchableOpacity
                  onPress={() =>
                    onSelectSuggestion(
                      placeId,
                      setRegionData,
                      setMarkerCoords,
                      setSearch,
                      setSuggestions,
                      region,
                      setLocationInfo,
                    )
                  }
                  style={styles.searchSuggestItem}
                  key={placeId}
                >
                  <View style={styles.searchSuggestTextBox}>
                    <Text numberOfLines={1} style={styles.searchSuggestText}>
                      {description}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
        {!!region.latitude && !!region.longitude && (
          <>
            <MapView
              ref={mapRef}
              style={{ ...styles.map, marginTop }}
              region={region}
              showsUserLocation
              onMapReady={onMapReady}
              // onRegionChangeComplete={onRegionChange} // need to test it for web
              moveOnMarkerPress
              onPointerMoveCapture
            >
              <Marker
                coordinate={markerCoords}
                draggable
                onDragEnd={onDragEnd}
              />
            </MapView>
          </>
        )}
      </View>
      <TouchableOpacity
        style={[styles.button, buttonLoading && { opacity: 0.6 }]}
        onPress={onLocationSelect}
        disabled={buttonLoading}
      >
        <Text style={styles.buttonText}>Select Location</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    marginBottom: 40,
  },
  map: {
    flex: 1,
  },
  mapMarkerContainer: {
    left: '47%',
    position: 'absolute',
    top: '42%',
  },
  mapMarker: {
    fontSize: 40,
    color: 'red',
  },
  deatilSection: {
    flex: 1,
    backgroundColor: colors.white,
    padding: 10,
    display: 'flex',
    justifyContent: 'flex-start',
  },
  spinnerView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: colors.black,
    borderRadius: 4,
    alignItems: 'center',
    marginTop: 20,
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    marginVertical: 12,
  },
  searchContainer: {
    zIndex: 1,
    marginTop: 4,
  },
  optionsContainer: {
    position: 'absolute',
    top: 40,
    backgroundColor: colors.white,
    width: '100%',
    boxShadow: '0px 2px 10px 1px rgba(229,229,229,0.8)',
  },
  list: {
    width: 360,
    height: '100%',
    backgroundColor: colors.white,
  },
  textInput: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    backgroundColor: colors.white,
    borderRadius: 4,
    height: 40,
    paddingHorizontal: 10,
    width: '100%',
    borderWidth: 1,
    borderColor: 'rgba(229,229,229,0.8)',
    marginBottom: 2,
    color: colors.foxyBlack,
  },
  searchSuggestItem: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    padding: 3,
    borderBottomWidth: 0.7,
    borderBottomColor: colors.background,
    height: 22,
    marginRight: 10,
  },
  searchSuggestText: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    color: colors.foxyBlack,
    marginRight: 8,
  },
  searchSuggestTextBox: {
    flex: 1,
    marginLeft: 12,
    marginRight: 12,
    alignItems: 'flex-start',
    height: 22,
    justifyContent: 'center',
  },
});
