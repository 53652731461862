import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import Config from '../libraries/ReactNativeConfig';

import {
  applyCartCoupon,
  incrementDecrementCart,
  incrementDecrementCartBeforeCall,
} from '../actions/ActionTypes';
import { EventType, AnalyticsManager, EventParameterKey } from '../analytics';
import Utility from '../utils/Utility';
import DynamicLinkManager from '../utils/DynamicLinkManager';

import FoxyAlert from '../components/camera/shared/FoxyAlert';

import colors from '../theme/Colors';
import images from '../theme/Images';
import { ProductDetailStyles } from '../components/Product/styles';
import OfferSuccessModal from '../components/modals/OfferSuccessModal';
import { isBlank, isPresent } from '../utils/BooleanUtility';
import { navigateToScreen } from '../utils/NavigationUtility';
import { SCREEN_CONSTANTS } from '../config/ScreenConstants';

const applyOffer = (WrappedComponent) => {
  return (props) => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const {
      currentSku = '',
      item: { is_wallet_offer = false } = {},
      previousScreen,
      selectedVariant,
      itemData,
      productInfo,
      fromProductPage = false,
      shopifyDirect = false,
    } = props;

    const [notClubbableCouponCode, setNotClubbableCouponCode] = useState('');
    const [notClubbableOfferId, setNotClubbableOfferId] = useState('');
    const [notClubbableWith, setNotClubbableWith] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [unfulfilledCoupon, setUnfulfilledCoupon] = useState({});
    const [couponCode, setCouponCode] = useState('');
    const [openClubbableModal, setOpenClubbableModal] = useState(false);
    const [nonClubbableOfferMeta, setNonClubbableOfferMeta] = useState(false);

    const [isError, setIsError] = useState(false);
    const [successfullyAppliedCoupon, setSuccessfullyAppliedCoupon] = useState(
      {},
    );

    const setError = () => {
      setIsError(false);
    }

    const cartItems = useSelector((state) => state.bag.cartItems) || [];

    const [isLoading, setIsLoading] = useState(false);

    const forceApplyOffer = (code) => {
      setIsLoading(true);

      dispatch(
        applyCartCoupon(
          code,
          (success, callbackData) => {
            setIsLoading(false);

            const appliedCoupon = callbackData?.prompts?.find(
              (coupon) => coupon.coupon_code === code,
            );

            if (success) {
              AnalyticsManager.logEvent('offer_applied', {
                source: 'cart',
                coupon_code: code,
                offer_id: appliedCoupon.offer_id,
                offer_type: 'offer_coupon',
                offer_applied_by: 'user_applied',
                is_wallet_offer,
                previousScreen,
              });

              AnalyticsManager.logFirebaseEvent(
                EventType.googleRemarketingEvents.SELECT_PROMOTION,
                {
                  promotion_name: code,
                  promotion_id: appliedCoupon.offer_id,
                  is_wallet_offer,
                  previousScreen,
                },
              );

              AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
                modal_name: 'OfferClaimModal',
                offer_id: appliedCoupon.offer_id,
                source: 'clubbable_modal',
                is_wallet_offer,
                previousScreen,
              });

              setSuccessfullyAppliedCoupon(appliedCoupon);
            }
          },
          false,
          true,
        ),
      );
    };

    useEffect(() => {
      if (openClubbableModal) {
        navigation.navigate(SCREEN_CONSTANTS.CLUBBABLE_MODAL, {
          notClubbableCouponCode,
          navigation,
          notClubbableOfferId,
          notClubbableWith,
          applyOffer: forceApplyOffer,
          nonClubbableOfferMeta,
        });
      }
    }, [openClubbableModal]);

    const navigateToOfferDetail = (url, id) => {
      if (Utility.isBlank(url)) {
        return;
      }

      if (url.includes('choose_free_gift')) {
        navigation.push('ChooseFreeGiftScreen', {
          id,
        });
        return;
      }

      if (Utility.isPresent(url) && url === Config.HTTPS_DOMAIN_NAME) {
        navigation.navigate('Feed');
        return;
      }

      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        url,
        ({ route = '', slug = '', extra = {} }) => {
          navigateToScreen({
            navigation,
            type: 'push',
            screen: route,
            params: { slug, extra },
          });
        },
      );
    };

    const isItemAddedToCart = (skuToAdd) => {
      const product = cartItems.filter((item) => item.sku_id === skuToAdd || item.shopify_variant_id == skuToAdd);
      return product.length > 0;
    };

    const navigateToUnfulfilledModal = () => {
      if (isBlank(unfulfilledCoupon)) return;
      navigation.navigate(SCREEN_CONSTANTS.UNFULFILLED_OFFER_MODAL, {
        navigation,
        itemData: unfulfilledCoupon,
        navigateToOfferDetail,
        skuId: currentSku,
        productInfo,
        selectedVariant,
        currentSku,
        setUnfulfilledCoupon,
        fromProductPage,
      });
    };

    useEffect(navigateToUnfulfilledModal, [unfulfilledCoupon]);

    const applyCouponCode = (
      code,
      callback,
      appliedFromDeeplink = false,
      force = false,
      source = 'cart',
      skuId = '',
    ) => {
      setIsLoading(true);
      dispatch(
        applyCartCoupon(
          code,
          (success, callbackData) => {
            setIsLoading(false);

            if (typeof callback === 'function') callback();

            if (!success){
              setIsError(true);
              if (callbackData.app_only) {
                navigation.navigate('ApplyOnAppOfferModal', {
                  couponCode: code,
                  message: callbackData?.short_description,
                  offerId: callbackData?.offer_id,
                });
                return;
              }
            };

            if (Utility.isBlank(callbackData)) return;

            if (success) {
              const appliedCoupon = callbackData?.prompts?.find(
                (coupon) => coupon.coupon_code === code,
              );

              AnalyticsManager.logEvent('offer_applied', {
                source: 'cart',
                coupon_code: code,
                offer_id: appliedCoupon?.offer_id,
                offer_type: 'offer_coupon',
                offer_applied_by: 'user_applied',
                is_wallet_offer,
                previousScreen,
              });

              AnalyticsManager.logFirebaseEvent(
                EventType.googleRemarketingEvents.SELECT_PROMOTION,
                {
                  promotion_name: code,
                  promotion_id: appliedCoupon.offer_id,
                  is_wallet_offer,
                  previousScreen,
                },
              );

              setSuccessfullyAppliedCoupon(appliedCoupon);

              AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
                modal_name: 'success_offer',
                offer_id: callbackData.offer_id,
                is_wallet_offer,
                previousScreen,
              });
            } else if (
              callbackData.condition_unfullfilled &&
              callbackData.can_offer_fullfill
            ) {
              const data = {
                coupon_code: 'COUPON UNLOCKED',
                message: callbackData.prompt_text,
              };
              setSuccessfullyAppliedCoupon(data);
              setCouponCode(code);
            } else if (!!callbackData?.condition_unfullfilled) {
              setUnfulfilledCoupon(callbackData);

              AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
                modal_name: 'unfulfilled_offer',
                offer_id: callbackData.offer_id,
                cta: callbackData.cta_text,
                is_wallet_offer,
                previousScreen,
              });
            } else if (
              Utility.isBlank(callbackData?.clubbable) &&
              Utility.isBlank(callbackData?.errors?.[0])
            ) {
              const offerPrompts = callbackData.offers_prompts.map(
                (e) => e.offer_id,
              );

              AnalyticsManager.logEvent(EventType.artistEvents.MODAL_VIEW, {
                modal_name: 'replace_offer',
                new_offer_id: callbackData.offer_id,
                old_offer_ids: offerPrompts.toString(),
                is_wallet_offer,
                previousScreen,
              });
              setNotClubbableCouponCode(callbackData.coupon_code);
              setNotClubbableOfferId(callbackData.offer_id);
              setNotClubbableWith(callbackData.offers_prompts);
              setNonClubbableOfferMeta({
                old_cart_value: callbackData.old_cart_value,
                new_cart_value: callbackData.new_cart_value,
              });
              const shouldOpenClubbableModal =
                isPresent(callbackData.coupon_code) &&
                callbackData.offer_id &&
                callbackData.offers_prompts;
              setOpenClubbableModal(shouldOpenClubbableModal);
            } else {
              AnalyticsManager.logEvent(EventType.offer.OFFER_ERROR_MODAL, {
                offer_id: callbackData?.offer_id,
                reason: callbackData?.errors[0] || '',
              });
              setErrorMessage(callbackData?.errors[0] || '');
            }
          },
          appliedFromDeeplink,
          force,
          source,
          skuId,
          shopifyDirect,
        ),
      );
    };

    const clearErrorMessage = () => {
      setErrorMessage('');
    };

    const hideSuccessModal = () => {
      setSuccessfullyAppliedCoupon({});
    };

    const removeSuccessfullyAppliedCoupon = () => {
      setSuccessfullyAppliedCoupon({});
      setCouponCode('');
    };

    const addItemToCart = () => {
      const {
        itemData: { id },
      } = props;

      const data = {
        sku_id: currentSku,
        quantity: 1,
        offer_id: couponCode,
        flashDealData: {
          type: 'Product',
          id,
        },
        shopifyDirect,
      };

      AnalyticsManager.logEvent(
        EventType.discoveryEvents.PRODUCT_ADDED_TO_CART,
        {
          [EventParameterKey.SCREEN_NAME]: 'offer_success_modal',
          [EventParameterKey.SKU_ID]: currentSku,
          [EventParameterKey.PRODUCT_ID]: id,
          [EventParameterKey.BRAND_ID]: isPresent(itemData?.brand)
            ? itemData?.brand?.id
            : '',
          [EventParameterKey.PRODUCT_LISTING_PRICE]: itemData?.mrp,
          [EventParameterKey.PRODUCT_SELLING_PRICE]: itemData?.sp,
          [EventParameterKey.QUANTITY]: 1,
          [EventParameterKey.LIST_ID]: 'NA',
          [EventParameterKey.LIST_DISPLAY]: 'NA',
          [EventParameterKey.LIST_CONTENT]: 'product',
          [EventParameterKey.LIST_POSITION]: 0,
          is_buy_now: false,
          [EventParameterKey.LIST_SLUG]: 'NA',
        },
      );

      dispatch(incrementDecrementCartBeforeCall(data));
      setSuccessfullyAppliedCoupon({});
      dispatch(
        incrementDecrementCart(data, (response) => {
          setTimeout(() => {
            navigation.push('Cart');
          }, 200);
        }),
      );
    };

    const hideProductAddToCartCTA = Utility.isPresent(currentSku)
      ? isItemAddedToCart(currentSku)
      : true;

    const onFirstButtonPress = () => {
      if (!hideProductAddToCartCTA) {
        addItemToCart();
        return;
      }
      setSuccessfullyAppliedCoupon({});
      navigateToOfferDetail(
        successfullyAppliedCoupon?.cta_url,
        successfullyAppliedCoupon?.offer_id,
      );
    };

    const offerNotClubbable = (coupon_code, offer_id, offers_prompts) => {
      setClubbableModalVisible(true);
      setNotClubbableCouponCode(coupon_code);
      setNotClubbableOfferId(offer_id);
      setNotClubbableWith(offers_prompts);
    };

    let offerSuccessModalCta = Utility.isPresent(
      successfullyAppliedCoupon?.cta_text,
    )
      ? successfullyAppliedCoupon?.cta_text
      : 'YAY !';

    let offerSuccessModalType = Utility.isPresent(
      successfullyAppliedCoupon?.cta_text,
    )
      ? 'add_to_bag'
      : 'successfully_applied';

    if (!hideProductAddToCartCTA) {
      offerSuccessModalCta = 'Add to Bag';
      offerSuccessModalType = 'add_to_bag';
    }

    const offerSuccessModalButtonStyle = !hideProductAddToCartCTA
      ? ProductDetailStyles.orderSuccessFirstActionButtonStyle
      : undefined;

    return (
      <>
        <WrappedComponent
          {...props}
          applyCartCoupon={applyCouponCode}
          isLoading={isLoading}
          error={isError}
          setError={setError}
          successfullyAppliedCoupon={successfullyAppliedCoupon}
        />

        <FoxyAlert
          isVisible={Utility.isPresent(errorMessage)}
          hideSecondButton
          alertBoxTitle="Oops"
          alertMessage={errorMessage}
          firstButtonTitle="Okay"
          firstButtonOnPress={clearErrorMessage}
          onTapOutside={clearErrorMessage}
          height={180}
          autoWrapContent
          firstButtonTextColor={colors.cta.lightBlue}
          image_url={images.alert_message.uri}
          showImage
          isAbsolute
          isError
          source={"apply_offer"}
        />

        <OfferSuccessModal
          isVisible={Utility.isPresent(successfullyAppliedCoupon)}
          hideSecondButton
          alertBoxTitle={successfullyAppliedCoupon?.coupon_code}
          alertMessage={successfullyAppliedCoupon?.message}
          firstButtonTitle={offerSuccessModalCta}
          firstButtonOnPress={onFirstButtonPress}
          onTapOutside={hideSuccessModal}
          autoWrapContent
          firstButtonTextColor={
            offerSuccessModalCta === 'YAY !'
              ? colors.cta.lightBlue
              : colors.white
          }
          image_url={images.alert_message.uri}
          showJson
          crossButtonTapped={removeSuccessfullyAppliedCoupon}
          firstActionButtonStyle={offerSuccessModalButtonStyle}
          isProductAddedToCart={hideProductAddToCartCTA}
          sku={currentSku}
          offerSuccessModalType={offerSuccessModalType}
          offerId={successfullyAppliedCoupon?.offer_id}
        />
      </>
    );
  };
};

export default applyOffer;
