import { NativeModules } from 'react-native';
import {
  NOTIFICATION_IDENTIFIERS,
  NOTIFICATION_TYPE,
  PERMISSION_CONSTANTS,
  REMOTE_CONFIG_KEYS,
  UPLOAD_NOTIFICATION_TEXT,
} from '../config/Constants';
import { isAndroid, isBlank, isIOS, isWeb } from './BooleanUtility';
import { savePermissionStatus } from './PermissionsUtility';
import { jsonParser } from './JsonUtility';
import EventParameterValue from '../analytics/EventParameterValue';
import EventType from '../analytics/AnalyticsEventType';
import { triggerPermissionsEvent } from '../analytics/PermissionsEvents';

export default class NotificationManager {
  /**
   * Right now this function will start everytime when you retry the uploading.
   * FIX: It should be invoke only once after that it should keep increasing its processing bar
   */
  static showUploadingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.startUploading();
      const payload = {
        title: UPLOAD_NOTIFICATION_TEXT.IMAGE_UPLOAD_TITLE,
        description: UPLOAD_NOTIFICATION_TEXT.IMAGE_UPLOAD_DESCRIPTION,
      };
      this.createNotification(
        payload,
        NOTIFICATION_IDENTIFIERS.PROFILE_PIC_UPLOAD,
      );
    }
  };

  static setProgress = (maxProgress, currentProgress) => {
    if (isWeb()) return;
    if (isAndroid()) {
      const max = maxProgress / 1000;
      const current = currentProgress / 1000;
      NativeModules.UploadManager.setCurrentProgress(max, current);
    }
  };

  static cancelUploadingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.cancelNotification();
    } else {
      NotificationManager.cancelNotification(
        NOTIFICATION_IDENTIFIERS.PROFILE_PIC_UPLOAD,
      );
    }
  };

  static clearNotifications = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.LocalNotificationsPackage.clearNotifications();
    }
  };

  static showSkipSelfieNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.startUploading();
      const payload = {
        title: UPLOAD_NOTIFICATION_TEXT.IMAGE_UPLOAD_TITLE,
        description: UPLOAD_NOTIFICATION_TEXT.IMAGE_UPLOAD_DESCRIPTION,
        type: NOTIFICATION_TYPE.PROFILE_PIC_UPLOAD,
      };
      this.createNotification(
        payload,
        NOTIFICATION_IDENTIFIERS.PROFILE_PIC_UPLOAD,
      );
    } else {
      NativeModules.LocalNotificationHandler.showSkipSelfieNotification();
    }
  };

  static cancelNotification = (identifier) => {
    if (isWeb()) return;
    if (isIOS()) {
      NativeModules.LocalNotificationHandler.removeNotification(identifier);
    }
  };

  static cancelCartNotification = () => {
    if (isAndroid()) {
      NativeModules.CartNotification.cancelCartNotification();
    } else {
      this.cancelNotification(NOTIFICATION_IDENTIFIERS.CART_REMINDER);
    }
  };

  static cancelSelfieSkipNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.showSkipSelfieNotification();
    } else {
      NativeModules.LocalNotificationHandler.cancelSkipSelfieNotification();
    }
  };

  static createNotification = (payload, notificationIdentifier = '') => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.LocalNotificationsPackage.createNotification(
        notificationIdentifier,
        JSON.stringify(payload),
      );
    } else {
      NativeModules.LocalNotificationHandler.createNotification(
        notificationIdentifier,
        payload,
      );
    }
  };

  static createCartNotification(payload) {
    if (isAndroid()) {
      NativeModules.CartNotification.createCartNotification(
        JSON.stringify(payload),
      );
    } else {
      this.createNotification(payload, NOTIFICATION_IDENTIFIERS.CART_REMINDER);
    }
  }

  static retryUpload = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      const payload = {
        title: 'Selfie upload failed',
        description:
          'Please make sure you are on a stable network connection and try again',
      };
      this.createNotification(
        payload,
        NOTIFICATION_IDENTIFIERS.PROFILE_PIC_UPLOAD,
      );
      if (isAndroid()) {
        NativeModules.UploadManager.retryUpload(JSON.stringify(payload));
      } else {
        NativeModules.UploadManager.retryUpload(payload);
      }
    }
  };

  static removeProgressBar = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.removeProgressBar();
    }
  };

  static showOnboardingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
    } else {
      NativeModules.LocalNotificationHandler.showOnboardingNotification();
    }
  };

  static cancelOnboardingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
    } else {
      NativeModules.LocalNotificationHandler.cancelOnboardingNotification();
    }
  };

  static startHeadless = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.startHeadlessForOnboardingNotification();
    }
  };

  static stopHeadless = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.stopHeadlessForOnboarding();
    }
  };

  static showVideoUploadingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.startVideoUploadingService();
    }
  };

  static hideVideoUploadingNotification = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.stopVideoUploadingService();
    }
  };

  static setProgressToVideoUploadNotification = (
    maxProgress,
    currentProgress,
  ) => {
    if (isWeb()) return;
    if (isAndroid()) {
      const max = maxProgress / 1000;
      const current = currentProgress / 1000;
      NativeModules.UploadManager.setProgressToVideoUploadNotification(
        max,
        current,
      );
    }
  };

  static retryVideoUpload = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.showNetworkFailureNotification();
    }
  };

  static testVideoUploadNotification = () => {
    if (isWeb()) return;
    this.showVideoUploadingNotification();
    setTimeout(() => {
      this.retryVideoUpload();
    }, 3000);
  };

  static checkNotificationPermissions = () => {
    if (isWeb()) return;
    if (isIOS()) {
      NativeModules.LocalNotificationHandler.checkNotificationPermissions();
    }
  };

  static getNotificationOnAppLaunch = () => {
    if (isWeb()) return;
    if (isIOS()) {
      NativeModules.LocalNotificationHandler.getNotificationOnAppLaunch();
    }
  };

  static getNotificationPermission = (source = '') => {
    if (isWeb()) return;
    if (isIOS()) {
      NativeModules.LocalNotificationHandler.getNotificationPermission(
        (status) => {
          let permissionStatus = '';
          if (status) {
            triggerPermissionsEvent(
              EventType.miscAppEvents.PERMISSION_ALLOW,
              EventParameterValue.PERMISSIONS.notifications,
              source,
            );
            permissionStatus = 'authorized';
            // CleverTapAnalytics.subscribeToNotifications(true);
          } else {
            triggerPermissionsEvent(
              EventType.miscAppEvents.PERMISSION_DENY,
              EventParameterValue.PERMISSIONS.notifications,
              source,
            );
            permissionStatus = 'never_ask_again';
            // CleverTapAnalytics.subscribeToNotifications(false);
          }

          savePermissionStatus(
            PERMISSION_CONSTANTS.notification,
            permissionStatus,
            true,
          );
        },
      );
    }
  };

  static checkConnection = () => {
    if (isWeb()) return;
    if (isAndroid()) {
      NativeModules.UploadManager.checkInternetPeriodically();
    }
  };
}
