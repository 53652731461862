import React, { Component } from 'react';
import { View, StyleSheet, Animated, Easing, Text, Image } from 'react-native';
import animations from '../../theme/Animations';
import GestureRecognizer from '../../libraries/ReactNativeSwipGesture';
import { TapGestureHandler } from 'react-native-gesture-handler';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
class SwipeNextAnimate extends Component {
  constructor(props) {
    super(props);
    this.timeInterval = null;
  }

  componentDidMount() {
    this.timeInterval = setInterval(() => {
      this.fireTimer();
    }, 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timeInterval);
  }

  exitCue = () => {
    const { videoSwipeNextCueDisplayed } = this.props;
    videoSwipeNextCueDisplayed();
  };

  fireTimer = () => {
    this.exitCue();
  };

  cueCardTapped = () => {
    this.exitCue();
  };

  cueSwipedUp = () => {
    const { cueSwipedUp } = this.props;
    cueSwipedUp();
    this.exitCue();
  };

  swipeUpText = () => {
    const text = 'Swipe up for more';
    return (
      <View style={styles.swipeUpTextView}>
        <Text style={styles.swipeUpText}>{text}</Text>
      </View>
    );
  };

  getLottieView = () => {
    return (
      <View style={styles.lottieContainer}>
        {/* <LottieView
          source={animations.swipeUp}
          style={styles.animationView}
          resizeMode='cover'
          resizeMode='cover'
          autoPlay={true}
          speed={0.85}
        /> */}
        <Image style={styles.animationView} source={animations.swipeUp} />
      </View>
    );
  };

  render() {
    const { showSwipeNextCue } = this.props;
    if (!showSwipeNextCue) {
      return null;
    }
    return (
      <TapGestureHandler onHandlerStateChange={this.cueCardTapped}>
        <View style={styles.container}>
          <GestureRecognizer onSwipeUp={this.cueSwipedUp}>
            <this.getLottieView />
            {/* <this.swipeUpText/> */}
          </GestureRecognizer>
        </View>
      </TapGestureHandler>
    );
  }
}

export default withNavigation(SwipeNextAnimate);

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: Utility.getScreenHeight(),
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#00000085',
    position: 'absolute',
    top: 0,
  },
  lottieContainer: {
    width: Utility.getScreenWidth(),
    height: 300,
    justifyContent: 'center',
    alignItems: 'center',
  },
  animationView: {
    width: 300,
    height: '100%',
  },
  swipeUpText: {
    fontFamily: 'roboto',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
    fontWeight: '400',
    color: 'white',
  },
  swipeUpTextView: {
    position: 'relative',
    top: -60,
    height: 20,
    width: Utility.getScreenWidth(),
    justifyContent: 'center',
    alignItems: 'center',
  },
});
