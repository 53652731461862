import { put, all, select, call, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_LIST_DATA,
  FETCH_LIST_FAILURE,
  FETCH_LIST,
  FETCH_DATA,
  LIST_DETAIL_RECEIVED,
  FETCHING_LIST,
  GET_ARTIST_STORY,
} from '../actions/ActionTypes';
import watchFetchProduct from './productSaga';
import watchUserAttributesVideos from './UserAttributesVideosSaga';
import {
  HOME_PAGE_SLUG,
  API_DOMAIN,
  SELFIE_STATE,
  BUILD_NO,
  PLATFORM_VERSION,
} from '../config/Constants';
import { convertJsonFromResponse, getApiHeaders, getDynamicListParams } from './GeneratorUtil';
import { toLower } from 'lodash';
import watchFetchMediaDetailsActionSaga from './MediaDetailsSaga';
import watchRequestOtpSaga from './RequestOtpSaga';
import watchVerifyOtpSaga from './VerifyOtpSaga';
import watchAddProfileDetailsSaga from './AddProfileDetailsSaga';
import watchVerifyHandleSaga from './VerifyHandleSaga';
import watchLocationSaga from './GetLocationSaga';
import watchAutoCompleteLocalitySaga from './AutoCompleteLocalitySaga';
import watchFetchStore from './StoreSaga';
import watchPostSelectedInterestSaga from './PostSelectedInterestSaga';
import watchGetInterestsListSaga from './GetInterestsListSaga';
import watchCreatePostSaga from './CreatePostSaga';
import watchFetchArtist from './ArtistSaga';
import watchFetchAddToCart from './CartSaga';
import watchFetchBrand from './BrandSaga';
import watchFetchStories from './StoriesSaga';
import watchFetchFavourite from './FavouriteSaga';
import watchFetchRelateContent from './RelatedContentSaga';
import watchSearchProductSaga from './SearchProductsSaga';
import watchGetSearchSuggestionSaga from './GetSearchSuggestionsSaga';
import watchSearchItem from './SearchSaga';
import watchGetHashTagsSuggestionSaga from './GetHashTagsSuggestionSaga';
import watchGetArtistsSuggestionSaga from './GetArtistSuggestionsSaga';
import watchPublishPostSaga from './PublishPostSaga';
import watchGetMediaCategoriesListSaga from './MediaCategorySaga';
import watchMediaUploadOnS3Saga from './MediaUploadOnS3Saga';
import watchFetchAddress from './AddressSaga';
import watchInstagramProfileSaga from './GetInstagramProfileSaga';
import watchGetProfilePicUrlSaga, {
  getUserFacialAttributes,
  getMyAttributes,
} from './ProfilePicUploadUrlSaga';
import watchFetchArtistOnboarding from './ArtistOnboardingSaga';
import watchUpdateConcatenatedVideoSaga from './UpdateConcatenatedVideoSaga';
import watchFetchProfileSaga from './ProfileSaga';
import watchUploadContactSaga from './UploadContactsSaga';
import watchFetchDetail from './DetailSaga';
import watchFetchOrderSaga from './OrderSaga';
import watchFetchCollection from './CollectionSaga';
import watchUACScoreCalculation from './UACEventsSaga';
import watchScanProductImageSaga from './ScanProductImageSaga';
import watchPaytmSaga from './PaytmSaga';
import watchPromptsSaga from './PromptsSaga';
import watchFetchRatings from './RatingsAndReviewsSaga';
import watchUploadReviewSaga from './ReviewUploadSaga';
import watchPaginatedCallbackSaga from './PaginationCallbackSaga';
import watchPaymentSaga from './PaymentSaga';
import watchPayoffSaga from './PayoffSagas';
import watchPostVideoViewSaga from './postVideoViewSaga';
import watchDeleteUserSaga from './DeleteUserSaga';
import watchDeleteSelfieSaga from './DeleteSelfieSaga';
import watchAutoLoginSaga from './AutoLoginSaga';
import watchPersonalizedSaga from './PersonalisedSaga';
import watchGroupDealsSaga from './GroupDealsSaga';
import watchGuestUserSaga from './GuestUserSaga';
import watchDeviceSaga from './DeviceSaga';
import watchSalonSaga from './SalonSaga';
import watchRoutinesSaga from './RoutinesSaga';
import watchRoutineSaga from './RoutineSaga';
import watchPointsSaga from './PointsSaga';
import watchRetailStoreSaga from './RetailStoreSaga';
import watchFoxyEdgeSaga from './FoxyEdgeSaga';
import watchInviteSaga from './InviteSaga';
import watchFetchFaqDetails from './FaqSectionDetailSaga';
import watchUserAttributeValues from './userAttributeValuesSaga';
import watchShopifyOrder from './ShopifyOrdersSaga';
import watchCategoryBrand from './CategorySaga';
import { isBlank, isPresent, isWeb } from '../utils/BooleanUtility';
import { appendAttributesWithHomePageUrl } from '../utils/UriUtility';
import { restructuredPersonalizedElements } from '../utils/ArrayUtility';
import Config from '../libraries/ReactNativeConfig';
import { Platform } from 'react-native';
import watchShopify from './ShopifySaga';

function* fetchSelfieData() {
  const has_selfie = yield select((state) => state.UserAccountInfo.has_selfie);
  const facialAnalysis = yield select(
    (state) => state.UserAccountInfo.facialAnalysis,
  );
  const { my_attributes = [] } = facialAnalysis;
  if (has_selfie && isBlank(my_attributes)) {
    yield getMyAttributes('', true);
  }
}

export function* fetchAsync(action) {
  const {
    callback,
    page,
    data: { slug, args = [], hideOosProduct = false },
    use_modified_product_list = false,
  } = action;
  let url = `${API_DOMAIN}${slug}`;
  const personalizedList = url.includes('personalized');
  if (url.indexOf('?Nan') === -1) {
    url = `${url}?Nan=Nan`;
  }
  if (isPresent(page)) {
    url = `${url}&page=${page}`;
  }
  url = `${url}&hide_oos=${hideOosProduct}`;
  if (personalizedList || slug === HOME_PAGE_SLUG ) {
    // if (page === 0) yield fetchSelfieData();
    const facialAnalysis = yield select(
      (state) => state.UserAccountInfo.facialAnalysis,
    );
    const personalizationAttributes = yield select(
      (state) => state?.userPersonalizationInfo,
    );
    const edgePlanId = yield select(
      (state) => state?.todayDeals?.membership_id,
    );
    const androidVersion = yield select(
      (state) => state?.UserAccountInfo?.device_information?.os_version,
    );
    const userCityName = yield select(
      (state) => state?.UserAccountInfo?.locationInfo?.city,
    );
    const osVersion = Platform.OS === 'ios' ? PLATFORM_VERSION : androidVersion;

    if (
      isPresent(facialAnalysis) &&
      isPresent(facialAnalysis.facialProperties)
    ) {
      const { facialProperties } = facialAnalysis;
      url = appendAttributesWithHomePageUrl(
        url,
        facialProperties,
        false,
      );
    } else if (isPresent(facialAnalysis.my_attributes_values)) {
      url = appendAttributesWithHomePageUrl(
        url,
        facialAnalysis.my_attributes_values,
        true,
      );
    }
    if (isPresent(personalizationAttributes)) {
      const personalizedAttributesArray = restructuredPersonalizedElements(
        personalizationAttributes,
      );
      url = appendAttributesWithHomePageUrl(
        url,
        personalizedAttributesArray,
        true,
      );
    }
    //TODO:This code needs to be optimized on priority
    if (isPresent(osVersion)) {
      url = `${url}&os_version=${osVersion}`;
    }
    if (isPresent(edgePlanId)) {
      url = `${url}&edge_plan_id=${edgePlanId}`;
    }
    if (isPresent(userCityName)) {
      url = `${url}&city_name=${toLower(userCityName)}`;
    }
    url = `${url}&app_version=${Config.VERSION_NAME}`;
  }

  if (isPresent(args) && args?.length > 0) {
    for (const item of args) {
      const params = yield getDynamicListParams(item);
      url += params;
    }
  }
  let sendCallbackData = true;

  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false, { errorType: 'api_failure', status: 'connection_error' });
  }, 25000);

  try {
    const authToken = yield select((state) => state.UserAccountInfo.authToken);
    const guestAuthToken = yield select(
      (state) => state.UserAccountInfo.guestProfile.guestAuthToken,
    );

    const headers = yield getApiHeaders();
    if (isWeb()) {
      headers.Platform = 'web';
    }
    // yield put({ type: FETCHING_LIST, refreshing: action.data.refreshing }); // TODO: this is making onendreached being called multiple times in vertical.js
    const response = yield fetch(
    `${url}&show_special_offer=true&build_number=${BUILD_NO}&use_modified_product_list=${use_modified_product_list}`,
      {
        method: 'GET',
        headers,
      },
    );

    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      const json = yield convertJsonFromResponse(response);
      if (isBlank(json)) {
        callback(false, { errorType: 'api_failure', status: 'server_error' });
        return;
      }
      callback(true, json);
    } else {
      callback(false, { errorType: 'api_failure', status: 'server_error' });
    }
  } catch (error) {
    callback(false, { errorType: 'api_failure', status: 'connection_error' });
  } finally {
    clearTimeout(timer);
  }
}

export function* fetchDataAsync(action) {
  const {
    callback,
    data: { slug, method = 'GET', body = {} },
  } = action;
  const url = `${API_DOMAIN}${slug}`;

  let sendCallbackData = true;
  const timer = setTimeout(() => {
    sendCallbackData = false;
    callback(false, { errorType: 'api_failure', status: 'connection_error' });
  }, 25000);

  try {
    let requestBody = {
      method,
      headers: yield getApiHeaders(),
    };
    if (isPresent(body)) {
      requestBody = { ...requestBody, body: JSON.stringify(body) };
    }

    const response = yield fetch(`${url}`, requestBody);

    if (response.status >= 200 && response.status < 400 && sendCallbackData) {
      const json = yield convertJsonFromResponse(response);
      if (isBlank(json)) {
        callback(false, { errorType: 'api_failure', status: 'server_error' });
        return;
      }
      clearTimeout(timer);
      callback(true, json);
    } else {
      callback(false, { errorType: 'api_failure', status: 'server_error' });
    }
  } catch (error) {
    callback(false, { errorType: 'api_failure', status: 'connection_error' });
  } finally {
    clearTimeout(timer);
  }
}
// Our watcher Saga:
export function* watchFetchFeed() {
  yield takeEvery(FETCH_LIST, fetchAsync);
  yield takeEvery(FETCH_DATA, fetchDataAsync);
}

export default function* rootSaga() {
  yield all([
    watchFetchFeed(),
    watchFetchProduct(),
    watchUserAttributesVideos(),
    watchFetchMediaDetailsActionSaga(),
    watchRequestOtpSaga(),
    watchVerifyOtpSaga(),
    watchAddProfileDetailsSaga(),
    watchVerifyHandleSaga(),
    watchLocationSaga(),
    watchAutoCompleteLocalitySaga(),
    watchFetchStore(),
    watchGetInterestsListSaga(),
    watchPostSelectedInterestSaga(),
    watchCreatePostSaga(),
    watchFetchArtist(),
    watchFetchAddToCart(),
    watchFetchBrand(),
    watchFetchStories(),
    watchFetchFavourite(),
    watchFetchRelateContent(),
    watchSearchProductSaga(),
    watchGetSearchSuggestionSaga(),
    watchSearchItem(),
    watchGetHashTagsSuggestionSaga(),
    watchGetArtistsSuggestionSaga(),
    watchPublishPostSaga(),
    watchGetMediaCategoriesListSaga(),
    watchMediaUploadOnS3Saga(),
    watchFetchAddress(),
    watchInstagramProfileSaga(),
    watchGetProfilePicUrlSaga(),
    watchFetchArtistOnboarding(),
    watchUpdateConcatenatedVideoSaga(),
    watchFetchProfileSaga(),
    watchUploadContactSaga(),
    watchFetchDetail(),
    watchFetchOrderSaga(),
    watchFetchCollection(),
    watchUACScoreCalculation(),
    watchScanProductImageSaga(),
    watchPaytmSaga(),
    watchPromptsSaga(),
    watchFetchRatings(),
    watchUploadReviewSaga(),
    watchPaginatedCallbackSaga(),
    watchPaymentSaga(),
    watchPayoffSaga(),
    watchPostVideoViewSaga(),
    watchDeleteUserSaga(),
    watchDeleteSelfieSaga(),
    watchAutoLoginSaga(),
    watchPersonalizedSaga(),
    watchGroupDealsSaga(),
    watchGuestUserSaga(),
    watchSalonSaga(),
    watchDeviceSaga(),
    watchRoutinesSaga(),
    watchRoutineSaga(),
    watchPointsSaga(),
    watchRetailStoreSaga(),
    watchFoxyEdgeSaga(),
    watchInviteSaga(),
    watchFetchFaqDetails(),
    watchUserAttributeValues(),
    watchShopifyOrder(),
    watchCategoryBrand(),
    watchShopify(),
  ]);
}
