import Config from '../libraries/ReactNativeConfig';
import NavigationService from '../navigator/NavigationService';
import DynamicLinkManager from './DynamicLinkManager';
import { isIOS } from './BooleanUtility';
import DynamicLinkUtilities from './DynamicLinkUtilities';

const HandleNavigationFromWebview = (clickParams, currentUrl) => {
  const { url = '', navigationType } = clickParams;

  const { slug: currentSlug, route: currentRoute } = getRouteAndSlugFromUrl(currentUrl);
  const { slug, route } = getRouteAndSlugFromUrl(url);
  const isExternalLink = isIOS() ? navigationType === 'click' : true;

  if ((isExternalLink && route === currentRoute && currentSlug === slug)) return false;

  const isProductClick = url.startsWith(`${Config.WEB_URL}/products/`) || url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/products/`);
  const isCollectionClick = url.startsWith(`${Config.WEB_URL}/collections/`) || url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/collections/`);
  const isPages = url.startsWith(`${Config.WEB_URL}/pages/`) || url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/pages/`);
  const isBlogs = url.startsWith(`${Config.WEB_URL}/blogs/`) || url.startsWith(`${Config.HTTPS_WWW_WEB_URL}/blogs/`);
  if (isExternalLink) {
    if (isProductClick || isCollectionClick || isBlogs || isPages) {
      navigate(url);
    }
    return false;
  }
  return !isExternalLink;
};

export default HandleNavigationFromWebview;

const handleRouteFromLink = ({ route = '', slug = '', extra = {}, path }) => {
  if (route === 'GoBack') {
    NavigationService.goBack();
    return;
  }
  NavigationService.push(route, { slug, extra, destination: slug, path });
};

const navigate = (url) => {
  DynamicLinkManager.handleLinkWithInternalTrackingParams(
    url,
    handleRouteFromLink,
  );
};

const getRouteAndSlugFromUrl = (url) => {
  const [slug, params] = DynamicLinkUtilities.removeDomainName(url);
  const route = DynamicLinkUtilities.extractRouteFromSlug(slug, url);
  return { slug, route };
};
