// Dependencies
import React, { Component } from 'react';
import {
  ImageBackground,
  View,
  Image,
  Text,
  FlatList,
  ScrollView,
} from 'react-native';
import Orientation from 'react-native-orientation';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import { sharedIds, sharedStyles } from './styles';
import StaticNavigationHeader from './StaticNavigationHeader';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import FoxyEdgeDiscoveryCard from '../../containers/FoxyEdge/FoxyEdgeDiscoveryCard';
import colors from '../../theme/Colors';
import CategoryBrowsingPageShimmer from './shimmers/CategoryBrowsingPageShimmer';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import RemoteConfig from '../../utils/RemoteConfig';
import { jsonParser } from '../../utils/JsonUtility';
import { isDesktop, isNative } from '../../utils/BooleanUtility';
import { getScreenWidth } from '../../utils/LayoutUtility';

class ScreenPlaceholder extends Component {
  constructor(props) {
    super(props);
    this.edgeExtensionCardData = jsonParser(RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.edge_extension_card_position_in_cart,
    ));
    const { show_edge_extension_card = false } =
      this.edgeExtensionCardData || {};
    this.showEdgeExtensionCard = show_edge_extension_card;
  }

  componentDidMount() {
    if (isNative()) {
      Orientation.lockToPortrait();
    }
  }

  componentWillUnmount() {
    const { firePageLoadEnd = () => {} } = this.props;
    firePageLoadEnd();
  }

  railPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.railPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.productBrandNamePlaceholder}
        />
        <ShimmerPlaceHolder autoRun style={styles.productTextPlaceholder} />
        <ShimmerPlaceHolder autoRun style={styles.productTextPlaceholder} />
      </View>
    );
  };

  contentPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.contentPlaceholderShimmerContainer}>
        <ShimmerPlaceHolder autoRun style={styles.cntentImagePlaceholder} />
        <ShimmerPlaceHolder autoRun style={styles.contentNamePlaceholder} />
        <ShimmerPlaceHolder
          autoRun
          style={styles.contentArtistImagePlaceholder}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.contentArtistNamePlaceholder}
        />
      </View>
    );
  };

  longHeaderPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.longHeaderPlaceholder}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.headerLongTextShimmerStyle}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
      </View>
    );
  };

  headerPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.headerPlaceholder}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.headerTextShimmerStyle}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
      </View>
    );
  };

  storyPlaceHolder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.storyPlaceHolder} dataSet={{ media: sharedIds.storyPlaceHolder }}>
        <ShimmerPlaceHolder
          autoRun
          style={styles.story}
          dataSet={{ media: sharedIds.story }}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
        <ShimmerPlaceHolder
          autoRun
          style={styles.storyText}
          dataSet={{ media: sharedIds.storyText }}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
      </View>
    );
  };

  largeBannerPlaceHolder = () => {
    const styles = sharedStyles;
    const { previousScreen = '' } = this.props;
    const largeBannerContainer = previousScreen.includes('foxy_edge')
      ? styles.edgeFeatureCardPlaceholder
      : styles.largeBannerContainer;
    return (
      <View>
        <ShimmerPlaceHolder
          autoRun
          style={largeBannerContainer}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
      </View>
    );
  };

  gridPlaceHolder = () => {
    const styles = sharedStyles;
    return (
      <View>
        <ShimmerPlaceHolder
          autoRun
          style={styles.gridContainer}
          colorShimmer={['#ffffff1A', '#ffffff33', '#ffffff1A']}
        />
      </View>
    );
  };

  mediaListPlaceholder = () => {
    const styles = sharedStyles;
    const { previousScreen = '' } = this.props;
    const mediaCardPlaceholderContent =
      previousScreen === SCREEN_CONSTANTS.PRE_ROUTINE ||
      previousScreen === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE
        ? [styles.mediaCardPlaceholderContent, { left: 0 }]
        : styles.mediaCardPlaceholderContent;
    const mediaCardPlaceholder =
      previousScreen === SCREEN_CONSTANTS.PRE_ROUTINE ||
      previousScreen === SCREEN_CONSTANTS.SUBSCRIBE_ROUTINE
        ? styles.mediaCardPlaceholder
        : [styles.mediaCardPlaceholder, { marginLeft: 12 }];
    return (
      <View>
        <View style={mediaCardPlaceholderContent}>
          <ShimmerPlaceHolder
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            autoRun
            style={styles.mediaCardPlaceholderProfile}
          />
          <View>
            <ShimmerPlaceHolder
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
              autoRun
              style={styles.mediaCardPlaceholderTitle}
            />
            <ShimmerPlaceHolder
              colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
              autoRun
              style={styles.mediaCardPlaceholderSubtitle}
            />
          </View>
        </View>
        <View style={mediaCardPlaceholder} />
      </View>
    );
  };

  featureListPlaceholder = () => {
    const styles = sharedStyles;
    return (
      <View style={styles.featureCardPlaceholderContainer}>
        <View>
          <ShimmerPlaceHolder
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            autoRun
            style={styles.featurePlaceholderTitle}
          />
          <ShimmerPlaceHolder
            colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
            autoRun
            style={styles.featurePlaceholderSubtitle}
          />
        </View>
        <ShimmerPlaceHolder
          colorShimmer={['#fafafa', '#eeeeee', '#fafafa']}
          autoRun
          style={styles.featurePlaceholderButton}
        />
      </View>
    );
  };

  render() {
    const { fullscreen, previousScreen = '', shimmerDisplay = '' } = this.props;
    const styles = sharedStyles;
    const showMediaShimmer =
      previousScreen.includes('routine') && shimmerDisplay === 'mediaRail';
    const showFeatureShimmer =
      previousScreen.includes('routine') && shimmerDisplay === 'featureRail';
    if (showMediaShimmer) {
      return (
        <ScrollView
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
        >
          <this.mediaListPlaceholder />
          <this.mediaListPlaceholder />
        </ScrollView>
      );
    }
    if (showFeatureShimmer) {
      return (
        <ScrollView
          horizontal
          scrollEnabled={false}
          showsHorizontalScrollIndicator={false}
        >
          <this.featureListPlaceholder />
          <this.featureListPlaceholder />
        </ScrollView>
      );
    }
    if (
      previousScreen === SCREEN_CONSTANTS.NON_PERSONALISED_FEED &&
      this.showEdgeExtensionCard
    ) {
      return <FoxyEdgeDiscoveryCard />;
    }
    if (
      previousScreen === SCREEN_CONSTANTS.CATEGORY_BROWSING ||
      previousScreen === SCREEN_CONSTANTS.VERTICAL_TABBED
    ) {
      return <CategoryBrowsingPageShimmer previousScreen={previousScreen} />;
    }
    const shimmerContainerStyle =
      previousScreen === SCREEN_CONSTANTS.NON_MEMBER_LANDING_PAGE ||
      previousScreen === SCREEN_CONSTANTS.MEMBER_LANDING_PAGE
        ? styles.shimmerContainerWhite
        : styles.shimmerContainer;
    return (
      <>
        {fullscreen && <StaticNavigationHeader title="" />}
        <View style={shimmerContainerStyle}>
          {previousScreen === 'feed' && (
            <View style={styles.storyContainer} dataSet={{ media: sharedIds.storyContainer }}>
              <this.storyPlaceHolder />
              <this.storyPlaceHolder />
              <this.storyPlaceHolder />
              <this.storyPlaceHolder />
              <this.storyPlaceHolder />
              {isDesktop() && (
                <>
                  <this.storyPlaceHolder />
                  <this.storyPlaceHolder />
                </>
              )}
            </View>
          )}
          <View style={styles.itemPlaceholder} dataSet={{ media: sharedIds.itemPlaceholder }}>
            <this.largeBannerPlaceHolder />
          </View>
          <this.headerPlaceholder />
          <this.longHeaderPlaceholder />
          <View style={styles.gridRowPlaceholder} dataSet={{ media: sharedIds.gridRowPlaceholder }}>
            <this.gridPlaceHolder />
            <this.gridPlaceHolder />
          </View>
          <View style={[styles.gridRowPlaceholder, { marginTop: 8 }]} dataSet={{ media: sharedIds.gridRowPlaceholder }}>
            <this.gridPlaceHolder />
            <this.gridPlaceHolder />
          </View>
        </View>
      </>
    );
  }
}

export default ScreenPlaceholder;
