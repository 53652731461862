import React, { useState, useEffect } from 'react';
import {
  FlatList,
  Image,
  LayoutAnimation,
  StyleSheet,
  View,
  TouchableOpacity,
  ScrollView,
  Text,
} from 'react-native';
import FastImageView from '../../FastImageView';
import colors from '../../../theme/Colors';
import images from '../../../theme/Images';
import { isBlank } from '../../../utils/BooleanUtility';
import { cartItemsStyles } from '../../payment/paymentStatus/styles';
import { CURRENCY_SYMBOL } from '../../../config/Constants';
const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

const OrderItemsAccordion = (props) => {
  const { skus: rawSkus } = props;
  const [skus, setSkus] = useState([]);
  const [showItemsDetails, setShowItemsDetails] = useState(false);

  useEffect(() => {
    if (isBlank(rawSkus)) {
      return;
    }
    // Deserialize the skus data
    new JSONAPIDeserializer({
      typeAsAttribute: false,
      pluralizeType: true,
      keyForAttribute: 'camelCase',
    })
      .deserialize(rawSkus)
      .then((deserializedData) => {
        setSkus(deserializedData);
      })
      .catch((error) => {});
  }, [rawSkus]);

  const toggleShowItemsDetails = () => {
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
    setShowItemsDetails((prevState) => !prevState);
  };

  const renderOrderItemImage = ({ item }) => (
    <FastImageView
      source={{ uri: item.imageUrl }}
      style={styles.orderItem}
      resizeMode='cover'
    />
  );

  const keyExtractor = (item) => item.id.toString();

  if (isBlank(skus)) {
    return null;
  }

  return (
    <View>
      <ScrollView
        style={styles.orderItemsContainer}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        <FlatList
          data={skus}
          renderItem={renderOrderItemImage}
          keyExtractor={keyExtractor}
          horizontal
        />
      </ScrollView>

      {showItemsDetails && <ItemDetailsList skus={skus} />}

      <TouchableOpacity
        style={styles.chevronContainer}
        onPress={toggleShowItemsDetails}
      >
        <Image
          source={showItemsDetails ? images.chevronUp : images.chevronDown}
          style={styles.chevron}
        />
      </TouchableOpacity>
    </View>
  );
};

const ItemDetailsList = ({ skus }) => (
  <View style={styles.itemSummary}>
    {skus.map((item) => (
      <View key={item.id} style={styles.itemSummaryContainer}>
        <FastImage source={{ uri: item.imageUrl }} style={styles.itemImage} />
        <View style={styles.itemDetailsContainer}>
          <Text style={cartItemsStyles.brandName}>{item.brandName}</Text>
          <Text style={cartItemsStyles.productName}>{item.name}</Text>
        </View>
        <View style={cartItemsStyles.spContainer}>
          <Text style={cartItemsStyles.sp}>
            {`${CURRENCY_SYMBOL} ${item.esp || 0}`}
          </Text>
        </View>
      </View>
    ))}
  </View>
);

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingRight: 12,
  },
  itemSummary: {
    backgroundColor: colors.white,
    paddingVertical: 12,
    borderTopWidth: 0.3,
    borderTopColor: colors.lightGrey,
    marginTop: 8,
  },
  itemSummaryContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.white,
    paddingRight: 12,
  },
  itemDetailsContainer: {
    flexDirection: 'column',
    flex: 4,
    justifyContent: 'space-between',
  },
  itemImage: {
    height: 60,
    width: 60,
  },
  chevron: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: colors.foxyBlack,
  },
  orderItemsContainer: {
    flexDirection: 'row',
    marginVertical: 8,
    zIndex: 1,
  },
  orderItem: {
    height: 36,
    width: 36,
    borderRadius: 18,
    borderWidth: 1,
    borderColor: '#F4F6F8',
    marginLeft: 4,
  },
  chevronContainer: {
    position: 'absolute',
    right: 12,
    top: 12,
    borderColor: colors.border,
    borderWidth: 1,
    height: 32,
    width: 32,
    borderRadius: 18,
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
});

export default OrderItemsAccordion;
