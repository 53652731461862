import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  RefreshControl,
  BackHandler,
  View,
  Dimensions,
  StyleSheet,
} from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { AnalyticsScreenNames } from '../../config/Constants';
import { getMyCollection } from '../../actions/ActionTypes';
import List from '../List/List';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import {
  AnalyticsManager,
  EventType,
  EventParameterKey,
} from '../../analytics';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import { ScreenPlaceholder } from '../../components/shared';
import StaticNavigationHeader from '../../components/shared/StaticNavigationHeader';
import colors from '../../theme/Colors';
import ServerError from '../../components/shared/ServerError';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';

class MyCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listData: {},
      isRefreshing: false,
      loading: false,
      serverError: false,
    };
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.onHardwareBackKeyPress,
      );
    }
  }

  componentDidMount() {
    AnalyticsManager.logEvent(EventType.discoveryEvents.MYKIT_VIEW);
    this.getMyCollectionData();
  }

  onHardwareBackKeyPress = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  myCollectionApiCallback = (success, data) => {
    this.setState({
      listData: data || {},
      isRefreshing: false,
      loading: false,
      serverError: !success,
    });
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.MYKIT,
    });
  };

  getMyCollectionData = () => {
    const { getMyCollection } = this.props;
    this.setState({
      loading: true,
    });
    AnalyticsUtility.recordTime(
      { screen_name: SCREEN_CONSTANTS.MYKIT },
      this.myCollectionApiCallback,
      getMyCollection,
      this.myCollectionApiCallback,
    );
  };

  refreshPage = () => {
    this.setState({
      isRefreshing: true,
    });
    this.getMyCollectionData();
  };

  onDidFocus = () => {
    AnalyticsManager.logEvent(EventType.discoveryEvents.MYKIT_VIEW);
    this.getMyCollectionData();
  };

  componentWillUnmount() {
    if (Utility.isAndroid()) {
      this.backhandler.remove();
    }
  }

  render() {
    const { isRefreshing, listData, loading, serverError } = this.state;
    const { navigation } = this.props;

    return (
      <>
        <StaticNavigationHeader title='My Kit' />
        <ErrorBoundary
          screen_name={AnalyticsScreenNames.MY_KIT}
          itemData={listData}
          showServerError={serverError}
          hideHeader
          screen_name={'my_collection'}
        >
          {loading && <ScreenPlaceholder />}
          {!loading && (
            <ScrollView
              refreshControl={
                <RefreshControl
                  refreshing={isRefreshing}
                  onRefresh={this.refreshPage}
                />
              }
              style={style.listContainerStyle}
              contentContainerStyle={style.contentContainerStyle}
            >
              <List
                itemData={listData}
                navigation={navigation}
                previousScreen={SCREEN_CONSTANTS.MYKIT}
                useInViewPort
              />
            </ScrollView>
          )}
        </ErrorBoundary>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        getMyCollection,
      },
      dispatch,
    ),
  };
}

const style = StyleSheet.create({
  listContainerStyle: {
    backgroundColor: colors.background,
  },
  contentContainerStyle: {
    alignItems: 'center',
  },
});

export default withNavigation(connect(null, mapDispatchToProps)(MyCollection));
