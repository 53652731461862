import {
  View,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Image,
  Text,
  SafeAreaView,
  StyleSheet,
} from 'react-native';
import { memoize } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import Toast from 'react-native-easy-toast';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { PopUpStyles } from './styles';
import { isPresent } from '../../utils/BooleanUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import {
  getSimilarProducts,
} from '../../actions/ActionTypes';
import Header from '../header/Header';
import List from '../../containers/List/List';
import { sharedStyles } from '../shared/styles';
import ModalContainerWithoutSafeArea from '../modals/ModalContainerWithoutSafeArea';


export default function UnavailableProductsModal() {
  const navigation = useNavigation();
  const { params = {} } = useRoute();
  const toastRef = useRef();
  const [item, setItem] = useState(null);
  const dispatch = useDispatch();

  const {
    itemData,
    id,
  } = params;

  useEffect(() => {
    dispatch(getSimilarProducts((id || itemData.id), (success, response) => {
      if (success) {
        setItem(response);
      }
    }));
  }, []);

  const goBack = () => {
    navigation.goBack();
  };

  let modalHeading;
  const outOfStockHeading = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.outofstock_modal_message,
  );
  const discontinuedHeading = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.discontinued_product_modal_message,
  );
  const unStockedHeading = RemoteConfig.getValue(
    REMOTE_CONFIG_KEYS.unstocked_product_modal_message,
  );
  if (itemData && itemData.stocked_status === 'unstocked') {
    modalHeading = unStockedHeading;
  } else if (itemData && itemData.stocked_status === 'discontinued') {
    modalHeading = discontinuedHeading;
  } else {
    modalHeading = outOfStockHeading;
  }

  const showToast = (message) => {
    toastRef.current.show(message, 1000);
  };

  const ctaTextStyles = getMemoizedStyles(Utility.bottomInset);

  return (
    <>
      <ModalContainerWithoutSafeArea>
        <View style={modalStyles.modalContainer} pointerEvents='box-none'>
          <View style={PopUpStyles.container}>
            <View
              style={[
                PopUpStyles.addReviewHeader,
                {
                  justifyContent: 'space-between',
                  paddingTop: 10,
                  paddingRight: 10,
                },
              ]}
            >
              <Text style={PopUpStyles.reviewHeaderText}>{modalHeading}</Text>
              <TouchableOpacity onPress={goBack}>
                <View style={modalStyles.crossIcon}>
                  <Image
                    source={images.cross}
                    style={PopUpStyles.cancelStyle}
                  />
                </View>
              </TouchableOpacity>
            </View>

            {isPresent(item) ? (
              <ProductRail
                item={item}
                showToast={showToast}
                goBack={goBack}
              />
            ) : (
              <Shimmer />
            )}

            <TouchableOpacity style={ctaTextStyles} onPress={goBack}>
              <Text style={PopUpStyles.submitText}>Continue Shopping</Text>
            </TouchableOpacity>
          </View>
          <Toast ref={toastRef} />
        </View>
      </ModalContainerWithoutSafeArea>
    </>
  );
}

const HeaderPlaceholder = () => (
  <View
    style={modalStyles.header}
  >
    <ShimmerPlaceHolder autoRun style={sharedStyles.headerTextShimmerStyle} />
  </View>
);

const Shimmer = () => (
  <View style={modalStyles.shimmerContainer}>
    <HeaderPlaceholder />
    <View style={modalStyles.shimmer}>
      <RailPlaceholder />
      <RailPlaceholder />
      <RailPlaceholder />
    </View>
  </View>
);

const RailPlaceholder = () => (
  <View style={sharedStyles.railPlaceholderShimmerContainerInModal}>
    <ShimmerPlaceHolder
      autoRun
      style={sharedStyles.productBrandNamePlaceholder}
    />
    <ShimmerPlaceHolder autoRun style={sharedStyles.productTextPlaceholder} />
    <ShimmerPlaceHolder autoRun style={sharedStyles.productTextPlaceholder} />
  </View>
);

const ProductRail = ({ item, goBack, showToast }) => (
  <View style={modalStyles.railContainer}>
    <Header
      item={item}
      layout={item.display}
      fromModal
      title="Similar products"
      moreClick={goBack}
    />
    <List
      id={item.id}
      itemData={item}
      toggleUnavailableProductsModal={goBack}
      showToast={showToast}
    />
  </View>
);

const modalStyles = StyleSheet.create({
  touchableContainer: {
    height: '100%',
    width: '100%',
  },
  modalContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  shimmerContainer: {
    flexDirection: 'column',
    paddingLeft: 16,
    paddingRight: 16,
    backgroundColor: colors.background,
  },
  shimmer: {
    flexDirection: 'row',
    marginLeft: 12,
    marginRight: 16,
    width: '28%',
  },
  crossIcon: {
    height: 28,
    width: 28,
    borderRadius: 40,
    backgroundColor: colors.background,
    justifyContent: 'center',
    alignItems: 'center',
  },
  railContainer: {
    width: Utility.getScreenWidth(),
  },
  header: {
    height: 30,
    width: '100%',
    marginTop: 24,
    marginLeft: 12,
  },
  SafeAreaView: {
    flex: 1,
    justifyContent: 'flex-end',
    alignSelf: 'center',
  },
});

const getMemoizedStyles = memoize((bottomInset) => [
  PopUpStyles.submitButton,
  { marginBottom: bottomInset },
]);
