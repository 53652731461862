import { Platform } from 'react-native';
import { takeLatest, put, select } from 'redux-saga/effects';
import {
  GET_PRODUCT_RATINGS,
  FETCH_PRODUCT_FOR_REVIEW,
} from '../actions/RatingAndReviewsActions';
import { ADDRESS_URL, URL, API_DOMAIN } from '../config/Constants';
import { logoutUser } from '../actions/LoginActions';
import {
  getApiHeaders,
  convertJsonFromResponse,
} from './GeneratorUtil';
import Utility from '../utils/Utility';
import { isPresent } from '../utils/BooleanUtility';

function* getProductReviews(action) {
  const { metaData, callback = () => {} } = action;
  try {
    const {
      productId,
      page,
      filterRatings = [],
      showVerifiedOnly = false,
      sort_by,
      influencerHandle = ''
    } = metaData;
    let url = `${API_DOMAIN}/api/v2/products/${productId}/reviews?page=${page}`;

    if (showVerifiedOnly) {
      url = `${url}&verified_only=${showVerifiedOnly}`;
    }

    if (Utility.isPresent(sort_by)) {
      url = `${url}&sort_by=${sort_by}`;
    }

    if (isPresent(influencerHandle)) {
      url = `${API_DOMAIN}/api/v2/profiles/${influencerHandle}/reviews?page=${page}`;
    }

    url = Utility.updateRatingsUrl(url, filterRatings);

    const response = yield fetch(url, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

function* fetchProductForReview(action) {
  const { lookback_days = 7, callback = () => {} } = action;
  try {
    const api = `${URL.PRODUCT_FOR_REVIEW_URL}?lookback_days=${lookback_days}`;

    const response = yield fetch(api, {
      method: 'GET',
      headers: yield getApiHeaders(),
    });
    if (response.status >= 200 && response.status < 300) {
      const json = yield convertJsonFromResponse(response);
      if (Utility.isBlank(json)) {
        callback(false);
        return;
      }
      callback(true, json);
    } else if (response.status >= 400 && response.status < 500) {
      callback(false);
    } else {
      callback(false);
    }
  } catch (error) {
    callback(false);
  }
}

export default function* watchFetchRatings() {
  yield takeLatest(GET_PRODUCT_RATINGS, getProductReviews);
  yield takeLatest(FETCH_PRODUCT_FOR_REVIEW, fetchProductForReview);
}
