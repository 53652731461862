import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import colors from '../../theme/Colors';
import PRODUCT from '../../config/LayoutConstants/ProductConfig';
import size from '../../theme/Fonts';
import { getPriceText } from '../../utils/NumberUtility';
import Config from 'react-native-config';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import ColorSwatches from '../Product/ColorSwatches';
import Session from '../../utils/Sessions';

const ShopifyProductDescription = (props) => {
  const {
    layout,
    size,
    brandName,
    hideBrandName,
    name,
    titleLineCount = 2,
    mrp,
    sp = mrp,
    discount,
    itemData: { options = [] } = {},
    itemData = {},
  } = props;

  const colorVariants = options.filter((option) => option.name === 'Color')?.[0] || {};

  const colorVariantsData = colorVariants.values?.map((key) => {
    return {
      ...(Session.colorMappingShopify[key.toLowerCase()] || { color_code: colors.grey }),
      name: key,
    };
  });

  const rep = new RegExp(brandName, 'gi');
  const productName = name.replace(rep, '').trim();

  const BrandName = () => {
    if (hideBrandName) {
      return null;
    }
    return (
      <Text numberOfLines={PRODUCT.MAX_LINES.BRAND_NAME[layout][size] || 1} style={styles[`${layout}BrandName`]}>
        {brandName?.toUpperCase()}
      </Text>
    );
  };

  const ProductName = () => {
    const productNameStyle = styles[`${layout}ProductName`];
    const isSingleOrMultiLine = parseInt(titleLineCount) === 1 ? 'SingleLine' : 'MultiLine';
    const productNameContainer = styles[`productName${isSingleOrMultiLine}Container`];
    return (
      <View style={productNameContainer}>
        <Text allowFontScaling={false} numberOfLines={2} ellipsizeMode={'tail'} style={productNameStyle}>
          {productName}
        </Text>
      </View>
    );
  };

  const DiscountSection = () => {
    if (isBlank(discount) || discount === 0) return null;
    return <Text style={styles.discountRateStyle}>{discount}% off</Text>;
  };

  const PricingComponent = () => {
    return (
      <View style={styles.priceContainer}>
        <Text style={styles.spStyle}>{`${getPriceText(parseFloat(sp))} `}</Text>
        {discount > 0 && <Text style={styles.mrpStyle}>{getPriceText(parseFloat(mrp))}</Text>}
        <DiscountSection />
      </View>
    );
  };

  return (
    <View style={styles.descriptionContainer}>
      <BrandName />
      <ProductName />
      <PricingComponent />
      {isPresent(colorVariantsData) && (
        <View style={styles.colorSwatchesContainer}>
          <ColorSwatches colorSwatches={colorVariantsData} type={itemData.type} layout={layout} size={size} />
        </View>
      )}
    </View>
  );
};

export default ShopifyProductDescription;

const styles = StyleSheet.create({
  gridBrandName: {
    marginTop: 8,
    marginLeft: 8,
    marginRight: 35,
    fontFamily: 'Roboto-Bold',
    fontSize: 12,
    fontStyle: 'normal',
    letterSpacing: 0,
    color: colors.disabled,
  },
  descriptionContainer: {
    width: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    justifyContent: 'flex-start',
    alignSelf: 'flex-start',
    alignItems: 'flex-start',
  },
  gridProductName: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: size.h3,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
    marginLeft: 8,
    marginRight: 8,
    marginVertical: 4,
  },
  productNameMultiLineContainer: { height: 50 },
  productNameSingleLineContainer: { height: 25 },
  railProductName: {
    marginLeft: 8,
    marginRight: 8,
    marginTop: 1,
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlack,
    fontSize: 14,
    marginVertical: 4,
    fontStyle: 'normal',
    letterSpacing: 0,
    textAlign: 'left',
    alignItems: 'flex-start',
  },
  priceContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    paddingLeft: 8,
    alignItems: 'center',
  },
  mrpStyle: {
    color: colors.silver,
    fontSize: size.h4,
    textDecorationLine: 'line-through',
    marginLeft: 3,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
  },
  spStyle: {
    color: colors.foxyBlack,
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    fontStyle: 'normal',
  },
  discountRateStyle: {
    color: Config.PRODUCT_DISCOUNT_COLOR,
    fontSize: size.h4,
    marginLeft: 3,
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    opacity: 0.7,
  },
  colorSwatchesContainer: {
    marginTop: 6,
  },
});
