import React, { useEffect, useState, useRef } from 'react';
import {
  ScrollView,
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  KeyboardAvoidingView,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import { OutlinedTextField } from 'react-native-material-textfield';
import { useNavigation, useRoute } from '@react-navigation/native';
import colors from '../../theme/Colors';
import InfluencerTag from './InfluencerTag';
import ImageUploader from './UploadProfileImage';
import { isBlank, isDesktop, isIOS, isPresent } from '../../utils/BooleanUtility';
import { ids, styles } from './styles';
import {
  checkHandleAvailability,
  getInfluencerDetails,
  getInfluencerTags,
  setArtistHandle,
  updateInfluencerData,
} from '../../actions/InfluencerActions';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import Config from '../../libraries/ReactNativeConfig';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import SaveProfileButton from './SaveProfileButton';
import { COUNTRY_CODE, REMOTE_CONFIG_KEYS } from '../../config/Constants';
import NavigationService from '../../navigator/NavigationService';
import Utility from '../../utils/Utility';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { StaticNavigationHeader } from '../shared';
import HandleSelectionShimmer from './HandleSelectionShimmer';
import Header from './Header';
import { setInfluencerProfileStatus } from '../../actions/ActionTypes';
import RemoteConfig from '../../utils/RemoteConfig';
import { jsonParser } from '../../utils/JsonUtility';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';
import DropDownPicker from 'react-native-dropdown-picker';
import { getScreenWidth } from '../../utils/LayoutUtility';

const PlatformAwareKeyboardAvoidingView = withEither(
  isIOS,
  KeyboardAvoidingView,
)(View);

export default function HandleSelection() {
  const {
    id: currentUserId = '',
    profile = {},
    mobileNumber = '',
    authToken,
  } = useSelector((state) => state.UserAccountInfo);

  const namePrefixData = [
    'Mr.',
    'Ms.',
    'Mrs.',
    'Dr.',
  ];

  const { id: profileId = '', handle: instagramHandle = '' } = profile;
  const [influencerTagIds, setInfluencerTagIds] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [influencerTags, setInfluencerTags] = useState([]);
  const [image, setImage] = useState('');
  const [imageBase64String, setImageBase64String] = useState('');
  const [handleErrorMessage, setHandleErrorMessage] = useState('');
  const [bio, setBio] = useState('');
  const [handle, setHandle] = useState(instagramHandle);
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [bioError, setBioError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [prefixOpen, setPrefixOpen] = useState(false);
  const [namePrefix, setNamePrefix] = useState(namePrefixData[0]);

  const [isCtaActionLoading, setIsCtaActionLoading] = useState(false);
  const dispatch = useDispatch();
  const scrollViewRef = useRef(null);
  const nameInputRef = useRef(null); // Ref for the Full Name input field
  const bioInputRef = useRef(null);
  const phoneNumberInputRef = useRef(null);
  const handleInputRef = useRef(null);
  const navigation = useNavigation();
  const { params = {} } = useRoute();
  const { source = '' } = params;
  const fromDashboard = source === 'dashboard';
  const [influencerData, setInfluencerData] = useState({});
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['handleSelection']?.title;
  const headerSubtitle = headerData['handleSelection']?.subtitle;


  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.HANDLE_SELECTION,
    });
  }, []);

  useEffect(() => {
    fetchInfluencerTags();
    if (isPresent(instagramHandle)) {
      handleInputRef?.current?.focus();
      setHandle(instagramHandle);
      fetchInfluencerDetails();
    } else {
      setNamePrefix('');
      setBio('');
      setName('');
      setHandle('');
      setImage('');
      setInfluencerTagIds([]);
      setIsLoading(false);
    }
  }, [instagramHandle, authToken]);

  useEffect(() => {
    if (isBlank(handle)) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!authToken) {
      setIsLoading(true);
      setTimeout(() => {
        dispatch(
          setPhoneNumberModalInfo(Utility.getLoginModalInfo('DERMAT_ONBOARD')),
        );
        NavigationService.renderOnboarding({
          goBackOnBackgroundTap: false,
          hideCrossButton: true,
        });
      }, 400);
    }
  }, [authToken]);

  useEffect(() => {
    setTimeout(() => {
      if (isPresent(mobileNumber)) {
        setPhoneNumber(mobileNumber.replace(COUNTRY_CODE, ''));
        phoneNumberInputRef?.current?.focus();
      }
    }, 500);
  }, [mobileNumber]);

  useEffect(() => {
    if (isPresent(name)) {
      nameInputRef?.current?.focus();
    }
  }, [name]);

  useEffect(() => {
    if (isPresent(bioInputRef)) {
      bioInputRef?.current?.focus();
    }
  }, [bio]);

  const fetchInfluencerDetails = () => {
    if (isPresent(handle || instagramHandle)) {
      dispatch(
        getInfluencerDetails(handle || instagramHandle, (success, response) => {
          const { statusCode = '' } = response;
          if (success) {
            const {
              bio: responseBio = '',
              name: responseName = '',
              influencer_tags: responseInfluencerTags,
              image_url = '',
              phone_number: phone,
              title = '',
            } = response;
            setIsLoading(false);
            setNamePrefix(title);
            setBio(responseBio);
            setName(responseName);
            const filteredInfluencerTagsIds = responseInfluencerTags?.reduce(
              (a, currentValue) => [...a, currentValue.id],
              [],
            );
            setInfluencerTagIds(filteredInfluencerTagsIds);
            setInfluencerData(response);
            setImage(image_url);
            setTimeout(() => {
              if (isPresent(phone)) setPhoneNumber(phone);
            }, 500);
          } else if (statusCode == 404) {
            setIsLoading(false);
          }
        }),
      );
    }
  };

  const fetchInfluencerTags = () => {
    dispatch(
      getInfluencerTags((success, response) => {
        if (success) {
          setInfluencerTags(response);
        }
      }),
    );
  };

  const handleChipPress = (value) => {
    if (influencerTagIds.includes(value)) {
      setInfluencerTagIds(influencerTagIds.filter((id) => id !== value));
    } else if (influencerTagIds.length < 5) {
      setInfluencerTagIds([...influencerTagIds, value]);
    }
  };

  const PrefixIcons = ({ image_url, isFocused }) => {
    return (
      <GrayScaledImage
        source={{ uri: image_url }}
        style={styles.grayScaledImage}
        enableGrayscale={!isFocused}
      />
    );
  };

  const onChangeHandle = (value = '') => {
    setHandle(value);
    if (isBlank(value)) {
      setHandleErrorMessage('');
      return;
    }
    dispatch(
      checkHandleAvailability(value, (success, response = {}) => {
        let errorMessage = '';
        if (!success) {
          const { error_message = '' } = response;
          errorMessage = error_message;
        }
        setHandleErrorMessage(errorMessage);
      }),
    );
  };

  const scrollToIndex = (value) => {
    if (scrollViewRef && scrollViewRef.current) {
      scrollViewRef.current.scrollTo(value);
    }
  };

  const onContinuePress = () => {
    setIsCtaActionLoading(true);
    if (isBlank(handle) || isPresent(handleErrorMessage)) {
      setHandleErrorMessage(handleErrorMessage || 'Required.');
      scrollToIndex(170);
      setIsCtaActionLoading(false);
      return;
    }
    if (name === '') {
      setNameError('Name is required');
      setIsCtaActionLoading(false);
      return;
    }
    if (bio === '') {
      setBioError('required');
      scrollViewRef.current?.scrollToEnd({ animated: true });
      setIsCtaActionLoading(false);
      return;
    }
    if (bio?.trim().length < 20) {
      setBioError('Minimum 20 characters required.');
      scrollViewRef.current?.scrollToEnd({ animated: true });
      setIsCtaActionLoading(false);
      return;
    }
    updateInfluencer();
  };

  const updateInfluencer = () => {
    const postData = {
      user: {
        profile_attributes: {
          bio,
          handle,
          id: profileId,
          influencer_tag_ids: influencerTagIds,
          image: imageBase64String || '',
          name,
          title: namePrefix,
        },
      },
    };
    dispatch(setArtistHandle(handle));
    dispatch(
      updateInfluencerData(postData, (success, response) => {
        if (success) {
          setIsCtaActionLoading(false);
          dispatch(setInfluencerProfileStatus(true));
          if (fromDashboard) {
            navigation.navigate('AboutYou');
          } else {
            navigation.navigate('SaveProgress', {
              handle,
              ...response,
            });
          }
        }
      }),
    );
  };

  const onChangeBio = (value = '') => {
    setBio(value);
    setBioError('');
  };

  const goBack = () => {
    navigation.goBack();
  };

  const handlePress = () => {
    navigation.push('TabNavigator');
  };

  const handlePostInsertImage = () => {
    AnalyticsManager.logEvent(EventType.INFLUENCER.PROFILE_PIC_UPLOAD, {
      current_user_id: currentUserId,
    });
  };

  const setProfileImage = (imageUrl, base64String) => {
    setImage(imageUrl);
    setImageBase64String(base64String);
  };

  if (isLoading) {
    return (
      <View>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle}/>}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <HandleSelectionShimmer />
      </View>
    );
  }

  const NamePrefix = () => {
    const namePrefixItems = namePrefixData.map((value) => ({ label: value, value }));
    return (
      <View>
        <DropDownPicker
          open={prefixOpen}
          value={namePrefix || namePrefixData[0]}
          items={namePrefixItems}
          setOpen={setPrefixOpen}
          setValue={setNamePrefix}
          placeholder=''
          style={[handleStyles.prefixPicker]}
          dropDownContainerStyle={[handleStyles.dropdownContainer]}
          arrowIconStyle={handleStyles.arrowIcon}
          textStyle={handleStyles.text}
          listItemContainerStyle={handleStyles.listItemContainer}
        />
      </View>
    );
  }; 

  const handleTextField = (text) => {
    if (isPresent(nameError)) {
      setNameError('');
    }
    setName(text);
  };

  const handleTextFieldLabelOffset = { x1: -126, y1: -20, y0: -8 };
  const handleTextFieldContentInset = { input: 8, left: 0 };
  const nameTextFieldLabelOffset = { x1: -132, y1: -30, y0: -8 };
  const nameTextFieldContentInset = { input: 8 };
  const labelOffset = { x1: -17, y1: -20 };
  return (
    <PlatformAwareKeyboardAvoidingView style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader onBackPress={goBack} title={headerTitle} subtitle={headerSubtitle} />}
        <ScrollView style={styles.scrollView} ref={scrollViewRef}>
          <View dataSet={{ media: ids.formContainer }}>
            <ImageUploader image={image} setImage={setProfileImage} postInsertImage={handlePostInsertImage} />
            <View style={styles.paddingContainer}>
              <OutlinedTextField
                renderLeftAccessory={AppHandlePrefix}
                label='Claim your FoxyRx profile URL*'
                tintColor={colors.subtitle}
                error={handleErrorMessage}
                onChangeText={onChangeHandle}
                inputContainerStyle={styles.textInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={handleTextFieldLabelOffset}
                contentInset={handleTextFieldContentInset}
                value={handle}
                ref={handleInputRef}
              />
            </View>
            {isPresent(handle) && (
              <View style={styles.urlContainer}>
                <Text style={styles.urlHeading}>Your URL will be:</Text>
                <Text
                  style={styles.url}
                  numberOfLines={1}
                  ellipsizeMode='tail'
                >{`  ${Config.URI_HOST}/@${handle}`}</Text>
              </View>
            )}
            <View style={[styles.paddingContainer, { zIndex: 1000 }]}>
              <OutlinedTextField
                label='Full Name*'
                tintColor={colors.subtitle}
                inputContainerStyle={styles.textInputContainerStyle}
                renderLeftAccessory={NamePrefix}
                lineWidth={0}
                activeLineWidth={0}
                labelOffset={nameTextFieldLabelOffset}
                contentInset={nameTextFieldContentInset}
                value={name}
                onChangeText={handleTextField}
                error={nameError}
                ref={nameInputRef} // Assigning ref to Full Name input field
              />
            </View>
            <View style={styles.paddingContainer}>
              <Text style={styles.label}>Select 5 top things your patients come to you for*</Text>
              <View style={styles.chipContainer}>
                {influencerTags.map((chipObject) => (
                  <InfluencerTag
                    key={chipObject.id}
                    {...chipObject}
                    label={chipObject.name}
                    image={chipObject.image_url}
                    handleChipPress={handleChipPress}
                    influencerTagIds={influencerTagIds}
                    value={chipObject.id}
                  />
                ))}
              </View>
            </View>
            <View style={handleStyles.descriptionTextField}>
              <OutlinedTextField
                label='Briefly describe your background & specialty*'
                height={150}
                tintColor={colors.subtitle}
                inputContainerStyle={styles.multilineTextInputContainerStyle}
                lineWidth={0}
                activeLineWidth={0}
                onChangeText={onChangeBio}
                labelOffset={labelOffset}
                multiline
                numberOfLines={15}
                maxLength={600}
                style={styles.textInputStyle}
                value={bio}
                error={bioError}
                ref={bioInputRef}
              />
            </View>
            <View style={styles.verticalSpace} />
          </View>
        </ScrollView>
        <SaveProfileButton
          onPress={onContinuePress}
          loading={isCtaActionLoading}
          screenName={SCREEN_CONSTANTS.HANDLE_SELECTION}
        />
      </View>
    </PlatformAwareKeyboardAvoidingView>
  );
}

const AppHandlePrefix = () => <Text style={styles.appHandlePrefix}>{Config.URI_HOST}/@</Text>;

const handleStyles = StyleSheet.create({
  prefixPicker: {
    backgroundColor: colors.white,
    borderWidth: 1,
    borderColor: '#00000024',
    width: 90,
    height: 50,
    top: 14,
    left: -12,
    paddingVertical: 14,
    paddingHorizontal: 10,
    borderRadius: 8,
  },
  dropdownContainer: {
    borderColor: '#d3d3d3',
    borderRadius: 5,
    width: getScreenWidth() / 2,
    backgroundColor: colors.white,
  },
  arrowIcon: {
    tintColor: colors.foxyBlack,
    // height: 20,
  },
  listItemContainer: {
    backgroundColor: colors.white,
    zIndex: 998, // Ensures that the list items are above other components
  },
  text: {
    color: colors.foxyBlack,
    width: 30,
    alignSelf: 'center',
    fontSize: 16,
    fontFamily: 'Roboto-Medium',
  },
  descriptionTextField: {
    padding: 16, // Adjust padding to ensure content is not too close to the edges
    height: 150, // Define the height explicitly if needed
    overflow: 'hidden', // Prevent content from overflowing outside the container
  },
  multilineTextInputContainerStyle: {
    borderWidth: 1,
    borderColor: colors.subtitle,
    borderRadius: 8,
    padding: 8,
    height: '100%', // Ensure it takes the full height of the container
    overflow: 'hidden', // Ensure no overflow
  },
  textInputStyle: {
    flex: 1, // Make the text field take up available space
    paddingVertical: 8, // Adjust vertical padding for text
    paddingHorizontal: 8, // Adjust horizontal padding for text
    outlineStyle: 'none', // Remove outline (for web compatibility)
    textAlignVertical: 'top', // Start text from the top when multiline
  },
});
