import React from 'react';
import { View, Text } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useNavigation } from '@react-navigation/native';
import _ from 'lodash';
import { compose } from 'recompose';
import Card from '../../lib/Card';
import { withEither } from '../../lib/Monads';
import ProductImageAndRating from './ProductImageAndRating';

import ProductDescription from './ProductDescription';
import { PRODUCT } from '../../config/LayoutConstants/ProductConfig';
import Utility from '../../utils/Utility';
import AddToCart from './AddToCart';
import ScaleAnimate from '../shared/ScaleAnimate';
import { LAYOUT } from '../../config/Constants';
import colors from '../../theme/Colors';
import ProductColorVariants from '../variants/ProductColorVariants';
import ProductOtherVariants from '../variants/ProductOtherVariants';
import ProductSizeVariants from '../variants/ProductSizeVariants';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { getCategorySlugParams } from '../../utils/StringUtility';

export default function ShopOnlineProductCard(props) {
  const navigation = useNavigation();

  const navigate = () => {
    const {
      itemData = {},
      itemData: {
        id = '',
        slug = '',
        stocked_status = '',
        replacement_sku = '',
      } = {},
      previousScreen,
    } = props;

    navigateToScreen({
      navigation,
      type: 'push',
      screen: 'Product',
      params: {
        id,
        slug:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku)
            ? `/api/v1/products/${replacement_sku}`
            : slug,
        isReplacement:
          stocked_status === 'discontinued' && Utility.isPresent(replacement_sku),
        original_slug: slug,
        name: itemData.name,
        display: LAYOUT.SCREEN,
        itemData,
        previousScreen,
      },
    });
  };

  const debouncedNavigate = _.debounce(navigate, 1000, {
    leading: true,
    trailing: false,
  });

  const {
    orientation,
    previousScreen,
    itemData,
    hideAddToCart,
    itemData: {
      offer_value,
      final_sp,
      brand_name: variantBrandName,
      images,
      webp_images,
      image_webp_url,
      rating,
      id,
      name,
      mrp,
      sp,
      discount,
      brand: { name: brandName },
      sku_id: skuId,
      prompts,
      has_variants: hasVariants,
      outOfStock,
      priority,
      image_url: imageUrl,
      single_stocked_variant: singleStockedVariantSku,
      hero_description: heroDescription,
      hero_ingredients: heroIngredients = [],
      product_category: { slug: productCategorySlug } = {},
    },
  } = props;

  const disabled =
    previousScreen === 'brandCollab' || previousScreen === 'free_items';

  const display_price = final_sp;

  const pills = [...heroIngredients, ...getCategorySlugParams(`/${productCategorySlug}`)];
  return (
    <ScaleAnimate disabled={disabled} onPress={debouncedNavigate}>
      <Card style={styles.card}>
        <View style={styles.cardContainer} dataSet={{ media: ids.cardContainer }}>
          <View style={styles.productContainer}>
            <View style={styles.imageContainer}>
              <ProductImageAndRating
                layout={LAYOUT.PERSONALISEDRAIL}
                rating={rating}
                images={images}
                webp_images={webp_images}
                image_webp_url={image_webp_url}
                imageUrl={imageUrl}
                id={id}
                name={name}
                previousScreen={previousScreen}
                itemData={itemData}
              />
            </View>
            <View style={styles.productDescriptionContainer}>
              <ProductDescription
                itemData={itemData}
                name={name}
                mrp={mrp}
                offer_value={offer_value}
                sp={sp}
                display_price={display_price}
                discount={discount}
                brandName={brandName}
                variantBrandName={variantBrandName}
                layout={LAYOUT.PERSONALISEDRAIL}
                id={id}
                previousScreen={previousScreen}
                prompts={prompts}
                renderProp={() => (
                  <ProductVariants
                    containerStyle={styles.variantContainerStyle}
                    itemData={itemData}
                  />
                )}
              >
              <View />
              </ProductDescription>
            </View>
            <View style={styles.cartImageContainer}>
              <AddToCartButton
                skuId={skuId}
                orientation={orientation}
                layout={LAYOUT.PERSONALISEDRAIL}
                addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
                hideAddToCart={hideAddToCart}
                id={id}
                itemData={itemData}
                hasVariants={hasVariants}
                priority={priority}
                outOfStock={outOfStock}
                previousScreen={previousScreen}
                singleStockedVariantSku={singleStockedVariantSku}
                prompts={prompts}
                {...props}
              />
            </View>
          </View>
          <Text style={styles.bottomContainer}>
            <Text style={styles.heroDescription}>{heroDescription}</Text>
            {pills.map(pill => <Text style={styles.heroDescriptionPill}> #{pill}</Text>)}
          </Text>
        </View>
      </Card>
    </ScaleAnimate>
  );
}


const AddToCartButton = (props) => {
  const {
    skuId,
    orientation,
    layout,
    hideAddToCart,
    id,
    itemData,
    hasVariants,
    priority,
    singleStockedVariantSku,
    campaignId,
    onItemAddToCartFromCollab,
    prompts,
  } = props;
  const {
    previousScreen,
    maxFreeItemsToSelect,
    showToast,
    refreshOfferStrip,
    refreshOffersDetailsPageDiscountStrip,
    listId,
    listName,
    listIndex,
    listContent,
    index,
    listData,
    listData: { additional_data = {} } = {},
  } = props;
  let { outOfStock = false } = props;
  if (outOfStock === null) {
    outOfStock = true;
  }
  return (
    <AddToCart
      skuId={skuId}
      orientation={orientation}
      layout={LAYOUT.GRID}
      addToCartLayout={PRODUCT.ADD_TO_CART_LAYOUT.PRODUCT_CARD}
      hideAddToCart={hideAddToCart}
      id={id}
      itemData={itemData}
      hasVariants={hasVariants}
      priority={priority}
      outOfStock={outOfStock}
      refreshOfferStrip={refreshOfferStrip}
      refreshOffersDetailsPageDiscountStrip={
        refreshOffersDetailsPageDiscountStrip
      }
      previousScreen={previousScreen}
      maxFreeItemsToSelect={maxFreeItemsToSelect}
      showToast={showToast}
      campaignId={campaignId}
      onItemAddToCartFromCollab={onItemAddToCartFromCollab}
      listId={listId}
      listName={listName}
      listIndex={listIndex}
      listContent={listContent}
      index={index}
      singleStockedVariantSku={singleStockedVariantSku}
      prompts={prompts}
      additional_data={additional_data}
      listData={listData}
    />
  );
};

const productShadesCondition = ({ variantsPropertyName }) =>
variantsPropertyName === 'Shade';

const productSizeCondition = ({ variantsPropertyName }) =>
variantsPropertyName === 'Size';

const ConditionalVariants = compose(
  withEither(productShadesCondition, ProductColorVariants),
  withEither(productSizeCondition, ProductSizeVariants),
)(ProductOtherVariants);

const ProductVariants = (props) => {
  const {
    itemData: { image_url: imageUrl, variants_details: variantDetails = {} },
  } = props;
  const { containerStyle } = props;
  const {
    variants_count: variantsCount = 0,
    variants_shade_images: variantShadeImages = [],
    variants_property_name: variantPropertyName = '',
  } = variantDetails;
  return (
    <ConditionalVariants
      variantImage={imageUrl}
      variantsCount={variantsCount}
      variantShadesImages={variantShadeImages}
      variantsPropertyName={variantPropertyName}
      containerStyle={containerStyle}
    />
  );
};

const { ids, styles } = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.white,
    borderColor: colors.borderColor,
    marginHorizontal: Utility.spacingBetweenItems,
    borderRadius: 4,
    width: Utility.getScreenWidth() - 20,
    marginBottom: 2,
    alignSelf: 'center',
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2 - 24,
      maxWidth: 480,
    },
  },
  productContainer: {
    flexDirection: 'row',
    height: 120,
  },
  imageContainer: {
    alignSelf: 'flex-start',
    height: 90,
    width: 90,
  },
  cartImageContainer: {
    position: 'absolute',
    bottom: 10,
    right: 12,
  },
  variantContainerStyle: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: 4,
  },
  heroDescription: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: colors.black,
  },
  heroDescriptionPill: {
    fontSize: 12,
    fontFamily: 'Roboto-Regular',
    color: '#4285F4',
  },
  bottomContainer: {
    paddingHorizontal: 14,
    paddingBottom: 10,
  },
  productDescriptionContainer: {
    flex: 1,
    marginRight: 4,
  },
  card: {
    flexDirection: 'column',
  },
});
