import { v7 as uuidv7 } from 'uuid';
import { getCookie, setCookie } from './webUtility';

let guestToken = '';

export const setGuestToken = (token) => {
  guestToken = token;
};

export const getGuestToken = () => guestToken;

export const getWebDeviceId = () => {
  const webDeviceId = getCookie('webDeviceId');
  if (webDeviceId) return webDeviceId;
  setCookie('webDeviceId', uuidv7(), 365 * 100);
  return getCookie('webDeviceId');
};
