import React from 'react';
import { useSelector } from 'react-redux';
import AccountOptionItem from './AccountOptionItem';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import { isPresent } from '../../utils/BooleanUtility';

const DermatOption = (props) => {
  const { option: { title = '', destination = '', type = '' } = {}, navigation, toggleAccountModal } = props;
  const handle = useSelector((state) => state.UserAccountInfo.profile.handle) || '';

  const handleScreenNavigation = ({ route, slug }) => {
    navigation.navigate(route, { slug });
  };

  const onTapPreviewPage = () => {
    navigation.navigate('Influencer', { bioHandle: handle });
  };

  const buttonActions = {
    preview_profile: onTapPreviewPage,
  };

  const onPressButton = () => {
    toggleAccountModal();
    if (isPresent(destination)) {
      DynamicLinkManager.handleLinkWithoutInternalTrackingParams(destination, handleScreenNavigation);
      return;
    }
    if (typeof buttonActions[type] == 'function') {
      buttonActions[type]();
    }
  };

  return <AccountOptionItem label={title} handlePress={onPressButton} />;
};

export default DermatOption;
