import React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { getScreenWidth } from '../../utils/LayoutUtility';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import images from '../../theme/Images';
import InfluencerRating from '../../containers/artist/InfluencerRating';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import DebouncedTouchableOpacity from '../shared/DebouncedTouchableOpacity';
import { useSelector } from 'react-redux';
import AnalyticsUtility from '../../analytics/AnalyticsUtility';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';

const DefaultSizeDermatProfileList = ({
  itemData: {
    itemData = {},
    attributes: {
      full_name: name = '',
      image_url: imageUrl = '',
      online_consultation_link = '',
      ratings_count = '',
      consultation_charge = '',
      locality = 'Vasant kunj, Delhi',
      rating = '',
      handle = '',
    } = {},
  } = {},
  listData = {},
  navigation,
  index,
  listId = '',
  layout = '',
  listName = '',
  listIndex = '',
  previousScreen = '',
  listContent = '',
}) => {
  const image = isPresent(imageUrl) ? imageUrl : images.place_holder;
  const onPressButton = () => {
    if (isBlank(online_consultation_link)) return;
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
      [EventParameterKey.TYPE]: itemData.type,
      [EventParameterKey.CTA]: 'Book Now',
      [EventParameterKey.SCREEN_NAME]: previousScreen,
    });
    Linking.openURL(online_consultation_link).catch((err) => console.error('Error opening Google Maps', err));
  };

  const onPressCard = () => {
    AnalyticsUtility.fireItemClickEvent(
      previousScreen,
      itemData.id,
      itemData.type,
      itemData.name,
      index,
      listId,
      layout,
      listName,
      listIndex,
      '',
      false,
      listContent,
      itemData.id,
      itemData?.slug,
      listData?.slug,
    );
    navigation.navigate('Influencer', { bioHandle: handle });
  };

  return (
    <DebouncedTouchableOpacity style={styles.card} onPress={onPressCard}>
      <FastImageView
        source={getMinifiedImage(image, styles.artistImageStyle.width)}
        style={styles.artistImageStyle}
        resizeMode='cover'
      />
      <View style={styles.rightContainer}>
        <Text style={styles.title} numberOfLines={2} ellipsizeMode='tail'>
          {name}
        </Text>
        <InfluencerRating
          rating={rating}
          ratingsCount={ratings_count}
          ratingSize={12}
          ratingTextStyle={styles.ratingTextStyle}
          containerStyle={styles.ratingContainerStyle}
        />
        {isPresent(locality) && (
          <View style={styles.localityContainer}>
            <FastImageView source={images.location_black_icon} style={styles.locationIcon} />
            <Text style={styles.location}>{locality}</Text>
          </View>
        )}
      </View>
      <View style={styles.rightBottomContainer}>
        <Text style={styles.charges}>{`₹${consultation_charge}/per session`}</Text>
        <TouchableOpacity style={styles.button} onPress={onPressButton}>
          <Text style={styles.buttonText}>Book Now</Text>
        </TouchableOpacity>
      </View>
    </DebouncedTouchableOpacity>
  );
};
const styles = StyleSheet.create({
  card: {
    backgroundColor: '#FFFFFF',
    width: getScreenWidth() - 24,
    height: 110,
    borderRadius: 8,
    margin: 10,
    paddingHorizontal: 16,
    alignContent: 'space-between',
    flexDirection: 'row',
  },
  title: {
    fontSize: 18,
    width: getScreenWidth() / 2.7,
    fontWeight: 'bold',
    color: colors.black,
  },
  location: {
    fontSize: 13,
    color: colors.subtitle,
  },
  artistImageStyle: {
    borderRadius: 35,
    width: 71,
    height: 71,
    alignSelf: 'center',
  },
  button: {
    width: getScreenWidth() / 4,
    height: 22,
    borderRadius: 11,
    backgroundColor: colors.black,
  },
  buttonText: {
    fontSize: 12,
    color: colors.white,
    textAlign: 'center',
    lineHeight: 22,
  },
  ratingTextStyle: {
    fontSize: 10,
    color: colors.darkGrey,
    fontFamily: 'Roboto-Regular',
    textDecorationLine: 'underline',
    marginLeft: 2,
  },
  charges: {
    fontSize: 12,
    color: colors.subtitle,
    marginTop: 4,
    marginBottom: 10,
  },
  rightContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 10,
    marginVertical: 4,
  },
  rightBottomContainer: {
    justifyContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: 10,
  },
  ratingContainerStyle: {
    backgroundColor: colors.white,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingVertical: 4,
  },
  localityContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  locality: {},
  locationIcon: {
    height: 12,
    width: 12,
    marginRight: 4,
  },
});

export default DefaultSizeDermatProfileList;
