import StyleSheet from 'react-native-media-query';
import colors from '../../theme/Colors';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isAndroid, isWeb } from '../../utils/BooleanUtility';

export const { ids, styles } = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    height: '100%',
    '@media (min-width: 768px)': {
      width: 640,
      alignSelf: 'center',
      alignItems: 'center',
      border: '1px solid #979baa',
      marginBottom: 20,
      height: 'calc(100vh - 74px)',
      borderRadius: 16,
      paddingHorizontal: 10,
      flex: 1,
    },
  },
  header: {
    backgroundColor: colors.white,
    zIndex: 10,
    padding: 12,
    borderBottom: '0.5px solid #dfe0e1',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  back: {
    height: 24,
    width: 24,
    marginRight: 10,
  },
  headerTitle: {
    fontWeight: '700',
    fontSize: 18,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Medium',
    lineHeight: 21,
    '@media (min-width: 768px)': {
      fontSize: 24,
      lineHeight: 28,
    },
  },
  headerSubtitle: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
    '@media (min-width: 768px)': {
      fontSize: 20,
      lineHeight: 23,
    },
  },
  buttonContainer: {
    paddingBottom: 32,
    paddingTop: 12,
    borderTop: '0.5px solid #dfe0e1',
    paddingHorizontal: 12,
    zIndex: 5,
    '@media (min-width: 768px)': {
      width: 480,
    },
  },
  button: {
    backgroundColor: colors.black,
    borderRadius: 4,
    alignItems: 'center',
  },
  buttonText: {
    color: colors.white,
    fontSize: 18,
    marginVertical: 12,
  },
  chipContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 8,
  },
  label: {
    color: colors.silver,
    fontSize: 12,
    marginBottom: 4,
    fontFamily: 'Roboto-Regular',
    marginTop: isWeb() ? 2 : 0,
  },
  picker: {
    borderRadius: 4,
    borderWidth: 0,
    backgroundColor: colors.background,
    paddingVertical: 8,
    paddingHorizontal: 4,
    fontSize: 16,
    paddingRight: 10,
    color: colors.foxyBlack,
  },

  appHandlePrefix: {
    position: 'relative',
    top: 8,
    marginRight: 4,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: '#7f8d95',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    backgroundColor: colors.white,
    paddingHorizontal: 10,
    height: 40,
    paddingTop: 10,
    borderWidth: 1,
    borderColor: '#00000024',
    '@media (min-width: 768px)': {
      border: '1px solid #00000024',
    },
  },
  textInputContainerStyle: {
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
    height: 40,
  },
  multilineTextInputContainerStyle: {
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
  },
  verticalSpace: { height: 150 },
  formContainer: {
    paddingTop: 24,
    paddingHorizontal: 24,
    '@media (min-width: 768px)': {
      paddingTop: 0,
      width: 480,
      paddingHorizontal: 12,
      alignSelf: 'center',
    },
  },
  scrollView: {
    width: '100%',
  },
  logo: {
    width: 75.5,
    height: 36,
  },
  logoContainer: {
    paddingHorizontal: 30,
    paddingVertical: 10,
  },
  headingTextContainer: {
    '@media (min-width: 768px)': {
      width: '90%',
      alignItems: 'center',
    },
  },
  otherPlatformSuffix: {
    position: 'relative',
    top: 8,
    marginRight: -12,
    border: '1px solid #00000024',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    color: 'cornflowerblue',
    fontFamily: 'Roboto-Regular',
    fontSize: 16,
    backgroundColor: '#ebf7ff',
    paddingHorizontal: 10,
    height: 40,
    paddingTop: 10,
  },
  closeButton: {
    height: 16,
    width: 16,
    marginBottom: 4,
    resizeMode: 'contain',
    tintColor: colors.black,
  },
  contentHeading: {
    fontSize: 16,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Bold',
    lineHeight: 21,
  },
  contentSubheading: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
    marginTop: 4,
  },
  addLinkContainer: {
    height: 256,
    width: 200,
    borderRadius: 8,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.white,
    marginTop: 12,
    marginBottom: 36,
    marginRight: 12,
  },
  addLinkContainerFooter: {
    height: 70,
    borderTopWidth: 1,
    borderColor: colors.border,
    width: '100%',
    padding: 12,
    justifyContent: 'space-between',
  },
  addLinkContainerImageContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  addLinkContainerHeading: {
    fontSize: 14,
    color: colors.foxyBlack,
    fontFamily: 'Roboto-Regular',
  },
  addLinkContainerSubheading: {
    fontSize: 14,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
  },
  plusButton: {
    height: 90,
    width: 90,
    resizeMode: 'contain',
    alignSelf: 'center',
  },
  linkChainImage: {
    height: 14,
    width: 14,
    resizeMode: 'contain',
    tintColor: colors.subtitle,
  },
  touchableContainer: {
    width: '100%',
    height: '100%',
  },
  modalContainer: {
    height: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: colors.translucent,
  },
  modal: {
    width: 440,
    bottom: 0,
    position: 'absolute',
    alignSelf: 'center',
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    paddingVertical: 24,
    paddingHorizontal: 12,
    backgroundColor: 'white',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  addIntroVideoHeading: {
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
    alignSelf: 'center',
    lineHeight: 20,
    marginTop: 12,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 22,
      lineHeight: 23,
    },
  },
  addIntroVideoSubheading: {
    fontSize: 16,
    fontFamily: 'Roboto-Regular',
    lineHeight: 20,
    marginTop: 12,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 16,
      lineHeight: 23,
    },
  },
  videoContainer: {
    height: 250,
    width: 140,
    borderRadius: 16,
    resizeMode: 'cover',
    marginRight: 16,
  },
  addIntroVideoFlatlist: {
    marginHorizontal: -24,
    marginVertical: 24,
    marginTop: 10,
  },
  addIntroVideoFlatlistContent: {
    paddingHorizontal: 24,
  },
  addIntroPlayIconContainer: {
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
    width: 140,
    zIndex: 1,
    alignSelf: 'center',
  },
  addIntroPlayIcon: {
    height: 20,
    width: 20,
    alignSelf: 'center',
  },
  skipText: {
    fontFamily: 'Roboto-Regular',
    color: colors.foxyBlue,
    fontSize: 18,
    marginTop: 4,
  },
  influencerPreviewContainer: {
    height: 360,
    width: 180,
    borderWidth: 6,
    borderColor: colors.black,
    borderRadius: 16,
    backgroundColor: colors.background,
    alignSelf: 'center',
  },
  influencerPreviewShareIcon: {
    height: 10,
    width: 10,
    resizeMode: 'contain',
    position: 'absolute',
    zIndex: 1,
    top: 10,
    right: 10,
  },
  influencerPreviewHeaderContainer: {
    backgroundColor: colors.white,
    borderBottomRightRadius: 40,
    height: 100,
    borderTopLeftRadius: 16,
    borderTopRightRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 12,
  },
  influencerPreviewFollowerReachContainer: {
    backgroundColor: '#fff0c2',
    padding: 2,
    paddingHorizontal: 4,
    flexDirection: 'row',
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  influencerPreviewFollowerReachImage: {
    height: 8,
    width: 8,
    resizeMode: 'contain',
  },
  influencerPreviewFollowerReachText: {
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    fontSize: 6,
    marginLeft: 2,
  },
  influencerPreviewBioText: {
    fontFamily: 'Roboto-Regular',
    color: colors.black,
    fontSize: 8,
    marginTop: 8,
  },
  influencerPreviewTileImage: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    borderRadius: 15,
    marginRight: 4,
  },
  influencerPreviewTileContainer: {
    flexDirection: 'row',
    width: getScreenWidth() - 24,
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 40,
    padding: 6,
    backgroundColor: colors.white,
    marginVertical: 4,
  },
  influencerPreviewTileText: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: 8,
    flex: 1,
    marginLeft: -8,
    textAlign: 'center',
    justifyContent: 'center',
  },
  influencerPreviewBodyContainer: {
    paddingTop: 6,
  },
  influencerPreviewFooter: {
    height: 30,
    width: 100,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginTop: 8,
  },
  copyImage: {
    height: 16,
    width: 16,
    resizeMode: 'contain',
  },
  registrationContainer: {
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: 48,
  },
  registrationCompletedHeading: {
    fontSize: 16,
    fontFamily: 'Roboto-Bold',
    lineHeight: 20,
    paddingHorizontal: 24,
    marginTop: 24,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 14,
    },
  },
  registrationCompletedSubheading: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    // lineHeight: 10,
    paddingHorizontal: 12,
    // marginTop: 8,
    lineHeight: 23,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 12,
    },
  },
  registrationCompletedSubheadingApp: {
    fontSize: 14,
    fontFamily: 'Roboto-Regular',
    paddingHorizontal: 12,
    // marginTop: 8,
    color: colors.black,
    '@media (min-width: 768px)': {
      fontSize: 12,
    },
  },
  registrationCompletionGif: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 1,
  },
  oneLinkVerifyingGif: {
    width: 150,
    height: 250,
    resizeMode: 'contain',
  },
  oneLinkVerificationFailedGif: {
    width: 100,
    height: 100,
    resizeMode: 'contain',
  },
  dashboardContainer: {
    width: '100%',
    paddingHorizontal: 12,
    '@media (min-width: 768px)': {
      width: 640,
      alignSelf: 'center',
      paddingHorizontal: 0,
    },
  },
  dashboardInfoCardContainer: {
    paddingHorizontal: 12,
    paddingVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  editButtonContainer: {
    position: 'absolute',
    top: 12,
    right: 12,
  },
  editButton: {
    height: 24,
    width: 24,
    resizeMode: 'contain',
  },
  dashboardSeparator: {
    height: 24,
  },
  editProfileButton: {
    backgroundColor: colors.black,
    borderRadius: 20,
    paddingVertical: 12,
    marginHorizontal: 24,
    marginTop: 24,
    flexDirection: 'row',
    justifyContent: 'center',
    width: '90%',
    alignItems: 'center',
    marginBottom: 12,
  },
  editButtonIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    tintColor: colors.white,
  },
  editButtonText: {
    fontSize: 14,
    color: colors.white,
    fontFamily: 'Roboto-Regular',
    marginLeft: 6,
  },
  socialMediaHandleTileImage: {
    height: 50,
    width: 50,
    resizeMode: 'contain',
    borderRadius: 25,
    marginRight: 4,
  },
  socialMediaHandleTileText: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: 16,
    flex: 1,
    marginLeft: -25,
    textAlign: 'center',
    justifyContent: 'center',
  },
  proInviteImage: {
    height: 130,
    width: 200,
    resizeMode: 'contain',
  },
  profileImage: {
    height: 126,
    width: 126,
    borderRadius: 63,
    resizeMode: 'cover',
  },
  oneLinkPageShareIcon: {
    height: 20,
    width: 20,
    resizeMode: 'contain',
    position: 'absolute',
    zIndex: 1,
    top: 20,
    right: 20,
  },
  oneLinkPageTileContainer: {
    flexDirection: 'row',
    width: '90%',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 40,
    padding: 8,
    paddingVertical: 12,
    backgroundColor: colors.white,
    marginVertical: 6,
  },
  oneLinkPageTileImage: {
    height: 50,
    width: 50,
    resizeMode: 'contain',
    borderRadius: 25,
    marginRight: 4,
  },
  oneLinkPageTileText: {
    fontFamily: 'Roboto-Bold',
    color: colors.black,
    fontSize: 16,
    flex: 1,
    marginLeft: -25,
    textAlign: 'center',
    justifyContent: 'center',
  },
  socialMediaTileContainer: {
    width: '100%',
    paddingTop: 48,
    backgroundColor: colors.background,
    borderTopLeftRadius: 120,
  },
  oneLinkContainer: {
    width: '100%',
    paddingHorizontal: 60,
    '@media (min-width: 768px)': {
      width: 540,
      alignSelf: 'center',
      paddingHorizontal: 0,
    },
  },
  oneLinkPageContent: {
    width: '100%',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  oneLinkFooter: {
    height: 100,
    width: 200,
    resizeMode: 'contain',
    alignSelf: 'center',
    marginTop: 8,
  },
  videoPlay: {
    position: 'absolute',
    bottom: -10,
    width: 20,
    height: 20,
    resizeMode: 'contain',
    alignSelf: 'center',
    zIndex: 1,
  },
  profileImageContainer: {
    height: 128,
    width: 128,
    zIndex: -1,
    borderRadius: 64,
    resizeMode: 'cover',
    position: 'absolute',
  },
  pop_up_close_container: {
    position: 'absolute',
    bottom: 24,
    zIndex: 5,
    alignSelf: 'center',
    borderRadius: 15,
  },
  pop_up_close: {
    height: 30,
    width: 30,
    resizeMode: 'contain',
  },
  checkboxContainer: {
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    backgroundColor: '#f3f6f8',
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 12,
  },
  checkboxLabel: {
    color: colors.foxyBlack,
    fontSize: 16,
    marginLeft: 8,
    fontFamily: 'Roboto-Regular',
  },
  addMoreClinics: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  plusIcon: {
    width: 20,
    height: 20,
    tintColor: colors.foxyBlue,
    alignSelf: 'center',
    marginBottom: 2,
  },
  fileUpload: {
    flexDirection: 'row',
    padding: 16,
    alignItems: 'center',
    backgroundColor: '#f3f6f8',
    borderRadius: 5,
    marginVertical: 4,
    paddingVertical: 12,
  },
  fileUploadLabel: {
    fontFamily: 'Roboto-Regular',
    marginLeft: 10,
    color: colors.subtitle,
  },
  warningText: {
    color: colors.foxyYellow,
    fontSize: 12,
    marginBottom: 4,
    fontFamily: 'Roboto-Regular',
  },
  prefix: {
    position: 'relative',
    bottom: isAndroid() ? 2.8 : 0,
  },
  columnWrapper: {
    justifyContent: 'space-between',
  },
  dropdownStyle: {
    height: 40,
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
  },
  textStyle: {
    color: colors.foxyBlue,
    fontSize: 14,
    marginBottom: 4,
    fontFamily: 'Roboto-Regular',
    alignSelf: 'center',
    padding: 4,
  },
  pickerItem: {
    backgroundColor: colors.white,
    color: colors.foxyBlack,
    alignSelf: 'center',
  },
  copyHandleContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 10,
    paddingHorizontal: 10,
    width: getScreenWidth() - 40,
  },
  copyTextContainer: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  copyText: {
    fontSize: 14,
    fontFamily: 'Roboto-Bold',
    color: colors.foxyBlue,
  },
  text: {
    fontSize: 10,
    fontFamily: 'Roboto-Normal',
    color: colors.foxyBlue,
    paddingHorizontal: 4,
  },
  urlHeading: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.subtitle,
    width: '30%',
  },
  urlContainer: {
    flexDirection: 'row',
    alignContent: 'center',
    paddingHorizontal: 16,
    paddingBottom: 12,
  },
  url: {
    fontSize: 14,
    fontFamily: 'Roboto-Medium',
    color: colors.black,
    width: '70%',
    alignSelf: 'center',
  },
  fullWidthContainer: { width: '100%' },
  mediaContainer: {
    paddingTop: 24,
    paddingHorizontal: 12,
  },
  paddingContainer: { padding: 16 },
  videoConsultation: { padding: 16, paddingTop: 0 },
  videoConsultationDropdown: { padding: 16, paddingTop: 0, zIndex: 1000 },
  inPersonContainer: { padding: 16, paddingTop: 5 },
  dropdownContainer: {
    zIndex: 1000,
    padding: 16,
    paddingTop: 5,
  },
  pickerColor: { backgroundColor: colors.background },
  linearGradientStyle: {
    paddingHorizontal: 12,
    paddingVertical: 12,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
  },
  socialProfileContainer: { paddingTop: 12 },
  socialProfileSubContainer: { paddingHorizontal: 16, paddingTop: 24, paddingBottom: 16 },
  paddingHorizontal: { paddingHorizontal: 16 },
  socialProfileTextField: { paddingHorizontal: 16, paddingBottom: 16 },
});
