import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Rating } from 'react-native-rating-element';

import { useNavigation } from '@react-navigation/native';
import colors from '../../theme/Colors';

import images from '../../theme/Images';
import { isBlank, isPresent } from '../../utils/BooleanUtility';

const InfluencerRating = ({
  onRatingTap = () => {},
  rating = 4,
  disableRatingText = false,
  ratingSize,
  marginBetweenRatingIcon,
  selectedIconImage = images.filled_star,
  containerStyle = {},
  ratingsCount = '',
  ratingTextStyle = {},
}) => {
  if (rating === 0 || isBlank(rating)) {
    return null;
  }
  const size = disableRatingText ? 10 : 20;

  const onIconTap = () => {
    onRatingTap();
  };

  let outerContainerStyle = disableRatingText ? styles.disableContainer : styles.container;

  outerContainerStyle = isPresent(containerStyle) ? containerStyle : outerContainerStyle;
  const ratingStyle = isPresent(ratingTextStyle) ? ratingTextStyle : styles.ratingText;

  return (
    <View style={outerContainerStyle}>
      <View style={styles.innerContainer}>
        <Rating
          rated={parseFloat(rating)}
          totalCount={5}
          size={isPresent(ratingSize) ? ratingSize : size}
          onIconTap={onIconTap}
          marginBetweenRatingIcon={isPresent(marginBetweenRatingIcon) ? marginBetweenRatingIcon : 3}
          direction='row'
          type='custom' // default is always to "icon"
          selectedIconImage={selectedIconImage}
          emptyIconImage={images.empty_star}
        />
        {isPresent(ratingsCount) && <Text style={ratingStyle}>{ratingsCount} ratings</Text>}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    paddingTop: 12,
    paddingBottom: 12,
    paddingHorizontal: 12,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  disableContainer: {
    backgroundColor: 'transparent',
    paddingTop: 2,
    paddingHorizontal: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  innerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ratingText: {
    fontSize: 12,
    color: colors.darkGrey,
    fontFamily: 'Roboto-Regular',
    marginLeft: 8,
    textDecorationLine: 'underline',
  },
});

export default React.memo(InfluencerRating);
