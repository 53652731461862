import React, { Component } from 'react';
import {
  View,
  Text,
  BackHandler,
  FlatList,
  ScrollView,
  RefreshControl,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Toast from 'react-native-easy-toast';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, find } from 'lodash';
import withNavigation from '../../utils/WithNavigation';
import { StaticNavigationHeader } from '../../components/shared';
import colors from '../../theme/Colors';
import Utility from '../../utils/Utility';
import { LOGIN_MODAL_MESSAGES } from '../../config/Constants';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import size from '../../theme/Fonts';
import FoxyEdgeHeader from './FoxyEdgeHeader';
import { getLoyaltyPlans } from '../../actions/FoxyEdgeAction';
import LoyaltyPlanMembershipCard from './LoyaltyPlansMembershipCard';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import _, { camelCase } from 'lodash';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import ShimmerPlaceHolder from '../../libraries/ReactNativeShimmerPlaceholder';
import ErrorBoundary from '../../components/shared/ErrorBoundary';
import {
  AnalyticsManager,
  EventParameterKey,
  EventParameterValue,
  EventType,
} from '../../analytics';
import NavigationService from '../../navigator/NavigationService';
import { getScreenWidth } from '../../utils/LayoutUtility';
import { isDesktop } from '../../utils/BooleanUtility';

const JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;

class LoyaltyPlans extends Component {
  constructor(props) {
    super(props);
    const { route = {}, navigation, membership_cohort } = props;
    const { params: { params: { smart = false } = {} } = {} } = route;
    if (smart === 'true' && membership_cohort === 'member') {
      navigation.navigate('TheEdge');
    }

    this.state = {
      loyaltyPlans: [],
      purchasableLoyaltyPans: [],
      freeLoyaltyPlans: [],
      selectedPlan: {},
      serverError: false,
      connectionError: false,
      loading: false,
      refreshing: false,
    };
    this.colorShimmerArray = ['#fafafa', '#eeeeee', '#fafafa'];
    this.darkColorShimmer = [colors.subtitle, '#DADADA', colors.subtitle];

    this.selectedPlanName = route.params?.selectedPlanName;
    this.upgrade = route.params?.upgrade || false;
    this.debouncedLoyaltyPlansFetch = debounce(this.getLoyaltyPlans, 500, {
      leading: true,
      trailing: false,
    });
  }

  componentDidMount() {
    const { navigation } = this.props;
    this.unsubscribe = navigation.addListener('focus', () => {
      this.onFocus();
    });
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.LOYALTY_PLANS);
    setTimeout(() => {
      const { authToken } = this.props;
      if (Utility.isBlank(authToken)) {
        this.invokeLoginFlow();
        Utility.furtherAction = {
          action: this.loginFlowCompleted,
        };
      }
    }, 10);
    this.debouncedLoyaltyPlansFetch();
    if (Utility.isAndroid()) {
      this.backhandler = BackHandler.addEventListener(
        'hardwareBackPress',
        this.goBack,
      );
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
    if (Utility.isAndroid()) {
      this.backhandler?.remove();
    }
  }

  loginFlowCompleted = () => {
    const { membership_cohort } = this.props;
    if (membership_cohort === 'member') {
      this.goBack();
    } else {
      this.debouncedLoyaltyPlansFetch();
    }
  };

  onRefresh = () => {
    this.setState({
      refreshing: true,
    });
    this.debouncedLoyaltyPlansFetch();
  };

  getLoyaltyPlans = () => {
    const {
      getLoyaltyPlans,
      authToken,
      membership_id,
      membership_cohort,
      route: {
        params: { updatedParams: { minimumEdgePriority = -1 } = {} } = {},
      } = {},
      route,
    } = this.props;
    const { refreshing = false } = this.state;
    if (Utility.isBlank(authToken)) {
      return;
    }
    this.setState({
      loading: true,
    });

    Utility.setPageLoadStart(SCREEN_CONSTANTS.LOYALTY_PLANS, 0, '', true, {
      refreshing,
      membership_id,
    });
    getLoyaltyPlans(
      (success, response) => {
        if (!success) {
          this.setState({
            serverError: !success && !response?.connectionError,
            connectionError: !success && response?.connectionError,
            refreshing: false,
            loading: false,
          });
          return;
        }

        new JSONAPIDeserializer({
          typeAsAttribute: false,
          pluralizeType: true,
          keyForAttribute: 'camelCase',
        })
          .deserialize(response)
          .then((data) => {
            const purchasableLoyaltyPans = data.filter(
              (plan) => plan.purchasable,
            );
            const freeLoyaltyPlans = data.filter((plan) => !plan.purchasable);

            const defaultSelectedPlan = find(data, (e) => e.purchasable);

            this.setState({
              loyaltyPlans: data,
              purchasableLoyaltyPans,
              freeLoyaltyPlans,
              serverError: false,
              refreshing: false,
              loading: false,
              selectedPlan: defaultSelectedPlan,
            });
            const { selectedPlan = {} } = this.state;
            if (Utility.isPresent(selectedPlan)) {
              const updatedSelectedPlan =
                data.filter(
                  (element) => element?.id === selectedPlan?.id,
                )?.[0] || {};
              this.setState({
                selectedPlan: updatedSelectedPlan,
              });
            } else if (Utility.isPresent(this.selectedPlanName)) {
              const updatedSelectedPlan =
                data.filter(
                  (element) => element?.name === this.selectedPlanName,
                )?.[0] || {};
              this.setState({
                selectedPlan: updatedSelectedPlan,
              });
            }
            const eligible = this.getElligiblePlansForUser(data);
            Utility.setPageLoadEnd(SCREEN_CONSTANTS.LOYALTY_PLANS, 0, {
              refreshing,
              membership_id,
              eligible,
            });
            AnalyticsManager.logEvent('membership_status', {
              membership_cohort: membership_cohort || 'error',
              membership_id: membership_id || 'error',
            });
          });
      },
      this.upgrade,
      minimumEdgePriority,
    );
  };

  getElligiblePlansForUser = (loyaltyPlans) => {
    let eligible = '';
    loyaltyPlans.map((item) => {
      if (`${item?.ctaText}`.toLowerCase() === 'activate') {
        eligible += `${item?.id},`;
      }
    });
    return eligible.replace(/(^,)|(,$)/g, '');
  };

  goBack = () => {
    const { navigation } = this.props;
    navigation.goBack();
    return true;
  };

  invokeLoginFlow = () => {
    const { setPhoneNumberModalInfo } = this.props;
    Utility.hideLoginSkipButtonForSalon = false;
    setPhoneNumberModalInfo(Utility.getLoginModalInfo('FOXY_EDGE'));
    NavigationService.renderOnboarding({
      onPressOutsideAction: this.goBack,
      parentSkipAction: this.goBack,
    });
  };

  keyExtractor = (item, index) => index.toString();

  purchasableLoyaltyPlansList = () => {
    const {
      loyaltyPlans = [],
      purchasableLoyaltyPans = [],
      loading,
    } = this.state;
    if (Utility.isBlank(loyaltyPlans) || loading) {
      return <this.loyaltyPlansListPlaceholder />;
    }

    return (
      <View style={styles.marginTop}>
        <FlatList
          data={loyaltyPlans}
          renderItem={this.loyaltyPlanCard}
          keyExtractor={this.keyExtractor}
          scrollEnabled={false}
          extraData={{ ...this.props, ...this.state }}
        />
      </View>
    );
  };

  freeLoyaltyPlansList = () => {
    const { loyaltyPlans = [], freeLoyaltyPlans = [], loading } = this.state;
    if (Utility.isBlank(loyaltyPlans) || loading) {
      return <this.loyaltyPlansListPlaceholder />;
    }
    if (Utility.isBlank(freeLoyaltyPlans)) return null;
    return (
      <FlatList
        data={freeLoyaltyPlans}
        renderItem={this.loyaltyPlanCard}
        keyExtractor={this.keyExtractor}
        scrollEnabled={false}
        extraData={{ ...this.props, ...this.state }}
      />
    );
  };

  loyaltyPlansList = () => {
    const { loyaltyPlans = [], loading } = this.state;
    if (Utility.isBlank(loyaltyPlans) || loading) {
      return <this.loyaltyPlansListPlaceholder />;
    }
    return (
      <FlatList
        data={loyaltyPlans}
        renderItem={this.loyaltyPlanCard}
        keyExtractor={this.keyExtractor}
        scrollEnabled={false}
        extraData={{ ...this.props, ...this.state }}
      />
    );
  };

  loyaltyPlansListPlaceholder = () => {
    return (
      <View>
        <this.loyaltyPlanCardPlaceholder />
        <this.loyaltyPlanCardPlaceholder />
        <this.loyaltyPlanCardPlaceholder />
      </View>
    );
  };

  loyaltyPlanCardPlaceholder = () => {
    return (
      <View style={styles.planCardPlaceholderContainer}>
        <View style={styles.radioButton} />
        <ShimmerPlaceHolder
          colorShimmer={this.colorShimmerArray}
          style={styles.planCardImagePlaceholder}
          autoRun
        />
        <View>
          <ShimmerPlaceHolder
            colorShimmer={this.colorShimmerArray}
            style={styles.planCardTitlePlaceholder}
            autoRun
          />
          <ShimmerPlaceHolder
            colorShimmer={this.colorShimmerArray}
            style={styles.planCardSubtitlePlaceholder}
            autoRun
          />
        </View>
      </View>
    );
  };

  onMembershipCardPress = (itemData) => {
    this.setState({
      selectedPlan: itemData,
    });
    AnalyticsManager.logEvent(EventType.discoveryEvents.LIST_ITEM_CLICK, {
      [EventParameterKey.ITEM_TYPE]:
        EventParameterValue.ITEM_TYPE.MEMBERSHIP_TYPE,
      [EventParameterKey.MEMBERSHIP_TYPE]: itemData?.name,
    });
  };

  loyaltyPlanCard = ({ item, index }) => {
    const { id = '' } = item;
    const { selectedPlan: { id: selectedPlanid = '' } = {} } = this.state;
    const isSelected = parseInt(id) === parseInt(selectedPlanid);
    return (
      <View style={styles.loyaltyPlanCard} dataSet={{ media: ids.loyaltyPlanCard }}>
        <LoyaltyPlanMembershipCard
          isSelected={isSelected}
          onPress={this.onMembershipCardPress}
          id={id}
          itemData={item}
          index={index}
        />
      </View>
    );
  };

  onButtonPress = () => {
    const { selectedPlan: { ctaAction = '', ctaText = '', name = '' } = {} } =
      this.state;
    const { membership_cohort, membership_id = '' } = this.props;
    if (Utility.isBlank(ctaText)) {
      this.showToast('Please select a plan.');
      return;
    }
    AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_ACTION, {
      [EventParameterKey.MEMBERSHIP_TYPE]: name,
      [EventParameterKey.MEMBERSHIP_STATUS]: membership_cohort,
      membership_id,
    });
    DynamicLinkManager.handleDynamicLink(`${ctaAction}`, this.navigateToScreen);
  };

  showToast = (message) => {
    this.toast.show(message, 1000);
  };

  navigateToScreen = ({
    route = '',
    slug = '',
    path = '',
    extra = {},
    params = {},
    url = '',
  }) => {
    const { navigation } = this.props;
    const { selectedPlan: { name = '' } = {} } = this.state;
    if (path === 'open_modal') {
      AnalyticsManager.logEvent('modal_view', {
        modal_name: 'request_invite_modal',
        previousScreen: 'loyalty_plans',
      });
      this.openRequestInviteModal();
      return;
    }
    const updatedParams = {
      ...params,
      plan_type: name,
      source: SCREEN_CONSTANTS.LOYALTY_PLANS,
      previousScreen: params?.previousScreen ?? SCREEN_CONSTANTS.LOYALTY_PLANS,
    };
    navigation.navigate(route, { slug, extra, params: updatedParams, url });
  };

  renderReferralModal = () => {
    const { navigation } = this.props;
    navigation.navigate('InviteToClaimModal');
  };

  onFocus = () => {
    Utility.setStatusBarColor(colors.background);
    this.debouncedLoyaltyPlansFetch();
    AnalyticsManager.setCurrentScreen(SCREEN_CONSTANTS.LOYALTY_PLANS);
  };

  openRequestInviteModal = () => {
    const { navigation } = this.props;
    const { selectedPlan } = this.state;
    navigation.navigate('RequestInviteModal', {
      itemData: selectedPlan,
      previousScreen: SCREEN_CONSTANTS.PRODUCT_DETAIL,
      referralModalType: 'invite_request_modal',
      fromInviteCenter: false,
    });
  };

  bottomActionButton = () => {
    const { selectedPlan: { ctaText: cta_text = '' } = {}, loyaltyPlans = [] } =
      this.state;
    if (Utility.isBlank(loyaltyPlans)) {
      return (
        <View style={styles.actionButtonPlaceholder}>
          <ShimmerPlaceHolder
            colorShimmer={this.darkColorShimmer}
            style={styles.buttonPlaceholderText}
            autoRun
          />
        </View>
      );
    }
    const buttonText = Utility.isBlank(cta_text) ? 'Continue' : cta_text;
    const buttonColor = Utility.isBlank(cta_text)
      ? colors.subtitle
      : colors.black;
    return (
      <FoxyShadowButton
        width={isDesktop() ? getScreenWidth() / 2 : getScreenWidth() - 24}
        title={buttonText}
        onPress={this.onButtonPress}
        style={styles.button}
        firstColor={buttonColor}
        secondColor={buttonColor}
        underlayColor={colors.subtitle}
      />
    );
  };

  render() {
    const { selfie_image_url = '' } = this.props;
    const {
      selectedPlan,
      serverError = false,
      refreshing = false,
      loading = false,
      connectionError = false,
    } = this.state;
    return (
      <>
        <StaticNavigationHeader
          title='Select a membership plan'
          bgColor={colors.background}
          overrideBackButtonBgColor
          skipDivider
        />
        <ErrorBoundary
          showServerError={serverError}
          onPressReload={this.debouncedLoyaltyPlansFetch}
          loading={loading}
          connectionError={connectionError}
          hideHeader
          screen_name={'loyalty_plans'}
        >
          <ScrollView
            style={styles.scrollView}
            contentContainerStyle={styles.contentContainerStyle}
            showsVerticalScrollIndicator={false}
            refreshControl={
              <RefreshControl
                refreshing={refreshing}
                onRefresh={this.onRefresh}
                backgroundColor={colors.background}
              />
            }
          >
            <FoxyEdgeHeader
              selfie_image_url={selfie_image_url}
              hideTopButtons
            />
            <this.purchasableLoyaltyPlansList />
          </ScrollView>
          <View style={styles.buttonContainer}>
            <this.bottomActionButton />
          </View>
        </ErrorBoundary>
        <Toast
          style={{ position: 'absolute', bottom: 20 }}
          ref={(ref) => {
            this.toast = ref;
          }}
        />
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    selfie_image_url: state.UserAccountInfo.profile.selfie_image_url,
    authToken: state.UserAccountInfo.authToken,
    has_selfie: state.UserAccountInfo.has_selfie,
    membership_cohort: state.todayDeals.membership_cohort,
    membership_id: state.todayDeals.membership_id,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      setPhoneNumberModalInfo,
      getLoyaltyPlans,
    },
    dispatch,
  ),
});

export default withNavigation(
  connect(mapStateToProps, mapDispatchToProps)(LoyaltyPlans),
);

const { ids, styles } = StyleSheet.create({
  purchasablePlanTitle: {
    color: colors.foxyBlack,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: size.h1,
    marginLeft: 12,
    marginBottom: 4,
    marginTop: 12,
  },
  title: {
    color: colors.light_grey,
    fontFamily: 'Roboto',
    fontSize: size.h3,
    fontWeight: '500',
    marginLeft: 20,
    marginTop: 4,
    marginBottom: 12,
  },
  loyaltyPlanCard: {
    marginHorizontal: 12,
    marginBottom: 8,
    '@media (min-width: 768px)': {
      width: Utility.getScreenWidth() / 2,
      alignSelf: 'center',
    },
  },
  scrollView: {
    flex: 1,
    backgroundColor: colors.white,
    width: Utility.getScreenWidth(),
    alignSelf: 'center',
  },
  contentContainerStyle: {
    paddingBottom: 40,
  },
  button: {
    height: 44,
  },
  buttonContainer: {
    width: Utility.getScreenWidth(),
    backgroundColor: colors.white,
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: Utility.isIOS() ? 20 : 8,
    alignSelf: 'center',
  },
  planCardPlaceholderContainer: {
    width: Utility.getScreenWidth() - 24,
    height: 100,
    borderRadius: 16,
    marginVertical: 12,
    alignSelf: 'center',
    backgroundColor: colors.background,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  radioButton: {
    width: 20,
    height: 20,
    backgroundColor: colors.white,
    borderWidth: 1.5,
    borderColor: '#DADADA',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 20,
  },
  planCardImagePlaceholder: {
    width: 60,
    height: 60,
    borderRadius: 30,
    marginLeft: 8,
  },
  planCardTitlePlaceholder: {
    height: 14,
    width: 100,
    borderRadius: 4,
    marginBottom: 12,
    marginLeft: 12,
    marginTop: 4,
  },
  planCardSubtitlePlaceholder: {
    height: 32,
    width: '100%',
    borderRadius: 4,
    marginBottom: 8,
    marginLeft: 12,
  },
  actionButtonPlaceholder: {
    height: 44,
    borderRadius: 4,
    backgroundColor: colors.subtitle,
    width: Utility.getScreenWidth() - 24,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonPlaceholderText: {
    width: 90,
    height: 18,
    borderRadius: 4,
  },
  marginTop: {
    marginTop: 18,
  },
});
