import React, { useEffect, useRef, useState } from 'react';
import { ScrollView, View, Text, TouchableOpacity, Image, StyleSheet, KeyboardAvoidingView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { OutlinedTextField } from 'react-native-material-textfield';
import DropDownPicker from 'react-native-dropdown-picker';
import isBoolean from 'lodash/isBoolean';
import { ids, styles } from './styles';
import { isDesktop, isIOS, isPresent } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import colors from '../../theme/Colors';
import DesktopHeaderLogo from '../shared/DesktopHeaderLogo';
import SaveProfileButton from './SaveProfileButton';
import { COUNTRY_CODE, CURRENCY_SYMBOL, REMOTE_CONFIG_KEYS, URL } from '../../config/Constants';
import OutlinedTextFieldNumber from './OutlinedTextFieldNumber';
import { useApiCallDeserializer, useApiResponseDeserializer, usePostApi } from '../../lib/hooksUtil';
import Clinic from './Clinic';
import { validateEmail } from '../../utils/ValidationUtility';
import { StaticNavigationHeader } from '../shared';
import MapModal from './MapModal';
import Header from './Header';
import { jsonParser } from '../../utils/JsonUtility';
import RemoteConfig from '../../utils/RemoteConfig';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';

const PlatformAwareKeyboardAvoidingView = withEither(
  isIOS,
  KeyboardAvoidingView,
)(View);

export default function ConsultationDetails() {
  const [consultationCharge, setConsultationCharge] = useState('');
  const [hasClinic, setHasClinic] = useState('');
  const [clinics, setClinics] = useState([]);
  const [linkError, setLinkError] = useState('');
  const [open, setOpen] = useState(false);
  const [consultationDropdownOpen, setConsultationDropdownOpen] = useState(false);
  const [videoDropdownOpen, setVideoDropdownOpen] = useState(false);
  const [deletedLocationData, setDeletedLocationData] = useState([]);
  const [videoConsultationAvailable, setVideoConsultationAvailable] = useState('');
  const [foxyConsultationPriceLowered, setFoxyConsultationPriceLowered] = useState('');
  const [assistantNumber, setAssistantNumber] = useState('');
  const [assistantPhoneNumberError, setAssistantPhoneNumberError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [onlineConsultationLink, setOnlineConsultationLink] = useState('');
  const navigation = useNavigation();
  const { data } = useApiCallDeserializer({ url: URL.GET_DERMAT_PROFILE });
  const { postApi, success, response } = usePostApi();
  const [showMapModal, setShowMapModal] = useState(false);
  const [uploadedDetails, setUploadedDetails] = useState(data);
  const [mapIndex, setMapIndex] = useState(0);
  const consultationChargeInputRef = useRef(null);
  const assistantNumberInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const consultationLinkInputRef = useRef(null);
  const clinicNameInputRef = useRef(null);
  const clinicNumberInputRef = useRef(null);
  const clinicAddressInputRef = useRef(null);
  const headerData = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.dermat_onbaord_pages_Header_text)) || {};
  const headerTitle = headerData['consultationDetails']?.title;
  const headerSubtitle = headerData['consultationDetails']?.subtitle;


  useEffect(() => {
    if (isPresent(data)) {
      setUploadedDetails(data);
    }
  }, [data]);

  useEffect(() => {
    AnalyticsManager.logEvent(EventType.pageLoad.PAGE_LOAD_2, {
      [EventParameterKey.SCREEN_NAME]: SCREEN_CONSTANTS.CONSULTATION_DETAILS,
    });
  }, []);

  const { response: uploadedData } = useApiResponseDeserializer(response);

  useEffect(() => {
    if (uploadedData) {
      setUploadedDetails(uploadedData);
    }
  }, [uploadedData]);

  useEffect(() => {
    if (isPresent(uploadedDetails)) {
      const {
        hasClinic: isClinic,
        clinics: medics = [],
        consultationCharge: charge,
        videoConsultationAvailable: videoConsultation,
        assistantNumber: assistantContact,
        foxyConsultationPriceLowered: isLowerPrice,
        email: emailAddress,
        onlineConsultationLink: link,
      } = uploadedDetails;
      if (isBoolean(isClinic)) {
        setHasClinic(isClinic ? 'yes' : 'no');
      }
      if (medics) {
        setClinics(medics);
      }
      if (isPresent(charge) && charge != 0) {
        setConsultationCharge(charge);
        consultationChargeInputRef?.current?.focus();
      }
      if (isBoolean(videoConsultation)) {
        setVideoConsultationAvailable(videoConsultation ? 'yes' : 'no');
      }
      if (assistantContact) {
        setAssistantNumber(assistantContact);
        assistantNumberInputRef?.current?.focus();
      }
      if (isBoolean(isLowerPrice)) {
        setFoxyConsultationPriceLowered(isLowerPrice ? 'yes' : 'no');
      }
      if (emailAddress) {
        setEmail(emailAddress);
        emailInputRef?.current?.focus();
      }
      if (link) {
        consultationLinkInputRef?.current?.focus();
        setOnlineConsultationLink(link);
      }
    }
  }, [uploadedDetails]);

  useEffect(() => {
    if (success) {
      navigation.navigate('SocialProfile');
    }
  }, [success]);

  useEffect(() => {
    const { hasClinic: isClinic = false } = uploadedDetails;
    if (!isClinic) {
      onHasClinicChange();
    }
  }, [hasClinic]);

  useEffect(() => {
    if (isPresent(consultationCharge) || consultationCharge != 0) {
      consultationChargeInputRef?.current?.focus();
    }
  }, [consultationCharge]);

  const goBack = () => {
    navigation.goBack();
  };

  const onConsultationChargeChange = (amount) => {
    consultationChargeInputRef?.current?.focus();
    if (!amount.length) setConsultationCharge(amount);
    if (+amount) setConsultationCharge(+amount);
  };

  const onHasClinicChange = () => {
    if (hasClinic === 'yes' && !clinics.length) {
      setClinics([
        ...clinics,
        {
          name: '',
          number: '',
          address: '',
        },
      ]);
    } else {
      setClinics([]);
    }
    setHasClinic(hasClinic);
  };

  const handlePress = () => navigation.push('TabNavigator');

  const onClinicNameChange = (name, index) => {
    setClinics(clinics.map((clinic, current) => (current === index ? { ...clinic, name } : clinic)));
  };

  const onClinicNumberChange = (number, index) => {
    const numberString = number.toString();
    if (numberString.length > 10) {
      return; // Stop further execution if there's an error
    }
    setClinics(clinics.map((clinic, current) => (current === index ? { ...clinic, number } : clinic)));
  };

  const onClinicAddressChange = (address, index) => {
    setClinics(clinics.map((clinic, current) => (current === index ? { ...clinic, address } : clinic)));
  };

  const onClinicCancel = (index) => {
    const deletedLocations = clinics
      .filter((_, currIndex) => currIndex === index)
      .map((item) => ({ ...item, _destroy: 1 }));

    // Append the new deleted locations to the existing deletedLocationData array
    setDeletedLocationData((prevDeletedLocations) => [...prevDeletedLocations, ...deletedLocations]);

    // Remove the clinic at the specified index
    setClinics(clinics.filter((_, currIndex) => currIndex !== index));
  };

  const onClickMore = () => setClinics([...clinics, { name: '', number: '', address: '' }]);

  const onSubmit = () => {
    const { isError, clinicsWithErrors } = checkClinicErrors(clearClinicErrors(clinics));
    if (!/^[6-9]\d{9}$/.test(assistantNumber) && isPresent(assistantNumber)) {
      setAssistantPhoneNumberError('number is invalid');
      return;
    }
    if (isError) {
      setClinics(clinicsWithErrors);
      return;
    }
    setEmailError('');
    if (email && !validateEmail(email)) {
      setEmailError('Email is invalid');
      return;
    }

    setLinkError('');
    if (
      onlineConsultationLink &&
      !(
        onlineConsultationLink.toLowerCase().startsWith('https://') ||
        onlineConsultationLink.toLowerCase().startsWith('http://')
      )
    ) {
      setLinkError('Link must start with https:// or http://');
      return;
    }

    let locationsData = clinics;

    if (isPresent(deletedLocationData)) {
      locationsData = [...clinics, ...deletedLocationData];
    }

    const requestParams = {
      dermat_profile: {
        has_clinic: hasClinic ? mapBoolean[hasClinic] : null,
        consultation_charge: +consultationCharge,
        clinics_attributes: locationsData.map(
          ({
            id,
            name,
            number,
            address,
            lat,
            long,
            rating,
            ratingsCount,
            locationImage,
            _destroy,
            address_components = [],
          }) => ({
            id,
            name,
            number,
            address,
            lat: `${lat}`,
            long: `${long}`,
            rating,
            user_ratings_total: ratingsCount,
            photo_reference: locationImage,
            address_components,
            _destroy,
          }),
        ),
        video_consultation_available: videoConsultationAvailable ? mapBoolean[videoConsultationAvailable] : null,
        foxy_consultation_price_lowered: foxyConsultationPriceLowered ? mapBoolean[foxyConsultationPriceLowered] : null,
        assistant_number: `${assistantNumber}`,
        email,
        online_consultation_link: onlineConsultationLink,
      },
    };
    postApi(URL.DERMAT_PROFILE, requestParams);
  };

  const showModal = () => setShowMapModal(true);
  const hideModal = () => setShowMapModal(false);
  const openMap = (index) => {
    showModal();
    setMapIndex(index);
  };
  const onLocationSelect = ({ index, lat, long, address, rating, ratingsCount, locationImage, address_components }) => {
    clinicAddressInputRef?.current?.focus();
    clinicAddressInputRef.current.setNativeProps({ selection: { start: 0, end: 0 } });
    setClinics(
      clinics.map((clinic, current) =>
        current === index
          ? {
              ...clinic,
              lat,
              long,
              address,
              rating,
              ratingsCount,
              locationImage,
              address_components,
            }
          : clinic,
      ),
    );
    hideModal();
  };

  const labelOffset = { x1: -17, y1: -20, y0: -8 };
  const contentInset = { input: 8 };

  const handleAssistantNumber = (number) => {
    setAssistantPhoneNumberError('');
    const numberString = number.toString();
    if (numberString.length > 10) {
      return;
    }
    setAssistantNumber(number);
  };

  const dropdownItems = [
    { label: 'Yes', value: 'yes' },
    { label: 'No', value: 'no' },
  ];

  return (
    <PlatformAwareKeyboardAvoidingView style={styles.container}>
      {isDesktop() && (
        <TouchableOpacity style={styles.logoContainer} onPress={handlePress}>
          <DesktopHeaderLogo />
        </TouchableOpacity>
      )}
      <View style={styles.container} dataSet={{ media: ids.container }}>
        {isDesktop() && <Header title={headerTitle} subtitle={headerSubtitle} />}
        {!isDesktop() && <StaticNavigationHeader title={headerTitle} subtitle={headerSubtitle} />}
        <ScrollView style={styles.scrollView}>
          <View style={styles.fullWidthContainer}>
            <View dataSet={{ media: ids.formContainer }} style={styles.mediaContainer}>
              <View style={innerStyles.clinicDropdownContainer}>
                <Text style={styles.label}>Do you have a clinic?</Text>
                <DropDownPicker
                  open={open}
                  value={hasClinic}
                  items={dropdownItems}
                  setOpen={setOpen}
                  setValue={setHasClinic} // Pass the function reference directly
                  placeholder='Do you have a clinic?'
                  placeholderStyle={innerStyles.placeholder}
                  style={innerStyles.picker}
                  dropDownContainerStyle={innerStyles.dropdownContainer}
                  arrowIconStyle={innerStyles.arrowIcon}
                  textStyle={innerStyles.text}
                />
              </View>
              {hasClinic === 'yes' && (
                <>
                  {clinics.map((clinic, index) => (
                    <Clinic
                      clinic={clinic}
                      index={index}
                      onNameChange={onClinicNameChange}
                      onNumberChange={onClinicNumberChange}
                      onAddressChange={onClinicAddressChange}
                      onCancel={onClinicCancel}
                      openMap={openMap}
                      clinicNameInputRef={clinicNameInputRef}
                      clinicNumberInputRef={clinicNumberInputRef}
                      clinicAddressInputRef={clinicAddressInputRef}
                    />
                  ))}
                  <View style={styles.addMoreClinics}>
                    <TouchableOpacity onPress={onClickMore}>
                      <Image source={{ uri: images.plus_button_circle }} style={styles.plusIcon} />
                    </TouchableOpacity>
                    <Text style={styles.text}>Add more if you have multiple clinics</Text>
                  </View>
                </>
              )}
              <View style={styles.paddingContainer}>
                <OutlinedTextFieldNumber
                  label='What is your in-person consultation charge?'
                  value={consultationCharge}
                  setValue={onConsultationChargeChange}
                  prefix={CURRENCY_SYMBOL}
                  phoneNumberInputRef={consultationChargeInputRef}
                  maxLength={10}
                />
              </View>
              <View style={styles.videoConsultationDropdown}>
                <Text style={styles.label}>Do you take Video consultation?</Text>
                <DropDownPicker
                  open={videoDropdownOpen}
                  value={videoConsultationAvailable}
                  items={dropdownItems}
                  setOpen={setVideoDropdownOpen}
                  setValue={setVideoConsultationAvailable}
                  placeholder='Do you take Video consultation?'
                  placeholderStyle={innerStyles.placeholder}
                  style={innerStyles.picker}
                  dropDownContainerStyle={innerStyles.dropdownContainer}
                  arrowIconStyle={innerStyles.arrowIcon}
                  textStyle={innerStyles.text}
                />
              </View>
              <View style={styles.paddingContainer}>
                <OutlinedTextFieldNumber
                  label="Assistant's Number"
                  value={assistantNumber}
                  setValue={handleAssistantNumber}
                  prefix={COUNTRY_CODE}
                  phoneNumberInputRef={assistantNumberInputRef}
                  error={assistantPhoneNumberError}
                  maxLength={10}
                />
                <Text style={styles.warningText}>Skip if you have provided clinic number</Text>
              </View>
              <View style={styles.dropdownContainer}>
                <Text style={styles.label}>
                  Will you be willing to provide a consultation for Foxy users at a price lower than your in-person
                  consultation price?
                </Text>
                <DropDownPicker
                  open={consultationDropdownOpen}
                  value={foxyConsultationPriceLowered}
                  items={dropdownItems}
                  setOpen={setConsultationDropdownOpen}
                  setValue={setFoxyConsultationPriceLowered}
                  placeholder='willing to provide a consultation...'
                  placeholderStyle={innerStyles.placeholder}
                  style={innerStyles.picker}
                  dropDownContainerStyle={innerStyles.dropdownContainer}
                  arrowIconStyle={innerStyles.arrowIcon}
                  textStyle={innerStyles.text}
                />
              </View>
              <View style={styles.paddingContainer}>
                <OutlinedTextField
                  label='Your Email Address'
                  tintColor={colors.subtitle}
                  inputContainerStyle={styles.textInputContainerStyle}
                  lineWidth={0}
                  activeLineWidth={0}
                  labelOffset={labelOffset}
                  contentInset={contentInset}
                  keyboardType='email-address'
                  value={email}
                  onChangeText={setEmail}
                  error={emailError}
                  ref={emailInputRef}
                />
              </View>
              <View style={styles.paddingContainer}>
                <OutlinedTextField
                  label='Practo / Online Consultation Link'
                  error={linkError}
                  tintColor={colors.subtitle}
                  inputContainerStyle={styles.textInputContainerStyle}
                  lineWidth={0}
                  activeLineWidth={0}
                  labelOffset={labelOffset}
                  contentInset={contentInset}
                  value={onlineConsultationLink}
                  onChangeText={setOnlineConsultationLink}
                  ref={consultationLinkInputRef}
                />
              </View>
            </View>
          </View>
        </ScrollView>
        <SaveProfileButton onPress={onSubmit} screenName={SCREEN_CONSTANTS.CONSULTATION_DETAILS} />
      </View>
      <MapModal isVisible={showMapModal} onLocationSelect={onLocationSelect} index={mapIndex} />
    </PlatformAwareKeyboardAvoidingView>
  );
}

const mapBoolean = {
  yes: true,
  no: false,
};

const checkClinicErrors = (clinics = []) => {
  let isError = false;
  const clinicsWithErrors = clinics.map((clinic) => {
    const { name, number, address, lat } = clinic;
    if (!name) {
      isError = true;
      return { ...clinic, nameError: 'name is required' };
    }
    if (!/^[6-9]\d{9}$/.test(number)) {
      isError = true;
      return { ...clinic, numberError: 'number is invalid' };
    }
    if (!address) {
      isError = true;
      return { ...clinic, addressError: 'address is required' };
    }
    if (!lat) {
      isError = true;
      return { ...clinic, locationError: 'location is required' };
    }
    return clinic;
  });
  return { isError, clinicsWithErrors };
};

const clearClinicErrors = (clinics = []) =>
  clinics.map(({ id, name, number, address, lat, long, rating, ratingsCount, locationImage, address_components }) => {
    if (id)
      return {
        id,
        name,
        number,
        address,
        lat,
        long,
        rating,
        ratingsCount,
        locationImage,
      };
    return {
      name,
      number,
      address,
      lat,
      long,
      rating,
      ratingsCount,
      locationImage,
      address_components,
    };
  });


const innerStyles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  subContainer: {
    paddingTop: 24,
    paddingHorizontal: 12,
  },
  paddingContainer: {
    padding: 16,
  },
  clinicDropdownContainer: {
    padding: 16,
    zIndex: 1000,
  },
  qualificationText: {
    color: colors.grey,
    fontFamily: 'Roboto-Medium',
  },
  picker: {
    borderWidth: 1,
    borderColor: '#d3d3d3',
    borderRadius: 5,
    paddingHorizontal: 10,
    backgroundColor: 'white',
    // zIndex: 1000,
  },
  dropdownContainer: {
    borderWidth: 1,
    borderColor: '#d3d3d3',
    borderRadius: 5,
  },
  placeholder: {
    color: '#6e6e6e', // Replace with your desired color
    fontSize: 16,
  },
  arrowIcon: {
    tintColor: '#6e6e6e', // Color for the dropdown arrow icon
  },
  text: {
    fontSize: 16,
    color: colors.foxyBlack,
  },
});