// Dependencies
import React, { Component } from 'react';
import { Image, Text, View } from 'react-native';
import CardView from '../../libraries/ReactNativeCardView';
// Components
import { styles as BannerStyles } from './styles';
import { SIZE } from '../../config/Constants';
import Images from '../../theme/Images';
import ScaleAnimate from '../shared/ScaleAnimate';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import withProfiledNavigation from '../../utils/withProfiledNavigation';
import { getNavigationSource } from '../../utils/PerfUtility';

class ShortBanner extends Component {
  navigateToScreen = ({ route = '', slug = '', extra = {} }) => {
    const { navigation } = this.props;
    navigation.navigate(getNavigationSource(this, this.props), route, {
      destination: slug, // webview reads data from destination
      slug,
      extra,
    });
  };

  onBannerPressed = (route, destination) => {
    if (!Utility.isBlank(route)) {
      this.props.navigation.navigate(
        getNavigationSource(this, this.props),
        route,
        {},
      );
    } else {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        destination,
        this.navigateToScreen,
      );
    }
  };

  ShortBanner = (props) => {
    const height = props.size === SIZE.large ? 118 : 54;
    const { size, img, heading, subHeading, destination = '', route } = props;
    return (
      <ScaleAnimate
        {...this.props}
        onPress={() => this.onBannerPressed(route, destination)}
      >
        <CardView
          cardElevation={2.0}
          cardMaxElevation={2}
          cornerRadius={4}
          style={[BannerStyles.shortBannerStyle, { height }]}
        >
          <View
            style={[
              BannerStyles.bannerTextContainer,
              BannerStyles[`${size}BannerText`],
            ]}
          >
            <Text style={BannerStyles.shortBannerCardText}>{heading}</Text>
            <Text style={BannerStyles.adText}>{subHeading}</Text>
          </View>
          <Image
            source={Images[img]}
            style={BannerStyles[`${size}ImagePosition`]}
          />
        </CardView>
      </ScaleAnimate>
    );
  };

  render() {
    return (
      <View style={BannerStyles.adContainer}>
        <this.ShortBanner
          size={SIZE.large}
          img='productShortBannerImage'
          heading='Buy Products'
          subHeading='Latest Products from
the best brands'
          destination='https://foxy.in/lists/just-arrived'
          route=''
        />
        <View style={{ justifyContent: 'flex-start' }}>
          <this.ShortBanner
            size={SIZE.small}
            img='serviceShortBannerImage'
            heading='View Store'
            subHeading='Browse products'
            destination=''
            route='Store'
          />
        </View>
      </View>
    );
  }
}

export default withProfiledNavigation(ShortBanner);
