// Dependencies
import React, { Component } from 'react';
import {
  View,
  TouchableWithoutFeedback,
  Text,
  StatusBar,
  BackHandler,
  ActivityIndicator,
} from 'react-native';
import StyleSheet from 'react-native-media-query';
import Config from '../../libraries/ReactNativeConfig';
import { WebView } from 'react-native-webview';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import StaticNavigationHeader from './StaticNavigationHeader';
import colors from '../../theme/Colors';
import size from '../../theme/Fonts';
import { AnalyticsManager, EventType, EventParameterKey } from '../../analytics';
import Toast from 'react-native-easy-toast';
import { CartStyles } from '../cart/styles';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import AppConfig from '../../config/AppConfig';
import COMMON_INJECTED_JAVASCRIPT from '../../injected/Common';

const { styles, ids } = StyleSheet.create({
  webView: {
    flex: 1.7,
  },
  buttonLayout: {
    width: Utility.getScreenWidth(),
    flex: 0.3,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: 'white',
    '@media (min-width: 800px)': {
      width: '100%',
      padding: 0,
      flex: 0.2,
    },
    // position: 'absolute',
    // bottom: 20,
    // left: 0,
    // right: 0,
  },
  buttonContainer: {
    height: 46,
    backgroundColor: Utility.isPresent(Config.THEME_COLOR)
      ? Config.THEME_COLOR
      : colors.green,
    width: Utility.getButtonWidth(),
    borderRadius: 8,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    color: colors.white,
    fontSize: size.h1,
    fontFamily: 'Roboto-Medium',
  },
});

class WebUrlView extends Component {
  constructor(props) {
    super(props);
    const {
      route,
      route: { params: { customInjectedJavascript = '' } = {} } = {},
    } = props;
    this.webViewRef = React.createRef();
    this.state = {
      title: route.params?.title ?? '',
      showBottomButton: route.params?.showBottomButton ?? false,
      fromOnboarding: route.params?.fromOnboarding ?? false,
      showPlaceholder: false,
    };

    this.disableHttpErrorReturn = route.params?.disableHttpErrorReturn || false;
    this.replaceScreen = route.params?.replaceScreen;
    this.injectedJavaScript = `${COMMON_INJECTED_JAVASCRIPT} ${customInjectedJavascript}`;
  }

  componentDidMount() {
    this.backHandler = BackHandler.addEventListener(
      'hardwareBackPress',
      this.handleBackPress,
    );
  }

  componentWillUnmount() {
    this.backHandler.remove();
  }

  handleBackPress = () => {
    const { fromOnboarding, title } = this.state;
    if (fromOnboarding) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.ONBOARDING_LINK_CONTINUE,
        {
          [EventParameterKey.LINK_NAME]: title,
          [EventParameterKey.METHOD]: 'Back Press',
        },
      );
    }
    this.goBack();
    return true;
  };

  handleContinue = () => {
    const { fromOnboarding, title } = this.state;
    if (fromOnboarding) {
      AnalyticsManager.logEvent(
        EventType.onboardingEvent.ONBOARDING_LINK_CONTINUE,
        {
          [EventParameterKey.LINK_NAME]: title,
          [EventParameterKey.METHOD]: 'Button Press',
        },
      );
    }
    this.goBack();
  };

  goBack = () => {
    const { navigation } = this.props;
    if (this.replaceScreen) {
      navigation.replace(this.replaceScreen);
    } else {
      if (navigation.getState().routes.length < 2) {
        navigation.replace('TabNavigator');
        return;
      }
      navigation.goBack();
    }
  };

  handleMessage = (message) => {
    const { data, title } = message.nativeEvent;
    const { navigation } = this.props;
    // const { title } = this.state;

    try {
      const { type, slug, event, params, success, showPlaceholder } = Utility.jsonParser(
        decodeURIComponent(decodeURIComponent(data)),
      );
      this.setState({
        showPlaceholder,
      });

      if (success !== undefined) {
        this.goBack();
        return;
      }
      if (event === 'go_to_home_page') {
        navigation.navigate('Feed');
      }
      AnalyticsManager.logEvent(event, params);
    } catch (er) {
      console.tron.log(er, 'error');
    }

    // if (title === '') {
    //   this.setState({
    //     title: message.nativeEvent.data,
    //   });
    // }
  };

  buttonLayout = () => (
    <TouchableWithoutFeedback onPress={this.handleContinue} >
      <View style={styles.buttonLayout} dataSet={{ media: ids.buttonLayout }}>
        <View style={styles.buttonContainer} dataSet={{ media: ids.buttonContainer }}>
          <Text style={styles.buttonText}>Continue</Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );

  toastRef = (ref) => {
    this.toast = ref;
  };

  handleRouteFromLink = ({ route = '', slug = '', extra = {} }) => {
    const { navigation, getListDetails } = this.props;
    if (route === 'GoBack') {
      navigation.goBack();
      return;
    }
    navigation.replace(route, { slug, extra, destination: slug });
  };

  onNavigationStateChange = (navState) => {
    // onNavigationStateChange gets called everytime on iOS on initial load also, which is not the case on Android
    // To prevent unnecessary linking return if navState.navigationType !== 'click')
    if (Utility.isIOS() && navState.navigationType !== 'click') {
      return true;
    }
    if (navState.url.includes('foxy.in/')) {
      DynamicLinkManager.handleLinkWithInternalTrackingParams(
        navState.url,
        this.handleRouteFromLink,
      );
      return false;
    }
    return true;
  };

  webviewLoader = () => {
    return (
      <View
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.5,
        }}
      >
        <ActivityIndicator
          color={colors.foxyBlack}
          size="large"
          style={styles.webviewLoadContainer}
        />
      </View>
    );
  };

  isExternalUrl = (url) => {
    return !url?.includes(Config.SHORT_WEB_URL);
  };

  injectJavaScriptOnLoadEnd = () => {
    const {
      route: { params: { injectJavaScriptOnLoadEnd = false } = {} } = {},
    } = this.props;
    if (!injectJavaScriptOnLoadEnd) return;
    this.webViewRef.current.injectJavaScript(this.injectedJavaScript);
  };

  render() {
    const { showBottomButton, showPlaceholder } = this.state;
    const { route } = this.props;
    let url = route.params?.destination;

    const placeholder = route.params?.placeholder;

    if (!!route.params?.newDestination) {
      url = route.params?.newDestination;
    }

    if (Utility.isBlank(url)) {
      url = Config.HTTPS_DOMAIN_NAME;
    }

    const userAgentPrefix = Utility.isAndroid() ? 'Android' : 'ios';

    const redirectingText = RemoteConfig.getValue(
      REMOTE_CONFIG_KEYS.redirect_text,
    );

    let webviewUrl = `${url}`;
    if (
      AppConfig.getBooleanValue(Config.APPEND_PLATFORM_WITH_WEBVIEW_URL) &&
      !this.isExternalUrl(webviewUrl)
    ) {
      webviewUrl = `${webviewUrl}?platform=app`;
    }

    return (
      <View style={{ flex: 1 }}>
        <StatusBar barStyle="dark-content" />
        <StaticNavigationHeader
          title={this.state.title}
          onBackPress={this.goBack}
        />
        <View style={styles.webView}>
          {showPlaceholder && placeholder ? (
            placeholder()
          ) : (
            <WebView
              ref={this.webViewRef}
              style={styles.webViewHeight}
              source={{
                uri: `${webviewUrl}`,
              }}
              onHttpError={(syntheticEvent) => {
                if (this.disableHttpErrorReturn) return;
                this.toast.show(redirectingText, 3000);
                setTimeout(this.props.navigation.goBack, 3000);
              }}
              injectedJavaScript={this.injectedJavaScript} // to remove zoom and add header title
              onMessage={this.handleMessage}
              onShouldStartLoadWithRequest={this.onNavigationStateChange}
              renderLoading={this.webviewLoader}
              onLoadEnd={this.injectJavaScriptOnLoadEnd}
              androidLayerType='hardware'
              domStorageEnabled
              scalesPageToFit={true}
              cacheMode='LOAD_DEFAULT'
            />
          )}
        </View>
        {showBottomButton && <this.buttonLayout />}
        <Toast style={CartStyles.toast} ref={this.toastRef} />
      </View>
    );
  }
}

export default withNavigation(WebUrlView);
