import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import Tags from './Tags';
import colors from '../../theme/Colors';
import FastImageView from '../../components/FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import { isBlank, isDesktop, isPresent } from '../../utils/BooleanUtility';
import images from '../../theme/Images';

const ProfilePicture = ({ imageUrl = '' }) => {
  const image = isBlank(imageUrl) ? images.uploadProfileImagePlaceholder : imageUrl;
  return <FastImageView source={getMinifiedImage(image, styles.image.width)} style={styles.image} />;
};

const InfluencerInfoCard = ({ artistData }) => {
  const {
    name,
    image_url: imageUrl,
    influencer_tags = [],
    influencer_tag_colors = [],
    bio = '',
    title = '',
    verified = '',
  } = artistData;
  return (
    <View style={styles.cardContainer}>
      <View style={styles.contentContainer}>
        <ProfilePicture imageUrl={imageUrl} />
        <View style={styles.titleContainer}>
          <Text style={styles.influencerName}>{`${title} ${name}`}</Text>
          {verified && <FastImageView source={getMinifiedImage(images.verified_tick)} style={styles.verifiedImage} />}
        </View>
        <Tags
          tags={influencer_tags}
          influencer_tag_colors={influencer_tag_colors}
          TagContainerStyle={styles.tagsContainer}
        />
        {isPresent(bio) && (
          <Text numberOfLines={2} ellipsizeMode='tail' style={styles.influencerBio}>
            {bio?.replace(/(\r\n|\n|\r)/gm, '')}
          </Text>
        )}
      </View>
    </View>
  );
};

export default InfluencerInfoCard;

const styles = StyleSheet.create({
  cardContainer: {
    overflow: 'hidden',
    backgroundColor: '#fff',
    marginHorizontal: 10,
    height: isDesktop() ? 260 : getScreenHeight() / 3,
    width: isDesktop() ? '40%' : getScreenWidth(),
    alignContent: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
  },
  headerContainer: {
    // padding: 10,
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: colors.foxyBlack,
  },
  contentContainer: {
    padding: 10,
    alignItems: 'center',
  },
  image: {
    width: 80,
    height: 80,
    borderRadius: 40,
    // marginRight: 10,
  },
  tagsContainer: {
    width: isDesktop() ? '100%' : getScreenWidth() / 1.3,
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    paddingHorizontal: 12,
  },
  influencerBio: {
    marginTop: 8,
    textAlign: 'center',
    fontSize: 14,
    color: colors.black,
    width: isDesktop() ? '80%' : getScreenWidth() / 1.3,
  },
  influencerName: {
    color: colors.black,
    fontSize: 20,
    fontFamily: 'Roboto-Bold',
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 10,
  },
  verifiedImage: {
    height: 24,
    width: 24,
    marginHorizontal: 6,
  },
});
