import {
  View,
  TouchableOpacity,
  Image,
  StyleSheet,
  Text,
} from 'react-native';
import React from 'react';
import { OutlinedTextField } from 'react-native-material-textfield';
import colors from '../../theme/Colors';
import { styles } from './styles';
import OutlinedTextFieldNumber from './OutlinedTextFieldNumber';
import { COUNTRY_CODE } from '../../config/Constants';
import images from '../../theme/Images';

export default function Clinic({
  clinic = {},
  index,
  onNameChange,
  onNumberChange,
  onAddressChange,
  onCancel,
  openMap,
  clinicNameInputRef,
  clinicNumberInputRef,
  clinicAddressInputRef,
}) {
  const onPress = () => openMap(index);
  const onChangeName = (text) => onNameChange(text, index);
  const onChangeNumber = (text) => onNumberChange(text, index);
  const onChangeAddress = (text) => onAddressChange(text, index);
  const labelOffset = { x1: -17, y1: -20, y0: -8 };
  const contentInset = { input: 8 };
  return (
    <View style={clinicStyles.container}>
      {!!index && (
        <TouchableOpacity style={clinicStyles.crossStyle} onPress={() => onCancel(index)}>
          <Image source={images.cross} style={{ tintColor: colors.black }} />
        </TouchableOpacity>
      )}
      <View style={clinicStyles.input}>
        <OutlinedTextField
          label='Clinic name*'
          tintColor={colors.subtitle}
          inputContainerStyle={styles.textInputContainerStyle}
          lineWidth={0}
          activeLineWidth={0}
          labelOffset={labelOffset}
          contentInset={contentInset}
          value={clinic.name}
          onChangeText={onChangeName}
          error={clinic.nameError}
          ref={clinicNameInputRef}
        />
      </View>
      <View style={clinicStyles.bottomInput}>
        <OutlinedTextFieldNumber
          label='Clinic Number*'
          value={clinic.number}
          setValue={onChangeNumber}
          prefix={COUNTRY_CODE}
          error={clinic.numberError}
          phoneNumberInputRef={clinicNumberInputRef}
          onNumberChange={onNumberChange}
        />
      </View>
      <View style={clinicStyles.bottomInput}>
        <TouchableOpacity onPress={onPress}>
          <View style={clinicStyles.locationContainer}>
            <Image source={{ uri: images.mapLocationIcon }} style={clinicStyles.icon} />
            <Text style={clinicStyles.locationText}>{clinic.lat ? 'Edit Location' : 'Select Location*'}</Text>
          </View>
        </TouchableOpacity>
        {Boolean(clinic.locationError) && <Text style={clinicStyles.error}>{clinic.locationError}</Text>}
      </View>
      <View style={clinicStyles.bottomInput}>
        <OutlinedTextField
          label='Clinic Address*'
          tintColor={colors.subtitle}
          inputContainerStyle={styles.textInputContainerStyle}
          lineWidth={0}
          activeLineWidth={0}
          labelOffset={labelOffset}
          contentInset={contentInset}
          value={clinic.address}
          onChangeText={onChangeAddress}
          error={clinic.addressError}
          ref={clinicAddressInputRef}
        />
      </View>
    </View>
  );
}

const clinicStyles = StyleSheet.create({
  container: {
    backgroundColor: '#fafafa',
    marginVertical: 10,
    paddingTop: 10,
    width: '100%',
  },
  crossStyle: {
    position: 'absolute',
    top: -4,
    right: -4,
    height: 30,
    width: 30,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  input: {
    padding: 16,
  },
  bottomInput: {
    padding: 16,
    paddingTop: 8,
  },
  locationContainer: {
    flexDirection: 'row',
    height: 40,
    width: '100%',
    backgroundColor: '#f4f6f8',
    borderRadius: 4,
    alignItems: 'center',
    paddingHorizontal: 10,
  },
  icon: {
    width: 16,
    height: 16,
  },
  locationText: {
    fontSize: 16,
    color: colors.subtitle,
    fontFamily: 'Roboto-Regular',
    marginLeft: 5,
  },
  error: {
    fontSize: 12,
    paddingVertical: 2,
    color: '#D50000',
    fontFamily: 'Roboto-Regular',
    marginLeft: 5,
  },
});
