import React, { useEffect, useRef, useState } from 'react';
import { View, Text, Image, TouchableOpacity, StyleSheet } from 'react-native';
import images from '../../theme/Images';
import { isDesktop, isPresent, isWeb } from '../../utils/BooleanUtility';
import colors from '../../theme/Colors';
import FastImageView from '../FastImageView';
import { getMinifiedImage } from '../../utils/ImageUtility';
import { foxyImagePicker, openGalleryOrShowPermissionModal } from '../../utils/ImagePickerUtility';
import { getStoreRef } from '../../store/StoreUtility';
import { STORAGE_MODAL_INFO } from '../../config/Constants';
import CameraUtils from '../../utils/CameraUtils';
import { getScreenWidth } from '../../utils/LayoutUtility';

export default function ImageUploader({
  image,
  setImage,
  postInsertImage = () => {},
  navigation,
}) {
  const fileInputRef = useRef(null);
  const [permissionStatus, setPermissionStatus] = useState();
  useEffect(() => {
    const store = getStoreRef().getState();
    setPermissionStatus(store?.permissionStatus);
  }, [permissionStatus]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result;
        setImage(base64String, base64String);
      };

      reader.readAsDataURL(file);
      postInsertImage();
    }
  };

  const onCancelAlert = () => {
    navigation.goBack();
  };

  const openGallery = async () => {
    const imagePickerProps = {
      mediaType: 'photo',
      multiple: false,
    };
    const { appendedImages: mediaData } = await foxyImagePicker({
      storagePermissionStatus: permissionStatus,
      onCancelAlert,
      imagePickerProps,
      allowMediaSingleSelection: true,
    });
    if (isPresent(mediaData)) {
      convertImageToBase64AndUpload(mediaData?.path)
    }
  };

  const convertImageToBase64AndUpload = (imageUri) => {
    CameraUtils.convertImageToBase64(imageUri)
      .then((response) => {
        const base64String = `data:image/jpeg;base64,${response}`;
        setImage(imageUri, base64String);
      })
      .catch((error) => {});
  };

  const onClickUploadImageButton = () => {
    openGalleryOrShowPermissionModal({
      openGallery,
      type: 'Post Review',
      modalInfo: STORAGE_MODAL_INFO.post_review,
    });
  };

  const handleUploadClick = () => {
    if (isWeb()) {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
      return;
    }
    onClickUploadImageButton();
  };

  const imageUrl = image || images.uploadProfileImagePlaceholder;

  return (
    <View style={styles.avatarContainer}>
      <TouchableOpacity onPress={handleUploadClick} style={styles.avatar}>
        <FastImageView
          source={imageUrl}
          style={styles.image}
          resizeMode='cover'
        />
        {isPresent(image) && <Text style={styles.edit}>Edit</Text>}
      </TouchableOpacity>
      <Text style={styles.caption}>Uploading your profile picture will help build trust with our users!</Text>
      {isWeb() && (
        <input
          ref={fileInputRef}
          type='file'
          accept='image/*'
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  avatarContainer: {
    marginBottom: 20,
    marginTop: 30,
    alignItems: 'center',
    backgroundColor: colors.white,
  },
  avatar: {
    width: 100,
    height: 100,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ddd',
    borderRadius: 75,
    overflow: 'hidden',
    '@media (min-width: 768px)': {
      overflow: 'clip',
    },
  },
  image: {
    width: 100,
    height: 100,
    borderRadius: 75,
    '@media (min-width: 768px)': {
      overflow: 'clip',
    },
    overflow: 'hidden',
  },
  edit: {
    position: 'absolute',
    bottom: 0,
    textAlign: 'center',
    color: colors.white,
    width: '100%',
    backgroundColor: '#00000042',
    fontSize: 11,
    height: 23,
    lineHeight: 23,
    fontFamily: 'Roboto-Regular',
    '@media (min-width: 768px)': {
      '-webkit-backdrop-filter': 'blur(8px)',
      backdropFilter: 'blur(8px)',
    },
  },
  caption: {
    alignSelf: 'center',
    color: colors.disabled,
    fontSize: 12,
    marginTop: 4,
    fontFamily: 'Roboto-Regular',
    textAlign: 'center',
    width: getScreenWidth() / 2,
  },
});
