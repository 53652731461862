import {
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
} from 'react-native';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import AccountModal from './AccountModal';
import { useAuthToken } from '../../lib/hooksUtil';
import { isPresent } from '../../utils/BooleanUtility';
import images from '../../theme/Images';
import { getProfileCompletion } from '../../actions/ActionTypes';

export default function Account({ navigation }) {
  const authToken = useAuthToken();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [dermatOption, setDermatOption] = useState([]);

  useEffect(() => {
    if (showModal) {
      dispatch(getProfileCompletion(getProfileCompletionCallback));
    }
  }, [showModal, authToken]);

  const toggleModal = () => setShowModal(!showModal);

  const getProfileCompletionCallback = (success, data) => {
    setDermatOption([]);
    if (success) {
      const { dermat_options = [] } = data;
      setDermatOption(dermat_options);
    }
  };

  const accountTitle = isPresent(authToken) ? 'Account' : 'Login';
  return (
    <>
      <TouchableOpacity style={styles.container} onPress={toggleModal}>
        <Image
          source={{ uri: images.desktop.accountIcon }}
          style={styles.image}
        />
        <Text style={styles.text}>{accountTitle}</Text>
      </TouchableOpacity>
      <AccountModal
        showModal={showModal}
        toggleModal={toggleModal}
        navigation={navigation}
        dermatOption={dermatOption}
      />
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: 'space-between',
  },
  image: {
    marginTop: 3,
    width: 22,
    height: 22,
    lineHeight: 1.5,
    alignSelf: 'center',
  },
  text: {
    fontSize: 12,
    color: '#173143',
    lineHeight: 1.5,
    alignSelf: 'center',
    marginTop: 12,
  },
});
