import React, { PureComponent, useRef } from 'react';
import {
  BackHandler,
  Image,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Linking,
  RefreshControl,
  Platform,
  Clipboard,
} from 'react-native';
import Config from '../../libraries/ReactNativeConfig';
import Share from '../../libraries/ReactNativeShare';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getInfluencerDetails } from '../../actions/InfluencerActions';
import { followArtist, unFollowArtist } from '../../actions/ActionTypes';
import { setPhoneNumberModalInfo } from '../../actions/LoginActions';
import { AnalyticsManager, EventParameterKey, EventType } from '../../analytics';
import FastImageView from '../../components/FastImageView';
import { StaticNavigationHeader } from '../../components/shared';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import NoInternet from '../../components/shared/NoInternet';
import {
  AnalyticsScreenNames,
  API_ERRORS,
  LOGIN_MODAL_MESSAGES,
  ONE_LINK_REGISTRATION,
  PERMISSION_STORE,
  PERMISSIONS,
  REMOTE_CONFIG_KEYS,
  SCREEN,
  URL,
  WEB_URL,
} from '../../config/Constants';
import { SCREEN_CONSTANTS } from '../../config/ScreenConstants';
import { withEither } from '../../lib/Monads';
import images from '../../theme/Images';
import Utility, { openUrlInBrowser } from '../../utils/Utility';
import { List } from '../List';
import CircularGradient from './CircularGradient';
import InfluencerPlaceHolder from './InfluencerPlaceHolder';
import ProAccountsList from './ProAccountsList';
import styles from './styles';
import colors, { Theme } from '../../theme/Colors';
import RemoteConfig from '../../utils/RemoteConfig';
import WithNavigation from '../../utils/WithNavigation';
import NavigationService from '../../navigator/NavigationService';
import { isDesktop, isWeb } from '../../utils/BooleanUtility';
import WebFooter from '../../components/webFooter/WebFooter';
import { getFirebasePerformanceTrace } from '../../utils/PerfUtility';
import { getMinifiedImage } from '../../utils/ImageUtility';
import Qualifications from './Qualifications';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import ProductRating from '../../components/productRatings/ProductRating';
import { RateProduct } from '../../components/Product';
import { getScreenHeight, getScreenWidth } from '../../utils/LayoutUtility';
import FoxyShadowButton from '../../lib/FoxyShadowButton';
import Tags from './Tags';
import { debounce } from 'lodash';
import InfluencerRating from './InfluencerRating';
import { Animated } from 'react-native';
import TaskPromptCard from '../../components/feature/TaskPromptCard';
import { getStoreRef } from '../../store/StoreUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import { jsonParser } from '../../utils/JsonUtility';


var JSONAPIDeserializer = require('jsonapi-serializer').Deserializer;
class Influencer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      error: undefined,
      isFollowing: false,
      ratingLayout: {},
      allReviews: [],
    };
    const { route } = this.props;
    this.previousScreen = route?.params?.previousScreen;
    this.goBack = () => {
      const { navigation } = props;
      if (navigation.getState().routes.length > 1) {
        navigation?.goBack();
      } else {
        navigation.replace('TabNavigator');
      }
      return true;
    };
    if (Utility.isAndroid()) {
      this.backHandler = BackHandler.addEventListener('hardwareBackPress', this.goBack);
    }
    this.shareButtonStyle = {
      position: 'relative',
      height: 48,
      width: 48,
      left: Utility.getScreenWidth() - 48,
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: Utility.isIOS() ? Utility.topInset : 8,
    };
    this.placeholderProfileImageUrl = route.params?.profilePlaceholderUrl || '';
    this.trace = getFirebasePerformanceTrace(SCREEN_CONSTANTS.ARTIST);
    this.trace.start();
    this.artistHandle = this.getInfluencerHandle();
    this.debouncedSetRatingLayout = debounce(this.setRatingLayout.bind(this), 1000);
    this.scrollViewRef = React.createRef();
    this.scrollY = React.createRef(new Animated.Value(0)).current;
    this.skinTwinCardData = {
      title: 'Find your skin twins!',
      body: 'Take the survey & get relevant reviews tailored to your needs.',

      cta_text: 'Click Selfie',
      image_url: images.selfie_image,
      backgroundColor: colors.light_pink,
    };
    this.debouncedFollowArtist = debounce(this.followArtist, 300);
    this.currentPermissionState = '';
    this.shareTemplate = jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.share_handle)) || {};
    // this.scrollY = useRef(new Animated.Value(0)).current;
  }

  componentDidMount() {
    this.fetchInfluencerDetails();
  }

  componentWillUnmount() {
    if (Utility.isAndroid()) this.backHandler?.remove();
  }

  fetchInfluencerDetails = () => {
    const { getInfluencerDetails } = this.props;
    const handle = this.getInfluencerHandle();
    getInfluencerDetails(handle, this.getInfluencerDetailsCallback);
  };

  onRefreshInfluencerDetails = () => {
    this.setState({ loading: true, data: {} });
    this.fetchInfluencerDetails();
  };

  getInfluencerHandle = () => {
    const { route, profile = {} } = this.props;
    const bioHandle = route.params?.bioHandle || route.params?.destination;
    return bioHandle ? bioHandle.slice(bioHandle.indexOf('@') + 1) : ' ';
  };

  getInfluencerDetailsCallback = (success, details) => {
    this.trace.stop();
    if (success) {
      this.setState({ loading: false });
      const { followedArtists = {} } = this.props;
      const { reviews = [], skin_twin_ratings: skinTwinRatings = [] } = details;
      const allReviews = this.mergeReviews(reviews, skinTwinRatings);
      const isFollowing = Utility.isPresent(followedArtists[details?.artist_id]);
      this.setState({ data: details, isFollowing, error: undefined, allReviews: allReviews });
      AnalyticsManager.logEvent(EventType.influencer.BIO_PAGE_VIEW, {
        [EventParameterKey.FOXY_HANDLE]: this.getInfluencerHandle(),
      });
    } else {
      this.setState({ data: {}, error: API_ERRORS.SERVER_ERROR, loading: false });
    }
  };

  mergeReviews = (reviews = [], skinTwinRatings = []) => {
    // Check if skinTwinRatings is not empty and add the flag to the first item
    if (skinTwinRatings.length > 0) {
      skinTwinRatings[0].label = 'Skin Twin Review';
    }

    // Check if reviews is not empty and add the flag to the first item
    if (reviews.length > 0) {
      reviews[0].label = 'Most Helpful';
    }

    // Merge the two arrays with skinTwinRatings coming first
    const mergedArray = [...skinTwinRatings, ...reviews];

    return mergedArray;
  };

  circularGradientWithPlayButton = (props) => {
    return (
      <>
        <CircularGradient {...props}>{props.children}</CircularGradient>
        <FastImageView source={images.introVideoPlay} style={styles.videoPlayIcon} />
      </>
    );
  };

  getVideoUrl = () => {
    const { data: { intro_video: { metadata: { videoUrl = '' } = {} } = {} } = {} } = this.state;
    return videoUrl;
  };

  isVideoPresent = () => {
    return Utility.isPresent(this.getVideoUrl());
  };

  conditionalHalo = withEither(this.isVideoPresent, this.circularGradientWithPlayButton)(View);

  navigateToVideo = () => {
    const { navigation } = this.props;
    const videoUrl = this.getVideoUrl();
    if (Utility.isBlank(videoUrl)) return;
    navigation.navigate(SCREEN_CONSTANTS.MEDIA_COMPONENT_WITHOUT_CONTROLS, {
      uri: videoUrl,
    });
    AnalyticsManager.logEvent(EventType.influencer.INTRO_PLAY_VIDEO, {
      [EventParameterKey.FOXY_HANDLE]: this.getInfluencerHandle(),
    });
  };

  tag = ({ image_url: imageUrl, name }, index) => {
    const { data: { influencer_tag_colors: tagColors = [] } = {} } = this.state;
    return (
      <View style={[styles.tag, { backgroundColor: tagColors[index] }]}>
        <FastImageView
          source={getMinifiedImage(imageUrl, styles.tagIcon.width, styles.tagIcon.height)}
          style={styles.tagIcon}
        />
        <Text style={styles.tagText}>{name}</Text>
      </View>
    );
  };

  Tags = ({ tags = [] }) => {
    if (Utility.isBlank(tags)) return null;
    return <View style={styles.tagsContainer}>{tags.map(this.tag)}</View>;
  };

  ProfilePicture = ({ imageUrl = '' }) => {
    if (isBlank(imageUrl)) {
      return (
        <TouchableOpacity onPress={this.onPressEditIcon}>
          <FastImageView
            source={getMinifiedImage(images.uploadProfileImagePlaceholder, styles.image.width)}
            style={styles.image}
            resizeMode='cover'
          />
        </TouchableOpacity>
      );
    }
    return (
      <DebouncedTouchableOpacity
        {...this.props}
        onPress={this.navigateToVideo}
        style={styles.profilePictureContainer}
        disabled={!this.isVideoPresent()}
      >
        <this.conditionalHalo circleRadius={52} padding={2} colorsArray={Theme.influencerHaloGradient}>
          <FastImageView
            source={getMinifiedImage(imageUrl, styles.profileImage.width, styles.profileImage.height)}
            style={styles.profileImage}
          />
        </this.conditionalHalo>
      </DebouncedTouchableOpacity>
    );
  };

  onShareButtonPress = () => {
    const { messageTemplate: { greetingText = '', closingText = '' } = {} } = this.shareTemplate;
    const {
      profile: { name = '' },
    } = this.props;
    const handle = this.getInfluencerHandle();
    AnalyticsManager.logEvent(EventType.INFLUENCER.INFLUENCER_SHARE, {
      handle,
      screenName: SCREEN_CONSTANTS.INFLUENCER,
    });
    const title = Config.APP_NAME;
    const url = `${Config.URI_HOST}/@${handle}`;
    const message = `${greetingText} ${name}. ${closingText}`;
    if (isWeb()) {
      if (navigator.share) {
        navigator
          .share({
            title,
            text: message,
            url,
          })
          .then(() => console.log('Successful share! 🎉'))
          .catch((err) => console.error(err));
      } else {
        Clipboard.setString(`${Config.URI_HOST}/@${handle}`);
      }
    }
    this.shareLink(title, message, url);
  };

  shareLink = (title, message, url) => {
    Share.open({
      title,
      message: `${message} ${url}`,
    });
  };


  followArtist = () => {
    const { followArtist, unFollowArtist, authToken, setPhoneNumberModalInfo } = this.props;
    const { isFollowing, data: { artist_id: id = '' } = {} } = this.state;
    if (Utility.isBlank(authToken)) {
      setPhoneNumberModalInfo(Utility.getLoginModalInfo('FOLLOW_ARTIST'));
      NavigationService.renderOnboarding();
      return;
    }
    const slug = isFollowing ? URL.UNFOLLOW_INFLUENCER_SLUG : URL.FOLLOW_INFLUENCER_SLUG;
    AnalyticsManager.logEvent(EventType.videoEvents.VIDEO_ARTIST_FOLLOW);
    const url = slug?.replace('id', id);
    const data = {
      url,
      isFollowing,
      id,
    };
    if (isFollowing) {
      unFollowArtist(data);
    } else {
      followArtist(data);
    }
    this.setState({ isFollowing: !isFollowing });
  };

  FollowButton = ({ isFollowing }) => {
    const containerStyle = isFollowing ? styles.followingButtonContainer : styles.followButtonContainer;
    const iconStyle = isFollowing ? styles.followingButtonIcon : styles.followButtonIcon;
    const textStyle = isFollowing ? styles.followingButtonText : styles.followButtonText;
    const text = isFollowing ? 'Following' : 'Follow';
    const icon = isFollowing ? images.followingIcon : images.followIcon;

    return (
      <TouchableOpacity style={containerStyle} onPress={this.debouncedFollowArtist}>
        <FastImageView style={iconStyle} source={icon} />
        <Text style={textStyle}>{text}</Text>
      </TouchableOpacity>
    );
  };

  IntroCard = ({ data, isFollowing }) => {
    const {
      pro_accounts = [],
      image_url: imageUrl = '',
      name = '',
      bio = '',
      influencer_tags = [],
      influencer_tag_colors = [],
      rating = '',
      reviews_count = 0,
      ratings_count = 0,
      verified = false,
      title = '',
    } = data;

    const profileUrl = Utility.isBlank(imageUrl) ? this.placeholderProfileImageUrl : imageUrl;
    return (
      <>
        <View style={styles.introContainer}>
          <View style={styles.headerContainer}>
            <this.ProfilePicture imageUrl={profileUrl} />
            <View style={styles.titleContainer}>
              <Text style={styles.influencerName}>{`${title} ${name}`}</Text>
              {verified && (
                <FastImageView source={getMinifiedImage(images.verified_tick)} style={styles.verifiedImage} />
              )}
            </View>
            <InfluencerRating onRatingTap={this.scrollToRatingSection} rating={rating} ratingsCount={ratings_count} />
            <Tags tags={influencer_tags} influencer_tag_colors={influencer_tag_colors} />
            {Utility.isPresent(bio) && <Text style={styles.influencerBio}>{bio?.replace(/(\r\n|\n|\r)/gm, '')}</Text>}
            <this.FollowButton isFollowing={isFollowing} />
          </View>
          <ProAccountsList accounts={pro_accounts} getInfluencerHandle={this.getInfluencerHandle} />
        </View>
        <View style={styles.introCardFooterContainer}>
          <View style={styles.bottomCurveLayer1} />
          <View style={styles.bottomCurveLayer2} />
        </View>
      </>
    );
  };

  registerOneLink = () => {
    if (isWeb()) {
      Linking.openURL('/pro/handle-selection');
      return;
    }
    openUrlInBrowser(ONE_LINK_REGISTRATION);
  };

  scrollToRatingSection = () => {
    const { ratingLayout } = this.state;
    this.scrollViewRef.current.scrollTo({
      y: ratingLayout?.y - 24,
      animated: true,
    });
  };

  setRatingLayout(layout) {
    this.setState({ ratingLayout: layout });
  }

  onRatingLayout = (e) => {
    this.debouncedSetRatingLayout(e.nativeEvent.layout);
  };

  OneLinkButton = () => {
    return (
      <View style={styles.registerOneLink}>
        <FastImageView source={getMinifiedImage(images.foxy_rx, styles.icon.width)} style={styles.icon} />
      </View>
    );
  };

  ConnectionErrorPage = () => {
    return (
      <>
        <StaticNavigationHeader onBackPress={this.goBack} />
        <NoInternet onPress={this.fetchInfluencerDetails} />
      </>
    );
  };

  ErrorCard = () => {
    return (
      <View style={styles.errorCardContainer}>
        <Image source={images.no_internet} style={styles.errorCardHeight} resizeMode='contain' />
        <Text style={styles.heading}>Uh-oh!</Text>
        <Text style={styles.subHeading}>
          The profile doesn't exist or may have been moved permanently to a new location
        </Text>
      </View>
    );
  };

  hasApiFailed = () => {
    const { error } = this.state;
    return error === API_ERRORS.SERVER_ERROR;
  };

  conditionalErrorOrIntroCard = withEither(this.hasApiFailed, this.ErrorCard)(this.IntroCard);

  toggleReview = () => {
    const {
      data: {
        rating = '',
        name = '',
        image_url = '',
        influencer_tags = [],
        influencer_tag_colors = [],
        bio = '',
        title = '',
        verified = false,
      } = {},
    } = this.state;
    const { navigation } = this.props;
    const artistData = {
      name,
      image_url,
      influencer_tags,
      influencer_tag_colors,
      bio,
      communityGuideline:
        'Upload photos or videos of the clinic if you have had an in-person consultation with the dermatologist.',
      title,
      verified,
    };
    navigateToScreen({
      navigation,
      type: isDesktop() ? 'push' : 'navigate',
      screen: 'PostReview',
      params: {
        rating,
        artistHandle: this.artistHandle,
        artistData,
      },
    });
  };

  onPressPrimaryButton = () => {};

  primaryButtonContainer = ({ isFollowing }) => {
    const containerStyle = isFollowing ? styles.followingCtaContainer : {};
    const text = isFollowing ? 'Following' : 'Follow';
    const icon = isFollowing ? images.followingIcon : images.followIcon;
    const buttonTextStyle = isFollowing ? styles.followingCtaButtonText : styles.buttonText;
    return (
      <FoxyShadowButton
        width={(getScreenWidth() - 24 - 12) / 2}
        height={42}
        title={text}
        onPress={this.debouncedFollowArtist}
        textStyle={buttonTextStyle}
        firstColor={isFollowing ? colors.transparent : colors.black}
        secondColor={isFollowing ? colors.transparent : colors.black}
        underlayColor={isFollowing ? colors.transparent : colors.black}
        actionIcon={{ uri: icon }}
        showActionIcon
        style={containerStyle}
      />
    );
  };

  onPressSecondaryButton = () => {
    const { data: { online_consultation_link = '' } = {} } = this.state;
    if (isBlank(online_consultation_link)) return;
    Linking.openURL(online_consultation_link).catch((err) => console.error('Error opening Google Maps', err));
  };

  secondaryButtonContainer = () => {
    const { data: { online_consultation_link = '' } = {} } = this.state;
    if (isBlank(online_consultation_link)) return null;
    return (
      <FoxyShadowButton
        width={(getScreenWidth() - 24 - 12) / 2}
        height={42}
        title={'Book Appointment'}
        onPress={this.onPressSecondaryButton}
        textStyle={styles.buttonText}
        firstColor={colors.light_green}
        secondColor={colors.light_green}
        underlayColor={colors.light_green}
      />
    );
  };

  buttonContainer = ({ isFollowing }) => {
    const { data: { online_consultation_link = '' } = {} } = this.state;
    return (
      <View style={styles.buttonContainer}>
        {isBlank(online_consultation_link) && <View />}
        <this.primaryButtonContainer isFollowing={isFollowing} />
        <this.secondaryButtonContainer />
      </View>
    );
  };

  onPressEditIcon = () => {
    const { navigation } = this.props;
    const { data: { self_profile: selfProfile = false } = {} } = this.state;
    if (!selfProfile) return;
    navigation.navigate('HandleSelection');
  };

  navigateToSelfieScreen = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(
      PERMISSION_STORE.camera,
      true,
      (permissionStatus) => {
        if (permissionStatus === 'granted' || permissionStatus === 'authorized') {
          navigation.navigate(SCREEN.TAKE_SELFIE, {
            previousScreen: SCREEN_CONSTANTS.INFLUENCER,
            isImageUploading: this.isImageUploading,
          });
          this.currentPermissionState = '';
        } else if (permissionStatus === 'never_ask_again' || permissionStatus === 'denied') {
          if (Utility.isBlank(this.currentPermissionState)) {
            this.currentPermissionState = permissionStatus;
          } else {
            Linking.openSettings();
          }
        }
      },
      Utility.createPermissionMetaObject(AnalyticsScreenNames.INFLUENCER, PERMISSIONS.CAMERA),
    );
  };

  onClickSelfie = () => {
    const { navigation } = this.props;
    Utility.checkPermissionStatic(PERMISSION_STORE.camera, true, (permissionStatus) => {
      if (permissionStatus === 'granted' || permissionStatus === 'authorized') {
        navigation.navigate('TakeSelfie', {
          previousScreen: SCREEN_CONSTANTS.INFLUENCER,
        });
        this.currentPermissionState = '';
      } else if (permissionStatus === 'never_ask_again' || permissionStatus === 'denied') {
        if (Utility.isBlank(this.currentPermissionState)) {
          this.currentPermissionState = permissionStatus;
        } else {
          Linking.openSettings();
        }
      }
    });
  };

  render() {
    const {
      data: {
        lists = {},
        degrees = [],
        reviews = [],
        rating = '',
        reviews_count = 0,
        ratings_count = 0,
        self_profile = false,
      } = {},
      data = {},
      loading,
      error,
      isFollowing,
      allReviews = [],
    } = this.state;
    const { navigation, selfieImageUrl } = this.props;
    if (loading && isBlank(data)) {
      return <InfluencerPlaceHolder />;
    }
    if (!loading && error === API_ERRORS.CONNECTION_ERROR) {
      return <this.ConnectionErrorPage />;
    }

    const UserAccountInfo = getStoreRef().getState()?.UserAccountInfo;
    const myAttributesValue = UserAccountInfo?.facialAnalysis?.my_attributes_values;
    const Wrapper = Platform.OS === 'web' ? View : React.Fragment;
    const showInfluencerShareButton = !isDesktop() && isPresent(this.artistHandle);
    const headerTitle = `${this.getInfluencerHandle().slice(0, 20)}${
      this.getInfluencerHandle().length > 20 ? '...' : ''
    }`;
    return (
      <View style={styles.background}>
        <StaticNavigationHeader
          title={headerTitle}
          onBackPress={this.goBack}
          showEditIcon={self_profile}
          showInfluencerShareButton={showInfluencerShareButton}
          onPressEditIcon={this.onPressEditIcon}
          onPressInfluencerShareIcon={this.onShareButtonPress}
        ></StaticNavigationHeader>
        <Animated.ScrollView
          ref={this.scrollViewRef}
          contentContainerStyle={styles.scrollContainer}
          showsVerticalScrollIndicator={false}
          scrollEventThrottle={1}
          removeClippedSubviews
          refreshControl={<RefreshControl refreshing={loading} onRefresh={this.onRefreshInfluencerDetails} />}
        >
          <this.conditionalErrorOrIntroCard data={data} isFollowing={isFollowing} />
          {Utility.isPresent(lists) && (
            <Wrapper>
              <List
                itemData={lists}
                previousScreen={SCREEN_CONSTANTS.INFLUENCER}
                disableRefresh
                navigation={navigation}
                useInViewPort
              />
            </Wrapper>
          )}

          {isPresent(degrees) && <Qualifications qualifications={degrees} />}
          {isPresent(this.artistHandle) && (
            <View style={styles.ratingContainer} onLayout={this.onRatingLayout}>
              <Text style={styles.rateProductHeader}>Rating and reviews</Text>
              <View style={styles.rateProductContainer}>
                <RateProduct
                  onReview={this.toggleReview}
                  artistHandle={this.artistHandle}
                  heading={'Consulted the Dermatologist? Rate now'}
                />
              </View>
              {isBlank(myAttributesValue) && isBlank(selfieImageUrl) && !isWeb() && (
                <TaskPromptCard
                  itemData={this.skinTwinCardData}
                  showActionIcon={false}
                  buttonAction={this.onClickSelfie}
                  isCenterAligned
                  previousScreen={SCREEN_CONSTANTS.INFLUENCER}
                  cardType='click_selfie'
                />
              )}
              {isPresent(reviews) && (
                <ProductRating
                  rating={rating}
                  reviewData={allReviews}
                  reviewsCount={reviews_count}
                  ratingsCount={ratings_count}
                  navigation={navigation}
                  heading={'All reviews'}
                  subHeading={'Check out what other patients have to say'}
                  previousScreen={SCREEN_CONSTANTS.INFLUENCER}
                  influencerHandle={this.artistHandle}
                />
              )}
            </View>
          )}
          <this.OneLinkButton />
          {isWeb() && <WebFooter />}
        </Animated.ScrollView>
        {isPresent(this.artistHandle) && <this.buttonContainer isFollowing={isFollowing} />}
      </View>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    followedArtists: store.UserAccountInfo?.followedArtists,
    authToken: store.UserAccountInfo?.authToken,
    profile: store.UserAccountInfo.profile,
    selfieImageUrl: store.UserAccountInfo.profile.selfie_image_url,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      getInfluencerDetails,
      followArtist,
      unFollowArtist,
      setPhoneNumberModalInfo,
    },
    dispatch,
  ),
});

export default WithNavigation(connect(mapStateToProps, mapDispatchToProps)(Influencer));
