import Config from 'react-native-config';
import { REMOTE_CONFIG_KEYS } from '../config/Constants';
import { jsonParser } from '../utils/JsonUtility';
import RemoteConfig from '../utils/RemoteConfig';

const APLUS_CONTENT_CUSTOM_CSS_INJECTED = `

var styleElement = document.createElement('style');
styleElement.innerHTML = \`

  .no-scroll::before,.no-scroll {
    display: contents !important;
  }

  /* Header and Footer Removal */
  HEADER_FOOTER {
    display: none !important;
  }


  /* Carousel Removal */
  CAROUSEL {
    display: none !important;
  }


  /* Ratings and Reviews Removal */
  SECTION_SELECTOR[id*="heading"]:has(+ SECTION_SELECTOR * REVIEWS_RATING)  {
    display: none !important;
  }
  SECTION_SELECTOR:has(REVIEWS_RATING) {
    display: none !important;
  }


  /* Product Lists Removal With Titles */
  PRODUCT_LIST {
    display: none !important;
  }


  /* Extracting Product Description from Product Info */
  PRODUCT_INFO > * {
    display: none !important;
  }
  PRODUCT_INFO > .PRODUCT_DESCRIPTION {
    display: block !important;
  }


  /* Miscellaneous Elements Removal */
  OTHER_SELECTORS {
    display: none !important;
  }

  /* Override Background Color */
  BACKGROUND_COLOR_SELECTORS {
    background: BACKGROUND_COLOR;
  }

\`;

document.head.appendChild(styleElement);

(function() {
  var originalXhr = window.XMLHttpRequest;
  function newXhr() {
    var xhr = new originalXhr();
    var originalOpen = xhr.open;
    xhr.open = function(method, url) {
      if (method.toLowerCase() === 'post' && url?.includes('/cart/add')) {
        return;
      }
      if (method.toLowerCase() === 'post' && url?.includes('ipinfo.io')) {
        return;
      }
      originalOpen.apply(this, arguments);
    };
    return xhr;
  }
  window.XMLHttpRequest = newXhr;
})();

(function() {
  var originalFetch = window.fetch;
  window.fetch = function() {
    if (arguments[0]?.includes('/cart/add') && arguments[1] && arguments[1].method && arguments[1].method.toLowerCase() === 'post') {
      return Promise.resolve(new Response(null, { status: 200 }));
    }
    if(arguments[0]?.includes('ipinfo.io')) {
      return Promise.resolve(new Response(null, { status: 200 }));
    }
    return originalFetch.apply(this, arguments);
  };
})();

`;

export default APLUS_CONTENT_CUSTOM_CSS_INJECTED;

export const HOMEPAGE_CONTENT_CUSTOM_CSS_INJECTED = `
var styleElement = document.createElement('style');
styleElement.innerHTML = \`
  /* Header and Footer Removal */
  HOMEPAGE_SELECTORS {
    display: none !important;
  }
  .no-scroll::before,.no-scroll {
    display: contents !important;
  }
      
  .slider.slider--tablet {
        overflow-y: clip;
    }
\`;

document.head.appendChild(styleElement);

var consoleLog = (log) => window.ReactNativeWebView.postMessage(JSON.stringify({'type': 'log', 'log': log}));
var addToCart = (skuId, formId) => window.ReactNativeWebView.postMessage(JSON.stringify({'type': 'add', 'skuId': skuId, 'formId': formId}));

(function() {
  var originalXhr = window.XMLHttpRequest;
  function newXhr() {
    var xhr = new originalXhr();
    var originalOpen = xhr.open;
    xhr.open = function(method, url) {
      if (method.toLowerCase() === 'post' && url?.includes('/cart/add')) {
        return;
      }
      if (method.toLowerCase() === 'post' && url?.includes('ipinfo.io')) {
        return;
      }
      originalOpen.apply(this, arguments);
    };
    return xhr;
  }
  window.XMLHttpRequest = newXhr;
})();

(function() {
  var originalFetch = window.fetch;
  window.fetch = function() {
    if (arguments[0]?.includes('/cart/add') && arguments[1] && arguments[1].method && arguments[1].method.toLowerCase() === 'post') {
      return Promise.resolve(new Response(null, { status: 200 }));
    }
    if(arguments[0]?.includes('ipinfo.io')) {
      return Promise.resolve(new Response(null, { status: 200 }));
    }
    return originalFetch.apply(this, arguments);
  };
})();

var forms = document.getElementsByClassName('ADD_TO_CART_FORM_SELECTORS');

Array.from(forms).forEach((form, index) => {
  var formId = form.getAttribute('id');
  form.addEventListener('submit', () => {
    event.preventDefault();
    var skuId = form.elements["id"].value;
    addToCart(skuId, formId);
  });
});
`;

export const getAutoHeightScript = (injectedJS) => {
  const AplusContentData =
  jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
  {};
  const { mainContentSelector = Config.PRODUCT_PAGE_MAIN_CONTENT_SELECTOR || '#content' } = AplusContentData;

  return (`
  ${injectedJS}
  var mainContent = document.querySelector('${mainContentSelector}');

  var informReactNative = () => {
      window?.ReactNativeWebView?.postMessage(JSON.stringify({height: mainContent.clientHeight}));
  }

  new ResizeObserver(informReactNative).observe(mainContent)

  window?.ReactNativeWebView?.postMessage(JSON.stringify({height: mainContent.clientHeight}));
  `);
};

export const RESET_ADD_TO_CART_BUTTON_SCRIPT = `
  'ADD_TO_CART_BUTTON_SELECTORS'.split(',').forEach(btnName => {
  try {
    var buttons = document.getElementsByClassName(btnName+' SHOPFLO_ATC_LOADING_SELECTOR');
    Array.from(buttons).forEach(button => {
      button.className = btnName;  
    });
  } catch(e) {
    buttons = document.getElementsByClassName(btnName+' SHOPFLO_ATC_LOADING_SELECTOR');
    Array.from(buttons).forEach(button => {
    button.className = btnName;  
    });
  }
  });
`;

export const getResetAddToCartButtonScript = () => {
  const AplusContentData =
  jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
  {};
  const {
    addToCartButtonSelectors = Config.ADD_TO_CART_BUTTON_SELECTORS,
    loadingAddToCartSelectors = Config.LOADING_ADD_TO_CART_BUTTON_SELECTORS,
  } = AplusContentData;

  return RESET_ADD_TO_CART_BUTTON_SCRIPT
    .replace(/\bSHOPFLO_ATC_LOADING_SELECTOR\b/g, loadingAddToCartSelectors)
    .replace(/\bADD_TO_CART_BUTTON_SELECTORS\b/g, addToCartButtonSelectors);
};

const populateProductListsRemovalWithTitles = (selectorsArray) => {
  let cssRule = '';
  selectorsArray.forEach((selector) => {
    cssRule += `
      SECTION_SELECTOR[id*="heading"]:has(+ SECTION_SELECTOR * ${selector}),
      SECTION_SELECTOR:has(${selector}),
    `;
  });
  return cssRule;
};


export const javaScriptForProductPage = (() => {
  const AplusContentData =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
    {};
  const {
    header_footer_selectors = Config.PRODUCT_PAGE_HEADER_FOOTER_SELECTORS?.split(',') || [],
    reviews_rating_selector = Config.PRODUCT_PAGE_REVIEWS_RATINGS_SELECTOR || '',
    carousel_selector = Config.PRODUCT_PAGE_CAROUSEL_SELECTOR || '',
    section_selector = Config.PRODUCT_PAGE_SECTION_SELECTOR || '',
    product: {
      product_info_selector = Config.PRODUCT_PAGE_PRODUCT_INFO_SELECTOR || '',
      product_description_selector = Config.PRODUCT_PAGE_PRODUCT_DESCRIPTION_SELECTOR || '',
      product_list_selectors = Config.PRODUCT_PAGE_PRODUCT_LISTS_SELECTORS?.split(',') || [],
    } = {},
    other_list_selectors = Config.PRODUCT_PAGE_OTHER_LISTS_SELECTORS?.split(',') || [],
    main_content_selector = Config.PRODUCT_PAGE_MAIN_CONTENT_SELECTOR || '',
    background_color_selectors = Config.PRODUCT_PAGE_BACKGROUND_COLOR_SELECTORS?.split(',') || [],
    background_color = Config.PRODUCT_PAGE_BACKGROUND_COLOR || '',
  } = AplusContentData;

  return APLUS_CONTENT_CUSTOM_CSS_INJECTED.replace(
    /\bCAROUSEL\b/g,
    carousel_selector,
  )
    .replace(/\bHEADER_FOOTER\b/g, header_footer_selectors)
    .replace(/\bREVIEWS_RATING\b/g, reviews_rating_selector)
    .replace(/\bPRODUCT_INFO\b/g, product_info_selector)
    .replace(/\bPRODUCT_DESCRIPTION\b/g, product_description_selector)
    .replace(/\bOTHER_SELECTORS\b/g, other_list_selectors)
    .replace(
      /\bPRODUCT_LIST\b/g,
      populateProductListsRemovalWithTitles(product_list_selectors)?.replace(
        /,\s*$/,
        '',
      ),
    )
    .replace(/\bMAIN_CONTENT_SELECTOR\b/g, main_content_selector)
    .replace(/\bBACKGROUND_COLOR_SELECTORS\b/g, background_color_selectors)
    .replace(/\bBACKGROUND_COLOR\b/g, background_color)
    .replace(/\bSECTION_SELECTOR\b/g, section_selector);
})();

export const javaScriptForListPage = (rejectSelectors) => {
  const AplusContentData =
    jsonParser(RemoteConfig.getValue(REMOTE_CONFIG_KEYS.a_plus_content_data)) ||
    {};
  const { addToCartFormSelectors = Config.ADD_TO_CART_FORM_SELECTORS } = AplusContentData;
  return HOMEPAGE_CONTENT_CUSTOM_CSS_INJECTED.replace(
    /\bHOMEPAGE_SELECTORS\b/g,
    rejectSelectors,
  ).replace(/\bADD_TO_CART_FORM_SELECTORS\b/g, addToCartFormSelectors);
};
