import React, { PureComponent, memo } from 'react';
import { connect } from 'react-redux';
import {
  View,
  Image,
  Text,
  TouchableOpacity,
  NativeModules,
} from 'react-native';
import memoize from 'lodash/memoize';
import Config from '../../libraries/ReactNativeConfig';
import styles from './styles';
import images from '../../theme/Images';
import withNavigation from '../../utils/WithNavigation';
import Utility from '../../utils/Utility';
import DebouncedTouchableOpacity from '../../components/shared/DebouncedTouchableOpacity';
import Carousel from 'react-native-looped-carousel';
import RemoteConfig from '../../utils/RemoteConfig';
import { REMOTE_CONFIG_KEYS } from '../../config/Constants';
import DynamicLinkManager from '../../utils/DynamicLinkManager';
import {
  AnalyticsManager,
  EventParameterKey,
  EventType,
} from '../../analytics';
import AppConfig from '../../config/AppConfig';
import { jsonParser } from '../../utils/JsonUtility';
import { isBlank, isPresent } from '../../utils/BooleanUtility';
import { getStoreRef } from '../../store/StoreUtility';
import { navigateToScreen } from '../../utils/NavigationUtility';
import FastImageView from '../../components/FastImageView';

class CartTray extends PureComponent {
  cartTrayConstants = {
    trayText: 'You have items saved in your Bag',
    ctaText: 'VIEW BAG',
    ctaAction: `${Config.WEB_URL}/cart`,
    image: images.coloredCartIcon,
  };

  constructor(props) {
    super(props);
    this.dotStyle = { height: 0, width: 0 };
    this.activeDotStyle = { height: 0, width: 0 };
    this.state = {
      hideNotificationTray: false,
    };

    this.remoteConfigDataForEdge = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.edge_constants_for_cart_tray),
    );

    this.notificationTrayContent = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.notification_tray_content),
    );

    this.remoteConfigDataForRoutine = jsonParser(
      RemoteConfig.getValue(REMOTE_CONFIG_KEYS.routine_tray_item),
    );
  }

  openPermissionSettings = () => {
    if (Utility.isAndroid()) {
      NativeModules.NotificationsChannelModule.openNotificationChannelSettings(
        null,
      );
    } else {
      Utility.openSettings();
    }

    this.setState({
      hideNotificationTray: true,
    });
  };

  handleCTATap = (ctaAction, ctaText, showRoutineTray = false) => {
    const { navigation, activeRoutineSlugs = [] } = this.props;
    return () => {
      if (ctaAction === 'open_notification_settings') {
        this.openPermissionSettings();
      } else if (showRoutineTray && isPresent(activeRoutineSlugs)) {
        navigation.navigate('DuringRoutineDetails', {
          slug: activeRoutineSlugs[0],
        });
      } else {
        DynamicLinkManager.handleDynamicLink(
          ctaAction,
          this.handleRouteFromLink,
        );
      }
      AnalyticsManager.logEvent(EventType.discoveryEvents.ITEM_CLICK, {
        [EventParameterKey.TYPE]: 'cart_tray',
        [EventParameterKey.CTA]: ctaText,
      });
    };
  };

  handleRouteFromLink = ({
    route = '',
    slug = '',
    extra = {},
    params = {},
  }) => {
    const { navigation } = this.props;
    navigateToScreen({
      navigation,
      screen: route,
      type: 'navigate',
      params: { slug, extra, params },
    });
  };

  trayItemsForEdge = () => {
    const {
      todayDeals: {
        is_upgradable = false,
        membership_cohort,
        remaining_order_amount_for_extension,
      } = {},
    } = this.props;
    const {
      show_edge_data_in_cart_tray = false,
      expired_member,
      member,
      trial_member,
      non_member,
    } = this.remoteConfigDataForEdge || {};
    if (isBlank(this.remoteConfigDataForEdge) || !show_edge_data_in_cart_tray) {
      return [];
    }

    if (!is_upgradable && membership_cohort === 'expired') {
      return expired_member;
    }
    if (!is_upgradable && membership_cohort === 'member') {
      return member;
    }
    if (is_upgradable && membership_cohort !== 'non_member') {
      const itemsToShow = trial_member;
      return itemsToShow.filter(
        (item) =>
          !(
            item.trayText.includes('remaining_order_amount_for_extension') &&
            Utility.isBlank(remaining_order_amount_for_extension)
          ),
      );
    }
    return non_member;
  };

  trayItemForRoutine = () => {
    const {
      show_routine_tray: showRoutineTray = false,
      active_subscribed_routine: activeSubscribedRoutine = [],
    } = this.remoteConfigDataForRoutine || {};
    const { activeRoutineSlugs = [] } = this.props;
    if (
      isBlank(this.remoteConfigDataForRoutine) ||
      isBlank(activeRoutineSlugs)
    ) {
      return [];
    }
    return activeSubscribedRoutine;
  };

  trayItemsForAnnouncements = () => {
    if (
      Utility.isBlank(
        RemoteConfig.getValue(
          REMOTE_CONFIG_KEYS.announcements_constants_for_cart_tray,
        ),
      )
    ) {
      return [];
    }
    return Utility.jsonParser(
      RemoteConfig.getValue(
        REMOTE_CONFIG_KEYS.announcements_constants_for_cart_tray,
      ),
    );
  };

  getTrayItems = () => {
    const { cartItems, notificationPermissionStatus } = this.props;
    const { hideNotificationTray } = this.state;
    let itemsToShow = [];

    if (Utility.isPresent(cartItems) && cartItems.length > 0) {
      itemsToShow.push(this.cartTrayConstants);
    }

    if (Utility.isPresent(this.notificationTrayContent)) {
      itemsToShow.push(this.notificationTrayContent);
    }
    itemsToShow = [...itemsToShow, ...this.trayItemsForAnnouncements()];

    const filteredItemsToShow = itemsToShow.filter((item = {}) => {
      const { trayType = '' } = item;
      return trayType === 'notification_status'
        ? !hideNotificationTray &&
            notificationPermissionStatus === 'never_ask_again'
        : true;
    });

    return [
      ...filteredItemsToShow,
      ...this.trayItemsForEdge(),
      ...this.trayItemForRoutine(),
    ];
  };

  renderView(item) {
    const {
      todayDeals,
      todayDeals: {
        membership_savings = 0,
        remaining_order_amount_for_extension = 0,
      } = {},
      notificationPermissionStatus,
    } = this.props;
    const { hideNotificationTray } = this.state;

    let ctaAction = item.ctaAction;

    let text = '';
    let ctaText = item.ctaText;
    const showRoutineTray = item.show_routine_tray;

    if (item.trayText.includes('remaining_order_amount_for_extension')) {
      if (remaining_order_amount_for_extension === 0) {
        text = 'Extend your membership for free';
        ctaText = 'Claim';
        ctaAction = Config.LOYALTY_PLANS_DEEPLINK;
      } else {
        text = item.trayText.replace(
          '{remaining_order_amount_for_extension}',
          remaining_order_amount_for_extension,
        );
      }
    } else {
      text = item.trayText.replace('{membership_savings}', membership_savings);
    }

    const onPress = memoize(
      () => this.handleCTATap(ctaAction, ctaText, showRoutineTray),
      () => [ctaAction, ctaText],
    )();

    return (
      <DebouncedTouchableOpacity
        style={styles.cartTrayContainer}
        onPress={onPress}
        activeOpacity={0.9}
        key={`${ctaText}${item.trayText}`}
      >
        <FastImageView source={item.image?.uri} style={styles.cartTrayIcon} />
        <Text style={styles.cartText}>{text}</Text>
        <DebouncedTouchableOpacity
          {...this.props}
          style={styles.viewCartButton}
          onPress={onPress}
          hitSlop={Utility.getHitSlop()}
        >
          <Text style={styles.viewButtonText}>{ctaText}</Text>
        </DebouncedTouchableOpacity>
      </DebouncedTouchableOpacity>
    );
  }

  render() {
    return (
      <Carousel
        horizontal={false}
        vertical
        bullets={false}
        style={styles.cartTrayCarouselContainer}
        currentPage={0}
        autoplay
        isLooped
      >
        {this.getTrayItems().map((item) => this.renderView(item))}
      </Carousel>
    );
  }
}

const mapStateToProps = (state) => ({
  cartItems: state.bag.cartItems,
  cartOffers: state.cart.cartOffers,
  todayDeals: state.todayDeals,
  notificationPermissionStatus: state.permissionStatus?.notification,
  activeRoutineSlugs: state.UserAccountInfo.activeRoutineSlugs,
});

export default withNavigation(connect(mapStateToProps, null)(memo(CartTray)));
